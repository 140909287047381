<div [sessionTimeout]="closePopup">
    <h1 mat-dialog-title class="modal-primary">{{'invoices.popup.title' | translate}}</h1>
    <div mat-dialog-content>

        <mat-form-field [hidden]="!unitCostSelectorVisible()" class="dropdown-width">
            <mat-select #uc
                        placeholder="{{'invoices.popup.unitCostSelector.placeholder' | translate}}"
                        (selectionChange)="unitCostSelectionChanged($event)"
                        [value]="unitCost">
                <mat-option [value]="false">{{'invoices.popup.unitCostSelector.values.false' | translate}}</mat-option>
                <mat-option [value]="true">{{'invoices.popup.unitCostSelector.values.true' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>

        <jhi-table *ngIf="tableData"
                   [data]="tableData"></jhi-table>

        <jhi-invoice-column-diagram *ngIf="invoiceReportResponseByInterval"
                                    [chartData]="invoiceReportResponseByInterval"
                                    [unitCost]="unitCost"></jhi-invoice-column-diagram>

        <jhi-invoice-pie-chart *ngIf="pieChartData"
                               [chartData]="pieChartData"
                               [unitCost]="unitCost"></jhi-invoice-pie-chart>

        <jhi-invoice-file *ngIf="tableData || invoiceReportResponseByInterval || pieChartData"
                          [invoices]="invoices"></jhi-invoice-file>

    </div>


    <div mat-dialog-actions align="end">
        <button mat-raised-button (click)="navigateToReports()">
            <mat-icon>fast_forward</mat-icon> {{'invoices.popup.goto-invoicereport' | translate}}
        </button>
        <button mat-raised-button mat-dialog-close class="cez-color--dark">
            <mat-icon>close</mat-icon> {{'entity.action.close' | translate }}
        </button>
    </div>

    <ngx-loading [show]="loadingOverlay"></ngx-loading>
</div>
