import { CommonModule } from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CezPortalSharedModule } from '../shared';
import { ConsumptionComponent } from './consumption.component';
import { CONSUMPTION_ROUTE } from './consumption.route';
import { DiagramColumnComponent } from './diagram-column/diagram-column.component';
import { FilterBasicComponent } from './filter-basic/filter-basic.component';
import { MinMaxAvarageComponent } from './min-max-avarage/min-max-avarage.component';
import { PerformanceDiagramAreaComponent } from './performance-diagram-area/performance-diagram-area.component';
import { PerformanceDiagramBoxPlotComponent } from './performance-diagram-box-plot/performance-diagram-box-plot.component';
import { PerformanceDispersionDiagramColumnComponent } from './performance-dispersion-diagram-column/performance-dispersion-diagram-column.component';
import { PerformanceHeatmapDiagramComponent } from './performance-heatmap-diagram/performance-heatmap-diagram.component';
import { ConsumptionExportComponent } from './export/consumption-export.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(CONSUMPTION_ROUTE, {useHash: true}),
        CezPortalSharedModule,
        SelectButtonModule
    ],
    declarations: [ConsumptionComponent,
        DiagramColumnComponent,
        PerformanceDiagramAreaComponent,
        FilterBasicComponent,
        PerformanceDiagramBoxPlotComponent,
        PerformanceDispersionDiagramColumnComponent,
        PerformanceHeatmapDiagramComponent,
        MinMaxAvarageComponent,
        ConsumptionExportComponent],
    providers: [],
    exports: [ConsumptionComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConsumptionModule {
}
