<mat-card>
  <mat-card-content>
    <table class="table table-striped">
      <thead>
      <tr>
        <th><span>{{'email.tabs.template.table.header.name' | translate}}</span></th>
        <th><span>{{'email.tabs.template.table.header.description' | translate}}</span></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let template of emailTemplates">
        <td>{{'email.tabs.template.table.content.' + template.key + ".name"  | translate}}</td>
        <td>{{'email.tabs.template.table.content.' + template.key + ".description"  | translate}}</td>

        <td>
          <div class="pull-right">
            <button type="submit" class="btn btn-info btn-sm" (click)="openModal(template)">
              <span *ngIf="!template.editMode" class="fa fa-pencil"></span>
              <span *ngIf="template.editMode" class=" fa fa-spinner fa-spin"></span>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
