import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ScheduledTask } from './scheduled.task.model';

@Component({
    selector: 'scheduled-task',
    templateUrl: './scheduled.task.component.html'
})
export class ScheduledTaskComponent implements OnInit {

    scheduledTasks: ScheduledTask[];

    constructor(private http: HttpClient) {

    }

    ngOnInit() {
        this.refreshList();
    }

    refreshList() {
        this.http.get<ScheduledTask[]>('api/scheduled_task').subscribe(r => {
            this.scheduledTasks = r;
            this.scheduledTasks.map(result => {
                let task = result;
                task.lastExecutionTime = task.lastManualExecutionTime > task.lastScheduledExecutionTime
                    ? task.lastManualExecutionTime : task.lastScheduledExecutionTime;
            });
        });
    }

    runtask(task: ScheduledTask) {
        task.isSaving = true;
        this.http.post('api/scheduled_task/run/' + task.taskName, task).subscribe(r => {
            this.refreshList();
            task.isSaving = false;
        });
    }
}
