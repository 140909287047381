import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TolerancePeriod } from '../dto/tolerance.limits';

@Component({
    selector: 'jhi-tolerance-limit-list',
    templateUrl: './tolerance-limit-list.component.html',
    styles: []
})
export class ToleranceLimitListComponent implements OnInit {

    @Input() model: TolerancePeriod[];
    @Input() adminView: boolean;
    @Output() editEmitter: EventEmitter<TolerancePeriod> = new EventEmitter();
    @Output() deleteEmitter: EventEmitter<number> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    edit(toleranceLimit: TolerancePeriod) {
        this.editEmitter.emit(toleranceLimit);
    }

    delete(toleranceLimit: TolerancePeriod) {
        this.deleteEmitter.emit(toleranceLimit.id);
    }
}
