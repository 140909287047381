<h1 mat-dialog-title>
    {{(data ? 'additional-diag-type-edit.title-edit' : 'additional-diag-type-edit.title-create') | translate}}
</h1>
<div mat-dialog-content [formGroup]="form" class="d-flex flex-column">
    <mat-form-field>
        <input matInput
               formControlName="typeNameEN"
               placeholder="{{'additional-diag-type-edit.type-name.en' | translate }}"
               required>
    </mat-form-field>

    <mat-form-field>
        <input matInput
               formControlName="typeNameHU"
               placeholder="{{'additional-diag-type-edit.type-name.hu' | translate }}"
               required>
    </mat-form-field>

    <mat-form-field>
        <input matInput
               formControlName="columnName"
               placeholder="{{'additional-diag-type-edit.columnName' | translate }}"
               required>
    </mat-form-field>

    <mat-form-field>
        <textarea matInput
                  formControlName="typeDescriptionEN"
                  placeholder="{{'additional-diag-type-edit.type-description.en' | translate }}">
        </textarea>
    </mat-form-field>

    <mat-form-field>
        <textarea matInput
                  formControlName="typeDescriptionHU"
                  placeholder="{{'additional-diag-type-edit.type-description.hu' | translate }}">
        </textarea>
    </mat-form-field>

    <mat-form-field>
        <mat-select placeholder="{{'additional-diag-type-edit.diagramType' | translate }}"
                    formControlName="diagramType"
                    required>
            <mat-option *ngFor="let entry of diagramType | keys" [value]="entry.value">
                {{'DiagramType.' + entry.value | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <p-checkbox
        formControlName="groupOnlyVisibility"
        [label]="'additional-diag-type-edit.groupOnlyVisibility' | translate"
        binary="true" />

    <mat-form-field>
        <input matInput
               formControlName="measureUnit"
               placeholder="{{'additional-diag-type-edit.measureUnit' | translate }}"
               required>
    </mat-form-field>

    <mat-form-field>
        <mat-select placeholder="{{'additional-diag-type-edit.resolution' | translate }}"
                    formControlName="resolution"
                    required>
            <mat-option *ngFor="let entry of additionalDiagramResolution | keys" [value]="entry.value">
                {{'AdditionalDiagramResolution.' + entry.value | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close class="cez-color--dark">
        {{'entity.action.cancel' | translate }}
    </button>
    <button mat-raised-button
            (click)="save()"
            color="primary"
            [disabled]="!form.valid">
        {{'entity.action.save' | translate }}
    </button>
</div>
