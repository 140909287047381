import {ProductType} from '../agreement-details/agreement-enums.model';
import {OfferPeriod} from '../offer-claim/model/offer-period.enum';

export interface AggregationTableDataModel {
    type: ProductType;
    beforeTrade: OfferClaimQuantitiesModel;
    afterTrade: OfferClaimQuantitiesModel;
    hasActivePurchase: boolean;
}

export interface OfferClaimQuantitiesModel {
    yearlyQuantities: QuantitiesForPeriodModel;
    quarterlyQuantities: QuantitiesForPeriodModel[];
    monthlyQuantities: QuantitiesForPeriodModel[];
}

export interface QuantitiesForPeriodModel {
    period: OfferPeriod;
    periodNumber: number;

    tranche: number;
    quantityMw: number;
    quantityMwh: number;
    percent: number;

    available: boolean;
    illiquid: boolean;
    cancelled: boolean;
}

export enum AggregationTableType {
    BEFORE_TRADE = 'BEFORE_TRADE',
    AFTER_TRADE = 'AFTER_TRADE',
    ALL = 'ALL'
}

export type QuantityValueType = 'tranche' | 'quantityMw' | 'quantityMwh' | 'percent';
export type OfferClaimPeriodType = 'yearlyQuantities' | 'quarterlyQuantities' | 'monthlyQuantities';
export const OfferClaimPeriodType = {
    yearlyQuantities: 'yearlyQuantities' as OfferClaimPeriodType,
    quarterlyQuantities: 'quarterlyQuantities' as OfferClaimPeriodType,
    monthlyQuantities: 'monthlyQuantities' as OfferClaimPeriodType
};
