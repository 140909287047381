import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {User, UserService} from '../../shared';
import {Principal} from '../../shared/auth/principal.service';
import {PeriodParameterModel} from '../../shared/dto/period-parameter.model';
import {ViewTemplate} from '../../shared/dto/view.template.model';

import {DiagramService} from '../../shared/services/diagram.service';
import {MultiSelectTranslationService} from '../../shared/services/multi-select-translation.service';
import {NgBDatePickerConvertService} from '../../shared/services/ngb.datepicker.convert.service';
import {PodService} from '../../shared/services/pod.service';
import {ViewTemplateService} from '../../shared/services/view-template.service';
import {Account} from '../../shared/user/account.model';
import {FilterModel} from './filter.model';
import {StateStorageService} from '../../shared/auth';
import {SelectItem} from 'primeng/api';
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {NavitaMultiSelectOption} from "../../shared/dto/common.model";
import {DEFAULT_DROPDOWN_SETTINGS} from "../../core/util/constants";


@Component({
  selector: 'jhi-filter-basic',
  templateUrl: './filter-basic.component.html',
  styles: []
})
export class FilterBasicComponent implements OnInit {

  @Output() reportRunned: EventEmitter<FilterModel> = new EventEmitter();
  podListOptions: NavitaMultiSelectOption[];
  podGroupsOptions: NavitaMultiSelectOption[];
  diagramListOptions: any[];
  showDiagrams: boolean;
  filterModel: FilterModel = new FilterModel();
  newViewTemplateName: string;
  shift: number;
  intervalSize: number;
  selectedIntervalGranularity: number;
  isTemperatureAllowed: boolean;

  showPeriod2: boolean;
  showPeriod3: boolean;
  showPeriod4: boolean;
  periodInDays: number;
  showTemperature: boolean;
  comparisonAllowed: boolean;
  resolutions: any[];
  viewTemplates: ViewTemplate[];
  selectedTemplate: ViewTemplate;
  selectedTemplateType: { label: string, value: number };
  users: User[];
  selectedUser: User;
  currentUserAdmin: boolean = false;
  currentUser: Account;
  templateOptions: SelectItem[] = [
    {
      label: 'filter.basic.saveAsTemplate',
      value: 2
    }, {
      label: 'filter.basic.saveAsChart',
      value: 1
    }
  ];

  public dropdownSettings: IDropdownSettings = {
    ...DEFAULT_DROPDOWN_SETTINGS,
    ...this.multiSelectTranslationService.getMultiSelectTexts()
  };

  constructor(private podService: PodService, private diagramService: DiagramService,
              private dateConverter: NgBDatePickerConvertService,
              private viewTemplateService: ViewTemplateService,
              public translateService: TranslateService,
              private principal: Principal,
              private userService: UserService,
              private stateStorageService: StateStorageService,
              private multiSelectTranslationService: MultiSelectTranslationService) {
    podService.getAllPodMultiSelect().subscribe(r => this.podListOptions = r);
    podService.getPodGroupsOfPartner().subscribe(r => {
      this.podGroupsOptions = r.map(podgroup => {
        return {id: podgroup, name: podgroup.name};
      });
    });

    this.filterModel.selectedPods = [];
    this.periodInDays = 0;
    this.showPeriod2 = false;
    this.showPeriod3 = false;
    this.showPeriod4 = false;
    this.showDiagrams = false;
    this.showTemperature = false;
    this.comparisonAllowed = false;
  }

  ngOnInit() {
    const permissions = this.stateStorageService.getEffectivePermissions();
    this.isTemperatureAllowed = [
      'TEMPERATURE_PAST_QUARTER_HOURLY',
      'TEMPERATURE_PAST_HOURLY',
      'TEMPERATURE_FUTURE_QUARTER_HOURLY',
      'TEMPERATURE_FUTURE_HOURLY'
    ].some(permission => permissions.indexOf(permission) > -1);

    this.filterModel.periodParameters = [new PeriodParameterModel(this.periodInDays, 1, this.filterModel.multiplePeriod, false),
      new PeriodParameterModel(this.periodInDays, 2, this.filterModel.multiplePeriod, false),
      new PeriodParameterModel(this.periodInDays, 3, this.filterModel.multiplePeriod, false),
      new PeriodParameterModel(this.periodInDays, 4, this.filterModel.multiplePeriod, false)];

    this.filterModel.referenceWeek = [new PeriodParameterModel(this.periodInDays, 1, false, false)];
    this.refreshViewTemplates();

    this.currentUser = this.principal.userIdentity;
    this.currentUserAdmin = this.principal.userIdentity.groups.some(r => r === 1);
    this.diagramListOptions = this.diagramService.getAllDiagram();
    this.resolutions = this.getResolutionList();
    this.filterModel.selectedDiagram = this.diagramListOptions.find(diagram => permissions.indexOf(diagram.requiredPermission) > -1);
    if (this.filterModel.selectedDiagram) {
      this.diagramSelected();
    }

    if (this.currentUserAdmin) {
      this.userService.query({
        sort: ['id,asc']
      }).subscribe(r => {
        this.users = r.body.filter(u => u.login != this.currentUser.login);
      });
    }
  }

  refreshViewTemplates() {
    this.viewTemplateService.getChartViewTemplates().subscribe(r => {
      this.viewTemplates = r;
    });
  }

  getResolutionList() {
    return [
      {
        value: 'MIN_15',
        description: this.translateService.instant('filter.basic.min15'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_QUARTER_HOURLY'
      },
      {
        value: 'HOUR',
        description: this.translateService.instant('filter.basic.hour'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_HOURLY'
      },
      {
        value: 'DAY',
        description: this.translateService.instant('filter.basic.day'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_DAILY'
      },
      {
        value: 'WEEK',
        description: this.translateService.instant('filter.basic.week'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_WEEKLY'
      },
      {
        value: 'MONTH',
        description: this.translateService.instant('filter.basic.month'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_MONTHLY'
      },
      {
        value: 'QUARTER_OF_YEAR',
        description: this.translateService.instant('filter.basic.quarterOfYear'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_QUARTER_YEARLY'
      },
      {
        value: 'YEAR',
        description: this.translateService.instant('filter.basic.year'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_YEARLY'
      }
    ];
  }

  setResolutionList(enabledList: boolean[]) {
    const permissions = this.stateStorageService.getEffectivePermissions();

    this.resolutions.forEach((resolutionOption, i) => {
      resolutionOption.enabled = enabledList[i] && permissions.indexOf(resolutionOption.requiredPermission) > -1;
    });

    const currentResolutionDisabled = this.resolutions.filter(r => r.enabled).map(r => r.value).indexOf(this.filterModel.resolution) === -1;

    if (!this.filterModel.resolution || currentResolutionDisabled) {
      const firstAllowedResolution = this.resolutions.find(r => r.enabled);
      this.filterModel.resolution = firstAllowedResolution ? firstAllowedResolution.value : null;
    }

  }

  runReport() {
    this.reportRunned.emit(this.filterModel);

    this.filterModel.comparePods = false;
  }

  diagramSelected() {
    this.setResolutionList(this.filterModel.selectedDiagram.resolutionAllowed);
    this.controlTemperature(this.filterModel.selectedDiagram.temperatureAllowed);
    this.controlComparison(this.filterModel.selectedDiagram.comaparisonAllowed);
    if (this.filterModel.selectedDiagram.id === 2) {
      this.filterModel.temperatureChecked = false;
    }
  }

  filterValid() {
    let selectedPeriods = this.filterModel.periodParameters.filter((item, i) => {
      return (i == 0 || this.filterModel.periodParameters[i - 1].showNextPeriod);
    });

    let dateSelected = selectedPeriods.every(r => r.dateFrom && r.dateTo);

    let podSelected = this.filterModel.selectedPods.length > 0 || this.filterModel.selectedPodGroups.length > 0;

    return this.filterModel.resolution && dateSelected && podSelected && selectedPeriods.length > 0;
  }

  exportValid() {
    return this.filterValid() && !this.filterModel.periodParameters[0].showNextPeriod;
  }

  anyActionButtonEnabled() {
    return this.showEnabled() || this.podComparisionEnabled() || this.intervalComparisionEnabled();
  }

  showEnabled() {
    let selectedPeriods = this.filterModel.periodParameters.filter((item, i) => {
      return (i == 0 || this.filterModel.periodParameters[i - 1].showNextPeriod);
    }).length;
    return this.filterValid() && selectedPeriods === 1;
  }

  podComparisionEnabled() {
    let selectedPods = this.filterModel.selectedPods.length + this.filterModel.selectedPodGroups.length;
    return this.filterValid() && selectedPods > 1 && !this.intervalComparisionEnabled();
  }

  intervalComparisionEnabled() {
    const selectedPeriods = this.filterModel.periodParameters.filter((item, i) => {
      return (i == 0 || this.filterModel.periodParameters[i - 1].showNextPeriod);
    }).length;
    return this.filterValid() && selectedPeriods > 1;
  }

  controlTemperature(tempAllowed: boolean) {
    this.showTemperature = tempAllowed;
    if (!this.showTemperature) {
      this.filterModel.temperatureChecked = undefined;
    }
  }

  controlComparison(compAllowed: boolean) {
    this.comparisonAllowed = compAllowed;
    this.filterModel.multiplePeriod = compAllowed;

    this.filterModel.periodParameters.map(r => {
      r.isMultiplePeriodAllowed = this.filterModel.multiplePeriod;
      if (!r.isMultiplePeriodAllowed && r.postfix > 1) {
        r.dateFrom = undefined;
        r.dateTo = undefined;
        r.periodInDays = undefined;
        r.showNextPeriod = false;
      } else if (!r.isMultiplePeriodAllowed && r.postfix == 1) {
        r.showNextPeriod = false;
      }
    });
    // always set last for false
    this.filterModel.periodParameters[this.filterModel.periodParameters.length - 1].showNextPeriod = false;
  }

  saveTemplate() {
    let viewTeplate: ViewTemplate = {
      id: null,
      name: this.newViewTemplateName,
      startTime: this.dateConverter.convertToDate(this.filterModel.periodParameters[0].dateFrom),
      endTime: this.dateConverter.convertToDate(this.filterModel.periodParameters[0].dateTo),
      jsonData: JSON.stringify({
        selectedDiagram: this.filterModel.selectedDiagram,
        selectedPods: this.filterModel.selectedPods,
        selectedPodGroups: this.filterModel.selectedPodGroups,
        temperatureChecked: this.filterModel.temperatureChecked,
        multiplePeriod: this.filterModel.multiplePeriod,
        periodParameters: this.filterModel.periodParameters,
        resolution: this.filterModel.resolution,
        referenceWeek: this.filterModel.referenceWeek,
        referenceWeekChecked: this.filterModel.referenceWeekChecked,
        excludeBusinessHolidays: this.filterModel.excludeBusinessHolidays,
        shift: this.selectedTemplateType.value === 2 ? this.shift : null,
        intervalSize: this.selectedTemplateType.value === 2 ? this.intervalSize : null,
        selectedIntervalGranularity: this.selectedTemplateType.value === 2 ? this.selectedIntervalGranularity : null
      }),
      templateType: 'CHART',
      dynamic: this.selectedTemplateType.value === 2
    };

    if (this.selectedUser && this.selectedUser.id)
      this.viewTemplateService.createOrUpdateViewTemplateForAnotherUser(viewTeplate, this.selectedUser.id).subscribe(() => {
        this.selectedTemplateType = null;
      });
    else
      this.viewTemplateService.createOrUpdateViewTemplate(viewTeplate).subscribe(() => {
        this.refreshViewTemplates();
        this.selectedTemplateType = null;
      });
  }

  loadTemplate() {
    if (this.filterModel)
      this.filterModel.periodParameters = [];
    setTimeout(() => {
      let jsonData = JSON.parse(this.selectedTemplate.jsonData);
      if (jsonData) {
        this.filterModel = this.viewTemplateService.filterModelFromViewTemplate(jsonData, this.selectedTemplate.dynamic);
        this.filterModel.selectedDiagram = this.diagramListOptions.filter(r => r.id === jsonData.selectedDiagram.id)[0];
        this.filterModel.selectedPods = this.podListOptions.filter(r => jsonData.selectedPods.map(pod => pod.id).indexOf(r.id.id) > -1).map(r => r.id);
        this.filterModel.selectedPodGroups = this.podGroupsOptions
          .filter(r => jsonData.selectedPodGroups.map(pod => pod.id).indexOf(r.id.id) > -1)
          .map(r => r.id);
        if (!this.filterModel.referenceWeek)
          this.filterModel.referenceWeek = [new PeriodParameterModel(this.periodInDays, 1, this.filterModel.multiplePeriod, false)];

        this.controlComparison(this.filterModel.selectedDiagram.comaparisonAllowed);
        if (this.filterModel.selectedDiagram.length > 0) {
          this.filterModel.selectedDiagram = this.filterModel.selectedDiagram.filter(r => r.id === jsonData.selectedDiagram.id)[0];
        }
        this.controlTemperature(this.filterModel.selectedDiagram.temperatureAllowed);
      }
    });
  }

  deleteViewTemplate() {
    this.viewTemplateService.deleteViewTemplate(this.selectedTemplate.id).subscribe(() => {
      this.viewTemplates = this.viewTemplates.filter(r => r.id != this.selectedTemplate.id);
      this.selectedTemplate = null;
    });
  }

  shiftBlur() {
    if (this.shift > 0)
      this.shift = 0;
  }

  intervalSizeBlur() {
    if (this.intervalSize < 1)
      this.intervalSize = 1;
  }
}
