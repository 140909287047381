<mat-accordion>
    <div *ngFor="let item of products">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{getItemTitle(item)}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
                <mat-list-item>
                    <mat-icon mat-list-icon aria-hidden="true">
                        {{documentFilterService.getCssForFileExtension(item.productFile.mimeType)}}
                    </mat-icon>
                    <h4 mat-line>
                        <a href="{{item.productFile.urlPath}}" target="_blank">
                            {{getItemDescription(item)}}
                        </a>
                    </h4>
                    <p mat-line>
                        {{'documents.created' | translate}}:
                        {{item.releaseTime | cezDate}}
                    </p>
                    <p mat-line>
                        {{'documents.size' | translate}}:
                        {{item.productFile.sizeBytes | ByteFormatterPipe}}
                    </p>
                    <div *ngIf="adminMode" class="doc-item-buttons">
                        <button *ngIf="!item.visible" color="primary" mat-mini-fab (click)="publishClick.emit(item.id)">
                            <mat-icon>cloud_upload</mat-icon>
                        </button>
                        <button *ngIf="item.visible" color="primary" mat-mini-fab
                                (click)="deactivateClick.emit(item.id)">
                            <mat-icon>cloud_off</mat-icon>
                        </button>
                        <button color="primary" mat-mini-fab (click)="editClick.emit(item)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button color="primary" mat-mini-fab (click)="deleteClick.emit(item.id)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </div>
</mat-accordion>
