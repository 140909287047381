 <ul ngbNav #nav="ngbNav" class="nav-tabs" tabSave="administration.tabs" #tabSave="tabSave">

    <li [ngbNavItem]="'AdminTabs.news'">
        <a ngbNavLink>{{'AdminTabs.news' | translate}}</a>
        <ng-template ngbNavContent>
            <news-admin *ngIf="tabSave.tabLoaded"></news-admin>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.doc'">
        <a ngbNavLink>{{'AdminTabs.doc' | translate}}</a>
        <ng-template ngbNavContent>
            <document-management-admin *ngIf="tabSave.tabLoaded"></document-management-admin>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.user'">
        <a ngbNavLink>{{'AdminTabs.user' | translate}}</a>
        <ng-template ngbNavContent>
            <jhi-user-mgmt *ngIf="tabSave.tabLoaded"></jhi-user-mgmt>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.link'">
        <a ngbNavLink>{{'AdminTabs.link' | translate}}</a>
        <ng-template ngbNavContent>
            <useful-links-admin *ngIf="tabSave.tabLoaded"></useful-links-admin>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.group'">
        <a ngbNavLink>{{'AdminTabs.group' | translate}}</a>
        <ng-template ngbNavContent>
            <role-editor *ngIf="tabSave.tabLoaded"></role-editor>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.tos'">
        <a ngbNavLink>{{'AdminTabs.tos' | translate}}</a>
        <ng-template ngbNavContent>
            <terms-of-service-editor *ngIf="tabSave.tabLoaded"></terms-of-service-editor>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.email'"  *ngIf="'EMAIL_READ' | hasPermission">
        <a ngbNavLink>{{'AdminTabs.email' | translate}}</a>
        <ng-template ngbNavContent>
            <jhi-email *ngIf="tabSave.tabLoaded"></jhi-email>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.scheduler'">
        <a ngbNavLink>{{'AdminTabs.scheduler' | translate}}</a>
        <ng-template ngbNavContent>
            <scheduled-task *ngIf="tabSave.tabLoaded"></scheduled-task>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.agreement'">
        <a ngbNavLink>{{'AdminTabs.agreement' | translate}}</a>
        <ng-template ngbNavContent>
            <contract-admin *ngIf="tabSave.tabLoaded"></contract-admin>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.company'">
        <a ngbNavLink>{{'AdminTabs.company' | translate}}</a>
        <ng-template ngbNavContent>
            <jhi-company *ngIf="tabSave.tabLoaded"></jhi-company>
        </ng-template>
    </li>
   <li [ngbNavItem]="'AdminTabs.pod-maintenance'" *ngIf="'POD_MGMT_SCREEN' | hasPermission">
     <a ngbNavLink>{{'AdminTabs.pod-maintenance' | translate}}</a>
     <ng-template ngbNavContent>
       <app-pod-maintenance *ngIf="tabSave.tabLoaded"></app-pod-maintenance>
     </ng-template>
   </li>
    <li [ngbNavItem]="'AdminTabs.prod'">
        <a ngbNavLink>{{'AdminTabs.prod' | translate}}</a>
        <ng-template ngbNavContent>
            <jhi-product *ngIf="tabSave.tabLoaded"></jhi-product>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.calendar'">
        <a ngbNavLink>{{'AdminTabs.calendar' | translate}}</a>
        <ng-template ngbNavContent>
            <jhi-calendar-admin *ngIf="tabSave.tabLoaded"></jhi-calendar-admin>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.additionalDiagType'" *ngIf="'ADDITIONAL_DIAGRAM_READ' | hasPermission">
        <a ngbNavLink>{{'AdminTabs.additionalDiagType' | translate}}</a>
        <ng-template ngbNavContent>
            <jhi-additional-diag-types *ngIf="tabSave.tabLoaded"></jhi-additional-diag-types>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.invoiceResource'" *ngIf="'INVOICE_RESOURCE_READ' | hasPermission">
        <a ngbNavLink>{{'AdminTabs.invoiceResource' | translate}}</a>
        <ng-template ngbNavContent>
            <jhi-invoice-resource *ngIf="tabSave.tabLoaded"></jhi-invoice-resource>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.fileUpload'" *ngIf="'CUSTOM_FILE_READ' | hasPermission">
        <a ngbNavLink>{{'AdminTabs.fileUpload' | translate}}</a>
        <ng-template ngbNavContent>
            <admin-file-upload *ngIf="tabSave.tabLoaded"></admin-file-upload>
        </ng-template>
    </li>
    <li [ngbNavItem]="'AdminTabs.coverageTransactions'" *ngIf="'HEDGE_TRANSACTIONS_ADMIN' | hasPermission">
        <a ngbNavLink>{{'AdminTabs.coverageTransactions' | translate}}</a>
        <ng-template ngbNavContent>
            <jhi-coverage-transactions-admin *ngIf="tabSave.tabLoaded"></jhi-coverage-transactions-admin>
        </ng-template>
    </li>
</ul>
 <div [ngbNavOutlet]="nav"></div>
