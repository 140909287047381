import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoverageTransactionsComponent} from './coverage-transactions.component';
import {RouterModule} from '@angular/router';
import {COVERAGE_TRANSACTION_ROUTE} from "./coverage-transactions.route";
import {CezPortalSharedModule} from '../shared';
import {CoverageTransactionsService} from './coverage-transactions.service';
import {CountdownModule} from 'ngx-countdown';
import {NgxMaskModule} from 'ngx-mask';
import {ScheduledQuotationDialogComponent} from './scheduled-quotation-dialog/scheduled-quotation-dialog.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {ScheduledQuotationService} from './scheduled-quotation-dialog/scheduled-quotation.service';
import {PinnedCoverageTransactionBarComponent} from './pinned-bar/pinned-coverage-transaction-bar.component';
import {CoverageTransactionsPeriodTabService} from './coverage-transactions-period-tab.service';
import {NewClaimDialogFormStateService} from './offer-claim/new-claim-dialog/new-claim-dialog-form-state.service';
import {OfferClaimService} from './offer-claim.service';
import {CartModule} from "./cart/cart.module";
import {NewClaimDialogComponent} from "./offer-claim/new-claim-dialog/new-claim-dialog.component";
import {
    CoverageTransactionsPriceTrackerModalComponent
} from "./price-monitoring/modal/coverage-transactions-price-tracker-modal.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(COVERAGE_TRANSACTION_ROUTE, { useHash: true }),
        CezPortalSharedModule,
        CountdownModule,
        NgxMaskModule.forRoot(),
        NgxMaterialTimepickerModule,
        CartModule
    ],
    declarations: [
        NewClaimDialogComponent,
        CoverageTransactionsPriceTrackerModalComponent,
        ScheduledQuotationDialogComponent,
        CoverageTransactionsComponent,
        PinnedCoverageTransactionBarComponent
    ],
    providers: [
        CoverageTransactionsService,
        CoverageTransactionsPeriodTabService,
        ScheduledQuotationService,
        NewClaimDialogFormStateService,
        OfferClaimService
    ],
    exports: []
})
export class CoverageTransactionsModule {
}
