import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductType} from '../agreement-details/agreement-enums.model';
import {DeliveryPeriod} from '../dto/delivery.periods';
import {NgBDatePickerConvertService} from '../services/ngb.datepicker.convert.service';

@Component({
    selector: 'jhi-delivery-period-modal',
    templateUrl: './delivery-period-modal.component.html',
    styles: []
})

export class DeliveryPeriodModalComponent implements OnInit {

    @Input() model: DeliveryPeriod;
    @Input() productType: ProductType;
    @Input() goO: boolean;
    @Output() modalClose: EventEmitter<DeliveryPeriod> = new EventEmitter();

    constructor(private dateConverter: NgBDatePickerConvertService, public activeModal: NgbActiveModal) {}

    ngOnInit(): void {
        if (this.model.startTime) {
            this.model.startTimeNgB = this.dateConverter.convertFromDate(this.model.startTime);
            this.model.endTimeNgB = this.dateConverter.convertFromDate(this.model.endTime);
        }
    }

    public save(): void {
        this.model.startTime = this.dateConverter.convertToDate(this.model.startTimeNgB);
        this.model.endTime = this.dateConverter.convertToDate(this.model.endTimeNgB);
        this.modalClose.emit(this.model);
    }

    public blockDecimalMarkers(event: any): void {
        if (event.key === '.' || event.key === ',') {
            event.preventDefault();
        }
    }
}
