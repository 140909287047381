import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {DailyRatesDto} from '../../shared/daily-rates/model/DailyRatesDto';
import {HttpErrorResponse} from "@angular/common/http";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, NgForm} from "@angular/forms";
import * as _ from "lodash";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {DailyRatePreload} from "../../shared/daily-rates/model/DailyRatePreload";
import {FileUploadQueueComponent} from '../../shared/fileupload/matFileUploadQueue/file-upload-queue.component';
import {ExcelImportResult} from '../../shared/time-series/model/excel-import-result.model';
import {CoverageTransactionsAdminService} from '../coverage-transactions.admin.service';
import {DailyPricesService} from '../../shared/services/daily-prices.service';
import {DailyRatesPricesChartBaseComponent} from './daily-rates-prices-chart-base.component';
import {LoadingOverlayService} from '../../shared/services/loading-overlay.service';
import {TimeSeriesService} from '../../shared/time-series/time-series.service';
import {ExcelType} from '../../shared/time-series/model/excel-type.enum';
import {TimeSeriesType} from '../../shared/time-series/model/time-series-type.enum';
import * as moment from 'moment';

import {Moment} from 'moment';
import {Resolution} from '../../shared/time-series/model/resolution.enum';
import {DailyRatesExportDto} from '../../shared/daily-rates/model/DailyRatesExportDto';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {CoverageTransactionsUtil} from '../../coverage-transactions/coverage-transactions.util';
import {OfferPeriod} from '../../shared/offer-claim/model/offer-period.enum';
import {UtilService} from '../../shared/services/util.service';
import {DailyRateType} from '../../shared/daily-rates/model/DailyRatesType.enum';
import {ProductNamePipe} from "../../shared/pipes/product-name.pipe";
import {DeadlineSelectorComponent} from '../../shared/deadline-selector/deadline-selector.component';
import {MeasureUnit} from '../../shared/time-series/time-series-interval/measure-unit.enum';
import {TimeSeriesChartLineType} from '../../shared/time-series/model/chart-type.enum';
import {DailyPriceStateChange} from '../../shared/daily-rates/model/DailyPriceStateChange';

@Component({
    selector: 'jhi-daily-rates',
    templateUrl: './daily-rates.component.html',
    styleUrls: ['./daily-rates.component.scss']
})
export class DailyRatesComponent extends DailyRatesPricesChartBaseComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('hpfcUploadQueue', {static: true})
    public hpfcUploadQueue: FileUploadQueueComponent;

    @ViewChild('dailyPriceUploadQueue', {static: true})
    public dailyPriceUploadQueue: FileUploadQueueComponent;

    @ViewChild('deadlineSelector', {static: true})
    public deadlineSelector: DeadlineSelectorComponent;

    public TimeSeriesChartLineType: typeof TimeSeriesChartLineType = TimeSeriesChartLineType;

    public dailyRates: DailyRatesDto[] = [];
    public preload: DailyRatePreload;

    public dailyRateExportForm: UntypedFormGroup;
    public dailyRateExport: DailyRatesExportDto[] = [];
    public filteredDailyRateExport: DailyRatesExportDto[] = [];
    public dailyRateExportControl: UntypedFormControl = new UntypedFormControl();
    public dailyRateExportMinDate: Moment;
    public dailyRateExportMaxDate: Moment;
    public isFirmOffer: boolean = false;
    public isActiveDailyPrices: boolean;
    public deadline: string;
    public pricePublishButtonActive: boolean = true;

    private destroy: Subject<void> = new Subject();

    constructor(private snackBar: MatSnackBar,
                private coverageTransactionsAdminService: CoverageTransactionsAdminService,
                private dailyPricesService: DailyPricesService,
                private translate: TranslateService,
                private loadingOverlay: LoadingOverlayService,
                private timeSeriesService: TimeSeriesService,
                private formBuilder: UntypedFormBuilder,
                public utilService: UtilService,
                private productNamePipe: ProductNamePipe) {
        super(coverageTransactionsAdminService, loadingOverlay, productNamePipe, true);
    }

    ngOnInit(): void {
        this.preloadConfig();
        this.loadDailyRates();
        this.loadDailyRatesExportData();
        this.initFormGroup();
        this.setDailyPricesState();
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    ngAfterViewInit(): void {
      // console.log('ngAfterViewInit will init chart');
      super.init();
    }

    public preloadConfig(): void {
        this.coverageTransactionsAdminService.getDailyRatesPreloadConfig().subscribe((data: DailyRatePreload): void => {
            this.preload = data;
        });
    }

    public loadDailyRates() {
        setTimeout(() => this.loadingOverlay.turnOn());
        this.coverageTransactionsAdminService.getDailyRates().subscribe((data: DailyRatesDto[]) => {
            this.dailyRates = data.map((rate: DailyRatesDto) => {
                rate.currentPrice = null;
                rate.withoutPrice = false;
                return rate;
            });
            this.loadingOverlay.turnOff();
        }, () => this.loadingOverlay.turnOff());
    }

    public onKeyDown(event: any) {
        // not allow negative prices
        if (event.key === '-' || (this.translate.currentLang === 'hu' && event.key === '.')) {
            event.preventDefault();
        }
    }

    public isPublishDisabled(rates: DailyRatesDto[], form: NgForm): boolean {
        if (!!this.preload && !this.preload.editEnabled || !this.deadline || this.isActiveDailyPrices || !this.pricePublishButtonActive) {
            return true;
        }

        rates = rates.filter(rate => !rate.withoutPrice);
        return !!_.find(rates, ['currentPrice', null]) || !!_.find(rates, ['currentPrice', '']);
    }

    public onSubmit(rates: DailyRatesDto[], form: NgForm) {
        const type: DailyRateType = this.isFirmOffer ? DailyRateType.FIRM : DailyRateType.INDICATIVE;
        const deadline: Moment = moment()
            .add(this.deadline.slice(0, 2), 'minute')
            .add(this.deadline.slice(2, 4), 'second');
        this.pricePublishButtonActive = false;
        this.coverageTransactionsAdminService.saveDailyRates(rates, type, deadline).subscribe(() => {
            this.successSnackBar(this.translate.instant('common.result.saveSuccess'));
            this.loadDailyRates();
            this.filterChart(this.queryInterval);
            this.deadlineSelector.reset();
            this.pricePublishButtonActive = true;
            this.isFirmOffer = false;
        }, () => this.pricePublishButtonActive = true);
    }

    public withdrawalDailyPrices(): void {
        this.coverageTransactionsAdminService.withdrawalDailyPrices().subscribe();
    }

    public onDailyRateUpload(event: {
        file: File,
        success: boolean,
        response?: ExcelImportResult,
        error?: HttpErrorResponse
    }) {
        this.onUpload(event);
        this.loadDailyRates();
        this.filterChart();
    }

    public onUpload(event: { file: File, success: boolean, response?: ExcelImportResult, error?: HttpErrorResponse }) {
        if (event.error) {
            return;
        }
        this.successSnackBar(this.translate.instant('common.result.saveSuccess'));
    }

    public successSnackBar(message: string, action?: string) {
        this.snackBar.open(message, action, {duration: 3000});
    }

    public exportDisabled(): boolean {
        return !this.dailyRateExportControl.value ||
            !this.dailyRateExportForm.get('exportInterval').value ||
            !this.dailyRateExportForm.get('exportInterval').value.start ||
            !this.dailyRateExportForm.get('exportInterval').value.end ||
            !this.filteredDailyRateExport.filter((rate: DailyRatesExportDto) => rate.name === this.dailyRateExportControl.value)[0];
    }

    public exportTemplate(): void {
        this.timeSeriesService.export(
            ExcelType.VECTOR,
            TimeSeriesType.DAILY_RATE_PRICE,
            this.dailyRateExportForm.get('exportInterval').value,
            false,
            {
                product_name: this.dailyRateExportControl.value,
                product_type: CoverageTransactionsUtil.determineOfferPeriod(this.dailyRateExportControl.value) || OfferPeriod.Y
            }
        );
    }

    public exportTimeSeries(): void {
        this.timeSeriesService.export(
            ExcelType.VECTOR,
            TimeSeriesType.DAILY_RATE_PRICE,
            this.dailyRateExportForm.get('exportInterval').value,
            true,
            {
                product_name: this.dailyRateExportControl.value,
                product_type: CoverageTransactionsUtil.determineOfferPeriod(this.dailyRateExportControl.value) || OfferPeriod.Y,
                measureUnit: MeasureUnit.MW
            }
        );
    }

    public setDeadline(deadline: string): void {
        this.deadline = deadline;
    }

    private initFormGroup(): void {
        this.dailyRateExportForm = this.formBuilder.group({
            exportInterval: {
                start: moment().startOf('day').subtract(1, 'day'),
                end: moment().startOf('day'),
                resolution: Resolution.DAILY
            }
        });
    }

    private loadDailyRatesExportData(): void {
        this.coverageTransactionsAdminService.getAllDailyRateHeadForExport().subscribe((rates: DailyRatesExportDto[]) => {
            this.dailyRateExport = rates.sort((prev: DailyRatesExportDto, curr: DailyRatesExportDto) =>
                this.dailyRateNameComparator(prev.name, curr.name));
            this.filteredDailyRateExport = this.dailyRateExport;
            this.dailyRateExportControlChanges();
        });
    }

    private dailyRateExportControlChanges(): void {
        this.dailyRateExportControl.valueChanges.pipe(takeUntil(this.destroy)).subscribe((dailyRateName: string) => {
            this.filteredDailyRateExport = this.dailyRateExport
                .filter((rate: DailyRatesExportDto) => rate.name.includes(dailyRateName))
                .sort((prev: DailyRatesExportDto, curr: DailyRatesExportDto) => this.dailyRateNameComparator(prev.name, curr.name));

            const dailyRate: DailyRatesExportDto | undefined = this.filteredDailyRateExport.filter((rate: DailyRatesExportDto) => rate.name === dailyRateName)[0];
            if (dailyRate) {
                this.dailyRateExportMinDate = moment(dailyRate.availableFrom);
                this.dailyRateExportMaxDate = moment(dailyRate.availableTo).subtract(1, 'day');
                this.dailyRateExportForm.get('exportInterval').patchValue({
                    start: moment(dailyRate.availableFrom),
                    end: moment(dailyRate.availableTo),
                    resolution: Resolution.DAILY
                });
            }
        });
    }

    private dailyRateNameComparator(prev: string, curr: string): number {
        const prevYear: number = CoverageTransactionsUtil.determineYear(prev);
        const currYear: number = CoverageTransactionsUtil.determineYear(curr);
        const prevPeriod: OfferPeriod = CoverageTransactionsUtil.determineOfferPeriod(prev) || null;
        const currPeriod: OfferPeriod = CoverageTransactionsUtil.determineOfferPeriod(curr) || null;
        const prevPeriodNumber: number = CoverageTransactionsUtil.determinePeriodNumber(prev) || null;
        const currPeriodNumber: number = CoverageTransactionsUtil.determinePeriodNumber(curr) || null;
        const periodNumberComparator: number = prevYear === currYear ? prevPeriodNumber - currPeriodNumber : prevYear - currYear;

        if (prevPeriod === OfferPeriod.W) {
            if (currPeriod === OfferPeriod.M || currPeriod === OfferPeriod.Q || currPeriod === null) {
                return -1;
            }
            return periodNumberComparator;
        }

        if (prevPeriod === OfferPeriod.M) {
            if (currPeriod === OfferPeriod.W) {
                return 1;
            }
            if (currPeriod === OfferPeriod.Q || currPeriod === null) {
                return -1;
            }
            return periodNumberComparator;
        }

        if (prevPeriod === OfferPeriod.Q) {
            if (currPeriod === OfferPeriod.W || currPeriod === OfferPeriod.M) {
                return 1;
            }
            if (currPeriod === null) {
                return -1;
            }
            return periodNumberComparator;
        }

        if (prevPeriod === null) {
            if (currPeriod === OfferPeriod.W || currPeriod === OfferPeriod.M || currPeriod === OfferPeriod.Q) {
                return 1;
            }
            return prevYear - currYear;
        }

        return -1;
    }

    public getRowWithoutPriceState(rowData: DailyRatesDto): boolean {
        if (!this.preload || !this.preload.editEnabled) {
            return false;
        }

        return rowData.withoutPrice;
    }

    displayProductName(name: string) {
        if (name && name.length && name.length > 0) {
            return this.productNamePipe.transform(name);
        }
        return name;
    }

    private getDailyPricesPublicationType(): void {
        this.coverageTransactionsAdminService.getDailyPricesPublicationType().subscribe((data: DailyRateType) => {
            this.isFirmOffer = data === DailyRateType.FIRM;
        });
    }

    private setDailyPricesState(): void {
        this.dailyPricesService.dailyPricesStateChange.pipe(takeUntil(this.destroy)).subscribe((change: DailyPriceStateChange): void => {
            this.isActiveDailyPrices = change.type != null;
        });
    }
}
