import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {ToleranceThresholdComponent} from './tolerance-threshold.component';
import {CezPortalSharedModule} from '../shared';

@NgModule({
    imports: [
        CommonModule,
        CezPortalSharedModule
    ],
    declarations: [
        ToleranceThresholdComponent
    ],
    exports: [
        ToleranceThresholdComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ToleranceThresholdModule {
}
