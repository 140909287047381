<mat-card class="over-flow-scrollbar cez-mat-card">

    <div class="d-flex">
        <div>{{ 'marketPrices.marketproducts.title' | translate }}</div>
        <mat-slide-toggle color="primary" [(ngModel)]="compactSelected" class="ml-auto">
            {{'marketPrices.marketproducts.compact' |
            translate}}
        </mat-slide-toggle>
    </div>

    <mat-card-content
        [ngClass]="compactSelected ? 'min-width-market-products-compact': 'min-width-market-products'"
        class="min-height-300">
        <p-table #dt [value]="products"
                 [scrollable]="true" scrollHeight="300px"
                 (sortFunction)="customSort($event)"
                 [customSort]="true"
                 sortMode="multiple"
                 [multiSortMeta]="compactSelected ? [{field: 'year', order: 1},{field: 'intervalType', order: 1},{field: 'productType', order: 1}] : [{field: 'year', order: 1},{field: 'intervalType', order: 1},{field: 'productType', order: 1}]"
                 class="table table-striped">
            <ng-template pTemplate="header">
                <tr style="background-color: white; border-bottom: 2px solid #dee2e6; border-top: 2px solid #dee2e6;">    <!--   d-->
                    <th [pSortableColumn]="'year'" class="market-product-year-column-width" *ngIf="!compactSelected">
                        <span class="no-linebreak">
                            {{'marketPrices.marketproducts.header.year' | translate}}
                            <p-sortIcon [field]="'year'"></p-sortIcon>
                        </span>
                    </th>
                    <th *ngIf="!compactSelected" [pSortableColumn]="'productType'"
                        class="market-product-type-column-width">
                        <span class="no-linebreak">
                            {{'marketPrices.marketproducts.header.productType' | translate}}
                            <p-sortIcon [field]="'productType'"></p-sortIcon>
                        </span>
                    </th>
                    <th *ngIf="!compactSelected" [pSortableColumn]="'intervalType'"
                        class="market-product-interval-column-width">
                        <span class="no-linebreak">
                            {{'marketPrices.marketproducts.header.intervalType' | translate}}
                            <p-sortIcon [field]="'intervalType'"></p-sortIcon>
                        </span>
                    </th>
                    <th *ngIf="!compactSelected" [pSortableColumn]="'marketName'"
                        class="market-product-marketname-column-width">
                        <span class="no-linebreak">
                            {{'marketPrices.marketproducts.header.marketName' | translate}}
                            <p-sortIcon [field]="'marketName'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'productName'" class="product-name-column-min-width">
                        <span class="no-linebreak">
                            {{'marketPrices.marketproducts.header.productName' | translate}}
                            <p-sortIcon [field]="'productName'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'selected'" class="market-product-display-column-width">
                        <span class="no-linebreak">
                            {{'marketPrices.marketproducts.header.selected' | translate}}
                            <p-sortIcon [field]="'selected'"></p-sortIcon>
                        </span>
                    </th>
                    <th [pSortableColumn]="'spotInDelivery'" class="market-product-spot-column-width">
                        <span class="no-linebreak">
                            {{'marketPrices.marketproducts.header.spotInDelivery' | translate}}
                        </span>
                    </th>
                    <th class="warning-column"></th>
                </tr>

                <tr style="background-color: white; border-bottom: 2px solid #dee2e6; border-top: 2px solid #dee2e6;">
                    <th *ngIf="!compactSelected">
                        <mat-form-field class="short-field">
                            <input matInput placeholder="{{'global.search' | translate}}"
                                   (input)="dt.filter($event.target.value, 'year', 'intervalfilter')">
                        </mat-form-field>
                    </th>
                    <th *ngIf="!compactSelected">
                        <mat-form-field class="short-field">
                            <input matInput placeholder="{{'global.search' | translate}}"
                                   (input)="dt.filter($event.target.value, 'productType', 'contains')">
                        </mat-form-field>
                    </th>
                    <th *ngIf="!compactSelected">
                        <mat-form-field class="short-field">
                            <mat-select
                                (selectionChange)="dt.filter($event.value, 'intervalType', 'equals')"
                                placeholder="{{'global.choose' | translate}}">
                                <mat-option>{{'multiSelectText.checkAll' | translate}}</mat-option>
                                <mat-option *ngFor="let intervalTypeOption of intervalTypeOptions"
                                            [value]="intervalTypeOption.value">
                                    {{intervalTypeOption.label | translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </th>
                    <th *ngIf="!compactSelected">
                        <mat-form-field class="short-field">
                            <input matInput placeholder="{{'global.search' | translate}}"
                                   (input)="dt.filter($event.target.value, 'marketName', 'contains')">
                        </mat-form-field>
                    </th>
                    <th>
                        <mat-form-field class="short-field">
                            <input matInput placeholder="{{'global.search' | translate}}"
                                   (input)="dt.filter($event.target.value, 'productName', 'smartTextFilter')">
                        </mat-form-field>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>

            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <td class="market-product-year-column-width" *ngIf="!compactSelected">{{rowData.year}}</td>
                    <td class="market-product-type-column-width" *ngIf="!compactSelected">{{rowData.productType}}</td>
                    <td class="market-product-interval-column-width"
                        *ngIf="!compactSelected">{{'IntervalType.' + rowData.intervalType | translate}}</td>
                    <td class="market-product-marketname-column-width"
                        *ngIf="!compactSelected">{{rowData.marketName}}</td>
                    <td class="product-name-column-min-width">{{rowData.productName}}</td>
                    <td class="market-product-display-column-width">
                        <p-checkbox [ngModel]="rowData.selected" [disabled]="!rowData.hasPermission"
                                      *permissionOverlay="'';disabled:rowData.hasPermission;class:'permission-overlay-checkbox'"
                                      (ngModelChange)="selectionChanged($event, rowData)" binary="true" />
                    </td>
                    <td class="market-product-spot-column-width">
                        <ng-container *ngIf="rowData.isForward">
                            <p-checkbox [ngModel]="rowData.spotInDelivery"
                                          [disabled]="!rowData.hasSidPermission"
                                          *permissionOverlay="'';disabled:rowData.hasSidPermission;class:'permission-overlay-checkbox'"
                                          (ngModelChange)="spotInDeliveryChanged($event, rowData)" binary="true" />
                        </ng-container>
                    </td>
                    <td class="warning-column">
                        <mat-icon *ngIf="rowData.spotInDelivery && rowData.spotInDeliveryError"
                                  color="warn"
                                  class="warning-icon"
                                  [matTooltip]="('validation.' + rowData.spotInDeliveryError) | translate"
                                  [matTooltipClass]="'largeFont'"
                                  [matTooltipPosition]="'right'">warning
                        </mat-icon>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </mat-card-content>
</mat-card>
