import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from '../dialog/dialog.service';
import {CompanyModel} from '../dto/company.model';
import {MultiSelectTranslationService} from '../services/multi-select-translation.service';
import {PartnerService} from '../services/partner.service';
import {CanLeave, CanLeaveService} from "../can-leave/can-leave.service";
import {Observable} from "rxjs";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {NavitaMultiSelectOption} from "../dto/common.model";
import {DEFAULT_DROPDOWN_SETTINGS} from "../../core/util/constants";

@Component({
  selector: 'jhi-partner-details',
  templateUrl: './partner-details.component.html',
  styles: [],
  exportAs: 'partnerDetails'
})
export class PartnerDetailsComponent implements OnInit, CanLeave {

  @Input() controlsState: { [name: string]: boolean } = {};
  @Output() controlsStateChange: EventEmitter<{ [name: string]: boolean }> = new EventEmitter();
  @Input() userView: boolean;
  @Input() companyModel: CompanyModel;
  @Input() isFormDisabled: boolean = false;
  @Output() companyModelChange: EventEmitter<CompanyModel> = new EventEmitter();
  @ViewChild('partnerForm', {static: true}) public form: NgForm;


  public dropdownSettings: IDropdownSettings = {
    ...DEFAULT_DROPDOWN_SETTINGS,
    ...this.multiSelectTranslationService.getMultiSelectTexts()
  };

  possibleSubsidiaryOptions: NavitaMultiSelectOption[] = [];

  subsidiaryOptionsModel: NavitaMultiSelectOption[] = [];
  all: CompanyModel[] = [];

  // hotfix for `ss-multiselect-dropdown` component which trows a `modelChange` event on init
  private subsidiariesChangeEventFlag: boolean = false;
  private lastPaymentIndexChangeEventFlag: boolean = false;
  private lastDBFailureScoreChangeEventFlag: boolean = false;

  constructor(private partnerService: PartnerService,
              private dialogService: DialogService,
              private translate: TranslateService,
              private multiSelectTranslationService: MultiSelectTranslationService,
              private canLeaveService: CanLeaveService) {
  }

  ngOnInit() {
    if (this.companyModel.id) {
      if (this.companyModel.navisionIds && this.companyModel.navisionIds.length > 0) {
        this.companyModel.navisionId = this.companyModel.navisionIds[0].navisionId;
      }

      this.partnerService.getSubsidiaryCandidates(this.companyModel.id).subscribe(r => {
        this.all = this.all.concat([]).concat(r);
        let unselectedCandidates = r.map(cm => {
          return {id: cm, name: cm.accountName};
        });
        this.possibleSubsidiaryOptions = unselectedCandidates.concat(this.companyModel.subsidiaries.map(cm => {
          this.all = this.all.concat(cm);
          return {id: cm, name: cm.accountName};
        }));
      });
      this.subsidiaryOptionsModel = this.companyModel.subsidiaries.map(cm => {
        return {id: cm, name: cm.accountName};
      });

    }
  }

  canLeave(): Observable<boolean> {
    return this.canLeaveService.canLeave();
  }

  isDisabled(controlName): boolean {
    return this.userView && this.controlsState[controlName] !== true;
  }

  enable(controlName) {
    this.controlsState[controlName] = true;
  }

  showAllNavisionId() {
    const navisionIdHistory = this.companyModel.navisionIds.map(navision => navision.navisionId).join(', ');
    this.dialogService.alert(this.translate.instant('company.navisionIdHistory'), navisionIdHistory);
  }

  onChange(): void {
    this.canLeaveService.setModified({allowedTabsets: ['agreement-details']});
  }



  onSelectSubsidiariesChange(): void {
    console.log('onSelectSubsidiariesChange');
    var ids: number[] = this.subsidiaryOptionsModel.map(a => a.id.id);
    this.companyModel.subsidiaries = [].concat(this.all).filter(r => ids.includes(r.id))
    if (this.subsidiariesChangeEventFlag) {
      this.onChange();
    } else {
      this.subsidiariesChangeEventFlag = true;
    }
  }  /**
   * hotfix for `ss-multiselect-dropdown` component which trows a `modelChange` event on init
   */
  onSelectAllSubsidiariesChange(): void {
    console.log('onSelectAllSubsidiariesChange');
    this.companyModel.subsidiaries = [].concat(this.all)
    if (this.subsidiariesChangeEventFlag) {
      this.onChange();
    } else {
      this.subsidiariesChangeEventFlag = true;
    }
  }

  public onDeselectAllSubsidiariesChange(): void {
    console.log('onDeselectAllSubsidiariesChange');

    this.companyModel.subsidiaries = []
    if (this.subsidiariesChangeEventFlag) {
      this.onChange();
    } else {
      this.subsidiariesChangeEventFlag = true;
    }
  }

  onLastPaymentIndexChange(): void {
    if (this.lastPaymentIndexChangeEventFlag) {
      this.onChange();
    } else {
      this.lastPaymentIndexChangeEventFlag = true;
    }
  }

  onLastDBFailureChange(): void {
    if (this.lastDBFailureScoreChangeEventFlag) {
      this.onChange();
    } else {
      this.lastDBFailureScoreChangeEventFlag = true;
    }
  }
}
