import {LocalizedTexts} from "../../../shared";

export class Template {
    key: string;
    templateName: string;
    mailBcc: string;
    mailCc: string;
    mailFrom: string;
    mailTo: string;
    mailToEditable: boolean;
    message: LocalizedTexts;
    subject: LocalizedTexts;
    editMode: boolean;
}
