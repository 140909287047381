<div class="modal-header">
    <h4 *ngIf="!saveAsNew" class="modal-title" jhiTranslate="common.action.save">Mentés</h4>
    <h4 *ngIf="saveAsNew" class="modal-title" jhiTranslate="common.action.saveAsNew">Mentés Újként</h4>
    <button type="button" class="close" aria-label="Close" (click)="this.activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form name="saveForm" role="form" #saveForm="ngForm" (ngSubmit)="saveTemplate()">
    <div class="modal-body">
        <div class="row mb-3">
            <mat-form-field class="col-sm-6">
                <mat-label jhiTranslate="filter.basic.type"></mat-label>
                <mat-select required name="selectedTemplateType"
                            [(ngModel)]="selectedTemplateType">
                    <ng-container *ngFor="let type of templateOptions">
                        <mat-option [value]="type">
                            {{type.name | translate}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
        <div *ngIf="selectedTemplateType?.value === 1 || selectedTemplateType?.value === 2">
            <div class="row mb-3" style="margin-top: 5px;">
                <div [ngClass]="{'col-sm-6' : currentUserAdmin,'col-sm-12' : !currentUserAdmin}">
                    <mat-form-field class="w-100">
                        <mat-label *ngIf="selectedTemplateType.value===2" for="newViewTemplateName"
                                   jhiTranslate="filter.basic.templateName"></mat-label>
                        <mat-label *ngIf="selectedTemplateType.value===1" for="newViewTemplateName"
                                   jhiTranslate="filter.basic.chartName"></mat-label>
                        <input matInput required [(ngModel)]="newViewTemplateName"
                               name="newViewTemplateName">
                    </mat-form-field>
                </div>
                <div class="col-sm-6" *ngIf="currentUserAdmin">
                    <mat-form-field class="w-100">
                        <mat-label jhiTranslate="userManagement.detail.title"></mat-label>
                        <mat-select required name="selectedUser"
                                    [compareWith]="userSelectCompareWith"
                                    [(ngModel)]="selectedUser">
                            <mat-option [value]="{id:null}" jhiTranslate="filter.basic.me"></mat-option>
                            <ng-container *ngFor="let user of users">
                                <mat-option [value]="user">
                                    {{user.login}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-3" *ngIf="selectedTemplateType.value===2">
                <div class="form-group" class="col-sm-6">
                    <mat-form-field class="w-100">
                        <mat-label jhiTranslate="filter.basic.intervalGranularity"></mat-label>
                        <mat-select name="selectedIntervalGranularity" required
                                    [(ngModel)]="selectedIntervalGranularity">
                            <mat-option [value]="0">
                                <span jhiTranslate="filter.basic.dynamicDataDay"></span>
                            </mat-option>
                            <mat-option [value]="1">
                                <span jhiTranslate="filter.basic.dynamicDataWeek"></span>
                            </mat-option>
                            <mat-option [value]="2">
                                <span jhiTranslate="filter.basic.dynamicDataMonth"></span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="form-group" class="col-sm-6">
                    <mat-form-field class="w-100">
                        <mat-label jhiTranslate="filter.basic.intervalMeasure"></mat-label>
                        <input matInput required [(ngModel)]="intervalSize" name="intervalSize" type="number" min="1"
                               (change)="intervalSizeBlur()">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mb-3" *ngIf="selectedTemplateType.value===2">
                <div class="col-sm-6">
                    <mat-form-field class="w-100">
                        <mat-label jhiTranslate="filter.basic.shift"></mat-label>
                        <input matInput required [(ngModel)]="shift" name="shift" type="number" max="0"
                               (change)="shiftBlur()">
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button mat-raised-button type="button"
                (click)="cancelSaveForm()"
                class="cez-color--dark"
                jhiTranslate="entity.action.cancel"></button>
        <button mat-raised-button  color="primary" type="submit"
                [disabled]="saveForm.form.invalid"
                jhiTranslate="entity.action.save"></button>
    </div>
</form>
