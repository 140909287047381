<div class="row text-center">
    <div class="col-12 col-sm-6 mat-date-selector">
        <day-selector [(selectedDate)]="dateFrom"
                      [placeholder]="order | formatOrder:'day-selector.placeholder-from'"></day-selector>
    </div>

    <div class="col-12 col-sm-6 mat-date-selector">
        <day-selector [(selectedDate)]="dateTo"
                      [placeholder]="order | formatOrder:'day-selector.placeholder-to'"></day-selector>
    </div>
</div>
