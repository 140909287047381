import {
    ClosingProductType,
    Language,
    ProductType,
    TariffType,
    ToleranceSettlementType
} from '../agreement-details/agreement-enums.model';
import {CompanyModel} from './company.model';
import {ContractModel} from './contract.model';
import {DeliveryPeriod} from './delivery.periods';
import {PeriodicalPodModel} from './periodical.pod.model';
import {AggreementServicesModel} from './product.model';
import {TolerancePeriod} from './tolerance.limits';


export class AgreementModel {
    guarantee: boolean = false;
    guaranteeAmount: number;
    contractLanguage: Language;
    quoteNumber: string;
    contractedQuantityMWh: number;
    startTime: Date;
    endTime: Date;
    startTimeNgB: any;
    endTimeNgB: any;
    salesforceQuoteId: string;
    toleranceGroupSettlement: boolean = false;
    toleranceGroupMembers: CompanyModel[];
    toleranceSettlement: ToleranceSettlementType;
    toleranceBandDownPercent: number;
    toleranceBandUpPercent: number;
    id: number;
    normativeConsumptionMWh: number;
    partner: CompanyModel;
    prepayment: boolean = false;
    paymentTerm: string;
    services: AggreementServicesModel = new AggreementServicesModel();
    productType: ProductType;
    tolerancePeriods: TolerancePeriod[];
    deliveryPeriods: DeliveryPeriod[];
    tariffType: TariffType;
    contracts: ContractModel[];
    pods: PeriodicalPodModel[];
    hudexDAccess: boolean = false;
    hudexDOneMarketAccess: boolean = false;
    averagingOtc: boolean = false;
    averagingHudex: boolean = false;
    hudexAccessPriceUplift: number;
    yearlyProducts: boolean = false;
    quarterlyProducts: boolean = false;
    monthlyProducts: boolean = false;
    weeklyProducts: boolean = false;
    hupxSpotAccess: boolean = false;
    maxTotalHedgeCapacity: number;
    maxTransactionSizeOnHudex: number;
    minClickSizeOnHudex: number;
    minClickSizeOnOtc: number;
    clicks: number;
    clickModification: boolean = false;
    clickFlexibilityUp: number;
    clickFlexibilityDown: number;
    maxTransactionSizeOnHudexTranche: number;
    automaticClosingProduct: ClosingProductType;
    thirdPartyDeliveryAllowed: boolean = false;
    accountOwner: string;
    backupKam: string;
    electricityInvoicedIn: string;
    futuresMarketAccess: string;
    rhdInvoicedIn: string;
    rhdInvoicing: string;
    taxesInvoicedIn: string;
    spotSellbackFee: number;
    hedgeLimit: number | string;
}
