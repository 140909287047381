export type HistoricalData =
    'HISTORICAL_DATA_INF'
    | 'HISTORICAL_DATA_25M'
    | 'HISTORICAL_DATA_16M'
    | 'HISTORICAL_DATA_13M'
    | 'HISTORICAL_DATA_6M'
    | 'HISTORICAL_DATA_3M';

export const HistoricalData = {
    HISTORICAL_DATA_INF: 'HISTORICAL_DATA_INF' as HistoricalData,
    HISTORICAL_DATA_25M: 'HISTORICAL_DATA_25M' as HistoricalData,
    HISTORICAL_DATA_16M: 'HISTORICAL_DATA_16M' as HistoricalData,
    HISTORICAL_DATA_13M: 'HISTORICAL_DATA_13M' as HistoricalData,
    HISTORICAL_DATA_6M: 'HISTORICAL_DATA_6M' as HistoricalData,
    HISTORICAL_DATA_3M: 'HISTORICAL_DATA_3M' as HistoricalData
};
