import { Directive } from '@angular/core';
import { UntypedFormGroup, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[passwordMatch]',
    providers: [{provide: NG_VALIDATORS, useExisting: PasswordMatchValidatorDirective, multi: true}]
})
export class PasswordMatchValidatorDirective implements Validator {

    validate(control: UntypedFormGroup): {[key: string]: any} | null {
        if (control.dirty && control.value.password !== control.value.confirmPassword) {
            return {'passwordMismatch': true};
         }

        return null;
    }
}
