import {ProductType, Scheduling} from '../agreement-details/agreement-enums.model';

export class DeliveryPeriodDto {
    id: number;
    agreementId: number;
    startTime: Date;
    endTime: Date;
    productType: ProductType;
    clicks: number;
    contractedQuantityMWh: number;
    serviceFee: number;
    invoicePriceElement: number;
    hedgeLimit: number;
    scheduling: Scheduling;
}
