import { HistoricalData } from './historical-data.enum';
import { Resolution } from '../time-series/model/resolution.enum';
import * as moment from 'moment-timezone';
import { Moment } from 'moment-timezone';


export class HistoricalDataUtil {


    public static getHistoricalMin(currentPermissions: string[], resolution: Resolution): Moment {
        const historicalPermission = Object.keys(HistoricalData)
            .map(key => HistoricalData[key])
            .find(value => currentPermissions.indexOf(value) > -1);

        const now: Moment = moment.tz('Europe/Budapest');

        switch (historicalPermission) {
            case 'HISTORICAL_DATA_INF':
                return null;
            case 'HISTORICAL_DATA_25M':
                now.subtract(25, 'months');
                break;
            case 'HISTORICAL_DATA_16M':
                now.subtract(16, 'months');
                break;
            case 'HISTORICAL_DATA_13M':
                now.subtract(13, 'months');
                break;
            case 'HISTORICAL_DATA_6M':
                now.subtract(6, 'months');
                break;
            case 'HISTORICAL_DATA_3M':
                now.subtract(3, 'months');
                break;
        }

        if (resolution == null) {
            return now;
        }

        switch (resolution) {
            case 'FIVE_MINUTELY':
            case 'QUARTER_HOURLY':
            case 'HOURLY':
            case 'DAILY':
            case 'WEEKLY':
                return now.startOf('day');
            case 'MONTHLY':
                return now.startOf('month');
            case 'QUARTER_YEARLY':
                return now.startOf('quarter');
            case 'YEARLY':
                return now.startOf('year');
            default:
                throw Error('Unknown resolution: ' + resolution);
        }
    }
}
