import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {StateStorageService} from '../auth/state-storage.service';
import {LocatorService} from '../services/locator.service';

@Pipe({
    name: 'cezDate'
})
export class CezDatePipe implements PipeTransform {

    transform(value: any): any {
        let langKey = this.getLangKey();
        let datePipe = new DatePipe('en-US');
        return datePipe.transform(value, this.getMomentFormat(langKey));
    }

    transformWithPattern(value: any, pattern: string): any {
        let datePipe = new DatePipe('en-US');
        return datePipe.transform(value, pattern);
    }

    getMomentFormat(langKey: string): string {
        if (langKey === 'hu')
            return 'yyyy.M.d.';

        return 'd/M/yyyy';
    }

    getLangKey() {
        let stateStorageService = <StateStorageService>LocatorService.injector.get(StateStorageService);
        let langKey = stateStorageService.getLanguage();

        return langKey;
    }
}
