import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StateStorageService } from '../auth/state-storage.service';
import { PodGroupCategoryModel } from '../dto/pod-group-category.model';
import { PodGroupModel } from '../dto/pod-group.model';
import { PodModel } from '../dto/pod.model';
import {NavitaMultiSelectOption} from "../dto/common.model";

@Injectable()
export class PodService {

    constructor(private http: HttpClient,
                private stateStorageService: StateStorageService,
                public translateService: TranslateService) {
    }

    getAllPod(): Observable<PodModel[]> {
        let partnerId = this.stateStorageService.getSelectedCompanyId();

        return this.http.get<PodModel[]>('api/pod/ever_owned_by/partner/' + partnerId);
    }

    calculateMefForPods(pods: PodModel[]): Observable<PodModel[]> {
        return this.http.post<PodModel[]>('api/pod/mef', pods);
    }

    getAllPodForGroup(podGroupCategoryId: number): Observable<PodModel[]> {
        return this.http.get<PodModel[]>(`api/pod/available_to/group_category/${podGroupCategoryId}`);
    }

    getPodGroupsOfPartner(): Observable<PodGroupModel[]> {
        let partnerId = this.stateStorageService.getSelectedCompanyId();
        return this.http.get<PodGroupModel[]>('api/pod/group/partner/' + partnerId);
    }

    getPodGroupCategories(): Observable<PodGroupCategoryModel[]> {
        let partnerId = this.stateStorageService.getSelectedCompanyId();
        return this.http.get<PodGroupCategoryModel[]>('api/pod/group_category/partner/' + partnerId);
    }

    addPodGroupCategory(podGroupCategoryModel: PodGroupCategoryModel): Observable<PodGroupCategoryModel> {
        let partnerId = this.stateStorageService.getSelectedCompanyId();
        return this.http.post<PodGroupCategoryModel>('api/pod/group_category/partner/' + partnerId, podGroupCategoryModel);
    }

    deletePodGroupCategory(podGroupCategoryId: number): Observable<PodGroupCategoryModel> {
        let partnerId = this.stateStorageService.getSelectedCompanyId();
        return this.http.delete<PodGroupCategoryModel>('api/pod/group_category/' + podGroupCategoryId);
    }

    autoCategorizePodGroups(podGroupCategoryId: number, autoCategoryType: string): Observable<PodGroupCategoryModel> {
        const params = new HttpParams().set('delete_existing_groups', 'true');
        return this.http.post<PodGroupCategoryModel>(
            `api/pod/group_category/${podGroupCategoryId}/auto_categorize/${autoCategoryType}`,
            null,
            {params}
        );
    }

    autoCategoryPodGroupRefresh(podGroupCategoryId: number): Observable<PodGroupCategoryModel> {
        return this.http.post<PodGroupCategoryModel>(`api/pod/group_category/${podGroupCategoryId}/auto_categorized_refresh`, null);
    }

    createOrUpdatePodGroupsOfPartner(group: PodGroupModel): Observable<PodGroupModel> {
        let partnerId = this.stateStorageService.getSelectedCompanyId();

        if (group.id) {
            return this.http.put<PodGroupModel>('api/pod/group/partner/' + partnerId, group);
        } else {
            return this.http.post<PodGroupModel>('api/pod/group/partner/' + partnerId, group);
        }
    }

    deletePodGroup(groupid: number): Observable<void> {
        return this.http.delete<void>('/api/pod/group/' + groupid);
    }

    getAllPodMultiSelect(): Observable<NavitaMultiSelectOption[]> {
        return this.getAllPod().pipe(map(r => {

            let podListOptions: NavitaMultiSelectOption[] = r.map(pod => {
                return { id: pod.id, name: pod.podCode };
            });

            return podListOptions;
        }));
    }

    public getPodAddress(pod: PodModel) {
        const streetNumber = pod.streetNumber ? pod.streetNumber + '.' : '';
        const street = pod.street ? (pod.street + (streetNumber ? ' ' : '')) : '';
        const city = pod.city ? (pod.city + ((street || streetNumber) ? ', ' : '')) : '';

        return city + street + streetNumber;
    }

    translateProfileType(type: string) {
        if (type === 'TIME_SERIES' || type === 'PROFILE') {
            return this.translateService.instant('profileType.' + type);
        }
        return type;
    }
}
