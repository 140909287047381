import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment-timezone';
import { Observable } from 'rxjs';
import { StateStorageService } from '../auth/index';
import { Invoice } from '../../invoices/invoice.model';

@Injectable({
    providedIn: 'root'
})
export class InvoicesService {

    constructor(private stateStorageService: StateStorageService, private http: HttpClient) {
    }

    public getInvoices(partnerId: String, fromDate: Moment, toDate: Moment): Observable<Invoice[]> {
        return this.http.get<Invoice[]>('api/invoice', {
            params: {
                partnerId: String(partnerId),
                from: fromDate.format('YYYY-MM-DD'),
                to: toDate.format('YYYY-MM-DD')
            }
        });
    }

    public deleteInvoice(invoiceId: number): Observable<any> {
        return this.http.delete(`api/invoice/${invoiceId}`);
    }
}
