import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DateInterval} from '../../../shared/dto/date-interval.model';
import {PeriodParameterModel} from '../../../shared/dto/period-parameter.model';
import {IntervalUtil} from '../../../core/util/interval-util';

@Component({
  selector: 'consumption-period-parameter-selector',
  templateUrl: 'period-parameter-selector.component.html'
})
export class ConsumptionPeriodParameterSelector {

  @Input() upperBound: number;

  @Input() set periodParameterModel(value: PeriodParameterModel[]) {
    const newIntervals = ConsumptionPeriodParameterSelector.convertPeriodParametersToIntervals(value);

    if (IntervalUtil.sameIntervals(newIntervals, this._intervals)) {
      return;
    }

    this._intervals = newIntervals;
  }

  @Output() periodParameterModelChange: EventEmitter<PeriodParameterModel[]> = new EventEmitter();

  private _intervals: DateInterval[];

  get intervals(): DateInterval[] {
    return this._intervals;
  }

  set intervals(value: DateInterval[]) {
    if (IntervalUtil.sameIntervals(value, this._intervals)) {
      return;
    }

    this._intervals = value;

    const newPeriodParameters = this.convertIntervalsToPeriodParameter(value);
    this.periodParameterModelChange.emit(newPeriodParameters);
  }

  constructor() {
    this._intervals = [ConsumptionPeriodParameterSelector.emptySelector()];
  }

  private convertIntervalsToPeriodParameter(intervals: DateInterval[]): PeriodParameterModel[] {
    const newPeriodParameters = intervals.map((interval, index) => {
      const periodInDays = Boolean(interval.to) && Boolean(interval.from) ? interval.to.diff(interval.from, 'days') : 0;
      const postfix = index + 1;
      const isMultiplePeriodAllowed = this.upperBound !== 1;
      const showNextPeriod = index + 1 < intervals.length;
      const periodParameter = new PeriodParameterModel(periodInDays, postfix, isMultiplePeriodAllowed, showNextPeriod);

      periodParameter.dateFrom = ConsumptionPeriodParameterSelector.convertMomentToNgbDate(interval.from);
      periodParameter.dateTo = ConsumptionPeriodParameterSelector.convertMomentToNgbDate(interval.to);

      return periodParameter;
    });
    for (let index = newPeriodParameters.length; index < 4; index++) {
      const isMultiplePeriodAllowed = this.upperBound !== 1;
      const postfix = index + 1;
      const periodParameter = new PeriodParameterModel(0, postfix, isMultiplePeriodAllowed, false);
      newPeriodParameters.push(periodParameter);
    }
    return newPeriodParameters;
  }

  public static convertMomentToNgbDate(date: Moment): { year: number, month: number, day: number } {
    if (!Boolean(date)) {
      return null;
    }

    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    };
  }

  public static convertPeriodParametersToIntervals(periodParameters: PeriodParameterModel[]): DateInterval[] {
    if (!Boolean(periodParameters) || periodParameters.length === 0) {
      return [];
    }

    const dateIntervals = [];

    let index = 0;
    do {
      const periodParameter = periodParameters[index];
      const from = ConsumptionPeriodParameterSelector.convertNgbDateToMoment(periodParameter.dateFrom);
      const to = ConsumptionPeriodParameterSelector.convertNgbDateToMoment(periodParameter.dateTo);
      dateIntervals.push({from, to});
    } while (periodParameters[index++].showNextPeriod);

    return dateIntervals;
  }

  public static convertNgbDateToMoment(date: { year: number, month: number, day: number }): Moment {
    if (!Boolean(date)) {
      return null;
    }

    return moment([
      date.year,
      date.month - 1,
      date.day
    ]);
  }

  private static emptySelector(): DateInterval {
    return {
      from: null,
      to: null
    };
  }

}
