export interface OfferClaimReservationPeriodMap {
    yearlyReservations: {[key: number]: number};
    quarterlyReservations: {[key: number]: number};
    monthlyReservations: {[key: number]: number};
    spotReservations: {[key: number]: number};
    customMonthlyReservations: {[key: number]: number};
}

export type ChartSide = 'MIN' | 'MAX';

export const ChartSide = {
    MIN: 'MIN' as ChartSide,
    MAX: 'MAX' as ChartSide,
};

export interface OfferClaimReservationPeriodMapWithAveraging {
    totalQuantity: OfferClaimReservationPeriodMap;
    alreadyPurchasedQuantity: OfferClaimReservationPeriodMap;
    notYetPurchasedQuantity: OfferClaimReservationPeriodMap;
}
