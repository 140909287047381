import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {TimeInterval} from '../../shared/time-series/time-series-interval/time-interval.model';
import {
    FactHedgeConsumptionSumRequest,
    FactHedgeConsumptionSumResponse,
    HedgeConsumptionPlanResponse
} from './hedge-consumption-plan.type';
import {TimeSeries} from '../../shared/time-series/model/timeseries';

@Injectable({
    providedIn: 'root'
})
export class HedgeConsumptionPlanService {

    constructor(private http: HttpClient) {
    }

    public getCalculatedPlan(start: string, end: string, value: number, type: string, agreementId: number, expectedPlanMonths: number[]): Observable<HedgeConsumptionPlanResponse> {
        let params = new HttpParams()
            .set('start', start)
            .set('end', end)
            .set('agreementId', agreementId.toString());

        if (value != null && !isNaN(value)) {
            params = params.append('value', value.toString());
        }
        if (!!expectedPlanMonths) {
            params = params.append('expectedPlanMonths', expectedPlanMonths.toString());
        }

        return this.http.get<HedgeConsumptionPlanResponse>(`api/hedge-consumption-plan/calculate-plan/${type}`, {params});
    }

    public getConsumptionMonthlySum(timeInterval: TimeInterval, agreementId: number): Observable<number[]> {
        let agreementParams = agreementId ? new HttpParams().set('agreementId', agreementId.toString()) : new HttpParams();
        return this.http.post<number[]>(`api/hedge-consumption-plan/consumption-monthly-sum`, timeInterval, {params: agreementParams});
    }

    public getFactConsumptionMonthlySum(timeInterval: TimeInterval, agreementId: number, deliveryPeriodId: number): Observable<number[]> {
        let agreementParams = agreementId ?
            new HttpParams().set('agreementId', agreementId.toString()).set('deliveryPeriodId', deliveryPeriodId.toString()) :
            new HttpParams();
        return this.http.post<number[]>(`api/hedge-consumption-plan/fact-consumption-monthly-sum`, timeInterval, {params: agreementParams});
    }

    public getFactCapacityMonthlyAvg(timeInterval: TimeInterval, agreementId: number, deliveryPeriodId: number): Observable<number[]> {
        let agreementParams = agreementId ?
            new HttpParams().set('agreementId', agreementId.toString()).set('deliveryPeriodId', deliveryPeriodId.toString()) :
            new HttpParams();
        return this.http.post<number[]>(`api/hedge-consumption-plan/fact-capacity-monthly-avg`, timeInterval, {params: agreementParams});
    }

    public getFactConsumptionSum(timeInterval: TimeInterval, agreementId: number, deliveryPeriodId: number): Observable<number> {
        let agreementParams = agreementId ?
            new HttpParams().set('agreementId', agreementId.toString()).set('deliveryPeriodId', deliveryPeriodId.toString()) :
            new HttpParams();
        return this.http.post<number>(`api/hedge-consumption-plan/fact-consumption-sum`, timeInterval, {params: agreementParams});
    }

    public getFactConsumptionsSum(factHedgeConRequest: FactHedgeConsumptionSumRequest[]): Observable<FactHedgeConsumptionSumResponse[]> {
        return this.http.post<FactHedgeConsumptionSumResponse[]>(`api/hedge-consumption-plan/fact-consumptions-sum`, factHedgeConRequest, {});
    }

    public saveCalculatedPlan(timeSeries: TimeSeries[], agreementId: number, deliveryPeriodId: number): Observable<void> {
        let agreementParams = agreementId ?
            new HttpParams()
                .set('agreementId', agreementId)
                .set('deliveryPeriodId', deliveryPeriodId) :
            new HttpParams();
        return this.http.post<void>(`api/hedge-consumption-plan/save`, timeSeries, {params: agreementParams});
    }
}
