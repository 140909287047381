import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CoverageTransactionsAdminComponent} from './coverage-transactions.admin.component';
import {CezPortalSharedModule} from '../shared';
import {TasksComponent} from './tasks/tasks.component';
import {NgxMaskModule} from 'ngx-mask';
import {CountdownModule} from 'ngx-countdown';
import {DailyRatesComponent} from './daily-rates/daily-rates.component';
import {AveragingTransactionsComponent} from './averaging-transactions/averaging-transactions.component';
import {OfferClaimHistoryComponent} from './offer-claim-history/offer-claim-history.component';
import {AvailableProductPeriodsComponent} from "./available-product-periods/available-product-periods.component";
import {BusinessParametersComponent} from './business-parameters/business-parameters.component';
import {BusinessParametersModalComponent} from './business-parameters/business-parameters.modal.component';
import {ActivePriceTrackingsComponent} from './active-price-trackings/active-price-trackings.component';
import {ActiveOffersComponent} from './active-offers/active-offers.component';

@NgModule({
    imports: [
        CommonModule,
        CezPortalSharedModule,
        NgxMaskModule.forRoot(),
        CountdownModule
    ],
    declarations: [
        CoverageTransactionsAdminComponent,
        TasksComponent,
        DailyRatesComponent,
        AveragingTransactionsComponent,
        OfferClaimHistoryComponent,
        AvailableProductPeriodsComponent,
        ActiveOffersComponent,
        BusinessParametersComponent,
        BusinessParametersModalComponent,
        ActivePriceTrackingsComponent
    ],
    exports: [CoverageTransactionsAdminComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoverageTransactionsAdminModule { }
