import { BaseDto, FileDto, Category, LocalizedTexts } from "../";

export class ProductAndServicesModelBase {
    id: number;
    categories: Category[];  
    releaseTime: Date;
    productFile: FileDto;
    show: boolean = true;
    showDetail: boolean;
    visible: boolean;
}