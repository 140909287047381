<div class="floating-alert">
    <div *ngIf="alerts.length > 1"
         class="d-flex justify-content-end"
         (click)="clearAlerts()">
        <button type="button" class="close d-flex mr-4 mb-1">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="d-flex flex-row align-items-center alert alert-{{alertObject.type}}" *ngFor="let alertObject of alerts">
        <mat-icon>{{ getAlertIcon(alertObject.type) }}</mat-icon>
        <div class="mr-5 ml-3">
            <span *ngIf="!alertObject.messageLocalized" [innerHTML]="alertObject.message"></span>
            <span *ngIf="alertObject.messageLocalized" jhiTranslate="{{alertObject.messageLocalized}}"></span>
        </div>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeAlert(alertObject)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
