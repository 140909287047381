<h1 mat-dialog-title class="modal-primary">{{'invoice-resource.editor.title' | translate}}</h1>

<div mat-dialog-content [formGroup]="form" class="d-flex flex-column">
    <mat-form-field>
        <input matInput
               formControlName="resourceId"
               type="number"
               max="10000000"
               placeholder="{{'invoice-resource.editor.resourceId' | translate}}"
               required>
    </mat-form-field>

    <mat-form-field>
        <input matInput
               formControlName="assignedId"
               type="number"
               max="10000000"
               placeholder="{{'invoice-resource.editor.assignedId' | translate}}"
               required>
    </mat-form-field>
    <mat-form-field>
        <input matInput
               formControlName="nameEn"
               placeholder="{{'invoice-resource.editor.nameEn' | translate}}"
               required>
    </mat-form-field>
    <mat-form-field>
        <input matInput
               formControlName="nameHu"
               placeholder="{{'invoice-resource.editor.nameHu' | translate}}"
               required>
    </mat-form-field>
    <mat-form-field>
        <input matInput
               formControlName="unit"
               placeholder="{{'invoice-resource.editor.unit' | translate}}"
               required>
    </mat-form-field>
    <div class="form-checkbox">
        <p-checkbox
            formControlName="shouldBeProcessed"
            [label]="'invoice-resource.editor.shouldBeProcessed' | translate"
            binary="true"
        />
    </div>
    <div class="form-checkbox">
        <p-checkbox
            formControlName="usedInUnitCost"
            [label]="'invoice-resource.editor.usedInUnitCost' | translate"
            binary="true"
        />
    </div>
    <mat-form-field>
        <mat-select placeholder="{{'invoice-resource.editor.resourceGroup' | translate}}"
                    formControlName="resourceGroup"
                    required>
            <mat-option *ngFor="let group of invoiceResourceGroup | keys" [value]="group.value">
                {{'invoice-resource.resource-groups.' + group.value | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field>
        <mat-select placeholder="{{'invoice-resource.editor.distributionProperty' | translate}}"
                    formControlName="distributionProperty"
                    required>
            <mat-option *ngFor="let value of distributionProperty | keys" [value]="value.value">
                {{'invoice-resource.distribution-property-values.' + value.value | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close class="cez-color--dark">
        {{'entity.action.cancel' | translate }}
    </button>
    <button mat-raised-button
            (click)="save()"
            color="primary"
            [disabled]="!form.valid">
        {{'entity.action.save' | translate }}
    </button>
</div>
