import {DeliveryPeriod} from "../../dto/delivery.periods";
import {UserDto} from './UserDto';
import {ClaimItemBaseModel} from './claim-item-base.model';

export class OfferClaimDto extends ClaimItemBaseModel {
  deliveryPeriod: DeliveryPeriod;
  tranche: number;
  quotationRequester: UserDto;
  quantityInTimeSeries: boolean;
}
