import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SiteMapComponent } from './site-map.component';
import { SITE_MAP_ROUTE } from "./site-map.route";
import {TreeModule} from 'primeng/tree';
import {CezPortalSharedModule} from '../shared';

@NgModule({
  imports: [
    CommonModule,
    CezPortalSharedModule,
    RouterModule.forRoot([SITE_MAP_ROUTE], { useHash: true }),
    TreeModule
  ],
  declarations: [SiteMapComponent]
})
export class SiteMapModule { }
