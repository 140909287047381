<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
    <mat-form-field class="w-100">
        <input matInput #answer placeholder="{{data.message}}" required>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="null" class="cez-color--dark">{{'common.action.cancel' | translate}}</button>
    <button mat-raised-button
            color="primary"
            [mat-dialog-close]="answer.value"
            [disabled]="!answer.value">
        {{'common.action.ok' | translate}}
    </button>
</div>
