<mat-card>
    <mat-card-title>{{'invoices.title' | translate }}</mat-card-title>

    <mat-card-content>
        <div class="d-flex mb-3">
            <form (ngSubmit)="findInvoices()">
                <monthly-date-interval intervalRestrictor [dateFrom]="fromDate" [dateTo]="toDate"
                                       (selectInterval)="setInterval($event)"></monthly-date-interval>
                <button mat-button mat-mini-fab color="primary"
                        [disabled]="!fromDate || !toDate || ('INVOICE_READ' | doesNotHavePermission)"
                        *permissionOverlay="'INVOICE_READ'">
                    <i class="material-icons">search</i>
                </button>
            </form>

            <div class="ml-auto button-margin align-self-center">
                <button mat-button mat-mini-fab color="warn" *ngIf="erasable" type="button"
                        [disabled]="!selectedInvoice || ('INVOICE_ADMIN_WRITE' | doesNotHavePermission)"
                        (click)="deleteInvoice(selectedInvoice.invoiceId)">
                    <mat-icon>delete</mat-icon>
                </button>

                <button mat-button mat-mini-fab color="primary"
                        [disabled]="!selectedInvoice || ('INVOICE_REPORT_TABLE' | doesNotHavePermission)" type="button"
                        matTooltip="{{'invoices.chart-type.table' | translate}}"
                        *permissionOverlay="'INVOICE_REPORT_TABLE'"
                        (click)="createChart(resultViewType.TABLE)">
                    <mat-icon>table_chart</mat-icon>
                </button>

                <button mat-button mat-mini-fab color="primary"
                        [disabled]="!selectedInvoice || ('INVOICE_REPORT_BAR_CHART' | doesNotHavePermission)"
                        type="button"
                        matTooltip="{{'invoices.chart-type.columndiagram' | translate}}"
                        *permissionOverlay="'INVOICE_REPORT_BAR_CHART'"
                        (click)="createChart(resultViewType.COLUMNDIAGRAM)">
                    <mat-icon>bar_chart</mat-icon>
                </button>

                <button mat-button mat-mini-fab color="primary"
                        [disabled]="!selectedInvoice || ('INVOICE_REPORT_PIE_CHART' | doesNotHavePermission)"
                        type="button"
                        matTooltip="{{'invoices.chart-type.piechart' | translate}}"
                        *permissionOverlay="'INVOICE_REPORT_PIE_CHART'"
                        (click)="createChart(resultViewType.PIECHART)">
                    <mat-icon>pie_chart</mat-icon>
                </button>
            </div>
        </div>

        <p-table [value]="invoices"
                 selectionMode="single"
                 [(selection)]="selectedInvoice"
                 [scrollable]="true"
                 scrollHeight="400px"
                 sortMode="single"
                 sortField="invoiceDate"
                 [sortOrder]="-1"
                 class="table table-striped selectable-table">

            <ng-template pTemplate="header">
                <tr style="background-color: white; border-bottom: 2px solid #dee2e6; border-top: 2px solid #dee2e6;">
                    <th [pSortableColumn]="'invoiceDate'">{{'invoices.table.header.invoiceDate' | translate}}
                        <p-sortIcon [field]="'invoiceDate'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'invoiceNumber'">{{'invoices.table.header.invoiceName' | translate}}
                        <p-sortIcon [field]="'invoiceNumber'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'pdfName'">{{'invoices.table.header.pdfName' | translate}}
                        <p-sortIcon [field]="'pdfName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'xlsName'">{{'invoices.table.header.xlsName' | translate}}
                        <p-sortIcon [field]="'xlsName'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr [pSelectableRow]="rowData">
                    <td class="date">{{rowData.invoiceDate | date: 'yyyy MMMM'}}</td>
                    <td>{{rowData.invoiceNumber}}</td>
                    <td><a href="/api/files/download/{{rowData.pdfId}}/{{rowData.pdfName | encodeURIComponent}}" target="_blank">
                        <button type="button" mat-button mat-mini-fab color="primary" [hidden]="!rowData.pdfId"
                                matTooltip="{{'invoices.table.downloadPdf' | translate}}">
                            <mat-icon>pageview</mat-icon>
                        </button>
                    </a></td>
                    <td><a href="/api/files/download/{{rowData.xlsId}}/{{rowData.xlsName | encodeURIComponent}}" target="_blank">
                        <button type="button" mat-button mat-mini-fab color="primary" [hidden]="!rowData.xlsId"
                                matTooltip="{{'invoices.table.downloadXls' | translate}}">
                            <mat-icon>cloud_download</mat-icon>
                        </button>
                    </a></td>
                </tr>
            </ng-template>
        </p-table>
    </mat-card-content>
</mat-card>
