import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { TranslateService } from '@ngx-translate/core';
import { InvoiceResourceDTO } from '../model/invoice-reosurce.model';
import { InvoiceResourceDistributionProperty } from '../model/invoice-resource-distribution-property.model';
import { InvoiceResourceGroup } from '../model/invoice-resource-group.model';
import { InvoiceResourceService } from '../service/invoice-resource.service';

@Component({
    selector: 'jhi-invoice-resource-editor',
    templateUrl: './invoice-resource-editor.component.html',
    styleUrls: ['./invoice-resource-editor.component.scss']
})
export class InvoiceResourceEditorComponent implements OnInit {

    form: UntypedFormGroup;
    invoiceResourceGroup = InvoiceResourceGroup;
    distributionProperty = InvoiceResourceDistributionProperty;

    constructor(@Inject(MAT_DIALOG_DATA) public data: InvoiceResourceDTO|null,
                private dialogRef: MatDialogRef<InvoiceResourceDTO>,
                private fb: UntypedFormBuilder,
                private translate: TranslateService,
                private service: InvoiceResourceService) {

        this.form = fb.group({
            resourceId: [{
                value: '',
                disabled: Boolean(data)
            }, Validators.compose([Validators.required, Validators.max(10000000)])],
            assignedId: ['', Validators.compose([Validators.required, Validators.max(10000000)])],
            nameEn: ['', Validators.required],
            nameHu: ['', Validators.required],
            resourceGroup: [InvoiceResourceGroup.ELECTRICITY, Validators.required],
            unit: [{value: '', disabled: Boolean(data)}, Validators.required],
            shouldBeProcessed: [false, Validators.required],
            usedInUnitCost: [false, Validators.required],
            distributionProperty: [InvoiceResourceDistributionProperty.DISTRIBUTABLE, Validators.required]
        });

    }

    ngOnInit() {
        if (this.data) {
            this.form.patchValue(this.data);
        }
    }

    save() {
        if (this.data) {
            this.service.updateInvoiceResource(Object.assign({}, this.data, this.form.value)).subscribe(() => {
                this.dialogRef.close(true);
            }, error => {
                console.error(error);
            });
        } else
            this.service.createInvoiceResource(this.form.value).subscribe(() => {
                this.dialogRef.close(true);
            }, error => {
                console.error(error);
            });
    }

}
