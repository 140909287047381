import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FilterModel} from '../filter-basic/filter.model';
import {Subscription} from 'rxjs';
import {Resolution} from '../../shared/time-series/model/resolution.enum';
import {TranslateService} from '@ngx-translate/core';
import {StateStorageService} from '../../shared/auth';
import { ConsumptionDynamicFilterService, FilterEvent } from './consumption-dynamic-filter.service';
import {DiagramChangeEvent, DiagramService} from '../../shared/services/diagram.service';
import * as moment from 'moment';;

@Component({
    selector: 'jhi-consumption-dynamic-filter',
    templateUrl: './consumption-dynamic-filter.component.html',
    styleUrls: ['./consumption-dynamic-filter.component.scss']
})
export class ConsumptionDynamicFilterComponent implements OnInit, OnDestroy {

    public _filterModel: FilterModel;
    private filterFormChangeSub: Subscription;
    private diagramSelectionChangeSub: Subscription;
    private chartChangeSub: Subscription;
    private basicFilterChangeSub: Subscription;
    @Output()
    private filterModelChanged: EventEmitter<FilterModel> = new EventEmitter();
    private currentFormState: any;

    public timeSeriesResolutions: any[] = this.consumptionDynamicFilterService.getResolutionList();
    public readonly min = moment("2000-01-01");
    public readonly max = moment().add(100, 'years').startOf('day');

    public showTemperature: boolean;
    public showMinMax: boolean;
    public showAvg: boolean;
    public showReferenceInterval: boolean;

    public filterFrom: FormGroup;
    public resolution: FormControl;
    public minSelected: FormControl;
    public maxSelected: FormControl;
    public avgSelected: FormControl;
    public tempSelected: FormControl;
    public perfSelected: FormControl;
    public scheduleSelected: FormControl;
    public showFilterDetails: boolean = false;
    public showSchedule: boolean = true;

    private schedulePotLineCounter: number = 0;

    constructor(private fb: FormBuilder,
                private translateService: TranslateService,
                private stateStorageService: StateStorageService,
                private consumptionDynamicFilterService: ConsumptionDynamicFilterService,
                private diagramService: DiagramService) {
    }

    ngOnInit(): void {
        this.listenDiagramSelectionChange();
        this.listenChartChange();
        this.listenBasicFilterChange();
        this.listenResolutionChange();
    }

    private buildForm(filterModel: FilterModel): void {
        this.resolution = new FormControl(filterModel.resolution ? filterModel.resolution : Resolution.FIVE_MINUTELY);
        this.minSelected = new FormControl(filterModel.minSelected);
        this.maxSelected = new FormControl(filterModel.maxSelected);
        this.avgSelected = new FormControl(filterModel.avgSelected);
        this.tempSelected = new FormControl(filterModel.temperatureChecked);
        this.perfSelected = new FormControl(filterModel.perfSelected);
        this.scheduleSelected = new FormControl(filterModel.scheduleSelected);
        this.filterFrom = this.fb.group({
            resolution: this.resolution,
            tempSelected: this.tempSelected,
            perfSelected: this.perfSelected
        });
        if (!this.filterFormChangeSub) {
            this.listenFormChange();
        }
    }

    private listenFormChange(): void {
        this.filterFormChangeSub = this.filterFrom.valueChanges.subscribe((val: any): void => {
            this.formChangeHandler(val);
        });
    }

    private formChangeHandler(val: any, forceChange?: boolean): void {
        if (!this.currentFormState || JSON.stringify(this.currentFormState) !== JSON.stringify(val) || forceChange) {
            this._filterModel.resolution = val.resolution;

            this._filterModel.minSelected = this.minSelected.value;
            this._filterModel.maxSelected = this.maxSelected.value;
            this._filterModel.avgSelected = this.avgSelected.value;
            this._filterModel.scheduleSelected = this.scheduleSelected.value;

            this._filterModel.temperatureChecked = val.tempSelected;
            this._filterModel.perfSelected = val.perfSelected;

            this.currentFormState = val;
            this.filterModelChanged.emit(this._filterModel);
            this.showSchedule = this._filterModel.resolution !== 'MIN_15';
        }
    }

    @Input()
    set filterModel(filterModel: FilterModel) {
        if (this.filterFormChangeSub) {
            this.filterFormChangeSub.unsubscribe();
            this.filterFormChangeSub = null;
        }
        this._filterModel = filterModel;
        this.buildForm(filterModel);

        if (this._filterModel.selectedDiagram) {
            this.showSchedule = this._filterModel.selectedDiagram.id === 1 && this._filterModel.resolution !== 'MIN_15';
        }
    }

    ngOnDestroy(): void {
        if (this.filterFormChangeSub) {
            this.filterFormChangeSub.unsubscribe();
        }
        if (this.diagramSelectionChangeSub) {
            this.diagramSelectionChangeSub.unsubscribe();
        }
        if (this.chartChangeSub) {
            this.chartChangeSub.unsubscribe();
        }
        if (this.basicFilterChangeSub) {
            this.basicFilterChangeSub.unsubscribe();
        }
    }

    private listenDiagramSelectionChange(): void {
        this.diagramSelectionChangeSub = this.consumptionDynamicFilterService.diagramSelectionChangeSubject.subscribe((selectedDiagram: any) => {
            if (selectedDiagram) {
                this._filterModel.selectedDiagram = selectedDiagram
            }
            this.diagramSelected();
        });
    }

    //Called if the diagram type changed on the left panel.
    private diagramSelected(): void {
        this.setResolutionList(this._filterModel.selectedDiagram.resolutionAllowed);
        this.controlTemperature(this._filterModel.selectedDiagram.temperatureAllowed);
        this.showMinMax = this._filterModel.selectedDiagram.minMaxAllowed;
        this.showAvg = this._filterModel.selectedDiagram.avarageAllowed;
        this.showReferenceInterval = this._filterModel.selectedDiagram.referenceIntervalAllowed;

        if (this._filterModel.selectedDiagram) {
            this.showSchedule = this._filterModel.selectedDiagram.id === 1;
        }

        this.showSchedule = this._filterModel.resolution !== 'MIN_15';
    }

    private controlTemperature(tempAllowed: boolean): void {
        this.showTemperature = tempAllowed;
        if (!this.showTemperature) {
            this._filterModel.temperatureChecked = undefined;
        }
    }

    private setResolutionList(enabledList: boolean[]): void {
        const permissions = this.stateStorageService.getEffectivePermissions();

        this.timeSeriesResolutions.forEach((resolutionOption, i) => {
            resolutionOption.enabled = enabledList[i] && permissions.indexOf(resolutionOption.requiredPermission) > -1;
        });

        const currentResolutionDisabled = this.timeSeriesResolutions.filter(r => r.enabled).map(r => r.value).indexOf(this._filterModel.resolution) === -1;

        if (!this._filterModel.resolution || currentResolutionDisabled) {
            let firstAllowedResolution = this.timeSeriesResolutions.find(r => r.value == 'HOUR' && r.enabled);
            if (!firstAllowedResolution) {
                firstAllowedResolution = this.timeSeriesResolutions.find(r => r.enabled);
            }
            this._filterModel.resolution = firstAllowedResolution ? firstAllowedResolution.value : null;
            this.resolution.setValue(this._filterModel.resolution, {emitEvent: false});
        }
    }

    /**
     * Called when a filter item changed and want to fire a separate event from the form group change event (filter model change).
     * Charts listen for this chagne event to hide or display plotlines.
     * @param item
     * @param value
     */
    public listenFilterItemChange(item: string, value: any): void {
        this.consumptionDynamicFilterService.filterChanged({
            type: item as any,
            value: value
        })
    }

    /**
     * Called when the highcharts (diagram service) fire a event, like 'legendItemClick'
     */
    private listenChartChange(): void {
        this.chartChangeSub = this.diagramService.diagramChanged.subscribe((event: DiagramChangeEvent) => {
            if (event.type == 'legendItemClick') {
                switch (event.value.groupId) {
                    case 'avg': {
                        //need the negation of the visible value because of the diagarm service implementation
                        this.avgSelected.setValue(!event.value.item.visible, {emitEvent: false});
                        break;
                    }
                    case 'min': {
                        this.minSelected.setValue(!event.value.item.visible, {emitEvent: false});
                        break;
                    }
                    case 'max': {
                        this.maxSelected.setValue(!event.value.item.visible, {emitEvent: false});
                        break;
                    }
                    case 'performance': {
                        this.perfSelected.setValue(!event.value.item.visible, {emitEvent: false});
                        break;
                    }
                    case 'schedule': {
                        if (!event.value.item.visible === true) {
                            this.schedulePotLineCounter++;
                            this.scheduleSelected.setValue(!event.value.item.visible, {emitEvent: false});
                        } else {
                            if (this.schedulePotLineCounter > 0) {
                                this.schedulePotLineCounter--;
                                if (this.schedulePotLineCounter === 0) {
                                    this.scheduleSelected.setValue(!event.value.item.visible, {emitEvent: false});
                                }
                            }
                        }
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        });
    }

    private listenBasicFilterChange(): void {
        this.basicFilterChangeSub = this.consumptionDynamicFilterService.filterBasicChangedSubject.subscribe((event: FilterEvent) => {
            if (event.type === 'schedule') {
                this.scheduleSelected.setValue(event.value);
                this.listenFilterItemChange('schedule', this.scheduleSelected.value);
            }
        }); ///
    }

    private listenResolutionChange(): void {
        this.resolution.valueChanges.subscribe((r) => {
            this.showSchedule = r !== 'MIN_15';
        });
    }
}
