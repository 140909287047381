import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { concat } from 'rxjs';
import { JhiLanguageHelper, Principal, User, UserService } from '../../shared';
import { TwoFactorAuthService } from '../../twofactor-auth/two-factor-auth.service';

import { UserModalService } from './user-modal.service';
import {ExcelImportResult} from "../../shared/time-series/model/excel-import-result.model";

@Component({
    selector: 'jhi-user-mgmt-dialog',
    templateUrl: './user-management-dialog.component.html'
})
export class UserMgmtDialogComponent implements OnInit {

    user: User;
    userPicture: File;
    languages: any[];
    authorities: any[];
    roleGroups: any[];
    isSaving: Boolean;
    twoFactorReset: boolean;

    constructor(
        public activeModal: NgbActiveModal,
        private languageHelper: JhiLanguageHelper,
        private userService: UserService,
        private http: HttpClient,
        private twoFactorAuthService: TwoFactorAuthService,
        private principal: Principal) {
    }

    ngOnInit() {
        this.isSaving = false;
        this.twoFactorReset = false;
        this.authorities = [];
        this.http.get<any[]>('/api/groups/PERMISSION').subscribe(r => {
            this.authorities = r;
        });
        this.http.get<any[]>('/api/groups/PARTNER_ROLE').subscribe(r => {
            this.roleGroups = r;
        });
        this.languageHelper.getAll().then((languages) => {
            this.languages = languages;
        });

    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.isSaving = true;
        if (this.user.id !== null) {
            let subscriptions = [];

            subscriptions.push(this.userService.update(this.user));
            if (this.twoFactorReset) {
                subscriptions.push(this.twoFactorAuthService.resetTwoFactorKey(this.user.id));
            }

            if (this.userPicture)
                subscriptions.push(this.userService.uploadPicture(this.user.id, this.userPicture));

            concat(...subscriptions).subscribe(r => {
                if (!r)
                    return;
                this.onSaveSuccess(r);
            }, () => this.onSaveError());
        } else {
            this.user.activated = true;
            this.userService.create(this.user).subscribe(response => this.onSaveSuccess(response), () => this.onSaveError());
        }
    }

    private onSaveSuccess(result) {
        this.isSaving = false;
        this.activeModal.dismiss(result);
        this.userService.getUserChangeEvent().next();
        this.principal.identity(true);
    }

    private onSaveError() {
        this.isSaving = false;
    }

  public addFile(fileInput: HTMLInputElement) {
    fileInput.value = '';
    fileInput.click();
  }

  public onUpload(event: { file: File, success: boolean, response?: ExcelImportResult, error?: HttpErrorResponse }) {
      this.userPicture = event.file;
  }

}

@Component({
    selector: 'jhi-user-dialog',
    template: ''
})
export class UserDialogComponent implements OnInit, OnDestroy {

    modalRef: NgbModalRef;
    routeSub: any;

    constructor(private route: ActivatedRoute,
                private userModalService: UserModalService) {
    }

    ngOnInit() {
        this.routeSub = this.route.params.subscribe(params => {
            if (params['login']) {
                this.modalRef = this.userModalService.open(UserMgmtDialogComponent, params['login']);
            } else {
                this.modalRef = this.userModalService.open(UserMgmtDialogComponent);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
