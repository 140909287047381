import { Route } from '@angular/router';

import { NewsAdminComponent } from './news.admin.component';
import {UserRouteAccessService} from '../shared';

export const NEWS_ADMIN_ROUTE: Route = {
  path: 'newsadmin',
  component: NewsAdminComponent,
  data: {
    authorities: ['ROLE_ADMIN'],
    pageTitle: 'home.title'
  },
  canActivate: [UserRouteAccessService]
};
