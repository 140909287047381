<ul ngbNav #navInner="ngbNav" class="nav-tabs" tabSave="pod-group" #tabSave="tabSave">
    <li [ngbNavItem]="'agreement.pod_groups.podGroupsButton'">
        <a ngbNavLink>{{ 'agreement.pod_groups.podGroupsButton' | translate }}</a>
        <ng-template ngbNavContent>
            <form #podGroupForm="ngForm">
                <div class="form-group" *ngIf="!addNewPodGroup">
                    <label for="selectedPodGroup" jhiTranslate="agreement.pod_groups.podGroupsButton">POD
                        csoportok</label>
                    <select name="selectedPodGroup" id="selectedPodGroup" class="form-control"
                            [(ngModel)]="selectedPodGroup"
                            (change)="selectedGroupChange()">
                        <option></option>
                        <option *ngFor="let group of podGroups" [ngValue]="group">
                            {{ group.name }} / {{ getGroupCategoryName(group) }}
                        </option>
                    </select>
                </div>
                <div class="form-group" *ngIf="addNewPodGroup">
                    <label for="newPodGroupName" jhiTranslate="agreement.pod_groups.new.groupName"></label>
                    <input required [(ngModel)]="newPodGroupName" id="newPodGroupName" name="newPodGroupName"
                           class="form-control"
                           (ngModelChange)="onChange()">
                    <strong *ngIf="duplicatedPodGroupName()"
                            jhiTranslate="agreement.pod_groups.duplicatedPodGroup"></strong>
                </div>
                <div class="form-group" *ngIf="addNewPodGroup">
                    <label for="selectedPodGroupCategory"
                           jhiTranslate="agreement.pod_groups.new.groupCategoryName"></label>
                    <select required id="selectedPodGroupCategory" name="selectedPodGroupCategory" class="form-control"
                            [(ngModel)]="selectedPodGroupCategory"
                            (ngModelChange)="onChange()">
                        <option></option>
                        <option *ngFor="let podGroupCategory of podGroupCategories" [ngValue]="podGroupCategory">
                            {{ podGroupCategory.name }}
                        </option>
                    </select>
                </div>
                <ng-container *ngIf="!addNewPodGroup">
                    <button class="btn btn-primary btn-wide-250"
                            (click)="addNewPodGroup=true"
                            [disabled]="('POD_GROUP_USER_WRITE' | doesNotHavePermission)"
                            jhiTranslate="agreement.pod_groups.new.newGroup"
                            *permissionOverlay="'POD_GROUP_USER_WRITE'"></button>
                </ng-container>

                <ng-container *ngIf="selectedPodGroup && !editedPodGroup && !addNewPodGroup">
                    <button class="btn btn-primary btn-wide-150 pull-right"
                            (click)="editPodGroup()"
                            [disabled]="('POD_GROUP_USER_WRITE' | doesNotHavePermission)"
                            *permissionOverlay="'POD_GROUP_USER_WRITE'"
                            jhiTranslate="entity.action.edit"></button>
                </ng-container>

                <ng-container *ngIf="selectedPodGroup && !editedPodGroup && !addNewPodGroup">
                    <button class="btn btn-primary btn-wide-150 pull-right mr-2"
                            (click)="deletePodGroup()"
                            [disabled]="!selectedPodGroup?.id ||('POD_GROUP_USER_WRITE' | doesNotHavePermission)"
                            *permissionOverlay="'POD_GROUP_USER_WRITE'"
                            jhiTranslate="entity.action.delete"></button>
                </ng-container>

                <ng-container *ngIf="addNewPodGroup">
                    <button class="btn btn-primary btn-wide-250"
                            *permissionOverlay="'POD_GROUP_USER_WRITE'"
                            [disabled]="('POD_GROUP_USER_WRITE' | doesNotHavePermission)"
                            (click)="close();"
                            jhiTranslate="agreement.pod_groups.new.cancel"></button>
                </ng-container>

                <ng-container *ngIf="addNewPodGroup">
                    <button class="btn btn-primary btn-wide-250 pull-right"
                            *permissionOverlay="'POD_GROUP_USER_WRITE'"
                            (click)="submitPodGroup()" jhiTranslate="agreement.pod_groups.new.save"
                            [disabled]="podGroupForm.form.invalid || duplicatedPodGroupName() || ('POD_GROUP_USER_WRITE' | doesNotHavePermission)">
                        Mentés
                    </button>
                </ng-container>
            </form>

            <form #podAssociationsForm="ngForm">
                <div oncopy="return false;" oncut="return false;" class="form-group mt-3" *ngIf="!addNewPodGroup">
                    <mat-card>
                        <mat-card-title>
                            <span jhiTranslate="agreement.pod_groups.table.selectPods"></span>
                        </mat-card-title>
                        <mat-card-content>
                            <p-table #table
                                     emptyMessage=""
                                     [value]="podListOptions"
                                     [(selection)]="selectedPods"
                                     dataKey="id"
                                     #dt
                                     (sortFunction)="sortPodOptions($event)"
                                     sortField="podCode"
                                     [globalFilterFields]="['podCode', 'podType', 'zipCode', 'address']"
                                     class="table table-striped selectable-table">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:2%">
                                            <p-tableHeaderCheckbox/>
                                        </th>
                                        <th style="width:24.5%" pSortableColumn="podCode">
                                            {{ getTranslation('agreement.pod_groups.table.podCode') }}
                                            <p-sortIcon field="podCode"></p-sortIcon>
                                        </th>

                                        <th style="width:24.5%" pSortableColumn="podType">
                                            {{ getTranslation('agreement.pod_groups.table.type') }}
                                            <p-sortIcon field="podType"></p-sortIcon>
                                        </th>

                                        <th style="width:24.5%" pSortableColumn="zipCode">
                                            {{ getTranslation('agreement.pod_groups.table.zip') }}
                                            <p-sortIcon field="zipCode"></p-sortIcon>
                                        </th>

                                        <th style="width:24.5%" pSortableColumn="city">
                                            {{ getTranslation('agreement.pod_groups.table.location') }}
                                            <p-sortIcon field="city"></p-sortIcon>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>
                                        </th>
                                        <th>
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{'agreement.pod_groups.table.podCode' | translate}}"
                                                       (input)="table.filter($event.target.value, 'podCode', 'contains')">
                                            </mat-form-field>
                                        </th>
                                        <th>
                                            <mat-form-field>
                                                <mat-select placeholder="{{'global.choose' | translate}}"
                                                            (selectionChange)="filterPodType(table, $event.value)">
                                                    <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                                                    <mat-option *ngFor="let podType of podTypes"
                                                                [value]="podType">{{ podType.label }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </th>
                                        <th>
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{'agreement.pod_groups.table.zip' | translate}}"
                                                       (input)="table.filter($event.target.value, 'zipCode', 'contains')">
                                            </mat-form-field>
                                        </th>
                                        <th>
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{'agreement.pod_groups.table.location' | translate}}"
                                                       (input)="table.filter($event.target.value, 'concatenatedAddress', 'contains')">
                                            </mat-form-field>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowPod>
                                    <tr>
                                        <td>
                                            <p-tableCheckbox [value]="rowPod"/>
                                        </td>
                                        <td> {{ rowPod.podCode }}</td>
                                        <td>
                                            {{ podService.translateProfileType(rowPod.podType) }}
                                        </td>
                                        <td> {{ rowPod.zipCode }}</td>
                                        <td> {{ podService.getPodAddress(rowPod) }}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </mat-card-content>
                    </mat-card>

                </div>
                <div oncopy="return false;" oncut="return false;" class="form-group mt-3" *ngIf="!addNewPodGroup">

                    <mat-card>
                        <mat-card-title>
                            <span jhiTranslate="agreement.pod_groups.new.groupCategoryName"></span>
                        </mat-card-title>
                        <mat-card-content>
                            <p-table [value]="selectedPods" (sortFunction)="sortSelectedPods($event)"
                                     sortField="podCode" emptyMessage="sadsasa"
                                     class="table table-striped selectable-table">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th pSortableColumn="podCode">
                                            {{ getTranslation('agreement.pod_groups.table.podCode') }}
                                            <p-sortIcon field="podCode"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="podType">
                                            {{ getTranslation('agreement.pod_groups.table.type') }}
                                            <p-sortIcon field="podType"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="zipCode">
                                            {{ getTranslation('agreement.pod_groups.table.zip') }}
                                            <p-sortIcon field="zipCode"></p-sortIcon>
                                        </th>
                                        <th pSortableColumn="city">
                                            {{ getTranslation('agreement.pod_groups.table.location') }}
                                            <p-sortIcon field="city"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowPod let-i="rowIndex">
                                    <tr>
                                        <td>
                                            <span class="noselect" oncopy="return false;" oncut="return false;">
                                                {{ rowPod.podCode }}
                                            </span>
                                        </td>
                                        <td>
                                            {{ podService.translateProfileType(rowPod.podType) }}
                                        </td>
                                        <td>
                                            {{ rowPod.zipCode }}
                                        </td>
                                        <td>
                                            {{ podService.getPodAddress(rowPod) }}
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </mat-card-content>
                    </mat-card>

                    <div *ngIf="!addNewPodGroup">
                        <button class="btn btn-primary btn-wide-250 pull-right mt-3"
                                [disabled]="!selectedPods || !selectedPods.length || !selectedPodGroup ||('POD_GROUP_USER_WRITE' | doesNotHavePermission)"
                                *permissionOverlay="'POD_GROUP_USER_WRITE'"
                                (click)="submit()" jhiTranslate="agreement.pod_groups.table.save">Mentés
                        </button>
                    </div>
                </div>
            </form>
        </ng-template>
    </li>
    <li [ngbNavItem]="'agreement.pod_groups.groupCategoryTitle'">
        <a ngbNavLink>{{ 'agreement.pod_groups.groupCategoryTitle' | translate }}</a>
        <ng-template ngbNavContent>
            <form #podGroupCategoryForm="ngForm">
                <mat-card>
                    <mat-card-content>
                        <div *ngIf="!newGroupCategory && podGroupCategories?.length > 0">
                            <table class="table table-striped">
                                <thead>
                                <tr>
                                    <th>
                                        <span jhiTranslate="agreement.pod_groups.new.groupCategoryName"></span>
                                    </th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let podGroupCategory of podGroupCategories">
                                    <td *ngIf="!podGroupCategory.editMode">{{ podGroupCategory.name }}</td>
                                    <td *ngIf="podGroupCategory.editMode && !podGroupCategory.autoCategorize">
                                        <input required [(ngModel)]="podGroupCategory.name" name="podGroupCategoryName"
                                               class="form-control"
                                               (ngModelChange)="onChange()">
                                    </td>
                                    <td *ngIf="podGroupCategory.editMode && podGroupCategory.autoCategorize">
                                        <select name="selectedPodGroup" class="form-control"
                                                [(ngModel)]="podGroupCategory.selectedAutocategorizeType"
                                                (change)="selectedGroupChange()">
                                            <option></option>
                                            <option [disabled]="isPodGroupCategoryTypeUsed('NETWORK_OPERATOR')"
                                                    value="NETWORK_OPERATOR">{{ 'agreement.pod_groups.DSOarea' | translate }}
                                            </option>
                                            <option [disabled]="isPodGroupCategoryTypeUsed('PROFILE_TYPE')"
                                                    value="PROFILE_TYPE"> {{ 'agreement.pod_groups.Typeofmetering' | translate }}
                                            </option>
                                            <option [disabled]="isPodGroupCategoryTypeUsed('CITY')"
                                                    value="CITY"> {{ 'agreement.pod_groups.Town' | translate }}
                                            </option>
                                            <option [disabled]="isPodGroupCategoryTypeUsed('SUBSIDIARIES')"
                                                    value="SUBSIDIARIES"> {{ 'agreement.pod_groups.Holding' | translate }}
                                            </option>
                                        </select>
                                    </td>
                                    <td class="align-middle w-25">
                                        <div class="pull-right row no-gutters">
                                            <div class="col"
                                                 *ngIf="!podGroupCategory.editMode && podGroupCategory.autoCategoryType">
                                                <button
                                                    title="{{getTranslation('agreement.pod_groups.autoCategorizeButton')}}"
                                                    class="btn btn-warning btn-sm"
                                                    [disabled]="('POD_GROUP_SYSTEM_WRITE' | doesNotHavePermission)"
                                                    *permissionOverlay="'POD_GROUP_SYSTEM_WRITE'"
                                                    (click)="refreshAutoCategory(podGroupCategory)">
                                                    <span class="fa fa-refresh"></span>
                                                </button>
                                            </div>
                                            <div class="col"
                                                 *ngIf="!podGroupCategory.editMode && !podGroupCategory.autoCategoryType">
                                                <button
                                                    title="{{getTranslation('agreement.pod_groups.autoCategorizeButton')}}"
                                                    class="btn btn-success btn-sm"
                                                    [disabled]="('POD_GROUP_SYSTEM_WRITE' | doesNotHavePermission)"
                                                    *permissionOverlay="'POD_GROUP_SYSTEM_WRITE'"
                                                    (click)="autoCategorize(podGroupCategory)">
                                                    <span class="fa fa-magic"></span>
                                                </button>
                                            </div>
                                            <div class="col" *ngIf="!podGroupCategory.editMode">
                                                <button class="btn btn-info btn-sm"
                                                        [disabled]="(('POD_GROUP_USER_WRITE' | doesNotHavePermission) && ('POD_GROUP_SYSTEM_WRITE' | doesNotHavePermission))"
                                                        *permissionOverlay="'';disabled:isCategoryAllowed"
                                                        (click)="podGroupCategory.editMode=true">
                                                    <span class="fa fa-pencil"></span>
                                                </button>
                                            </div>
                                            <div class="col" *ngIf="!podGroupCategory.editMode">
                                                <button class="btn btn-danger btn-sm"
                                                        [disabled]="('POD_GROUP_USER_WRITE' | doesNotHavePermission) && ('POD_GROUP_SYSTEM_WRITE' | doesNotHavePermission)"
                                                        *permissionOverlay="'';disabled:isCategoryAllowed"
                                                        (click)="deletepodGroupCategory(podGroupCategory)">
                                                    <span class="fa fa-remove"></span>
                                                </button>
                                            </div>
                                            <div class="col" *ngIf="podGroupCategory.editMode">
                                                <button class="btn btn-success btn-sm"
                                                        [disabled]="('POD_GROUP_USER_WRITE' | doesNotHavePermission) && ('POD_GROUP_SYSTEM_WRITE' | doesNotHavePermission)"
                                                        *permissionOverlay="'';disabled:isCategoryAllowed"
                                                        (click)="updatePodGroupCategory(podGroupCategory)">
                                                    <span class="fa fa-floppy-o"></span>
                                                </button>
                                            </div>
                                            <div class="col" *ngIf="podGroupCategory.editMode">
                                                <button class="btn btn-info btn-sm"
                                                        [disabled]="('POD_GROUP_USER_WRITE' | doesNotHavePermission) && ('POD_GROUP_SYSTEM_WRITE' | doesNotHavePermission)"
                                                        *permissionOverlay="'';disabled:isCategoryAllowed"
                                                        (click)="podGroupCategory.editMode=false;podGroupCategory.autoCategorize=false">
                                                    <span class="fa fa-undo"></span>
                                                </button>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </mat-card-content>
                    <mat-card-footer>
                        <div class="form-group" *ngIf="newGroupCategory">
                            <label for="newPodGroupCategoryName"
                                   jhiTranslate="agreement.pod_groups.new.groupCategoryName"></label>
                            <input required [(ngModel)]="newPodGroupCategoryName" id="newPodGroupCategoryName"
                                   name="newPodGroupCategoryName"
                                   class="form-control"
                                   (ngModelChange)="onChange()">
                        </div>
                    </mat-card-footer>
                </mat-card>

                <ng-container *ngIf="!newGroupCategory">
                    <button class="btn btn-primary btn-wide-250"
                            (click)="newGroupCategory=true"
                            [disabled]="('POD_GROUP_USER_WRITE' | doesNotHavePermission) && ('POD_GROUP_SYSTEM_WRITE' | doesNotHavePermission)"
                            *permissionOverlay="'';disabled:isCategoryAllowed"
                            jhiTranslate="agreement.pod_groups.new.newGroupCategoryName"></button>
                </ng-container>
                <ng-container *ngIf="newGroupCategory">
                    <button class="btn btn-primary btn-wide-250"
                            (click)="closeNewPodGroupCategories();"
                            [disabled]="('POD_GROUP_USER_WRITE' | doesNotHavePermission) && ('POD_GROUP_SYSTEM_WRITE' | doesNotHavePermission)"
                            *permissionOverlay="'';disabled:isCategoryAllowed"
                            jhiTranslate="agreement.pod_groups.new.cancel">Mégsem
                    </button>
                </ng-container>

                <ng-container *ngIf="newGroupCategory">
                    <button class="btn btn-primary btn-wide-250 pull-right"
                            (click)="submitPodGroupCategory()"
                            jhiTranslate="agreement.pod_groups.new.save"
                            [disabled]="!newPodGroupCategoryName || ('POD_GROUP_USER_WRITE' | doesNotHavePermission) && ('POD_GROUP_SYSTEM_WRITE' | doesNotHavePermission)"
                            *permissionOverlay="'';disabled:isCategoryAllowed">
                        Mentés
                    </button>
                </ng-container>
            </form>
        </ng-template>
    </li>
</ul>
<div [ngbNavOutlet]="navInner"></div>

<jhi-toast-message-component [alerts]="alerts"></jhi-toast-message-component>
