import {DecimalPipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import {DiagramService} from '../shared/services/diagram.service';
import {ToleranceData} from './tolerance-data.model';
import {Chart} from 'angular-highcharts';


@Injectable()
export class ToleranceCombinedChartService {

  chart: Chart;

  contractedConsumption = 0;
  tickPositions = [];
  tickValues = [];
  chartRef: Highcharts.Chart;

  constructor(private translate: TranslateService,
              private diagramService: DiagramService,
              private decimalPipe: DecimalPipe) {
  }


  public create(): Chart {
    const _this = this;
    this.chart = new Chart({
      chart: {
        events: this.diagramService.getWatermark(false),
        type: 'column',
        zooming: {
          type: 'x'
        },
        resetZoomButton: {
          theme: {
            opacity: 0.3,
            states: {
              hover: {
                opacity: 1
              }
            }
          }
        },
        marginRight: 90,
      },
      boost: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: {
        id: 'xAxis',
        categories: [
          this.translate.instant('toleranceThreshold.chart.contracted'),
          this.translate.instant('toleranceThreshold.types.CONSUMPTION_PLAN_RATE'),
          this.translate.instant('toleranceThreshold.types.CONTRACTED_PLAN'),
          this.translate.instant('toleranceThreshold.types.AVERAGE_CONSUMPTION'),
          this.translate.instant('toleranceThreshold.types.EXPECTED_PLAN'),
          this.translate.instant('toleranceThreshold.types.PERIOD_AVERAGE')
        ],
        labels: {
          formatter() {
            return `<span style="color: ${(this.value as string).indexOf('*') != -1 ? 'red' : '#707070'}">${this.value}</span>`;
          }
        },
        tickInterval: 1,
        tickWidth: 1,
      },
      yAxis: [{
        id: 'yAxis-energy',
        softMin: 0,
        tickAmount: 9,
        alignTicks: false,
        title: {
          text: this.translate.instant('toleranceThreshold.chart.energy')
        }
      }
        ,
        {
          id: 'yAxis-percentage',
          linkedTo: 0,
          alignTicks: false,
          tickPositioner: function () {
            return _this.tickPositions;
          },
          labels: {
            formatter: function () {
              return _this.tickValues[_this.tickPositions.indexOf(this.value)] + ' %';
            }
          },
          title: {
            text: ''
          },
          plotBands: [{
            color: 'rgba(255, 0, 0, 0.1)'
          }],
          plotLines: [
            {
              color: '#ff0000',
              dashStyle: 'ShortDash',
              width: 2,
              label: {
                text: this.translate.instant('toleranceThreshold.chart.warnUpper'),
                align: 'left'
              },
              zIndex: 5
            },
            {
              color: '#ff0000',
              dashStyle: 'ShortDash',
              width: 2,
              label: {
                text: this.translate.instant('toleranceThreshold.chart.warnLower'),
                align: 'left',
                y: 12
              },
              zIndex: 5
            }
          ],
          opposite: true
        }
      ],
      plotOptions: {
        column: {
          borderWidth: 0,
          stacking: 'normal',
          showInLegend: false,
          dataLabels: {
            color: (Highcharts.theme && (Highcharts.theme as any).dataLabelsColor) || 'white'
          }
        }
      },
      tooltip: {
        shared: true,
        valueDecimals: 2,
        useHTML: true,
        headerFormat: '<div style="padding: 0 0 5px 10px"><b>{point.key}</b></div><table>',
        pointFormatter: function () {
          const valueFn = (value: number) => _this.decimalPipe.transform(value, '1.3-3') + ' MWh';
          return `<tr style="color: ${this.color}">
                                <td style="padding-right: 10px">
                                    <b>${this.series.name}</b>
                                </td>
                                <td style="text-align: right">
                                    <b>${valueFn(this.y)}</b>
                                </td>
                            </tr>`;
        },
        footerFormat: `    <tr style="border-top: 1px solid">
                                        <td style="padding-right: 10px; font-style: italic"><b>Total:</b></td>
                                        <td style="text-align: right; font-style: italic"><b>{point.total:.3f} MWh</b></td>
                                   </tr>
                                </table>`

      },
      series: [
        {
          id: 'series-contracted',
          type: 'column',
          name: this.translate.instant('toleranceThreshold.chart.contracted'),
          color: '#8c8c8c'
        },
        {
          id: 'series-planned',
          type: 'column',
          name: this.translate.instant('toleranceThreshold.chart.planned'),
          color: '#f77f45'
        },
        {
          id: 'series-consumption',
          type: 'column',
          name: this.translate.instant('toleranceThreshold.chart.consumption'),
          color: '#4472C4'
        }
      ],
      exporting: {
        buttons: {
          contextButton: {
            align: 'left',
            x: -10,
            y: -10
          }
        },
        chartOptions: {
          chart: {
            marginRight: 60,
          }
        }
      }
    });
    this.chart.ref$.subscribe(result => {
      this.chartRef = result;
    })
    return this.chart;
  }

  public update(data: ToleranceData[]) {
    const contracted: number[] = data.map(t => null);
    contracted.unshift(data[0].sumContracted);

    const planned: number[] = data.map(t => t.sumPlan);
    planned.unshift(null);

    const consumption: number[] = data.map(t => t.sumFact);
    consumption.unshift(null);

    let contractedChart: any = this.chartRef.get('series-contracted');
    contractedChart.update({
      data: contracted
    }, true);


    //let seriesPlanned:any = this.chart.ref.get('series-planned');
    let plannedChart: any = this.chartRef.get('series-planned');
    plannedChart.update({
      data: planned
    }, true);

    //let seriesConsumption:any = this.chart.ref.get('series-consumption');
    let consumptionChart: any = this.chartRef.get('series-consumption');
    consumptionChart.update({
      data: consumption
    }, true);


    const xAxis: any = this.chartRef.get('xAxis');
    data
      .forEach((t, i) => {
        if (!t.contracted || t.contracted.length == 0) {
          xAxis.options.categories[i + 1] = this.translate.instant('toleranceThreshold.types.' + t.type) + ' *';
        } else {
          xAxis.options.categories[i + 1] = this.translate.instant('toleranceThreshold.types.' + t.type);
        }
      });

    const yAxis: any = this.chartRef.get('yAxis-percentage');
    this.contractedConsumption = data[0].sumContracted;
    if (data && data.length > 0) {
      yAxis.options.plotBands[0].from = this.percentToPower(data[0].lowerBound);
      yAxis.options.plotBands[0].to = this.percentToPower(data[0].upperBound);
      if (this.contractedConsumption) {
        this.tickPositions = [
          this.roundToTwoDigits(this.percentToPower(data[0].lowerBound)),
          this.roundToTwoDigits(this.percentToPower(data[0].upperBound))];
        this.tickValues = [data[0].lowerBound, data[0].upperBound];
      } else {
        this.tickPositions = [];
      }
    } else {
      this.tickPositions = [];
      yAxis.options.plotBands[0].from = null;
      yAxis.options.plotBands[0].to = null;
    }

    const yAxisEnergy: any = this.chartRef.get('yAxis-energy');
    let max = this.percentToPower(data[0].upperBound);
    for (let i = 0; i < 5; i++) {
      let sumAmount = contracted[i] + planned[i] + consumption[i];
      if (sumAmount > max) {
        max = sumAmount;
      }
    }
    yAxisEnergy.update({
      max: max
    });
    xAxis.update();


  }

  public clear() {
    let seriesContracted: any = this.chart.ref.get('series-contracted');
    seriesContracted.update({
      data: []
    }, true);

    let seriesPlanned: any = this.chart.ref.get('series-planned');
    seriesPlanned.update({
      data: []
    }, true);

    let seriesConsumption: any = this.chart.ref.get('series-consumption');
    seriesConsumption.update({
      data: []
    }, true);

    const yAxis: any = this.chart.ref.get('yAxis-percentage');
    yAxis.options.plotBands[0].from = null;
    yAxis.options.plotBands[0].to = null;
    yAxis.options.plotLines[0].value = null;
    yAxis.options.plotLines[1].value = null;
  }

  public refreshWarnPlotLines(value: number, plotLines: number) {
    if (this.contractedConsumption) {
      const yAxis: any = this.chart.ref.get('yAxis-percentage');
      yAxis.options.plotLines[plotLines].value = this.percentToPower(value);
      yAxis.update();
    }
  }

  private roundToTwoDigits(x: number) {
    return Math.round(x * 100) / 100;
  }

  private percentToPower(percent: number) {
    return percent * this.contractedConsumption / 100;
  }

}
