import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {BootstrapAlertService} from '../shared/services/bootstrap-alert.service';

@Component({
    selector: 'jhi-contact-page',
    templateUrl: './contact-page.component.html',
    styles: []
})
export class ContactPageComponent implements OnInit {

    message: string;

    constructor(private http: HttpClient,
                private alertService: BootstrapAlertService) {
    }

    ngOnInit() {
    }

    submit() {
        this.http.post('api/email_template/send_user_message_to_admin', this.message).subscribe(() => {
            this.alertService.showSuccess('news.modal.message.success');
        });
    }

}
