<mat-card *ngFor="let price of model; let i = index">
    <mat-card-title>
        <h4 class="card-title">{{i+1}}.
            <span jhiTranslate="agreement.agreementData.deliveryPeriods.cardTitle"></span>
        </h4>
    </mat-card-title>
    <mat-card-content>
        <div class="form-group row mt-3">
            <label class="col-sm-4" for="startTime" jhiTranslate="agreement.agreementData.deliveryPeriods.startTime"></label>
            <div class="col-sm-8">
                <strong>{{price.startTime | cezDate}}</strong>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-4" for="endTime" jhiTranslate="agreement.agreementData.deliveryPeriods.endTime"></label>
            <div class="col-sm-8">
                <strong>{{price.endTime | cezDate}}</strong>
            </div>
        </div>
        <div class="form-group row" *ngIf="productType === 'FIXED_PRICE' || productType === 'MULTI_SOURCE'">
            <label class="col-sm-4" for="price" jhiTranslate="agreement.agreementData.deliveryPeriods.price"></label>
            <div class="col-sm-8">
                <strong>{{price.price}}</strong>
            </div>
        </div>
        <div class="form-group row" *ngIf="productType === 'STEP_BY_STEP' || productType === 'CASH_OUT'">
            <label class="col-sm-4" for="formula" jhiTranslate="agreement.agreementData.deliveryPeriods.formula"></label>
            <div class="col-sm-8">
                <strong>{{price.priceFormula}}</strong>
            </div>
        </div>
        <div class="form-group row" *ngIf="goO">
            <label class="col-sm-4" for="priceOfGoo" jhiTranslate="agreement.agreementData.deliveryPeriods.priceOfGoo"></label>
            <div class="col-sm-8">
                <strong>{{price.priceOfGoo}}</strong>
            </div>
        </div>
    </mat-card-content>
</mat-card>
