<ng-container *ngIf="linkCategories?.length">
<ul ngbNav #nav="ngbNav" class="nav-tabs" tabSave="link-tabs" #tabSave="tabSave"
    [tabSaveTabsLoaded]="linkCategories?.length > 0">

  <ng-container *ngFor="let category of linkCategories">

    <li [ngbNavItem]="category.id">
      <a ngbNavLink>{{ category.name }}</a>
      <ng-template ngbNavContent>
        <useful-links *ngIf="tabSave.tabLoaded" [categoryId]="category.id"></useful-links>
      </ng-template>
    </li>
  </ng-container>
</ul>
<div [ngbNavOutlet]="nav"></div>

</ng-container>
