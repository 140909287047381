<header>
    <h2 jhiTranslate="global.menu.account.settings"></h2>
</header>
<h6 class="mt-4 mb-3">
    <strong jhiTranslate="home.homeLayoutText"></strong>
</h6>
<section class="adminpanel">
    <ng-container *ngFor="let option of layoutOptions">
        <div class="settings" [class.active]="option.active" [ngClass]="option.cssClass" (click)="setActiveLayout(option)"></div>
    </ng-container>
</section>
<section>
    <h6 class="mt-4 mb-3">
        <strong jhiTranslate="home.chartOrder"></strong>
    </h6>
    <div class="docBackground">
        <div class="form-group dropdownConatiner">
            <label>
                <strong>+</strong>
            </label>
            <select [(ngModel)]="usersettings.selectedGraphPlus" (change)="setGraphicon()" class="form-control form-control mx-sm-3"
                disabled>
                <option></option>
                <option *ngFor="let dt of dynamicTemplates" [ngValue]="dt.id">{{dt.name}}</option>
            </select>
        </div>
        <div class="form-group dropdownConatiner">
            <label>
                <strong>1.</strong>
            </label>
            <select [(ngModel)]="usersettings.selectedGraph1" (change)="setGraphicon()" class="form-control form-control mx-sm-3">
                <option></option>
                <option *ngFor="let dt of dynamicTemplates" [ngValue]="dt.id">{{dt.name}}</option>
            </select>
        </div>
        <div class="form-group dropdownConatiner">
            <label>
                <strong>2.</strong>
            </label>
            <select [(ngModel)]="usersettings.selectedGraph2" (change)="setGraphicon()" class="form-control form-control mx-sm-3">
                <option></option>
                <option *ngFor="let dt of dynamicTemplates" [ngValue]="dt.id">{{dt.name}}</option>
            </select>
        </div>
        <div class="form-group dropdownConatiner">
            <label>
                <strong>3.</strong>
            </label>
            <select [(ngModel)]="usersettings.selectedGraph3" (change)="setGraphicon()" class="form-control form-control mx-sm-3">
                <option></option>
                <option *ngFor="let dt of dynamicTemplates" [ngValue]="dt.id">{{dt.name}}</option>
            </select>
        </div>
        <div class="form-group dropdownConatiner">
            <label>
                <strong>4.</strong>
            </label>
            <select [(ngModel)]="usersettings.selectedGraph4" (change)="setGraphicon()" class="form-control form-control mx-sm-3">
                <option></option>
                <option *ngFor="let dt of dynamicTemplates" [ngValue]="dt.id">{{dt.name}}</option>
            </select>
        </div>
    </div>
</section>
