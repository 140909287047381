import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {finalize} from 'rxjs/operators';

import {InvoiceReportResultByIntervalDto} from './model/byinterval/invoice-report-result-by-interval-dto';
import {FilterParam} from './model/filter-event.model';
import {InvoiceReportResponse, InvoiceReportResponseInvoice} from './model/response.model';
import {ResultViewType} from './model/result-view-type.enum';
import {ScopeType} from './model/scope.model';
import {PieChartData} from './model/pie-chart-data';
import moment from "moment-timezone";
import {MatStep, MatStepper} from "@angular/material/stepper";
import {Currency} from '../shared/dto/currency.enum';
import {StateStorageService} from '../shared/auth';
import {InvoiceReportService} from '../shared/services/invoice-report.service';
import {TranslateLocalizedText} from '../shared/pipes/translate-localized-text';
import {InvoiceResourceDTO} from '../admin/invoice-resource/model/invoice-reosurce.model';
import {DateInterval} from '../shared/dto/date-interval.model';

@Component({
    selector: 'jhi-invoice-report',
    templateUrl: './invoice-report.component.html',
    styleUrls: ['./invoice-report.component.scss']
})
export class InvoiceReportComponent implements OnInit {

    @ViewChild(MatStepper, {static: true})
    stepper: MatStepper;

    loadingOverlay = false;
    tableData: InvoiceReportResponse;
    invoiceReportResponseByInterval: InvoiceReportResultByIntervalDto;
    pieChartData: PieChartData;
    invoices: InvoiceReportResponseInvoice[];

    scopes = ScopeType;
    resultViews = ResultViewType;
    currencies = Currency;

    scope: UntypedFormControl = new UntypedFormControl(ScopeType.CONTRACT, Validators.required);
    resultView: UntypedFormControl = new UntypedFormControl('', Validators.required);
    currency: UntypedFormControl = new UntypedFormControl(Currency.HUF, Validators.required);
    filterControl: UntypedFormControl = new UntypedFormControl(InvoiceReportComponent.createInitialFilterParameters());

    hasAnyOfThePermissions = false; // skip preloading filter data if there are no permissions

    readonly permissionMap = new Map<ResultViewType, string>([
        [ResultViewType.TABLE, 'INVOICE_REPORT_TABLE'],
        [ResultViewType.COLUMNDIAGRAM, 'INVOICE_REPORT_BAR_CHART'],
        [ResultViewType.PIECHART, 'INVOICE_REPORT_PIE_CHART']
    ]);

    static createInitialFilterParameters(): FilterParam {
        return {
            intervals: [{from: moment().startOf('month'), to: moment().startOf('month').add(1, 'month')}],
            resources: [],
            pods: [],
            podGroups: [],
            resolution: null,
            unitCost: null
        };
    }

    constructor(private stateStorageService: StateStorageService,
                private invoiceReportService: InvoiceReportService,
                private i18nPipe: TranslateLocalizedText,
                private cd: ChangeDetectorRef) {
    }

    ngOnInit() {
        const permissions = this.stateStorageService.getEffectivePermissions();
        const defaultSelectedEntry: [ResultViewType, string] = Array.from(this.permissionMap.entries()).find(([_, permission]) => permissions.indexOf(permission) > -1);
        if (defaultSelectedEntry) {
            this.hasAnyOfThePermissions = true;
            this.resultView.setValue(defaultSelectedEntry[0]);
        }
    }

    scopeStepCompleted() {
        return this.scope.valid && this.scope.pristine && this.resultView.valid && this.resultView.pristine;
    }

    goToFilterStep(stepOne: MatStep) {
        if (this.scope.dirty || this.resultView.dirty) {
            this.filterControl.setValue(this.filterControl.value);
        }
        this.scope.markAsPristine();
        this.resultView.markAsPristine();
        this.cd.detectChanges();
        this.stepper.next();
    }

    filterStepCompleted() {
        if (this.scope.dirty || this.resultView.dirty) {
            return false;
        }

        if (!this.scopeStepCompleted()) {
            return false;
        }

        if (this.filterControl.validator == null || this.filterControl.invalid) {
            return false;
        }

        if (this.filterControl.dirty) {
            return false;
        }

        return true;
    }

    getParameterLabel() {
        return [
            this.filterControl.value.intervals ? this.filterControl.value.intervals.map(interval => this.stringifyInterval(interval)).join(', ') : '',
            this.filterControl.value.resources ? this.filterControl.value.resources.map(resource => this.getResourceName(resource)).join(', ') : '',
            this.filterControl.value.pods ? this.filterControl.value.pods.map(pod => pod.podCode).join(', ') : '',
            this.filterControl.value.podGroups ? this.filterControl.value.podGroups.map(group => group.name).join(', ') : ''
        ].filter(Boolean).join(' | ');
    }

    private stringifyInterval(interval: DateInterval) {

        return interval.from.clone().add(1, 'month').isSame(interval.to) ?
            `${interval.from.format('YYYY.MMMM')}` : `${interval.from.format('YYYY.MMMM')} - ${interval.to.clone().subtract(1, 'month').format('YYYY.MMMM')}`;
    }

    private getResourceName(resource: InvoiceResourceDTO) {
        return this.i18nPipe.transform({textHU: resource.nameHu, textEN: resource.nameEn});
    }

    goToStep3(shouldCompare: boolean) {
        this.filterControl.markAsPristine();
        this.cd.detectChanges();
        this.tableData = null;
        this.invoiceReportResponseByInterval = null;
        this.pieChartData = null;

        switch (this.resultView.value) {
            case 'TABLE':
                this.createTable();
                break;
            case  'COLUMNDIAGRAM':
                this.createColumnDiagram(shouldCompare);
                break;
            case  'PIECHART':
                this.createPieChart();
                break;
        }
    }

    createTable() {
        const resourceIds = this.filterControl.value.resources.map(i => i.resourceId);
        const dateIntervals = this.filterControl.value.intervals;
        const partnerId = this.stateStorageService.getSelectedCompanyId();
        const currency: Currency = this.currency.value;
        let podIds = [];
        let podGroupIds = [];

        if (this.scope.value === ScopeType.POD) {
            podIds = this.filterControl.value.pods.map(pod => pod.id);
            podGroupIds = this.filterControl.value.podGroups.map(podGroup => podGroup.id);
        }

        this.loadingOverlay = true;
        this.invoiceReportService.getInvoicesForPods(partnerId, dateIntervals, resourceIds, podIds, podGroupIds, currency, null)
            .pipe(finalize(() => {
                this.loadingOverlay = false;
            }))
            .subscribe(value => {
                this.tableData = value;
                this.invoices = value.invoices;
                this.stepper.next();
            });
    }

    createColumnDiagram(shouldCompare: boolean) {
        const partnerId = this.stateStorageService.getSelectedCompanyId();
        const resourceIds = this.filterControl.value.resources.map(i => i.resourceId);
        const resolution = (<FilterParam>this.filterControl.value).resolution;
        const unitCost = this.filterControl.value.unitCost;
        const currency: Currency = this.currency.value;
        let podIds = [];
        let podGroupIds = [];

        if (this.scope.value === ScopeType.POD) {
            podIds = this.filterControl.value.pods.map(pod => pod.id);
            podGroupIds = this.filterControl.value.podGroups.map(podGroup => podGroup.id);
        }

        this.loadingOverlay = true;
        this.invoiceReportService.getInvoicesForColumnDiagram(partnerId, this.filterControl.value.intervals, resolution, resourceIds, podIds, podGroupIds, unitCost, currency, null, shouldCompare)
            .pipe(finalize(() => {
                this.loadingOverlay = false;
            }))
            .subscribe(value => {
                this.invoiceReportResponseByInterval = value;
                this.invoices = value.invoices;
                this.stepper.next();
            });

    }

    createPieChart() {
        const partnerId = this.stateStorageService.getSelectedCompanyId();
        const resourceIds = this.filterControl.value.resources.map(i => i.resourceId);
        const unitCost = this.filterControl.value.unitCost;
        const currency: Currency = this.currency.value;
        let podIds = [];
        let podGroupIds = [];

        if (this.scope.value === ScopeType.POD) {
            podIds = this.filterControl.value.pods.map(pod => pod.id);
            podGroupIds = this.filterControl.value.podGroups.map(podGroup => podGroup.id);
        }

        this.loadingOverlay = true;
        this.invoiceReportService.getInvoicesForPieChart(partnerId, this.filterControl.value.intervals, resourceIds, podIds, podGroupIds, unitCost, currency, null)
            .pipe(finalize(() => {
                this.loadingOverlay = false;
            }))
            .subscribe(value => {
                this.pieChartData = value;
                this.invoices = value.invoices;
                this.stepper.next();
            });

    }

    showDisabled(): boolean {
        return this.filterControl.invalid ||
            this.filterControl.value.intervals.length > 1 && this.resultView.value === this.resultViews.COLUMNDIAGRAM;
    }

    compareDisabled(): boolean {
        return this.scope.value === ScopeType.CONTRACT && this.filterControl.value.intervals.length < 2;
    }

    compareUnavailable(): boolean {
        return this.resultView.value !== ResultViewType.COLUMNDIAGRAM;
    }
}
