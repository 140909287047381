export class TolerancePeriod {
    startTime: Date;
    startTimeNgB: any;
    endTime: Date;
    endTimeNgB: any;    
    id: number;
    contractedQuantityMWh: number; 
    toleranceBandDownPercent: number;
    toleranceBandUpPercent: number;
    priceOfDownwardDeviation: string;
    priceOfUpwardDeviation: string;
}
