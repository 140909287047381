<div>
    <div class="row">
        <div class="col-md-8 offset-md-2">
            <h1 jhiTranslate="reset.request.title">Reset your password</h1>

            <div class="alert alert-warning" *ngIf="!success">
                <span jhiTranslate="reset.request.messages.info"></span>
            </div>

            <div class="alert alert-success" *ngIf="success">
                <span jhiTranslate="reset.request.messages.success"></span>
            </div>

            <form *ngIf="!success" name="form" role="form" (ngSubmit)="requestReset()" #resetRequestForm="ngForm">
                <div class="form-group">
                    <label class="form-control-label" for="email" jhiTranslate="global.form.email">E-mail</label>
                    <input type="email" email class="form-control" id="email" name="email" placeholder="{{'global.form.email.placeholder' | translate}}"
                           [(ngModel)]="resetAccount.email" minlength=5 maxlength=100 #emailInput="ngModel" required>
                    <div *ngIf="emailInput.dirty && emailInput.invalid">
                        <small class="form-text text-danger"
                           *ngIf="emailInput.errors.required" jhiTranslate="global.messages.validate.email.required">
                            Your e-mail is required.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="emailInput.errors.email" jhiTranslate="global.messages.validate.email.invalid">
                            Your e-mail is invalid.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="emailInput.errors.minlength" jhiTranslate="global.messages.validate.email.minlength">
                            Your e-mail is required to be at least 5 characters.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="emailInput.errors.maxlength" jhiTranslate="global.messages.validate.email.maxlength">
                            Your e-mail cannot be longer than 100 characters.
                        </small>
                    </div>
                </div>
                <button type="submit" [disabled]="resetRequestForm.form.invalid" class="btn btn-primary" jhiTranslate="reset.request.form.button">Reset</button>
            </form>

        </div>
    </div>
</div>
