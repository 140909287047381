import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {CoverageTransactionsOverviewComponent} from "./overview/coverage-transactions-overview.component";
import {AgreementModel} from '../shared/dto/agreement.model';
import {DeliveryPeriodDto} from '../shared/dto/delivery-period-dto';
import {DailyPricesService} from '../shared/services/daily-prices.service';
import {DailyRatesDto} from '../shared/daily-rates/model/DailyRatesDto';
import {TabSave} from '../shared/directives/tab-save/tab-save.type';
import {CoverageTransactionsPeriodTabService} from "./coverage-transactions-period-tab.service";
import {CoverageTransactionsUtil} from "./coverage-transactions.util";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalStorageService} from "ngx-webstorage";
import * as _ from "lodash";

@Component({
    selector: 'jhi-coverage-transactions',
    templateUrl: './coverage-transactions.component.html',
    styleUrls: ['coverage-transactions.component.scss']
})
export class CoverageTransactionsComponent implements OnInit, OnDestroy {

    @ViewChild('overviewTab', {static: true}) overviewTab: CoverageTransactionsOverviewComponent;

    public agreements: AgreementModel[] = [];
    public deliveryPeriods: DeliveryPeriodDto[] = [];

    private destroy: Subject<void> = new Subject<void>();
    //fixme: the whole centralized routing things must be removed
    //temp solution
    private tabRouteMapping: {} = {
        'buyingTab': '/hedge-transaction/transactions',
        'overviewTab': '/hedge-transaction/overview',
        'priceMonitoringTab': '/hedge-transaction/price-monitoring',
        'cartTab': '/hedge-transaction/cart'
    };

    constructor(private dailyPricesService: DailyPricesService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private $localStorage: LocalStorageService,
                private coverageTransactionsPeriodTabService: CoverageTransactionsPeriodTabService) {

        //if selected partner changes, we need to reload the hedge pages
        this.$localStorage.observe('company')
            .pipe(takeUntil(this.destroy))
            .subscribe((company) => {
            if (coverageTransactionsPeriodTabService.selectedCompanyId.getValue() !== company.id) {
                location.reload();
            }
        });
    }

    ngOnInit(): void {
        this.getAgreementByPartner();
        this.getDeliveryPeriods();
        this.triggerRouteChange();
        this.clearSessionStorage();
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
        this.clearSessionStorage();
    }

    isCartUrl(): boolean {
        return _.includes(this.router.url, '/cart');
    }

    private getAgreementByPartner(): void {
        this.coverageTransactionsPeriodTabService.agreements
            .pipe(takeUntil(this.destroy))
            .subscribe(value => this.agreements = value);
    }

    private getDeliveryPeriods(): void {
        this.coverageTransactionsPeriodTabService.deliveryPeriods
            .pipe(takeUntil(this.destroy))
            .subscribe(value => this.deliveryPeriods = value);
    }

    //fixme: the whole centralized routing things must be removed
    private triggerRouteChange(): void {
        this.dailyPricesService.changedSelectedRate.pipe(takeUntil(this.destroy)).subscribe((rate: DailyRatesDto) => {
            if (!!rate && !!this.dailyPricesService.navigateTo) {
                this.router.navigate([this.tabRouteMapping[this.dailyPricesService.navigateTo]]);
            } else if (this.dailyPricesService.navigateBack) {
                this.router.navigate([this.tabRouteMapping[this.dailyPricesService.navigateBack]]);
            }
        });

        if (!!this.dailyPricesService.selectedRate && !!this.dailyPricesService.navigateTo) {
            setTimeout(() => this.router.navigate([this.tabRouteMapping[this.dailyPricesService.navigateTo]]));
        }
    }

    private clearSessionStorage(): void {
        let keys = Object.keys(sessionStorage);
        for (let key of keys) {
            if (!key.startsWith(TabSave.STORAGE_KEY_PREFIX) && !key.startsWith(CoverageTransactionsUtil.CART_ITEM_DELIVERY_PERIOD_ID_SESSION_STORE_PREFIX)) {
                sessionStorage.removeItem(key);
            }
        }
    }
}
