import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FileDto } from '../';
import { News } from '../dto/news';
import { PagerModel } from '../dto/page.model';

@Injectable()
export class NewsService {

    constructor(private http: HttpClient) {
    }

    getLast3News(): Observable<News[]> {
        return this.http.get<PagerModel<News>>('api/news?page=0&size=3').pipe(map(r => r.content));
    }

    getPicture(file: FileDto): string {
        return file && file.urlPath || "";
    }

    trimNewsText(input: string): string {
        let trimmedString: string = input;

        if (/^/.test('input')) {
            trimmedString = this.strip(input);
        }

        if (trimmedString.length > 250) {
            trimmedString = trimmedString.substr(0, 250);
            trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
            trimmedString += ' ...';
        }

        return trimmedString;
    }

    strip(html) {
        let tmp = document.createElement('DIV');
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }
}
