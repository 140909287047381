import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective implements OnInit {


  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    this.el.nativeElement.focus();
  }

}
