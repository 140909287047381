import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { SortEvent } from 'primeng/api';
import { filter, flatMap } from 'rxjs/operators';
import { Language } from '../../shared/agreement-details/agreement-enums.model';
import { DialogService } from '../../shared/dialog/dialog.service';
import { CustomFile, CustomFileType } from '../../shared/dto/custom-file.model';
import { CustomFileService } from '../../shared/services/custom-file.service';
import { CustomFileEditDialogComponent } from './edit-dialog/custom-file-edit-dialog.component';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
    selector: 'admin-file-upload',
    templateUrl: './admin-file-upload.component.html',
    styleUrls: ['./admin-file-upload.component.scss']
})
export class AdminFileUploadComponent implements OnInit {

    types = CustomFileType;
    selectedType = CustomFileType.TERMS_OF_USE;
    languages = Language;
    selectedLanguage = Language.hu;
    note = '';

    files = new Array<CustomFile>();
    selectedFile: CustomFile = null;

    constructor(private service: CustomFileService,
                private dialogService: DialogService,
                private translate: TranslateService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.refreshTable();
    }

    refreshTable() {
        this.service.listFiles().subscribe(files => this.files = files);
    }

    deleteFile() {
        this.dialogService.confirm(this.translate.instant('customFileUpload.deleteDialog.title'), this.translate.instant('customFileUpload.deleteDialog.message'))
            .pipe(
                filter(Boolean),
                flatMap(() => this.service.deleteFile(this.selectedFile.id))
            )
            .subscribe(() => {
                this.refreshTable();
                this.dialogService.deleteSuccess();
            });
    }

    uploadParams(): HttpParams {
        let httpParams = new HttpParams().set('type', this.selectedType).set('note', this.note);

        if (this.selectedLanguage) {
            httpParams = httpParams.set('lang', this.selectedLanguage);
        }
        return httpParams;
    }

    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {

            let extractFn;
            let transformFn;
            switch (event.field) {
                case 'type':
                    extractFn = data => data.type;
                    transformFn = value => this.translate.instant('customFileUpload.types.' + value);
                    break;
                case 'uploadedAt':
                    extractFn = data => data.uploadedAt;
                    transformFn = value => moment(value).valueOf();
                    break;
                default:
                    extractFn = data => data[event.field];
                    transformFn = value => value;
                    break;
            }

            const value1 = extractFn(data1);
            const value2 = extractFn(data2);
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else {
                const transformed1 = transformFn(value1);
                const transformed2 = transformFn(value2);

                if (typeof transformed1 === 'string' && typeof transformed2 === 'string')
                    result = transformed1.localeCompare(transformed2);
                else
                    result = transformed1 - transformed2;
            }

            return (event.order * result);
        });
    }

    onUpload() {
        this.refreshTable();
        this.dialogService.saveSuccess();
    }

    addFile(fileInput: HTMLInputElement) {
        fileInput.value = '';
        fileInput.click();
    }

    editFile() {
        this.dialog
            .open(CustomFileEditDialogComponent, {
                disableClose: true,
                data: this.selectedFile
            })
            .afterClosed()
            .pipe(filter((saved) => saved))
            .subscribe(() => {
                this.refreshTable();
                this.dialogService.saveSuccess();
            });
    }
}
