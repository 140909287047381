import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
    selector: 'jhi-email',
    templateUrl: './email.component.html',
    styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
