import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Chart} from 'angular-highcharts';
import {finalize} from 'rxjs/operators';
import {DiagramService} from '../../shared/services/diagram.service';
import {NgBDatePickerConvertService} from '../../shared/services/ngb.datepicker.convert.service';
import {PodValueService} from '../../shared/services/pod-value.service';
import {FilterModel} from '../filter-basic/filter.model';
import {ConsumptionDiagramType} from "../../consumption/consumption-diagram-type";

@Component({
    selector: 'jhi-performance-dispersion-diagram-column',
    templateUrl: './performance-dispersion-diagram-column.component.html',
    styles: []
})
export class PerformanceDispersionDiagramColumnComponent implements OnInit {

    @Input() widget: boolean;
    chartData: any[];
    performanceDispersionChart: Chart;
    diagramId: number;
    filterModel: FilterModel;
    loading: boolean;

    constructor(private dateConverter: NgBDatePickerConvertService,
                private podValueService: PodValueService,
                private diagramService: DiagramService,
                public translateService: TranslateService) {

    }

    ngOnInit() {
        if (!this.widget) {
            this.performanceDispersionChart = this.diagramService.createDummyChart();
        }
    }

    getReportParametersForRun(filterModel: FilterModel) {
        this.filterModel = filterModel;
        this.loading = true;
        this.diagramId = filterModel.selectedDiagram.id;
        let dateTo = {
            year: filterModel.periodParameters[0].dateTo.year,
            month: filterModel.periodParameters[0].dateTo.month,
            day: filterModel.periodParameters[0].dateTo.day
        };
        let apiParams = {
            startTime: this.dateConverter.convertToDate(filterModel.periodParameters[0].dateFrom).toISOString(),
            endTime: this.dateConverter.convertToDate(dateTo).toISOString(),
            resolution: filterModel.resolution,
            podIds: filterModel.selectedPods.map(r => {
                return r.id;
            }),
            podGroupIds: filterModel.selectedPodGroups.map(r => r.id)
        };

        this.podValueService.getValues(apiParams)
            .pipe(finalize(() => this.loading = false))
            .subscribe(r => {
                this.performanceDispersionChart = new Chart({});
                this.diagramService.createLegendText(filterModel).subscribe(legendItemText => {
                    this.calculateData(r.valuesKW, r.resolution, legendItemText);
                });
            });

    }

    calculateData(valuesKw: number[], resolution: string, legendItemText: string) {
        this.chartData = [];
        let actData: number[] = this.calculatePerformanceDispersionData(valuesKw.sort((n1, n2) => n2 - n1));

        this.chartData.unshift({
            name: legendItemText,
            color: '#f24f00',
            lineWidth: 2,
            data: actData,
            events: {
                legendItemClick: function (e) {
                    return false;
                }
            }
        });

        this.calculateChart(resolution);
    }

    calculatePerformanceDispersionData(sortedArray: number[]) {
        let arrayMiddle = sortedArray.length / 2;
        let arrayIndexParityOdd = sortedArray.length % 2 == 1;
        let median;

        if (arrayIndexParityOdd)
            median = sortedArray[Math.ceil(arrayMiddle)];
        else
            median = Math.round((sortedArray[arrayMiddle] + sortedArray[arrayMiddle + 1]) / 2);

        let modus = this.getModus(sortedArray);

        let maxValue = sortedArray[0];
        let incomingDataLength = sortedArray.length;
        let intervallNumber: number = this.calculateIntervallNumber(incomingDataLength);
        let intervallResolution = Math.ceil(maxValue / intervallNumber);

        let xAxisCategories = Array(intervallNumber).fill('');
        for (let i = 0; i < intervallNumber; i++) {
            let bottomOfIntervallum = i * intervallResolution;
            let topOfIntervallum = (i + 1) * intervallResolution;

            xAxisCategories[i] = bottomOfIntervallum + '-' + topOfIntervallum;
        }

        let data: any[] = Array(intervallNumber).fill(0);

        for (let i = 0; i < incomingDataLength; i++) {
            let index = Math.floor(sortedArray[i] / intervallResolution);
            data[index]++;
        }

        this.markModusMedian(modus, median);
        data = data.map((r, i) => [xAxisCategories[i], r]);
        return data;
    }

    getModus(array: number[]): number {
        let frequency = {};  // array of frequency.
        let max = 0;  // holds the max frequency.
        let result = null;   // holds the max frequency element.
        array.forEach((element) => {
            frequency[element] = (frequency[element] || 0) + 1; // increment frequency.
            if (frequency[element] > max) { // is this frequency > max so far ?
                max = frequency[element];  // update max.
                result = element;          // update result.
            }
        });

        return result;
    }

    markModusMedian(modus: number, median: number) {
        this.chartData.push({
            type: 'line',
            color: 'white',
            name: `Modus: ${modus}, Median: ${median} `,
            events: {
                legendItemClick: function (e) {
                    return false;
                }
            }
        });
    }

    calculateIntervallNumber(incomingDataLength: number) {
        const MIN = 5;
        const MAX = 20;
        const MIN_DATA_LENGTH = 32; // Math.pow(2,MIN) => 2^5 = 32
        const MAX_DATA_LENGTH = 1048576; // Math.pow(2,MAX) => 2^20 = 1 048 576

        if (incomingDataLength <= MIN_DATA_LENGTH) {
            return MIN;
        } else if (incomingDataLength >= MAX_DATA_LENGTH) {
            return MAX;
        }

        let i = MIN;
        while (i < MAX) {
            if (Math.pow(2, i) <= incomingDataLength && incomingDataLength < Math.pow(2, i + 1)) {
                return i;
            }
            i++;
        }

        return MAX;
    }

    calculateChart(resolution: string) {
        let chartType = 'column';
        let xAxisType = 'category';
        let xAxisText = (resolution === 'MIN_15') ? 'consumption.kw' : 'consumption.kwh';
        let labelEnabled = true;
        let yAxisText = this.translateService.instant('consumption.frequency');
        let tooltipSuffix = this.translateService.instant('consumption.pcs');
        let diagramService = this.diagramService;
        let isMultiplePeriod: boolean = this.filterModel.periodParameters.filter(r => r.dateTo).length > 1;

        this.diagramService.createTitle(this.filterModel).subscribe(title =>
            this.performanceDispersionChart = new Chart({
                title: {
                    text: this.widget ? '' : title,
                    style: {
                        fontSize: this.widget ? '12px' : '18px'
                    }
                },
                chart: {
                    type: chartType,
                    height: this.widget ? null : '638.5px',
                    style: {
                        fontFamily: '\'Nimbus\''
                    },
                    events: this.diagramService.getWatermark(this.widget),
                    resetZoomButton: {
                        theme: {
                            style: 'opacity: 0.3',
                            states: {
                                hover: {
                                    style: 'opacity: 1'
                                }
                            }
                        }
                    }
                },
                xAxis: {
                    labels: {
                        enabled: labelEnabled
                    },
                    title: {
                        text: this.translateService.instant(xAxisText)
                    }
                },
                yAxis: {
                    title: {
                        text: yAxisText
                    }
                },
                credits: {
                    enabled: false
                },
                lang: {
                    decimalPoint: ','
                },
                legend: {
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    x: 0,
                    y: 5,
                    enabled: !this.widget
                },
                exporting: {
                    filename: title.replace(/\//ig, '_'),
                    buttons: {
                        contextButton: {
                            align: 'left',
                            x: -10,
                            y: -10
                        }
                    },
                    sourceWidth: 1180,
                    sourceHeight: 638,
                    scale: 1,
                    chartOptions: {
                        title: {
                            style: {
                                fontSize: '12px'
                            }
                        },
                        legend: {
                            enabled: true,
                            itemWidth: 400,
                            itemStyle: {
                                fontSize: '10px'
                            }
                        },
                        xAxis: {
                            labels: {
                                style: {
                                    fontSize: '10px'
                                }
                            },
                            title: {
                                style: {
                                    fontSize: '10px'
                                }
                            }
                        },
                        yAxis: {
                            labels: {
                                style: {
                                    fontSize: '10px'
                                }
                            },
                            title: {
                                style: {
                                    fontSize: '10px'
                                }
                            }
                        }
                    }
                },
                series: this.chartData,
                tooltip: {
                    useHTML: true,
                    formatter: function () {
                        return diagramService.getCommonTooltipFormatter(this, tooltipSuffix, false, resolution, ConsumptionDiagramType.DISPERSION, isMultiplePeriod);
                    }
                }
            }));
    }
}
