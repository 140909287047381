<ng-container *ngIf="contentVisible">
    <ngb-carousel *ngIf="latestNews.length > 0" class="nav-bar-padding">
        <ng-template ngbSlide *ngFor="let item of latestNews">
            <a href="#/news/{{item.id}}">
                <div class="tile">
                    <h2>{{item.title}}</h2>
                    <h5 class="text-muted">{{item.releaseTime | cezDate}}</h5>
                    {{item.summary}}
                    <footer>
                        <a href="#/news/{{item.id}}" class="nav-link">
                            <span>{{'home.readMore' | translate}}</span>
                        </a>
                    </footer>
                </div>
            </a>
            <div>
                <img class="img" *ngIf="item.picture" src="{{item.picture.urlPath}}"
                     alt="Random first slide">
            </div>
            <div class="carousel-caption">
            </div>
        </ng-template>
    </ngb-carousel>
    <div class="scrolling-element-wrapper">
        <div style="text-align: center">
            <span class="fa-stack fa-2x" (click)="goToElement(homeMainSection)">
                <i class="fa fa-circle fa-stack-2x" style="color:#f24f00;" aria-hidden="true"></i>
                <i class="fa fa-angle-down fa-stack-1x" style="color: white" aria-hidden="true"></i>
            </span>
        </div>
    </div>

    <div #homeMainSection class="container-fluid container-fluid-home nav-bar-padding">
        <section *ngIf="layoutId === 0" class="previousPayments  pt-3">
            <div class="row">
                <div class="col">
                    <h2 class="cez-chart-title">Utolsó számlák</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Beérkezés ideje</span>
                </div>
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Számlaszám</span>
                </div>
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Összeg</span>
                </div>
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Határidő</span>
                </div>
            </div>
            <div class="row cez-row-space">
                <div class="col-sm-3 col-md-3 cez-table-cell"
                     style="text-align: center; color: #f24f00; background-color: #f24f00;">
                    <span class="cez-table-text">|</span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #F86B26;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD844B;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD9B6A;">
                    <span class="cez-table-text"></span>
                </div>
            </div>
            <div class="row cez-row-space">
                <div class="col-sm-3 col-md-3 cez-table-cell"
                     style="text-align: center; color: #f24f00; background-color: #f24f00;">
                    <span class="cez-table-text">|</span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #F86B26;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD844B;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD9B6A;">
                    <span class="cez-table-text"></span>
                </div>
            </div>
            <div class="row  cez-row-space">
                <div class="col-sm-3 col-md-3 cez-table-cell"
                     style="text-align: center; color: #f24f00; background-color: #f24f00;">
                    <span class="cez-table-text">|</span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #F86B26;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD844B;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD9B6A;">
                    <span class="cez-table-text"></span>
                </div>
            </div>
        </section>
        <div class="row mb-5">
            <div class="col-sm-6 col-md-6">
                <h2 class="cez-chart-title">{{chart1Title}}</h2>
                <jhi-consumption widget="true" #chart1></jhi-consumption>
            </div>
            <div class="col-sm-6 col-md-6">
                <h2 class="cez-chart-title">{{chart2Title}}</h2>
                <jhi-consumption widget="true" #chart2></jhi-consumption>
            </div>
        </div>
        <section *ngIf="layoutId === 2" class="previousPayments  pt-3">
            <div class="row">
                <div>
                    <h2 class="cez-chart-title">Utolsó számlák</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Beérkezés ideje</span>
                </div>
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Számlaszám</span>
                </div>
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Összeg</span>
                </div>
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Határidő</span>
                </div>
            </div>
            <div class="row cez-row-space">
                <div class="col-sm-3 col-md-3 cez-table-cell"
                     style="text-align: center; color: #f24f00; background-color: #f24f00;">
                    <span class="cez-table-text">|</span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #F86B26;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD844B;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD9B6A;">
                    <span class="cez-table-text"></span>
                </div>
            </div>
            <div class="row cez-row-space">
                <div class="col-sm-3 col-md-3 cez-table-cell"
                     style="text-align: center; color: #f24f00; background-color: #f24f00;">
                    <span class="cez-table-text">|</span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #F86B26;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD844B;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD9B6A;">
                    <span class="cez-table-text"></span>
                </div>
            </div>
            <div class="row  cez-row-space">
                <div class="col-sm-3 col-md-3 cez-table-cell"
                     style="text-align: center; color: #f24f00; background-color: #f24f00;">
                    <span class="cez-table-text">|</span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #F86B26;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD844B;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD9B6A;">
                    <span class="cez-table-text"></span>
                </div>
            </div>
        </section>
        <div class="row mb-5">
            <div class="col-sm-6 col-md-6">
                <h2 class="cez-chart-title">{{chart3Title}}</h2>
                <jhi-consumption widget="true" #chart3></jhi-consumption>
            </div>
            <div class="col-sm-6 col-md-6">
                <h2 class="cez-chart-title">{{chart4Title}}</h2>
                <jhi-consumption widget="true" #chart4></jhi-consumption>
            </div>
        </div>
        <section *ngIf="layoutId === 1" class="previousPayments  pt-3">
            <div class="row">
                <div>
                    <h2 class="cez-chart-title">Utolsó számlák</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Beérkezés ideje</span>
                </div>
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Számlaszám</span>
                </div>
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Összeg</span>
                </div>
                <div class="col-sm-3 col-md-3" style="text-align: center;">
                    <span class="cez-table-order-text">Határidő</span>
                </div>
            </div>
            <div class="row cez-row-space">
                <div class="col-sm-3 col-md-3 cez-table-cell"
                     style="text-align: center; color: #f24f00; background-color: #f24f00;">
                    <span class="cez-table-text">|</span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #F86B26;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD844B;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD9B6A;">
                    <span class="cez-table-text"></span>
                </div>
            </div>
            <div class="row cez-row-space">
                <div class="col-sm-3 col-md-3 cez-table-cell"
                     style="text-align: center; color: #f24f00; background-color: #f24f00;">
                    <span class="cez-table-text">|</span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #F86B26;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD844B;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD9B6A;">
                    <span class="cez-table-text"></span>
                </div>
            </div>
            <div class="row  cez-row-space">
                <div class="col-sm-3 col-md-3 cez-table-cell"
                     style="text-align: center; color: #f24f00; background-color: #f24f00;">
                    <span class="cez-table-text">|</span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #F86B26;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD844B;">
                    <span class="cez-table-text"></span>
                </div>
                <div class="col-sm-3 col-md-3 cez-table-cell" style="text-align: center; background-color: #FD9B6A;">
                    <span class="cez-table-text"></span>
                </div>
            </div>
        </section>
    </div>
</ng-container>
