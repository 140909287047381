import { Component, Input, OnInit } from '@angular/core';
import { ContactModel } from '../../dto/contact.model';

@Component({
    selector: 'jhi-cez-contact-list',
    templateUrl: './cez-contact-list.component.html',
    styles: []
})
export class CezContactListComponent implements OnInit {

    @Input() contacts: ContactModel[] = [];

    constructor() {
    }

    ngOnInit() {
    }
}
