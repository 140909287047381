import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {HttpClient} from "@angular/common/http";
import {NgForm} from "@angular/forms";
import {concat} from "rxjs";
import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Template} from "./template.model";

@Component({
    selector: 'ngbd-modal-content',
    templateUrl: "template.modal.component.html"
})
export class TemplateModalComponent implements OnInit {
    @Input() template: Template;
    @Output() modalClose: EventEmitter<any> = new EventEmitter();
    isSaving: boolean = false;
    send: boolean;
    constructor(public activeModal: NgbActiveModal,
                private http: HttpClient) {
    }

    ngOnInit() {
    }

    save(emailEditForm: NgForm) {
        this.isSaving = true;
        this.template.message.textEN = emailEditForm.value.textEN;
        this.template.message.textHU = emailEditForm.value.textHU;
        this.template.mailTo = emailEditForm.value.mailTo;
        this.template.mailFrom = emailEditForm.value.mailFrom;
        this.template.subject.textHU = emailEditForm.value.subjectHu;
        this.template.subject.textEN = emailEditForm.value.subjectEn;
        let subscriptions = [];
        subscriptions.push(this.http.put("/api/email_template", this.template));
        if (this.send)
            subscriptions.push(this.http.post("api/email_template/send_admin_message", null));

        let observables = concat(...subscriptions);
        observables.subscribe(r => {
            this.isSaving = false;
            this.modalClose.emit();
        });
    }
}
