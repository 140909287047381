import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject } from 'rxjs';

import { User } from './user.model';
import PageRequest from '../pagination/page-request.model';

@Injectable()
export class UserService {
    private resourceUrl = 'api/users';
    private userChangeEvent: Subject<void> = new Subject();

    constructor(private http: HttpClient) {
    }

    getUserChangeEvent() {
        return this.userChangeEvent;
    }

    create(user: User): Observable<User> {
        return this.http.post<User>(this.resourceUrl, user);
    }

    update(user: User): Observable<User> {
        return this.http.put<User>(this.resourceUrl, user);
    }

    uploadPicture(userId: number, picture: File): Observable<void> {
        if (!picture)
            return EMPTY;

        let data = new FormData();
        data.append("file", picture);
        return this.http.post<void>(`/api/user/${userId}/picture_file`, data);
    }

    find(login: string): Observable<User> {
        return this.http.get<User>(`${this.resourceUrl}/${login}`);
    }

    query(req?: PageRequest): Observable<any> {
        let params: HttpParams = new HttpParams();
        if (req) {
            if (req.page > -1 && req.size) {
                params = params.append('page', req.page);
                params = params.append('size', req.size);
            }
            if (req.sort) {
                req.sort.forEach(sort => {
                    params = params.append('sort', sort);
                })
            }
        }

        return this.http.get(this.resourceUrl, {
            observe: 'response',
            params: params
        });
    }

    delete(login: string): Observable<void> {
        return this.http.delete<void>(`${this.resourceUrl}/${login}`);
    }

    updateLanguage(lang: string) {
        return this.http.put(`api/user/language/${lang}`, null);
    }
}
