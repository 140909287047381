import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AsyncSubject, Observable, of, PartialObserver, timer } from 'rxjs';
import { filter, finalize, map, share, takeWhile, tap } from 'rxjs/operators';
import { LoginService } from '../../login/login.service';
import { Principal } from '../auth';
import { SessionWarningPopupComponent } from './popup/session-warning-popup.component';
import { Subscription } from 'rxjs/internal/Subscription';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Injectable({
    providedIn: 'root'
})
export class SessionTimerService {

    private static readonly SESSION_LENGTH = 600; // 10 minutes
    private static readonly SESSION_WARNING = 120;

    timer: Observable<number>;

    timeoutNotifier: AsyncSubject<boolean> = new AsyncSubject();

    constructor(private http: HttpClient,
                private loginService: LoginService,
                private router: Router,
                private dialog: MatDialog,
                private principal: Principal) {
        this.restartSession();
    }

    public onTimeout(observer: PartialObserver<boolean>): Subscription {
        return this.timeoutNotifier.subscribe(observer);
    }

    restartSession(withPing = false) {
        if (!this.principal.isAuthenticated()) {
            this.timer = null;
            return;
        }

        (withPing ? this.sendPing() : of('pong')).subscribe(pong => {
            let hasBeenWarned = false;
            const x = timer(0, 1000).pipe(
                map(time => SessionTimerService.SESSION_LENGTH - time),
                tap(time => {
                    if (!hasBeenWarned && time <= SessionTimerService.SESSION_WARNING) {
                        this.showWarningPopup();
                        hasBeenWarned = true;
                    }
                }),
                takeWhile(time => time >= 0 && this.timer === x),
                finalize(() => {
                    if (this.timer === x) {
                        this.timeoutNotifier.next(true);
                        this.timeoutNotifier.complete();

                        this.loginService.logout('sessionExpired');
                        this.router.navigate(['/login']);
                        this.timer = null;
                    }
                }),
                share()
            );
            this.timer = x;
        });
    }

    sendPing(): Observable<string> {
        return this.http.post('api/ping', null, {responseType: 'text'});
    }

    private showWarningPopup() {
        this.dialog.open(SessionWarningPopupComponent, {disableClose: true, panelClass: 'session-warning-popup'})
            .afterClosed()
            .pipe(filter(Boolean))
            .subscribe(_ => this.restartSession(true));
    }
}
