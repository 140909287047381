<p-table [value]="model"
         sortMode="single"
         sortField="podCode"
         (sortFunction)="customSort($event)"
         [customSort]="true"
         [autoLayout]="true"
         class="table table-striped">

    <ng-template pTemplate="header">
        <tr>
            <th class="text-left" [pSortableColumn]="'podCode'">{{'agreement.agreementData.pods.podCode' | translate}}
                <p-sortIcon [field]="'podCode'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'type'">{{'agreement.agreementData.pods.type' | translate}}
                <p-sortIcon [field]="'type'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'profileType'">{{'agreement.agreementData.pods.profileType' | translate}}
                <p-sortIcon [field]="'profileType'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'zipCode'">{{'agreement.agreementData.pods.zipCode' | translate}}
                <p-sortIcon [field]="'zipCode'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'address'">{{'agreement.agreementData.pods.address' | translate}}
                <p-sortIcon [field]="'address'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'start'">{{'agreement.agreementData.pods.startTime' | translate}}
                <p-sortIcon [field]="'start'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'end'">{{'agreement.agreementData.pods.endTime' | translate}}
                <p-sortIcon [field]="'end'"></p-sortIcon>
            </th>
            <th class="text-right" [pSortableColumn]="'yearlyStandardConsumption'">{{'agreement.agreementData.pods.yearlyStandardConsumption' | translate}}
                <mat-icon matTooltip="{{'agreement.agreementData.pods.yearlyStandardConsumptionDescription' | translate}}"
                          matTooltipClass="largeFontMultiline"
                          aria-label="{{'agreement.agreementData.pods.yearlyStandardConsumptionDescription' | translate}}"
                          class="info-icon">info
                </mat-icon>
                <p-sortIcon [field]="'yearlyStandardConsumption'"></p-sortIcon>
            </th>
            <th *ngIf="adminView"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
        <tr class="table-padding">
            <td class="text-left">{{rowData.pod.podCode}}</td>
            <td>{{podService.translateProfileType(rowData.pod.podType)}}</td>
            <td>{{rowData.pod.profileType}}</td>
            <td>{{rowData.pod.zipCode}}</td>
            <td>{{podService.getPodAddress(rowData.pod)}}</td>
            <td>{{rowData.startTime | cezDate}}</td>
            <td>{{rowData.endTime | cezDate}}</td>
            <td class="text-right">{{rowData.pod.yearlyStandardConsumption | number:'1.3-3'}}</td>
            <td *ngIf="adminView" class="text-right">
                <button type="button" class="btn btn-info btn-sm" (click)="edit(rowData)">
                    <span class="fa fa-pencil"></span>
                </button>
                <button type="button" class="btn btn-danger btn-sm" (click)="delete(rowData)">
                    <span class="fa fa-remove"></span>
                </button>
            </td>
        </tr>
    </ng-template>
</p-table>
