import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'jhi-coverage-transactions-admin',
  templateUrl: './coverage-transactions.admin.component.html',
  styleUrls: ['./coverage-transactions.admin.component.scss']
})
export class CoverageTransactionsAdminComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
