import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Moment} from 'moment';
import * as moment from 'moment';

export class ReactiveFormUtil {

    /**
     * Use if you want to add required validator conditionally to a formControl.
     *
     * @param conditionFunc It is an external function that returns boolean value.
     * @return ValidatorFn Validators.required if the conditionFunc() returns true, null if the conditionFunc() returns false.
     * */
    public static conditionalRequired(conditionFunc: () => boolean): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors | null => {
            return conditionFunc() ? Validators.required(formControl) : null;
        };
    }

    /**
     * Use if you want to set minimum date validator to a formControl.
     *
     * @param minDate The minimum date, that user can select.
     * @return ValidatorFn Returns minDateValidationError if the formControl contains less date than the minDate.
     * */
    public static minDateValidator(minDate: Moment | Date): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors | null => {
            const date: Moment = moment(formControl.value);
            if (date.isBefore(moment(minDate))) {
                return { minDateValidationError: true };
            }
            return null;
        };
    }

    /**
     * Use if you want to set maximum date validator to a formControl.
     *
     * @param maxDate The maximum date, that user can select.
     * @return ValidatorFn Returns maxDateValidationError if the formControl contains bigger date than the maxDate.
     * */
    public static maxDateValidator(maxDate: Moment | Date): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors | null => {
            const date: Moment = moment(formControl.value);
            if (date.isAfter(moment(maxDate))) {
                return { maxDateValidationError: true };
            }
            return null;
        };
    }

    /**
     * Use if you want to cause formControl error according to an external logic.
     *
     * @param conditionFunc It is an external function that returns boolean value.
     * @return ValidatorFn Returns externalConditionValidationError if the conditionFunc() returns true.
     * */
    public static externalConditionValidator(conditionFunc: () => boolean): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors | null => {
            return conditionFunc() ? null : { externalConditionValidationError: true };
        };
    }

}
