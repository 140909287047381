<div class="modal-header">
    <h4 class="modal-title">{{ 'businessParameters.editDialog.title' | translate }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="this.modalClose.emit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div role="form" [formGroup]="form">
    <div class="modal-body d-flex flex-column">
        <mat-form-field>
            <input matInput
                   class="text-dark"
                   formControlName="key"
                   placeholder="{{'businessParameters.model.key' | translate }}"
                   readonly>
        </mat-form-field>
        <mat-form-field>
            <input matInput
                   class="text-dark"
                   formControlName="type"
                   placeholder="{{'businessParameters.model.type' | translate }}"
                   readonly>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'businessParameters.model.value' | translate }}</mat-label>
            <input
                matInput
                formControlName="value"
                [type]="getValueInputType()">
            <mat-error *ngIf="form.controls.value.invalid">{{ 'businessParameters.typeHint.' + businessParameter.type | translate }}</mat-error>
            <mat-hint>{{ 'businessParameters.typeHint.' + businessParameter.type | translate }}</mat-hint>
        </mat-form-field>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary"
                (click)="save()"
                [disabled]="!form.valid || isSaving">{{ 'entity.action.save' | translate }}
        </button>
    </div>
</div>
