import {Component, OnInit, ViewChild} from '@angular/core';
import {SendingStatus} from "./model/email-sending-status.enum";
import {TranslateService} from "@ngx-translate/core";
import {EmailHistory} from "./model/email-history.model";
import {EmailHistoryService} from "./service/email-history.service";
import * as _ from 'lodash';
import {SortEvent, SortMeta} from "primeng/api";
import {HttpClient} from "@angular/common/http";
import {EmailDetails} from "./model/email-content.model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {EmailContentModalComponent} from "./modal/email-content-modal.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import * as moment from "moment";
import {BootstrapAlertService} from "../../../shared/services/bootstrap-alert.service";
import {Table} from "primeng/table";
import {Moment} from 'moment';

@Component({
    selector: 'jhi-history',
    templateUrl: './email-history.component.html',
    styleUrls: ['./email-history.component.scss'],
})
export class EmailHistoryComponent implements OnInit {

    public dateRangeForm: FormGroup;
    public tableData: EmailHistory[];

    public partners: string[] = [];
    public subjects: string[] = [];
    public mailTos: string[] = [];
    public mailCcs: string[] = [];
    public mailBccs: string[] = [];
    public sendingStatuses: SendingStatus[] = [];
    public sentAts: Date[] = [];

    @ViewChild('emailHistoryTable', {static: true}) emailHistoryTable: Table;

    constructor(private translate: TranslateService,
                private historyService: EmailHistoryService,
                private http: HttpClient,
                private modalService: NgbModal,
                private bootstrapAlertService: BootstrapAlertService,
                private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.dateRangeForm = this.formBuilder.group({
            fromDate: [this.currentMoment().clone().startOf('day'), [Validators.required]],
            toDate: [this.currentMoment().clone().endOf('day'), [Validators.required]]
        });

        this.loadEmailHistory();
    }

    private currentMoment() {
        const currentYear = moment().year();
        const currentMonth = moment().month();
        const currentDay = moment().date();

        return moment([currentYear, currentMonth, currentDay]);
    }

    onSubmit() {
        const fromDate = this.dateRangeForm.value.fromDate;
        const toDate = this.dateRangeForm.value.toDate;

        if (fromDate > toDate) {
            let message = this.translate.instant("email.tabs.history.intervalError.toBeforeFrom");
            this.bootstrapAlertService.showError(message);
            return;
        } else if (Math.abs(fromDate.diff(toDate, 'days')) >= 31) {
            let message = this.translate.instant("email.tabs.history.intervalError.outOfThirtyDay");
            this.bootstrapAlertService.showError(message);
            return;
        }

        this.loadEmailHistory();
    }

    loadEmailHistory() {
        let fromDate: Moment | null = null;
        let toDate: Moment | null = null;

        if (this.dateRangeForm?.value?.fromDate) {
            fromDate = moment(this.dateRangeForm.value.fromDate);
        }
        if (this.dateRangeForm?.value?.toDate) {
            toDate = moment(this.dateRangeForm.value.toDate);
        }

        this.getEmailHistory(fromDate, toDate);
    }

    translateSendingStatus(sendingStatus: SendingStatus) {
        if (!sendingStatus) {
            return '';
        }

        return this.translate.instant('global.email.sendingStatus.' + sendingStatus);
    }

    private getEmailHistory(fromDate: Moment, toDate: Moment) {
        this.historyService.getEmailHistory(fromDate.toISOString(), toDate.toISOString())
            .subscribe((history: EmailHistory[]) => {
                this.tableData = history;
                this.createFilters(history);
            });
    }

    private createFilters(history: EmailHistory[]) {
        history.forEach((emailHistoryEntry: EmailHistory) => {
            if (!_.includes(this.partners, emailHistoryEntry.partner) && !!emailHistoryEntry.partner) {
                this.partners.push(emailHistoryEntry.partner);
            }
            if (!_.includes(this.subjects, emailHistoryEntry.subject) && !!emailHistoryEntry.subject) {
                this.subjects.push(emailHistoryEntry.subject);
            }
            if (!_.includes(this.mailTos, emailHistoryEntry.mailTo) && !!emailHistoryEntry.mailTo) {
                this.mailTos.push(emailHistoryEntry.mailTo);
            }
            if (!_.includes(this.mailCcs, emailHistoryEntry.mailCc) && !!emailHistoryEntry.mailCc) {
                this.mailCcs.push(emailHistoryEntry.mailCc);
            }
            if (!_.includes(this.mailBccs, emailHistoryEntry.mailBcc) && !!emailHistoryEntry.mailBcc) {
                this.mailBccs.push(emailHistoryEntry.mailBcc);
            }
            if (!_.includes(this.sendingStatuses, emailHistoryEntry.sendingStatus)) {
                this.sendingStatuses.push(emailHistoryEntry.sendingStatus);
            }
            if (!_.includes(this.sentAts, emailHistoryEntry.sentAt)) {
                this.sentAts.push(emailHistoryEntry.sentAt);
            }
        });

        this.sortFilters();
    }

    private sortFilters() {
        this.partners.sort();
        this.subjects.sort();
        this.mailTos.sort();
        this.mailCcs.sort();
        this.mailBccs.sort();
        this.sendingStatuses.sort((a, b) => this.translateSendingStatus(a).localeCompare(this.translateSendingStatus(b)));
        this.sentAts.sort();
    }

    sortOverview(event: SortEvent) {
        event.data.sort((data1, data2) => {
            const multiSortMeta: SortMeta[] = event.multiSortMeta;
            let result: number = null;

            if (multiSortMeta === undefined) {
                return this.sortField(event.order, event.field, data1, data2);
            } else {
                multiSortMeta.forEach((meta) => {
                    let metaResult = this.sortField(meta.order, meta.field, data1, data2);
                    result = result || metaResult;
                });
            }

            return result;
        });
    }

    private sortField(order: number, field: string, data1: EmailHistory, data2: EmailHistory) {
        let result;

        let value1 = data1[field];
        let value2 = data2[field];

        if (field === 'sendingStatus') {
            value1 = this.translateSendingStatus(data1[field]);
            value2 = this.translateSendingStatus(data2[field]);
        }

        if (value1 == null && value2 != null) {
            result = -1;
        } else if (value1 != null && value2 == null) {
            result = 1;
        } else if (value1 == null && value2 == null) {
            result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }

        return order * result;
    }

    protected filter(value: any, field: any, operator: any) {
        this.emailHistoryTable.filter(value.value, field, operator);
    }

    openModal(contentId: number, attachmentIds: number[], subject: string) {
        this.http.post<EmailDetails>('/api/email_history/data', { contentId, attachmentIds}).subscribe(data => {
            data.subject = subject;

            const modalRef = this.modalService.open(EmailContentModalComponent);
            modalRef.componentInstance.emailDetails = data;
        });
    }

    onFromDateSelected(event: any) {
        const selectedDateStart = moment(event.value).startOf('day');
        this.dateRangeForm.get('fromDate').setValue(selectedDateStart);
    }

    onToDateSelected(event: any) {
        const selectedDateEnd = moment(event.value).endOf('day');
        this.dateRangeForm.get('toDate').setValue(selectedDateEnd);
    }
}
