import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {AlertComponent} from './alert/alert.component';
import {ConfirmComponent} from './confirm/confirm.component';
import {PromptComponent} from './prompt/prompt.component';
import {
    HighchartsFullscreenComponent,
    HighchartsFullscreenData
} from '../highcharts-fullscreen/highcharts-fullscreen.component';
import {DetailsComponent, DetailsComponentData} from './details/details.component';
import {ModifyComponent, ModifyDialogData} from './modify/modify.component';
import {ErrorAlertComponent} from './error-alert/error-alert.component';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {Chart} from 'angular-highcharts';
import {MatDialogConfig} from "@angular/material/dialog";
import {PodImportAlertComponent} from './pod-import-alert/pod-import-alert.component';
import DataUpdateTrackingModel from '../dto/data-update-tracking.model';
import {LastUpdatesComponent} from './last-updates/last-updates.component';

@Injectable()
export class DialogService {

    constructor(private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private translate: TranslateService) {
    }

    alert(title: string, message: string): Observable<any> {
        return this.dialog.open(AlertComponent, {
            data: {title, message},
            disableClose: true,
        }).afterClosed();
    }

    errorAlert(title: string, errors: string[]): Observable<any> {
        return this.dialog.open(ErrorAlertComponent, {
            data: {title, errors},
            disableClose: true,
        }).afterClosed();
    }

    podImportAlert(title: string, errors: string[]): Observable<any> {
        return this.dialog.open(PodImportAlertComponent, {
            data: {title, errors},
            disableClose: true,
        }).afterClosed();
    }

    showLastUpdates(updateTrackingModel: DataUpdateTrackingModel, config?: MatDialogConfig): Observable<any> {
        return this.dialog.open(LastUpdatesComponent, {
            ...config,
            data: updateTrackingModel,
        }).afterClosed();
    }

    confirm(title: string, message: string): Observable<boolean> {
        return this.dialog.open(ConfirmComponent, {
            disableClose: true,
            data: {title, message}
        }).afterClosed();
    }

    prompt(title: string, message: string): Observable<string> {
        return this.dialog.open(PromptComponent, {
            disableClose: true,
            data: {title, message}
        }).afterClosed();
    }

    /**
     * @param data values of the details parameter are displayed without sanitization, when user input is passed in it
     * should be sanitized
     * @param config optional dialog configuration parameters
     */
    details(data: DetailsComponentData, config?: MatDialogConfig): Observable<any> {
        return this.dialog.open(DetailsComponent, {
            ...config,
            data: data
        }).afterClosed();
    }

    modify(data: ModifyDialogData): Observable<void> {
        return this.dialog.open(ModifyComponent, {
            data: data,
            hasBackdrop: true,
            disableClose: true,
        }).afterClosed();
    }

    fullscreenChart(chart: Chart, chartData?: Highcharts.SeriesOptions[]): void {
        this.dialog.open(HighchartsFullscreenComponent, {
            data: {
                chart: chart,
                chartData: chartData || []
            } as HighchartsFullscreenData,
            width: '95vw'
        });
    }

    successSnackBar(message: string, action?: string) {
        this.snackBar.open(message, action, {duration: 3000});
    }

    saveSuccess() {
        this.successSnackBar(this.translate.instant('common.result.saveSuccess'));
    }

    deleteSuccess() {
        this.successSnackBar(this.translate.instant('common.result.deleteSuccess'));
    }
}
