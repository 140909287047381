<div class="modal-header">
  <h4 class="modal-title">{{(product?.id ? 'products.modal.modifyTitle' : 'products.modal.addTitle') | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<form #productEditForm="ngForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="form-group">
      <label>{{'prod.new.category' | translate}}</label>
      <select required name="selectedCategory" [(ngModel)]="selectedCategory" class="form-control">
        <option></option>
        <option *ngFor="let item of categories" [ngValue]="item">{{item.name}}</option>
      </select>
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label>{{'prod.new.titleTextHu' | translate}}</label>
        <input required name="title.textHU" [(ngModel)]="product.title.textHU" class="form-control">
      </div>
      <div class="col-md-6 form-group">
        <label>{{'prod.new.descriptionTextHu' | translate}}</label>
        <input required name="description.textHU" [(ngModel)]="product.description.textHU" class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label>{{'prod.new.titleTextEn' | translate}}</label>
        <input required name="title.textEN" [(ngModel)]="product.title.textEN" class="form-control">
      </div>
      <div class="col-md-6 form-group">
        <label>{{'prod.new.descriptionTextEn' | translate}}</label>
        <input required name="description.textEN" [(ngModel)]="product.description.textEN" class="form-control">
        <br>
        <label jhiTranslate="documents.file"></label>
        <!-- <file-uploader [fileType]="'application/*'" (onSelection)="fileuploaderFileChange($event)" [required]="!product.id"></file-uploader>-->
        <div class="col-auto">

          <div style="display: flex; align-items: center;">
            <input placeholder="{{'select.default' | translate}}" readonly class="form-control"
                   style="width: 200px; float: left; margin-right: 0px;" matInput *ngIf="!fileInput?.httpUrl"
                   value="{{fileInput?.files[0]?.name}}"/>

            <button mat-mini-fab color="primary" (click)="addFile(fileInput)" type="button">
              <mat-icon>add</mat-icon>
            </button>
          </div>
          <input #fileInput [hidden]="true" type="file" accept="application/*"/>
        </div>

        <cez-file-upload class="col"
                         *ngIf="fileInput.value"
                         [file]="fileInput.files[0]"
                         [autoUpload]="true"
                         (onUpload)="onUpload($event)"
                         (removeEvent)="fileInput.value = ''"></cez-file-upload>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" jhiTranslate="entity.action.save"
            [disabled]="productEditForm.form.invalid || isSaving || (!product.id && !selectedFiles)">Submit
    </button>
  </div>
</form>
