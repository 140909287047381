import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, Validators} from '@angular/forms';

@Directive({
    selector: '[minNumber]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinNumberInputDirective, multi: true }]
})
export class MinNumberInputDirective implements Validator {

    @Input() minNumber: number;

    validate(control: AbstractControl): { [key: string]: any } {
        return Validators.min(this.minNumber)(control);
    }

}
