import {Injectable} from '@angular/core';
import {MenuModel} from '../../layouts/navbar/menu-model';
import {CategoryService} from '../../shared/services/category.service';

@Injectable()
export class MenuService {

    constructor(
        private categoryService: CategoryService) {
    }

    getMenuItems(permissions: string[]): MenuModel[] {
        let productSubMenus: MenuModel[] = [];

        this.categoryService.getProductCategories().subscribe(r => {
            for (let category of r) {
                productSubMenus.push(new MenuModel('products/' + category.id, category.name, null, true, true));
            }
        });

        let reportMenuItems = [
            // new MenuModel('/consumption', 'main.link.consumption', null, permissions.indexOf('CONSUMPTION_SCREEN') > -1),
            new MenuModel('/consumption-new', 'main.link.consumption', null, permissions.indexOf('CONSUMPTION_SCREEN') > -1),
            new MenuModel('/invoiceReport', 'main.link.invoiceReport', null, permissions.indexOf('INVOICE_REPORT_SCREEN') > -1),
            new MenuModel('/marketPrices', 'main.link.marketPrices', null, permissions.indexOf('PRICE_REPORT_SCREEN') > -1),
            new MenuModel('/tolerance-threshold', 'main.link.tolerancy', null, permissions.indexOf('TOLERANCE_THRESHOLD_SCREEN') > -1)
        ];

        let personalMenuItems = [
            new MenuModel('/contact-data', 'main.link.contactData', null, permissions.indexOf('CONTACT_DATA_SCREEN') > -1),
            new MenuModel('/corporate-data', 'main.link.corporateData', null, permissions.indexOf('CORPORATE_DATA_SCREEN') > -1),
            new MenuModel('/agreements', 'main.link.contract', null, permissions.indexOf('AGREEMENT_SCREEN') > -1),
            new MenuModel('/invoices', 'main.link.bills', null, permissions.indexOf('INVOICE_SCREEN') > -1),
            new MenuModel('/scheduling', 'main.link.scheduling', null, permissions.indexOf('BOOKED_PERFORMANCE_SCREEN') > -1),
            new MenuModel('/podgroup', 'agreement.pod_groups.title', null, permissions.indexOf('POD_GROUP_SCREEN') > -1),
            // new MenuModel('/hedge-transaction', 'main.link.coverageTransactions', null, permissions.indexOf('HEDGE_TRANSACTIONS_SCREEN') > -1),
            // new MenuModel('/hedge-consumption-plan', 'main.link.hedgeConsumptionPlan', null, permissions.indexOf('HEDGE_TRANSACTIONS_SCREEN') > -1),
            new MenuModel('/reports', 'main.link.reports', null, reportMenuItems.some(item => item.isVisible))
        ];

        let documentMenuItems = [
            new MenuModel('/documentsCommon', 'main.link.docs.common', null, permissions.indexOf('DOCUMENT_READ') > -1),
            new MenuModel('/documentsCompany', 'main.link.docs.company', null, true),
            new MenuModel('/link', 'main.link.useful', null, permissions.indexOf('USEFUL_LINK_READ') > -1)
        ];

        let newsMenuItems = [
            new MenuModel('/news/category/educational', 'main.link.news.educational', null, permissions.indexOf('NEWS_READ') > -1),
            new MenuModel('/news/category/market', 'main.link.news.market', null, permissions.indexOf('NEWS_READ') > -1),
            new MenuModel('/news/category/cez', 'main.link.news.cez', null, permissions.indexOf('NEWS_READ') > -1)

        ];

        let menuItems = [
            new MenuModel('', 'main.link.news.main', newsMenuItems, newsMenuItems.some(item => item.isVisible)),
            new MenuModel('', 'main.link.docs.main', documentMenuItems, documentMenuItems.some(item => item.isVisible)),
            new MenuModel('', 'main.link.services', productSubMenus, permissions.indexOf('PRODUCT_AND_SERVICE_READ') > -1),
            new MenuModel('', 'main.link.personal', personalMenuItems, personalMenuItems.some(item => item.isVisible)),
        ];

        return menuItems;
    }

    getHedgeMenuItems(permissions: string[]): MenuModel[] {
        return [
            new MenuModel('/hedge-transaction/overview', 'coverageTransactions.tabs.overview.title', null, permissions.indexOf('HEDGE_TRANSACTIONS_SCREEN') > -1),
            new MenuModel('/hedge-transaction/transactions', 'coverageTransactions.tabs.buying.title', null, permissions.indexOf('HEDGE_TRANSACTIONS_SCREEN') > -1),
            new MenuModel('/hedge-transaction/price-monitoring', 'coverageTransactions.tabs.priceMonitoring.title', null, permissions.indexOf('HEDGE_TRANSACTIONS_SCREEN') > -1),
            new MenuModel('/hedge-transaction/transaction-history', 'coverageTransactions.tabs.transactionOverview.title', null, permissions.indexOf('HEDGE_TRANSACTIONS_SCREEN') > -1),
            new MenuModel('/hedge-transaction/contract-parameters', 'coverageTransactions.tabs.contractParameters.title', null, permissions.indexOf('HEDGE_TRANSACTIONS_SCREEN') > -1),
            new MenuModel('/hedge-transaction/hedge-consumption-plan', 'main.link.hedgeConsumptionPlan.title', null, permissions.indexOf('CONSUMPTION_PLAN_SCREEN') > -1),
        ];
    }

}
