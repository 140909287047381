import { Route } from '@angular/router';

import { UserRouteAccessService } from '../shared/auth/user-route-access-service';
import { PartnerMandatoryGuard } from '../shared/partner-mandatory.guard';
import { ConsumptionComponent } from './consumption.component';

export const CONSUMPTION_ROUTE: Route[] = [
    {
        path: 'consumption',
        component: ConsumptionComponent,
        data: {
            permission: 'CONSUMPTION_SCREEN',
            pageTitle: 'consumption.title',
            softPermissions: [
                'CONSUMPTION_CHART_CONSUMPTION',
                'CONSUMPTION_CHART_CONSUMPTION_RANKING',
                'CONSUMPTION_CHART_DISTRIBUTION',
                'CONSUMPTION_CHART_BOX_PLOT',
                'CONSUMPTION_CHART_MIN_MAX_AVG',
                'CONSUMPTION_CHART_HEATMAP'
            ]
        },
        canActivate: [UserRouteAccessService, PartnerMandatoryGuard]
    }];
