import {Component, OnDestroy, OnInit} from '@angular/core';
import {GroupedOfferClaimDto} from '../../shared/offer-claim/model/GroupedOfferClaimDto';
import * as _ from 'lodash';
import {OfferClaimDto} from '../../shared/offer-claim/model/OfferClaimDto';
import {DeliveryPeriod} from '../../shared/dto/delivery.periods';
import {DeliveryPeriodByPartnerDto} from '../../shared/offer-claim/model/DeliveryPeriodByPartnerDto';
import {CoverageTransactionsAdminService} from '../coverage-transactions.admin.service';
import {UserDto} from '../../shared/offer-claim/model/UserDto';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {SaleStatus} from '../../shared/offer-claim/model/sale-status.enum';
import {TranslateService} from '@ngx-translate/core';
import {NgForm} from '@angular/forms';
import {PartnerWithDeliveryPeriods} from "../../shared/offer-claim/model/PartnerWithDeliveryPeriods";
import {UtilService} from '../../shared/services/util.service';
import {OfferClaimPriceType} from '../../shared/offer-claim/model/offer-claim-price-type.enum';
import {
  NewClaimDialogConfigBuilder,
  NewClaimDialogMode
} from '../../coverage-transactions/offer-claim/new-claim-dialog/new-claim-dialog.model';
import {OfferClaimService} from '../../coverage-transactions/offer-claim.service';
import {interval, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import moment, {Duration} from 'moment';
import {OfferPeriod} from '../../shared/offer-claim/model/offer-period.enum';
import {
  DeliveryPeriodOpenPositionStateDTO,
  OpenPositionStateDTO
} from '../../coverage-transactions/offer-claim/offer-claim-list/offer-claim-list.open-position-status.model';
import {DateUtil} from '../../core/util/date.util';
import {ProgressBarConfig} from '../../shared/directives/progressbar.directive';
import {
  OfferClaimFieldsForOpenPositionDTO
} from '../../coverage-transactions/offer-claim/offer-claim-list/offer-claim-list.component';
import {SortUtil} from '../../core/util/sort.util';
import {CountdownFormatFnOption} from 'ngx-countdown';
import {formatDate} from '@angular/common';

@Component({
    selector: 'jhi-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, OnDestroy {

    private readonly LIST_REFRESH_INTERVAL_MS: number = 30_000;

    public groupedOfferClaimDtos: GroupedOfferClaimDto[] = [];
    public deliveryPeriodByPartnerDtos: DeliveryPeriodByPartnerDto[] = [];
    public scheduledQuotationCountdownFormat: string;
    public deadlineOptions: string[] = [];
    public newClaimDialogOpened: boolean = false;
    public openPositionStatePartnerMap: Map<number, OpenPositionStateDTO> = new Map();

    private destroyed: Subject<void> = new Subject();

    constructor(private snackBar: MatSnackBar,
                private translate: TranslateService,
                private coverageTransactionsAdminService: CoverageTransactionsAdminService,
                public utilService: UtilService,
                public offerClaimService: OfferClaimService) {
    }

    ngOnInit(): void {
        this.initDeadlineDropdownList();
        this.scheduledQuotationCountdownFormat = this.translate.instant('offerClaim.scheduledQuotation.timer.format');
        this.getOfferClaims();

        interval(this.LIST_REFRESH_INTERVAL_MS)
            .pipe(takeUntil(this.destroyed))
            .subscribe(x => {
                this.refreshOfferClaimList();
            });
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    public getDeliveryPeriods(partnerId: number): DeliveryPeriod[] {
        let deliveryPeriodByPartnerDto: DeliveryPeriodByPartnerDto = _.find(this.deliveryPeriodByPartnerDtos, ['partnerId', partnerId]);
        return deliveryPeriodByPartnerDto.deliveryPeriods;
    }

    public getQuotationRequester(quotationRequester: UserDto): string {
        return quotationRequester != null ? `${quotationRequester.lastName} ${quotationRequester.firstName}` : '';
    }

    public isDisabledSendQuotationButton(groupedOfferClaimDto: GroupedOfferClaimDto, form: NgForm): boolean {
        return !!_.find(groupedOfferClaimDto.offerClaims, ['netPrice', null]) ||
            !!_.find(groupedOfferClaimDto.offerClaims, ['netPrice', '']) ||
            !groupedOfferClaimDto.deadline || form.invalid;
    }

    public onKeyDown(event: any) {
        // not allow negative prices
        if (event.key === '-' || (this.translate.currentLang === 'hu' && event.key === '.')) {
            event.preventDefault();
        }
    }

    public onSubmit(form: NgForm, groupedOfferClaimDto: GroupedOfferClaimDto): void {
        let minutes: number = parseInt(groupedOfferClaimDto.deadline.slice(0, 2), 0);
        let seconds: number = parseInt(groupedOfferClaimDto.deadline.slice(2, 4), 0);

        if (seconds > 59) {
            form.controls['deadline'].setErrors({"invalid": true}, {emitEvent: true});
        }

        if (form.valid) {
            let deadline = moment(new Date()).add(minutes, 'minutes').add(seconds, 'seconds').toDate();
            this.sendQuotation(groupedOfferClaimDto, deadline);
        }
    }

    public successSnackBar(message: string, action?: string) {
        this.snackBar.open(message, action, {duration: 3000});
    }

    public getProductName(offerClaim: OfferClaimDto): string {
        const period: string = offerClaim.period === OfferPeriod.Y ? 'YR' : `${offerClaim.period}${offerClaim.periodNumber}`;
        return `${period}-${new Date(offerClaim.startDate).getFullYear()} HU ${offerClaim.type}`;
    }

    public delete(offerClaimId: number): void {
        this.offerClaimService.deleteOfferClaims([offerClaimId], () => this.getOfferClaims(), () => this.getOfferClaims());
    }

    public getPeriodProgressBarLabel(periodState: DeliveryPeriodOpenPositionStateDTO): string {
        return this.translate.instant(
            "offerClaim.progressBar.periodBarLabel",
            {deliveryPeriod: `${DateUtil.transformDateToLocalFormat(periodState.startDate)} - ${DateUtil.transformDateToLocalFormat(periodState.endDate)}`}
        );
    }

    public getProgressBarConfig(agreementOpenPositionPercent: number): ProgressBarConfig {
        let agreementValue: number = +UtilService.round(agreementOpenPositionPercent, 2);
        return {
            minValue: 0,
            maxValue: 100,
            currentValue: agreementValue,
            warnBefore: 10,
            warnAfter: undefined
        };
    }

    private sendQuotation(groupedOfferClaimDto: GroupedOfferClaimDto, deadline: Date): void {
        groupedOfferClaimDto.offerClaims.forEach((offerClaim: OfferClaimDto) => {
            offerClaim.offerClaimHead.deadline = deadline;
            offerClaim.saleStatus = SaleStatus.EVALUATION;
        });

        this.coverageTransactionsAdminService.sendQuotation(groupedOfferClaimDto.offerClaims)
            .subscribe(() => {
                this.successSnackBar(this.translate.instant('common.result.saveSuccess'));
                this.getOfferClaims();
            }, (error: any) => this.getOfferClaims());
    }

    private getOfferClaims(): void {
        this.coverageTransactionsAdminService.getOfferClaims()
            .pipe(takeUntil(this.destroyed))
            .subscribe((offerClaimDtos: GroupedOfferClaimDto[]) => {
                this.updateGroupedOfferClaimDtoList(offerClaimDtos);
            });
    }

    private updateGroupedOfferClaimDtoList(offerClaimDtos: GroupedOfferClaimDto[]): void {
        this.groupedOfferClaimDtos = this.mapOfferItemsWithTimer(offerClaimDtos);
        this.getHedgeLimitDetails(this.groupedOfferClaimDtos);
        this.createDeliveryPeriods(offerClaimDtos);

        if (this.hasTimerMoreThan100Days()) {
            this.periodicallyCheckTimer();
        }
    }

    private refreshOfferClaimList(): void {
        this.coverageTransactionsAdminService.getOfferClaims()
            .pipe(takeUntil(this.destroyed))
            .subscribe((offerClaimDtos: GroupedOfferClaimDto[]) => {
                if (this.hasNewOrRemovedOfferClaim(offerClaimDtos)) {
                    this.updateGroupedOfferClaimDtoList(offerClaimDtos);
                }
            });
    }

    private hasNewOrRemovedOfferClaim(offerClaimDtos: GroupedOfferClaimDto[]): boolean {
        const currentOfferClaimIds: number[] = [];
        this.groupedOfferClaimDtos.forEach((item) => {
            currentOfferClaimIds.push(...item.offerClaims.map((offerClaim) => offerClaim.id));
        });
        const nextOfferClaimIds: number[] = [];
        offerClaimDtos.forEach((item) => {
            nextOfferClaimIds.push(...item.offerClaims.map((offerClaim) => offerClaim.id));
        });

        const removedIds: number[] = currentOfferClaimIds.filter(num => nextOfferClaimIds.indexOf(num) === -1);
        const newIds: number[] = nextOfferClaimIds.filter(num => currentOfferClaimIds.indexOf(num) === -1);

        return [...removedIds, ...newIds].length > 0;
    }

    private hasTimerMoreThan100Days(): boolean {
        return this.groupedOfferClaimDtos.some((item) => !!item.partner.isScheduledQuotationTimerMoreThan100Days);
    }

    private periodicallyCheckTimer(): void {
        interval(this.LIST_REFRESH_INTERVAL_MS)
            .pipe(takeUntil(this.destroyed))
            .subscribe(x => {
                this.groupedOfferClaimDtos.forEach((item) => {
                    if (item.partner.isScheduledQuotationTimerMoreThan100Days) {
                        this.mapOfferItemWithTimer(item);
                    }
                });
            });
    }

    private createDeliveryPeriods(groupedOfferClaimDtos: GroupedOfferClaimDto[]): void {
        groupedOfferClaimDtos.forEach((groupedOfferClaimDto: GroupedOfferClaimDto) => {
            let deliveryPeriods: DeliveryPeriod[] = [];
            groupedOfferClaimDto.offerClaims.forEach((offerClaimDto: OfferClaimDto) => {
                if (!_.find(deliveryPeriods, ['id', offerClaimDto.deliveryPeriod.id])) {
                    deliveryPeriods.push(offerClaimDto.deliveryPeriod);
                }
            });

            let deliveryPeriodByPartnerDto: DeliveryPeriodByPartnerDto = {
                partnerId: groupedOfferClaimDto.partner.id,
                deliveryPeriods: deliveryPeriods
            };
            this.deliveryPeriodByPartnerDtos.push(deliveryPeriodByPartnerDto);
        });
    }

    public addNewOfferClaim(): void {
        this.newClaimDialogOpened = true;
        this.coverageTransactionsAdminService.getPartnersWithDeliveryPeriods().subscribe((partnersWithDeliveryPeriods: PartnerWithDeliveryPeriods[]) => {
            this.offerClaimService.addNewOrEditOfferClaim(NewClaimDialogConfigBuilder
                .builder()
                .withMode(NewClaimDialogMode.ADMIN_MODE)
                .withPriceType(OfferClaimPriceType.INDIVIDUAL)
                .withAllPartner(partnersWithDeliveryPeriods)
                .build(),
                (): boolean => this.newClaimDialogOpened = false,
                (): boolean => this.newClaimDialogOpened = false);
        }, (): boolean => this.newClaimDialogOpened = false);
    }

    private mapOfferItemsWithTimer(offerClaimDtos: GroupedOfferClaimDto[]): GroupedOfferClaimDto[] {
        return offerClaimDtos.map((item: GroupedOfferClaimDto) => {
            if (item.partner.scheduledQuotation) {
                this.mapOfferItemWithTimer(item);
            }

            return item;
        });
    }

    private mapOfferItemWithTimer(item: GroupedOfferClaimDto): void {
        item.partner.scheduledQuotationTimer = moment(item.partner.scheduledQuotation).diff(moment(), 'seconds');
        item.partner.isScheduledQuotationTimerMoreThan100Days = moment(item.partner.scheduledQuotation).diff(moment(), 'days') >= 100;
    }

    private initDeadlineDropdownList() {
        for (let i = 1; i < 31; i++) {
            this.deadlineOptions.push(_.padStart(i.toString(), 2, '0') + ":00");
        }
    }

    public selectDeadLine(groupedOfferClaimDto: GroupedOfferClaimDto): (deadline: string) => void {
        return (deadline: string): void => {
            groupedOfferClaimDto.deadline = deadline;
        };
    }

    public translatePurchaseStatus(offerClaimInput: OfferClaimDto): string {
        if (!offerClaimInput) {
            return '';
        }

        if (!offerClaimInput.purchaseStatus) {
            return '';
        }

        if (this.isAveragingTransaction(offerClaimInput)) {
            return this.translate.instant('offerClaimHistory.averagingPurchaseStatus.' + offerClaimInput.purchaseStatus);
        } else {
            return this.translate.instant('offerClaimHistory.purchaseStatus.' + offerClaimInput.purchaseStatus);
        }
    }

    public calcNetPrice(rowData: OfferClaimDto): number {
        const duration: Duration = moment.duration(moment(rowData.endDate).add(1, 'day').diff(moment(rowData.startDate)));
        const durationInHours: number = duration.asHours();

        return rowData.netPrice * rowData.quantity * durationInHours;
    }

    public calcSumNetPrice(groupedOfferClaimDto: GroupedOfferClaimDto): number {
        return groupedOfferClaimDto.offerClaims
            .map((offerClaim: OfferClaimDto) => this.calcNetPrice(offerClaim))
            .reduce((previous: number, current: number) => previous + current, 0);
    }

    public priceFocusOutEvent($event: any, groupedOfferClaim: GroupedOfferClaimDto, rowData: any): void {
        this.utilService.onFocusoutBlur($event);
        this.getHedgeLimitDetails([groupedOfferClaim]);
    }

    private getHedgeLimitDetails(groupedOfferClaimDtos: GroupedOfferClaimDto[]) {
        groupedOfferClaimDtos.forEach((groupedOfferClaimDto: GroupedOfferClaimDto): void => {
            let offerClaimsForOpenPosition: OfferClaimFieldsForOpenPositionDTO[] = groupedOfferClaimDto.offerClaims.map((offerClaim: OfferClaimDto): OfferClaimFieldsForOpenPositionDTO => {
                return {
                    offerClaimId: offerClaim.id,
                    isAccepted: false,
                    price: offerClaim.netPrice
                };
            });
            this.coverageTransactionsAdminService.getIncomingHedgeLimitDetails(offerClaimsForOpenPosition).subscribe((openPositionStateDTO: OpenPositionStateDTO): void => {
                openPositionStateDTO.agreementOpenPositionStates.forEach(agreementPosition => {
                    agreementPosition.deliveryPeriodOpenPositionStates.sort((a, b) => SortUtil.sortDate(a.startDate, b.startDate));
                });
                openPositionStateDTO.agreementOpenPositionStates.sort((a, b) =>
                    SortUtil.sortDate(a.deliveryPeriodOpenPositionStates[0].startDate, b.deliveryPeriodOpenPositionStates[0].startDate));

                this.openPositionStatePartnerMap.set(groupedOfferClaimDto.partner.id, openPositionStateDTO);
            });
        });
    }

    private isAveragingTransaction(offerClaim: OfferClaimDto): boolean {
        return !!offerClaim
            && !!offerClaim.averagingStartDate
            && !!offerClaim.averagingEndDate;
    }

    public formatDate({ date, formatStr }): string {
        const duration = Number(date || 0);
        const formattedDate = formatDate(new Date(date), formatStr, 'hu');
        return formattedDate.replace('DD', '' + Math.floor(duration / (1000 * 60 * 60 * 24)));
    }
}
