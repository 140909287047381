import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { CompanyModel } from '../dto/company.model';

@Injectable({
    providedIn: 'root'
})
export class StateStorageService {
    constructor(
        private $localStorage: LocalStorageService
    ) { }

    getTermsOfServiceNeedsToBeAcceptedState(): boolean {
        return this.$localStorage.retrieve('TermsOfServiceNeedsToBeAcceptedState');
    }

    storeTermsOfServiceNeedsToBeAcceptedState(value: boolean) {
        this.$localStorage.store('TermsOfServiceNeedsToBeAcceptedState', value);
    }

    getPreviousState() {
        return this.$localStorage.retrieve('previousState');
    }

    resetPreviousState() {
        this.$localStorage.clear('previousState');
    }

    storePreviousState(previousStateName, previousStateParams) {
        let previousState = { 'name': previousStateName, 'params': previousStateParams };
        this.$localStorage.store('previousState', previousState);
    }

    storeSelectedCompany(company: CompanyModel) {
        this.$localStorage.store('company', company);
    }

    getSelectedCompanyId(): number {
        let company = <CompanyModel>this.$localStorage.retrieve('company');
        if (!company)
            return null;
        return company.id;
    }

    getSelectedCompany(): CompanyModel {
        return <CompanyModel>this.$localStorage.retrieve('company');
    }

    storeEffectivePermissions(effectivePermissions: string[]) {
        this.$localStorage.store('effectivePermissions', effectivePermissions);
    }

    getEffectivePermissions(): string[] {
        return this.$localStorage.retrieve('effectivePermissions');
    }

    storeLanguage(langKey: string) {
        this.$localStorage.store('langKey', langKey);
    }

    getLanguage(): string {
        return this.$localStorage.retrieve('langKey');
    }

    resetSelectedCompany() {
        this.$localStorage.clear('company');
    }

    getDestinationState() {
        return this.$localStorage.retrieve('destinationState');
    }

    storeDestinationState(destinationState, destinationStateParams, fromState) {
        let destinationInfo = {
            'destination': {
                'name': destinationState.name,
                'data': destinationState.data,
            },
            'params': destinationStateParams,
            'from': {
                'name': fromState.name,
            }
        };
        this.$localStorage.store('destinationState', destinationInfo);
    }

    clearStorage(): void {
        this.$localStorage.clear();
    }
}
