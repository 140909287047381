import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class AuthServerProvider {

    constructor(
        private http: HttpClient
    ) { }

    login(credentials): Observable<any> {

        let data = 'j_username=' + encodeURIComponent(credentials.username) +
            '&j_password=' + encodeURIComponent(credentials.password) +
            '&remember-me=' + credentials.rememberMe + '&submit=Login';
        if (credentials.twoFactorCode)
            data += '&j_code=' + encodeURIComponent(credentials.twoFactorCode);

        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        return this.http.post('api/authentication', data, {
            headers: headers
        });
    }

    logout(): Observable<any> {
        // logout from the server
        return this.http.post('api/logout', {}).pipe(map((response: Response) => {
            // to get a new csrf token call the api
            this.http.get('api/account').subscribe(() => { }, () => { });
            return response;
        }));
    }
}
