export class PeriodParameterModel {
    periodInDays: number;
    dateFrom: any;
    dateTo: any;
    postfix: number;
    isMultiplePeriodAllowed: boolean;
    showNextPeriod: boolean;

    constructor(periodInDays: number, postfix: number, isMultiplePeriodAllowed: boolean, showNextPeriod: boolean) {
        this.periodInDays = periodInDays;
        this.postfix = postfix;
        this.isMultiplePeriodAllowed = isMultiplePeriodAllowed;
        this.showNextPeriod = showNextPeriod;
    }
}
