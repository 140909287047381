import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {CezPortalSharedModule} from '../shared';

import {
  adminState,
  AuditsComponent,
  AuditsService,
  JhiConfigurationComponent,
  JhiConfigurationService,
  LogsComponent,
  LogsService,
  UserModalService,
  UserResolve
} from './';
import {CustomFileEditDialogComponent} from './admin-file-upload/edit-dialog/custom-file-edit-dialog.component';
import {SubscriptionModalComponent} from './company/subscription-modal/subscription-modal.component';
import {ContractModalComponent} from './contract/contract-modal/contract-modal.component';
import {InvoiceModalComponent} from './contract/invoice-modal/invoice-modal.component';
import {PodModalComponent} from './contract/pod-modal/pod-modal.component';
import {ToleranceLimitModalComponent} from './contract/tolerance-limit-modal/tolerance-limit-modal.component';
import {NewsAdminModule} from "../news.admin";

@NgModule({
  imports: [
    CezPortalSharedModule,
    RouterModule.forRoot(adminState, {useHash: true}),
    NewsAdminModule
  ],
    declarations: [
        AuditsComponent,
        LogsComponent,
        JhiConfigurationComponent,
        ToleranceLimitModalComponent,
        ContractModalComponent,
        InvoiceModalComponent,
        PodModalComponent,
        SubscriptionModalComponent,
        CustomFileEditDialogComponent
    ],
    providers: [
        AuditsService,
        JhiConfigurationService,
        LogsService,
        UserResolve,
        UserModalService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CezPortalAdminModule {}
