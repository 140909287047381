import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {PeriodParameterModel} from '../../shared/dto/period-parameter.model';

import {DiagramService} from '../../shared/services/diagram.service';
import {MultiSelectTranslationService} from '../../shared/services/multi-select-translation.service';
import {NgBDatePickerConvertService} from '../../shared/services/ngb.datepicker.convert.service';
import {PodService} from '../../shared/services/pod.service';
import {ViewTemplateService} from '../../shared/services/view-template.service';
import {FilterModel} from './filter.model';
import {NgForm} from '@angular/forms';
import {
  ConsumptionDynamicFilterService,
  FilterEvent
} from '../consumption-dynamic-filter/consumption-dynamic-filter.service';
import {ConsumptionPeriodParameterSelector} from './dynamic-day-interval-selector/period-parameter-selector.component';
import {DialogService} from '../../shared/dialog/dialog.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SaveTemplateDialogComponent} from './template-save-dialog/save-template-dialog.component';
import {ViewTemplateDto} from '../../shared/dto/view.template-dto.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import {Subscription} from 'rxjs';
import {StateStorageService} from '../../shared/auth';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {NavitaMultiSelectOption} from "../../shared/dto/common.model";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {DEFAULT_DROPDOWN_SETTINGS} from "../../core/util/constants";
import {PodModel} from "../../shared/dto/pod.model";

;

@Component({
  selector: 'jhi-filter-basic',
  templateUrl: './filter-basic.component.html',
  styleUrls: ['./filter-basic.component.scss']
})
export class FilterBasicComponent implements OnInit, OnDestroy {
  @Output() reportRunned: EventEmitter<FilterModel> = new EventEmitter();
  @Output() filterModelChanged: EventEmitter<FilterModel> = new EventEmitter();
  @Output() scheduleChanged: EventEmitter<FilterModel> = new EventEmitter();


  public dropdownSettings: IDropdownSettings = {
    ...DEFAULT_DROPDOWN_SETTINGS,
    ...this.multiSelectTranslationService.getMultiSelectTexts()
  };

  podListOptions: NavitaMultiSelectOption[];
  podGroupsOptions: NavitaMultiSelectOption[];
  podGroupDefaultTitle: string;
  podDefaultTitle: string;

  diagramListOptions: any[];
  showDiagrams: boolean;
  @Input()
  filterModel: FilterModel;
  defaultFilterModel: FilterModel;
  isTemperatureAllowed: boolean;

  showPeriod2: boolean;
  showPeriod3: boolean;
  showPeriod4: boolean;
  periodInDays: number;
  showTemperature: boolean;
  comparisonAllowed: boolean;
  resolutions: any[];
  viewTemplates: ViewTemplateDto[];
  selectedTemplate: ViewTemplateDto;
  @ViewChild('filterForm', {static: true}) public formGroup: NgForm;

  private dynamicFilterChangeSub: Subscription;

  constructor(private podService: PodService, private diagramService: DiagramService,
              private dateConverter: NgBDatePickerConvertService,
              private viewTemplateService: ViewTemplateService,
              public translateService: TranslateService,
              private dialogService: DialogService,
              private dialog: MatDialog,
              private modalService: NgbModal,
              private stateStorageService: StateStorageService,
              private multiSelectTranslationService: MultiSelectTranslationService,
              private consumptionDynamicFilterService: ConsumptionDynamicFilterService) {

    podService.getAllPodMultiSelect().subscribe(r => {
      this.podListOptions = r;
      if (this.podListOptions != null && this.podListOptions.length == 1) {
        this.filterModel.selectedPods.push(this.podListOptions[0].id.id);
      }
    });
    podService.getPodGroupsOfPartner().subscribe(r => {
      this.podGroupsOptions = r.map(podgroup => {
        return {id: podgroup.id, name: podgroup.name};
      });
    });

    // this.filterModel.selectedPods = [];
    this.periodInDays = 0;
    this.showPeriod2 = false;
    this.showPeriod3 = false;
    this.showPeriod4 = false;
    this.showDiagrams = false;
    this.showTemperature = false;
    this.comparisonAllowed = false;


  }

  ngOnInit() {
    this.defaultFilterModel = _.cloneDeep(this.filterModel);
    const permissions = this.stateStorageService.getEffectivePermissions();
    this.isTemperatureAllowed = [
      'TEMPERATURE_PAST_QUARTER_HOURLY',
      'TEMPERATURE_PAST_HOURLY',
      'TEMPERATURE_FUTURE_QUARTER_HOURLY',
      'TEMPERATURE_FUTURE_HOURLY'
    ].some(permission => permissions.indexOf(permission) > -1);

    const firstPeriod = new PeriodParameterModel(7, 1, this.filterModel.multiplePeriod, false);
    firstPeriod.dateFrom = ConsumptionPeriodParameterSelector.convertMomentToNgbDate(moment().subtract(8, 'days'));
    firstPeriod.dateTo = ConsumptionPeriodParameterSelector.convertMomentToNgbDate(moment().subtract(1, 'days'));

    this.filterModel.periodParameters = [
      firstPeriod,
      new PeriodParameterModel(this.periodInDays, 2, this.filterModel.multiplePeriod, false),
      new PeriodParameterModel(this.periodInDays, 3, this.filterModel.multiplePeriod, false),
      new PeriodParameterModel(this.periodInDays, 4, this.filterModel.multiplePeriod, false)
    ];

    this.filterModel.referenceWeek = [new PeriodParameterModel(this.periodInDays, 1, false, false)];
    this.refreshViewTemplates();


    this.diagramListOptions = this.diagramService.getAllDiagram();
    this.resolutions = this.getResolutionList();
    this.filterModel.selectedDiagram = this.diagramListOptions.find(diagram => permissions.indexOf(diagram.requiredPermission) > -1);
    if (this.filterModel.selectedDiagram) {
      this.diagramSelected();
    }
    this.podGroupDefaultTitle = this.translateService.instant('filter.basic.selectPodGroups');

    this.podDefaultTitle = this.translateService.instant('filter.basic.selectPod');
    this.listenDynamicFilterChange();
  }

  ngOnDestroy(): void {
    if (this.dynamicFilterChangeSub) {
      this.dynamicFilterChangeSub.unsubscribe();
    }
  }

  refreshViewTemplates(initialTemplateId?: number) {
    this.viewTemplateService.getChartViewTemplates().subscribe(r => {
      this.viewTemplates = r as ViewTemplateDto[];
      if (initialTemplateId) {
        this.selectedTemplate = this.viewTemplates.find(r => r.id == initialTemplateId);
      }
    });
  }

  getResolutionList() {
    return [
      {
        value: 'MIN_15',
        description: this.translateService.instant('filter.basic.min15'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_QUARTER_HOURLY'
      },
      {
        value: 'HOUR',
        description: this.translateService.instant('filter.basic.hour'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_HOURLY'
      },
      {
        value: 'DAY',
        description: this.translateService.instant('filter.basic.day'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_DAILY'
      },
      {
        value: 'WEEK',
        description: this.translateService.instant('filter.basic.week'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_WEEKLY'
      },
      {
        value: 'MONTH',
        description: this.translateService.instant('filter.basic.month'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_MONTHLY'
      },
      {
        value: 'QUARTER_OF_YEAR',
        description: this.translateService.instant('filter.basic.quarterOfYear'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_QUARTER_YEARLY'
      },
      {
        value: 'YEAR',
        description: this.translateService.instant('filter.basic.year'),
        enabled: true,
        requiredPermission: 'CONSUMPTION_RESOLUTION_YEARLY'
      }
    ];
  }

  setResolutionList(enabledList: boolean[]) {
    const permissions = this.stateStorageService.getEffectivePermissions();

    this.resolutions.forEach((resolutionOption, i) => {
      resolutionOption.enabled = enabledList[i] && permissions.indexOf(resolutionOption.requiredPermission) > -1;
    });

    const currentResolutionDisabled = this.resolutions.filter(r => r.enabled).map(r => r.value).indexOf(this.filterModel.resolution) === -1;

    if (!this.filterModel.resolution || currentResolutionDisabled) {
      const firstAllowedResolution = this.resolutions.find(r => r.enabled);
      this.filterModel.resolution = firstAllowedResolution ? firstAllowedResolution.value : null;
    }

  }

  runReport() {
    this.reportRunned.emit(this.filterModel);
    this.filterModel.comparePods = false;
  }

  diagramSelected(selectedDiagram?: any) {
    this.consumptionDynamicFilterService.diagramSelectionChanged(selectedDiagram);
    this.controlComparison(this.filterModel.selectedDiagram.comaparisonAllowed);
    if (this.filterModel.selectedDiagram.id === 2) {
      this.filterModel.temperatureChecked = false;
    }
  }

  filterValid() {
    let selectedPeriods = this.filterModel.periodParameters.filter((item, i) => {
      return (i == 0 || this.filterModel.periodParameters[i - 1].showNextPeriod);
    });

    let dateSelected = selectedPeriods.every(r => r.dateFrom && r.dateTo);

    let podSelected = this.filterModel.selectedPods.length > 0 || this.filterModel.selectedPodGroups.length > 0;

    let referenceIntervalSeleced = true;
    if (this.filterModel.referenceWeekChecked) {
      referenceIntervalSeleced = this.filterModel.referenceWeek[0].dateFrom && this.filterModel.referenceWeek[0].dateTo;
    }

    return this.filterModel.resolution && dateSelected && podSelected && selectedPeriods.length > 0 && this.formGroup.form.valid && referenceIntervalSeleced;
  }

  exportValid() {
    return this.filterValid() && !this.filterModel.periodParameters[0].showNextPeriod;
  }

  anyActionButtonEnabled() {
    return this.showEnabled() || this.podComparisionEnabled() || this.intervalComparisionEnabled();
  }

  showEnabled() {
    let selectedPeriods = this.filterModel.periodParameters.filter((item, i) => {
      return (i == 0 || this.filterModel.periodParameters[i - 1].showNextPeriod);
    }).length;
    return this.filterValid() && selectedPeriods === 1;
  }

  podComparisionEnabled() {
    let selectedPods = this.filterModel.selectedPods.length + this.filterModel.selectedPodGroups.length;
    return this.filterValid() && selectedPods > 1 && !this.intervalComparisionEnabled();
  }

  intervalComparisionEnabled() {
    const selectedPeriods = this.filterModel.periodParameters.filter((item, i) => {
      return (i == 0 || this.filterModel.periodParameters[i - 1].showNextPeriod);
    }).length;
    return this.filterValid() && selectedPeriods > 1;
  }

  controlTemperature(tempAllowed: boolean) {
    this.showTemperature = tempAllowed;
    if (!this.showTemperature) {
      this.filterModel.temperatureChecked = undefined;
    }
  }

  controlComparison(compAllowed: boolean) {
    this.comparisonAllowed = compAllowed;
    this.filterModel.multiplePeriod = compAllowed;

    this.filterModel.periodParameters.map(r => {
      r.isMultiplePeriodAllowed = this.filterModel.multiplePeriod;
      if (!r.isMultiplePeriodAllowed && r.postfix > 1) {
        r.dateFrom = undefined;
        r.dateTo = undefined;
        r.periodInDays = undefined;
        r.showNextPeriod = false;
      } else if (!r.isMultiplePeriodAllowed && r.postfix == 1) {
        r.showNextPeriod = false;
      }
    });
    // always set last for false
    this.filterModel.periodParameters[this.filterModel.periodParameters.length - 1].showNextPeriod = false;
  }

  public loadTemplate(): void {
    if (!this.selectedTemplate) {
      this.clearTemplate();
    } else {
      if (this.filterModel)
        this.filterModel.periodParameters = [];
      setTimeout(() => {
        let jsonData = JSON.parse(this.selectedTemplate.jsonData);
        if (jsonData) {
          this.filterModel = this.viewTemplateService.filterModelFromViewTemplate(jsonData, this.selectedTemplate.dynamic);
          this.filterModel.selectedDiagram = this.diagramListOptions.filter(r => r.id === jsonData.selectedDiagram.id)[0];
          this.filterModel.selectedPods = this.podListOptions.filter(r => jsonData.selectedPods.map(pod => pod.id).indexOf(r.id.id) > -1).map(r => r.id);
          this.filterModel.selectedPodGroups = this.podGroupsOptions
            .filter(r => jsonData.selectedPodGroups.map(pod => pod.id).indexOf(r.id.id) > -1)
            .map(r => r.id);
          if (!this.filterModel.referenceWeek)
            this.filterModel.referenceWeek = [new PeriodParameterModel(this.periodInDays, 1, this.filterModel.multiplePeriod, false)];

          this.controlComparison(this.filterModel.selectedDiagram.comaparisonAllowed);
          if (this.filterModel.selectedDiagram.length > 0) {
            this.filterModel.selectedDiagram = this.filterModel.selectedDiagram.filter(r => r.id === jsonData.selectedDiagram.id)[0];
          }
          this.controlTemperature(this.filterModel.selectedDiagram.temperatureAllowed);
        }
        this.filterModelChanged.emit(this.filterModel);

        // Run report automatically
        if (this.showEnabled()) {
          this.runReport();
        } else if (this.podComparisionEnabled() || this.intervalComparisionEnabled()) {
          this.filterModel.comparePods = true;
          this.runReport();
        }

      });
    }
  }

  public deleteViewTemplate(): void {
    this.dialogService.confirm(this.translateService.instant('filter.basic.deleteDialog.title'), this.translateService.instant('filter.basic.deleteDialog.message'))
      .subscribe((value: boolean): void => {
        if (value) {
          this.viewTemplateService.deleteViewTemplate(this.selectedTemplate.id).subscribe(() => {
            this.viewTemplates = this.viewTemplates.filter(r => r.id != this.selectedTemplate.id);
            this.selectedTemplate = null;
          });
        }
      });
  }

  public resetViewTemplate(): void {
    this.loadTemplate();
    setTimeout(() => {
      this.diagramSelected();
    });
  }

  public openTemplateSaveDialog(saveAsNew: boolean = false): void {
    const modalRef = this.modalService.open(SaveTemplateDialogComponent, {backdrop: 'static'});

    modalRef.componentInstance.filterModel = this.filterModel;
    modalRef.componentInstance.saveAsNew = saveAsNew;
    if (this.selectedTemplate) {
      modalRef.componentInstance.templateName = this.selectedTemplate.name;
      modalRef.componentInstance.templateId = this.selectedTemplate.id;
      modalRef.componentInstance.dynamic = this.selectedTemplate.dynamic;
      modalRef.componentInstance.userId = this.selectedTemplate.userId;
    }
    modalRef.componentInstance.modalClose.subscribe((closeEvent: any) => {
      if (closeEvent.refresh) {
        this.refreshViewTemplates(closeEvent.templateId);
      }
      modalRef.close();
    });
  }

  public podSelectionChange(): void {
    this.consumptionDynamicFilterService.filterBasicChanged({
      type: 'schedule',
      value: false
    });
  }

  private clearTemplate(): void {
    this.selectedTemplate = null;
    this.filterModel = _.cloneDeep(this.defaultFilterModel);
    this.filterModel.resolution = 'HOUR';

    const permissions = this.stateStorageService.getEffectivePermissions();

    const firstPeriod = new PeriodParameterModel(this.periodInDays, 1, this.filterModel.multiplePeriod, false);
    firstPeriod.dateFrom = ConsumptionPeriodParameterSelector.convertMomentToNgbDate(moment().subtract(8, 'days'));
    firstPeriod.dateTo = ConsumptionPeriodParameterSelector.convertMomentToNgbDate(moment().subtract(1, 'days'));

    this.filterModel.periodParameters = [
      firstPeriod,
      new PeriodParameterModel(this.periodInDays, 2, this.filterModel.multiplePeriod, false),
      new PeriodParameterModel(this.periodInDays, 3, this.filterModel.multiplePeriod, false),
      new PeriodParameterModel(this.periodInDays, 4, this.filterModel.multiplePeriod, false)
    ];

    this.filterModel.referenceWeek = [new PeriodParameterModel(this.periodInDays, 1, false, false)];

    this.filterModel.selectedDiagram = this.diagramListOptions.find(diagram => permissions.indexOf(diagram.requiredPermission) > -1);
    this.filterModelChanged.emit(this.filterModel);
    this.diagramSelected(this.filterModel.selectedDiagram);
  }

  private listenDynamicFilterChange(): void {
    this.dynamicFilterChangeSub = this.consumptionDynamicFilterService.filterChangedSubject.subscribe((event: FilterEvent) => {
      if (event.type === 'schedule') {
        if (event.value === true) {

          this.filterModel.selectedPods = this.podListOptions.map(r => {
            return {id: r.id, podCode: r.name} as PodModel;
          });


          this.filterModel.scheduleSelected = true;
          this.scheduleChanged.emit(this.filterModel);
        } else {
          this.filterModel.scheduleSelected = false;
        }
      }
    });
  }
}
