<div class="p-2">
    <span class="file-info">{{file.name}} ({{file.size | bytes}})</span>

    <section class="d-flex align-items-center" style="height: 10px">
        <mat-progress-bar *ngIf="this.httpUrl" mode="determinate" [value]="progressSubject | async"></mat-progress-bar>

        <button mat-icon-button color="primary"
                type="button"
                (click)="upload()"
                *ngIf="!isUploaded" [disabled]="isUploading">
            <mat-icon>file_upload</mat-icon>
        </button>

        <button mat-icon-button (click)="cancel()" type="button"
                *ngIf="!isUploaded" [disabled]="isUploading">
            <mat-icon>cancel</mat-icon>
        </button>

        <mat-icon *ngIf="isUploaded">done</mat-icon>
    </section>

    <span *ngIf="this.httpUrl" class="file-info">{{progressSubject | async}}%</span><span> {{loaded | bytes}} of {{total | bytes}}</span>
    <label *ngIf="!this.httpUrl">{{loaded | bytes}} of {{total | bytes}}</label>

</div>
