import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Category } from '../../shared';
import { ProductAndServicesModel } from '../../shared/dto/product-and-services.model';
import { CategoryService } from '../../shared/services/category.service';
import { DocumentFilterService } from '../../shared/services/document.filter.service';
import { ProductService } from '../../shared/services/product.service';
import { ProductModalComponent } from './product.modal.component';
import {LinkModel} from "../../links";
import {DialogService} from "../../shared/dialog/dialog.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'jhi-product',
  templateUrl: './product.component.html',
  styles: []
})
export class ProductComponent implements OnInit {
  products: ProductAndServicesModel[];
  categories: Category[];
  activatedPanelId: number;

  constructor(private modalService: NgbModal, private productService: ProductService,
    private categoryService: CategoryService, private documentFilterService: DocumentFilterService,
              private dialogService: DialogService,
              private translate: TranslateService) {

    this.refreshList();
    this.categoryService.getProductCategories().subscribe(r => this.categories = r);
  }

  ngOnInit() {
  }

  refreshList(openedProductId: number = null) {
    this.productService.getProductsAdmin().subscribe(r => {
      this.products = r;
      if (openedProductId)
        this.activatedPanelId = openedProductId;
    });
  }

  openProduct(product: ProductAndServicesModel) {
    if (!product)
      product = new ProductAndServicesModel();

      const modalRef = this.modalService.open(ProductModalComponent, {backdrop: 'static'});
    modalRef.componentInstance.product = product;
    modalRef.componentInstance.categories = this.categories;
    modalRef.componentInstance.modalClose.subscribe(() => {
      modalRef.close();
      this.refreshList();
    });
  }

  publish(id: number) {
    this.productService.setVisibility(id, true).subscribe(() => {
      this.products.filter(r => r.id === id)[0].visible = true;
    });
  }

  deactivate(id: number) {
    this.productService.setVisibility(id, false).subscribe(() => {
      this.products.filter(r => r.id === id)[0].visible = false;
    });
  }

  delete(id: number) {
      this.dialogService.confirm(this.translate.instant('filter.basic.deleteDialog.title'), this.translate.instant('filter.basic.deleteDialog.message'))
          .subscribe((value: boolean): void => {
              if (value) {
                  this.deleteConfirmed(id);
              }
          });
  }

    deleteConfirmed(id: number): void {
        this.productService.deleteProduct(id).subscribe(r => {
            this.refreshList();
        });
    }
}
