import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { InvoicePopupModel } from '../model/invoice-popup.model';
import { finalize } from 'rxjs/operators';
import { InvoiceReportResponse, InvoiceReportResponseInvoice } from '../../invoice-report/model/response.model';
import { InvoiceReportResultByIntervalDto } from '../../invoice-report/model/byinterval/invoice-report-result-by-interval-dto';
import { PieChartData } from '../../invoice-report/model/pie-chart-data';
import { ResultViewType } from '../../invoice-report/model/result-view-type.enum';
import { PopupResultModel } from './popup-result.model';
import {InvoicesService} from '../../shared/services/invoices.service';
import {InvoiceReportService} from '../../shared/services/invoice-report.service';
import {StateStorageService} from '../../shared/auth';
import {Resolution} from '../../shared/time-series/model/resolution.enum';

@Component({
    selector: 'jhi-invoice-chart-popup',
    templateUrl: './invoice-chart-popup.component.html',
    styleUrls: ['./invoice-chart-popup.component.scss']
})
export class InvoiceChartPopup implements OnInit {

    loadingOverlay = false;

    closePopup = () => this.dialogRef.close(PopupResultModel.CLOSE);

    tableData: InvoiceReportResponse;
    invoiceReportResponseByInterval: InvoiceReportResultByIntervalDto;
    pieChartData: PieChartData;
    invoices: InvoiceReportResponseInvoice[];

    invoicePopupModel: InvoicePopupModel;

    unitCost: boolean = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: InvoicePopupModel,
                private dialogRef: MatDialogRef<InvoicePopupModel>,
                private invoicesService: InvoicesService,
                private invoiceReportService: InvoiceReportService,
                private stateStorageService: StateStorageService) {
        this.invoicePopupModel = data;
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.dialogRef.close(PopupResultModel.CLOSE);
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.tableData = null;
        this.invoiceReportResponseByInterval = null;
        this.pieChartData = null;
        this.invoices = null;

        if (this.invoicePopupModel.diagramType === ResultViewType.TABLE) {
            this.createTable();
        } else if (this.invoicePopupModel.diagramType === ResultViewType.COLUMNDIAGRAM) {
            this.createColumnDiagram();
        } else if (this.invoicePopupModel.diagramType === ResultViewType.PIECHART) {
            this.createPieChart();
        }

    }

    createTable() {
        const partnerId = this.stateStorageService.getSelectedCompanyId();
        const invoiceIds: number[] = this.invoicePopupModel.invoices.map(invoice => invoice.invoiceId);

        this.loadingOverlay = true;
        this.invoiceReportService.getInvoicesForPods(partnerId, null, null, null, null, null, invoiceIds)
            .pipe(finalize(() => {
                this.loadingOverlay = false;
            }))
            .subscribe(value => {
                this.tableData = value;
                this.invoices = value.invoices;
            });
    }

    createColumnDiagram() {
        const partnerId = this.stateStorageService.getSelectedCompanyId();
        const resolution = Resolution.MONTHLY;
        const invoiceIds: number[] = this.invoicePopupModel.invoices.map(invoice => invoice.invoiceId);

        this.loadingOverlay = true;
        this.invoiceReportService.getInvoicesForColumnDiagram(partnerId, null, resolution, null, null, null, this.unitCost, null, invoiceIds, false)
            .pipe(finalize(() => {
                this.loadingOverlay = false;
            }))
            .subscribe(value => {
                this.invoiceReportResponseByInterval = value;
                this.invoices = value.invoices;
            });

    }

    createPieChart() {
        const partnerId = this.stateStorageService.getSelectedCompanyId();
        const invoiceIds: number[] = this.invoicePopupModel.invoices.map(invoice => invoice.invoiceId);

        this.loadingOverlay = true;
        this.invoiceReportService.getInvoicesForPieChart(partnerId, null, null, null, null, this.unitCost, null, invoiceIds)
            .pipe(finalize(() => {
                this.loadingOverlay = false;
            }))
            .subscribe(value => {
                this.pieChartData = value;
                this.invoices = value.invoices;
            });

    }

    unitCostSelectionChanged(unitCostEvent) {
        this.unitCost = unitCostEvent.value;
        this.refresh();
    }

    unitCostSelectorVisible(): boolean {
        return this.invoicePopupModel.diagramType === ResultViewType.COLUMNDIAGRAM ||
            this.invoicePopupModel.diagramType === ResultViewType.PIECHART;
    }

    navigateToReports() {
        this.dialogRef.close(PopupResultModel.NAVIGATE);
    }
}
