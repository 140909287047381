import {DecimalPipe} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import {PieChartData} from '../model/pie-chart-data';
import {colors} from '../../shared/constants/colors';
import {TranslateLocalizedText} from '../../shared/pipes/translate-localized-text';
import {DiagramService} from '../../shared/services/diagram.service';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'jhi-invoice-pie-chart',
    templateUrl: './invoice-pie-chart.component.html'
})
export class InvoicePieChartComponent implements OnInit {

    @Input() set unitCost(value: boolean) {
        this._unitCost = value;
    }

    @Input() set chartData(pieChartData: PieChartData) {
        this.data = pieChartData;
        this.createChart();
    }

    chart: Chart;

    options: any;
    data: PieChartData;

    _unitCost: boolean;

    constructor(private translateService: TranslateService,
                private translateLocalizedText: TranslateLocalizedText,
                private diagramService: DiagramService,
                private decimalPipe: DecimalPipe) {
    }

    ngOnInit() {
    }

    private createChart() {
        this.options = this.defaultChartOptions;

        this.options.title = {
            text: this._unitCost ? this.translateService.instant('invoiceReport.chart.unitCostTitle') : this.translateService.instant('invoiceReport.chart.costTitle')
        };

        this.options.series = [{
            colorByPoint: true,
            name: this.translateService.instant('invoiceReport.pie-chart.name'),
            data: this.data.resourceShareValues.map((value, valueIndex) => {
                return {
                    name: this.translateLocalizedText.transform(value.resource.resourceName),
                    y: value.value,
                    price: value.value,
                    color: colors[valueIndex % colors.length],
                };
            })
        }];

        this.chart = new Chart(this.options);
    }

    get defaultChartOptions(): any {
        const _this = this;
        const valueFn = (value: number) => _this.decimalPipe.transform(value, this.data.unit.match('.*MW.*') ? '1.3-3' : '1.2-2');

        return {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
            },
            tooltip: {
                pointFormatter: function () {
                    return this.series.name + ': <br>' +
                           '<b>' + valueFn(this.percentage) + ' %</b><br>' +
                           '<b>' + valueFn(this.price) + ' ' + _this.data.unit + '</b>';
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return '<b>' + this.point.name + '</b>:<br>' +
                                   valueFn(this.point.percentage) + ' %<br>' +
                                   valueFn(this.point.price) +  ' ' + _this.data.unit;
                        },
                        style: {
                            color: (Highcharts.theme && Highcharts.theme.colors['contrastTextColor']) || 'black'
                        }
                    },
                    showInLegend: true
                }
            },
            scrollbar: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            exporting: {
                filename: this._unitCost ? this.translateService.instant('invoiceReport.chart.unitCostTitle') : this.translateService.instant('invoiceReport.chart.costTitle'),
                chartOptions: {
                    title: {
                        style: {
                            fontSize: '12px'
                        }
                    },
                    legend: {
                        itemStyle: {
                            fontSize: '6px'
                        }
                    }
                }
            }
        };
    }

}
