<div class="row">
    <div class="col" *ngIf="minIsVisible()">
        <mat-slide-toggle color="primary" [(ngModel)]="minSelected">{{'consumption.diagram.diagram-options.min' | translate}}
        </mat-slide-toggle>
    </div>
    <div class="col" *ngIf="maxIsVisible()">
        <mat-slide-toggle color="primary" [(ngModel)]="maxSelected">{{'consumption.diagram.diagram-options.max' | translate}}
        </mat-slide-toggle>
    </div>
    <div class="col" *ngIf="avgIsVisible()">
        <mat-slide-toggle color="primary" [(ngModel)]="avgSelected">{{'consumption.diagram.diagram-options.avg' | translate}}
        </mat-slide-toggle>
    </div>
    <div class="col" *ngIf="tempIsVisible()">
        <mat-slide-toggle color="primary" [(ngModel)]="tempSelected">{{'consumption.diagram.diagram-options.temp' | translate}}
        </mat-slide-toggle>
    </div>

    <div class="col" *ngIf="performanceIsVisible()">
        <mat-slide-toggle color="primary" [(ngModel)]="perfSelected">{{'consumption.diagram.diagram-options.performance' | translate}}
        </mat-slide-toggle>
    </div>

    <div class="col" *ngIf="wDayOffIsVisible()">
        <mat-slide-toggle color="primary" [(ngModel)]="woffDaySelected">{{'consumption.diagram.diagram-options.w_off_day' | translate}}
        </mat-slide-toggle>
    </div>
</div>
