import { BaseDto, Category, FileDto } from '../';

export class News {
  categories: Category[];
  id: number;
  picture: FileDto;
  title: string;
  summary: string;
  text: string;
  releaseTime: Date;
  language: string;
  contentLanguage: string;
  tags: BaseDto[];
  visible: boolean;
}
