import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CezPortalSharedModule } from '../shared';
import { HOME_ROUTE } from './home.route';
import { HomeComponent } from './home.component';
import { ConsumptionModule } from "../consumption/consumption.module";

@NgModule({
    imports: [
        CezPortalSharedModule,
        RouterModule.forRoot(HOME_ROUTE, { useHash: true }),
        ConsumptionModule
    ],
    declarations: [
        HomeComponent
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CezPortalHomeModule { }
