import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToleranceData } from './tolerance-data.model';
import { TolerancePeriod } from './tolerance-period.model';
import { ToleranceRequest } from './tolerance-request.model';

@Injectable({
    providedIn: 'root'
})
export class ToleranceThresholdService {

    constructor(private http: HttpClient) {
    }


    listTolerancePeriods(partnerId: number): Observable<TolerancePeriod[]> {
        const params = new HttpParams()
            .set('partnerId', partnerId.toString());
        return this.http.get<TolerancePeriod[]>(`api/tolerance-threshold/tolerance-periods`, {
            params
        });
    }

    getToleranceData(request: ToleranceRequest): Observable<ToleranceData> {
        let params = new HttpParams()
            .set('tolerancePeriodId', request.tolerancePeriodId.toString())
            .set('partnerId', request.partnerId.toString());

        if (request.start) {
            params = params.append('start', request.start.format('YYYY-MM-DD'));
        }
        if (request.end) {
            params = params.append('end', request.end.format('YYYY-MM-DD'));
        }
        if (request.sumYearly) {
            params = params.append('sumYearly', request.sumYearly.toString());
        }
        if (request.sumMonthly) {
            params = params.append('sumMonthly', request.sumMonthly.toString());
        }

        return this.http.get<ToleranceData>(`api/tolerance-threshold/tolerance-data/${request.type}`, {
            params
        });
    }

    getAllToleranceData(request: ToleranceRequest): Observable<ToleranceData[]> {
        let params = new HttpParams()
            .set('tolerancePeriodId', request.tolerancePeriodId.toString())
            .set('partnerId', request.partnerId.toString());

        if (request.start) {
            params = params.append('start', request.start.format('YYYY-MM-DD'));
        }
        if (request.end) {
            params = params.append('end', request.end.clone().add(1, 'day').format('YYYY-MM-DD'));
        }
        if (request.sumYearly) {
            params = params.append('sumYearly', request.sumYearly.toString());
        }
        if (request.sumMonthly) {
            params = params.append('sumMonthly', request.sumMonthly.toString());
        }
        if (request.expectedPlanMonths) {
            params = params.append('expectedPlanMonths', request.expectedPlanMonths.toString());
        }

        return this.http.get<ToleranceData[]>(`api/tolerance-threshold/tolerance-data/all`, {
            params
        });
    }
}
