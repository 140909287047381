import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { filter, take } from 'rxjs/operators';
import { AdditionalDiagramResolution } from '../model/additional-diagram-resolution';
import { AdditionalDiagramTypeDTO } from '../model/additional-diagram-type.model';
import { DiagramType } from '../model/diagram-type';
import { AdditionalDiagramService } from '../service/additional-diagram-service';

@Component({
    selector: 'jhi-additional-diag-type-editor',
    templateUrl: './additional-diag-type-editor.component.html',
    styles: []
})
export class AdditionalDiagTypeEditorComponent implements OnInit {

    form: UntypedFormGroup;
    diagramType = DiagramType;
    additionalDiagramResolution = AdditionalDiagramResolution;

    constructor(@Inject(MAT_DIALOG_DATA) public data: AdditionalDiagramTypeDTO|null,
                private translate: TranslateService,
                private dialogRef: MatDialogRef<AdditionalDiagTypeEditorComponent>,
                private service: AdditionalDiagramService,
                private fb: UntypedFormBuilder) {

        this.form = this.fb.group({
            typeNameEN: ['', Validators.required],
            typeNameHU: ['', Validators.required],
            columnName: ['', Validators.required],
            typeDescriptionEN: [''],
            typeDescriptionHU: [''],
            diagramType: [DiagramType.COLUMN, Validators.required],
            groupOnlyVisibility: [false, Validators.required],
            measureUnit: ['', Validators.required],
            resolution: [AdditionalDiagramResolution.MONTHLY, Validators.required]
        });

    }

    ngOnInit() {
        if (this.data) {
            this.form.patchValue(this.data);
        }

        this.dialogRef.keydownEvents().pipe(
            filter((e: KeyboardEvent) => e.code === 'Escape'),
            take(1)
        ).subscribe(() => this.dialogRef.close());
    }

    save() {
        const result: AdditionalDiagramTypeDTO = this.data ? _.merge({}, this.data, this.form.value) : this.form.value;

        this.service.postDiagramType(result).subscribe(() => {
            this.dialogRef.close(true);
        }, (error) => {
            console.error(error);
        });
    }
}
