import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { DialogService } from '../../shared/dialog/dialog.service';
import { InvoiceResourceEditorComponent } from './invoice-resource-editor/invoice-resource-editor.component';
import { InvoiceResourceDTO } from './model/invoice-reosurce.model';
import { InvoiceResourceService } from './service/invoice-resource.service';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
    selector: 'jhi-invoice-resource',
    templateUrl: './invoice-resource.component.html',
    styleUrls: ['./invoice-resource.component.scss']
})
export class InvoiceResourceComponent implements OnInit {

    invoiceResources: InvoiceResourceDTO [];
    selectedInvoiceResource: InvoiceResourceDTO;

    constructor(private translate: TranslateService,
                private invoiceResourceService: InvoiceResourceService,
                private dialog: MatDialog,
                private dialogService: DialogService) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.invoiceResourceService.getInvoiceResources().subscribe(invoiceResources => this.invoiceResources = invoiceResources);
        this.selectedInvoiceResource = null;

    }

    editInvoiceResource(invoiceResourceDto?: InvoiceResourceDTO) {
        this.dialog
            .open(InvoiceResourceEditorComponent, {
                disableClose: true,
                data: invoiceResourceDto || null
            })
            .afterClosed()
            .pipe(filter((saved) => saved))
            .subscribe(() => {
                this.refresh();
                this.dialogService.saveSuccess();
            });

    }

    deleteInvoiceResource(id: number) {
        this.dialogService.confirm(
            this.translate.instant('invoice-resource.dialog.alert.title'),
            this.translate.instant('invoice-resource.dialog.alert.message')
        )
            .pipe(filter((confirmed) => confirmed))
            .subscribe(
                () => {
                    this.invoiceResourceService.deleteInvoiceResource(id)
                        .subscribe(() => {
                            this.refresh();
                            this.dialogService.deleteSuccess();
                        });
                }, (error) => {
                    console.error(error);
                });

    }
}
