<mat-card class="mat-card-min-height">
  <mat-card-title>
    <div class="d-flex row m-0">
      <div class="ml-auto mb-3 button-row">
        <button mat-raised-button
                [disabled]="offerClaimHistoryDto.length === 0"
                color="primary"
                (click)="exportClaimHistory()"
                type="button"
                class="btn-block btn-primary">
          <i class="material-icons">receipt</i>
          {{'offerClaimHistory.button.export' | translate}}
        </button>
      </div>
    </div>
  </mat-card-title>
    <p-table #historyTable
             id="history-table"
             [value]="offerClaimHistoryDto"
             [paginator]="true"
             [rows]="10"
             sortMode="multiple"
             [multiSortMeta]="[
                     {field: 'dealId', order: TABLE_SORT_ASC},
                     {field: 'partner', order: TABLE_SORT_ASC},
                     {field: 'deliveryPeriod.startTime', order: TABLE_SORT_DESC},
                     {field: 'purchaseDate', order: TABLE_SORT_DESC}
                 ]"
             (sortFunction)="sortOverview($event)"
             [customSort]="true"
             class="table table-striped">
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'dealId'" class="text-left head-th filter-column-width">
            <span class="no-linebreak" jhiTranslate="offerClaimHistory.tableField.dealId"></span>
            <p-sortIcon [field]="'dealId'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'partner'" class="text-left head-th filter-column-width">
            <span class="no-linebreak" jhiTranslate="offerClaimHistory.tableField.partner"></span>
            <p-sortIcon [field]="'partner'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'deliveryPeriod.startTime'" class="text-center head-th small-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.deliveryPeriod"></span>
            <p-sortIcon [field]="'deliveryPeriod.startTime'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'saleStatus'" class="text-center head-th default-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.status"></span>
            <p-sortIcon [field]="'saleStatus'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'purchaseDate'" class="text-center head-th purchase-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.purchaseDate"></span>
            <p-sortIcon [field]="'purchaseDate'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'product'" class="text-left head-th default-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.productName"></span>
            <p-sortIcon [field]="'product'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'startDate'" class="text-center head-th purchase-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.startDate"></span>
            <p-sortIcon [field]="'startDate'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'endDate'" class="text-center head-th purchase-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.endDate"></span>
            <p-sortIcon [field]="'endDate'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'productType'" class="text-center head-th default-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.deliveryPeriodType"></span>
            <p-sortIcon [field]="'productType'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'purchaseStatus'" class="text-center head-th default-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.purchaseType"></span>
            <p-sortIcon [field]="'purchaseStatus'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'quantityMw'" class="text-center head-th quantity-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.quantityMw"></span>
            <p-sortIcon [field]="'quantityMw'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'quantity'" class="text-center head-th quantity-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.quantity"></span>
            <p-sortIcon [field]="'quantity'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'price'" class="text-center head-th price-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.price"></span>
            <p-sortIcon [field]="'price'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'netSum'" class="text-center head-th quantity-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.netSum"></span>
            <p-sortIcon [field]="'netSum'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'purchaseApprovedPerson'" class="text-center head-th purchase-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.approvedUser"></span>
            <p-sortIcon [field]="'purchaseApprovedPerson'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'pricePublisher'" class="text-center head-th default-column-width">
            <span jhiTranslate="offerClaimHistory.tableField.pricePublisher"></span>
            <p-sortIcon [field]="'pricePublisher'"></p-sortIcon>
          </th>
          <th class="text-center head-th purchase-column-width"></th>
        </tr>
        <tr>
          <th class="filter-th" id="deal-id-filter-form">
            <mat-form-field class="pt-2 pb-2 head-select text-left">
              <mat-label>{{ 'global.choose' | translate }}</mat-label>
              <input matInput
                     type="text"
                     aria-label="Number"
                     [formControl]="dealIdControl"
                     [matAutocomplete]="auto">
              <mat-autocomplete
                #auto="matAutocomplete"
                (optionSelected)="historyTable.filter($event.option.value, 'dealId', 'equals')"
                panelWidth="auto">
                <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                <mat-option *ngFor="let dealId of filteredDealIds | async"
                            [value]="dealId">{{ dealId }}</mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </th>
          <th class="filter-th">
            <mat-select
              class="pt-2 pb-2 head-select text-left"
              (selectionChange)="historyTable.filter($event.value, 'partner', 'equals')"
              placeholder="{{'global.choose' | translate}}">
              <mat-option>{{'multiSelectText.checkAll' | translate}}</mat-option>
              <mat-option
                *ngFor="let partner of partners"
                [value]="partner">
                {{partner}}
              </mat-option>
            </mat-select>
          </th>
          <th class="filter-th">
            <mat-select
              class="pt-2 pb-2 head-select"
              (selectionChange)="historyTable.filter($event.value, 'deliveryPeriod.id', 'equals')"
              placeholder="{{'global.choose' | translate}}">
              <mat-option>{{'multiSelectText.checkAll' | translate}}</mat-option>
              <mat-option
                *ngFor="let deliveryPeriod of deliveryPeriods"
                [value]="deliveryPeriod.id">
                {{deliveryPeriod.startTime | cezDate}} - {{deliveryPeriod.endTime | cezDate}}
              </mat-option>
            </mat-select>
          </th>
          <th>
            <mat-select
              class="pt-2 pb-2 head-select"
              (selectionChange)="historyTable.filter($event.value, 'saleStatus', 'equals')"
              placeholder="{{'global.choose' | translate}}">
              <mat-option>{{'multiSelectText.checkAll' | translate}}</mat-option>
              <mat-option
                *ngFor="let status of statusList"
                [value]="status">
                {{translateHistoryStatus(status)}}
              </mat-option>
            </mat-select>
          </th>
          <th>
            <div class="date-filter-padding">
              <jhi-date-picker class="purchase-column-width"
                               [(dpModel)]="dateFilterMap.get('purchaseDate').start"
                               (dpModelChange)="this.historyTable.filter($event, 'purchaseDate', 'dateBetween-purchaseDate')"
                               placeholder="{{ 'global.from' | translate }}">
              </jhi-date-picker>
            </div>
            <div class="date-filter-padding">
              <jhi-date-picker class="purchase-column-width"
                               [(dpModel)]="dateFilterMap.get('purchaseDate').end"
                               (dpModelChange)="this.historyTable.filter($event, 'purchaseDate', 'dateBetween-purchaseDate')"
                               placeholder="{{ 'global.to' | translate }}">
              </jhi-date-picker>
            </div>
          </th>
          <th>
            <mat-select
              class="pt-2 pb-2 head-select"
              (selectionChange)="historyTable.filter($event.value, 'product', 'equals')"
              placeholder="{{'global.choose' | translate}}">
              <mat-option>{{'multiSelectText.checkAll' | translate}}</mat-option>
              <mat-option
                *ngFor="let product of products"
                [value]="product">
                {{ product }}
              </mat-option>
            </mat-select>
          </th>
          <th>
            <div class="date-filter-padding">
              <jhi-date-picker class="default-column-width"
                               [(dpModel)]="dateFilterMap.get('startDate').start"
                               (dpModelChange)="this.historyTable.filter($event, 'startDate', 'dateBetween-startDate')"
                               placeholder="{{ 'global.from' | translate }}">
              </jhi-date-picker>
            </div>
            <div class="date-filter-padding">
              <jhi-date-picker class="default-column-width"
                               [(dpModel)]="dateFilterMap.get('startDate').end"
                               (dpModelChange)="this.historyTable.filter($event, 'startDate', 'dateBetween-startDate')"
                               placeholder="{{ 'global.to' | translate }}">
              </jhi-date-picker>
            </div>
          </th>
          <th>
            <div class="date-filter-padding">
              <jhi-date-picker class="default-column-width"
                               [(dpModel)]="dateFilterMap.get('endDate').start"
                               (dpModelChange)="this.historyTable.filter($event, 'endDate', 'dateBetween-startDate')"
                               placeholder="{{ 'global.from' | translate }}">
              </jhi-date-picker>
            </div>
            <div class="date-filter-padding">
              <jhi-date-picker class="default-column-width"
                               [(dpModel)]="dateFilterMap.get('endDate').end"
                               (dpModelChange)="this.historyTable.filter($event, 'endDate', 'dateBetween-startDate')"
                               placeholder="{{ 'global.to' | translate }}">
              </jhi-date-picker>
            </div>
          </th>
          <th>
            <mat-select
              class="pt-2 pb-2 head-select"
              (selectionChange)="historyTable.filter($event.value, 'productType', 'equals')"
              placeholder="{{'global.choose' | translate}}">
              <mat-option>{{'multiSelectText.checkAll' | translate}}</mat-option>
              <mat-option
                *ngFor="let productType of productTypes"
                [value]="productType">
                {{translateProductType(productType)}}
              </mat-option>
            </mat-select>
          </th>
          <th>
            <mat-select
              class="pt-2 pb-2 head-select"
              (selectionChange)="historyTable.filter($event.value, 'purchaseStatus', 'equals')"
              placeholder="{{'global.choose' | translate}}">
              <mat-option>{{'multiSelectText.checkAll' | translate}}</mat-option>
              <mat-option
                *ngFor="let purchaseStatus of purchaseStatuses"
                [value]="purchaseStatus">
                {{translatePurchaseStatus(purchaseStatus)}}
              </mat-option>
            </mat-select>
          </th>
          <th>
            <mat-form-field class="pt-2 pb-2 quantity-column-width">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     [maxNumber]="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('quantityMw').min"
                     (input)="historyTable.filter($event.target.value, 'quantityMw', 'numberBetween-quantityMw')"
                     placeholder="{{'global.from' | translate}}">
            </mat-form-field>
            <mat-form-field class="pt-2 pb-2 quantity-column-width number-filter-margin">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     [maxNumber]="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('quantityMw').max"
                     (input)="historyTable.filter($event.target.value, 'quantityMw', 'numberBetween-quantityMw')"
                     placeholder="{{'global.to' | translate}}">
            </mat-form-field>
          </th>
          <th>
            <mat-form-field class="pt-2 pb-2 quantity-column-width">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     [maxNumber]="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('quantity').min"
                     (input)="historyTable.filter($event.target.value, 'quantity', 'numberBetween-quantity')"
                     placeholder="{{'global.from' | translate}}">
            </mat-form-field>
            <mat-form-field class="pt-2 pb-2 quantity-column-width number-filter-margin">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     [maxNumber]="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('quantity').max"
                     (input)="historyTable.filter($event.target.value, 'quantity', 'numberBetween-quantity')"
                     placeholder="{{'global.to' | translate}}">
            </mat-form-field>
          </th>
          <th>
            <mat-form-field class="pt-2 pb-2 price-column-width">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     [maxNumber]="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('price').min"
                     (input)="historyTable.filter($event.target.value, 'price', 'numberBetween-price')"
                     placeholder="{{'global.from' | translate}}">
            </mat-form-field>
            <mat-form-field class="pt-2 pb-2 price-column-width number-filter-margin">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     [maxNumber]="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('price').max"
                     (input)="historyTable.filter($event.target.value, 'price', 'numberBetween-price')"
                     placeholder="{{'global.to' | translate}}">
            </mat-form-field>
          </th>

          <th>
            <mat-form-field class="pt-2 pb-2 quantity-column-width">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     [maxNumber]="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('netSum').min"
                     (input)="historyTable.filter($event.target.value, 'netSum', 'numberBetween-netSum')"
                     placeholder="{{'global.from' | translate}}">
            </mat-form-field>
            <mat-form-field class="pt-2 pb-2 quantity-column-width number-filter-margin">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     [maxNumber]="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('netSum').max"
                     (input)="historyTable.filter($event.target.value, 'netSum', 'numberBetween-netSum')"
                     placeholder="{{'global.to' | translate}}">
            </mat-form-field>
          </th>
          <th>
            <mat-select
              class="pt-2 pb-2 head-select"
              (selectionChange)="historyTable.filter($event.value, 'purchaseApprovedPerson', 'equals')"
              placeholder="{{'global.choose' | translate}}">
              <mat-option>{{'multiSelectText.checkAll' | translate}}</mat-option>
              <mat-option
                *ngFor="let approver of approvers"
                [value]="approver">
                {{approver}}
              </mat-option>
            </mat-select>
          </th>
          <th>
            <mat-select
              class="pt-2 pb-2 head-select"
              (selectionChange)="historyTable.filter($event.value, 'pricePublisher', 'equals')"
              placeholder="{{'global.choose' | translate}}">
              <mat-option>{{'multiSelectText.checkAll' | translate}}</mat-option>
              <mat-option
                *ngFor="let bidder of bidders"
                [value]="bidder">
                {{bidder}}
              </mat-option>
            </mat-select>
          </th>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData>
        <tr [pSelectableRow]="rowData">
          <td class="text-left">{{rowData.dealId}}</td>
          <td class="text-left">{{rowData.partner}}</td>
          <td>{{rowData.deliveryPeriod.startTime | cezDate}}
            - {{rowData.deliveryPeriod.endTime | cezDate}}</td>
          <td>{{translateHistoryStatus(rowData.saleStatus)}}</td>
          <td>{{rowData.purchaseDate | cezDate}}</td>
          <td class="text-left">{{rowData.product}}</td>
          <td>{{rowData.startDate | cezDate}}</td>
          <td>{{rowData.endDate | cezDate}}</td>
          <td>{{translateProductType(rowData.productType)}}</td>
          <td>{{translatePurchaseStatus(rowData)}}</td>
          <td class="text-right">{{rowData.quantityMw | number}}</td>
          <td class="text-right">{{rowData.quantity | number}}</td>
          <td class="text-right">{{rowData.price | number: '1.2-2'}}</td>
          <td class="text-right">{{rowData.netSum | number: '1.0-0'}}</td>
          <td class="text-center">{{rowData.purchaseApprovedPerson}}</td>
          <td class="text-center">{{rowData.pricePublisher}}</td>
          <td class="text-center">
            <button type="submit" replaceUrl="true" (click)="delete(rowData)"
                    *ngIf="rowData.saleStatus === 'ACCEPTED' && rowData.dealId!=null"
                    class="btn btn-danger btn-sm">
              <span class="fa fa-remove"></span>

            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  <mat-card-content>
  </mat-card-content>
</mat-card>
