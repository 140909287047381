<!-- CASH_OUT -->

<table *ngIf="productType === ProductType.CASH_OUT && showTable()"
       class="table aggregation-table table-borderless">

    <!-- YEARLY -->
    <tbody *ngIf="!!quantities.yearlyQuantities">
    <tr>
        <td class="period text-white font-weight-bold"
            rowspan="2">
            <div class="gap-r" [ngClass]="bgColorCssClass">Y</div>
        </td>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">MW</div>
        </td>
        <td class="data border"
            writeProtected="HEDGE_TRANSACTIONS_WRITE"
            (clickIfHasPermission)="purchaseNewClaim(quantities.yearlyQuantities)"
            [writeProtectEnabled]="quantities.yearlyQuantities.available"
            [ngClass]="getDataCssClass(quantities.yearlyQuantities, 'quantityMw') + getClickableStateCssClass(quantities.yearlyQuantities, ' co-y-1-mw')" colspan="12">
            {{quantities.yearlyQuantities.quantityMw | number:'1.2-2'}}
        </td>
    </tr>
    <tr>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">%</div>
        </td>
        <td class="data border"
            writeProtected="HEDGE_TRANSACTIONS_WRITE"
            (clickIfHasPermission)="purchaseNewClaim(quantities.yearlyQuantities)"
            [writeProtectEnabled]="quantities.yearlyQuantities.available"
            [ngClass]="getDataCssClass(quantities.yearlyQuantities, 'percent') + getClickableStateCssClass(quantities.yearlyQuantities, ' co-y-1-percent')" colspan="12">
            {{quantities.yearlyQuantities.percent | number:'1.2-2'}}%
        </td>
    </tr>
    </tbody>


    <!-- QUARTERLY -->
    <tbody *ngIf="!!quantities.quarterlyQuantities && quantities.quarterlyQuantities.length > 0">
    <tr>
        <td class="period text-white font-weight-bold"
            rowspan="2">
            <div class="gap-r" [ngClass]="bgColorCssClass">Q</div>
        </td>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">MW</div>
        </td>
        <td *ngFor="let quarterly of quantities.quarterlyQuantities; let i=index" class="data" colspan="3">
            <div class="border"
                 writeProtected="HEDGE_TRANSACTIONS_WRITE"
                 (clickIfHasPermission)="purchaseNewClaim(quarterly)"
                 [writeProtectEnabled]="quarterly.available"
                 [ngClass]="getCssClassForTextContainer(quarterly) + ' ' + getDataCssClass(quarterly, 'quantityMw') + getClickableStateCssClass(quarterly, ' co-q-'+ i + '-mw')">
                {{quarterly.quantityMw | number:'1.2-2'}}
            </div>
        </td>
    </tr>
    <tr>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border border-top-0">%</div>
        </td>
        <td *ngFor="let quarterly of quantities.quarterlyQuantities; let i=index" class="data" colspan="3">
            <div class="border border-top-0"
                 writeProtected="HEDGE_TRANSACTIONS_WRITE"
                 (clickIfHasPermission)="purchaseNewClaim(quarterly)"
                 [writeProtectEnabled]="quarterly.available"
                 [ngClass]="getCssClassForTextContainer(quarterly) + ' ' + getDataCssClass(quarterly, 'percent') + getClickableStateCssClass(quarterly, ' co-q-' + i + '-percent')">
                {{quarterly.percent | number:'1.2-2'}}%
            </div>
        </td>
    </tr>
    </tbody>

    <!-- MONTHLY -->
    <tbody *ngIf="!!quantities.monthlyQuantities && quantities.monthlyQuantities.length > 0">
    <tr>
        <td class="period text-white font-weight-bold"
            rowspan="2">
            <div class="gap-r" [ngClass]="bgColorCssClass">M</div>
        </td>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">MW</div>
        </td>
        <td *ngFor="let monthly of quantities.monthlyQuantities; let i=index" class="data">
            <div class="border"
                 writeProtected="HEDGE_TRANSACTIONS_WRITE"
                 (clickIfHasPermission)="purchaseNewClaim(monthly)"
                 [writeProtectEnabled]="monthly.available"
                 [ngClass]="getCssClassForTextContainer(monthly) + ' ' + getDataCssClass(monthly, 'quantityMw') + getClickableStateCssClass(monthly, ' co-m-' + i + '-mw')">
                {{monthly.quantityMw | number:'1.2-2'}}
            </div>
        </td>
    </tr>
    <tr>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border border-top-0">%</div>
        </td>
        <td *ngFor="let monthly of quantities.monthlyQuantities; let i=index"
            [ngClass]="getDataContainerClass(monthly) + ' ' + getDataCssClass(monthly, 'percent')">
            <div class="border border-top-0"
                 writeProtected="HEDGE_TRANSACTIONS_WRITE"
                 (clickIfHasPermission)="purchaseNewClaim(monthly)"
                 [writeProtectEnabled]="monthly.available"
                 [ngClass]="getCssClassForTextContainer(monthly) + getClickableStateCssClass(monthly, ' co-m-' + i + '-percent')">
                {{monthly.percent | number:'1.2-2'}}%
            </div>
        </td>
    </tr>
    </tbody>

    <tfoot>
    <tr>
        <td></td>
        <td></td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M1</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M2</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="gap-r" [ngClass]="bgColorCssClass">M3</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M4</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M5</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="gap-r" [ngClass]="bgColorCssClass">M6</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M7</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M8</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="gap-r" [ngClass]="bgColorCssClass">M9</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M10</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M11</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r" [ngClass]="bgColorCssClass">M12</div>
        </td>
    </tr>
    </tfoot>
</table>

<!-- STEP_BY_STEP -->

<table *ngIf="productType === ProductType.STEP_BY_STEP && showTable()"
       class="table aggregation-table table-borderless">
    <!-- YEARLY -->
    <tbody *ngIf="!!quantities.yearlyQuantities">
    <tr>
        <td class="period text-white font-weight-bold"
            rowspan="3">
            <div class="gap-r" [ngClass]="bgColorCssClass">Y</div>
        </td>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">Tranche db</div>
        </td>
        <td class="data border"
            writeProtected="HEDGE_TRANSACTIONS_WRITE"
            (clickIfHasPermission)="purchaseNewClaim(quantities.yearlyQuantities)"
            [writeProtectEnabled]="quantities.yearlyQuantities.available"
            [ngClass]="getDataCssClass(quantities.yearlyQuantities, 'tranche') + getClickableStateCssClass(quantities.yearlyQuantities, ' sbs-y-1-tranche')" colspan="12">
            {{quantities.yearlyQuantities.tranche}}
        </td>
    </tr>
    <tr>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">MWh</div>
        </td>
        <td class="data border"
            writeProtected="HEDGE_TRANSACTIONS_WRITE"
            (clickIfHasPermission)="purchaseNewClaim(quantities.yearlyQuantities)"
            [writeProtectEnabled]="quantities.yearlyQuantities.available"
            [ngClass]="getDataCssClass(quantities.yearlyQuantities, 'quantityMwh') + getClickableStateCssClass(quantities.yearlyQuantities, ' sbs-y-1-mwh')" colspan="12">
            {{quantities.yearlyQuantities.quantityMwh | number:'1.2-2'}}
        </td>
    </tr>
    <tr>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">%</div>
        </td>
        <td class="data border"
            writeProtected="HEDGE_TRANSACTIONS_WRITE"
            (clickIfHasPermission)="purchaseNewClaim(quantities.yearlyQuantities)"
            [writeProtectEnabled]="quantities.yearlyQuantities.available"
            [ngClass]="getDataCssClass(quantities.yearlyQuantities, 'percent') + getClickableStateCssClass(quantities.yearlyQuantities, ' sbs-y-1-percent')" colspan="12">
            {{quantities.yearlyQuantities.percent | number:'1.2-2'}}%
        </td>
    </tr>
    </tbody>

    <!-- QUARTERLY -->
    <tbody *ngIf="!!quantities.quarterlyQuantities && quantities.quarterlyQuantities.length > 0">
    <tr>
        <td class="period text-white font-weight-bold"
            rowspan="3">
            <div class="gap-r" [ngClass]="bgColorCssClass">Q</div>
        </td>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">Tranche db</div>
        </td>
        <td *ngFor="let quarterly of quantities.quarterlyQuantities; let i=index" class="data" colspan="3">
            <div class="border"
                 writeProtected="HEDGE_TRANSACTIONS_WRITE"
                 (clickIfHasPermission)="purchaseNewClaim(quarterly)"
                 [writeProtectEnabled]="quarterly.available"
                 [ngClass]="getCssClassForTextContainer(quarterly) + ' ' + getDataCssClass(quarterly, 'tranche') + getClickableStateCssClass(quarterly, ' sbs-q-' + i + '-tranche')">
                {{quarterly.tranche}}
            </div>
        </td>
    </tr>
    <tr>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">MWh</div>
        </td>
        <td *ngFor="let quarterly of quantities.quarterlyQuantities; let i=index" class="data" colspan="3">
            <div class="border"
                 writeProtected="HEDGE_TRANSACTIONS_WRITE"
                 (clickIfHasPermission)="purchaseNewClaim(quarterly)"
                 [writeProtectEnabled]="quarterly.available"
                 [ngClass]="getCssClassForTextContainer(quarterly) + ' ' + getDataCssClass(quarterly, 'quantityMwh') + getClickableStateCssClass(quarterly, ' sbs-q-' + i + '-mwh')">
                {{quarterly.quantityMwh | number:'1.2-2'}}
            </div>
        </td>
    </tr>
    <tr>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">%</div>
        </td>
        <td *ngFor="let quarterly of quantities.quarterlyQuantities; let i=index" class="data" colspan="3">
            <div class="border"
                 writeProtected="HEDGE_TRANSACTIONS_WRITE"
                 (clickIfHasPermission)="purchaseNewClaim(quarterly)"
                 [writeProtectEnabled]="quarterly.available"
                 [ngClass]="getCssClassForTextContainer(quarterly) + ' ' + getDataCssClass(quarterly, 'percent') + getClickableStateCssClass(quarterly, ' sbs-q-' + i + '-percent')">
                {{quarterly.percent | number:'1.2-2'}}%
            </div>
        </td>
    </tr>
    </tbody>

    <!-- MONTHLY -->
    <tbody *ngIf="!!quantities.monthlyQuantities && quantities.monthlyQuantities.length > 0">
    <tr>
        <td class="period text-white font-weight-bold"
            rowspan="3">
            <div class="gap-r" [ngClass]="bgColorCssClass">M</div>
        </td>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">Tranche db</div>
        </td>
        <td *ngFor="let monthly of quantities.monthlyQuantities; let i=index" class="data">
            <div class="border"
                 writeProtected="HEDGE_TRANSACTIONS_WRITE"
                 (clickIfHasPermission)="purchaseNewClaim(monthly)"
                 [writeProtectEnabled]="monthly.available"
                 [ngClass]="getCssClassForTextContainer(monthly) + ' ' + getDataCssClass(monthly, 'tranche') + getClickableStateCssClass(monthly, ' sbs-m-' + i + '-tranche')">
                {{monthly.tranche}}
            </div>
        </td>
    </tr>
    <tr>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">MWh</div>
        </td>
        <td *ngFor="let monthly of quantities.monthlyQuantities; let i=index" class="data">
            <div class="border"
                 writeProtected="HEDGE_TRANSACTIONS_WRITE"
                 (clickIfHasPermission)="purchaseNewClaim(monthly)"
                 [writeProtectEnabled]="monthly.available"
                 [ngClass]="getCssClassForTextContainer(monthly) + ' ' + getDataCssClass(monthly, 'quantityMwh') + getClickableStateCssClass(monthly, ' sbs-m-' + i + '-mwh')">
                {{monthly.quantityMwh | number:'1.2-2'}}
            </div>
        </td>
    </tr>
    <tr>
        <td class="unit font-weight-bold">
            <div class="gap-r bg-light-gray border">%</div>
        </td>
        <td *ngFor="let monthly of quantities.monthlyQuantities; let i=index" class="data">
            <div class="border"
                 writeProtected="HEDGE_TRANSACTIONS_WRITE"
                 (clickIfHasPermission)="purchaseNewClaim(monthly)"
                 [writeProtectEnabled]="monthly.available"
                 [ngClass]="getCssClassForTextContainer(monthly) + ' ' + getDataCssClass(monthly, 'percent') + getClickableStateCssClass(monthly, ' sbs-m-' + i + '-percent')">
                {{monthly.percent | number:'1.2-2'}}%
            </div>
        </td>
    </tr>
    </tbody>

    <tfoot>
    <tr>
        <td></td>
        <td></td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M1</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M2</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="gap-r" [ngClass]="bgColorCssClass">M3</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M4</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M5</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="gap-r" [ngClass]="bgColorCssClass">M6</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M7</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M8</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="gap-r" [ngClass]="bgColorCssClass">M9</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M10</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r border-right" [ngClass]="bgColorCssClass">M11</div>
        </td>
        <td class="text-white font-weight-bold">
            <div class="no-gap-r" [ngClass]="bgColorCssClass">M12</div>
        </td>
    </tr>
    </tfoot>
</table>

