import {Directive, ElementRef, Input, OnChanges, OnInit, Renderer2} from '@angular/core';

export interface ProgressBarConfig {
  minValue: number;
  maxValue: number;
  currentValue: number;

  warnAfter: number | undefined;
  warnBefore: number | undefined;
}

@Directive({
  selector: '[progressbar]'
})
export class ProgressbarDirective implements OnInit, OnChanges {

  private static readonly MIN_VAL_ATTRIBUTE: string = 'aria-valuemin';
  private static readonly MAX_VAL_ATTRIBUTE: string = 'aria-valuemax';
  private static readonly NOW_VAL_ATTRIBUTE: string = 'aria-valuenow';
  private readonly CEZ_RED: string = '#cd1919';

  @Input('progressbar') config: ProgressBarConfig;

  constructor(private element: ElementRef,
              private renderer: Renderer2) { }

  ngOnInit(): void {
    this.setProgressbarValues();
  }

  ngOnChanges(): void {
    this.setProgressbarValues();
  }

  private setProgressbarValues() {
    this.renderer.setAttribute(this.element.nativeElement, ProgressbarDirective.MIN_VAL_ATTRIBUTE, `${ !!this.config ? this.config.minValue : 0 }`);
    this.renderer.setAttribute(this.element.nativeElement, ProgressbarDirective.MAX_VAL_ATTRIBUTE, `${ !!this.config ? this.config.maxValue : 0 }`);
    this.renderer.setAttribute(this.element.nativeElement, ProgressbarDirective.NOW_VAL_ATTRIBUTE, `${ !!this.config ? this.config.currentValue : 0 }`);

    (this.element.nativeElement as HTMLElement).style.width = `${ !!this.config ? (this.config.currentValue / this.config.maxValue * 100) : 0 }%`;

    this.setWarn();
  }

  private setWarn(): void {
    if ((!!this.config.warnAfter && this.config.currentValue > this.config.warnAfter) ||
      (!!this.config.warnBefore && this.config.currentValue < this.config.warnBefore)) {
      this.renderer.setStyle(this.element.nativeElement, 'color', this.CEZ_RED);
    } else {
      this.renderer.setStyle(this.element.nativeElement, 'color', 'black');
    }
  }
}
