import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdditionalDiagramGroupDTO } from '../model/additional-diagram-group.model';
import { AdditionalDiagramTypeDTO } from '../model/additional-diagram-type.model';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class AdditionalDiagramService {

    constructor(
        private http: HttpClient
    ) {
    }


    public postDiagramType(diagramType: AdditionalDiagramTypeDTO): Observable<AdditionalDiagramTypeDTO> {
        return this.http.post<AdditionalDiagramTypeDTO>('/api/additional-diagram/type/admin', diagramType);
    }

    public listDiagramTypes(): Observable<Array<AdditionalDiagramTypeDTO>> {
        return this.http.get<AdditionalDiagramTypeDTO[]>('/api/additional-diagram/type/admin');
    }

    public removeDiagramType(typeId: number): Observable<any> {
        return this.http.delete(`/api/additional-diagram/type/${typeId}/admin`);
    }

    public removeProduct(timeSeriesHeaderId: number): Observable<any> {
        return this.http.delete(`/api/market-price/product/${timeSeriesHeaderId}/admin`);
    }

    public listDiagramGroups(): Observable<Array<AdditionalDiagramGroupDTO>> {
        return this.http.get<AdditionalDiagramGroupDTO[]>('/api/additional-diagram/group/admin');
    }

    public postDiagramGroup(diagramGroup: AdditionalDiagramGroupDTO): Observable<AdditionalDiagramGroupDTO> {
        return this.http.post<AdditionalDiagramGroupDTO>('/api/additional-diagram/group/admin', diagramGroup);
    }

    public removeDiagramGroup(groupId: number): Observable<any> {
        return this.http.delete(`/api/additional-diagram/group/${groupId}/admin`);
    }

}
