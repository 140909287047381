export interface MtmReportDto {
    type: string;
    quantity: number;
    unitPrice: number;
    price: number;
    summaryRow: boolean;
}

export type MtmTableType = 'SUMMARY' | 'DETAILS';
export enum MtmReportCashoutCalculationType {
    DEFAULT = 'DEFAULT',
    SF = 'SF',
    SF_RBC = 'SF_RBC'
}
