import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BoxPlotResponse } from '../../shared/dto/boxplot.response.model';
import { DiagramService } from '../../shared/services/diagram.service';
import { NgBDatePickerConvertService } from '../../shared/services/ngb.datepicker.convert.service';
import { PodValueService } from '../../shared/services/pod-value.service';
import { ConsumptionNewDiagramType } from '../consumption-new-diagram-type';
import { FilterModel } from '../filter-basic/filter.model';
import {ConsumptionDiagramType} from "../../consumption/consumption-diagram-type";
import * as moment from 'moment';;
import {Chart} from 'angular-highcharts';

@Component({
    selector: 'jhi-performance-diagram-box-plot',
    templateUrl: './performance-diagram-box-plot.component.html',
    styles: []
})
export class PerformanceDiagramBoxPlotComponent implements OnInit {

    @Input() widget: boolean;
    chartData: any[];
    chartCategories: Date[] = [];
    performanceBoxPlotChart: Chart;
    filterModel: FilterModel;
    readonly defaultTickAmount = 8;

    constructor(private podValueService: PodValueService,
                private ngBDatePickerConvertService: NgBDatePickerConvertService,
                private diagramService: DiagramService,
                public translateService: TranslateService) {
    }

    ngOnInit() {
        if (!this.widget) {
            this.performanceBoxPlotChart = this.diagramService.createDummyChart();
        }
    }

    getReportParametersForRun(filterModel: FilterModel) {
        this.filterModel = filterModel;
        let podValueDto = this.podValueService.createApiParams(filterModel);
        this.podValueService.getBoxPlotData(podValueDto).subscribe(r => {
            for (let i = 0; i < r.maximumKw.length; i++) {
                let xAxisDate = this.diagramService.addDateByResolutionForPerformanceAnalysis(podValueDto.startTime, i, podValueDto.resolution);
                this.chartCategories[i] = new Date(Date.UTC(xAxisDate.getFullYear(), xAxisDate.getMonth(), xAxisDate.getDate()));
            }

            this.diagramService.createLegendText(filterModel).subscribe(legendItemText =>
                this.calculateData(r, legendItemText, podValueDto)
            );
        });
    }


    calculateData(boxPlotData: BoxPlotResponse, legendItemText: string, apiParams: any) {
        this.chartData = [];

        for (let i = 0; i < boxPlotData.maximumKw.length; i++) {

            this.chartData[i] = {
                low: boxPlotData.minimumKW[i],
                q1: boxPlotData.quartileQ1[i],
                median: boxPlotData.quartileQ2[i],
                q3: boxPlotData.quartileQ3[i],
                high: boxPlotData.maximumKw[i]
            };

        }

        let showPartialLegend = false;

        if (boxPlotData.valueStats.partialFirstInterval) {
            this.chartData[0].fillColor = 'grey';
            showPartialLegend = true;
        }

        if (boxPlotData.valueStats.partialLastInterval) {
            this.chartData[this.chartData.length - 1].fillColor = 'grey';
            showPartialLegend = true;
        }

        let series: any[] = [{
            type: 'boxplot',
            name: legendItemText,
            color: '#f24f00',
            fillColor: '#F2C2A9',
            pointStart: moment(boxPlotData.startTime).valueOf(),
            pointInterval: this.diagramService.getPointInterval(boxPlotData.resolution),
            pointIntervalUnit: this.diagramService.getPointIntervalUnit(boxPlotData.resolution),
            data: this.chartData,
            stemWidth: 1
        }];

        if (showPartialLegend)
            series.push({
                name: this.translateService.instant('consumption.partialValue'),
                color: 'grey',
                marker: {symbol: 'circle'},
                type: 'line',
                events: {
                    legendItemClick: function (e) {
                        return false;
                    }
                }
            });

        if (this.filterModel.temperatureChecked)
            this.diagramService.calculateTemperatureSeries(this.filterModel, series).subscribe(() => {
                series.map(t => t.tooltip = {
                    headerFormat: ''
                });
                this.calculateChart(series);
            });
        else
            this.calculateChart(series);
    };

    calculateChart(series: any) {
        let resolution: string = this.filterModel.resolution;
        let translateService = this.translateService;
        let diagramService = this.diagramService;
        let isMultiplePeriod: boolean = this.filterModel.periodParameters.filter(r => r.dateTo).length > 1;

        let maxPerformance: number = this.chartData.reduce((a, b) => (a.high > b.high) ? a : b).high;

        this.diagramService.createTitle(this.filterModel).subscribe(title =>
            this.performanceBoxPlotChart = new Chart({
                chart: {
                    type: 'boxplot',
                    zooming: {
                      type: 'xy'
                    },
                    height: this.widget ? null : '642.66px',
                    style: {
                        fontFamily: '\'Nimbus\''
                    },
                    events: this.diagramService.getWatermark(this.widget),
                    resetZoomButton: {
                        theme: {
                            style: 'opacity: 0.3',
                            states: {
                                hover: {
                                    style: 'opacity: 1'
                                }
                            }
                        }
                    }
                },
                title: {
                    text: this.widget ? '' : title,
                    style: {
                        fontSize: this.widget ? '12px' : '18px'
                    }
                },
                legend: {
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    enabled: !this.widget
                },
                exporting: {
                    filename: title.replace(/\//ig, '_'),
                    buttons: {
                        contextButton: {
                            align: 'left',
                            x: -10,
                            y: -10
                        }
                    },
                    sourceWidth: 1180,
                    sourceHeight: 642,
                    scale: 1,
                    chartOptions: {
                        title: {
                            style: {
                                fontSize: '12px'
                            }
                        },
                        legend: {
                            enabled: true,
                            itemWidth: 400,
                            itemStyle: {
                                fontSize: '10px'
                            }
                        },
                        xAxis: {
                            labels: {
                                align: 'right',
                                rotation: -45,
                                formatter: function () {
                                    return moment(new Date(this.value)).format('l');
                                },
                                style: {
                                    fontSize: '10px'
                                }
                            },
                            title: {
                                style: {
                                    fontSize: '10px'
                                }
                            }
                        },
                        yAxis: [{
                            max: Math.ceil(maxPerformance),
                            endOnTick: false,
                            tickAmount: this.defaultTickAmount,
                            title: {
                                text: 'kW',
                                style: {
                                    fontSize: '10px'
                                }
                            },
                            labels: {
                                style: {
                                    fontSize: '10px'
                                }
                            }
                        }, { // Secondary yAxis
                            tickAmount: this.defaultTickAmount,
                            gridLineWidth: 0,
                            labels: {
                                format: '{value}°C',
                                style: {
                                    color: '#000000',
                                    fontSize: '10px'
                                }
                            },
                            title: {
                                text: this.filterModel.temperatureChecked ? this.translateService.instant('filter.basic.temperature') : '',
                                style: {
                                    color: '#000000',
                                    fontSize: '10px'
                                }
                            },
                            opposite: true

                        }]
                    }
                },
                plotOptions: {
                    boxplot: {
                        colors: ['#F17F48']
                    }
                },

                xAxis: {
                    type: 'datetime',
                    title: {
                        text: this.translateService.instant('audits.table.header.date')
                    },
                    dateTimeLabelFormats: this.diagramService.getDateTimeLabelFormat(),
                    plotBands: this.diagramService.createPlotBands(this.filterModel),
                    labels: {
                        align: 'right',
                        rotation: -45,
                        formatter: function () {
                            return moment(new Date(this.value)).format('l');
                        }
                    }
                },
                yAxis: [{
                    max: Math.ceil(maxPerformance),
                    endOnTick: false,
                    tickAmount: this.defaultTickAmount,
                    title: {
                        text: 'kW'
                    }
                }, { // Secondary yAxis
                    tickAmount: this.defaultTickAmount,
                    gridLineWidth: 0,
                    labels: {
                        format: '{value}°C',
                        style: {
                            color: '#000000'
                        }
                    },
                    title: {
                        text: this.filterModel.temperatureChecked ? this.translateService.instant('filter.basic.temperature') : '',
                        style: {
                            color: '#000000'
                        }
                    },
                    opposite: true

                }],
                credits: {
                    enabled: false
                },
                lang: {
                    decimalPoint: ','
                },
                tooltip: {
                    useHTML: true,
                    formatter: function () {
                        return diagramService.getCommonTooltipFormatter(this, 'kW', true, resolution, ConsumptionDiagramType.BOXPLOT, isMultiplePeriod);
                    }
                },
                series: series
            }));
    }

    getMedianOfSequence(values: number[], firstIdx: number, lastIdx: number): number {
        let length = lastIdx - firstIdx + 1;
        if (length % 2 == 0) { //páros
            let half = length / 2;
            return (values[firstIdx + half - 1] + values[firstIdx + half]) / 2; //indexes!!
        } else { //páratlan
            return values[(lastIdx + firstIdx) / 2];
        }

    }

}
