import {HttpClient} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgBDatePickerConvertService} from '../../shared/services/ngb.datepicker.convert.service';
import {AgreementModel} from '../dto/agreement.model';
import {CompanyModel} from '../dto/company.model';
import {PagerModel} from '../dto/page.model';
import {LoadingOverlayService} from "../services/loading-overlay.service";
import {BootstrapAlertService} from "../services/bootstrap-alert.service";

@Component({
    selector: 'jhi-agreement-list',
    templateUrl: './agreement-list.component.html',
    styles: []
})
export class AgreementListComponent implements OnInit {

    @Input() adminMode: boolean;
    @Input() selectedCompany: CompanyModel;

    @Output() editEvent: EventEmitter<AgreementModel> = new EventEmitter();
    @Output() deleteEvent: EventEmitter<number> = new EventEmitter();
    @Output() viewEvent: EventEmitter<AgreementModel> = new EventEmitter();
    @Output() dataReceived: EventEmitter<AgreementModel[]> = new EventEmitter();
    @Output() syncAgreementFromSalesforce: EventEmitter<string> = new EventEmitter();

    agreements: AgreementModel[] = [];

    constructor(private http: HttpClient,
                private ngBDatePickerConvertService: NgBDatePickerConvertService,
                private loadingOverlay: LoadingOverlayService,
                private bootstrapAlertService: BootstrapAlertService) {
    }

    edit(agreement: AgreementModel) {
        this.editEvent.emit(agreement);
    }

    syncSalesforce(agreement: AgreementModel): void {
        this.syncAgreementFromSalesforce.emit(agreement.salesforceQuoteId);
    }

    delete(agreement: AgreementModel) {
        this.deleteEvent.emit(agreement.id);
    }

    view(agreement: AgreementModel) {
        this.viewEvent.emit(agreement);
    }

    ngOnInit() {
    }

    refreshAgreementList(selectedCompany: CompanyModel) {
        this.http.get<PagerModel<AgreementModel>>('api/agreement/partner/' + selectedCompany.id).subscribe(r => {
            this.agreements = r.content;
            this.dataReceived.emit(r.content);
            for (let agreement of this.agreements) {
                this.refreshAgreementDetails(agreement);
                agreement.partner = selectedCompany;
            }
        });
    }

    refreshAgreementDetails(agreement: AgreementModel) {
        agreement.startTimeNgB = this.ngBDatePickerConvertService.convertFromDate(agreement.startTime);
        agreement.endTimeNgB = this.ngBDatePickerConvertService.convertFromDate(agreement.endTime);
        agreement.services.extensionDeadlineNgB = this.ngBDatePickerConvertService.convertFromDate(agreement.services.extensionDeadline);
        agreement.services.extensionPeriodStartNgB = this.ngBDatePickerConvertService.convertFromDate(agreement.services.extensionPeriodStart);
        agreement.services.extensionPeriodEndNgB = this.ngBDatePickerConvertService.convertFromDate(agreement.services.extensionPeriodEnd);
    }

    public hasDependencies(agreement: AgreementModel) {
        return (agreement.contracts && agreement.contracts.length > 0)
            || (agreement.tolerancePeriods && agreement.tolerancePeriods.length > 0)
            || (agreement.pods && agreement.pods.length > 0);
    }

}
