import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Account, Principal, User, UserService } from "../../../shared";
import { ViewTemplate } from "../../../shared/dto/view.template.model";
import { NgBDatePickerConvertService } from "../../../shared/services/ngb.datepicker.convert.service";
import { ViewTemplateService } from "../../../shared/services/view-template.service";
import { FilterModel } from "../filter.model";

@Component({
    selector: "jhi-save-template-dialog",
    templateUrl: "./save-template-dialog.component.html",
    styleUrls: ["save-template-dialog.component.scss"]
})
export class SaveTemplateDialogComponent implements OnInit {

    @Input() saveAsNew: boolean;
    @Input() filterModel: FilterModel;
    @Input() templateName: string;
    @Input() templateId: number;
    @Input() dynamic: boolean;
    @Input() userId: number;
    @Output() modalClose: EventEmitter<any> = new EventEmitter();
    public shift: number;
    public intervalSize: number;
    public selectedIntervalGranularity: number;
    public selectedTemplateType: { label: string, value: number };
    public newViewTemplateName: string;
    public selectedUser: User;
    public users: User[];
    public currentUser: Account;
    public currentUserAdmin: boolean = false;
    public templateOptions: any[] = [
        {
            label: 'filter.basic.saveAsTemplate',
            value: 2,
            name: 'Sablon'
        }, {
            label: 'filter.basic.saveAsChart',
            value: 1,
            name: 'Diagram'
        }
    ];


    constructor(
        public activeModal: NgbActiveModal,
        private principal: Principal,
        private viewTemplateService: ViewTemplateService,
        private dateConverter: NgBDatePickerConvertService,
        private userService: UserService,
    ) {
    }

    ngOnInit() {

        this.newViewTemplateName = this.templateName;

        if (this.dynamic != null) {
            this.selectedTemplateType = this.dynamic ? this.templateOptions[0] : this.templateOptions[1];
        } else {
            this.selectedTemplateType = this.templateOptions[0];
        }
        this.intervalSize = this.filterModel.intervalSize;
        this.selectedIntervalGranularity = this.filterModel.selectedIntervalGranularity;
        this.shift = this.filterModel.shift;
        this.currentUser = this.principal.userIdentity;
        this.currentUserAdmin = this.principal.userIdentity.groups.some(r => r === 1);
        if (this.currentUserAdmin) {
            this.userService.query({
                sort: ['id,asc']
            }).subscribe(r => {
                this.users = r.body.filter(u => {
                    if (this.userId == u.id) {
                        if (u.login == this.currentUser.login) {
                            this.selectedUser = {id: null} as any;
                        } else {
                            this.selectedUser = u;
                        }
                    }
                    return u.login != this.currentUser.login;
                });
            });
        }
    }

    public saveTemplate(): void {
        let viewTeplate: ViewTemplate = {
            id: this.saveAsNew ? null : this.templateId,
            name: this.newViewTemplateName,
            startTime: this.dateConverter.convertToDate(this.filterModel.periodParameters[0].dateFrom),
            endTime: this.dateConverter.convertToDate(this.filterModel.periodParameters[0].dateTo),
            jsonData: JSON.stringify({
                selectedDiagram: this.filterModel.selectedDiagram,
                selectedPods: this.filterModel.selectedPods,
                selectedPodGroups: this.filterModel.selectedPodGroups,
                temperatureChecked: this.filterModel.temperatureChecked,
                multiplePeriod: this.filterModel.multiplePeriod,
                periodParameters: this.filterModel.periodParameters,
                resolution: this.filterModel.resolution,
                referenceWeek: this.filterModel.referenceWeek,
                referenceWeekChecked: this.filterModel.referenceWeekChecked,
                excludeBusinessHolidays: this.filterModel.excludeBusinessHolidays,
                shift: this.selectedTemplateType.value === 2 ? this.shift : null,
                intervalSize: this.selectedTemplateType.value === 2 ? this.intervalSize : null,
                selectedIntervalGranularity: this.selectedTemplateType.value === 2 ? this.selectedIntervalGranularity : null
            }),
            templateType: 'CHART',
            dynamic: this.selectedTemplateType.value === 2
        };

        if (this.selectedUser && this.selectedUser.id)
            this.viewTemplateService.createOrUpdateViewTemplateForAnotherUser(viewTeplate, this.selectedUser.id).subscribe((response: any) => {
                this.selectedTemplateType = null;
                this.modalClose.emit({refresh: false, templateId: response.id});
            });
        else
            this.viewTemplateService.createOrUpdateViewTemplate(viewTeplate).subscribe((response: any) => {
                // this.refreshViewTemplates();
                this.modalClose.emit({refresh: true, templateId: response.id});
                this.selectedTemplateType = null;
            });
    }

    public shiftBlur(): void {
        if (this.shift > 0)
            this.shift = 0;
    }

    public intervalSizeBlur(): void {
        if (this.intervalSize < 1)
            this.intervalSize = 1;
    }

    public cancelSaveForm(): void {
        this.modalClose.emit({refresh: false, templateId: null});
    }

    userSelectCompareWith(o1: any, o2: any) {
        return o1.id === o2.id;
    }
}
