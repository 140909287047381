import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class MultiSelectTranslationService {

    constructor(private translate: TranslateService) {
    }

    getMultiSelectTexts(): any {
        return {
          //old multiselect
            checkAll: this.translate.instant('multiSelectText.checkAll'),
            uncheckAll: this.translate.instant('multiSelectText.uncheckAll'),
            checked: this.translate.instant('multiSelectText.checked'),
            checkedPlural: this.translate.instant('multiSelectText.checkedPlural'),
            searchPlaceholder: this.translate.instant('multiSelectText.searchPlaceholder'),
            searchEmptyResult: this.translate.instant('multiSelectText.searchEmptyResult'),
            searchNoRenderText: this.translate.instant('multiSelectText.searchNoRenderText'),
            defaultTitle: this.translate.instant('multiSelectText.defaultTitle'),
            allSelected: this.translate.instant('multiSelectText.allSelected'),

  //new multiselect
          selectAllText: this.translate.instant('multiSelectText.checkAll'),
          unSelectAllText: this.translate.instant('multiSelectText.uncheckAll'),
          searchPlaceholderText: this.translate.instant('multiSelectText.searchPlaceholder'),
        };
    }
}
