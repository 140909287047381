<div class="modal-header">
  <h4 class="modal-title">{{'company.newSubscription.subscription' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="this.activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form name="contactForm" role="form" #subscriptionForm="ngForm" (ngSubmit)="save()">
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-6">
        <label for="startTime">{{'company.newSubscription.from' | translate}}</label>
        <jhi-date-picker [(dpModel)]="subscription.startTimeNgB" id="startTime"></jhi-date-picker>
      </div>
      <div class="form-group col-md-6">
        <label for="endTime">{{'company.newSubscription.to' | translate}}</label>
        <jhi-date-picker [(dpModel)]="subscription.endTimeNgB" id="endTime"></jhi-date-picker>
      </div>
    </div>
    <div class="form-group">
      <label for="selectedSubscription">{{'company.newSubscription.subscriptionGroup' | translate}}</label>
      <select required class="form-control" name="selectedSubscription" [(ngModel)]="selectedSubscription">
        <option></option>
        <option *ngFor="let subscription of subscriptions" [ngValue]="subscription">{{subscription.name}}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="subscriptionForm.form.invalid">{{'entity.action.save' | translate}}</button>
  </div>
</form>
