import { AdditionalDiagramResolution } from './additional-diagram-resolution';
import { DiagramType } from './diagram-type';
import { SeriesType } from './series-type';

export class AdditionalDiagramTypeDTO {
    diagramTypeId?: number;
    typeNameEN: string;
    typeNameHU: string;
    seriesType: SeriesType;
    typeDescriptionEN: string;
    typeDescriptionHU: string;
    columnName: string;
    diagramType: DiagramType;
    groupOnlyVisibility: boolean;
    measureUnit: string;
    resolution: AdditionalDiagramResolution;
}
