import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import { PodGroupModel } from '../../shared/dto/pod-group.model';
import { PodModel } from '../../shared/dto/pod.model';
import { FileSaverService } from '../../shared/services/file-saver.service';

@Injectable()
export class ConsumptionExportService {

    constructor(private fileSaver: FileSaverService) {
    }

    download(start: Moment, end: Moment, resolution: string, pods: PodModel[], podGroups: PodGroupModel[]) {

        const params = new HttpParams()
            .set('startTime', start.format('YYYY-MM-DD'))
            .set('endTime', end.format('YYYY-MM-DD'))
            .set('resolution', resolution);

        this.fileSaver.save(
            'api/pod_value/export',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            {
                pods: pods.map(pod => pod.id),
                podGroups: podGroups.map(podGroup => podGroup.id)
            },
            params
        );
    }
}
