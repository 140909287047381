import {Injectable} from '@angular/core';
import {BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import {DeliveryPeriodDto} from '../shared/dto/delivery-period-dto';
import {DeliveryPeriodForOffer} from '../shared/offer-claim/model/DeliveryPeriodForOffer';
import * as moment from 'moment';;
import {CoverageTransactionsService} from "./coverage-transactions.service";
import {StateStorageService} from "../shared/auth/state-storage.service";
import {CoverageTransactionsOverview} from "./overview/coverage-transactions-overview.model";
import {AgreementModel} from "../shared/dto/agreement.model";
import {CoverageTransactionsUtil} from "./coverage-transactions.util";

@Injectable({
    providedIn: 'root'
})
export class CoverageTransactionsPeriodTabService {

    public deliveryPeriodForOffers: DeliveryPeriodForOffer[] = [];
    public deliveryPeriods: Subject<DeliveryPeriodDto[]> = new ReplaySubject<DeliveryPeriodDto[]>(1);
    public selectedCompanyId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public coverageTransactionsOverviews: Subject<CoverageTransactionsOverview[]> = new ReplaySubject<CoverageTransactionsOverview[]>(1);
    public agreements: Subject<AgreementModel[]> = new ReplaySubject<AgreementModel[]>(1);

    private selectedDeliveryPeriod: Subject<DeliveryPeriodDto> = new ReplaySubject<DeliveryPeriodDto>(1);

    constructor(private coverageTransactionsService: CoverageTransactionsService, private stateStorageService: StateStorageService) {
        this.selectedCompanyId.next(this.stateStorageService.getSelectedCompanyId());
        this.getDeliveryPeriods();
        this.getAgreementByPartner();
    }

    public setSelectedDeliveryPeriod(dto: DeliveryPeriodDto): void {
        this.selectedDeliveryPeriod.next(dto);
    }

    public getSelectedDeliveryPeriod(): Subject<DeliveryPeriodDto> {
        return this.selectedDeliveryPeriod;
    }

    private getDeliveryPeriods(): void {
        this.coverageTransactionsService.getDeliveryPeriods(this.selectedCompanyId.getValue()).subscribe((deliveryPeriods: DeliveryPeriodDto[]) => {
            deliveryPeriods.sort((prev: DeliveryPeriodDto, curr: DeliveryPeriodDto) => {
                if (moment(prev.startTime).isBefore(curr.startTime)) return -1;
                return moment(prev.startTime).isAfter(curr.startTime) ? 1 : 0;
            });
            this.deliveryPeriods.next(this.filterDeliveryPeriods(deliveryPeriods));
        });
    }

    private filterDeliveryPeriods(deliveryPeriods: DeliveryPeriodDto[]): DeliveryPeriodDto[] {
        const startIndex: number = this.getFilteredDeliveryPeriodStartIndex(deliveryPeriods);
        const endIndex: number = this.getFilteredDeliveryPeriodEndIndex(deliveryPeriods, startIndex);

        return deliveryPeriods.slice(startIndex, endIndex);
    }

    private getFilteredDeliveryPeriodStartIndex(deliveryPeriods: DeliveryPeriodDto[]): number {
        const indexOfCurrentPeriod: number = this.findIndexOfCurrentPeriod(deliveryPeriods);
        return indexOfCurrentPeriod >= 1 ? indexOfCurrentPeriod - 1 : 0;
    }

    private getFilteredDeliveryPeriodEndIndex(deliveryPeriods: DeliveryPeriodDto[], startIndex: number): number {
        return deliveryPeriods.slice(startIndex).length >= 5 ? startIndex + 5 : deliveryPeriods.length;
    }

    private findIndexOfCurrentPeriod(deliveryPeriods: DeliveryPeriodDto[]): number {
        return deliveryPeriods.findIndex((deliveryPeriod: DeliveryPeriodDto) =>
            moment().isSameOrAfter(deliveryPeriod.startTime) &&
            moment().isSameOrBefore(moment(deliveryPeriod.endTime).add(1, 'day')));
    }

    private getAgreementByPartner(): void {
        this.coverageTransactionsService.getAgreementsByPartner(this.selectedCompanyId.getValue(), true).subscribe((agreements: AgreementModel[]) => {
            this.deliveryPeriodForOffers = CoverageTransactionsUtil.convertAgreementsToDeliveryPeriodsForOffer(agreements, false);
            this.agreements.next(agreements);
        });
    }

}
