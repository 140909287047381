import {SaleStatus} from './sale-status.enum';
import {Market} from './market.enum';
import {OfferType} from './offer-type.enum';
import {OfferPeriod} from './offer-period.enum';
import {OfferClaimPriceType} from './offer-claim-price-type.enum';
import {ProductType} from '../../agreement-details/agreement-enums.model';
import {OfferClaimHeadDto} from './OfferClaimHeadDto';
import {UserDto} from './UserDto';
import {PurchaseStatus} from './purchase-status.enum';

export class ClaimItemBaseModel {
  id: number;
  saleStatus: SaleStatus;
  market: Market;
  type: OfferType;
  period: OfferPeriod;
  periodNumber: number;
  startDate: Date;
  endDate: Date;
  quantity: number;
  price: number;
  netPrice: number;
  priceType: OfferClaimPriceType;
  timeRemain: number;
  deadline: Date;
  createdByPartner: boolean;
  productType: ProductType;
  averagingTransaction: boolean;
  averagingStartDate: Date;
  averagingEndDate: Date;
  offerClaimHead: OfferClaimHeadDto;
  pricePublisher: UserDto;
  purchaseStatus: PurchaseStatus;
  dealId: String;
}
