import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { SortEvent } from 'primeng/api';
import { PeriodicalPodModel } from '../dto/periodical.pod.model';
import { PodService } from '../services/pod.service';


@Component({
    selector: 'jhi-pod-list',
    templateUrl: './pod-list.component.html',
    styleUrls: ['pod-list.component.scss']
})
export class PodListComponent implements OnInit {

    @Input() model: PeriodicalPodModel[];
    @Input() adminView: boolean = false;
    @Output() editEmitter: EventEmitter<PeriodicalPodModel> = new EventEmitter();
    @Output() deleteEmitter: EventEmitter<number> = new EventEmitter();

    constructor(public podService: PodService) {
    }

    ngOnInit() {
        if (this.model)
            this.podService.calculateMefForPods(this.model.map(x => x.pod))
                .subscribe(result => result.forEach((e, i, a) => this.model[i].pod = e));
    }

    edit(invoice: PeriodicalPodModel) {
        this.editEmitter.emit(invoice);
    }

    delete(invoice: PeriodicalPodModel) {
        this.deleteEmitter.emit(invoice.id);
    }

    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {

            let extractFn;
            let transformFn;
            switch (event.field) {
                case 'podCode':
                    extractFn = data => data.pod.podCode;
                    transformFn = value => value;
                    break;
                case 'type':
                    extractFn = data => data.pod.podType;
                    transformFn = value => this.podService.translateProfileType(value);
                    break;
                case 'profileType':
                    extractFn = data => data.pod.profileType;
                    transformFn = value => value;
                    break;
                case 'zipCode':
                    extractFn = data => data.pod.zipCode;
                    transformFn = value => value;
                    break;
                case 'address':
                    extractFn = data => data.pod;
                    transformFn = value => this.podService.getPodAddress(value);
                    break;
                case 'start':
                    extractFn = data => data.startTime;
                    transformFn = value => moment(value).valueOf();
                    break;
                case 'end':
                    extractFn = data => data.endTime;
                    transformFn = value => moment(value).valueOf();
                    break;
                case 'yearlyStandardConsumption':
                    extractFn = data => data.pod.yearlyStandardConsumption;
                    transformFn = value => value;
                    break;
                default:
                    console.error('Unknown field: ' + event.field);
            }

            const value1 = extractFn(data1);
            const value2 = extractFn(data2);
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else {
                const transformed1 = transformFn(value1);
                const transformed2 = transformFn(value2);

                if (typeof transformed1 === 'string' && typeof transformed2 === 'string')
                    result = transformed1.localeCompare(transformed2);
                else
                    result = transformed1 - transformed2;
            }

            return (event.order * result);
        });
    }
}
