export class PodModel {
    id: number;
    podType: string;
    idmapOnly: boolean;
    yearlyStandardConsumption: number;
    salesForceId: string;
    consumer: string;
    podGroup: string;
    networkOperator: string;
    podCode: string;
    profileType: string;
    zipCode: string;
    city: string;
    street: string;
    streetNumber: string;
    concatenatedAddress: string;
}
