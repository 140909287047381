import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoadingOverlayService {

    private counter: number = 0;

    constructor() {
    }

    public turnOn(): void {
        this.counter++;
    }

    public turnOff(): void {
        this.counter--;
    }

    public isOn(): boolean {
        return this.counter > 0;
    }

}
