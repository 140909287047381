import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ContactModel} from '../../shared/dto/contact.model';
import {ExcelImportResult} from "../../shared/time-series/model/excel-import-result.model";
import {HttpErrorResponse} from "@angular/common/http";
import {Language} from '../../shared/agreement-details/agreement-enums.model';

@Component({
  selector: 'ngbd-modal-content',
  templateUrl: "contact.modal.component.html"
})
export class ContactModalComponent implements OnInit {
  @Input() contact: ContactModel;
  @Input() isCez: boolean;
  @Output() modalClose: EventEmitter<ContactModel> = new EventEmitter();


  isSaving: boolean = false;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  fileuploaderFileChange(files: FileList) {
    this.contact.image = files;
  }

  save() {
    this.modalClose.emit(this.contact);

  }

  public addFile(fileInput: HTMLInputElement) {
    fileInput.value = '';
    fileInput.click();
  }

  public onUpload(event: { file: File, success: boolean, response?: ExcelImportResult, error?: HttpErrorResponse }) {
    this.contact.image.item[0] = event.file;
  }

  protected readonly languages = Language;
}
