import { SeriesType } from './model/series-type';
import { DiagramType } from './model/diagram-type';
import { IntervalType } from '../../price/model/interval-type';
import { AdditionalDiagramResolution } from './model/additional-diagram-resolution';
import { ProductDto } from '../../price/model/productDto';
import { AdditionalDiagramTypeDTO } from './model/additional-diagram-type.model';
import { AdditionalTimeSeriesDto } from '../../price/model/additional-time-series';
import { Injectable } from "@angular/core";

@Injectable()
export class AdditionalDiagUtil {

    static readonly product_measure_unit: string = 'EUR/MWh';

    public static convertProductDto(product: ProductDto): AdditionalDiagramTypeDTO {
        return {
            diagramTypeId: product.timeSeriesHeaderId,
            typeNameEN: product.productName,
            typeNameHU: product.productName,
            typeDescriptionHU: null,
            typeDescriptionEN: null,
            seriesType: SeriesType.PRODUCT,
            columnName: product.productType,
            diagramType: DiagramType.LINE,
            groupOnlyVisibility: false,
            measureUnit: AdditionalDiagUtil.product_measure_unit,
            resolution: product.intervalType === IntervalType.SPOT ? AdditionalDiagramResolution.HOURLY : AdditionalDiagramResolution.DAILY
        };
    }

    public static convertAdditionalTimeSeriesDto(additionalTimeSeries: AdditionalTimeSeriesDto): AdditionalDiagramTypeDTO {
        return {
            diagramTypeId: additionalTimeSeries.timeSeriesIds[0].timeSeriesHeaderId,
            typeNameEN: additionalTimeSeries.name,
            typeNameHU: additionalTimeSeries.name,
            typeDescriptionEN: null,
            typeDescriptionHU: null,
            seriesType: SeriesType.PRODUCT,
            columnName: 'SPOT',
            diagramType: DiagramType.LINE,
            groupOnlyVisibility: false,
            measureUnit: AdditionalDiagUtil.product_measure_unit,
            resolution: AdditionalDiagramResolution.HOURLY
        };
    }

}
