import { Directive, HostListener } from '@angular/core';
import { SessionTimerService } from './session-timer.service';

@Directive({
    selector: '[restartSession]'
})
export class RestartSessionDirective {

    constructor(private sessionTimerService: SessionTimerService) {
    }

    @HostListener('mouseup', ['$event'])
    @HostListener('keyup.enter', ['$event'])
    triggerEvent(event) {
        this.sessionTimerService.restartSession(true);
    }

}
