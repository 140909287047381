import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseDto } from '../../../shared/dto/BaseDto';
import { SubscriptionModel } from '../../../shared/dto/subscription.model';
import { NgBDatePickerConvertService } from '../../../shared/services/ngb.datepicker.convert.service';

@Component({
    selector: 'jhi-subscription-modal',
    templateUrl: './subscription-modal.component.html',
    styles: []
})
export class SubscriptionModalComponent implements OnInit {

    @Input() subscription: SubscriptionModel;
    @Output() modalClose: EventEmitter<SubscriptionModel> = new EventEmitter();
    subscriptions: BaseDto[] = [];
    selectedSubscription: BaseDto;

    constructor(private dateConverter: NgBDatePickerConvertService,
                public activeModal: NgbActiveModal,
                private http: HttpClient) {
    }

    ngOnInit() {
        if (this.subscription.startTime)
            this.subscription.startTimeNgB = this.dateConverter.convertFromDate(this.subscription.startTime);

        if (this.subscription.endTime)
            this.subscription.endTimeNgB = this.dateConverter.convertFromDate(this.subscription.endTime);

        this.http.get<BaseDto[]>('/api/groups/SUBSCRIPTION').subscribe(subscriptions => {
            this.subscriptions = subscriptions;
            this.selectedSubscription = this.subscriptions.filter(subscription => subscription.id == this.subscription.subscriptionGroup.id)[0];
        });
    }

    save() {
        if (this.subscription.startTimeNgB)
            this.subscription.startTime = this.dateConverter.convertToDate(this.subscription.startTimeNgB);
        else
            this.subscription.startTime = null;

        if (this.subscription.endTimeNgB)
            this.subscription.endTime = this.dateConverter.convertToDate(this.subscription.endTimeNgB);
        else
            this.subscription.endTime = null;

        this.subscription.subscriptionGroup = {
            id: this.selectedSubscription.id,
            name: this.selectedSubscription.name,
            permissions: null,
            type: null
        };

        this.modalClose.emit(this.subscription);
    }

}
