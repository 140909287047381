import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {Observable} from "rxjs";
import {PodModel} from '../../shared/dto/pod.model';
import {CanLeave, CanLeaveService} from '../../shared/can-leave/can-leave.service';
import {PodGroupModel} from '../../shared/dto/pod-group.model';
import {PodGroupCategoryModel} from '../../shared/dto/pod-group-category.model';
import {ToastMessageModel} from '../../shared/dto/toast-message.model';
import {PodService} from '../../shared/services/pod.service';
import {StateStorageService} from '../../shared/auth';
import {DialogService} from '../../shared/dialog/dialog.service';
import {Table} from 'primeng/table';
import {FilterService} from 'primeng/api';

@Component({
    selector: 'jhi-pod-group-component',
    templateUrl: './pod-group-component.component.html',
    styles: []
})
export class PodGroupComponentComponent implements OnInit, CanLeave {

    selectedPods: PodModel[];
    podTypes: any[];
    podListOptions: PodModel[];
    podListOriginal: PodModel[];
    everOwnedPods: PodModel[];
    podGroups: PodGroupModel[];
    podGroupCategories: PodGroupCategoryModel[];
    selectedPodGroup: PodGroupModel;
    selectedPodGroupCategory: PodGroupCategoryModel;
    newPodGroupName: string;
    newPodGroupCategoryName: string;
    editedPodGroup: PodGroupModel;
    addNewPodGroup: boolean;
    newGroupCategory: boolean;
    alerts: ToastMessageModel[] = [];
    filterTimeout: any;
    dataTableHeaderTranslations: { [id: string]: string; } = {};
    isCategoryAllowed: boolean;
    searchAddressText: string;

    constructor(public podService: PodService,
                private translateService: TranslateService,
                private stateStorage: StateStorageService,
                private dialogService: DialogService,
                private canLeaveService: CanLeaveService,
                private filterService: FilterService) {
        this.podTypes = [
            {label: '', value: null},
            {label: this.podService.translateProfileType('PROFILE'), value: 'PROFILE'},
            {label: this.podService.translateProfileType('TIME_SERIES'), value: 'TIME_SERIES'}
        ];
        podService.getAllPod().subscribe(r => this.everOwnedPods = r);
        this.refreshPodGroupCategories();
    }

    ngOnInit() {
        this.isCategoryAllowed = this.stateStorage.getEffectivePermissions().filter(p => p === 'POD_GROUP_USER_WRITE' || p === 'POD_GROUP_SYSTEM_WRITE').length > 0;
        this.filterService.register('isNull', (value): boolean => {
          return value === null || value === undefined;
      });
    }

    canLeave(): Observable<boolean> {
        return this.canLeaveService.canLeave();
    }

    getTranslation(key: string) {
        let translation = this.dataTableHeaderTranslations[key];
        if (!translation || translation.indexOf('not-found') > -1) {
            translation = this.translateService.instant(key);
            this.dataTableHeaderTranslations[key] = translation;
        }

        return translation;
    }

    refreshPodGroupCategories() {
        this.podService.getPodGroupCategories().subscribe(r => {
            this.podGroupCategories = r;
            this.podGroupCategories.map(cat => {
                cat.editMode = false;
                cat.autoCategorize = false;
                cat.selectedAutocategorizeType = '';
            });

            this.refreshPodGroups();
        });
    }

    refreshPodGroups() {
        this.podListOptions = [];
        this.selectedPods = [];
        this.podService.getPodGroupsOfPartner().subscribe(r => {
            this.podGroups = r;
            if (this.selectedPodGroup && this.podGroups.every(group => group.id !== this.selectedPodGroup.id)) {
                this.selectedPodGroup = null;
            }
        });
    }

    selectedGroupChange() {
        if (!this.selectedPodGroup)
            return;
        this.onChange();
        this.podService.getAllPodForGroup(this.selectedPodGroup.podGroupCategoryId).subscribe(pods => {
            this.podListOptions = pods;

            this.selectedPods = this.everOwnedPods
                .filter(pod => this.selectedPodGroup.podIds
                    .some(podId => podId === pod.id));

            this.podListOptions.push(...this.selectedPods);

            this.podListOriginal = this.podListOptions;
            this.podListOptions.forEach(a=>{a.concatenatedAddress = this.podService.getPodAddress(a)});
        });
    }

    editPodGroup() {
        this.addNewPodGroup = true;
        this.editedPodGroup = this.selectedPodGroup;
        this.newPodGroupName = this.selectedPodGroup.name;
        this.selectedPodGroupCategory = this.podGroupCategories.filter(r => r.id === this.selectedPodGroup.podGroupCategoryId)[0];
    }

    deletePodGroup() {
        this.dialogService.confirm(this.translateService.instant('filter.basic.deleteDialog.title'), this.translateService.instant('filter.basic.deleteDialog.message'))
            .subscribe((value: boolean): void => {
                if (value) {
                    this.deleteConfirmed();
                }
            });
    }

    deleteConfirmed(): void {
        this.podService.deletePodGroup(this.selectedPodGroup.id).subscribe(() => {
            this.podGroups = this.podGroups.filter(r => r.id !== this.selectedPodGroup.id);
            this.selectedPodGroup = null;
            this.alerts.push({
                id: 1,
                type: 'success',
                message: null,
                messageLocalized: 'common.result.deleteSuccess'
            });
        });
    }

    submitPodGroup() {
        let newPodGroup;
        if (this.editedPodGroup) {
            this.editedPodGroup.name = this.newPodGroupName;
            this.editedPodGroup = null;
        } else {
            newPodGroup = new PodGroupModel();
            newPodGroup.name = this.newPodGroupName;
            newPodGroup.podGroupCategoryId = this.selectedPodGroupCategory.id;
            newPodGroup.podIds = [];
            this.podGroups.push(newPodGroup);
            this.selectedPodGroup = newPodGroup;
            this.selectedGroupChange();
        }
        this.closeConfirmed();
    }

    submitPodGroupCategory() {
        let newPodGroupCategory = new PodGroupCategoryModel();
        newPodGroupCategory.name = this.newPodGroupCategoryName;
        this.podService.addPodGroupCategory(newPodGroupCategory).subscribe(r => {
            this.podGroupCategories.push(r);
            this.closeNewPodGroupCategoriesConfirmed();
        });
    }

    updatePodGroupCategory(podGroupCategory: PodGroupCategoryModel) {
        if (podGroupCategory.autoCategorize)
            this.podService.autoCategorizePodGroups(podGroupCategory.id, podGroupCategory.selectedAutocategorizeType).subscribe(r => {
                this.alerts.push({
                    id: 1,
                    type: 'success',
                    message: null,
                    messageLocalized: 'common.result.saveSuccess'
                });
                podGroupCategory.editMode = false;
                podGroupCategory.autoCategorize = false;
                this.refreshPodGroupCategories();
                this.refreshPodGroups();
            });
        else
            this.podService.addPodGroupCategory(podGroupCategory).subscribe(r => {
                podGroupCategory.editMode = false;
            });
    }

    refreshAutoCategory(podGroupCategory: PodGroupCategoryModel) {
        this.podService.autoCategoryPodGroupRefresh(podGroupCategory.id).subscribe(r => {
            this.alerts.push({
                id: 1,
                type: 'success',
                message: null,
                messageLocalized: 'common.result.saveSuccess'
            });
            this.refreshPodGroups();
        });
    }

    closeNewPodGroupCategories() {
        this.canLeaveService.canLeave()
            .subscribe((value) => {
                if (value) {
                    this.closeNewPodGroupCategoriesConfirmed();
                }
            });
    }

    closeNewPodGroupCategoriesConfirmed() {
        this.newGroupCategory = false;
        this.newPodGroupCategoryName = '';
        this.canLeaveService.setModified(false);
    }

    close() {
        this.canLeaveService.canLeave()
            .subscribe((value) => {
                if (value) {
                    this.closeConfirmed();
                }
            });
    }

    closeConfirmed() {
        this.addNewPodGroup = false;
        this.editedPodGroup = null;
        this.newPodGroupName = '';
        this.selectedPodGroupCategory = undefined;
        this.canLeaveService.setModified(false);
    }

    onChange(): void {
        this.canLeaveService.setModified({allowedTabsets: ['pod-group']});
    }

    submit() {
        this.selectedPodGroup.podIds = this.selectedPods.map(r => r.id);
        this.podService.createOrUpdatePodGroupsOfPartner(this.selectedPodGroup).subscribe(r => {
            this.selectedPodGroup.id = r.id;
            this.alerts.push({
                id: 1,
                type: 'success',
                message: null,
                messageLocalized: 'common.result.saveSuccess'
            });
            this.canLeaveService.setModified(false);
        });
    }

    deletepodGroupCategory(podGroupCategory: PodGroupCategoryModel) {
        this.dialogService.confirm(this.translateService.instant('filter.basic.deleteDialog.title'), this.translateService.instant('filter.basic.deleteDialog.message'))
            .subscribe((value: boolean): void => {
                if (value) {
                    this.deletepodGroupCategoryConfirmed(podGroupCategory);
                }
            });
    }

    deletepodGroupCategoryConfirmed(podGroupCategory: PodGroupCategoryModel): void {
        this.podService.deletePodGroupCategory(podGroupCategory.id).subscribe(() => {
            this.refreshPodGroupCategories();
            this.refreshPodGroups();
        });
    }

    autoCategorize(podGroupCategory: PodGroupCategoryModel) {
        podGroupCategory.editMode = true;
        podGroupCategory.autoCategorize = true;
    }

    addressFilter(searchText) {
        if (this.filterTimeout) {
            clearTimeout(this.filterTimeout);
        }

        this.filterTimeout = setTimeout(() => {
            let searchedValue = searchText.toLocaleLowerCase();

            this.podListOptions = this.podListOriginal.filter(pod =>
                this.podService.getPodAddress(pod).toLocaleLowerCase().indexOf(searchedValue) > -1
            );
        }, 250);

    }

    getGroupCategoryName(podgroup: PodGroupModel) {
        return this.podGroupCategories.filter(r => r.id == podgroup.podGroupCategoryId)[0].name;
    }

    duplicatedPodGroupName(): boolean {
        if (!this.newPodGroupName)
            return false;

        return this.podGroups.some(r => r.name.toLowerCase() === this.newPodGroupName.toLowerCase());
    }

    isPodGroupCategoryTypeUsed(type: string): boolean {
        return this.podGroupCategories.some(r => r.autoCategoryType === type);
    }

    sortPodOptions(event: any) {
        this.customSort(event, this.podListOptions);
        this.podListOptions = [...this.podListOptions];
    }

    sortSelectedPods(event: any) {
        this.customSort(event, this.selectedPods);
        this.selectedPods = [...this.selectedPods];
    }

    preventSelection(): void {
        // just created this method because it is called in the html, but who knows what exactly should it do ...
    }

    private customSort(event: { field: string, order: -1|1 }, model: PodModel[]) {
        model.sort((data1: PodModel, data2: PodModel) => {
            let extractFn;
            let transformFn;
            switch (event.field) {
                case 'podCode':
                    extractFn = data => data.podCode;
                    transformFn = value => value;
                    break;
                case 'podType':
                    extractFn = data => data.podType;
                    transformFn = value => this.podService.translateProfileType(value);
                    break;
                case 'zipCode':
                    extractFn = data => data.zipCode;
                    transformFn = value => value;
                    break;
                case 'address':
                    extractFn = data => data;
                    transformFn = value => this.podService.getPodAddress(value);
                    break;
                default:
                    console.error('Unknown field: ' + event.field);
            }

            const value1 = extractFn(data1);
            const value2 = extractFn(data2);
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else {
                const transformed1 = transformFn(value1);
                const transformed2 = transformFn(value2);

                if (typeof transformed1 === 'string' && typeof transformed2 === 'string')
                    result = transformed1.localeCompare(transformed2);
                else
                    result = transformed1 - transformed2;
            }

            return (event.order * result);
        });
    }

  filterPodType(table: Table, value: any) {
    return table.filter((value) ? (value as any ).value : value, 'podType', (value) ? ((value as any ).value == null ? 'isNull' : 'equals'): 'equals');
  }
}
