import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'jhi-bootstrap-toggle',
    templateUrl: './bootstrap-toggle.component.html',
    styles: []
})
export class BootstrapToggleComponent implements OnInit {

    @Input() state: boolean;
    @Input() onText: string;
    @Input() offText: string;
    @Input() width: number;
    @Output() stateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
    }

    toggle() {
        this.state = !this.state;
        this.stateChange.emit(this.state);
    }

}
