<mat-form-field class="chip-list">
    <mat-chip-list #chipList>
        <mat-chip
            *ngFor="let item of selectedItems"
            [selectable]="selectable"
            [removable]="removable"
            (removed)="remove(item)">
            {{nameFunction(item)}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input
            [placeholder]="placeholder"
            #itemInput
            #trigger="matAutocompleteTrigger"
            (click)="trigger.openPanel()"
            [formControl]="inputFormControl"
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="addOnBlur"
            (matChipInputTokenEnd)="add($event)">
    </mat-chip-list>
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-option class="select-all" [hidden]="items.length == selectedItems.length">{{'chip-list.select-all' |
            translate}}
        </mat-option>
        <mat-option *ngFor="let item of filteredItems | async"
                    [value]="nameFunction(item)">
            {{nameFunction(item)}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
