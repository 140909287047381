import {Injectable} from '@angular/core';

import {Observable} from 'rxjs';
import {CanLeave} from './can-leave.service';

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard  {
  public canDeactivate(component: CanLeave): Observable<boolean> | Promise<boolean> | boolean {
    return component.canLeave ? component.canLeave() : true;
  }
}
