import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
    ControlValueAccessor,
    UntypedFormBuilder,
    UntypedFormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    Validator
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FilterParam } from '../model/filter-event.model';
import { ResultViewType } from '../model/result-view-type.enum';
import { ScopeType } from '../model/scope.model';
import {PodModel} from '../../shared/dto/pod.model';
import {PodGroupModel} from '../../shared/dto/pod-group.model';
import {InvoiceResourceDTO} from '../../admin/invoice-resource/model/invoice-reosurce.model';
import {Resolution} from '../../shared/time-series/model/resolution.enum';
import {DateInterval} from '../../shared/dto/date-interval.model';
import {PodService} from '../../shared/services/pod.service';
import {InvoiceResourceService} from '../../admin/invoice-resource/service/invoice-resource.service';
import {InvoiceReportService} from '../../shared/services/invoice-report.service';

export function createFilterValidator(scope: ScopeType, resolutionView: ResultViewType) {
    return function validateFilter(c: UntypedFormControl) {
        if (c.value === null || c.value.resources === null || c.value.resources.length === 0) {
            return 'Missing resources';
        }

        if (scope === 'POD') {
            if ((c.value.pods === null || c.value.pods.length === 0) && (c.value.podGroups === null || c.value.podGroups.length === 0)) {
                return 'Missing pods or pod groups';
            }
        }

        if (resolutionView === 'COLUMNDIAGRAM') {
            if (c.value.resolution == null || c.value.unitCost == null) {
                return 'Missing resolution or unitCost';
            }
        }

        if (resolutionView === 'PIECHART') {
            if (c.value.unitCost == null) {
                return 'Missing unitCost';
            }
        }

        return null;
    };
}

@Component({
    selector: 'jhi-filters',
    templateUrl: './filters.component.html',
    styleUrls: ['./filters.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FiltersComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FiltersComponent),
            multi: true
        }
    ]
})
export class FiltersComponent implements OnInit, ControlValueAccessor, Validator, OnChanges {

    @Input() scope: ScopeType;
    @Input() resultView: ResultViewType;

    validateFn: Function;

    resolutionControl: UntypedFormControl;

    parametersList: FilterParam = {
        intervals: [],
        resources: [],
        pods: [],
        podGroups: [],
        resolution: null,
        unitCost: null
    };

    selectablePodList: PodModel[] = [];
    selectablePodGroupList: PodGroupModel[] = [];
    selectableResourceList: InvoiceResourceDTO[] = [];
    resolutions = Resolution;

    private oldIntervals: DateInterval[];

    constructor(private fb: UntypedFormBuilder,
                private podService: PodService,
                private invoiceResourceService: InvoiceResourceService,
                private translate: TranslateService,
                private invoiceReportService: InvoiceReportService) {
        this.resolutionControl = new UntypedFormControl();
        this.resolutionControl.valueChanges.subscribe(value => this.resolutionSelectionChanged(value));
    }

    ngOnInit() {
        this.podService.getAllPod().subscribe(pods => this.selectablePodList = pods);
        this.podService.getPodGroupsOfPartner().subscribe(groups => this.selectablePodGroupList = groups);
        this.invoiceReportService.getInvoiceResourceParents().subscribe(resources => this.selectableResourceList = resources);

        this.validateFn = createFilterValidator(this.scope, this.resultView);
    }

    validate(c: UntypedFormControl) {
        return this.validateFn(c);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.validateFn = createFilterValidator(this.scope, this.resultView);

        if (this.resultView === ResultViewType.PIECHART) {
            this.oldIntervals = this.parametersList.intervals;
            this.parametersList.intervals = [this.parametersList.intervals[0]];
        } else {
            if (this.oldIntervals) {
                this.parametersList.intervals = this.oldIntervals;
            }
        }

    }

    resourceSelectionChanged(resources): void {
        this.parametersList.resources = resources;
        this.propagateChange(this.parametersList);
    }

    podSelectionChanged(pods): void {
        this.parametersList.pods = pods;
        this.propagateChange(this.parametersList);
    }

    podGroupSelectionChanged(podGroups): void {
        this.parametersList.podGroups = podGroups;
        this.propagateChange(this.parametersList);
    }

    dateIntervalList(intervalList: DateInterval[]) {
        this.parametersList.intervals = intervalList;
        this.propagateChange(this.parametersList);
    }

    podSelectorVisible() {
        return this.scope === ScopeType.POD;
    }

    podNameFunction() {
        return (item: PodModel) => item.podCode;
    }

    podGroupNameFunction() {
        return (item: PodGroupModel) => item.name;
    }

    resolutionSelectorVisible() {
        return this.resultView === ResultViewType.COLUMNDIAGRAM;
    }

    unitCostSelectorVisible() {
        return this.resultView === ResultViewType.COLUMNDIAGRAM ||
               this.resultView === ResultViewType.PIECHART;
    }

    resolutionSelectionChanged(resolution: Resolution): void {
        this.parametersList.resolution = resolution;
        this.propagateChange(this.parametersList);
    }

    unitCostSelectionChanged(unitCostEvent): void {
        this.parametersList.unitCost = unitCostEvent.value;
        this.propagateChange(this.parametersList);
    }

    resourceNameFunction() {
        const currentLang = this.translate.currentLang;
        return (item: InvoiceResourceDTO) => currentLang === 'hu' ? item.nameHu : item.nameEn;
    }


    propagateChange = (_: any) => {
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: FilterParam): void {
        this.parametersList = obj;
        this.propagateChange(this.parametersList);
    }

    get datesUpperBound(): number {
        if (this.resultView === 'PIECHART') {
            return 1;
        } else {
            return null;
        }
    }


}
