import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {CanLeave, CanLeaveService} from '../../shared/can-leave/can-leave.service';

@Component({
  selector: 'jhi-pod-group-page',
  templateUrl: './pod-group-page.component.html',
  styles: []
})
export class PodGroupPageComponent implements OnInit, CanLeave {

  constructor(private canLeaveService: CanLeaveService) { }

  ngOnInit() {
  }

  canLeave(): Observable<boolean> {
    return this.canLeaveService.canLeave();
  }

}
