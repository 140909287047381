import { Route } from '@angular/router';
import { TermsOfServiceComponent } from './terms.of.service.component';
import { UserRouteAccessService } from '../shared';

export const TERMS_OF_SERVICE_ROUTE: Route = {
  path: 'termsofservice',
  component: TermsOfServiceComponent,
  data: {
    pageTitle: 'home.title'
  },
  canActivate: [UserRouteAccessService]
};
