import { InvoiceResourceDistributionProperty } from './invoice-resource-distribution-property.model';
import { InvoiceResourceGroup } from './invoice-resource-group.model';

export class InvoiceResourceDTO {
    resourceId: number;
    assignedId: number;
    nameEn: string;
    nameHu: string;
    resourceGroup: InvoiceResourceGroup;
    shouldBeProcessed: boolean;
    distributionProperty: InvoiceResourceDistributionProperty;
    unit: string;
    usedInUnitCost: boolean;
}
