export type InvoiceResourceGroup =
    'ELECTRICITY'|
    'EXTRA_ITEMS'|
    'OTHER'|
    'SYSTEM_USAGE_FEE'|
    'GUARANTEES_OF_ORIGIN';

export const InvoiceResourceGroup = {
    ELECTRICITY: 'ELECTRICITY' as InvoiceResourceGroup,
    EXTRA_ITEMS: 'EXTRA_ITEMS' as InvoiceResourceGroup,
    OTHER: 'OTHER' as InvoiceResourceGroup,
    SYSTEM_USAGE_FEE: 'SYSTEM_USAGE_FEE' as InvoiceResourceGroup,
    GUARANTEES_OF_ORIGIN: 'GUARANTEES_OF_ORIGIN' as InvoiceResourceGroup
};
