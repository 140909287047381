import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateInterval } from '../../../shared/dto/date-interval.model';
import * as moment from 'moment';;


@Component({
    selector: 'dynamic-day-interval-selector',
    templateUrl: './dynamic-day-interval-selector.component.html'
})
export class DynamicDayIntervalSelectorComponent {

    private _intervals: DateInterval[] = [{
        from: moment(),
        to: moment()
    }];
    @Input()
    set intervals(intervals: DateInterval[]) {
        this._intervals = Array.from(intervals);
    };
    get intervals(): DateInterval[] {
        return this._intervals;
    }

    @Output()
    intervalsChange: EventEmitter<DateInterval[]> = new EventEmitter();

    private _upperBound: number;
    @Input() set upperBound(upperBound: number) {
        this._upperBound = upperBound;
        if (this.intervals.length > upperBound) {
            this.intervals.splice(upperBound);
            this.intervalsChanged();
        }
    };
    get upperBound(): number {
        return this._upperBound;
    }

    get noMoreDatesAllowed(): boolean {
        if (this.upperBound) {
            return this.upperBound <= this.intervals.length;
        }

        return false;
    }

    addNextDateInterval() {
        this.intervals.push({
            from: null,
            to: null
        });
        this.intervalsChanged();
    }

    deleteInterval(index: number) {
        this.intervals.splice(index, 1);
        this.intervalsChanged();
    }

    setInterval(interval: DateInterval, intervalIndex: number) {
        this.intervals[intervalIndex] = interval;
        this.intervalsChanged();
    }

    private intervalsChanged() {
        const newArray = Array.from(this.intervals);
        this.intervalsChange.emit(newArray);
    }

    private notDefinedInterval(interval: DateInterval) {
        return !Boolean(interval.from) || !Boolean(interval.to);

    }
}
