import {Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, ViewChild} from '@angular/core';
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';

@Component({
  selector: 'jhi-date-picker',
  templateUrl: './date-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent implements ControlValueAccessor {
  @Output() click: EventEmitter<any> = new EventEmitter();
  @Input() id: string;
  @Input() dpModel: NgbDateStruct;
  @Input() disabled: boolean;
  @Input() placeholder: string = '';
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Output() dpModelChange: EventEmitter<any> = new EventEmitter();
  @ViewChild("d", {static: true}) datePicker;

  public onChange: Function;
  public onTouched: Function;
  @Input() isDayDisabled: (date: NgbDate) => boolean = (date: NgbDate): boolean => false;

  constructor(private _eref: ElementRef) {}

  @HostListener('document:click', ['$event']) clickedOutside(event) {
    if (event && !this._eref.nativeElement.contains(event.target)) {
      this.datePicker.close();
    }
  }

  public changeEvent(): void {
    this.dpModelChange.emit(this.dpModel);
    if (this.onChange) {
      this.onChange(this.dpModel);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public writeValue(obj: any): void {
    this.dpModel = obj;
  }

}
