import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ByteFormatterPipe' })
export class ByteFormatterPipe implements PipeTransform {
  transform(value) {
    let byte = <number>value;
      let i = Math.floor(Math.log(byte) / Math.log(1024));
      let units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    return Math.round(byte / Math.pow(1024, i))  +  ' ' + units[i];
  }
}
