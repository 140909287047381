import {LOCATION_INITIALIZED, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import localeHu from '@angular/common/locales/hu';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, Injector, LOCALE_ID, NgModule} from '@angular/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {HIGHCHARTS_MODULES} from 'angular-highcharts';
import * as highcharts_more from 'highcharts/highcharts-more.src';
import * as accessibility from 'highcharts/modules/accessibility.src';
import * as annotations from 'highcharts/modules/annotations.src';

import * as boost_canvas from 'highcharts/modules/boost-canvas.src';
import * as data from 'highcharts/modules/data.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as hc_exportData from 'highcharts/modules/export-data.src';
import * as heatmap from 'highcharts/modules/heatmap.src';
import * as moment from 'moment';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';
import {NgxWebstorageModule} from 'ngx-webstorage';
import {finalize} from 'rxjs/operators';
import {CezPortalAccountModule} from './account/account.module';
import {CezPortalAdminModule} from './admin/admin.module';
import {AdminTabsModule} from './admin/admintabs/admin.tabs.module';
import {AgreementUserModule} from './agreement-user/agreement-user.module';
import {BillsModule} from './bills/bills.module';
import {PaginationConfig} from './blocks/config/uib-pagination.config';
import {BookedPerformanceModule} from './booked-performance/booked-performance.module';
import {CompanySelectorPageComponent} from './company-selector-page/company-selector-page.component';

import {COMPANY_SELECTOR_ROUTE} from './company-selector-page/company-selector.route';
import {ConsumptionModule} from './consumption/consumption.module';
import {ContactPageModule} from './contact-page/contact-page.module';
import {DocumentAdminModule} from './documents.admin/document.admin.module';
import {DocumentModule} from './documents/document.module';
import {CezPortalEntityModule} from './entities/entity.module';
import * as boost from 'highcharts/modules/boost.src';
import {CezPortalHomeAdminModule} from './home.admin/home.module';
import {CezPortalHomeModule} from './home/home.module';
import {InvoiceReportModule} from './invoice-report/invoice-report.module';


import {
  ActiveMenuDirective,
  ErrorComponent,
  FooterComponent,
  JhiMainComponent,
  LayoutRoutingModule,
  NavbarComponent,
  PageRibbonComponent,
  ProfileService,
  SidebarComponent
} from './layouts';
import {LinkModule} from './links/link.module';
import {LoginComponent} from './login/login.component';
import {LOGIN_ROUTE} from './login/login.route';
import {LoginService} from './login/login.service';
import {NewsAdminModule} from './news.admin/news.admin.module';
import {NewsModule} from './news/news.module';
import {MarketPriceModule} from './price/market-price.module';
import {ProductsModule} from './products/products.module';
import {RegistrationRequestComponent} from './registration.request/registration.request.component';
import {REGISTRATION_ROUTE} from './registration.request/registration.request.route';

import {CezPortalSharedModule, UserRouteAccessService} from './shared';

import {BreadcrumbComponent} from './shared/breadcrumb/breadcrumb.component';
import {DialogModule} from './shared/dialog/dialog.module';
import {CezHttpInterceptorService} from './shared/services/cez-http-interceptor.service';
import {SiteMapModule} from './site-map/site-map.module';
import {TermsOfServiceComponent} from './terms.of.service/terms.of.service.component';
import {TERMS_OF_SERVICE_ROUTE} from './terms.of.service/terms.of.service.route';
import {ToleranceThresholdModule} from './tolerance-threshold/tolerance-threshold.module';
import {TwofactorAuthModule} from './twofactor-auth/twofactor-auth.module';
import {InvoicesModule} from './invoices/invoices.module';
import {ConsumptionNewModule} from "./consumption-new/consumption-new.module";
import {ReportsModule} from './reports/reports.module';
import {CoverageTransactionsModule} from './coverage-transactions/coverage-transactions.module';
import {CountdownModule} from "ngx-countdown";
import {NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {CorporateDataModule} from "./corporate-data/corporate-data.module";
import {ContactDataModule} from "./company-data/contact-data.module";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {environment} from "../environments/environment";
import {HighchartsService} from './highcharts/highcharts.service';
import {StateStorageService} from './shared/auth';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {AppComponent} from './app.component';
import {init} from './highcharts/highcharts.extension';
import * as Highcharts from 'highcharts';


registerLocaleData(localeHu, 'hu');
Highcharts.setOptions({
  global: {
    useUTC: false
  }
});

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/i18n/', `.json?${environment/*.version*/}`);
}

@NgModule({
  declarations: [
    AppComponent,
    JhiMainComponent,
    NavbarComponent,
    ErrorComponent,
    PageRibbonComponent,
    ActiveMenuDirective,
    FooterComponent,
    SidebarComponent,
    BreadcrumbComponent,
    TermsOfServiceComponent,
    RegistrationRequestComponent,
    LoginComponent,
    CompanySelectorPageComponent
  ],
  imports: [
    BrowserModule,
    LayoutRoutingModule,
    NgxWebstorageModule.forRoot({prefix: 'jhi', separator: '-'}),
    RouterModule.forRoot([TERMS_OF_SERVICE_ROUTE, REGISTRATION_ROUTE, LOGIN_ROUTE, COMPANY_SELECTOR_ROUTE], {useHash: true}),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CezPortalSharedModule,
    CezPortalHomeModule,
    CezPortalAdminModule,
    CezPortalAccountModule,
    CezPortalEntityModule,
    DocumentModule,
    LinkModule,
    NewsModule,
    NewsAdminModule,
    DocumentAdminModule,
    AdminTabsModule,
    CezPortalHomeAdminModule,
    AgreementUserModule,
    ProductsModule,
    ConsumptionModule,
    ConsumptionNewModule,
    BookedPerformanceModule,
    TwofactorAuthModule,
    BillsModule,
    CorporateDataModule,
    ContactDataModule,
    SiteMapModule,
    InvoicesModule,
    ContactPageModule,
    MarketPriceModule,
    InvoiceReportModule,
    ToleranceThresholdModule,
    ReportsModule,
    DialogModule,
    CoverageTransactionsModule,
    CountdownModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.threeBounce,
      backdropBackgroundColour: 'rgba(0,0,0,0.2)',
      backdropBorderRadius: '0',
      fullScreenBackdrop: true,
      primaryColour: '#f24f00',
      secondaryColour: '#f24f00',
      tertiaryColour: '#f24f00'
    })

  ],
  providers: [ProfileService,
    PaginationConfig,
    UserRouteAccessService,
    LoginService,
    CezHttpInterceptorService,
    HighchartsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CezHttpInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      deps: [TranslateService],
      useFactory: (translate: TranslateService) => translate.currentLang || 'en'
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS
    },
    {provide: NgbModalConfig, useValue: {backdrop: 'static', keyboard: false}},
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [highcharts_more, exporting, hc_exportData, accessibility, data, heatmap, boost_canvas, boost, annotations]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => init,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      deps: [TranslateService, Injector, StateStorageService],
      useFactory: (translateService: TranslateService, injector: Injector, $storageService: StateStorageService) => () => new Promise<any>((resolve: any) => {

        const userLang = $storageService.getLanguage();
        const browserLang = translateService.getBrowserLang();
        const languageToUse = userLang ? userLang : browserLang;
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

        translateService.addLangs(['en', 'hu']);
        moment.locale(languageToUse);

        locationInitialized.then(() => {
          translateService.setDefaultLang(languageToUse);
          translateService.use(languageToUse).pipe(finalize(() => resolve(null))).subscribe();
        });

      }),
      multi: true
    }],
  bootstrap: [JhiMainComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CezPortalAppModule {
}
