import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../login/login.service';
import { StateStorageService } from '../shared/auth/state-storage.service';
import { TermsOfService } from './terms.of.service.model';

@Component({
    selector: 'terms-of-service',
    templateUrl: './terms.of.service.component.html',
})
export class TermsOfServiceComponent implements OnInit {
    showNewContainer: boolean;
    termsOfService: TermsOfService;

    constructor(private router: Router,
                private loginService: LoginService,
                private stateStorageService: StateStorageService,
                private http: HttpClient) {

    }

    ngOnInit() {
        this.refreshList();
    }

    refreshList() {
        this.http.get<TermsOfService>('/api/account/terms_of_service').subscribe(r => {
            this.termsOfService = r;
        });
    }

    accept() {
        this.http.post("/api/account/terms_of_service", this.termsOfService.id).subscribe(r => {
            this.stateStorageService.storeTermsOfServiceNeedsToBeAcceptedState(false);
            this.router.navigate(['company/selector']);
        });
    }

    logOut() {
        this.loginService.logout();
        this.router.navigate(['']);
    }
}
