import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';

import { Principal } from '../../shared';
import { UserDeleteDialogComponent } from './user-management-delete-dialog.component';
import { UserMgmtDetailComponent } from './user-management-detail.component';
import { UserDialogComponent } from './user-management-dialog.component';
import { UserMgmtComponent } from './user-management.component';
import {Observable} from "rxjs";


@Injectable()
export class UserResolve  {

    constructor(private principal: Principal) {
    }

    public canActivate(): boolean|Observable<boolean>|Promise<boolean> {
      return this.principal.identity().then(account => this.principal.hasAnyAuthority(['USER_ADMIN_WRITE']));
    }
}


export const userMgmtRoute: Routes = [
    {
        path: 'user-management',
        component: UserMgmtComponent,
        canActivate: [UserResolve],
        data: {
            pageTitle: 'userManagement.home.title'
        }
    },
    {
        path: 'user-management/:login',
        component: UserMgmtDetailComponent,
        canActivate: [UserResolve],
        data: {
            pageTitle: 'userManagement.home.title'
        }
    }
];

export const userDialogRoute: Routes = [
    {
        path: 'user-management-new',
        component: UserDialogComponent,
        canActivate: [UserResolve],
        outlet: 'popup'
    },
    {
        path: 'user-management/:login/edit',
        component: UserDialogComponent,
        canActivate: [UserResolve],
        outlet: 'popup'
    },
    {
        path: 'user-management/:login/delete',
        component: UserDeleteDialogComponent,
        canActivate: [UserResolve],
        outlet: 'popup'
    }
];
