import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { TranslateService } from '@ngx-translate/core';
import { ProductDto } from '../../../price/model/productDto';
import { MarketPriceService } from '../../../shared/services/market-price.service';
import { AdditionalDiagramGroupDTO } from '../model/additional-diagram-group.model';
import { AdditionalDiagramTypeDTO } from '../model/additional-diagram-type.model';
import { DiagramType } from '../model/diagram-type';
import { AdditionalDiagramService } from '../service/additional-diagram-service';
import { forkJoin } from 'rxjs';
import { SpotDto } from '../../../price/model/spot-dto';

export const requiredIfValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors|null => {
    const additionalDiagramTypes = control.get('additionalDiagramTypes');
    const products = control.get('products');
    const spots = control.get('spots');

    return  additionalDiagramTypes.value.length > 0 || products.value.length > 0 || spots.value.length > 0 ? null : {'requiredIf': true};
};

@Component({
    selector: 'jhi-additional-diag-group-editor',
    templateUrl: './additional-diag-group-editor.component.html',
    styleUrls: []
})
export class AdditionalDiagGroupEditorComponent implements OnInit {

    diagramType = DiagramType;
    additionalDiagramTypes: AdditionalDiagramTypeDTO[];
    products: ProductDto[];
    spots: SpotDto[];
    modalTitle: string;

    public form: UntypedFormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data: AdditionalDiagramGroupDTO|null,
                public translate: TranslateService,
                private dialogRef: MatDialogRef<AdditionalDiagGroupEditorComponent>,
                public fb: UntypedFormBuilder,
                private additionalDiagramService: AdditionalDiagramService,
                private marketPriceService: MarketPriceService) {


        if (this.data && this.data.diagramGroupId) {
            this.translate.get('additional-diag-group-edit.titleEdit').subscribe((res: string) => {
                this.modalTitle = res;
            });
        } else {
            this.translate.get('additional-diag-group-edit.titleCreate').subscribe((res: string) => {
                this.modalTitle = res;
            });
        }


        this.form = new UntypedFormGroup({
                'groupNameEN': new UntypedFormControl('', [Validators.required]),
                'groupNameHU': new UntypedFormControl('', [Validators.required]),
                'groupDescriptionEN': new UntypedFormControl(''),
                'groupDescriptionHU': new UntypedFormControl(''),
                'diagramType': new UntypedFormControl(DiagramType.COLUMN, [Validators.required]),
                'additionalDiagramTypes': new UntypedFormControl([]),
                'products': new UntypedFormControl([]),
                'spots': new UntypedFormControl([])
            }, {validators: requiredIfValidator}
        );

    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.dialogRef.close();
    }

    ngOnInit() {
        forkJoin(
            this.marketPriceService.listProducts(),
            this.additionalDiagramService.listDiagramTypes(),
            this.marketPriceService.listSpotTimeSeriesAsDtos()
        ).subscribe(results => {
            const [products, diagramTypes, spots] = results;

            this.products = products;
            this.additionalDiagramTypes = diagramTypes;
            this.spots = spots;
        });

        if (this.data) {
            this.form.patchValue(this.data);
        }
    }

    compareWithFunc(a: AdditionalDiagramTypeDTO, b: AdditionalDiagramTypeDTO): boolean {
        return a.diagramTypeId === b.diagramTypeId;
    }

    compareWithFuncProduct(a: ProductDto, b: ProductDto): boolean {
        return a.timeSeriesHeaderId === b.timeSeriesHeaderId;
    }

    compareWithFuncSpot(a: SpotDto, b: SpotDto): boolean {
        return a.timeSeriesHeaderId === b.timeSeriesHeaderId &&
            a.profileName === b.profileName;
    }

    save() {
        const result: AdditionalDiagramGroupDTO = this.data ? Object.assign({}, this.data, this.form.value) : this.form.value;

        this.additionalDiagramService.postDiagramGroup(result).subscribe(() => {
            this.dialogRef.close(true);
        }, (error) => {
            console.error(error);
        });
    }
}
