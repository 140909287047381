import {Directive, ViewChild} from '@angular/core';
import {TimeSeriesChartComponent} from '../../shared/time-series/time-series-chart/time-series-chart.component';
import {Unit} from '../../shared/time-series/model/unit.enum';
import {QueryInterval} from '../../shared/daily-rates/model/query-interva.enum';
import {DailyRateTimeSeries} from '../../shared/daily-rates/model/DailyRateTimeSeries';
import {ChartData} from '../../shared/time-series/time-series-chart/chart-data.model';
import {CoverageTransactionsAdminService} from '../coverage-transactions.admin.service';
import {LoadingOverlayService} from '../../shared/services/loading-overlay.service';
import {ProductNamePipe} from "../../shared/pipes/product-name.pipe";
import {ProductType} from '../../shared/daily-rates/model/product-type.enum';
import {ColorUtil} from '../../core/util/color.util';
import * as _ from 'lodash';

@Directive()
export abstract class DailyRatesPricesChartBaseComponent {

    @ViewChild('chart', {static: false})
    public chart: TimeSeriesChartComponent;

    private deliveryPeriodId: number;

    public QueryInterval: typeof QueryInterval = QueryInterval;
    public queryInterval: QueryInterval = QueryInterval.ONE_MONTH;

    public readonly D_7: string = '7D';
    public readonly M_1: string = '1M';
    public readonly M_2: string = '2M';
    public readonly Q_1: string = '1Q';
    public readonly Q_2: string = '2Q';
    public readonly Q_3: string = '3Q';
    public readonly Y_1: string = '1Y';
    public readonly Y_2: string = '2Y';
    public readonly YTD: string = 'YTD';
    public readonly MAX: string = 'MAX';

    public activeButton: string = this.M_1;
    public isDailyRatePricesLoading: boolean = false;

    protected constructor(private _coverageTransactionsAdminService: CoverageTransactionsAdminService,
                          private _loadingOverlay: LoadingOverlayService,
                          private _productNamePipe: ProductNamePipe,
                          private _fullScreenLoader: boolean) {
    }

    public init(deliveryPeriodId?: number): void {
        this.deliveryPeriodId = deliveryPeriodId;
        if (!!this.chart) {
            this.chart.initChart(Unit.EUR, 'line');
            this.loadData(QueryInterval.ONE_MONTH, this.M_1);
        }
    }

    public loadData(interval: QueryInterval, buttonId: string): void {
        this.activeButton = buttonId;
        this.filterChart(interval);
    }

    public filterChart(interval?: QueryInterval): void {
        this.queryInterval = interval || this.queryInterval;
        if (this._fullScreenLoader) {
            this._loadingOverlay.turnOn();
        }
        this.isDailyRatePricesLoading = true;
        this._coverageTransactionsAdminService.getCurrentRates(this.queryInterval, this.deliveryPeriodId).subscribe((data: DailyRateTimeSeries[]) => {
            this.chart.clearTimeSeries();
            const colorShadeAmount: Map<ProductType, number> = new Map([
                [ProductType.WEEKLY, 1],
                [ProductType.MONTHLY, 1],
                [ProductType.QUARTERLY, 1],
                [ProductType.YEARLY, 1],
            ]);

            data.forEach((d: DailyRateTimeSeries): void => {

                let isAllEmpty: boolean = d.series.data.every(_.isNil);

                if (!isAllEmpty) {
                    const d1: ChartData = {
                        timeSeries: d.series,
                        name: this._productNamePipe.transform(d.name),
                        unit: Unit.EUR
                    } as ChartData;

                    this.chart.addTimeSeries(d1, 'line', this.getColorShadeByType(d.type, colorShadeAmount));
                    this.incrementColorShade(d.type, colorShadeAmount);
                }

            });
            if (this._fullScreenLoader) {
                this._loadingOverlay.turnOff();
            }
            this.isDailyRatePricesLoading = false;
        }, () => {
            if (this._fullScreenLoader) {
                this._loadingOverlay.turnOff();
            }
            this.isDailyRatePricesLoading = false;
        });
    }

    private getColorShadeByType(type: ProductType, colorShadeAmount: Map<ProductType, number>): string {
        return ColorUtil.getColorFromPaletteByProductType(type, colorShadeAmount.get(type));
    }

    private incrementColorShade(type: ProductType, colorShadeAmount: Map<ProductType, number>): void {
        colorShadeAmount.set(type, colorShadeAmount.get(type) + 1);
    }
}
