<div *ngFor="let param of parameters" class="w-100 border-bottom py-2 px-3">

<!--TEXT-->
    <div *ngIf="param.type === ParameterViewerType.TEXT_INPUT"
         class="row justify-content-between">
        <label for="{{getParamName(param)}}"
               class="col-9 m-0">
            {{ getParamName(param) | translate }}

            <mat-icon *ngIf="param.info"
                      class="info-icon col-6 info-icon align-left display-6 hand"
                      style="display: inline"
                      (click)="getInfo(param)">info
            </mat-icon>
        </label>

        <div class="col-3 text-right"
             id="{{ getParamName(param)}}">{{ getParamValue(param) | translate }}
        </div>
    </div>

<!--NUMBER-->
    <div *ngIf="param.type === ParameterViewerType.NUMBER_INPUT || param.type === ParameterViewerType.NUMBER_INPUT_NO_DECIMALS"
        class="row justify-content-between">
        <label for="{{getParamName(param)}}"
               class="col-9 m-0">
            {{ getParamName(param) | translate }}

            <mat-icon *ngIf="param.info"
                      class="col-6 info-icon align-left display-6 hand"
                      style="display: inline"
                      (click)="getInfo(param)">info
            </mat-icon>
        </label>

        <div class="col-3 text-right"
             id="{{ getParamName(param) }}">{{ param.type === ParameterViewerType.NUMBER_INPUT ? (param.value | number:'1.2-2') : (param.value | number:'1.0-0') }}
        </div>
    </div>

<!--CHECKBOX-->
    <div *ngIf="param.type === ParameterViewerType.CHECKBOX && param.disabled"
         class="row justify-content-between">
        <label for="{{getParamName(param)}}"
               class="col-9 m-0">
            {{ getParamName(param) | translate }}

            <mat-icon *ngIf="param.info"
                      class="col-6 info-icon align-left display-6 hand"
                      style="display: inline"
                      (click)="getInfo(param)">info
            </mat-icon>
        </label>

        <p-checkbox class="col-3 justify-content-end"
                    id="{{getParamName(param)}}"
                    [(ngModel)]="!!param.value"
                    [disabled]="!!param.disabled"
                    [binary]="true"></p-checkbox>
    </div>

<!--SELECT_OPTION-->
    <div *ngIf="param.type === ParameterViewerType.SELECT_OPTION"
         class="row justify-content-between">
        <label for="{{getParamName(param)}}"
               class="col-9 m-0">
            {{ getParamName(param) | translate }}

            <mat-icon *ngIf="param.info"
                      class="col-6 info-icon align-left display-6 hand"
                      style="display: inline"
                      (click)="getInfo(param)">info
            </mat-icon>
        </label>

        <select class="col-3"
                id="{{getParamName(param)}}"
                [(ngModel)]="param.value"
                [disabled]="!!param.disabled">
            <option [value]="param.value" selected>{{ getParamValue(param) | translate }}</option>
        </select>
    </div>
</div>

