<div class="modal-header">
    <h4 class="modal-title">{{ 'dailyRates.dialog.title' | translate }}</h4>
    <button (click)="activeModal.close()" aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="container-fluid">
        <div class="d-flex row">
            <p-table #dt [value]="dailyRates"
                     class="table table-striped">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center head-th" rowspan="2">
                            <span jhiTranslate="dailyRates.dialog.name"></span>
                        </th>
                        <th class="text-center head-th" rowspan="2">
                            <span jhiTranslate="dailyRates.dialog.currentPrice"></span>
                        </th>
                        <th class="text-center head-th" colspan="3">
                            <span jhiTranslate="dailyRates.dialog.priceChanging"></span>
                        </th>
                        <th class="text-center head-th daily-price-dialog-table-chart-header" rowspan="2">
                            <span jhiTranslate="dailyRates.dialog.priceVarying"></span>
                        </th>
                        <th class="text-left daily-price-dialog-table-button-header" rowspan="2"></th>
                    </tr>
                    <tr>
                        <th class="text-center head-th">
                            <span jhiTranslate="dailyRates.dialog.previousPriceDifferenceD-1"></span>
                        </th>
                        <th class="text-center head-th">
                            <span jhiTranslate="dailyRates.dialog.previousPriceDifferenceD-7"></span>
                        </th>
                        <th class="text-center head-th">
                            <span jhiTranslate="dailyRates.dialog.previousPriceDifferenceD-28"></span>
                        </th>
                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowData>
                    <tr [pSelectableRow]="rowData">
                        <td>
                            {{ rowData.name | productName }}
                        </td>
                        <td class="text-center">
                            {{ formatPrice(rowData.currentPrice) }}
                        </td>
                        <td class="text-center {{ getBackgroundClass(rowData.previousPriceDiff) }}">
                            {{ formatPrice(rowData.previousPriceDiff) }}
                        </td>
                        <td class="text-center {{ getBackgroundClass(rowData.previousPriceDMinus7Diff) }}">
                            {{ formatPrice(rowData.previousPriceDMinus7Diff) }}
                        </td>
                        <td class="text-center {{ getBackgroundClass(rowData.previousPriceDMinus28Diff) }}">
                            {{ formatPrice(rowData.previousPriceDMinus28Diff) }}
                        </td>
                        <td class="text-left">
                            <jhi-price-trend-chart
                                [priceData]="rowData.previous7BusinessDayPrices"></jhi-price-trend-chart>
                        </td>
                        <td>
                            <div>
                                <button *ngIf="isPriceMonitoringButtonVisible()"
                                        writeProtected="HEDGE_TRANSACTIONS_WRITE"
                                        (clickIfHasPermission)="goToPriceMonitoring(rowData)"
                                        matTooltip="{{'coverageTransactions.tabs.overview.table.tooltip.addPriceMonitoring' | translate}}"
                                        mat-mini-fab
                                        color="primary"
                                        class="btn-sm">
                                    <mat-icon>notifications_active</mat-icon>
                                </button>
                                <button *ngIf="isBuyProductButtonVisible(rowData)"
                                        writeProtected="HEDGE_TRANSACTIONS_WRITE"
                                        (clickIfHasPermission)="buyProduct(rowData)"
                                        mat-mini-fab
                                        color="primary"
                                        class="btn-sm ml-1"
                                        matTooltip="{{'dailyRates.dialog.button' | translate}}">
                                    <mat-icon>shopping_cart</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
