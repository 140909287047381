<header class="docs">

    <div class="row pt-3">
        <div [ngClass]="'col-lg-5 col-sm-12'">
            <mat-card class="bg-white">
                <mat-card-content class="min-card-height">
                    <div class="container-fluid">
                        <form #registrationRequestForm="ngForm"
                              (ngSubmit)="onSubmit(dailyRates, registrationRequestForm)">
                            <div class="d-flex row">
                                <p-table #dt [value]="dailyRates"

                                         class="table tasks-table table-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th class="text-center head-th">
                                                <span jhiTranslate="dailyRates.tableField.name"></span>
                                            </th>
                                            <th class="text-center head-th ">
                                                <span jhiTranslate="dailyRates.tableField.currentPrice"></span>
                                            </th>
                                            <th class="text-center head-th">
                                                <span jhiTranslate="dailyRates.tableField.withoutPrice"></span>
                                            </th>
                                            <th class="text-center head-th ">
                                                <span jhiTranslate="dailyRates.tableField.previousPrice"></span>
                                            </th>
                                            <th class="text-center head-th ">
                                                <span jhiTranslate="dailyRates.tableField.priceDifference"></span>
                                            </th>
                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-rowData>
                                        <tr [pSelectableRow]="rowData">
                                            <td>
                                                {{rowData.name | productName}}
                                            </td>

                                            <td class="text-center">
                                                <input type="text"
                                                       name="currentPrice-{{rowData.name}}"
                                                       mask="separator.2"
                                                       autocomplete="off"
                                                       class="text-right form-control"
                                                       maxlength="7"
                                                       [(ngModel)]="rowData.currentPrice"
                                                       thousandSeparator="{{'number.thousandSeparator' | translate}}"
                                                       (keydown)="onKeyDown($event)"
                                                       [decimalMarker]="'number.decimalMarker' | translate"
                                                       [dropSpecialCharacters]="true"
                                                       [disabled]="getRowWithoutPriceState(rowData)"
                                                       [validation]="true"
                                                       (focusout)="utilService.onFocusoutBlur($event)">
                                            </td>
                                            <td class="text-center">
                                                <p-checkbox name="withoutPrice-{{rowData.name}}" [(ngModel)]="rowData.withoutPrice" [binary]="true"></p-checkbox>
                                            </td>
                                            <td class="text-center">
                                                {{rowData.previousPrice === null ? '-' : rowData.previousPrice | number: '1.2-2'}}
                                            </td>
                                            <td class="text-center ">
                                                {{(rowData.previousPrice === null || rowData.currentPrice == null) ? '-' : rowData.currentPrice - rowData.previousPrice | number: '1.2-2'}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                            <div class="row m-0 justify-content-end mb-3">
                                <div class="col-6 p-0">
                                    <deadline-selector #deadlineSelector
                                                       [valueChange]="setDeadline.bind(this)"></deadline-selector>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 align-self-center">
                                    <p-checkbox name="firmOffer"
                                                label="{{'dailyRates.firmOffer' | translate}}"
                                                [(ngModel)]="isFirmOffer"
                                                [disabled]="isActiveDailyPrices"
                                                [binary]="true"></p-checkbox>
                                </div>
                                <div class="col-lg-8 d-flex flex-row justify-content-end">
                                    <button
                                        [disabled]="isPublishDisabled(dailyRates, registrationRequestForm)"
                                        class="btn btn-primary mr-2">{{'dailyRates.button.publish' | translate}}</button>
                                    <button
                                        [disabled]="!isActiveDailyPrices"
                                        (click)="withdrawalDailyPrices()"
                                        type="button"
                                        class="btn btn-primary">{{'dailyRates.button.withdrawal' | translate}}</button>
                                </div>
                            </div>
                        </form>

                    </div>
                </mat-card-content>
            </mat-card>

            <cez-file-upload-queue #hpfcUploadQueue [isMultipleFiles]="false">
                <cez-file-upload [file]="file" [id]="i"
                                 [httpUrl]="'api/daily-rates/import/hpfc'"
                                 (onUpload)="onUpload($event)"
                                 [autoUpload]="true"
                                 *ngFor="let file of hpfcUploadQueue.files; let i = index"></cez-file-upload>
            </cez-file-upload-queue>
            <button mat-raised-button
                    [matTooltip]="'dailyRates.toolTipForHPFC' | translate"
                    color="primary"
                    (click)="fileInput.click()"
                    type="button"
                    class="btn-block btn-import">
                <i class="material-icons">find_in_page</i>
                {{'dailyRates.button.import' | translate}}
            </button>
            <input #fileInput [hidden]="true" multiple type="file" [fileUploadInputFor]="hpfcUploadQueue"
                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
        </div>
        <div [ngClass]="'col-lg-7 col-sm-12'">
            <mat-card class="bg-white">
                <mat-card-content class="min-card-height">
                    <div class="btn-toolbar d-flex justify-content-end">
                        <div class="btn-group mb-2">
                            <button color="primary"
                                    matTooltip="7D"
                                    type="button"
                                    (click)="loadData(QueryInterval.ONE_WEEK, D_7)"
                                    [class.cez-btn-group__button--active]="activeButton === D_7"
                                    class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">7D</button>
                            <button color="primary"
                                    matTooltip="1M"
                                    type="button"
                                    (click)="loadData(QueryInterval.ONE_MONTH, M_1)"
                                    [class.cez-btn-group__button--active]="activeButton === M_1"
                                    class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">1M</button>
                            <button color="primary"
                                    matTooltip="2M"
                                    type="button"
                                    (click)="loadData(QueryInterval.TWO_MONTH, M_2)"
                                    [class.cez-btn-group__button--active]="activeButton === M_2"
                                    class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">2M</button>
                            <button color="primary"
                                    matTooltip="1Q"
                                    type="button"
                                    (click)="loadData(QueryInterval.ONE_QUARTER, Q_1)"
                                    [class.cez-btn-group__button--active]="activeButton === Q_1"
                                    class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">1Q</button>
                            <button color="primary"
                                    matTooltip="2Q"
                                    type="button"
                                    (click)="loadData(QueryInterval.TWO_QUARTER, Q_2)"
                                    [class.cez-btn-group__button--active]="activeButton === Q_2"
                                    class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">2Q</button>
                            <button color="primary"
                                    matTooltip="3Q"
                                    type="button"
                                    (click)="loadData(QueryInterval.THREE_QUARTER, Q_3)"
                                    [class.cez-btn-group__button--active]="activeButton === Q_3"
                                    class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">3Q</button>
                            <button color="primary"
                                    matTooltip="1Y"
                                    type="button"
                                    (click)="loadData(QueryInterval.ONE_YEAR, Y_1)"
                                    [class.cez-btn-group__button--active]="activeButton === Y_1"
                                    class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">1Y</button>
                            <button color="primary"
                                    matTooltip="2Y"
                                    type="button"
                                    (click)="loadData(QueryInterval.TWO_YEAR, Y_2)"
                                    [class.cez-btn-group__button--active]="activeButton === Y_2"
                                    class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">2Y</button>
                            <button color="primary"
                                    matTooltip="YTD"
                                    type="button"
                                    (click)="loadData(QueryInterval.YTD, YTD)"
                                    [class.cez-btn-group__button--active]="activeButton === YTD"
                                    class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">YTD</button>
                            <button color="primary"
                                    matTooltip="MAX"
                                    type="button"
                                    (click)="loadData(QueryInterval.MAX, MAX)"
                                    [class.cez-btn-group__button--active]="activeButton === MAX"
                                    class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">MAX</button>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <jhi-time-series-chart #chart
                                               [timeSeriesChartLineType]="TimeSeriesChartLineType.DOTTED"></jhi-time-series-chart>
                    </div>
                </mat-card-content>
            </mat-card>

            <mat-card class="bg-white">
                <form [formGroup]="dailyRateExportForm">
                    <div class="row">
                        <div class="col-12 col-sm-8">
                            <mat-accordion class="mb-2 col-7">
                                <mat-expansion-panel #exportPanel>
                                    <mat-expansion-panel-header>
                                        <mat-panel-title class="cez-panel-title">
                                            {{'timeSeriesImport.header.exportPanel' | translate}}
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <div class="row">
                                        <mat-form-field class="col-4">
                                            <input type="text" matInput [formControl]="dailyRateExportControl" [matAutocomplete]="auto">
                                            <mat-label>{{'dailyRates.tableField.name' | translate}}</mat-label>
                                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayProductName.bind(this)">
                                                <mat-option *ngFor="let dailyRate of filteredDailyRateExport"
                                                            [value]="dailyRate.name">
                                                    {{dailyRate.name | productName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>

                                        <div class="col-8">
                                            <cez-time-interval
                                                formControlName="exportInterval"
                                                [max]="dailyRateExportMaxDate"
                                                [hideResolution]="true"></cez-time-interval>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <button type="button" mat-raised-button
                                                class="btn btn-primary btn-wide-150 ml-3 col-12 col-sm-5 text-white"
                                                [ngClass]="exportDisabled() ? 'no-hover' : 'cursor--pointer'"
                                                matTooltip="{{'timeSeriesImport.exportButton.toolTip' | translate}}"
                                                [disabled]="exportDisabled()"
                                                (click)="exportTimeSeries()">
                                            <mat-icon class="text-white">receipt</mat-icon> {{'timeSeriesImport.exportButton.name' | translate}}
                                        </button>
                                        <button type="button" mat-raised-button
                                                class="btn btn-primary btn-wide-150 ml-3 col-12 col-sm-5 text-white"
                                                [ngClass]="exportDisabled() ? 'no-hover' : 'cursor--pointer'"
                                                matTooltip="{{'timeSeriesImport.templateButton.toolTip' | translate}}"
                                                [disabled]="exportDisabled()"
                                                (click)="exportTemplate()">
                                            <mat-icon class="text-white">receipt</mat-icon> {{'timeSeriesImport.templateButton.name' | translate}}
                                        </button>
                                    </div>
                                </mat-expansion-panel>
                            </mat-accordion>
                        </div>
                        <div class="col-12 col-sm-4 mt-sm-2 justify-content-center">
                            <cez-file-upload-queue #dailyPriceUploadQueue [isMultipleFiles]="false">
                                <cez-file-upload [file]="file" [id]="i"
                                                 [httpUrl]="'/api/timeseries/import'"
                                                 (onUpload)="onDailyRateUpload($event)"
                                                 [autoUpload]="true"
                                                 *ngFor="let file of dailyPriceUploadQueue.files; let i = index"></cez-file-upload>
                            </cez-file-upload-queue>
                            <button mat-raised-button
                                    [matTooltip]="'dailyRates.toolTipForHPFC' | translate"
                                    color="primary"
                                    (click)="timeSeriesFileInput.click()"
                                    type="button"
                                    class="btn-block btn-import w-100 mt-2">
                                <i class="material-icons">find_in_page</i>
                                {{'dailyRates.button.dailyRatesImport' | translate}}
                            </button>

                            <input #timeSeriesFileInput [hidden]="true" multiple type="file"
                                   [fileUploadInputFor]="dailyPriceUploadQueue"
                                   accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
</header>
