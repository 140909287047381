import { animate, state, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NewsModalComponent } from './news.modal.component';
import {News} from '../shared/dto/news';
import {BaseDto} from '../shared';
import {NewsService} from '../shared/services/news.service';
import {PagerModel} from '../shared/dto/page.model';

@Component({
    selector: 'news-module',
    templateUrl: './news.component.html',
    animations: [
        trigger('shrinkOut', [
            state('in', style({height: '*'})),
            transition('void => *', [
                style({height: '0'}),
                animate(500, style({height: '*'}))
            ])
        ])
    ]
})
export class NewsComponent {

    news: Array<News> = [];
    newsCategory: number;
    actualPage: number = 0;
    totalPages: number;
    public filterText: string = '';
    tagCloud: BaseDto;
    showLoadMore: boolean = true;
    isModalOpened: boolean;
    newsCategoryString: string;
    backSpaceCount: number;
    cezTitle: string = ' - CEZHU|b';

    constructor(
        private http: HttpClient,
        private modalService: NgbModal,
        public newsService: NewsService,
        private route: ActivatedRoute,
        private router: Router,
        private titleService: Title,
        public translateService: TranslateService
    ) {
        this.route.params.subscribe(params => {
            this.actualPage = 0;
            this.news = [];

            this.newsCategoryString = params['category'];
            this.loadTagsAndTitleNews(params);
            this.showLoadMore = true;
        });
    }

    refreshNews() {
        let newsSearchDTO = {
            withSubCategories: [{id: this.newsCategory}]
        };
        this.http.post<PagerModel<News>>(`/api/news/search?page=${this.actualPage}&size=3`, newsSearchDTO)
            .subscribe(r => {
                this.news = this.news.concat(r.content);
                this.totalPages = r.totalPages;
                this.titleService.setTitle(this.translateService.instant('main.link.news.' + this.newsCategoryString) + this.cezTitle);
            });
    }

    getNewsByTag(tagId: number) {
        let newsSearchDTO = {
            withSubCategories: [{id: this.newsCategory}],
            tags: [{id: tagId}]
        };

        this.http.post<PagerModel<News>>(`/api/news/search`, newsSearchDTO)
            .subscribe(r => {
                this.news = r.content;
                this.showLoadMore = false;
            });
    }


    openNews(article: News) {
        if (this.isModalOpened)
            return;

        this.isModalOpened = true;
        const modalRef = this.modalService.open(NewsModalComponent, {
            beforeDismiss: () => {
                this.isModalOpened = false;
                return true;
            }
        });
        modalRef.componentInstance.article = article;
    }

    noMoreNews(): boolean {
        return this.totalPages === 0 || this.actualPage === this.totalPages - 1;
    }

    loadMore() {
        this.actualPage += 1;
        this.refreshNews();
    }

    filter(event) {
        if (this.filterText === '' && (event.key === 'Backspace' || event.key === 'Delete')) {
            if (this.backSpaceCount++ > 0)
                return;
        } else {
            this.backSpaceCount = 0;
        }

        if (this.filterText) {
            let newsSearchDTO = {
                withSubCategories: [{id: this.newsCategory}],
                fullTextSearch: this.filterText ? this.filterText : null
            };

            this.http.post<PagerModel<News>>(`/api/news/search?page=0&size=1000`, newsSearchDTO)
                .subscribe(r => {
                    this.news = r.content;
                });
        } else {
            this.actualPage = 0;
            this.news = [];
            this.refreshNews();
        }
    }

    showEngFlag(news: News): boolean {
        return news.contentLanguage === 'en' && news.language === 'hu';
    }

    loadTagsAndTitleNews(params: any) {
        if (this.newsCategoryString)
            this.loadData(params);
        else
            this.redirectToNewsByCategory(params);
    }

    redirectToNewsByCategory(params: any) {
        let newsUrlMappings = {
            '-10': 'cez',
            '-11': 'educational',
            '-12': 'market'
        };

        let newsId = params['id'];
        if (newsId) {
            this.http.get('/api/news/' + newsId)
                .subscribe(r => {
                    let currentNews = <News>r;
                    this.newsCategory = currentNews.categories[0].id;
                    let categoryString = newsUrlMappings[this.newsCategory];
                    this.router.navigateByUrl(`news/category/${categoryString}/${newsId}`);
                });
        }
    }

    loadData(params: any) {
        let categoryDictonary = {
            'cez': -10,
            'educational': -11,
            'market': -12
        };

        this.newsCategory = categoryDictonary[this.newsCategoryString];

        this.http.get<BaseDto>('/api/news/tag_cloud/category/' + this.newsCategory)
            .subscribe(r => this.tagCloud = r);

        let newsId = params['id'];
        if (newsId) {
            this.http.get('/api/news/' + newsId)
                .subscribe(r => {
                    let currentNews = <News>r;
                    this.openNews(currentNews);

                });
        }

        this.refreshNews();
    }
}
