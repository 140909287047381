<div class="modal-header">
  <h4 class="modal-title" jhiTranslate="agreement.agreementData.contactTitle">Contact</h4>
  <button type="button" class="close" aria-label="Close" (click)="this.activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form name="contactForm" role="form" #contactForm="ngForm" (ngSubmit)="save()">
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-6">
        <label for="name" jhiTranslate="agreement.agreementData.contacts.name">Name</label>
        <input id="name" name="name" [(ngModel)]="contact.name" class="form-control">
      </div>
      <div class="form-group col-md-6">
        <label for="responsibility"
               jhiTranslate="agreement.agreementData.contacts.responsibility">Responsibility</label>
        <input id="responsibility" name="responsibility" [(ngModel)]="contact.responsibility"
               class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="phone" jhiTranslate="agreement.agreementData.contacts.phone">Phone number</label>
        <input id="phone" name="phone" [(ngModel)]="contact.phone" class="form-control">
      </div>
      <div class="form-group col-md-6">
        <label for="mobile" jhiTranslate="agreement.agreementData.contacts.mobile">Mobile number</label>
        <input id="mobile" name="mobile" [(ngModel)]="contact.mobile" class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="email" jhiTranslate="agreement.agreementData.contacts.e-mail">E-mail</label>
        <input id="email" name="email" [(ngModel)]="contact.email" class="form-control" type="email" email>
      </div>
      <div class="form-group col-md-6">
        <label for="fixingPerson">{{'agreement.agreementData.contacts.fixingPerson' | translate}}</label>
        <p-checkbox name="fixingPerson" id="fixingPerson"
                    [(ngModel)]="contact.fixingPerson" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="billingContact">{{'agreement.agreementData.contacts.billingContact' | translate}}</label>
        <p-checkbox name="billingContact" id="billingContact"
                    [(ngModel)]="contact.billingContact" binary="true"></p-checkbox>
      </div>
      <div class="form-group col-md-6">
        <label for="consumptionRelated">{{'agreement.agreementData.contacts.consumptionRelated' | translate}}</label>
        <p-checkbox name="consumptionRelated" id="consumptionRelated"
                    [(ngModel)]="contact.consumptionRelated" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="contactOwner">{{'agreement.agreementData.contacts.contactOwner' | translate}}</label>
        <input id="contactOwner" name="contactOwner" [(ngModel)]="contact.contactOwner"
               class="form-control">
      </div>
      <div class="form-group col-md-6">
        <label
          for="contractRelevantContact">{{'agreement.agreementData.contacts.contractRelevantContact' | translate}}</label>
        <p-checkbox name="contractRelevantContact" id="contractRelevantContact"
                    [(ngModel)]="contact.contractRelevantContact" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="department">{{'agreement.agreementData.contacts.department' | translate}}</label>
        <input id="department" name="department" [(ngModel)]="contact.department"
               class="form-control">
      </div>
      <div class="form-group col-md-6">
        <label for="ekr">{{'agreement.agreementData.contacts.ekr' | translate}}</label>
        <p-checkbox name="ekr" id="ekr"
                    [(ngModel)]="contact.ekr" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="optedOutOfEmail">{{'agreement.agreementData.contacts.optedOutOfEmail' | translate}}</label>
        <p-checkbox name="optedOutOfEmail" id="optedOutOfEmail"
                    [(ngModel)]="contact.optedOutOfEmail" binary="true"></p-checkbox>
      </div>
      <div class="form-group col-md-6">
        <label for="getAllInvoices">{{'agreement.agreementData.contacts.getAllInvoices' | translate}}</label>
        <p-checkbox name="getAllInvoices" id="getAllInvoices"
                    [(ngModel)]="contact.getAllInvoices" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="indicativePrices">{{'agreement.agreementData.contacts.indicativePrices' | translate}}</label>
        <p-checkbox name="indicativePrices" id="indicativePrices"
                    [(ngModel)]="contact.indicativePrices" binary="true"></p-checkbox>
      </div>
      <div class="form-group col-md-6">
        <label for="paymentContact">{{'agreement.agreementData.contacts.paymentContact' | translate}}</label>
        <p-checkbox name="paymentContact" id="paymentContact"
                    [(ngModel)]="contact.paymentContact" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="meetTheAnalyst">{{'agreement.agreementData.contacts.meetTheAnalyst' | translate}}</label>
        <p-checkbox name="meetTheAnalyst" id="meetTheAnalyst"
                    [(ngModel)]="contact.meetTheAnalyst" binary="true"></p-checkbox>
      </div>
      <div class="form-group col-md-6">
        <label for="reportContact">{{'agreement.agreementData.contacts.reportContact' | translate}}</label>
        <p-checkbox name="reportContact" id="reportContact"
                    [(ngModel)]="contact.reportContact" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="otcPrices">{{'agreement.agreementData.contacts.otcPrices' | translate}}</label>
        <p-checkbox name="otcPrices" id="otcPrices"
                    [(ngModel)]="contact.otcPrices" binary="true"></p-checkbox>
      </div>
      <div class="form-group col-md-6">
        <label
          for="paymentReminderNotToBeSent">{{'agreement.agreementData.contacts.paymentReminderNotToBeSent' | translate}}</label>
        <p-checkbox name="paymentReminderNotToBeSent" id="paymentReminderNotToBeSent"
                    [(ngModel)]="contact.paymentReminderNotToBeSent" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="language">{{'agreement.agreementData.contacts.language' | translate}}</label>
        <mat-select [(ngModel)]="contact.language" name="language" id="language" class="form-control">
          <mat-option [value]="null"></mat-option>
          <mat-option *ngFor="let language of languages | keys" [value]="language.value">
            {{('customFileUpload.languages.' + language.value) | translate}}
          </mat-option>
        </mat-select>
      </div>
      <div class="form-group col-md-6">
        <label
          for="representativeOfTheCompany">{{'agreement.agreementData.contacts.representativeOfTheCompany' | translate}}</label>
        <input id="representativeOfTheCompany" name="representativeOfTheCompany"
               [(ngModel)]="contact.representativeOfTheCompany"
               class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label for="status">{{'agreement.agreementData.contacts.status' | translate}}</label>
        <input id="status" name="status" [(ngModel)]="contact.status"
               class="form-control">
      </div>
      <div class="form-group col-md-6">
        <label for="technicalMatters">{{'agreement.agreementData.contacts.technicalMatters' | translate}}</label>
        <p-checkbox name="technicalMatters" id="technicalMatters"
                    [(ngModel)]="contact.technicalMatters" binary="true"></p-checkbox>
      </div>
    </div>

    <!--        scheduler:-->
    <div class="row">
      <div *ngIf="!isCez" class="form-group col-md-6">
        <label for="scheduler">{{'agreement.agreementData.contacts.scheduler' | translate}}</label>
        <p-checkbox name="scheduler" id="scheduler"
                    [(ngModel)]="contact.scheduler" binary="true"></p-checkbox>
      </div>
    </div>
    <!-- <div class="form-group">
        <label for="fax">Fax</label>
        <input id="fax" name="fax" [(ngModel)]="contact.fax" class="form-control">
    </div>
    <div class="form-group">
        <label for="comment" jhiTranslate="agreement.agreementData.contacts.comment">Comment</label>
        <input id="comment" name="comment" [(ngModel)]="contact.comment" class="form-control">
    </div> -->
    <div class="form-group" *ngIf="isCez && contact.id > -1">
      <!--  <label for="comment">File</label>
        <file-uploader [fileType]="'image/*'" (onSelection)="fileuploaderFileChange($event)"
                       [required]="false"></file-uploader>-->

      <div class="col-auto">
        <div style="display: flex; align-items: center;">
          <input placeholder="{{'select.default' | translate}}" readonly class="form-control"
                 style="width: 200px; float: left; margin-right: 0px;" matInput *ngIf="!fileInput?.httpUrl"
                 value="{{fileInput?.files[0]?.name}}"/>
          <button mat-mini-fab color="primary" (click)="addFile(fileInput)" type="button">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <input #fileInput [hidden]="true" type="file" accept="image/*"/>
      </div>

      <cez-file-upload class="col"
                       *ngIf="fileInput.value"
                       [file]="fileInput.files[0]"
                       [autoUpload]="true"
                       (onUpload)="onUpload($event)"
                       (removeEvent)="fileInput.value = ''"></cez-file-upload>

    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" [disabled]="contactForm.form.invalid || isSaving"
            jhiTranslate="entity.action.save">Save
    </button>
  </div>
</form>
