export interface ParameterViewerModel {
    name: string;
    value: any;
    group: string;
    type: ParameterViewerType;

    nameI18nPrefix: string;
    valueI18nPrefix: string;

    info: string;

    disabled: boolean;
}

export interface DeliveryPeriodParametersModel {
    fees: ParameterViewerModel[];
    productAvailability: ParameterViewerModel[];
    hedgingConditions: ParameterViewerModel[];
}

export type ParameterViewerType = 'TEXT_INPUT' | 'CHECKBOX' | 'NUMBER_INPUT' | 'NUMBER_INPUT_NO_DECIMALS' | 'SELECT_OPTION';

export const ParameterViewerType = {
    TEXT_INPUT: 'TEXT_INPUT' as ParameterViewerType,
    NUMBER_INPUT: 'NUMBER_INPUT' as ParameterViewerType,
    NUMBER_INPUT_NO_DECIMALS: 'NUMBER_INPUT_NO_DECIMALS' as ParameterViewerType,
    CHECKBOX: 'CHECKBOX' as ParameterViewerType,
    SELECT_OPTION: 'SELECT_OPTION' as ParameterViewerType
};
