import {Route} from '@angular/router';
import {UserRouteAccessService} from '../../shared';
import {PartnerMandatoryGuard} from '../../shared/partner-mandatory.guard';
import {CartComponent} from "./cart.component";

export const CART_ROUTE: Route[] = [
    {
        path: '',
        component: CartComponent,
        data: {
            permission: 'HEDGE_TRANSACTIONS_SCREEN',
            pageTitle: 'coverageTransactions.tabs.cart.title'
        },
        canActivate: [UserRouteAccessService, PartnerMandatoryGuard]
    }
];
