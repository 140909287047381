import {Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DefaultLangChangeEvent, TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {finalize, takeUntil} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {LoginService} from '../../login/login.service';
import {UserService} from '../../shared';
import {Principal} from '../../shared/auth/principal.service';
import {StateStorageService} from '../../shared/auth/state-storage.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {JhiLanguageHelper} from '../../shared/language/language.helper';
import {CompanySelectorService} from '../../shared/services/company-selector.service';
import {MenuService} from '../../shared/services/menu.service';


import {ProfileService} from '../profiles/profile.service'; // FIXME barrel doesnt work here
import {MenuModel} from './menu-model';
import {DailyPricesDialogComponent} from '../../shared/daily-prices/daily-prices-dialog.component';
import {DailyPricesService} from '../../shared/services/daily-prices.service';
import {CoverageTransactionsService} from "../../coverage-transactions/coverage-transactions.service";
import * as _ from "lodash";
import {SystemInfoDto} from '../../shared/dto/system-info.model';
import {SystemInfoService} from '../../shared/services/system-info.service';
import {DailyPriceStateChange} from '../../shared/daily-rates/model/DailyPriceStateChange';
import {DataUpdateTrackingService} from "../../shared/services/data-update-tracking.service";

@Component({
  selector: 'jhi-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: [
    'navbar.scss'
  ]
})
export class NavbarComponent implements OnInit, OnDestroy {

  inProduction: boolean;
  isNavbarCollapsed: boolean;
  languages: any[];
  swaggerEnabled: boolean;
  modalRef: NgbModalRef;
  companyLabel: string;
  menuItems: Array<MenuModel> = [];
  adminAccess: boolean;
  widgetAdmin: boolean;
  account: any;
  subscriptions: Subscription[] = [];
  selectedCompany: string;
  imageUrl: string;
  currLang: string;
  isActiveDailyPrices: boolean = false;
  private destroy: Subject<any> = new Subject();
  isHedgeUrl: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isCartUrl: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isCartItemInMultipleDeliveryPeriods: boolean = false;

  constructor(
    private loginService: LoginService,
    private languageHelper: JhiLanguageHelper,
    private principal: Principal,
    private profileService: ProfileService,
    private router: Router,
    private companySelectorService: CompanySelectorService,
    private stateStorageService: StateStorageService,
    private menuService: MenuService,
    private modalService: NgbModal,
    private userService: UserService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private dailyPricesService: DailyPricesService,
    private coverageTransactionsService: CoverageTransactionsService,
    private systemInfoService: SystemInfoService,
    private dataUpdateTrackingService: DataUpdateTrackingService
  ) {
    if (!environment.production) {
      console.info('production mode:', environment.production);
      console.info('version:', environment.version);
    }
    this.isNavbarCollapsed = true;
    this.companyLabel = 'CEZ MAGYARORSZÁG';
  }

  ngOnInit() {
    this.router.events.subscribe(() => this.openActivatedSubmenu());
    this.languageHelper.getAll().then((languages) => {
      this.languages = languages;
    });
    this.profileService.getProfileInfo().subscribe(profileInfo => {
      this.inProduction = profileInfo.inProduction;
      this.swaggerEnabled = profileInfo.swaggerEnabled;
    });

    this.getAccount();

    let selectedCompany = this.stateStorageService.getSelectedCompany();

    if (selectedCompany) {
      this.selectedCompany = selectedCompany.accountName;
    }
    this.loginService.loginSubscribe(() => {
      if (!this.account)
        this.getAccount();
      else
        this.refreshMenu();
    });

    this.companySelectorService.getCompanyChangeEvent().subscribe((event) => {
      this.refreshMenu(event.permissions);
      this.selectedCompany = event.companyName;
      this.coverageTransactionsService.initCartVisibility();
    });

    this.loginService.getLogoutEvent().subscribe(() => {
      this.clearMenu();
    });


    this.currLang = this.translateService.currentLang;

    this.translateService.onLangChange.subscribe((params: DefaultLangChangeEvent) => this.currLang = params.lang);

    this.setDailyPricesState();

    this.isHedgeUrl = new BehaviorSubject(this.isCurrentRouterUrlIncludePath('/hedge-transaction'));
    this.isHedgeUrl
      .pipe(takeUntil(this.destroy))
      .subscribe(value => {
        this.refreshMenu();
      });

    this.initUrlChangeSubscription();

    this.coverageTransactionsService.isCartItemInMultipleDeliveryPeriods.subscribe(isCartItemInMultipleDeliveryPeriods => {
      this.isCartItemInMultipleDeliveryPeriods = isCartItemInMultipleDeliveryPeriods;
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.map(r => r.unsubscribe());
    this.destroy.next(true);
    this.destroy.complete();
  }

  initUrlChangeSubscription(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const isHedge: boolean = this.isCurrentRouterUrlIncludePath('/hedge-transaction');
        const isCart: boolean = this.isCurrentRouterUrlIncludePath('/cart');
        if (this.isHedgeUrl.getValue() != isHedge) {
          this.isHedgeUrl.next(isHedge);
        }
        if (this.isCartUrl.getValue() != isCart) {
          this.isCartUrl.next(isCart);
        }
      }
    });
  }

  isCurrentRouterUrlIncludePath(path: string): boolean {
    return _.includes(this.router.url, path);
  }

  getAccount() {
    this.principal.identity().then(account => {
      this.account = account;
      if (this.account) {
        this.imageUrl = this.principal.getImageUrl();
      }
      this.refreshMenu();
    });
  }

  collapseNavbar() {
    this.deactivateSubMenu();
    this.isNavbarCollapsed = true;
  }

  isAuthenticated() {
    return this.principal.isAuthenticated();
  }

  logout() {
    this.loginService.logout();
    this.router.navigate(['/login']);
    this.clearMenu();
  }

  showInformation(): void {
    this.systemInfoService.getSystemInfo().subscribe((info: SystemInfoDto): void => {
      this.dialogService.details({
        title: "global.menu.information.title",
        details: [
          {key: "global.menu.information.name", value: info.systemName},
          {key: "global.menu.information.backend-version", value: info.applicationVersion},
          {key: "global.menu.information.frontend-version", value: environment.version}
        ]
      });
    });
  }

  public showDataUpdateTrackingInformation(): void {
    this.dataUpdateTrackingService.showPopup();
  }

  clearMenu() {
    this.menuItems = [];
    this.collapseNavbar();
    this.selectedCompany = null;
    this.account = null;
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  navigationHappened(menuItem: MenuModel, event) {
    this.menuItems.map(r => r.isActive = false);
    menuItem.isActive = true;
  }

  toggleChild(menuItem: MenuModel, event) {
    this.closeSubMenu(event);

    if (menuItem.ChildLinks === null) {
      this.menuItems.map(r => r.isActive = false);
      menuItem.isActive = true;
    } else {
      this.navigateFirstChildLinkByDefault(menuItem);
      menuItem.childrenVisible = true;

      return false;
    }
  }

  navigateFirstChildLinkByDefault(menuItem: MenuModel) {
    if (!menuItem.isActive) {
      this.router.navigateByUrl(menuItem.ChildLinks.find(value => value.isVisible).url);
    }

  }

  isSubMenuOpened(): boolean {
    return this.menuItems.some(r => r.childrenVisible);
  }

  closeSubMenu(event) {
    this.menuItems.map(r => r.childrenVisible = false);
  }

  deactivateSubMenu() {
    this.menuItems.map(r => {
      r.childrenVisible = false;
      r.isActive = false;
    });
  }

  refreshMenu(effectivePermissions: string[] = null) {
    if (this.account == null) {
      return [];
    }

    let permissions = effectivePermissions || <string[]>this.account.effectivePermissions;
    this.adminAccess = permissions.indexOf('USER_ADMIN_WRITE') > -1;
    this.widgetAdmin = permissions.indexOf('MAIN_PAGE_WIDGET_WRITE') > -1;

    this.menuItems = this.isHedgeUrl.getValue() ? this.menuService.getHedgeMenuItems(permissions) : this.menuService.getMenuItems(permissions);
    this.openActivatedSubmenu();
  }

  openActivatedSubmenu() {
    for (let item of this.menuItems) {
      let exactUrlMatch = this.router.url.indexOf('/news/') !== 0; // news popup submenu open
      item.childrenVisible = this.router.url !== '/' &&
        item.ChildLinks &&
        item.ChildLinks.some(r => r.url !== '#' && this.router.isActive(r.url, exactUrlMatch));
    }
  }

  updateLanguage(lang: string) {
    this.userService.updateLanguage(lang)
      .pipe(
        finalize(() => {
            location.reload();
          }
        ))
      .subscribe(
        () => this.stateStorageService.storeLanguage(lang),
        () => {
        },
        () => this.dialogService.saveSuccess());
  }

  openDailyPricesModal(): void {
    const modalRef = this.modalService.open(DailyPricesDialogComponent, {backdrop: 'static'});
    modalRef.componentInstance.isHedgeUrl = this.isHedgeUrl.getValue();
    modalRef.componentInstance.modalClose.subscribe(() => {
      modalRef.close();
    });
  }

  get hasManageServerPermission(): boolean {
    return this.stateStorageService?.getEffectivePermissions()?.indexOf('MANAGE_SERVER') !== -1;
  }

  private setDailyPricesState(): void {
    this.dailyPricesService.dailyPricesStateChange.pipe(takeUntil(this.destroy)).subscribe((change: DailyPriceStateChange): void => {
      this.isActiveDailyPrices = change.type != null;
    });
  }
}
