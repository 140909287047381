<ng-content></ng-content>

<div class="d-flex flex-wrap align-items-center button-items mt-2">
    <button mat-raised-button color="primary"
            *ngIf="files.length > 0 && isMultipleFiles"
            (click)="uploadAll()"
            type="button"
            [disabled]="isUploading || isUploadedAll">
        {{'file-upload.upload-all' | translate}}
    </button>
    <button mat-raised-button color="primary"
            *ngIf="files.length > 0 && isMultipleFiles"
            (click)="removeAll()"
            type="button"
            [disabled]="isUploading">
        {{'file-upload.remove-all' | translate}}
    </button>
</div>


