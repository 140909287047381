<header class="docs">
  <h2 jhiTranslate="main.link.reports"></h2>
</header>

<ul ngbNav #navReportsInner="ngbNav" class="nav-tabs" tabSave="reports" #tabSave="tabSave"  (navChange)="tabChanged($event)">
  <li [ngbNavItem]="'consumption'" *ngIf="isConsumptionNewAllowed">
    <a ngbNavLink>{{ 'consumption.title' | translate }}</a>
    <ng-template ngbNavContent>
      <jhi-consumption *ngIf="tabSave.tabLoaded"
                       [tabContent]="true"></jhi-consumption>
    </ng-template>
  </li>
  <li [ngbNavItem]="'invoice'" *ngIf="isInvoiceAllowed">
    <a ngbNavLink>{{ 'main.link.invoiceReport' | translate }}</a>
    <ng-template ngbNavContent>
      <jhi-invoice-report *ngIf="tabSave.tabLoaded"></jhi-invoice-report>
    </ng-template>
  </li>
  <li [ngbNavItem]="'price'" *ngIf="isPriceAllowed">
    <a ngbNavLink>{{ 'main.link.marketPrices' | translate }}</a>
    <ng-template ngbNavContent>
      <jhi-price *ngIf="tabSave.tabLoaded"
                 [tabContent]="true"></jhi-price>
    </ng-template>
  </li>
  <li [ngbNavItem]="'tolerance'" *ngIf="isToleranceAllowed">
    <a ngbNavLink>{{ 'main.link.tolerancy' | translate }}</a>
    <ng-template ngbNavContent>
      <cez-tolerance-threshold *ngIf="tabSave.tabLoaded"></cez-tolerance-threshold>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="navReportsInner"></div>
