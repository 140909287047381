import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { JhiLanguageHelper } from '../shared';
import { RegistrationRequest } from './registration.request.model';

@Component({
    selector: 'registration-request',
    templateUrl: './registration.request.html',
})
export class RegistrationRequestComponent implements OnInit {
    registrationData: RegistrationRequest = new RegistrationRequest();
    isSaving: boolean = false;
    success: boolean = false;
    languages: any[];


    constructor(private router: Router,
                private languageHelper: JhiLanguageHelper,
                private http: HttpClient) {

    }

    ngOnInit() {
        this.languageHelper.getAll().then((languages) => {
            this.languages = languages;
        });
    }

    onSubmit(form: NgForm) {
        this.isSaving = true;
        let regRequest = <RegistrationRequest>form.value;

        this.http.post('/api/registration_request/nologin', regRequest).subscribe(() => {
            this.isSaving = false;
            this.success = true;
        });
    }
}
