import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'jhi-diagram-options',
    templateUrl: './diagram-options.component.html',
    styles: []
})
export class DiagramOptionsComponent implements OnInit {

    @Output() minSelectedChange: EventEmitter<boolean> = new EventEmitter();
    private _minSelected: boolean;

    @Output() maxSelectedChange: EventEmitter<boolean> = new EventEmitter();
    private _maxSelected: boolean;

    @Output() avgSelectedChange: EventEmitter<boolean> = new EventEmitter();
    private _avgSelected: boolean;

    constructor() {
    }

    ngOnInit() {
    }

    get minSelected(): boolean {
        return this._minSelected;
    }

    @Input() set minSelected(value: boolean) {
        this._minSelected = value;
        this.minSelectedChange.emit(this._minSelected);
    }

    get maxSelected(): boolean {
        return this._maxSelected;
    }

    @Input() set maxSelected(value: boolean) {
        this._maxSelected = value;
        this.maxSelectedChange.emit(this._maxSelected);
    }

    get avgSelected(): boolean {
        return this._avgSelected;
    }

    @Input() set avgSelected(value: boolean) {
        this._avgSelected = value;
        this.avgSelectedChange.emit(this._avgSelected);
    }

}
