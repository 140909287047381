import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SessionTimerService } from './session-timer.service';

@Component({
    selector: 'session-timer',
    templateUrl: './session-timer.component.html',
    styleUrls: ['./session-timer.component.scss']
})
export class SessionTimerComponent implements OnInit {

    constructor(private sessionTimerService: SessionTimerService) {
    }

    ngOnInit() {
    }

    get timer(): Observable<number> {
        return this.sessionTimerService.timer;
    }

}
