export type Resolution =
    'FIVE_MINUTELY'
    | 'QUARTER_HOURLY'
    | 'HOURLY'
    | 'DAILY'
    | 'WEEKLY'
    | 'MONTHLY'
    | 'QUARTER_YEARLY'
    | 'YEARLY';

export const Resolution = {
    FIVE_MINUTELY: 'FIVE_MINUTELY' as Resolution,
    QUARTER_HOURLY: 'QUARTER_HOURLY' as Resolution,
    HOURLY: 'HOURLY' as Resolution,
    DAILY: 'DAILY' as Resolution,
    WEEKLY: 'WEEKLY' as Resolution,
    MONTHLY: 'MONTHLY' as Resolution,
    QUARTER_YEARLY: 'QUARTER_YEARLY' as Resolution,
    YEARLY: 'YEARLY' as Resolution
};
