import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CezPortalSharedModule} from '../shared';
import {ConsumptionNewComponent} from './consumption-new.component';
import {DiagramColumnComponent} from './diagram-column/diagram-column.component';
import {FilterBasicComponent} from './filter-basic/filter-basic.component';
import {MinMaxAvarageComponent} from './min-max-avarage/min-max-avarage.component';
import {PerformanceDiagramAreaComponent} from './performance-diagram-area/performance-diagram-area.component';
import {
    PerformanceDiagramBoxPlotComponent
} from './performance-diagram-box-plot/performance-diagram-box-plot.component';
import {
    PerformanceDispersionDiagramColumnComponent
} from './performance-dispersion-diagram-column/performance-dispersion-diagram-column.component';
import {PerformanceHeatmapDiagramComponent} from './performance-heatmap-diagram/performance-heatmap-diagram.component';
import {ConsumptionExportComponent} from './export/consumption-export.component';
import {ConsumptionDisplayModeComponent} from './consumption-display-mode/consumption-display-mode.component';
import {ConsumptionDiagramOptionsComponent} from './cunsumption-diagram-options/consumption-diagram-options.component';
import {ConsumptionDynamicFilterComponent} from './consumption-dynamic-filter/consumption-dynamic-filter.component';
import {ConsumptionDynamicFilterService} from './consumption-dynamic-filter/consumption-dynamic-filter.service';
import {ConsumptionExportService} from './export/consumption-export.service';
import {NgBDatePickerConvertService} from '../shared/services/ngb.datepicker.convert.service';
import {SaveTemplateDialogComponent} from './filter-basic/template-save-dialog/save-template-dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import { SelectButtonModule } from 'primeng/selectbutton';

@NgModule({
    imports: [
        CommonModule,
        CezPortalSharedModule,
        SelectButtonModule,
        TranslateModule
    ],
    declarations: [ConsumptionNewComponent,
        SaveTemplateDialogComponent,
        DiagramColumnComponent,
        PerformanceDiagramAreaComponent,
        FilterBasicComponent,
        PerformanceDiagramBoxPlotComponent,
        PerformanceDispersionDiagramColumnComponent,
        PerformanceHeatmapDiagramComponent,
        MinMaxAvarageComponent,
        ConsumptionExportComponent,
        ConsumptionDisplayModeComponent,
        ConsumptionDiagramOptionsComponent,
        ConsumptionDynamicFilterComponent],
    providers: [
        ConsumptionDynamicFilterService,
        ConsumptionExportService,
        NgBDatePickerConvertService
    ],
    exports: [ConsumptionNewComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ConsumptionNewModule {
}
