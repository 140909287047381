<mat-card>
    <form [formGroup]="form" (ngSubmit)="save()">

        <div class="row justify-content-start">
            <div class="col-xl-3 col-lg-4">
                <mat-form-field class="w-100">
                    <mat-select required
                                placeholder="{{'toleranceThreshold.tolerancePeriod' | translate}}"
                                formControlName="tolerancePeriodId">
                        <ng-container *ngFor="let v of tolerancePeriods">
                            <mat-option [value]="v.id" *permissionOverlay="'';disabled permissionMap.get(v)"
                                        [disabled]="!permissionMap.get(v)">
                                {{v.start | cezDate}} - {{v.end | cezDate}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-xl-2 col-lg-3 col-sm-6">
                <mat-form-field class="w-100">
                    <input type="number" formControlName="upperBound" matInput
                           placeholder="{{'toleranceThreshold.warnUpper' | translate}}" required>
                </mat-form-field>
            </div>

            <div class="col-xl-2 col-lg-3 col-sm-6">
                <mat-form-field class="w-100">
                    <input type="number" formControlName="lowerBound" matInput
                           placeholder="{{'toleranceThreshold.warnLower' | translate}}" required>
                </mat-form-field>
            </div>
        </div>


        <div class="row justify-content-start">
            <div class="col-xl-3 col-lg-4">
                <mat-form-field class="w-100">
                    <mat-select required
                                placeholder="{{'toleranceThreshold.type' | translate}}"
                                formControlName="type">
                        <mat-option *ngFor="let t of toleranceTypes | keys" [value]="t.value">
                            {{'toleranceThreshold.types.' + t.value | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>


            <div class="col-xl-2 col-lg-3 col-sm-6" *ngIf="formControl.typeControl.value === 'EXPECTED_PLAN'">
                <mat-form-field class="w-100">
                    <mat-select [(value)]="selectedExpectedPlan"
                                (selectionChange)="expectedPlanSelectionChange($event.value)">
                        <mat-option *ngFor="let p of expectedPlanProps" [value]="p">
                            {{'toleranceThreshold.' + p | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-xl-2 col-lg-3 col-sm-6" *ngIf="formControl.typeControl.value === 'EXPECTED_PLAN'">
                <div *ngFor="let p of expectedPlanProps" class="w-100">
                    <mat-form-field *ngIf="p===selectedExpectedPlan && p!=='expectedPlan' " class="w-100">
                        <input type="number" matInput required
                               formControlName="{{p}}"
                               placeholder="{{'toleranceThreshold.' + p | translate}} (MWh)">
                    </mat-form-field>
                </div>
            </div>


            <div class="col-xl-2 col-lg-3 col-sm-6" *ngIf="formControl.typeControl.value === 'PERIOD_AVERAGE'">
                <mat-form-field class="w-100">
                    <input matInput [matDatepicker]="start" required
                           placeholder="{{'toleranceThreshold.start' | translate}}"
                           intervalRestrictor
                           formControlName="start">
                    <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
                    <mat-datepicker #start>
                    </mat-datepicker>
                </mat-form-field>
            </div>

            <div class="col-xl-2 col-lg-3 col-sm-6" *ngIf="formControl.typeControl.value === 'PERIOD_AVERAGE'">
                <mat-form-field>
                    <input matInput [matDatepicker]="end" required
                           placeholder="{{'toleranceThreshold.end' | translate}}"
                           intervalRestrictor
                           formControlName="end">
                    <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
                    <mat-datepicker #end>
                    </mat-datepicker>
                </mat-form-field>
            </div>
        </div>

    </form>

    <form [formGroup]="formExpectedPlanMonths" (ngSubmit)="save()" *ngIf="formControl.typeControl.value === 'EXPECTED_PLAN' && selectedExpectedPlan === 'expectedPlan'">
        <div class="row justify-content-start">
            <div *ngFor="let p of monthInTolerancePeriod" class="col-xl-1 col-lg-1 col-sm-1">
                <mat-form-field class="w-100">
                    <input type="number" matInput required
                           formControlName="{{'expectedPlanMonths' + p}}"
                           placeholder="{{'toleranceThreshold.expectedPlanMonths.' + p | translate}} (MWh)">
                </mat-form-field>
            </div>
        </div>
    </form>

    <div class="row pt-1">
        <div class="col-lg-7 pt-2">
            <highcharts-chart [chart]="chart"></highcharts-chart>
        </div>
        <div class="col-lg-5 pt-2">
            <highcharts-chart [chart]="combined_chart"></highcharts-chart>
        </div>
    </div>

</mat-card>
