import { Route } from '@angular/router';

import { UserRouteAccessService } from '../shared/auth/user-route-access-service';
import { HomeComponent } from './home.component';

export const HOME_ADMIN_ROUTE: Route[] = [
  {
    path: 'home/admin',
    component: HomeComponent,
    data: {
      authorities: ['ROLE_ADMIN'],
      pageTitle: 'main.link.homepageadmin'
    },
    canActivate: [UserRouteAccessService]
  }];
