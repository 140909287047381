import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {ProductAndServicesUserModel} from '../shared/dto/product-and-services-user.model';
import {ProductService} from '../shared/services/product.service';
import {CategoryService} from '../shared/services/category.service';
import {DocumentFilterService} from '../shared/services/document.filter.service';


@Component({
    selector: 'jhi-products',
    templateUrl: './products.component.html',
    styles: []
})
export class ProductsComponent implements OnInit {

    products: ProductAndServicesUserModel[] = [];
    currentProductName: string;
    cezTitle: string = ' - CEZHU|b';


    constructor(private productService: ProductService,
                private categoryService: CategoryService,
                private documentFilterService: DocumentFilterService,
                private titleService: Title,
                private route: ActivatedRoute) {

        this.route.params.subscribe(params => {
            let productCategory = params['id'];
            this.categoryService.getProductCategories().subscribe(prods => {
                this.currentProductName = prods.filter(r => r.id == productCategory)[0].name;
                this.titleService.setTitle(this.currentProductName + this.cezTitle);
            });
            this.productService.getProducts(productCategory).subscribe(r => this.products = r);
        });
    }

    ngOnInit() {
    }

}
