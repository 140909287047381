import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterModel } from '../../consumption/filter-basic/filter.model';
import { NgBDatePickerConvertService } from '../../shared/services/ngb.datepicker.convert.service';
import { ViewTemplate } from '../dto/view.template.model';
import * as moment from 'moment';

@Injectable()
export class ViewTemplateService {

    constructor(private http: HttpClient,
                private dateConverter: NgBDatePickerConvertService) {

    }

    createOrUpdateViewTemplateForAnotherUser(filterData: ViewTemplate, userId: number): Observable<any> {
        return this.http.post('api/view_template/user/' + userId, filterData);
    }

    createOrUpdateViewTemplate(filterData: ViewTemplate): Observable<any> {
        return this.http.post('api/view_template/current_user', filterData);
    }

    getChartViewTemplates(): Observable<ViewTemplate[]> {
        return this.http.get<ViewTemplate[]>('api/view_template/current_user/type/CHART');
    }

    deleteViewTemplate(id: number): Observable<any> {
        return this.http.delete('api/view_template/current_user/template/' + id);
    }

    filterModelFromViewTemplate(jsonData: any, isDynamic: boolean): FilterModel {
        let filterModel = new FilterModel();
        filterModel.selectedDiagram = jsonData.selectedDiagram;
        if (jsonData.selectedPods)
            filterModel.selectedPods = jsonData.selectedPods;
        if (jsonData.selectedPodGroups)
            filterModel.selectedPodGroups = jsonData.selectedPodGroups;

        filterModel.multiplePeriod = jsonData.multiplePeriod;
        filterModel.temperatureChecked = jsonData.temperatureChecked;
        filterModel.periodParameters = jsonData.periodParameters;
        filterModel.resolution = jsonData.resolution;
        if (jsonData.referenceWeekChecked) {
            filterModel.referenceWeek = jsonData.referenceWeek;
            filterModel.referenceWeekChecked = jsonData.referenceWeekChecked;
            filterModel.excludeBusinessHolidays = jsonData.excludeBusinessHolidays;
        }
        filterModel.shift = jsonData.shift;
        filterModel.intervalSize = jsonData.intervalSize;
        filterModel.selectedIntervalGranularity = jsonData.selectedIntervalGranularity;

        if (isDynamic) {
            switch (filterModel.selectedIntervalGranularity) {
                case 0:
                    this.shiftDays(filterModel);
                    break;
                case 1:
                    this.shiftWeeks(filterModel);
                    break;
                case 2:
                    this.shiftMonths(filterModel);
                    break;
            }
            filterModel.periodParameters[0].periodInDays = moment(this.dateConverter.convertToDate(filterModel.periodParameters[0].dateTo))
                .diff(moment(this.dateConverter.convertToDate(filterModel.periodParameters[0].dateFrom)), 'days');
        }
        return filterModel;
    }

    shiftMonths(filterModel: FilterModel) {
        let today = new Date();
        let start = new Date(today.getFullYear(), today.getMonth() - filterModel.intervalSize + filterModel.shift + 1, 1);
        let end = new Date(today.getFullYear(), today.getMonth() + filterModel.shift + 1, 0);
        filterModel.periodParameters[0].dateFrom = this.dateConverter.convertFromDate(start);
        filterModel.periodParameters[0].dateTo = this.dateConverter.convertFromDate(end);
    }

    shiftWeeks(filterModel: FilterModel) {
        let today = new Date();
        let start = new Date();
        let end = new Date();
        let firstDayOfWeek = today.getDate() + 1 - today.getDay() - 7 * filterModel.intervalSize + 7 * (filterModel.shift + 1);
        let lastDayOfWeek = firstDayOfWeek - 1 + 7 * filterModel.intervalSize;
        start = new Date(today.getFullYear(), today.getMonth(), firstDayOfWeek);
        end = new Date(today.getFullYear(), today.getMonth(), lastDayOfWeek);
        filterModel.periodParameters[0].dateFrom = this.dateConverter.convertFromDate(start);
        filterModel.periodParameters[0].dateTo = this.dateConverter.convertFromDate(end);
    }

    shiftDays(filterModel: FilterModel) {
        let today = new Date();
        let start = new Date();
        let end = new Date();
        start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - filterModel.intervalSize + 1 + filterModel.shift);
        end = new Date(today.getFullYear(), today.getMonth(), today.getDate() + filterModel.shift);
        filterModel.periodParameters[0].dateFrom = this.dateConverter.convertFromDate(start);
        filterModel.periodParameters[0].dateTo = this.dateConverter.convertFromDate(end);
    }
}
