<mat-card [hidden]="editMode">
    <mat-card-title>
        <header class="docs">
            <h2>{{'company.companyAdmin' | translate}}</h2>
            <aside>
                <div class="pull-right btn-group">
                    <ng-container *ngIf="!editMode">
                        <input type="text" class="form-control pull-left" [(ngModel)]="salesforceAccountId"/>
                        <button class="btn btn-primary" (click)="syncNewCompanyFromSalesforce(salesforceAccountId)">{{'company.sync.new' | translate}}</button>
                    </ng-container>
                </div>
            </aside>
        </header>
    </mat-card-title>
    <mat-card-content>
        <jhi-company-list [companies]="companies"
                          (editCompany)="edit($event)"
                          (deleteCompany)="delete($event)"
                          (syncCompany)="sync($event)"
                          [crudOptions]="true"
                          *ngIf="!editMode"></jhi-company-list>
    </mat-card-content>
</mat-card>

<div class="row p-3 justify-content-end">
    <button [hidden]="!editMode" class="btn btn-primary float-right" (click)="onCancel()">{{'entity.action.cancel' | translate}}</button>
</div>

<form *ngIf="editMode" class="form-horizontal" name="companyForm" role="form" (ngSubmit)="submit()"
      #companyForm="ngForm">
    <jhi-partner-details [(companyModel)]="companyModel"
                         [userView]="false"
                         #partnerDetailsComponent="partnerDetails"></jhi-partner-details>

    <mat-card class="px-3 py-3 mt-3">
        <mat-card-title>
            <header class="cez-table-header">
                <h4 class="card-title">{{'company.new.cezContacts' | translate}}</h4>
                <button type="button" class="btn btn-primary btn-wide-150" (click)="newContact(null, true)">{{'company.new.newCezContact' | translate}}
                </button>
            </header>
        </mat-card-title>
        <mat-card-content>
            <jhi-contact-list [adminView]="true" [contacts]="companyModel.cezContacts"
                              (editContact)="newContact($event, true)"
                              (deleteContact)="deleteContact($event, true)"></jhi-contact-list>
        </mat-card-content>
    </mat-card>

    <mat-card class="px-3 py-3 mt-3">
        <mat-card-title>
            <header class="cez-table-header">
                <h4 class="card-title">{{'company.new.contacts' | translate}}</h4>
                <button type="button" class="btn btn-primary btn-wide-150" (click)="newContact(null)">{{'company.new.newContact' | translate}}
                </button>
            </header>
        </mat-card-title>
        <mat-card-content>
            <jhi-contact-list [adminView]="true" [contacts]="companyModel.contacts" (editContact)="newContact($event)"
                              (deleteContact)="deleteContact($event)"></jhi-contact-list>
        </mat-card-content>
    </mat-card>

    <mat-card class="px-3 py-3 mt-3">
        <mat-card-title>
            <h4 class="card-title">{{'company.new.users' | translate}}</h4>
        </mat-card-title>
        <mat-card-content>
            <ng-multiselect-dropdown
                name="selectedUsers"
                [placeholder]="'Select user'"
                [settings]="dropdownSettings"
                [data]="userListOptions"
                [(ngModel)]="userListOptionsModel"
                (onSelect)="onChange()"
                (onDeSelect)="onChange()"
                (onSelectAll)="onSelectAllChange()"
                (onDeSelectAll)="onDeSelectAllChange()">
            </ng-multiselect-dropdown>

            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th>
                            <span>{{'company.new.id' | translate}}</span>
                        </th>
                        <th>
                            <span>{{'company.new.userName' | translate}}</span>
                        </th>
                        <th>
                            <span>{{'company.new.email' | translate}}</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody *ngIf="companyModel.users">
                    <tr *ngFor="let agreementUser of companyModel.users">
                        <td>{{agreementUser.user.id}}</td>
                        <td>{{agreementUser.user.login}}</td>
                        <td>{{agreementUser.user.email}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="px-3 py-3 mt-3">
        <mat-card-title>
            <header class="cez-table-header">
                <h4 class="card-title">{{'company.new.subscription' | translate}}</h4>
                <button type="button" class="btn btn-primary btn-wide-150" (click)="subscriptionEditor(null)">{{'company.new.newSubscription' | translate}}
                </button>
            </header>
        </mat-card-title>
        <mat-card-content>
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th>
                            <span>{{'company.new.id' | translate}}</span>
                        </th>
                        <th>
                            <span>{{'company.new.name' | translate}}</span>
                        </th>
                        <th>
                            <span>{{'company.new.startTime' | translate}}</span>
                        </th>
                        <th>
                            <span>{{'company.new.endTime' | translate}}</span>
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody *ngIf="companyModel.subscriptions">
                    <tr *ngFor="let subscription of companyModel.subscriptions">
                        <td>{{subscription.subscriptionGroup.id}}</td>
                        <td>{{subscription.subscriptionGroup.name}}</td>
                        <td>{{subscription.startTime | cezDate}}</td>
                        <td>{{subscription.endTime | cezDate}}</td>
                        <td>
                            <div class="pull-right">
                                <button type="button" class="btn btn-info btn-sm"
                                        (click)="subscriptionEditor(subscription)">
                                    <span class="fa fa-pencil"></span>
                                </button>
                                <button type="button" class="btn btn-danger btn-sm"
                                        (click)="deleteSubscription(subscription.id)">
                                    <span class="fa fa-remove"></span>
                                </button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </mat-card-content>
    </mat-card>

    <div class="mt-3" *ngIf="companyModel.id">
        <jhi-invoices *ngIf="editMode" [erasable]="true" [partner]="companyModel.id"></jhi-invoices>
    </div>

    <button type="submit" class="btn btn-primary mt-3 pull-right"
            [disabled]="companyForm.invalid || partnerDetailsComponent.form.invalid" jhiTranslate="entity.action.save">Save
    </button>
</form>
