
<div *ngFor="let di of intervals;let i = index; last as isLast">
        <day-interval-selector [selectedInterval]="intervals[i]"
                               (selectedIntervalChange)="setInterval($event, i)"
                               [order]="i+1"></day-interval-selector>

    <div class="row">
        <div class="col-12 text-xl-right mb-1" *ngIf="isLast">
            <button [hidden]="upperBound === 1 || intervals.length < 2"
                    class="btn btn-block"
                    (click)="deleteInterval(i)">
                <span class="fa fa-calendar-minus-o"></span> {{intervals.length | formatOrder:'day-selector.dynamic-day-interval-selector.delete'}}
            </button>
        </div>

        <div class="col-12 text-xl-right my-1" *ngIf="isLast">
            <button [hidden]="noMoreDatesAllowed"
                    class="btn btn-block"
                    [disabled]="'CONSUMPTION_COMPARISION_BY_INTERVAL' | doesNotHavePermission"
                    *permissionOverlay="'CONSUMPTION_COMPARISION_BY_INTERVAL'"
                    (click)="addNextDateInterval()">
                <span class="fa fa-calendar-plus-o"></span> {{intervals.length+1 | formatOrder:'day-selector.dynamic-day-interval-selector.add'}}
            </button>
        </div>
    </div>

</div>
