import { Route } from '@angular/router';

import { ProductsComponent } from './products.component';
import {UserRouteAccessService} from '../shared';

export const PRODUCTS_ROUTE: Route[] = [
  {
    path: 'products/:id',
    component: ProductsComponent,
    data: {
      permission: "PRODUCT_AND_SERVICE_READ",
      pageTitle: 'main.link.services'
    },
    canActivate: [UserRouteAccessService]
  }];
