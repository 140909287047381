import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Principal } from '../../shared/auth/principal.service';
import { StateStorageService } from '../auth/state-storage.service';
import { PermissionOverlayPopupComponent } from '../permission-overlay/permission-overlay-popup/permission-overlay-popup.component';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Injectable()
export class UserRouteAccessService  {

    readonly companySelectorRoute: string = 'company/selector';

    constructor(private router: Router,
                private principal: Principal,
                private stateStorageService: StateStorageService,
                private dialog: MatDialog) {
    }

    canActivate(route: ActivatedRouteSnapshot): boolean|Observable<boolean>|Promise<boolean> {
        if (navigator.userAgent.indexOf('MSIE') !== -1
            || navigator.appVersion.indexOf('Trident/') > 0) {
            let event = document.createEvent('Event');
            event.initEvent('routeChange', true, true);
            // args: string type, boolean bubbles, boolean cancelable
            window.dispatchEvent(event);
        } else {
            //CustomEvent
            let event = new Event('routeChange');
            window.dispatchEvent(event);
        }

        window.scrollTo(0, 0);
        document.body.scrollTop = 0;


        return this.principal.identity().then((account) => {
            if (account == null) {
                this.router.navigate(['/login']);
                return false;
            }

            if (account.twoFactorAuthRequired && !account.twoFactorKeyActivated) {
                if (route.routeConfig.path !== 'twofactorauth/activate') {
                    this.router.navigate(['/twofactorauth/activate']);
                    return false;
                }
                return true;
            } else {
                let termsOfServiceNeedsToBeAccepted = this.stateStorageService.getTermsOfServiceNeedsToBeAcceptedState();

                if (termsOfServiceNeedsToBeAccepted) {
                    if (route.routeConfig.path !== 'termsofservice') {
                        this.router.navigate(['termsofservice']);
                        return false;
                    }
                    return true;
                }

                if (route.routeConfig.path !== this.companySelectorRoute) {
                    this.mustSelectCompanyExceptAdmin(account);
                }

                const permissions = this.stateStorageService.getEffectivePermissions();
                const softPermissions = route.data['softPermissions'] ? route.data['softPermissions'].filter(sp => permissions.indexOf(sp) > -1) : [];
                if (!permissions) {
                    this.router.navigate(['/login']);
                    return false;
                }

                if (permissions.indexOf(route.data['permission']) > -1 || !route.data['permission']) {
                    if (route.data['softPermissions'] && softPermissions.length == 0) {
                        this.dialog.open(PermissionOverlayPopupComponent, {
                            disableClose: true,
                            panelClass: 'session-warning-popup'
                        }).afterClosed();
                    }
                    return true;
                } else {
                    //redirect to an error page
                    return false;
                }
            }
        });
    }

    mustSelectCompanyExceptAdmin(account: any) {
        let selectedCompany = this.stateStorageService.getSelectedCompanyId();

        if (!account.groups.some(r => r === 1) && !selectedCompany) {
            this.router.navigate([this.companySelectorRoute]);
        }
    }
}
