import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SystemInfoDto} from '../dto/system-info.model';
import {Observable} from 'rxjs';

@Injectable()
export class SystemInfoService {

    constructor(private http: HttpClient) {}

    public getSystemInfo(): Observable<SystemInfoDto> {
        return this.http.get<SystemInfoDto>('/api/system/info');
    }
}

