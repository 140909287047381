import {DeliveryPeriod} from '../dto/delivery.periods';
import {DeliveryPeriodDto} from '../dto/delivery-period-dto';
import * as moment from 'moment';

import {Resolution} from '../time-series/model/resolution.enum';
import {PALETTE} from '../color-palette/color-palette.constant';

export class ChartDeliveryPeriodUtil {

  public static readonly MAIN_X_AXIS: string = 'main';

  public static getDividerPlotLine(deliveryPeriod: DeliveryPeriodDto | DeliveryPeriod, resolution?: Resolution): {
    [p: string]: string | number
  }[] {
    if (moment().isSameOrAfter(moment(deliveryPeriod.startTime)) && moment().isSameOrBefore(moment(deliveryPeriod.endTime).add(1, 'day'))) {
      return [{
        color: PALETTE.ORANGE[3],
        value: resolution === Resolution.QUARTER_YEARLY
            ? this.getQuarterlyDividerPlotValue(deliveryPeriod)
            : this.getMonthlyDividerPlotValue(deliveryPeriod),
        width: 1
      }];
    }
    return [];
  }

  public static analyzeDeliveryPeriod(deliveryPeriod: DeliveryPeriodDto | DeliveryPeriod): number[] {
    const startMonth: number = moment(deliveryPeriod.startTime).month() + 1;
    const endMonth: number = moment(deliveryPeriod.endTime).month() + 1;
    return Array.from({length: endMonth - startMonth + 1}, (_: any, num: number) => num + startMonth);
  }

  private static getMonthlyDividerPlotValue(deliveryPeriod: DeliveryPeriodDto | DeliveryPeriod): number {
    return (moment().month() + 1) - this.analyzeDeliveryPeriod(deliveryPeriod)[0] - 0.5;
  }

  private static getQuarterlyDividerPlotValue(deliveryPeriod: DeliveryPeriodDto | DeliveryPeriod): number {
    return moment().quarter() - Math.ceil(this.analyzeDeliveryPeriod(deliveryPeriod)[0] / 3) - 0.5;
  }

}
