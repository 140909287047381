import { BaseDto, FileDto, Category } from "../";

export class DocumentModel extends BaseDto {
    categories: Category[];
    title: string;
    description: string;
    releaseTime: Date;
    documentFile: FileDto;
    show: boolean = true;
    showDetail: boolean;
    partnerId: number;
}