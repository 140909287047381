import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'jhi-date-picker-pencil',
  templateUrl: './date-picker-pencil.component.html',
  styles: []
})
export class DatePickerPencilComponent implements OnInit {
  @Output() click: EventEmitter<any> = new EventEmitter();
  @Input() id: string;
  @Input() dpModel: any;
  @Input() disabled: boolean;
  @Input() userView: boolean;
  @Output() dpModelChange: EventEmitter<any> = new EventEmitter();
  @Output() dpPencilClick: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

}
