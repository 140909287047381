<header class="docs">
    <h2 jhiTranslate="register.title"></h2>
</header>

<form #registrationRequestForm="ngForm" (ngSubmit)="onSubmit(registrationRequestForm)" *ngIf="!success">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-md-6 col-sm-6">
                <label jhiTranslate="userManagement.lastName">Last name</label>
                <input required name="lastName" [ngModel]="registrationData.lastName" class="form-control">
            </div>
            <div class="form-group col-md-6 col-sm-6">
                <label jhiTranslate="userManagement.firstName">First name</label>
                <input required name="firstName" [ngModel]="registrationData.firstName" class="form-control">
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6 col-sm-6">
                <label jhiTranslate="userManagement.company">Company</label>
                <input required name="company" [ngModel]="registrationData.company" class="form-control">
            </div>
            <div class="form-group col-md-6 col-sm-6">
                <label jhiTranslate="global.form.email"></label>
                <input required name="email" type="email" [ngModel]="registrationData.email" class="form-control" email>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6 col-sm-6">
                <label jhiTranslate="userManagement.langKey">Language</label>
                <select class="form-control" id="language" name="language" [(ngModel)]="registrationData.language"
                        required>
                    <option></option>
                    <option *ngFor="let language of languages"
                            [value]="language">{{language | findLanguageFromKey}}</option>
                </select>
            </div>
            <div class="form-group col-md-6 col-sm-6">
                <label jhiTranslate="userManagement.phone">Phone</label>
                <input required name="phoneNumber" [ngModel]="registrationData.phoneNumber" class="form-control">
            </div>
        </div>
        <div class="form-group">
            <div>
                <label jhiTranslate="userManagement.message">Message</label>
                <textarea rows="3" required name="message" [ngModel]="registrationData.message" class="form-control"
                          maxlength="65535">
                </textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary" [disabled]="registrationRequestForm.form.invalid || isSaving"
                jhiTranslate="register.form.button">Save
        </button>
    </div>
</form>

<div class=" alert alert-success" *ngIf="success">
    <strong jhiTranslate="register.messages.success"></strong>
</div>
