import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DeliveryPeriod } from '../dto/delivery.periods';

@Component({
    selector: 'jhi-delivery-period-list',
    templateUrl: './delivery-period-list.component.html',
    styles: []
})
export class DeliveryPeriodListComponent implements OnInit {

    @Input() model: DeliveryPeriod[];
    @Input() adminView: boolean;
    @Output() editEmitter: EventEmitter<DeliveryPeriod> = new EventEmitter();
    @Output() deleteEmitter: EventEmitter<number> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    edit(deliveryPeriod: DeliveryPeriod) {
        this.editEmitter.emit(deliveryPeriod);
    }

    delete(deliveryPeriod: DeliveryPeriod) {
        this.deleteEmitter.emit(deliveryPeriod.id);
    }
}
