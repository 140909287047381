<div class="d-flex justify-content-end">
    <div class="d-flex px-0 pr-2 deadline-title">{{'offerClaim.addRemainingTime' | translate}}:</div>
    <div class="d-flex m-0 p-0 minute-input-container">
        <input name="deadline"
               maxlength="5"
               min="1"
               type="text"
               mask="00:00"
               autocomplete="off"
               [matMenuTriggerFor]="timeMenu"
               (menuOpened)="focusDeadline(deadlineInput)"
               #deadlineInput
               class="form-control deadline-input minute-input"
               [disabled]="disabled"
               (ngModelChange)="changeEvent($event)"
               [(ngModel)]="deadline">
        <mat-menu #timeMenu="matMenu" [overlapTrigger]="false" class="time-menu">
            <div *ngFor="let option of deadlineOptions">
                <span mat-menu-item (click)="selectDeadLine(option)" class="time-item">{{option}}</span>
            </div>
        </mat-menu>
    </div>

</div>
