import {Component, ElementRef, HostListener, Input, ViewChild} from '@angular/core';

@Component({
    selector: 'sticky-delivery-period',
    templateUrl: './sticky-delivery-period.component.html',
    styleUrls: ['./sticky-delivery-period.component.scss']
})
export class StickyDeliveryPeriodComponent {

    @Input() startTime: Date;
    @Input() endTime: Date;

    @ViewChild('deliveryPeriodWatcher', {static: true}) deliveryPeriodWatcher: ElementRef;
    @ViewChild('deliveryPeriod', {static: true}) deliveryPeriod: ElementRef;

    @HostListener('window:scroll', ['$event'])
    onScrollEvent() {
        const boundingClientRect = this.deliveryPeriodWatcher.nativeElement.getBoundingClientRect();
        this.deliveryPeriod.nativeElement.style.visibility = boundingClientRect.top == 0 ? 'visible' : 'hidden';
    }

}
