export class SortUtil {

  private static hungarianCollation: { [key: string]: number } = {
    'a': 1, 'á': 2, 'b': 3, 'c': 4, 'cs': 5, 'd': 6, 'dz': 7, 'dzs': 8, 'e': 9, 'é': 10,
    'f': 11, 'g': 12, 'gy': 13, 'h': 14, 'i': 15, 'í': 16, 'j': 17, 'k': 18, 'l': 19,
    'ly': 20, 'm': 21, 'n': 22, 'ny': 23, 'o': 24, 'ó': 25, 'ö': 26, 'ő': 27, 'p': 28,
    'q': 29, 'r': 30, 's': 31, 'sz': 32, 't': 33, 'ty': 34, 'u': 35, 'ú': 36, 'ü': 37,
    'ű': 38, 'v': 39, 'w': 40, 'x': 41, 'y': 42, 'z': 43, 'zs': 44
  };

  /**
   * Compare two lower cased string alphabetically, based on the Hungarian alphabet.
   *
   * @param a one of the compared string
   * @param b other compared string
   * @return the result of the comparison
   */
  public static compareHungarian(a: string, b: string): number {
    const minLength = Math.min(a.length, b.length);

    for (let i = 0; i < minLength; i++) {
      const charA = a[i].toLowerCase();
      const charB = b[i].toLowerCase();

      if (this.hungarianCollation[charA] !== undefined && this.hungarianCollation[charB] !== undefined) {
        if (this.hungarianCollation[charA] < this.hungarianCollation[charB]) {
          return -1;
        } else if (this.hungarianCollation[charA] > this.hungarianCollation[charB]) {
          return 1;
        }
      } else {
        // If characters are not in the Hungarian collation table, use default comparison
        if (charA < charB) {
          return -1;
        } else if (charA > charB) {
          return 1;
        }
      }
    }

    // If strings are equal so far, the shorter string should come first
    return a.length - b.length;
  }

  public static compareHungarianFunc: (a: string, b: string) => number = (a: string, b: string) => SortUtil.compareHungarian(a, b);

  public static sortField(order: number, field: string, data1: object, data2: object): number {
    let result;

    let value1 = data1[field];
    let value2 = data2[field];

    if (value1 == null && value2 != null) {
      result = -1;
    } else if (value1 != null && value2 == null) {
      result = 1;
    } else if (value1 == null && value2 == null) {
      result = 0;
    } else if (typeof value1 === 'string' && typeof value2 === 'string') {
      result = value1.localeCompare(value2);
    } else {
      result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
    }

    return order * result;
  }

  public static sortDate(field1: Date, field2: Date): number {
    return (field1 < field2) ? -1 : (field1 > field2) ? 1 : 0;
  }
}
