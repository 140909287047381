import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {ValueStats} from '../../shared/dto/value.stats.model';
import {DiagramService} from '../../shared/services/diagram.service';
import {NgBDatePickerConvertService} from '../../shared/services/ngb.datepicker.convert.service';
import {PodValueService} from '../../shared/services/pod-value.service';
import {FilterModel} from '../filter-basic/filter.model';
import moment from "moment-timezone";
import {
    ConsumptionDynamicFilterService,
    FilterEvent
} from '../consumption-dynamic-filter/consumption-dynamic-filter.service';
import {Subscription} from 'rxjs';
import {Chart} from "angular-highcharts";

@Component({
    selector: 'jhi-min-max-avarage',
    templateUrl: './min-max-avarage.component.html',
    styles: []
})
export class MinMaxAvarageComponent implements OnInit {

    @Input() widget: boolean;
    ranges: number[][];
    averages: any[];
    chart1: Chart;
    filterModel: FilterModel;
    loading: boolean;
    maxPerformance: number;
    readonly defaultTickAmount = 8;
    private dynamicFilterChangeSub: Subscription;

    constructor(private podValueService: PodValueService,
                private dateConverter: NgBDatePickerConvertService,
                private diagramService: DiagramService,
                public translateService: TranslateService,
                public consumptionDynamicFilterService: ConsumptionDynamicFilterService) {

    }

    ngOnInit() {
        if (!this.widget) {
            this.chart1 = this.diagramService.createDummyChart();
        }
        this.listenDynamicFilterChange();
    }

    getReportParametersForRun(filterModel: FilterModel) {
        this.filterModel = filterModel;
        this.loading = true;
        this.chart1 = new Chart({});
        this.ranges = [];
        this.averages = [];

        let apiParams = this.podValueService.createApiParams(filterModel);

        this.podValueService.getMinMaxData(apiParams)
            .pipe(finalize(() => this.loading = false))
            .subscribe(minMaxData => {
                this.ranges = [];
                this.averages = [];
                minMaxData.minimumKW.forEach((value, index) => {
                    this.ranges.push([value, minMaxData.maximumKw[index]]);
                    this.averages.push(minMaxData.averageKW[index]);
                });
                let chartData: any[] = [];
                this.diagramService.createLegendText(filterModel).subscribe(legendItemText => {
                    this.calculateChart(minMaxData.valueStats, legendItemText, chartData);
                });
            });

    }

    calculateChart(valueStats: ValueStats, legendItemText: string, chartData: any[]) {
        let plotLinesAndData = this.diagramService.createMinMaxAverageDiagramPlotLines(valueStats);
        const startTime = this.dateConverter.convertToDate(this.filterModel.periodParameters[0].dateFrom);
        this.markPartialValues(valueStats);
        this.maxPerformance = valueStats.max;


        chartData.push(...[{
            name: legendItemText,
            pointStart: moment(startTime).valueOf(),
            pointInterval: this.diagramService.getPointInterval(this.filterModel.resolution),
            pointIntervalUnit: this.diagramService.getPointIntervalUnit(this.filterModel.resolution),
            data: this.averages,
            zIndex: 1,
            color: '#f24f00',
            tooltip: {valueSuffix: ' kW'}
        }, {
            name: 'Range',
            pointStart: moment(startTime).valueOf(),
            pointInterval: this.diagramService.getPointInterval(this.filterModel.resolution),
            pointIntervalUnit: this.diagramService.getPointIntervalUnit(this.filterModel.resolution),
            data: this.ranges,
            type: 'arearange',
            lineWidth: 0,
            linkedTo: ':previous',
            color: '#ff884d',
            fillOpacity: 0.3,
            zIndex: 0,
            tooltip: {valueSuffix: ' kW'}
        }]);

        chartData = chartData.concat(plotLinesAndData.map(r => r.chartData));

        if (valueStats.partialLastInterval || valueStats.partialFirstInterval)
            chartData = chartData.concat({
                name: this.translateService.instant('consumption.partialValue'),
                type: 'line',
                color: 'grey',
                dashStyle: 'Dot',
                marker: {symbol: 'circle'},
                events: {
                    legendItemClick: function (e) {
                        return false;
                    }
                }
            });

        if (this.filterModel.temperatureChecked)
            this.diagramService.calculateTemperatureSeries(this.filterModel, chartData).subscribe(r => {
                chartData[chartData.length - 1].tooltip = {valueSuffix: ' °C'};
                this.displayChart(chartData, plotLinesAndData);
            });
        else
            this.displayChart(chartData, plotLinesAndData);
    }

    displayChart(chartData: any[], plotLinesAndData: any[]) {
        let chartCategories = this.diagramService.GenerateDateForDiagramData(chartData[0].data,
            this.dateConverter.convertToDate(this.filterModel.periodParameters[0].dateFrom).toISOString(),
            this.filterModel.resolution).map(cat => cat[0]);
        let chartCategoriesInTime = chartCategories.concat().map(r => {
            let date = new Date(r);
            date.setHours(0, 0, 0, 0);
            return date.getTime();
        });
        let translateService = this.translateService;
        let diagramService = this.diagramService;

        this.diagramService.createTitle(this.filterModel).subscribe(title => {
            this.chart1 = new Chart({
                title: {
                    text: this.widget ? '' : title,
                    style: {
                        fontSize: this.widget ? '12px' : '18px'
                    }
                },
                chart: {
                    zooming: {
                      type: 'xy'
                    },
                    height: this.widget ? null : '642.66px',
                    style: {
                        fontFamily: '\'Nimbus\''
                    },
                    events: this.diagramService.getWatermark(this.widget),
                    resetZoomButton: {
                        theme: {
                            style: 'opacity: 0.3',
                            states: {
                                hover: {
                                    style: 'opacity: 1'
                                }
                            }
                        }
                    }
                },
                xAxis: {
                    type: 'datetime',
                    title: {
                        text: ''
                    },
                    crosshair: true,
                    labels: {
                        align: 'right',
                        rotation: -45,
                        formatter: function () {
                            return moment(new Date(this.value)).format('l');
                        }
                    },
                    plotBands: this.diagramService.createPlotBands(this.filterModel)
                },

                yAxis: [{
                    max: Math.ceil(this.maxPerformance),
                    endOnTick: false,
                    tickAmount: this.defaultTickAmount,
                    title: {
                        text: 'kW'
                    },
                    crosshair: true,
                    labels: {
                        format: '{value:,.0f}'
                    },
                    plotLines: plotLinesAndData.map(r => r.plotLine)
                },
                    { // Secondary yAxis
                        tickAmount: this.defaultTickAmount,
                        gridLineWidth: 0,
                        labels: {
                            format: '{value}°C',
                            style: {
                                color: '#000000'
                            }
                        },
                        title: {
                            text: this.filterModel.temperatureChecked ? this.translateService.instant('filter.basic.temperature') : '',
                            style: {
                                color: '#000000'
                            }
                        },
                        crosshair: true,
                        opposite: true

                    }],
                lang: {
                    decimalPoint: ','
                },
                tooltip: {
                    useHTML: true,
                    shared: true,
                    formatter: function () {
                        return diagramService.getMinMaxToolTip(this);
                    }
                },
                credits: {
                    enabled: false
                },

                legend: {
                    verticalAlign: 'bottom',
                    layout: 'horizontal',
                    enabled: !this.widget
                },
                exporting: {
                    filename: title.replace(/\//ig, '_'),
                    buttons: {
                        contextButton: {
                            align: 'left',
                            x: -10,
                            y: -10
                        }
                    },
                    sourceWidth: 1180,
                    sourceHeight: 642,
                    scale: 1,
                    chartOptions: {
                        title: {
                            style: {
                                fontSize: '12px'
                            }
                        },
                        legend: {
                            enabled: true,
                            itemWidth: 400,
                            itemStyle: {
                                fontSize: '10px'
                            }
                        },
                        xAxis: {
                            labels: {
                                style: {
                                    fontSize: '10px'
                                }
                            },
                            title: {
                                style: {
                                    fontSize: '10px'
                                }
                            }
                        },
                        yAxis: [{
                            max: Math.ceil(this.maxPerformance),
                            endOnTick: false,
                            tickAmount: this.defaultTickAmount,
                            title: {
                                text: 'kW',
                                style: {
                                    fontSize: '10px'
                                }
                            },
                            crosshair: true,
                            plotLines: plotLinesAndData.map(r => r.plotLine),
                            labels: {
                                style: {
                                    fontSize: '10px'
                                }
                            }
                        }, { // Secondary yAxis
                            tickAmount: this.defaultTickAmount,
                            gridLineWidth: 0,
                            labels: {
                                format: '{value}°C',
                                style: {
                                    color: '#000000',
                                    fontSize: '10px'
                                }
                            },
                            crosshair: true,
                            title: {
                                text: this.filterModel.temperatureChecked ? this.translateService.instant('filter.basic.temperature') : '',
                                style: {
                                    color: '#000000',
                                    fontSize: '10px'
                                }
                            },
                            opposite: true

                        }]
                    }
                },

                series: chartData
            });
            this.diagramService.setVisiblePlot(this.chart1, 'min', this.filterModel.minSelected);
            this.diagramService.setVisiblePlot(this.chart1, 'max', this.filterModel.maxSelected);
            this.diagramService.setVisiblePlot(this.chart1, 'avg', this.filterModel.avgSelected);
        });
    }

    markPartialValues(valueStats: ValueStats) {
        if (valueStats.partialFirstInterval)
            this.averages[0] = {color: 'grey', y: this.averages[0]};

        if (valueStats.partialLastInterval) {
            let lastIndex = this.averages.length - 1;
            this.averages[lastIndex] = {color: 'grey', y: this.averages[lastIndex]};
        }
    }

    private listenDynamicFilterChange(): void {
        this.dynamicFilterChangeSub = this.consumptionDynamicFilterService.filterChangedSubject.subscribe((event: FilterEvent) => {
            if (event.type == 'min' || event.type == 'max' || event.type == 'avg') {
                this.diagramService.setVisiblePlot(this.chart1, event.type, event.value);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.dynamicFilterChangeSub) {
            this.dynamicFilterChangeSub.unsubscribe();
        }
    }
}
