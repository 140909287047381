import {TimeSeries} from '../../shared/time-series/model/timeseries';
import {Moment} from 'moment';

export type ExpectedPlanType =
    'SUM_YEARLY'
    |'SUM_MONTHLY'
    |'EXPECTED_PLAN_MONTHS';

export const ExpectedPlanType = {
    SUM_YEARLY: 'SUM_YEARLY' as ExpectedPlanType,
    SUM_MONTHLY: 'SUM_MONTHLY' as ExpectedPlanType,
    EXPECTED_PLAN_MONTHS: 'EXPECTED_PLAN_MONTHS' as ExpectedPlanType
};

export class HedgeConsumptionPlanResponse {
    monthlySum: number[];
    timeSeries?: TimeSeries[];
}

export class FactHedgeConsumptionSumRequest {
    deliveryPeriodId: number;
    start: Moment;
    end: Moment;
    agreementId: number;
}

export class FactHedgeConsumptionSumResponse {
    deliveryPeriodId: number;
    sum: number;
}
