export const colors = [
    // '#FFE0D1',
    '#F77F45',
    '#737373',
    '#BF3E00',
    '#B8B3AD',
    '#F24F00',
    '#8C8C8C',
    '#E6E6E6'
];
