import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'deadline-selector',
    templateUrl: 'deadline-selector.component.html',
    styleUrls: ['deadline-selector.component.scss']
})
export class DeadlineSelectorComponent implements OnInit {

    public static TIME_SEC_PATTERN: RegExp = /^(?:[0-5]\d):[0-5]\d$/;

    @Input() minuteRange: number = 30;
    @Input() step: number = 1;
    @Input() valueChange: (deadline: string) => void;
    @Input() disabled: boolean = false;
    @Input() defaultValue;

    public deadline: string;
    public deadlineOptions: string[] = [];

    constructor() {
        this.initDeadlineDropdownList();
    }

    ngOnInit(): void {
        if (!!this.defaultValue) {
            this.deadline = this.defaultValue;
        }
    }

    public reset(): void {
        this.selectDeadLine(null);
    }

    public selectDeadLine(minSec: string | null): void {
        const deadline: string = !!minSec ? minSec.split(':').join('') : minSec;
        this.deadline = deadline;
        this.valueChange(deadline);
    }

    public focusDeadline(input: HTMLInputElement): void {
        setTimeout(() => input.focus());
    }

    public changeEvent(event: string): void {
        const minSec: string = !!event && event.length === 4 ? `${event.slice(0, 2)}:${event.slice(2, 4)}` : event;
        this.selectDeadLine(DeadlineSelectorComponent.TIME_SEC_PATTERN.test(minSec) ? minSec : null);
    }

    private initDeadlineDropdownList(): void {
        for (let i: number = 1; i <= this.minuteRange; i += this.step) {
            this.deadlineOptions.push(_.padStart(i.toString(), 2, '0') + ":00")
        }
    }
}
