<header class="docs clearfix">
    <aside>
        <input [(ngModel)]="filterText" (keyup)="filter($event)" type="text"
               placeholder="{{'global.search' | translate}}"
        />
    </aside>
    <div>
        <span *ngFor="let tag of tagCloud" role="button" class="badge badge-secondary mr-1 mb-2"
              (click)="getNewsByTag(tag.id)">{{tag.name}}</span>
    </div>
</header>

<section class="newsList">
    <article *ngFor="let article of news" [@shrinkOut]="article.state" class="tile" (click)="openNews(article)"
             style="overflow: hidden">
        <div class="col-xl-4  text-center">
            <img class="medium" src="{{newsService.getPicture(article.picture)}}" alt="">
        </div>
        <div class="col-xl-8 text-left">
            <div>
                <header>
                    <h4>{{article.title}}
                        <span class="flag-en" *ngIf="showEngFlag(article)"></span>
                    </h4>
                    <h6>{{article.releaseTime | cezDate}}</h6>
                </header>
                <p> {{article.summary || newsService.trimNewsText(article.text)}} </p>
            </div>

            <footer>
                <a class="link" role="button" (click)="openNews(article)">{{'home.readMore' | translate}}</a>
            </footer>
        </div>
    </article>
</section>

<div class="row" *ngIf="showLoadMore">
    <div class="pull-center">
        <button class="btn cez-orange topSticky" (click)="loadMore()" role="button" [disabled]="noMoreNews()"
                jhiTranslate="home.loadMoreNews">
            <span>További hírek</span>
        </button>
    </div>
</div>
