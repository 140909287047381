<ngx-loading class="local-loader"
             [show]="isLoading"
             [config]="{fullScreenBackdrop: false}"></ngx-loading>

<hr class="mb-3"/>

<div *ngIf="beforeTradeShow" [ngClass]="afterTradeShow ? 'mb-5' : ''">
    <aggregation-table-content [productType]="aggregationTableData.type"
                               [tableType]="AggregationTableType.BEFORE_TRADE"
                               [quantities]="aggregationTableData.beforeTrade"></aggregation-table-content>
</div>

<div *ngIf="afterTradeShow">
    <aggregation-table-content [productType]="aggregationTableData.type"
                               [tableType]="AggregationTableType.AFTER_TRADE"
                               [quantities]="aggregationTableData.afterTrade"
                               [otherQuantities]="aggregationTableData.beforeTrade"></aggregation-table-content>
</div>
