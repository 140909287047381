import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Session } from './session.model';

@Injectable()
export class SessionsService {

    private resourceUrl = 'api/account/sessions/';

    constructor(private http: HttpClient) {
    }

    findAll(): Observable<Session[]> {
        return this.http.get<Session[]>(this.resourceUrl);
    }

    delete(series: string): Observable<any> {
        return this.http.delete(`${this.resourceUrl}${series}`);
    }
}
