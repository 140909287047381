import { Route } from '@angular/router';

import { TwofactorCodeActivateComponent } from './twofactor-code-activate.component';
import {UserRouteAccessService} from '../../shared';

export const TWO_FACTOR_CODE_ACTIVATE_ROUTE: Route[] = [
    {
        path: 'twofactorauth/activate',
        component: TwofactorCodeActivateComponent,
        data: {
            pageTitle: 'two-factor-auth.activate-title'
        },
        canActivate: [UserRouteAccessService]
    }];
