import { Injectable } from '@angular/core';
import { AsyncValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TimeSeriesService } from '../time-series.service';
import { TimeInterval } from './time-interval.model';


@Injectable()
export class TimeIntervalValidators {

    constructor(private _service: TimeSeriesService) {
    }


    timeIntervalValidator(): AsyncValidatorFn {
        return (c: UntypedFormGroup): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
            const interval: TimeInterval = c.value;

            if (interval && interval.resolution && interval.start && interval.end) {
                return this._service.check(interval).pipe(
                    map(() => {
                        return null;
                    }),
                    catchError(error => {
                        return of({error: 'TIME_INTERVAL_NOT_VALID'});
                    })
                );
            } else {
                return of({error: 'TIME_INTERVAL_NOT_VALID'});
            }
        };
    }

}
