import {DocumentModel} from './document.model';

export class DocumentByCategoryModel {
    categoryId: number;
    categoryName: string;
    categoryOrderNum: number;
    title: string;
    subCategories: Array<DocumentByCategoryModel>;
    show: boolean = true;
    contentList: Array<DocumentModel>;
    expand: boolean = false;
}
