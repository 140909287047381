import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import {MarketPriceService} from '../shared/services/market-price.service';

@Injectable({
    providedIn: 'root'
})
export class MarketPriceGuardService  {

    constructor(
        private marketPriceService: MarketPriceService
    ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any[]> {

        return forkJoin(
            this.marketPriceService.listProducts(),
            this.marketPriceService.listAdditionalTimeSeries(),
            this.marketPriceService.listSpotTimeSeries()
        );
    }
}
