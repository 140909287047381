<div class="mt-3">
    <jhi-chip-list-autocomplete [placeholder]="'invoiceReport.filter.placeholder.resources' | translate"
                                [items]="selectableResourceList"
                                [selectedItems]="parametersList.resources"
                                [nameFunction]="resourceNameFunction()"
                                (selectionChanged)="resourceSelectionChanged($event)">
    </jhi-chip-list-autocomplete>

    <jhi-chip-list-autocomplete *ngIf="podSelectorVisible()"
                                [placeholder]="'invoiceReport.filter.placeholder.pods' | translate"
                                [items]="selectablePodList"
                                [selectedItems]="parametersList.pods"
                                [nameFunction]="podNameFunction()"
                                (selectionChanged)="podSelectionChanged($event)">
    </jhi-chip-list-autocomplete>

    <jhi-chip-list-autocomplete *ngIf="podSelectorVisible()"
                                [placeholder]="'invoiceReport.filter.placeholder.podGroups' | translate"
                                [items]="selectablePodGroupList"
                                [selectedItems]="parametersList.podGroups"
                                [nameFunction]="podGroupNameFunction()"
                                (selectionChanged)="podGroupSelectionChanged($event)">
    </jhi-chip-list-autocomplete>

    <mat-form-field [hidden]="!resolutionSelectorVisible()" class="wide mr-2">
        <mat-select #res
                    placeholder="{{'invoiceReport.filter.placeholder.resolutions' | translate}}"
                    [formControl]="resolutionControl">
            <mat-option [value]="resolutions.MONTHLY">
                {{'global.resolutions.' + resolutions.MONTHLY | translate}}
            </mat-option>
            <mat-option [value]="resolutions.QUARTER_YEARLY">
                {{'global.resolutions.' + resolutions.QUARTER_YEARLY | translate}}
            </mat-option>
            <mat-option [value]="resolutions.YEARLY">
                {{'global.resolutions.' + resolutions.YEARLY | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field [hidden]="!unitCostSelectorVisible()" class="wide">
        <mat-select #uc
                    placeholder="{{'invoiceReport.filter.placeholder.unitCost' | translate}}"
                    (selectionChange)="unitCostSelectionChanged($event)">
            <mat-option [value]="false">{{'invoiceReport.unitCostSelector.false' | translate}}</mat-option>
            <mat-option [value]="true">{{'invoiceReport.unitCostSelector.true' | translate}}</mat-option>
        </mat-select>
    </mat-form-field>


    <jhi-dynamic-date [intervalList]="parametersList.intervals"
                      (intervalListEvent)="dateIntervalList($event)" [upperBound]="datesUpperBound"></jhi-dynamic-date>
</div>





