import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';

import {ActivatedRoute} from '@angular/router';
import {Observable} from "rxjs";
import {AgreementModel} from '../shared/dto/agreement.model';
import {CanLeave, CanLeaveService} from '../shared/can-leave/can-leave.service';
import {AgreementListComponent} from '../shared/agreement-list/agreement-list.component';
import {AgreementDetailsComponent} from '../shared/agreement-details/agreement-details.component';
import {StateStorageService} from '../shared/auth';
import {NgBDatePickerConvertService} from '../shared/services/ngb.datepicker.convert.service';
import {PartnerService} from '../shared/services/partner.service';
import {BootstrapAlertService} from '../shared/services/bootstrap-alert.service';


@Component({
    selector: 'jhi-contract-user',
    templateUrl: './agreement-user.component.html',
    styles: []
})
export class AgreementUserComponent implements OnInit, AfterViewInit, CanLeave {

    agreement: AgreementModel = new AgreementModel();
    editMode: boolean;
    addNewTemplate: boolean;
    controlsState: { [name: string]: boolean } = {};
    tempIdCounter: number = -1;
    saveButtonVisible: boolean = true;
    @ViewChild(AgreementListComponent, {static: true}) agreementListComponent: AgreementListComponent;
    @ViewChild(AgreementDetailsComponent, {static: false}) agreementDetailsComponent: AgreementDetailsComponent;

    constructor(
        private stateStorageService: StateStorageService,
        private http: HttpClient,
        private ngBDatePickerConvertService: NgBDatePickerConvertService,
        private partnerService: PartnerService,
        private bootstrapAlertService: BootstrapAlertService,
        private activatedRoute: ActivatedRoute,
        private canLeaveService: CanLeaveService) {

    }

    ngAfterViewInit() {
        this.refresh();
        this.editAgreement();
    }

    ngOnInit() {
    }

    canLeave(): Observable<boolean> {
        return this.canLeaveService.canLeave();
    }

    view(model: AgreementModel, tabId?: string) {
        this.agreement = model;
        this.editMode = true;
        if (!!tabId) {
            setTimeout(() => this.agreementDetailsComponent.navInner?.select(tabId));
        }
    }

    refresh() {
        let companyId = this.stateStorageService.getSelectedCompanyId();
        this.partnerService.get(companyId).subscribe(partner =>
            this.agreementListComponent.refreshAgreementList(partner));
    }

    cancel() {
        this.canLeaveService.canLeave()
            .subscribe((value) => {
                if (value) {
                    this.cancelConfirmed();
                }
            });
    }



    private cancelConfirmed() {
        this.editMode = false;
        this.canLeaveService.setModified(false);
    }

    submit() {

        let agreementCopy = JSON.parse(JSON.stringify(this.agreement));

        this.discoverChanges(agreementCopy, this.agreement, []);
        agreementCopy.id = this.agreement.id;

        this.http.put('/api/agreement/update_request', agreementCopy).subscribe(() => {
            this.bootstrapAlertService.showSuccess('agreement.UserModifyMessage.success');
        });

        this.controlsState = {};

        this.canLeaveService.setModified(false);
    }

    discoverChanges(object: Object, originalObject: Object, parentObjects: string[]) {
        let properties = Object.keys(object);

        for (let property of properties) {
            if (property.indexOf('NgB') > 0)
                continue;

            if (object[property] instanceof Object) {
                parentObjects.push(property);
                this.discoverChanges(object[property], originalObject[property], parentObjects);
                parentObjects.splice(parentObjects.length - 1, 1);
                continue;
            }

            let controlKey = parentObjects.length
                ? parentObjects.join('.') + '.' + property
                : property;

            if (controlKey.indexOf('partner.contacts') !== -1 && this.controlsState['partner.contacts'])
                continue;

            let propertyModified = this.controlsState[controlKey];

            if (!propertyModified) {
                object[property] = null;
                continue;
            }


            if (propertyModified && object.hasOwnProperty(property + 'NgB')) {
                object[property] = this.ngBDatePickerConvertService.convertToDate(object[property + 'NgB']);
            }
        }
    }

    hasDirtyData() {
        return Object.keys(this.controlsState).length > 0;
    }

    isAgreementInValid() {
        if (!this.agreementDetailsComponent || !this.agreementDetailsComponent.partnerDetailsComponent)
            return false;

        return this.agreementDetailsComponent.servicesForm.invalid ||
            this.agreementDetailsComponent.form.invalid ||
            this.agreementDetailsComponent.partnerDetailsComponent.form.invalid;
    }

    setSaveButtonVisible(tabId: string) {
        let tabsWhereUserCanSave = ['1', '2'];
        this.saveButtonVisible = this.agreementDetailsComponent &&
            this.agreementDetailsComponent.navInner &&
            tabsWhereUserCanSave.some(r => r === tabId);
    }

    private editAgreement(): void {
        const agreementId: string | null = this.activatedRoute.snapshot.paramMap.get('id');
        if (!!agreementId) {
            this.agreementListComponent.dataReceived.subscribe((data: AgreementModel[]) => {
                const selectedAgreement: AgreementModel = data.find((agreement: AgreementModel) =>
                    agreement.id === Number(agreementId));
                this.view(selectedAgreement, 'deliveryPeriods');
            });
        }
    }
}
