import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { NewsComponent } from './news.component';

import { NewsModalComponent } from './news.modal.component';
import { NEWS_ROUTE } from './news.route';
import {CezPortalSharedModule} from '../shared';


@NgModule({
    imports: [
        CezPortalSharedModule,
        RouterModule.forRoot(NEWS_ROUTE, { useHash: true }),
        BrowserAnimationsModule
    ],
    declarations: [
        NewsComponent, NewsModalComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NewsModule { }
