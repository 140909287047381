<div class="row" [formGroup]="form">
    <div class="col">
        <mat-form-field>
            <input matInput [matDatepicker]="fromPicker"
                   placeholder="{{'marketPrices.display-mode.datepicker.from' | translate }}"
                   formControlName="from"
                   intervalRestrictor
                   [min]="min"
                   [max]="max"
                   required>
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col">
        <mat-form-field>
            <input matInput [matDatepicker]="toPicker"
                   placeholder="{{'marketPrices.display-mode.datepicker.to' | translate }}"
                   formControlName="to"
                   intervalRestrictor
                   [min]="min"
                   [max]="max"
                   required>
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col">
        <mat-form-field>
            <mat-select
                placeholder="{{'marketPrices.display-mode.resolution' | translate }}"
                formControlName="resolution"
                required>
                <mat-option *ngFor="let entry of timeSeriesResolutions | keys" [value]="entry.value">
                    {{'TimeSeriesResolution.' + entry.value | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>



