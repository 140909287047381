import { Directive, Input, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { MonthlyDateIntervalComponent } from '../monthly-date-interval/monthly-date-interval.component';
import { Moment } from 'moment';
import { Resolution } from '../time-series/model/resolution.enum';
import { MonthlyDatePickerComponent } from '../monthly-date-picker/monthly-date-picker.component';
import { StateStorageService } from '../auth';
import { HistoricalDataUtil } from './historical-data.util';
import {MatDatepickerInput} from "@angular/material/datepicker";

@Directive({
    selector: '[intervalRestrictor]'
})
export class IntervalRestrictorDirective implements OnChanges, OnInit {

    @Input() min: Moment;
    @Input() intervalRestrictor: Resolution;

    private host: any;

    constructor(
        @Optional() @Self() private monthlyDateIntervalComponent: MonthlyDateIntervalComponent,
        @Optional() @Self() private monthlyDatePickerComponent: MonthlyDatePickerComponent,
        @Optional() @Self() private matDatepickerInput: MatDatepickerInput<Moment>,
        private stateStorageService: StateStorageService
    ) {
        if (this.monthlyDateIntervalComponent) {
            this.intervalRestrictor = this.intervalRestrictor ? this.intervalRestrictor : Resolution.MONTHLY;
            this.host = this.monthlyDateIntervalComponent;
        } else if (this.monthlyDatePickerComponent) {
            this.intervalRestrictor = this.intervalRestrictor ? this.intervalRestrictor : Resolution.MONTHLY;
            this.host = this.monthlyDatePickerComponent;
        } else if (this.matDatepickerInput) {
            this.intervalRestrictor = this.intervalRestrictor ? this.intervalRestrictor : Resolution.DAILY;
            this.host = this.matDatepickerInput;
        } else {
            throw new Error('IntervalRestrictorDirective is used on an unknown host element!');
        }
    }

    ngOnInit(): void {
        this.changeMinOnHost();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('min')) {
            this.changeMinOnHost();
        }
    }

    private changeMinOnHost() {
        const originalMin = this.host.min;
        const historicalMin = HistoricalDataUtil.getHistoricalMin(this.stateStorageService.getEffectivePermissions(), this.intervalRestrictor);
        if (!originalMin) {
            this.host.min = historicalMin;
        } else if (!historicalMin) {
            this.host.min = originalMin;
        } else {
            this.host.min = historicalMin.isAfter(originalMin) ? historicalMin : originalMin;
        }
    }
}
