export type Language = 'hu'|'en';
export const Language = {
    hu: 'hu' as Language,
    en: 'en' as Language
};

export type TariffType = 'SINGLE'|'DOUBLE'|'MULTIPLE';
export const TariffType = {
    SINGLE: 'SINGLE' as TariffType,
    DOUBLE: 'DOUBLE' as TariffType,
    MULTIPLE: 'MULTIPLE' as TariffType
};

export type ToleranceSettlementType = 'TAKE_OR_PAY'|'MARK_TO_MARKET'|'SPECIAL';
export const ToleranceSettlementType = {
    TAKE_OR_PAY: 'TAKE_OR_PAY' as ToleranceSettlementType,
    MARK_TO_MARKET: 'MARK_TO_MARKET' as ToleranceSettlementType,
    SPECIAL: 'SPECIAL' as ToleranceSettlementType
};

export type ProductType = 'FIXED_PRICE'|'STEP_BY_STEP'|'CASH_OUT'|'MULTI_SOURCE';
export const ProductType = {
    FIXED_PRICE: 'FIXED_PRICE' as ProductType,
    STEP_BY_STEP: 'STEP_BY_STEP' as ProductType,
    CASH_OUT: 'CASH_OUT' as ProductType,
    MULTI_SOURCE: 'MULTI_SOURCE' as ProductType
};

export type Scheduling = 'NO'|'FLOATING_BALANCING'|'SCHEDULING_BONUS';
export const Scheduling = {
    NO: 'NO' as Scheduling,
    FLOATING_BALANCING: 'FLOATING_BALANCING' as Scheduling,
    SCHEDULING_BONUS: 'SCHEDULING_BONUS' as Scheduling
};

export type CashoutChartType = 'CAPACITY'|'CONSUMPTION';
export const CashoutChartType = {
    CAPACITY: 'CAPACITY' as CashoutChartType,
    CONSUMPTION: 'CONSUMPTION' as CashoutChartType
};

export type SchedulingSettlementType = 'BONUS'|'MALUS'|'BONUS_MALUS';
export const SchedulingSettlementType = {
    BONUS: 'BONUS' as SchedulingSettlementType,
    MALUS: 'MALUS' as SchedulingSettlementType,
    BONUS_MALUS: 'BONUS_MALUS' as SchedulingSettlementType
};

export type ClosingProductType = 'Y'|'Q'|'M'|'SPOT';
export const ClosingProductType = {
    Y: 'Y' as ClosingProductType,
    Q: 'Q' as ClosingProductType,
    M: 'M' as ClosingProductType,
    SPOT: 'SPOT' as ClosingProductType
};
