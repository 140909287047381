import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { Language } from '../../../shared/agreement-details/agreement-enums.model';
import { CustomFile, CustomFileType } from '../../../shared/dto/custom-file.model';
import { CustomFileService } from '../../../shared/services/custom-file.service';

@Component({
    selector: 'jhi-edit-dialog',
    templateUrl: './custom-file-edit-dialog.component.html',
    styleUrls: ['./custom-file-edit-dialog.component.scss']
})
export class CustomFileEditDialogComponent {

    types = CustomFileType;
    form: UntypedFormGroup;
    languages = Language;

    constructor(@Inject(MAT_DIALOG_DATA) private data: CustomFile,
                private dialogRef: MatDialogRef<CustomFileEditDialogComponent>,
                private service: CustomFileService,
                private fb: UntypedFormBuilder) {

        this.form = fb.group({
            type: [data.type, Validators.required],
            note: [data.note],
            language: [data.language]
        });

    }

    save() {
        this.service.updateFileMeta(Object.assign({}, this.data, this.form.value)).subscribe(() => {
            this.dialogRef.close(true);
        }, error => {
            console.error(error);
        });
    }

}
