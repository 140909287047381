export type InvoiceResourceDistributionProperty =
    'DISTRIBUTABLE' |
    'NOT_DISTRIBUTABLE' |
    'HIDDEN';

export const InvoiceResourceDistributionProperty = {
    DISTRIBUTABLE: 'DISTRIBUTABLE' as InvoiceResourceDistributionProperty,
    NOT_DISTRIBUTABLE: 'NOT_DISTRIBUTABLE' as InvoiceResourceDistributionProperty,
    HIDDEN: 'HIDDEN' as InvoiceResourceDistributionProperty
};
