import { LinkModel } from './index';

export class LinkByCategoryModel {
    id: number;
    name: string;
    parentCategory: LinkByCategoryModel;
    show: boolean = true;
    links: Array<LinkModel>;

    constructor(name: string, links: Array<LinkModel>) {
        this.name = name;
        this.links = links;
    }
}
