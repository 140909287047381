import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Chart} from 'angular-highcharts';
import { finalize } from 'rxjs/operators';
import { DiagramService } from '../../shared/services/diagram.service';
import { NgBDatePickerConvertService } from '../../shared/services/ngb.datepicker.convert.service';
import { PodValueService } from '../../shared/services/pod-value.service';
import { FilterModel } from '../filter-basic/filter.model';
import {ConsumptionDiagramType} from "../../consumption/consumption-diagram-type";
import {
    ConsumptionDynamicFilterService,
    FilterEvent
} from '../consumption-dynamic-filter/consumption-dynamic-filter.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'jhi-diagram-column',
    templateUrl: './diagram-column.component.html',
    styles: []
})
export class DiagramColumnComponent implements OnInit, OnDestroy {

    @Input() widget: boolean;
    chartData: any[];
    performanceRunChart: Chart;
    diagramId: number;
    filterModel: FilterModel;
    loading: boolean;
    sortedDataWithOriginalIndexes = [];
    maxPerformance: number;
    readonly defaultTickAmount = 8;
    private dynamicFilterChangeSub: Subscription;

    constructor(private dateConverter: NgBDatePickerConvertService,
                private podValueService: PodValueService,
                private diagramService: DiagramService,
                public translateService: TranslateService,
                public consumptionDynamicFilterService: ConsumptionDynamicFilterService
    ) {
    }

    ngOnInit() {
        if (!this.widget) {
            this.performanceRunChart = this.diagramService.createDummyChart();
        }
        this.listenDynamicFilterChange();
    }

    getReportParametersForRun(filterModel: FilterModel) {
        this.filterModel = filterModel;
        this.loading = true;
        this.diagramId = filterModel.selectedDiagram.id;
        this.chartData = [];
        this.sortedDataWithOriginalIndexes = [];
        let apiParams = this.diagramService.createApiParamsFromFilter(this.filterModel, 0);

        if (this.filterModel.periodParameters.filter(r => r.dateTo).length > 1) {
            this.diagramService.calculateMultiplePeriodChartData(filterModel)
                .pipe(finalize(() => this.loading = false))
                .subscribe(r => {
                    let sortedChartData = r.chartData.map(series => {
                        let seriesData = {
                            name: series.name,
                            color: series.color,
                            lineWidth: 2,
                            valuesKW: series.data,
                            resolution: filterModel.resolution,
                            startTime: series.dateFrom,
                            valueStats: series.valueStats
                        };

                        this.diagramService.sortDataMarkPartial(seriesData, this.chartData, this.sortedDataWithOriginalIndexes);
                    });
                    this.maxPerformance = r.chartData.reduce((max, curr) => max > curr.valueStats.max ? max : curr.valueStats.max, 0);
                    this.calculateChart(filterModel.resolution);
                });
        } else {
            if (filterModel.comparePods) {
                let legendNames = filterModel.selectedPods.map(r => r.podCode).concat(filterModel.selectedPodGroups.map(r => r.name));
                this.diagramService.calculateComparedChartData(apiParams, legendNames)
                    .pipe(finalize(() => this.loading = false))
                    .subscribe(r => {
                        this.performanceRunChart = new Chart({});
                        let sortedChartData = r.map(series => {
                            let seriesData = {
                                name: series.name,
                                color: series.color,
                                lineWidth: 2,
                                valuesKW: series.data.map(keyValue => {
                                    if (keyValue) {
                                        return keyValue;
                                    } else return null;
                                }),
                                resolution: apiParams.resolution,
                                startTime: apiParams.startTime,
                                valueStats: series.valueStats
                            };
                            this.diagramService.sortDataMarkPartial(seriesData, this.chartData, this.sortedDataWithOriginalIndexes);
                        });
                        this.maxPerformance = r.reduce((max, curr) => max > curr.valueStats.max ? max : curr.valueStats.max, 0);
                        this.calculateChart(filterModel.resolution);
                    });
            } else
                this.podValueService.getValues(apiParams)
                    .pipe(finalize(() => this.loading = false))
                    .subscribe(r => {
                        this.performanceRunChart = new Chart({});
                        this.diagramService.createLegendText(this.filterModel).subscribe(legendText => {
                            let series: any = r;
                            series.name = legendText;
                            this.diagramService.sortDataMarkPartial(series, this.chartData, this.sortedDataWithOriginalIndexes);
                        });
                        this.maxPerformance = r.valueStats.max;
                        this.calculateChart(r.resolution);
                    });
        }
    }


    calculateChart(resolution: string) {
        let valueSuffix = (this.filterModel.resolution === 'MIN_15') ? ' kW' : ' kWh';
        this.chartData.map(r => r.tooltip = {formatter: null, valueSuffix: valueSuffix});
        if (this.filterModel.temperatureChecked) {
            this.diagramService.calculateMTemperatureSeriesForRanking(
                this.filterModel,
                this.sortedDataWithOriginalIndexes,
                this.chartData
            ).subscribe(() => this.displayChart(resolution));
        } else
            this.displayChart(resolution);
    }

    displayChart(resolution: string) {
        let xAxisType = 'linear';
        let chartType = (resolution !== 'MIN_15') ? 'column' : 'line';

        let labelEnabled = true;
        let yAxisText = (resolution === 'MIN_15') ? 'kW' : 'kWh';

        let plotLines = this.diagramService.createAvaragePlotLines(this.chartData);
        let diagramService = this.diagramService;
        let isMultiplePeriod: boolean = this.filterModel.periodParameters.filter(r => r.dateTo).length > 1;

        this.diagramService.createTitle(this.filterModel).subscribe(title =>{
            this.performanceRunChart = new Chart({
                title: {
                    text: this.widget ? '' : title,
                    style: {
                        fontSize: this.widget ? '12px' : '18px'
                    }
                },
                chart: {
                    type: chartType,
                    height: this.widget ? null : '716.66px',
                    style: {
                        fontFamily: '\'Nimbus\''
                    },
                    events: this.diagramService.getWatermark(this.widget),
                    resetZoomButton: {
                        theme: {
                            style: 'opacity: 0.3',
                            states: {
                                hover: {
                                    style: 'opacity: 1'
                                }
                            }
                        }
                    }
                },
                plotOptions: {
                    series: {
                        pointStart: 1
                    },
                    area: {
                        lineWidth: 0,
                        fillOpacity: 0.1
                    }
                },
                xAxis: {
                    labels: {
                        enabled: labelEnabled,
                        formatter: function () {
                            return Number(((this.value as number / this.axis.max) * 100).toFixed(0))+"";
                        }
                    },
                    title: {
                        text: '%'
                    },
                    tickInterval: this.sortedDataWithOriginalIndexes[0].length * 0.1,
                    plotBands: this.diagramService.diagramColumnPlotBands(this)
                },
                yAxis: [{
                    max: Math.ceil(this.maxPerformance),
                    endOnTick: false,
                    tickAmount: this.defaultTickAmount,
                    title: {
                        text: yAxisText
                    },
                    plotLines: plotLines

                }, { // Secondary yAxis
                    tickAmount: this.defaultTickAmount,
                    gridLineWidth: 0,
                    labels: {
                        format: '{value}°C',
                        style: {
                            color: '#000000'
                        }
                    },
                    title: {
                        text: this.filterModel.temperatureChecked ? this.translateService.instant('filter.basic.temperature') : '',
                        style: {
                            color: '#000000'
                        }
                    },
                    opposite: true

                }],
                legend: {
                    enabled: !this.widget
                },
                exporting: {
                    filename: title.replace(/\//ig, '_'),
                    buttons: {
                        contextButton: {
                            align: 'left',
                            x: -10,
                            y: -10
                        }
                    },
                    sourceWidth: 1180,
                    sourceHeight: 757,
                    chartOptions: {
                        title: {
                            style: {
                                fontSize: '12px'
                            }
                        },
                        legend: {
                            enabled: true,
                            itemWidth: 400,
                            itemStyle: {
                                fontSize: '8px'
                            }
                        },
                        xAxis: {
                            labels: {
                                style: {
                                    fontSize: '8px'
                                }
                            },
                            title: {
                                style: {
                                    fontSize: '10px'
                                }
                            }
                        },
                        yAxis: [{
                            max: Math.ceil(this.maxPerformance),
                            endOnTick: false,
                            tickAmount: this.defaultTickAmount,
                            title: {
                                text: yAxisText,
                                style: {
                                    fontSize: '10px'
                                }
                            },
                            plotLines: plotLines,
                            labels: {
                                style: {
                                    fontSize: '8px'
                                }
                            }

                        }, { // Secondary yAxis
                            tickAmount: this.defaultTickAmount,
                            gridLineWidth: 0,
                            labels: {
                                format: '{value}°C',
                                style: {
                                    color: '#000000',
                                    fontSize: '8px'
                                }
                            },
                            title: {
                                text: this.filterModel.temperatureChecked ? this.translateService.instant('filter.basic.temperature') : '',
                                style: {
                                    color: '#000000',
                                    fontSize: '10px'
                                }
                            },
                            opposite: true

                        }]
                    }
                },
                series: this.chartData.sort(function (a, b) {
                    if (a.color === '#98c601' || a.color === 'grey' || a.color === '#000000') return 1;
                    if (b.color === '#98c601' || b.color === 'grey' || b.color === '#000000') return -1;
                    if (a.color < b.color) return 1;
                    if (a.color > b.color) return -1;
                    if (a.name.length > b.name.length) return 1;
                    if (a.name.length < b.name.length) return -1;
                    return 0;
                }),
                lang: {
                    decimalPoint: ','
                },
                tooltip: {
                    useHTML: true,
                    formatter: function () {
                        return diagramService.getCommonTooltipFormatter(this, yAxisText, false, resolution, ConsumptionDiagramType.DIAGCOLUMN, isMultiplePeriod);
                    }
                },
                credits: {
                    enabled: false
                },
                boost: {
                    useGPUTranslations: false
                }
            });
            this.diagramService.setVisiblePlot(this.performanceRunChart, 'avg', this.filterModel.avgSelected);
        });

    }

    private listenDynamicFilterChange(): void {
        this.dynamicFilterChangeSub = this.consumptionDynamicFilterService.filterChangedSubject.subscribe((event: FilterEvent) => {
            if (event.type == 'avg') {
                this.diagramService.setVisiblePlot(this.performanceRunChart, event.type, event.value);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.dynamicFilterChangeSub) {
            this.dynamicFilterChangeSub.unsubscribe();
        }
    }

}
