import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessParametersModalComponent} from './business-parameters.modal.component';
import {GetBusinessParameterDtoModel} from '../../shared/dto/get-business-parameter-dto.model';
import {BusinessParameterService} from '../../shared/services/business-parameter.service';
import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'jhi-business-parameters',
    templateUrl: './business-parameters.component.html'
})
export class BusinessParametersComponent implements OnInit {

    businessParameters: {
        name: string
        model: GetBusinessParameterDtoModel
    }[];

    constructor(
        private businessParameterService: BusinessParameterService,
        private modalService: NgbModal,
        private translateService: TranslateService) {
    }

    ngOnInit() {
        this.refreshList();
    }

    refreshList() {
        this.businessParameterService.findAllVisible().subscribe(data => {
            this.businessParameters = _.sortBy(data.map(p => ({
                name: this.translateService.instant('businessParameters.key.' + p.key),
                model: p
            })), "name");
        })
    }

    openModal(businessParameter: GetBusinessParameterDtoModel) {
        const modalRef = this.modalService.open(BusinessParametersModalComponent, { backdrop: "static" });
        modalRef.componentInstance.businessParameter = businessParameter;
        modalRef.componentInstance.modalClose.subscribe(() => {
            this.refreshList();
            modalRef.close();
        });
    }

}
