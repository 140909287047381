import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LINK_ROUTE } from './link.route';
import { LinkComponent } from './link.component';
import { LinkTabsComponent } from "./link-tabs.component";
import { RouterModule } from '@angular/router';
import {CezPortalSharedModule} from '../shared';


@NgModule({
  imports: [CommonModule, FormsModule, RouterModule.forRoot([LINK_ROUTE], { useHash: true }), CezPortalSharedModule],
  declarations: [LinkComponent, LinkTabsComponent],
  exports: [LinkComponent, LinkTabsComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LinkModule { }
