<ul ngbNav #navInner="ngbNav" class="nav-tabs"
    tabSave="agreement-details" #tabSave="tabSave">

    <li [ngbNavItem]="'agreementData'">
        <a ngbNavLink>{{ translateService.instant('agreement.agreementData.mainDataTitle') }}</a>
        <ng-template ngbNavContent>
            <div class="card px-3 py-3 bg-grey" ngForm="agreementDetailsForm" #agreementDetailsForm="ngForm">
                <h4 class="card-title" jhiTranslate="agreement.agreementData.mainDataTitle">Main Data</h4>
                <div class="form-group row">
                    <label class="col-sm-4" for="bidNumber" jhiTranslate="agreement.agreementData.mainData.bidNumber">Bid
                        Number</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [attr.disabled]="isDisabled('quoteNumber') ? '' : null"
                                   type="text"
                                   class="form-control" id="bidNumber" name="bidNumber"
                                   [(ngModel)]="agreement.quoteNumber"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="contractedQuantityMWh"
                           jhiTranslate="agreement.agreementData.mainData.salesforceQuoteId"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [attr.disabled]="isDisabled('salesforceQuoteId')  ? '' : null"
                                   class="form-control" id="salesforceQuoteId"
                                   name="salesforceQuoteId" [(ngModel)]="agreement.salesforceQuoteId"
                                   (ngModelChange)="onChange()"
                                   type="text"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="contractedQuantityMWh"
                           jhiTranslate="agreement.agreementData.mainData.contractedQuantity">Contracted quantity
                        (MWh)</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="!userView"
                                   [attr.disabled]="isDisabled('contractedQuantityMWh')  ? '' : null"
                                   class="form-control" id="contractedQuantityMWh"
                                   name="contractedQuantityMWh" [(ngModel)]="agreement.contractedQuantityMWh"
                                   (ngModelChange)="onChange()"
                                   type="number"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row mt-3">
                    <label class="col-sm-4" for="productType"
                           jhiTranslate="agreement.agreementData.services.productType">Product Name</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select [required]="!userView" [attr.disabled]="isDisabled('productType') ? '' : null"
                                    class="form-control"
                                    id="productType" name="productType"
                                    [(ngModel)]="agreement.productType"
                                    (ngModelChange)="onChange()">
                                <option *ngFor="let item of productTypes | keys"
                                        [value]="item.value">{{ ('agreement.agreementData.services.productTypeValues.' + item.value) | translate }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-4" for="startTime" jhiTranslate="agreement.agreementData.mainData.startTime">Start
                        Time</label>
                    <div class="col-sm-8">
                        <jhi-date-picker [disabled]="isDisabled('startTime')" id="startTime"
                                         [(dpModel)]="agreement.startTimeNgB" [userView]="userView"
                                         (dpModelChange)="onChange()"></jhi-date-picker>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="endTime" jhiTranslate="agreement.agreementData.mainData.endTime">End
                        Time</label>
                    <div class="col-sm-8">
                        <jhi-date-picker [disabled]="isDisabled('endTime')" id="endTime"
                                         [(dpModel)]="agreement.endTimeNgB" [userView]="userView"
                                         (dpModelChange)="onChange()"></jhi-date-picker>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="paymentTerm"
                           jhiTranslate="agreement.agreementData.mainData.paymentTerm"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="!userView" [attr.disabled]="isDisabled('paymentTerm') ? '' : null"
                                   type="text"
                                   class="form-control" id="paymentTerm"
                                   name="paymentTerm" [(ngModel)]="agreement.paymentTerm"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>

                <div class="form-group row" id="toleranceGroupMembers" *ngIf="agreement.toleranceGroupMembers">
                    <label class="col-sm-4" for="toleranceGroupMembers"
                           jhiTranslate="agreement.agreementData.mainData.toleranceGroupMembers"></label>
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="col-sm-1">
                                <button type="button" class="btn btn-info btn-sm" role="button"
                                        (click)="showGroupMembers=!showGroupMembers">
                                    {{ agreement.toleranceGroupMembers.length }}
                                </button>
                            </div>
                            <div class="col-sm-11">
                                <div class="alert alert-warning" style="position:absolute; width:80%" role="alert"
                                     *ngIf="showGroupMembers && agreement.toleranceGroupMembers && agreement.toleranceGroupMembers.length > 0">
                                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                        <span aria-hidden="true" (click)="showGroupMembers=false">&times;</span>
                                    </button>
                                    <div *ngFor="let member of agreement.toleranceGroupMembers">
                                        {{ member.accountName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="contractLanguage"
                           jhiTranslate="agreement.agreementData.mainData.bidLanguage"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select [attr.disabled]="isDisabled('agreement.contractLanguage') ? '' : null"
                                    class="form-control" id="contractLanguage"
                                    name="contractLanguage" [(ngModel)]="agreement.contractLanguage"
                                    [required]="!userView"
                                    (ngModelChange)="onChange()">
                                <option *ngFor="let item of languages | keys"
                                        [value]="item.value">{{ ('agreement.agreementData.mainData.bidLanguageValues.' + item.value) | translate }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="averagingOtc">{{ averagingOtc }}</label>
                    <div class="col-sm-8">
                        <p-checkbox [disabled]="isDisabled('averagingOtc')" label="" name="averagingOtc"
                                    id="averagingOtc"
                                    [(ngModel)]="agreement.averagingOtc" [binary]="true"
                                    (ngModelChange)="onChange()"></p-checkbox>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="averagingHudex">{{ averagingHudex }}</label>
                    <div class="col-sm-8">
                        <p-checkbox [disabled]="isDisabled('averagingHudex')" label="" name="averagingHudex"
                                    id="averagingHudex"
                                    [(ngModel)]="agreement.averagingHudex" [binary]="true"
                                    (ngModelChange)="onChange()"></p-checkbox>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="hudexAccessPriceUplift"
                           jhiTranslate="agreement.agreementData.mainData.hudexAccessPriceUplift">HUDEX Access Price
                        Uplift [EUR/MWh]</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('hudexAccessPriceUplift') ? '' : null"
                                   class="form-control" id="hudexAccessPriceUplift"
                                   name="hudexAccessPriceUplift" [(ngModel)]="agreement.hudexAccessPriceUplift"
                                   type="number"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="yearlyProducts">{{ yearlyProducts }}</label>
                    <div class="col-sm-8">
                        <p-checkbox [disabled]="isDisabled('yearlyProducts')" label="" name="yearlyProducts"
                                    id="yearlyProducts"
                                    [(ngModel)]="agreement.yearlyProducts" [binary]="true"
                                    (ngModelChange)="onChange()"></p-checkbox>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="quarterlyProducts">{{ quarterlyProducts }}</label>
                    <div class="col-sm-8">
                        <p-checkbox [disabled]="isDisabled('quarterlyProducts')" label="" name="quarterlyProducts"
                                    id="quarterlyProducts"
                                    [(ngModel)]="agreement.quarterlyProducts" [binary]="true"
                                    (ngModelChange)="onChange()"></p-checkbox>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="monthlyProducts">{{ monthlyProducts }}</label>
                    <div class="col-sm-8">
                        <p-checkbox [disabled]="isDisabled('monthlyProducts')" label="" name="monthlyProducts"
                                    id="monthlyProducts"
                                    [(ngModel)]="agreement.monthlyProducts" [binary]="true"
                                    (ngModelChange)="onChange()"></p-checkbox>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="weeklyProducts">{{ weeklyProducts }}</label>
                    <div class="col-sm-8">
                        <p-checkbox [disabled]="isDisabled('weeklyProducts')" label="" name="weeklyProducts"
                                    id="weeklyProducts"
                                    [(ngModel)]="agreement.weeklyProducts" [binary]="true"
                                    (ngModelChange)="onChange()"></p-checkbox>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="hupxSpotAccess">{{ hupxSpotAccess }}</label>
                    <div class="col-sm-8">
                        <p-checkbox [disabled]="isDisabled('hupxSpotAccess')" label="" name="hupxSpotAccess"
                                    id="hupxSpotAccess"
                                    [(ngModel)]="agreement.hupxSpotAccess" [binary]="true"
                                    (ngModelChange)="onChange()"></p-checkbox>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="thirdPartyDeliveryAllowed"
                           jhiTranslate="agreement.agreementData.mainData.thirdPartyDeliveryAllowed"></label>
                    <div class="col-sm-8">
                        <p-checkbox [disabled]="isDisabled('thirdPartyDeliveryAllowed')" label=""
                                    name="thirdPartyDeliveryAllowed"
                                    id="thirdPartyDeliveryAllowed"
                                    [(ngModel)]="agreement.thirdPartyDeliveryAllowed" [binary]="true"
                                    (ngModelChange)="onChange()"
                        ></p-checkbox>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="accountOwner"
                           jhiTranslate="agreement.agreementData.mainData.accountOwner"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('accountOwner') ? '' : null"
                                   class="form-control" id="accountOwner"
                                   name="accountOwner" [(ngModel)]="agreement.accountOwner"
                                   type="text"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="backupKam"
                           jhiTranslate="agreement.agreementData.mainData.backupKam"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('backupKam') ? '' : null"
                                   class="form-control" id="backupKam"
                                   name="backupKam" [(ngModel)]="agreement.backupKam"
                                   type="text"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="electricityInvoicedIn"
                           jhiTranslate="agreement.agreementData.mainData.electricityInvoicedIn"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('electricityInvoicedIn') ? '' : null"
                                   class="form-control" id="electricityInvoicedIn"
                                   name="electricityInvoicedIn" [(ngModel)]="agreement.electricityInvoicedIn"
                                   type="text"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="electricityInvoicedIn"
                           jhiTranslate="agreement.agreementData.mainData.futuresMarketAccess"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('futuresMarketAccess') ? '' : null"
                                   class="form-control" id="futuresMarketAccess"
                                   name="futuresMarketAccess" [(ngModel)]="agreement.futuresMarketAccess"
                                   type="text"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="rhdInvoicedIn"
                           jhiTranslate="agreement.agreementData.mainData.rhdInvoicedIn"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('rhdInvoicedIn') ? '' : null"
                                   class="form-control" id="rhdInvoicedIn"
                                   name="rhdInvoicedIn" [(ngModel)]="agreement.rhdInvoicedIn"
                                   type="text"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="rhdInvoicing"
                           jhiTranslate="agreement.agreementData.mainData.rhdInvoicing"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('rhdInvoicing') ? '' : null"
                                   class="form-control" id="rhdInvoicing"
                                   name="rhdInvoicing" [(ngModel)]="agreement.rhdInvoicing"
                                   type="text"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="taxesInvoicedIn"
                           jhiTranslate="agreement.agreementData.mainData.taxesInvoicedIn"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('taxesInvoicedIn') ? '' : null"
                                   class="form-control" id="taxesInvoicedIn"
                                   name="taxesInvoicedIn" [(ngModel)]="agreement.taxesInvoicedIn"
                                   type="text"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="spotSellBackFee"
                           jhiTranslate="agreement.agreementData.mainData.spotSellbackFee"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('spotSellbackFee') ? '' : null"
                                   class="form-control" id="spotSellbackFee"
                                   name="spotSellbackFee" [(ngModel)]="agreement.spotSellbackFee"
                                   type="number"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div *ngIf="agreement.productType==='CASH_OUT'" class="form-group row">
                    <label class="col-sm-4" for="maxTotalHedgeCapacity"
                           jhiTranslate="agreement.agreementData.mainData.maxTotalHedgeCapacityMw">Max. total hedge
                        capacity [MW]</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('maxTotalHedgeCapacity') ? '' : null"
                                   class="form-control" id="maxTotalHedgeCapacity"
                                   name="maxTotalHedgeCapacity" [(ngModel)]="agreement.maxTotalHedgeCapacity"
                                   type="number"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div *ngIf="agreement.productType==='CASH_OUT'" class="form-group row">
                    <label class="col-sm-4" for="maxTransactionSizeOnHudex"
                           jhiTranslate="agreement.agreementData.mainData.maxTransactionSizeOnHudex">Max. transaction
                        size on HUDEX [MW]</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false"
                                   [attr.disabled]="isDisabled('maxTransactionSizeOnHudex') ? '' : null"
                                   class="form-control" id="maxTransactionSizeOnHudex"
                                   name="maxTransactionSizeOnHudex" [(ngModel)]="agreement.maxTransactionSizeOnHudex"
                                   type="number"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div *ngIf="agreement.productType==='CASH_OUT'" class="form-group row">
                    <label class="col-sm-4" for="minClickSizeOnHudex"
                           jhiTranslate="agreement.agreementData.mainData.minClickSizeOnHudex">Min. click size on HUDEX
                        [MW]</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('minClickSizeOnHudex') ? '' : null"
                                   class="form-control" id="minClickSizeOnHudex"
                                   name="minClickSizeOnHudex" [(ngModel)]="agreement.minClickSizeOnHudex"
                                   type="number"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div *ngIf="agreement.productType==='CASH_OUT'" class="form-group row">
                    <label class="col-sm-4" for="minClickSizeOnOtc"
                           jhiTranslate="agreement.agreementData.mainData.minClickSizeOnOtc">Min. click size on OTC
                        [MW]</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('minClickSizeOnOtc') ? '' : null"
                                   class="form-control" id="minClickSizeOnOtc"
                                   name="minClickSizeOnOtc" [(ngModel)]="agreement.minClickSizeOnOtc"
                                   type="number"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div *ngIf="agreement.productType==='STEP_BY_STEP'" class="form-group row">
                    <label class="col-sm-4" for="clicks"
                           jhiTranslate="agreement.agreementData.mainData.clicks">Clicks</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('clicks') ? '' : null"
                                   class="form-control" id="clicks"
                                   name="clicks" [(ngModel)]="agreement.clicks"
                                   type="number"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div *ngIf="agreement.productType==='STEP_BY_STEP'" class="form-group row">
                    <label class="col-sm-4" for="clickModification">{{ clickModification }}</label>
                    <div class="col-sm-8">
                        <p-checkbox [disabled]="isDisabled('clickModification')" label="" name="clickModification"
                                    id="clickModification"
                                    [(ngModel)]="agreement.clickModification" [binary]="true"
                                    (ngModelChange)="onChange()"></p-checkbox>
                    </div>
                </div>
                <div *ngIf="agreement.productType==='STEP_BY_STEP'" class="form-group row">
                    <label class="col-sm-4" for="clickFlexibilityUp"
                           jhiTranslate="agreement.agreementData.mainData.clickFlexibilityUp">Click Flexibility
                        Up</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('clickFlexibilityUp') ? '' : null"
                                   class="form-control" id="clickFlexibilityUp"
                                   name="clickFlexibilityUp" [(ngModel)]="agreement.clickFlexibilityUp"
                                   type="number"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div *ngIf="agreement.productType==='STEP_BY_STEP'" class="form-group row">
                    <label class="col-sm-4" for="clickFlexibilityDown"
                           jhiTranslate="agreement.agreementData.mainData.clickFlexibilityDown">Click Flexibility
                        Down</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false" [attr.disabled]="isDisabled('clickFlexibilityDown') ? '' : null"
                                   class="form-control" id="clickFlexibilityDown"
                                   name="clickFlexibilityDown" [(ngModel)]="agreement.clickFlexibilityDown"
                                   type="number"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div *ngIf="agreement.productType==='STEP_BY_STEP'" class="form-group row">
                    <label class="col-sm-4" for="maxTransactionSizeOnHudexTranche"
                           jhiTranslate="agreement.agreementData.mainData.maxTransactionSizeOnHudexTranche">Max.
                        transaction size on HUDEX [tranche]</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false"
                                   [attr.disabled]="isDisabled('maxTransactionSizeOnHudexTranche') ? '' : null"
                                   class="form-control" id="maxTransactionSizeOnHudexTranche"
                                   name="maxTransactionSizeOnHudexTranche"
                                   [(ngModel)]="agreement.maxTransactionSizeOnHudexTranche"
                                   type="number"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
                <div *ngIf="agreement.productType==='STEP_BY_STEP'" class="form-group row">
                    <label class="col-sm-4" for="automaticClosingProduct"
                           jhiTranslate="agreement.agreementData.mainData.automaticClosingProduct"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select [attr.disabled]="isDisabled('automaticClosingProduct') ? '' : null"
                                    class="form-control" id="automaticClosingProduct"
                                    name="automaticClosingProduct" [(ngModel)]="agreement.automaticClosingProduct"
                                    (ngModelChange)="onChange()">
                                <option *ngFor="let item of closingProductTypes | keys"
                                        [value]="item.value">{{ ('agreement.agreementData.mainData.closingProductTypeValues.' + item.value) | translate }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-4" for="hedgeLimit"
                           jhiTranslate="agreement.agreementData.mainData.hedgeLimit">Hedge limit</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input [required]="false"
                                   [attr.disabled]="isDisabled('hedgeLimit') ? '' : null"
                                   class="form-control" id="hedgeLimit"
                                   name="hedgeLimit"
                                   [(ngModel)]="agreement.hedgeLimit"
                                   type="number"
                                   (ngModelChange)="onChange()"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card px-3 py-3 bg-grey mt-3" ngForm="servicesForm" #servicesForm="ngForm">
                <h4 class="card-title" jhiTranslate="agreement.agreementData.servicesTitle"></h4>


                <div class="form-group row">
                    <label class="col-sm-4" for="schedulingCB"
                           jhiTranslate="agreement.agreementData.services.schedule.label"></label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select id="schedulingCB"
                                    name="schedulingCB"
                                    class="form-control"
                                    [attr.disabled]="isDisabled('services.scheduling') ? '' : null"
                                    [(ngModel)]="agreement.services.scheduling"
                                    (ngModelChange)="onChange()">
                                <option *ngFor="let item of ['']" [value]="item">{{ item }}</option>

                                <option *ngFor="let item of schedulingTypes | keys"
                                        [value]="item.value">{{ ('agreement.agreementData.services.schedule.items.' + item.value) | translate }}
                                </option>

                            </select>


                        </div>
                    </div>

                </div>
                <div class="form-group row" *ngIf="!userView">
                    <label class="col-sm-4" for="defaultUnit"
                           jhiTranslate="agreement.agreementData.services.measureUnit">Szerződés egységáras
                        mértékegysége</label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <select id="defaultUnit" name="defaultUnit"
                                    [(ngModel)]="agreement.services.measureUnit" class="form-control"
                                    (ngModelChange)="onChange()">
                                <option *ngFor="let item of ['MWh', 'kWh']" [value]="item">{{ item }}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group row">
                  <label class="col-sm-4" for="cezScreenAccess" jhiTranslate="agreement.agreementData.services.cezScreenAccess"></label>
                  <div class="col-sm-8">
                    <p-checkbox (click)="enable('services.cezScreenAccess')" label="" name="cezScreenAccess" [(ngModel)]="agreement.services.cezScreenAccess"
                      [binary]="true"></p-checkbox>
                  </div>
                </div> -->
            </div>
            <div class="card px-3 py-3 mt-3 bg-grey ">
                <header class="cez-table-header">
                    <h4 class="card-title" jhiTranslate="agreement.agreementData.contractsTitle">Contracts</h4>
                    <button *ngIf="!userView" type="button" class="btn btn-primary"
                            (click)="contractEditor(null)">{{ 'agreement.new' | translate }}
                    </button>
                </header>
                <jhi-contract-list *ngIf="tabSave.tabLoaded"
                                   [adminView]="!userView" [model]="agreement.contracts"
                                   (editEmitter)="contractEditor($event)"
                                   (deleteEmitter)="deleteContract($event)"></jhi-contract-list>
            </div>
        </ng-template>
    </li>

    <li [ngbNavItem]="'deliveryPeriods'">
        <a ngbNavLink>{{ translateService.instant('agreement.agreementData.deliveryPeriodsTitle') }}</a>
        <ng-template ngbNavContent>

            <mat-card>
                <mat-card-title>
                    <header class="cez-table-header">
                        <h4 class="card-title" jhiTranslate="agreement.agreementData.deliveryPeriods.title"></h4>
                        <button *ngIf="!userView" type="button" class="btn btn-primary"
                                (click)="deliveryPeriodEditor(null)">{{ 'agreement.agreementData.deliveryPeriods.new' | translate }}
                        </button>
                    </header>
                </mat-card-title>
                <mat-card-content>
                    <jhi-delivery-period-list *ngIf="tabSave.tabLoaded"
                                              [adminView]="!userView" [model]="agreement.deliveryPeriods"
                                              (editEmitter)="deliveryPeriodEditor($event)"
                                              (deleteEmitter)="deleteDeliveryPeriod($event)"></jhi-delivery-period-list>
                </mat-card-content>
            </mat-card>

            <mat-card>
                <mat-card-title>
                    <header class="cez-table-header">
                        <h4 class="card-title" jhiTranslate="agreement.agreementData.tolerancePeriods.title"></h4>
                        <button *ngIf="!userView" type="button" class="btn btn-primary"
                                (click)="toleranceLimitEditor(null)">{{ 'agreement.agreementData.tolerancePeriods.new' | translate }}
                        </button>
                    </header>
                </mat-card-title>
                <mat-card-content>
                    <jhi-tolerance-limit-list *ngIf="tabSave.tabLoaded"
                                              [adminView]="!userView" [model]="agreement.tolerancePeriods"
                                              (editEmitter)="toleranceLimitEditor($event)"
                                              (deleteEmitter)="deleteTolerance($event)"></jhi-tolerance-limit-list>
                </mat-card-content>
            </mat-card>
        </ng-template>
    </li>
    <li [ngbNavItem]="'agreement.agreementData.podsTitle'">
        <a ngbNavLink>{{ translateService.instant('agreement.agreementData.podsTitle') }}</a>
        <ng-template ngbNavContent>
            <mat-card>
                <mat-card-title>
                    <header class="cez-table-header">
                        <h4 class="card-title" jhiTranslate="agreement.agreementData.podsTitle">PODs</h4>
                        <div *ngIf="!userView && !importPodMode" class="btn-group" role="group">
                            <button type="button" class="btn btn-primary" [disabled]="!agreement.id"
                                    (click)="agreementPodExport()">{{ 'agreement.pod_groups.table.export' | translate }}
                            </button>
                            <button type="button" class="btn btn-primary" [disabled]="!agreement.id"
                                    (click)="agreementPodImport()">{{ 'agreement.pod_groups.table.import' | translate }}
                            </button>
                            <button type="button" class="btn btn-primary"
                                    (click)="podEditor(null)">{{ 'agreement.pod_groups.table.new' | translate }}
                            </button>
                        </div>
                    </header>
                </mat-card-title>
                <mat-card-content>
                    <form *ngIf="importPodMode" #importPodFileForm="ngForm">
                        <div>
                            <div class="form-group">
                                <br>
                                <label jhiTranslate="documents.file"></label>
                                <!--<file-uploader
                                    [fileType]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
                                    (onSelection)="fileuploaderFileChange($event)"
                                    [required]="true" allowMultiple="false"></file-uploader>-->
                                <div class="col-auto">
                                    <button mat-mini-fab color="primary" (click)="addFile(fileInput)" type="button">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                    <input #fileInput [hidden]="true" type="file"
                                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
                                </div>

                                <cez-file-upload class="col"
                                                 *ngIf="fileInput.value"
                                                 [file]="fileInput.files[0]"
                                                 [autoUpload]="true"
                                                 (onUpload)="onUpload($event)"
                                                 (removeEvent)="fileInput.value = ''"></cez-file-upload>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary" (click)="podImportCancel()"
                                    jhiTranslate="entity.action.cancel">Cancel
                            </button>
                            <button type="button" class="btn btn-primary" (click)="podImportOk()"
                                    jhiTranslate="entity.action.save"
                                    [disabled]="importPodFileForm.form.invalid ||  !selectedFilesForPodImport">
                                Submit
                            </button>
                        </div>
                    </form>
                    <jhi-pod-list *ngIf="tabSave.tabLoaded"
                                  [adminView]="!userView" [model]="agreement.pods" (editEmitter)="podEditor($event)"
                                  (deleteEmitter)="podDelete($event)"></jhi-pod-list>
                </mat-card-content>
            </mat-card>
        </ng-template>
    </li>
    <li [ngbNavItem]="'agreement.agreementData.priceTitle'">
        <a ngbNavLink>{{ translateService.instant('agreement.agreementData.priceTitle') }}</a>
        <ng-template ngbNavContent>
            <jhi-price-list *ngIf="tabSave.tabLoaded"
                            [model]="agreement.deliveryPeriods"
                            [productType]="agreement.productType"
                            [goO]="agreement.services.guaranteeOfOrigin"></jhi-price-list>
        </ng-template>
    </li>

    <li [ngbNavItem]="'plannedConsumption'" *ngIf="!userView && agreement.id">
        <a ngbNavLink>{{ translateService.instant('agreement.agreementData.plannedConsumption') }}</a>
        <ng-template ngbNavContent>
            <cez-time-series-import
                *ngIf="tabSave.tabLoaded"
                [uiName]="'agreement-details_planned-consumption'"
                [companyId]="agreement.partner.id"
                [excelTypeOptions]="['VECTOR']"
                [timeSeriesTypeOptions]="['PLANED_CONSUMPTION']"
                [defaultResolution]="'QUARTER_HOURLY'"
                [agreementId]="agreement.id"
                [isMultipleFiles]="false">
            </cez-time-series-import>
        </ng-template>
    </li>

    <li [ngbNavItem]="'scheduling'" *ngIf="('SCHEDULE_READ' | hasPermission)  && agreement.id">
        <a ngbNavLink>{{ translateService.instant('agreement.agreementData.scheduling') }}</a>
        <ng-template ngbNavContent>
            <cez-time-series-import
                *ngIf="tabSave.tabLoaded"
                [uiName]="'agreement-details_scheduling'"
                [companyId]="agreement.partner.id"
                [defaultTimeSeriesType]="'CONSUMPTION_SCHEDULE'"
                [timeSeriesTypeOptions]="['CONSUMPTION_SCHEDULE','PRODUCTION_SCHEDULE']"
                [defaultResolution]="'HOURLY'"
                [resolutionOptions]="['HOURLY', 'QUARTER_HOURLY']"
                [agreementId]="agreement.id"
                [isMultipleFiles]="false"
                (intervalChange)="schedulingIntervalChange($event)">
            </cez-time-series-import>
        </ng-template>
    </li>

</ul>

<div [ngbNavOutlet]="navInner"></div>
