<div class="container-fluid p-0">
    <div class="row">
        <div class="col-sm">
            <mat-card class="over-flow-scrollbar">

                <mat-card-title class="d-flex">
                    <div>{{ 'additional-diag-type.title' | translate }}</div>
                    <div class="ml-auto button-row" *ngIf="'ADDITIONAL_DIAGRAM_WRITE' | hasPermission">
                        <button color="primary" mat-mini-fab (click)="editDiagramType()">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button color="primary" mat-mini-fab (click)="editDiagramType(selectedDiagramType)"
                                [disabled]="!selectedDiagramType || selectedDiagramType?.seriesType === 'PRODUCT' ">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button color="primary" mat-mini-fab (click)="deleteDiagramType(selectedDiagramType)"
                                [disabled]="!selectedDiagramType">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </mat-card-title>


                <mat-card-content class="min-width-1300 min-height-300">
                    <p-table #diagramType [value]="additionalDiagramTypes" [paginator]="true" [rows]="10"
                             sortMode="multiple"
                             selectionMode="single" [(selection)]="selectedDiagramType"
                             class="table table-striped selectable-table">
                        <ng-template pTemplate="header">
                            <tr>
                                <th [pSortableColumn]="'typeNameEN'">
                                    {{'additional-diag-type.type.header.typeNameTextEN' | translate}}
                                    <p-sortIcon [field]="'typeNameEN'"></p-sortIcon>
                                </th>
                                <th [pSortableColumn]="'typeNameHU'">
                                    {{'additional-diag-type.type.header.typeNameTextHU' | translate}}
                                    <p-sortIcon [field]="'typeNameHU'"></p-sortIcon>
                                </th>
                                <th [pSortableColumn]="'seriesType'">
                                    {{'additional-diag-type.type.header.seriesType' | translate}}
                                    <p-sortIcon [field]="'seriesType'"></p-sortIcon>
                                </th>
                                <th [pSortableColumn]="'columnName'">
                                    {{'additional-diag-type.type.header.columnName' | translate}}
                                    <p-sortIcon [field]="'columnName'"></p-sortIcon>
                                </th>
                                <th [pSortableColumn]="'diagramType'">
                                    {{'additional-diag-type.type.header.diagramType' | translate}}
                                    <p-sortIcon [field]="'diagramType'"></p-sortIcon>
                                </th>
                                <th [pSortableColumn]="'groupOnlyVisibility'">
                                    {{'additional-diag-type.type.header.groupOnlyVisibility' | translate}}
                                    <p-sortIcon [field]="'groupOnlyVisibility'"></p-sortIcon>
                                </th>
                                <th [pSortableColumn]="'measureUnit'">
                                    {{'additional-diag-type.type.header.measureUnit' | translate}}
                                    <p-sortIcon [field]="'measureUnit'"></p-sortIcon>
                                </th>
                                <th [pSortableColumn]="'resolution'">
                                    {{'additional-diag-type.type.header.resolution' | translate}}
                                    <p-sortIcon [field]="'resolution'"></p-sortIcon>
                                </th>
                            </tr>

                            <tr>
                                <th>
                                    <mat-form-field>
                                        <input matInput
                                               placeholder="{{'global.search' | translate}}"
                                               (input)="diagramType.filter($event.target.value, 'typeNameEN', 'contains')">
                                    </mat-form-field>
                                </th>
                                <th>
                                    <mat-form-field>
                                        <input matInput
                                               placeholder="{{'global.search' | translate}}"
                                               (input)="diagramType.filter($event.target.value, 'typeNameHU', 'contains')">
                                    </mat-form-field>
                                </th>
                                <th>
                                    <mat-form-field>
                                        <mat-select
                                            (selectionChange)="diagramType.filter($event.value, 'seriesType', 'equals')"
                                            placeholder="{{'global.choose' | translate}}">
                                            <mat-option>{{'multiSelectText.checkAll' | translate}}</mat-option>
                                            <mat-option *ngFor="let seriesTypeOption of seriesTypeOptions"
                                                        [value]="seriesTypeOption.value">
                                                {{seriesTypeOption.label}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </th>
                                <th>
                                    <mat-form-field>
                                        <input matInput
                                               placeholder="{{'global.search' | translate}}"
                                               (input)="diagramType.filter($event.target.value, 'columnName', 'contains')">
                                    </mat-form-field>
                                </th>
                                <th>
                                    <mat-form-field>
                                        <mat-select
                                            (selectionChange)="diagramType.filter($event.value, 'diagramType', 'in')"
                                            placeholder="{{'additional-diag-type.type.selectDiagramType' | translate}}"
                                            multiple>
                                            <mat-option *ngFor="let diagTypeOption of diagramTypeOptions"
                                                        [value]="diagTypeOption.value">
                                                {{diagTypeOption.label}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </th>
                                <th>
                                    <mat-form-field>
                                        <mat-select
                                            (selectionChange)="diagramType.filter($event.value, 'groupOnlyVisibility', 'equals')"
                                            placeholder="{{'additional-diag-type.type.selectGroupOnly' | translate}}">
                                            <mat-option>{{'multiSelectText.checkAll' | translate}}</mat-option>
                                            <mat-option *ngFor="let boolean of booleanOptions" [value]="boolean.value">
                                                {{boolean.label}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </th>
                                <th>
                                    <mat-form-field>
                                        <input matInput
                                               placeholder="{{'global.search' | translate}}"
                                               (input)="diagramType.filter($event.target.value, 'measureUnit', 'contains')">
                                    </mat-form-field>
                                </th>
                                <th>
                                    <mat-form-field>
                                        <mat-select
                                            (selectionChange)="diagramType.filter($event.value, 'resolution', 'in')"
                                            placeholder="{{'additional-diag-type.type.selectResolution' | translate}}"
                                            multiple>
                                            <mat-option *ngFor="let resolutionOption of resolutionOptions"
                                                        [value]="resolutionOption.value">
                                                {{resolutionOption.label}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </th>
                            </tr>

                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr [pSelectableRow]="rowData">
                                <td>{{rowData.typeNameEN}}</td>
                                <td>{{rowData.typeNameHU}}</td>
                                <td>{{'SeriesType.' + rowData.seriesType | translate}}</td>
                                <td>{{rowData.columnName}}</td>
                                <td>{{'DiagramType.' + rowData.diagramType | translate}}</td>
                                <td>{{'additional-diag-type.type.groupOnlyVisibility.' + rowData.groupOnlyVisibility |
                                    translate}}
                                </td>
                                <td>{{rowData.measureUnit}}</td>
                                <td>{{'AdditionalDiagramResolution.' + rowData.resolution | translate}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-sm">
            <mat-card class="mt-2 over-flow-scrollbar">

                <mat-card-title class="d-flex">
                    <div>{{'additional-diag-group.title' | translate}}</div>
                    <div class="ml-auto button-row" *ngIf="'ADDITIONAL_DIAGRAM_WRITE' | hasPermission">
                        <button color="primary" mat-mini-fab (click)="editDiagramGroup()">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button color="primary" mat-mini-fab
                                [disabled]="!selectedDiagramGroup"
                                (click)="editDiagramGroup(selectedDiagramGroup)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button color="primary" mat-mini-fab
                                [disabled]="!selectedDiagramGroup"
                                (click)="deleteDiagramGroup(selectedDiagramGroup)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </mat-card-title>

                <mat-card-content class="min-width-1300 min-height-300">
                    <p-table #diagramGroup [value]="additionalDiagramGroups" [paginator]="true" [rows]="10"
                             sortMode="multiple"
                             dataKey="diagramGroupId"
                             selectionMode="single" [(selection)]="selectedDiagramGroup"
                             class="table table-striped selectable-table">
                        <ng-template pTemplate="header">
                            <tr>
                                <th [pSortableColumn]="'groupNameEN'">
                                    {{'additional-diag-group.group.header.groupName.en' |
                                    translate}}
                                    <p-sortIcon [field]="'groupNameEN'"></p-sortIcon>
                                </th>
                                <th [pSortableColumn]="'groupNameHU'">
                                    {{'additional-diag-group.group.header.groupName.hu' | translate}}
                                    <p-sortIcon [field]="'groupNameHU'"></p-sortIcon>
                                </th>
                                <th [pSortableColumn]="'digramType'">{{'additional-diag-group.group.header.diagramType'
                                    | translate}}
                                    <p-sortIcon [field]="'digramType'"></p-sortIcon>
                                </th>
                                <th>
                                    {{'additional-diag-group.group.header.additionalDigramTypes'
                                    | translate}}
                                </th>
                                <th>
                                    {{'additional-diag-group.group.header.products'
                                    | translate}}
                                </th>
                                <th>
                                    {{'additional-diag-group.group.header.spots'
                                    | translate}}
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    <mat-form-field>
                                        <input matInput
                                               placeholder="{{'global.search' | translate}}"
                                               (input)="diagramGroup.filter($event.target.value, 'groupNameEN', 'contains')">
                                    </mat-form-field>
                                </th>
                                <th>
                                    <mat-form-field>
                                        <input matInput
                                               placeholder="{{'global.search' | translate}}"
                                               (input)="diagramGroup.filter($event.target.value, 'groupNameHU', 'contains')">
                                    </mat-form-field>
                                </th>
                                <th>
                                    <mat-form-field>
                                        <mat-select
                                            (selectionChange)="diagramGroup.filter($event.value, 'diagramType', 'in')"
                                            placeholder="{{'additional-diag-group.selectDiagramType' | translate}}"
                                            multiple>
                                            <mat-option *ngFor="let diagTypeOption of diagramTypeOptions"
                                                        [value]="diagTypeOption.value">
                                                {{diagTypeOption.label}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </th>
                                <th>
                                </th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-expanded="expanded">
                            <tr [pSelectableRow]="rowData">
                                <td>{{rowData.groupNameEN}}</td>
                                <td>{{rowData.groupNameHU}}</td>
                                <td>{{'DiagramType.' + rowData.diagramType | translate}}</td>
                                <td>
                                    <a *ngIf="rowData.additionalDiagramTypes.length > 0" [pRowToggler]="rowData" href="#">
                                        {{rowData.additionalDiagramTypes[0] | translateAdditionalDiagramType}}
                                        {{rowData.additionalDiagramTypes.length > 1 ? ", ..." : ""}}
                                    </a>
                                </td>
                                <td>
                                    <a *ngIf="rowData.products.length > 0" [pRowToggler]="rowData" href="#">
                                        {{rowData.products[0].productName}}
                                        {{rowData.products.length > 1 ? ", ..." : ""}}
                                    </a>
                                </td>
                                <td>
                                    <a *ngIf="rowData.spots.length > 0" [pRowToggler]="rowData" href="#">
                                        {{rowData.spots[0].name}}
                                        {{rowData.spots.length > 1 ? ", ..." : ""}}
                                    </a>
                                </td>
                            </tr>
                        </ng-template>

                        <ng-template pTemplate="rowexpansion" let-rowData>
                            <tr>
                                <td [attr.colspan]="6" class="expansion">
                                    <div class="ui-g text-left">
                                        <div class="ui-g-12" *ngIf="rowData.additionalDiagramTypes.length > 0">
                                            <label>{{('additional-diag-group.group.header.additionalDigramTypes'
                                                | translate)}}: </label>
                                            <span
                                                *ngFor="let additionalDiagramType of rowData.additionalDiagramTypes; last as isLast"> {{isLast ? (additionalDiagramType | translateAdditionalDiagramType) : (additionalDiagramType | translateAdditionalDiagramType) + ', '}}</span>
                                        </div>
                                        <div class="ui-g-12" *ngIf="rowData.products.length > 0">
                                            <label>{{('additional-diag-group.group.header.products'
                                                | translate)}}:</label>
                                            <span
                                                *ngFor="let product of rowData.products; last as isLast"> {{isLast ? product?.productName : (product?.productName + ', ')}}</span>
                                        </div>
                                        <div class="ui-g-12" *ngIf="rowData.spots.length > 0">
                                            <label>{{('additional-diag-group.group.header.spots'
                                                | translate)}}:</label>
                                            <span
                                                *ngFor="let spot of rowData.spots; last as isLast"> {{isLast ? spot?.name : (spot?.name + ', ')}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>

                    </p-table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
