import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';

import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AggregationTableDataModel, AggregationTableType} from './aggregation-table-data.model';
import {OfferClaimEventType} from '../../coverage-transactions/offer-claim.service';
import {CoverageTransactionsService} from "../../coverage-transactions/coverage-transactions.service";

@Component({
    selector: 'aggregation-table-data',
    templateUrl: 'aggregation-table-data.component.html',
    styleUrls: ['aggregation-table-data.component.scss'],
})
export class AggregationTableDataComponent implements OnInit, OnDestroy, AfterViewInit {

    public readonly AggregationTableType: typeof AggregationTableType = AggregationTableType;

    @Input() deliveryPeriodId: number;
    @Input() tableType: AggregationTableType = AggregationTableType.ALL;
    @Input() refreshEvent: Observable<OfferClaimEventType | null>;

    public aggregationTableData: AggregationTableDataModel;
    public isLoading: boolean = false;

    private destroy: Subject<void> = new Subject();

    constructor(private coverageTransactionsService: CoverageTransactionsService) {}

    ngOnInit(): void {
        this.refresh();
    }

    ngAfterViewInit(): void {
        this.subscribeEvents();
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    public refresh(): void {
        this.loadAggregationTableDataModel();
    }

    public get beforeTradeShow(): boolean {
        return [AggregationTableType.ALL, AggregationTableType.BEFORE_TRADE].indexOf(this.tableType) !== -1 &&
            !!this.aggregationTableData && !!this.aggregationTableData.beforeTrade;
    }

    public get afterTradeShow(): boolean {
        return [AggregationTableType.ALL, AggregationTableType.AFTER_TRADE].indexOf(this.tableType) !== -1 &&
            !!this.aggregationTableData && this.aggregationTableData.hasActivePurchase && !!this.aggregationTableData.afterTrade;
    }

    private loadAggregationTableDataModel(): void {
        this.isLoading = true;
        this.coverageTransactionsService.getAggregationTableData(this.deliveryPeriodId.toString(), AggregationTableType.ALL)
            .subscribe((model: AggregationTableDataModel) => {
                this.aggregationTableData = model;
                this.isLoading = false;
            }, () => this.isLoading = false);
    }

    private subscribeEvents(): void {
        if (!!this.refreshEvent) {
            this.refreshEvent.pipe(takeUntil(this.destroy))
                .subscribe(() => this.refresh());
        }
    }

}
