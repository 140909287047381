import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {AlertComponent} from './alert/alert.component';
import {ConfirmComponent} from './confirm/confirm.component';
import {PromptComponent} from './prompt/prompt.component';
import {DialogService} from './dialog.service';
import {CezPortalSharedModule} from '../shared.module';
import {DetailsComponent} from './details/details.component';
import {ModifyComponent} from './modify/modify.component';
import {ErrorAlertComponent} from './error-alert/error-alert.component';
import {TranslateModule} from '@ngx-translate/core';
import {PodImportAlertComponent} from './pod-import-alert/pod-import-alert.component';
import {LastUpdatesComponent} from './last-updates/last-updates.component';

@NgModule({
    imports: [
        CezPortalSharedModule,
        TranslateModule
    ],
    providers: [DialogService],
    declarations: [
        AlertComponent,
        ConfirmComponent,
        PromptComponent,
        DetailsComponent,
        ModifyComponent,
        ErrorAlertComponent,
        PodImportAlertComponent,
        LastUpdatesComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DialogModule {
}
