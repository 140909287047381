import {OfferClaimDto} from '../../../shared/offer-claim/model/OfferClaimDto';
import {DeliveryPeriodForOffer} from '../../../shared/offer-claim/model/DeliveryPeriodForOffer';
import {DailyRatesDto} from '../../../shared/daily-rates/model/DailyRatesDto';
import {OfferClaimPriceType} from '../../../shared/offer-claim/model/offer-claim-price-type.enum';
import {OfferPeriod} from '../../../shared/offer-claim/model/offer-period.enum';

export type DailyRateData = {period: OfferPeriod, periodNumber: number, price: number | string | null};

export type NewClaimDialogMode = 'ADMIN_MODE' | 'PARTNER_MODE';
export const NewClaimDialogMode = {
    ADMIN_MODE: 'ADMIN_MODE' as NewClaimDialogMode,
    PARTNER_MODE: 'PARTNER_MODE' as NewClaimDialogMode
};

export interface PartnerWithDeliveryPeriods {
    companyId: number;
    name: string;
    deliveryPeriods: DeliveryPeriodForOffer[];
}

export interface NewClaimDialogConfig {
    mode: NewClaimDialogMode;
    claim: OfferClaimDto | null;
    partner: {
        all: PartnerWithDeliveryPeriods[]
        selected: PartnerWithDeliveryPeriods | null
    } | null;
    deliveryPeriod: {
        all: DeliveryPeriodForOffer[];
        selected: DeliveryPeriodForOffer | null;
    } | null;
    disabledDeliveryPeriods: DeliveryPeriodForOffer[];
    dailyRate: DailyRatesDto | null;
    priceType: OfferClaimPriceType | null;
    lockedAveraging: boolean;
}

export class NewClaimDialogConfigBuilder {

    private config: NewClaimDialogConfig = {
        mode: NewClaimDialogMode.PARTNER_MODE,
        claim: null,
        partner: null,
        deliveryPeriod: null,
        disabledDeliveryPeriods: [],
        dailyRate: null,
        priceType: null,
        lockedAveraging: false
    };

    private constructor() {}

    public static builder(): NewClaimDialogConfigBuilder {
        return new NewClaimDialogConfigBuilder();
    }

    public build(): NewClaimDialogConfig {
        return this.config;
    }

    public withMode(mode: NewClaimDialogMode): NewClaimDialogConfigBuilder {
        this.config.mode = mode;

        return this;
    }

    public withClaim(claim: OfferClaimDto | null): NewClaimDialogConfigBuilder {
        this.config.claim = claim;

        return this;
    }

    public withAllPartner(all: PartnerWithDeliveryPeriods[]): NewClaimDialogConfigBuilder {
        if (!this.config.partner) {
            this.config.partner = {
                all: [],
                selected: null
            };
        }
        this.config.partner.all = all;

        return this;
    }

    public withSelectedPartner(selected: PartnerWithDeliveryPeriods): NewClaimDialogConfigBuilder {
        if (!this.config.partner) {
            this.config.partner = {
                all: [],
                selected: null
            };
        }
        this.config.partner.selected = selected;

        return this;
    }

    public withAllDeliveryPeriod(all: DeliveryPeriodForOffer[]): NewClaimDialogConfigBuilder {
        if (!this.config.deliveryPeriod) {
            this.config.deliveryPeriod = {
                all: [],
                selected: null
            };
        }
        this.config.deliveryPeriod.all = all;

        return this;
    }

    public withSelectedDeliveryPeriod(selected: DeliveryPeriodForOffer): NewClaimDialogConfigBuilder {
        if (!this.config.deliveryPeriod) {
            this.config.deliveryPeriod = {
                all: [],
                selected: null
            };
        }
        this.config.deliveryPeriod.selected = selected;

        return this;
    }

    public withDisabledDeliveryPeriods(disabledDeliveryPeriods: DeliveryPeriodForOffer[]): NewClaimDialogConfigBuilder {
        this.config.disabledDeliveryPeriods = disabledDeliveryPeriods;

        return this;
    }

    public withDailyRate(dailyRate: DailyRatesDto): NewClaimDialogConfigBuilder {
        this.config.dailyRate = dailyRate;

        return this;
    }

    public withPriceType(priceType: OfferClaimPriceType): NewClaimDialogConfigBuilder {
        this.config.priceType = priceType;

        return this;
    }

    public withLockedAveraging(lockedAveraging: boolean): NewClaimDialogConfigBuilder {
        this.config.lockedAveraging = lockedAveraging;

        return this;
    }
}
