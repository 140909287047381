<mat-card>
  <mat-card-content>
    <table *ngIf="businessParameters && businessParameters.length"
           class="table table-striped">
      <thead>
      <tr>
        <th><span>{{ 'businessParameters.model.key' | translate }}</span></th>
        <th><span>{{ 'businessParameters.model.value' | translate }}</span></th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let businessParameter of businessParameters">
        <td>{{ businessParameter.name }}</td>
        <td>{{ businessParameter.model.value }}</td>
        <td>
          <div class="pull-right">
            <button type="submit" class="btn btn-info btn-sm" (click)="openModal(businessParameter.model)">
              <span class="fa fa-pencil"></span>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
