import {Route} from '@angular/router';

import {ReportsComponent} from './reports.component';
import {MarketPriceGuardService} from '../price/market-price-guard.service';
import {UserRouteAccessService} from '../shared';
import {PartnerMandatoryGuard} from '../shared/partner-mandatory.guard';

export const REPORTS_ROUTE: Route[] = [
    {
        path: 'reports',
        component: ReportsComponent,
        data: {
            pageTitle: 'main.link.reports'
        },
        canActivate: [UserRouteAccessService, PartnerMandatoryGuard],
        resolve: {
            marketPrices: MarketPriceGuardService
        }
    }
];
