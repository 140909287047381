import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api';
import {DecimalPipe} from '@angular/common';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    public static readonly NO_VALUE_PRESENT_FILTER: string = "/NO_VALUE_PRESENT/";

    constructor(private translate: TranslateService,
                private numberPipe: DecimalPipe) {

    }

    createBooleanOptions(): SelectItem[] {
        return [
            {
                label: 'True',
                value: 'true'
            },
            {
                label: 'False',
                value: 'false'
            }
        ];
    }

    public static round(number: number, decimals: number): string | number | null | undefined {
        let precision: number = Math.pow(10, decimals);
        if (_.isNil(number) || isNaN(number)) {
            return number;
        }
        return (Math.round(number * precision) / precision)
            .toFixed(decimals);
    }

    /**
     * Receives two array and returns an array.
     * @returns The values from the first array will be replaced with null if its number (index + 1) exist in second array.
     * */
    public static setUnchangedIndexesToNull(values: number[], months: number []): number[] {
        if (_.isNil(values) || _.isNil(months)) {
            return [];
        }
        return values.map((value: number, index: number): number | null =>
            months.indexOf(index + 1) !== -1 ? value : null);
    }

    public createSelectItem = (typeName, prefix: String): SelectItem => {
        return {
            label: this.translate.instant(prefix + '.' + typeName),
            value: typeName
        };
    };

    // https://github.com/primefaces/primeng/blob/master/src/app/components/table/table.ts#L1162
    public smartTextFilter = (value, filter: string): boolean => {
        if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
            return true;
        }

        if (value === undefined || value === null) {
            return false;
        }

        let parts = filter.split(' ');

        for (let part of parts) {
            if (value.toString().toLowerCase().indexOf(part.toLowerCase()) === -1) {
                return false;
            }
        }
        return true;
    };

    public intervalFilter = (value, filter: string): boolean => {
        if (filter === undefined || filter === null || filter === '') {
            return true;
        }

        if (filter.includes('-')) {
            let res = filter.split('-');
            if (res.length != 2 || isNaN(Number(res[0])) || isNaN(Number(res[1]))) {
                return true;
            }
            return !(value < res[0] || value > res[1]);
        }

        return Number(filter) === value;
    }

    public betweenFilter = (value, filter: any): boolean => {
        if (filter === undefined || filter === null || filter === '') {
            return true;
        }

        if (value === undefined || value === null) {
            return true;
        }

        return +filter[0] <= +value && +value <= filter[1];
    }

    public getISODate(from: Date): string {
        return from.toLocaleDateString()
            .replace(/ /g, '')
            .replace(/\./g, '-')
            .substring(0, 10);
    }

    public atStartOfDay(date: Date) {
        const dayStart = new Date(date);
        dayStart.setHours(0, 0, 0, 0);
        return dayStart;
    }

    public onFocusoutBlur($event: any): void {
        const inputElement: HTMLInputElement = $event.target as HTMLInputElement;
        inputElement.value = this.numberPipe.transform(parseFloat(inputElement.value.replace(',', '.')), '1.2-2');
    }

    public noAssignmentOrDefault(value: string | null | undefined): any {
        return value === this.translate.instant('global.filter.' + UtilService.NO_VALUE_PRESENT_FILTER) ?
            UtilService.NO_VALUE_PRESENT_FILTER :
            value;
    }

    public static hasUnsetElement(...elements: any[]): boolean {
        for (const element of elements) {
            if (_.isNil(element)) {
                return true;
            }
        }

        return false;
    }

}
