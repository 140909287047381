import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {
    BusinessCalendar,
    BusinessDayType
} from '../../coverage-transactions/scheduled-quotation-dialog/scheduled-quotation.types';
import {Moment} from 'moment';
import * as moment from 'moment';;

@Injectable({
    providedIn: 'root'
})
export class BusinessCalendarService {

    public holidays: Moment[] = [];
    public workdays: Moment[] = [];

    constructor(private http: HttpClient) {}

    public getBusinessCalendar(): Observable<BusinessCalendar[]> {
        return this.http.get<any[]>('api/business_calendar');
    }

    /**
     * Load and fill the holidays and workdays array for later use. You can use it in forkJoin() methods too.
     * @return Subject that completes after the data is loaded.
     * */
    public loadWorkdaysAndHolidays(): Subject<void> {
        const isFinished: Subject<void> = new Subject();
        this.getBusinessCalendar().subscribe((days: BusinessCalendar[]): void => {
            this.holidays = days.filter((day: BusinessCalendar): boolean => day.dayType === BusinessDayType.HOLIDAY)
                .map((day: BusinessCalendar) => moment(day.day));
            this.workdays = days.filter((day: BusinessCalendar): boolean => day.dayType === BusinessDayType.WORKDAY)
                .map((day: BusinessCalendar) => moment(day.day));

            isFinished.next();
            isFinished.complete();
        });

        return isFinished;
    }

    /**
     * Determines is the given day workday or not. The day is selectable if (workday and not holiday) or (weekend and workday).
     * You can pass this method to date-pickers.
     * Before you use it, you have to load the workdays and holidays by inviting loadWorkdaysAndHolidays() method.
     *
     *  @param day The given day, that you want to check.
     *  @return True if the given day is a workday, false if the given day is a holiday or weekend.
     * */
    public isDaySelectable(day: moment.Moment): boolean {
        return (!this.isWeekend(day) && !this.isDayInList(this.holidays, day)) ||
            (this.isWeekend(day) && this.isDayInList(this.workdays, day));
    }

    /**
     * Determines the next X workday from the current time. It watches holidays, workdays and weekends too.
     *
     * @param workDays Number of workdays need to pass from today.
     * @param countToday True if we want to count today too, false if we want to ignore today.
     * @return Moment Returns the determined workday after X workday day passed.
     * */
    public determineNextXWorkDay(workDays: number, countToday: boolean): Moment {
        const today: Moment = moment().startOf('day');
        let numberOfWorkDays: number = this.isDaySelectable(today) && countToday ? 1 : 0;

        while (numberOfWorkDays < workDays) {
            today.add(1, 'day');
            if (this.isDaySelectable(today)) {
                numberOfWorkDays++;
            }
        }

        return today;
    }

    /**
     * Calculates how many workdays are in the given interval.
     *
     * @param start Interval start.
     * @param end Interval end.
     * @return Number of workdays between start and end dates.
     * */
    public calculateNumberOfWorkdaysBetween(start: Moment, end: Moment): number {
        let numberOfWorkDays: number = 0;

        while (start.isBefore(end)) {
            if (this.isDaySelectable(start)) {
                numberOfWorkDays++;
            }
            start.add(1, 'day');
        }

        return numberOfWorkDays;
    }

    private isWeekend(day: moment.Moment): boolean {
        const dayOfWeek: number = day.day();
        return dayOfWeek === 0 || dayOfWeek === 6;
    }

    private isDayInList(list: Moment[], day: Moment): boolean {
        return list.some((item: Moment) => moment(day).isSame(item, 'day'));
    }

}
