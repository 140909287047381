<header>
  <h2 jhiTranslate="footer.siteMap"></h2>
</header>

<mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl" class="cez-tree">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li *ngIf="node.isVisible" class="mat-tree-node">
            <button mat-icon-button disabled></button>
            <a *ngIf="node.url" [routerLink]="'/' + node.url">{{node.localizedTextFromDb ? node.text : node.text |
                translate}}</a>
            <span *ngIf="!node.url">{{node.localizedTextFromDb ? node.text : node.text | translate}}</span>
        </li>
    </mat-tree-node>

    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasNestedChild">
        <li>
            <div class="mat-tree-node">
                <button mat-icon-button matTreeNodeToggle>
                    <mat-icon class="mat-icon-rtl-mirror">
                        {{nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                    </mat-icon>
                </button>
                <a *ngIf="node.url" [routerLink]="'/' + node.url">{{node.localizedTextFromDb ? node.text : node.text |
                    translate}}</a>
                <span *ngIf="!node.url">{{node.localizedTextFromDb ? node.text : node.text | translate}}</span>
            </div>
            <ul [class.cez-tree-invisible]="!nestedTreeControl.isExpanded(node)">
                <ng-container matTreeNodeOutlet></ng-container>
            </ul>
        </li>
    </mat-nested-tree-node>
</mat-tree>
