import { PodGroupModel } from './pod-group.model';

export class PodGroupCategoryModel {
    id: number;
    name: string;
    podGroups: PodGroupModel[];
    editMode: boolean;
    autoCategorize: boolean;
    selectedAutocategorizeType: string;
    autoCategoryType: string;
}
