import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoiceResourceDTO } from '../model/invoice-reosurce.model';

@Injectable({
    providedIn: 'root'
})
export class InvoiceResourceService {

    constructor(private http: HttpClient) {

    }

    public getInvoiceResources(): Observable<InvoiceResourceDTO[]> {
        return this.http.get<InvoiceResourceDTO[]>('/api/invoice-resource');
    }

    public createInvoiceResource(invoiceResource: InvoiceResourceDTO) {
        return this.http.post<InvoiceResourceDTO>('/api/invoice-resource', invoiceResource);
    }

    public updateInvoiceResource(invoiceResource: InvoiceResourceDTO) {
        return this.http.put<InvoiceResourceDTO>('/api/invoice-resource', invoiceResource);
    }

    public deleteInvoiceResource(id: number) {
        return this.http.delete(`/api/invoice-resource/${id}`);
    }
}
