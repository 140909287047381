<div class="pull-center">
    <div class="col-md-8 offset-md-2">
        <div class="alert alert-danger" *ngIf="showError" [innerHTML]="errorText">         
        </div>
    </div>

    <div class="row justify-content-md-center">
                    <span>
                        <a [ngClass]="currLang=== 'en' ? '': 'text-muted'"
                           (click)="changeLanguage('hu')">{{'login.changeLangToHun' | translate}}</a>
                        |
                        <a [ngClass]="currLang=== 'hu' ? '' : 'text-muted'"
                           (click)="changeLanguage('en')"> {{'login.changeLangToEn' | translate}}</a>
                    </span>
    </div>

    <div class="col-md-4 offset-md-4">
        <form class="form" role="form" (ngSubmit)="login()">
            <div class="form-group" *ngIf="twoFactorAuthShow">
                <label for="twoFactorCode" jhiTranslate="two-factor-auth.authCode"></label>
                <input type="text" class="form-control" name="twoFactorCode" id="twoFactorCode" [(ngModel)]="twoFactorCode" placeholder="{{'two-factor-auth.authPlaceholder' | translate}}">
            </div>
            <ng-container *ngIf="!twoFactorAuthShow">
                <div class="form-group">
                    <label for="username" jhiTranslate="global.form.username">Login</label>
                    <input type="text" class="form-control" name="username" id="username" placeholder="{{'global.form.username.placeholder' | translate}}"
                        [(ngModel)]="username">
                </div>
                <div class="form-group">
                    <label for="password" jhiTranslate="login.form.password">Password</label>
                    <input type="password" class="form-control" name="password" id="password" placeholder="{{'login.form.password.placeholder' | translate}}"
                        [(ngModel)]="password">
                </div>
            </ng-container>
            <button type="submit" [disabled]="loading" class="btn btn-primary" jhiTranslate="login.form.button">Sign in</button>
        </form>

        <p></p>
        <ng-container *ngIf="!twoFactorAuthShow">
            <div class="alert alert-warning">
                <a class="alert-link" (click)="requestResetPassword()" jhiTranslate="login.password.forgot">Did you forget your password?</a>
            </div>
            <div class="alert alert-warning">
                <span jhiTranslate="global.messages.info.register.noaccount">You don't have an account yet?</span>
                <a class="alert-link pl-1" (click)="register()" jhiTranslate="global.messages.info.register.link">Register
                    a new account</a>
            </div>
        </ng-container>
    </div>
</div>
