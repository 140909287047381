import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {NgForm} from '@angular/forms';
import {NgbModal, NgbNav} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {NgxMaterialTimepickerTheme} from 'ngx-material-timepicker';
import {DeliveryPeriod} from '../../shared/dto/delivery.periods';
import {PeriodicalPodModel} from '../../shared/dto/periodical.pod.model';
import {TolerancePeriod} from '../../shared/dto/tolerance.limits';
import {BootstrapAlertService} from '../../shared/services/bootstrap-alert.service';
import {DeliveryPeriodModalComponent} from '../delivery-period-modal/delivery-period-modal.component';
import {AgreementModel} from '../dto/agreement.model';
import {ContractModel} from '../dto/contract.model';
import {PartnerDetailsComponent} from '../partner-details/partner-details.component';
import {
  ClosingProductType,
  Language,
  ProductType,
  Scheduling,
  SchedulingSettlementType,
  TariffType,
  ToleranceSettlementType
} from './agreement-enums.model';
import {CanLeave, CanLeaveService} from "../can-leave/can-leave.service";
import {Observable} from "rxjs";
import {ContractModalComponent} from "../../admin/contract/contract-modal/contract-modal.component";
import {PodModalComponent} from "../../admin/contract/pod-modal/pod-modal.component";
import {ToleranceLimitModalComponent} from "../../admin/contract/tolerance-limit-modal/tolerance-limit-modal.component";
import {ExcelImportResult} from "../time-series/model/excel-import-result.model";
import {DialogService} from '../dialog/dialog.service';
import {PodMaintenanceImportResultModel} from '../../pod-maintenance/model/pod-maintenance-import-result.model';
import {Violation} from '../dto/validation.exception.model';

@Component({
    selector: 'jhi-agreement-details',
    templateUrl: './agreement-details.component.html',
    styles: [],
    exportAs: "AgreementDetails"
})
export class AgreementDetailsComponent implements OnInit, CanLeave {

    public prepaid;
    public groupTolerance;
    public bankGuarantee;
    public hudexDAccess;
    public hudexDOneMarketAccess;
    public averagingOtc;
    public averagingHudex;
    public yearlyProducts;
    public quarterlyProducts;
    public monthlyProducts;
    public weeklyProducts;
    public hupxSpotAccess;
    public clickModification;
    editMode: boolean;
    pods: PeriodicalPodModel[];
    tempIdCounter: number = -1;
    importPodMode: boolean;
    selectedFilesForPodImport: File;
    showGroupMembers = false;

    languages = Language;
    tariffTypes = TariffType;
    toleranceSettlementTypes = ToleranceSettlementType;
    closingProductTypes = ClosingProductType;
    productTypes = ProductType;
    schedulingTypes = Scheduling;
    schedulingSettlementTypes = SchedulingSettlementType;


    active:number = 1;
    cezTimePickerTheme: NgxMaterialTimepickerTheme = {
        container: {
            buttonColor: '#f24f00'
        },
        dial: {
            dialBackgroundColor: '#f24f00'
        },
        clockFace: {
            clockHandColor: '#f24f00'
        }
    };


    @Input() controlsState: { [name: string]: boolean } = {};
    @Output() controlsStateChange: EventEmitter<{ [name: string]: boolean }> = new EventEmitter();
    @Input() agreement: AgreementModel;
    @Output() agreementChange: EventEmitter<AgreementModel> = new EventEmitter();
    @Output() tabChange: EventEmitter<string> = new EventEmitter();
    @Input() userView: boolean = false;
    @ViewChild('agreementDetailsForm', {static: false}) public form: NgForm;
    @ViewChild('servicesForm', {static: false}) public servicesForm: NgForm;
    @ViewChild('partnerDetailsComponent', {static: true}) public partnerDetailsComponent: PartnerDetailsComponent;
    @ViewChild('navInner', {static: true}) navInner: NgbNav;

    constructor(public translateService: TranslateService,
                private modalService: NgbModal,
                private http: HttpClient,
                private bootstrapAlertService: BootstrapAlertService,
                private canLeaveService: CanLeaveService) {}


    ngOnInit() {
        this.translateService.get("agreement.agreementData.mainData.prepaid").subscribe(translation => {
            this.prepaid = translation;
        });
        this.translateService.get("agreement.agreementData.mainData.groupTolerance").subscribe(translation => {
            this.groupTolerance = translation;
        });
        this.translateService.get("agreement.agreementData.mainData.bankGuarantee").subscribe(translation => {
            this.bankGuarantee = translation;
        });
        this.navInner.navChange.subscribe((e) => {
           this.tabChange.emit(e.nextId);
        });
        this.translateService.get("agreement.agreementData.mainData.hudexDAccess").subscribe(translation => {
            this.hudexDAccess = translation;
        });
        this.translateService.get("agreement.agreementData.mainData.hudexDOneMarketAccess").subscribe(translation => {
            this.hudexDOneMarketAccess = translation;
        });
        this.translateService.get("agreement.agreementData.mainData.averagingOtc").subscribe(translation => {
            this.averagingOtc = translation;
        });
        this.translateService.get("agreement.agreementData.mainData.averagingHudex").subscribe(translation => {
            this.averagingHudex = translation;
        });
        this.translateService.get("agreement.agreementData.mainData.yearlyProducts").subscribe(translation => {
            this.yearlyProducts = translation;
        });
        this.translateService.get("agreement.agreementData.mainData.quarterlyProducts").subscribe(translation => {
            this.quarterlyProducts = translation;
        });
        this.translateService.get("agreement.agreementData.mainData.monthlyProducts").subscribe(translation => {
            this.monthlyProducts = translation;
        });
        this.translateService.get("agreement.agreementData.mainData.weeklyProducts").subscribe(translation => {
            this.weeklyProducts = translation;
        });
        this.translateService.get("agreement.agreementData.mainData.hupxSpotAccess").subscribe(translation => {
            this.hupxSpotAccess = translation;
        });
        this.translateService.get("agreement.agreementData.mainData.clickModification").subscribe(translation => {
            this.clickModification = translation;
        });
    }

    canLeave(): Observable<boolean> {
        return this.canLeaveService.canLeave();
    }

    isDisabled(controlName): boolean {
        return this.userView && this.controlsState[controlName] !== true;
    }

    enable(controlName) {
        if (!this.userView)
            return;
        this.controlsState[controlName] = true;
    }

    contractEditor(model: ContractModel) {
        let edit: boolean = false;
        if (!model) {
            model = new ContractModel();
            model.id = this.tempIdCounter--;
        } else
            edit = true;

        if (this.agreement.contracts == null)
            this.agreement.contracts = [];

        const modalRef = this.modalService.open(ContractModalComponent, {backdrop: "static"});
        modalRef.componentInstance.model = model;
        modalRef.componentInstance.modalClose.subscribe((savedModel: ContractModel) => {
            if (edit)
                this.agreement.contracts.filter(r => r.id == savedModel.id)[0] = savedModel;
            else
                this.agreement.contracts.push(savedModel);
            modalRef.close();
            this.onChange();
        });
    }

    podEditor(model: PeriodicalPodModel) {
        let edit: boolean = false;
        if (!model) {
            model = new PeriodicalPodModel();
            model.id = this.tempIdCounter--;
        } else
            edit = true;

        if (this.agreement.pods == null)
            this.agreement.pods = [];

        const modalRef = this.modalService.open(PodModalComponent, {backdrop: "static"});
        modalRef.componentInstance.model = model;
        modalRef.componentInstance.modalClose.subscribe((savedModel: PeriodicalPodModel) => {
            if (edit)
                this.agreement.pods.filter(r => r.id == savedModel.id)[0] = savedModel;
            else
                this.agreement.pods.push(savedModel);
            modalRef.close();
            this.onChange();
        });
    }

    deleteTolerance(id: number) {
        this.agreement.tolerancePeriods = this.agreement.tolerancePeriods.filter(r => r.id != id);
        this.onChange();
    }


    deleteDeliveryPeriod(id: number) {
        this.agreement.deliveryPeriods = this.agreement.deliveryPeriods.filter(r => r.id != id);
        this.onChange();
    }

    deleteContract(id: number) {
        this.agreement.contracts = this.agreement.contracts.filter(r => r.id != id);
        this.onChange();
    }

    podDelete(id: number) {
        this.agreement.pods = this.agreement.pods.filter(r => r.id != id);
        this.onChange();
    }


    agreementPodExport() {
        if (this.agreement.id) {
            window.open("api/agreement/" + this.agreement.id + "/pods/export", "_blank");
        }
    }

    agreementPodImport() {
        if (this.agreement.id) {
            this.importPodMode = true;
        }
    }

    podImportCancel() {
        this.setPodImportToDefault();
    }


  public addFile(fileInput: HTMLInputElement) {
    fileInput.value = '';
    fileInput.click();
  }

  public onUpload(event: { file: File, success: boolean, response?: ExcelImportResult, error?: HttpErrorResponse }) {
      this.selectedFilesForPodImport = event.file;
    this.onChange();
  }


    setPodImportToDefault() {
        this.importPodMode = false;
    }

    podImportOk() {
        console.log("Importing pods for agreement: " + this.agreement.id);
        if (this.selectedFilesForPodImport) {
            let data = new FormData();
            data.append("file", this.selectedFilesForPodImport);
            this.http.post('/api/agreement/' + this.agreement.id + '/pods/import', data).subscribe((pods: PeriodicalPodModel[]) => {
                console.log("Pods for agreement [id: " + this.agreement.id + "] were successfully imported.");
                this.bootstrapAlertService.showSuccessAdmin("Pods for agreement [id: " + this.agreement.id + "] were successfully imported.");
                this.agreement.pods = pods;
            });
        }
    }

    toleranceLimitEditor(model: TolerancePeriod) {
        let edit: boolean = false;
        if (!model) {
            model = new TolerancePeriod();
            model.id = this.tempIdCounter--;
        } else
            edit = true;

        if (this.agreement.tolerancePeriods == null)
            this.agreement.tolerancePeriods = [];

        const modalRef = this.modalService.open(ToleranceLimitModalComponent, {backdrop: "static"});
        modalRef.componentInstance.model = model;
        modalRef.componentInstance.modalClose.subscribe((savedModel: TolerancePeriod) => {
            if (edit)
                this.agreement.tolerancePeriods.filter(r => r.id == savedModel.id)[0] = savedModel;
            else
                this.agreement.tolerancePeriods.push(savedModel);
            modalRef.close();
            this.onChange();
        });
    }

    deliveryPeriodEditor(model: DeliveryPeriod) {
        let edit: boolean = false;
        if (!model) {
            model = new DeliveryPeriod();
            model.id = this.tempIdCounter--;
        } else
            edit = true;

        if (this.agreement.deliveryPeriods == null)
            this.agreement.deliveryPeriods = [];

        const modalRef = this.modalService.open(DeliveryPeriodModalComponent, {backdrop: "static"});
        modalRef.componentInstance.model = model;
        modalRef.componentInstance.productType = this.agreement.productType;
        modalRef.componentInstance.goO = this.agreement.services.guaranteeOfOrigin;
        modalRef.componentInstance.modalClose.subscribe((savedModel: DeliveryPeriod) => {
            if (edit)
                this.agreement.deliveryPeriods.filter(r => r.id == savedModel.id)[0] = savedModel;
            else
                this.agreement.deliveryPeriods.push(savedModel);
            modalRef.close();
            this.onChange();
        });
    }

    onChange(): void {
        this.canLeaveService.setModified({allowedTabsets: ['agreement-details']});
    }
}
