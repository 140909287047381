import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MaxTotalHedgeCapacity} from '../dto/max-total-hedge-capacity.model';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AgreementService {

    constructor(private http: HttpClient) {
    }

    public getMaxTotalHedgeCapacity(deliveryPeriodId: number): Observable<MaxTotalHedgeCapacity> {
        return this.http.get<MaxTotalHedgeCapacity>(`/api/agreement/deliveryPeriod/${deliveryPeriodId}/max-total-hedge-capacity`);
    }

}
