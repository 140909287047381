import {Route} from '@angular/router';


import {AgreementUserComponent} from './agreement-user.component';
import {PodGroupPageComponent} from './pod-group-page/pod-group-page.component';
import {PartnerMandatoryGuard} from '../shared/partner-mandatory.guard';
import {UserRouteAccessService} from '../shared';
import {CanDeactivateGuard} from '../shared/can-leave/can-deactivate.guard';


export const AGREEMENT_ROUTE: Route[] = [
    {
        path: 'agreements',
        component: AgreementUserComponent,
        data: {
            permission: 'AGREEMENT_SCREEN',
            pageTitle: 'main.link.contract'
        },
        canActivate: [UserRouteAccessService, PartnerMandatoryGuard],
        canDeactivate: [CanDeactivateGuard]
    },
    {
        path: 'agreements/:id',
        component: AgreementUserComponent,
        data: {
            permission: 'AGREEMENT_SCREEN',
            pageTitle: 'main.link.contract'
        },
        canActivate: [UserRouteAccessService, PartnerMandatoryGuard],
        canDeactivate: [CanDeactivateGuard]
    },
    {
        path: 'podgroup',
        component: PodGroupPageComponent,
        data: {
            permission: 'POD_GROUP_SCREEN',
            softPermissions: ['POD_GROUP_USER_READ', 'POD_GROUP_SYSTEM_READ', 'POD_GROUP_ADMIN_READ'],
            pageTitle: 'agreement.pod_groups.title'
        },
        canActivate: [UserRouteAccessService, PartnerMandatoryGuard],
        canDeactivate: [CanDeactivateGuard]
    }
];

