import { ProductDto } from '../../../price/model/productDto';
import { AdditionalDiagramTypeDTO } from './additional-diagram-type.model';
import { DiagramType } from './diagram-type';
import { SpotDto } from '../../../price/model/spot-dto';

export class AdditionalDiagramGroupDTO {
    diagramGroupId?: number;
    groupNameEN: string;
    groupNameHU: string;
    groupDescriptionEN: string;
    groupDescriptionHU: string;
    diagramType: DiagramType;
    additionalDiagramTypes?: AdditionalDiagramTypeDTO[];
    products?: ProductDto[];
    spots?: SpotDto[];
}
