import { Moment } from 'moment';
import { DateInterval } from '../../shared/dto/date-interval.model';
import {TimeInterval} from "../../shared/time-series/time-series-interval/time-interval.model";

export class IntervalUtil {


    static sameIntervals(intervals1: DateInterval[], intervals2: DateInterval[]): boolean {
        if (!Boolean(intervals1) && !Boolean(intervals2)) {
            return true;
        } else if (!Boolean(intervals1) && Boolean(intervals2)) {
            return false;
        } else if (Boolean(intervals1) && !Boolean(intervals2)) {
            return false;
        } else if (intervals1.length !== intervals2.length) {
            return false;
        }

        const intervalsLength = intervals1.length;
        for (let index = 0; index < intervalsLength; index++) {
            if (IntervalUtil.differentInterval(intervals1[index], intervals2[index])) {
                return false;
            }
        }

        return true;
    }

    static differentInterval(interval1: DateInterval, interval2: DateInterval) {
        if (!Boolean(interval1) && !Boolean(interval2)) {
            return false;
        }

        return (!Boolean(interval1) && Boolean(interval2)) ||
            (Boolean(interval1) && !Boolean(interval2)) ||
            this.differentMoments(interval1.from, interval2.from) ||
            this.differentMoments(interval1.to, interval2.to);
    }

    static differentMoments(date1: Moment, date2: Moment): boolean {
        if (!Boolean(date1) && !Boolean(date2)) {
            return false;
        }

        return (!Boolean(date1) && Boolean(date2)) ||
            (Boolean(date1) && !Boolean(date2)) ||
            !date1.isSame(date2);
    }

    public static mergeIntervals(first: TimeInterval, second: TimeInterval): TimeInterval {
        if (first.resolution !== second.resolution) {
            return Object.assign({}, second);
        }

        return {
            start: first.start.diff(second.start) < 0 ? first.start : second.start,
            end: first.end.diff(second.end) < 0 ? second.end : first.end,
            resolution: second.resolution
        };
    }

}
