import { Pipe, PipeTransform } from '@angular/core';
import { StateStorageService } from '../auth';

@Pipe({name: 'doesNotHavePermission'})
export class NegatedPermissionPipe implements PipeTransform {

    constructor(private stateStorageService: StateStorageService) {
    }

    transform(permission: string): boolean {
        const permissions = this.stateStorageService.getEffectivePermissions();
        if (permissions) {
            return permissions.indexOf(permission) === -1;
        } else {
            return true;
        }
    }

}
