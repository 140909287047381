import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ReportTabsUtil} from './util/report-tabs.util';

import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {PodService} from '../shared/services/pod.service';
import {StateStorageService} from '../shared/auth';
import {PermissionOverlayPopupComponent} from '../shared/permission-overlay/permission-overlay-popup/permission-overlay-popup.component';
import {NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: 'jhi-reports',
    templateUrl: './reports.component.html',
    styles: []
})
export class ReportsComponent implements OnInit {

    readonly consumption: string = "consumption";
    readonly invoice: string = "invoice";
    readonly price: string = "price";
    readonly tolerance: string = "tolerance";

    public isConsumptionNewAllowed: boolean;
    public isInvoiceAllowed: boolean;
    public isPriceAllowed: boolean;
    public isToleranceAllowed: boolean;
    public permissions: string[];

    constructor(private podService: PodService,
                private translateService: TranslateService,
                private stateStorage: StateStorageService,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.permissions = this.stateStorage.getEffectivePermissions();
        this.isConsumptionNewAllowed = ReportTabsUtil.hasPermission(this.permissions, ReportTabsUtil.consumptionPermission);
        this.isInvoiceAllowed = ReportTabsUtil.hasPermission(this.permissions, ReportTabsUtil.invoicePermission);
        this.isPriceAllowed = ReportTabsUtil.hasPermission(this.permissions, ReportTabsUtil.pricePermission);
        this.isToleranceAllowed = ReportTabsUtil.hasPermission(this.permissions, ReportTabsUtil.tolerancePermission);

        this.checkSoftPermissionsForActiveTab(this.consumption);
    }

    public tabChanged(event: NgbNavChangeEvent): void {
        this.checkSoftPermissionsForActiveTab(event.activeId);
    }

    private checkSoftPermissionsForActiveTab(activeId: string): void {
        if (this.noSoftPermission(activeId)) {
            this.dialog.open(PermissionOverlayPopupComponent, {
                disableClose: true,
                panelClass: 'session-warning-popup'
            }).afterClosed();
        }
    }

    private noSoftPermission(activeId: string): boolean {
        switch (activeId) {
            case this.consumption:
                return this.isConsumptionNewAllowed && !ReportTabsUtil.hasSoftPermissionPermission(this.permissions, ReportTabsUtil.consumptionSoftPermission);
            case this.invoice:
                return this.isInvoiceAllowed && !ReportTabsUtil.hasSoftPermissionPermission(this.permissions, ReportTabsUtil.invoiceSoftPermission);
            case this.price:
                return this.isPriceAllowed && !ReportTabsUtil.hasSoftPermissionPermission(this.permissions, ReportTabsUtil.priceSoftPermission);
            case this.tolerance:
                return this.isToleranceAllowed && !ReportTabsUtil.hasSoftPermissionPermission(this.permissions, ReportTabsUtil.toleranceSoftPermission);
        }
    }

}
