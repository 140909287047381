import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CONTACT_PAGE_ROUTE } from "./contact-page.route";
import { ContactPageComponent } from './contact-page.component';
import {CezPortalSharedModule} from '../shared';

@NgModule({
  imports: [
    CommonModule,
    CezPortalSharedModule,
    RouterModule.forRoot(CONTACT_PAGE_ROUTE, { useHash: true })
  ],
  declarations: [ContactPageComponent],
  exports: [ContactPageComponent]
})
export class ContactPageModule { }
