<div class="modal-header">
    <h4 class="modal-title">{{'global.email.edit' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="this.modalClose.emit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form name="emailEditForm" role="form" #emailEditForm="ngForm" (ngSubmit)="save(emailEditForm)">
    <div class="modal-body">
        <div class="form-group">
            <label>{{'global.email.name' | translate}}</label>
            <input name="templateName"
                   placeholder="{{ 'email.tabs.template.table.content.' + template.templateName + '.name' | translate }}"
                   class="form-control" disabled>
        </div>
        <div class="form-group">
            <label>{{'global.email.mailFrom' | translate}}</label>
            <input name="mailFrom" [ngModel]="template.mailFrom" class="form-control" type="email" multiple>
        </div>
        <div class="form-group">
            <label>{{'global.email.mailTo' | translate}}</label>
            <textarea class="form-control"
                      name="mailTo"
                      rows="3"
                      [ngModel]="template.mailTo"
                      [disabled]="template.mailToEditable ? null: true">
            </textarea>
        </div>
        <div class="form-group">
            <label>{{'global.email.subjectHu' | translate}}</label>
            <input name="subjectHu" [ngModel]="template.subject.textHU" class="form-control" required>
        </div>
        <div class="form-group">
            <label>{{'global.email.textHu' | translate}}</label>
            <quill-editor name="textHU" rows="10" [ngModel]="template.message.textHU" required>
            </quill-editor>
        </div>
        <div class="form-group">
            <label>{{'global.email.subjectEn' | translate}}</label>
            <input name="subjectEn" [ngModel]="template.subject.textEN" class="form-control" required>
        </div>
        <div class="form-group">
            <label>{{'global.email.textEn' | translate}}</label>
            <quill-editor name="textEN" rows="10" [ngModel]="template.message.textEN" required>
            </quill-editor>
        </div>
        <div class="form-group row">
            <div class="col-sm-8">
                <p-checkbox *ngIf="template.key === 'ADMIN_MESSAGE_TO_USERS'"
                            label="{{'global.email.send' | translate}}"
                            name="send" [(ngModel)]="send" binary="true"></p-checkbox>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary"
                [disabled]="emailEditForm.form.invalid || isSaving">{{'entity.action.save' | translate}}</button>
    </div>
</form>
