<header class="docs">
  <h2>{{'main.link.useful' | translate}}</h2>
  <aside>
    <button class="btn btn-primary" (click)="openModal(null)">{{'main.link.newLink' | translate}}</button>
  </aside>
</header>

<!--<div class="links" *ngFor="let category of linkList">
  <header>
    <h1>{{category.name}}</h1>
  </header>-->
<section class="newsList">
  <article *ngFor="let article of linkList" class="tile">
    <img class="rectangle80 ml-3" src="{{newsService.getPicture(article.picture)}}" alt="">
    <div>
      <header>
          <a href="{{article.linkUrl}}" class="nav-link">
          <strong>{{article.title}}</strong>
        </a>
      </header>
      <p>
        {{article.description}}
      </p>
      <footer>
        <div class="pull-right">
          <button type="submit" class="btn btn-success btn-sm" role="button" *ngIf="!article.visible" (click)="publishLink(article,true)">
                        <span class="fa fa-cloud-upload"></span>                           
                    </button>
          <button type="submit" class="btn btn-warning btn-sm" role="button" *ngIf="article.visible" (click)="publishLink(article,false)">
                        <span class="fa fa-cloud-download"></span>                           
                    </button>
          <button type="submit" class="btn btn-info btn-sm" (click)="openModal(article)">
                        <span class="fa fa-pencil"></span>                           
                    </button>
          <button type="submit" class="btn btn-danger btn-sm" (click)="deleteLink(article)">
                        <span class="fa fa-remove"></span>                           
                    </button>
        </div>
      </footer>
    </div>
  </article>
</section>
<!--</div>-->
