<div id="sidebar-wrapper">
    <ul class="sidebar-nav">
        <li class="sidebar-brand">
            <a href="#" jhiTranslate="main.link.homepage">
                       Nyitó oldal
                    </a>
        </li>
        <li>
            <a href="#" jhiTranslate="main.link.news">Hírek</a>
        </li>
        <li>
            <a href="#/documents" jhiTranslate="main.link.docs">Dokumentumtár</a>
        </li>
        <li>
            <a href="#/link" jhiTranslate="main.link.useful">Hasznos linkek</a>
        </li>
        <li>
            <a href="#" jhiTranslate="main.link.services">Termékek és szolgáltatások</a>
        </li>
        <li>
            <a href="#" (click)="togglePersonalMenu()">
                <span jhiTranslate="main.link.personal"></span>
                <i class="fa fa-angle-down" aria-hidden="true" *ngIf="!personalOpened"></i>
                <i class="fa fa-angle-up" aria-hidden="true" *ngIf="personalOpened"></i>
            </a>
            <ul *ngIf="personalOpened">
                <li>
                    <a href="#" jhiTranslate="main.link.contract"> </a>
                </li>
                <li>
                    <a href="#" jhiTranslate="main.link.consumption"></a>
                </li>
                <li>
                    <a href="#" jhiTranslate="main.link.marketPrice"></a>
                </li>
                <li>
                    <a href="#" jhiTranslate="main.link.tolerancy"> </a>
                </li>
                <li>
                    <a href="#" jhiTranslate="main.link.scheduling"></a>
                </li>
                <li>
                    <a href="#" jhiTranslate="main.link.invoiceReport"> </a>
                </li>
                <li>
                    <a href="#" jhiTranslate="main.link.rhd"></a>
                </li>
            </ul>
        </li>
        <li>
            <a href="#" (click)="togglepReportMenu()">Riportok  
                <span jhiTranslate="main.link.reports"></span>
                <i class="fa fa-angle-down" aria-hidden="true" *ngIf="!reportsOpened"></i>
                  <i class="fa fa-angle-up" aria-hidden="true" *ngIf="reportsOpened"></i>
            </a>
            <ul *ngIf="reportsOpened">
                <li>
                    <a href="#" jhiTranslate="main.link.pricereport"> </a>
                </li>
                <li>
                    <a href="#" jhiTranslate="main.link.riskreport"></a>
                </li>
            </ul>
        </li>
    </ul>
</div>
