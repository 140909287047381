import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DOCUMENT_ROUTE, DocumentCommonComponent, DocumentCompanyComponent } from './index';
import { RouterModule } from '@angular/router';
import {CezPortalSharedModule} from '../shared';


@NgModule({
  imports: [CommonModule, FormsModule, CezPortalSharedModule, RouterModule.forRoot(DOCUMENT_ROUTE, { useHash: true })],
  declarations: [DocumentCommonComponent, DocumentCompanyComponent]

})
export class DocumentModule { }
