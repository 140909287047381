import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'side-bar',
    templateUrl: './sidebar.component.html',
    styleUrls: [
        'sidebar.scss'
    ]
})
export class SidebarComponent implements OnInit {
    public personalOpened: boolean = false;
    public reportsOpened: boolean = false;

    constructor() {
    }

    ngOnInit() {

    }

    togglePersonalMenu() {
        this.personalOpened = !this.personalOpened;
    }
    togglepReportMenu() {
        this.reportsOpened = !this.reportsOpened;
    }
}
