<header>
    <h2 jhiTranslate="two-factor-auth.activate-title"></h2>
</header>

<div class="mt-4" jhiTranslate="two-factor-auth.activate-body"></div>

<form (ngSubmit)="save()" name="TwoFactorActivateForm" role="form" #TwoFactorActivateForm="ngForm">
    <div class="row">
        <div class="form-group col-md-6">
            <label jhiTranslate="two-factor-auth.generatedKey"></label>
            <p><img src="api/account/two_factor/key/qr_code?width=200&height=200&random={{randomId}}"/></p>
        </div>
        <div class="form-group col-md-6">
            <label jhiTranslate="two-factor-auth.generatedKeyBase32"></label>
            <p><strong>{{twoFactorBase32Code}}</strong></p>
        </div>
    </div>
    <div class="form-group">
        <label jhiTranslate="two-factor-auth.activationKey"></label>
        <input type="text" required name="activationKey" class="form-control" [(ngModel)]="activationKey" placeholder="{{'two-factor-auth.generatedKeyPlaceholder' | translate}}"
        />
    </div>
    <button type="submit" class="btn btn-primary pull-right" [disabled]="TwoFactorActivateForm.form.invalid">OK</button>

</form>
