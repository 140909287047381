import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {CountdownModule} from "ngx-countdown";
import {CezPortalSharedModule} from "../../shared";
import {CART_ROUTE} from "./cart.route";
import {CartComponent} from "./cart.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(CART_ROUTE),
        CountdownModule,
        CezPortalSharedModule
    ],
    declarations: [
        CartComponent
    ]
})
export class CartModule {
}
