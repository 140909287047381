<h2>
  POD admin</h2>

<header class="docs my-3">
  <input type="text" placeholder="Search" class="px-2" />
  <aside>
    <button class="btn btn-primary">New POD</button>
  </aside>
</header>


<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th><span>POD azonosító</span></th>
        <th><span>POD típusa</span></th>
        <th><span>POD lokációja</span></th>
        <th><span>POD csoport neve</span></th>
        <th><span></span></th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>2017-S-0021</td>
        <td>idősoros</td>
        <td>1236, Budapest Lorem ipsum utca 23.</td>
        <td>Lorem csoport</td>
        <td>
          <div class="pull-right">
            <button type="submit" class="btn btn-info btn-sm">
                        <span class="fa fa-pencil"></span>
                    </button>
            <button type="submit" class="btn btn-danger btn-sm">
                        <span class="fa fa-remove"></span>
                    </button>
          </div>
        </td>

      </tr>
      <tr>
        <td>2017-S-0022</td>
        <td>profilos</td>
        <td>1236, Budapest Lorem ipsum utca 23.</td>
        <td>Lorem csoport</td>
        <td>
          <div class="pull-right">
            <button type="submit" class="btn btn-info btn-sm">
                        <span class="fa fa-pencil"></span>
                    </button>
            <button type="submit" class="btn btn-danger btn-sm">
                        <span class="fa fa-remove"></span>
                    </button>
          </div>
        </td>
      </tr>
      <tr>
        <td>2017-S-0023</td>
        <td>profilos</td>
        <td>1236, Budapest Lorem ipsum utca 23.</td>
        <td>Lorem csoport</td>
        <td>
          <div class="pull-right">
            <button type="submit" class="btn btn-info btn-sm">
                        <span class="fa fa-pencil"></span>
                    </button>
            <button type="submit" class="btn btn-danger btn-sm">
                        <span class="fa fa-remove"></span>
                    </button>
          </div>
        </td>
      </tr>
      <tr>
        <td>2017-S-0024</td>
        <td>idősoros</td>
        <td>1236, Budapest Lorem ipsum utca 23.</td>
        <td>Lorem csoport</td>
        <td>
          <div class="pull-right">
            <button type="submit" class="btn btn-info btn-sm">
                        <span class="fa fa-pencil"></span>
                    </button>
            <button type="submit" class="btn btn-danger btn-sm">
                        <span class="fa fa-remove"></span>
                    </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
