export type MeasureUnit =
    'MW'
    | 'MWh'
    | 'kW'
    | 'kWh';

export const MeasureUnit = {
    MW: 'MW' as MeasureUnit,
    MWH: 'MWh' as MeasureUnit,
    KW: 'kW' as MeasureUnit,
    KWH: 'kWh' as MeasureUnit
};
