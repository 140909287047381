import { Component, Input, OnInit } from '@angular/core';
import { InvoiceReportResponseInvoice } from '../model/response.model';

@Component({
    selector: 'jhi-invoice-file',
    templateUrl: './invoice-file.component.html',
    styleUrls: ['./invoice-file.component.scss']
})
export class InvoiceFileComponent implements OnInit {

    @Input() set invoices(data: InvoiceReportResponseInvoice[]) {
        this._invoices = data;
    }

    _invoices: InvoiceReportResponseInvoice[];

    constructor() {
    }

    ngOnInit() {
    }

}
