import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    EmbeddedViewRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { StateStorageService } from '../auth';
import { PermissionOverlayComponent } from '../permission-overlay/permission-overlay.component';
import { PermissionOverlayRefreshService } from '../services/permission-overlay-refresh.service';

@Directive({
    selector: '[permissionOverlay]'
})
export class PermissionOverlayDirective implements OnInit, OnDestroy {

    @Input('permissionOverlay') permission: string;
    @Input('permissionOverlayDisabled') disabled: boolean;
    @Input('permissionOverlayClass') class: string;

    embeddedViewRef: EmbeddedViewRef<HTMLElement>;
    componentRef: ComponentRef<PermissionOverlayComponent>;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private componentFactoryResolver: ComponentFactoryResolver,
                private stateStorageService: StateStorageService,
                private refreshService: PermissionOverlayRefreshService,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        const permissionOverlayComponent = this.componentFactoryResolver.resolveComponentFactory(PermissionOverlayComponent);
        this.embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);

        if (!this.disabled && this.stateStorageService.getEffectivePermissions().indexOf(this.permission) === -1) {
            this.componentRef = this.viewContainer.createComponent(permissionOverlayComponent);
            if (this.class) {
                this.renderer.addClass(this.componentRef.location.nativeElement, this.class);
            }
            this.refreshService.register(this);
        }
    }

    ngOnDestroy() {
        this.refreshService.unregister(this);
    }

    recalculateOverlayDimensions() {
        if (!this.componentRef) {
            return;
        }

        const element: HTMLElement = this.embeddedViewRef.rootNodes[0];
        const overlayElement: HTMLElement = this.componentRef.location.nativeElement;

        if (element.offsetParent instanceof HTMLElement && getComputedStyle(element.offsetParent).position === 'static') {
            this.renderer.setStyle(element.offsetParent, 'position', 'relative');
        }

        const dimensions = element.getBoundingClientRect();
        const overlayDimensions = overlayElement.getBoundingClientRect();

        if (dimensions.width !== overlayDimensions.width) {
            this.renderer.setStyle(overlayElement, 'width', `${dimensions.width}px`);
        }
        if (dimensions.height !== overlayDimensions.height) {
            this.renderer.setStyle(overlayElement, 'height', `${dimensions.height}px`);
        }
        if (element.offsetTop !== overlayElement.offsetTop) {
            this.renderer.setStyle(overlayElement, 'top', `${element.offsetTop}px`);
        }
        if (element.offsetLeft !== overlayElement.offsetLeft) {
            this.renderer.setStyle(overlayElement, 'left', `${element.offsetLeft}px`);
        }
    }
}
