import {Route} from '@angular/router';


import {ContactDataComponent} from "./contact-data.component";
import {UserRouteAccessService} from '../shared';
import {PartnerMandatoryGuard} from '../shared/partner-mandatory.guard';

export const CONTACT_DATA_ROUTE: Route[] = [
    {
        path: 'contact-data',
        component: ContactDataComponent,
        data: {
            pageTitle: 'main.link.contactData'
        },
        canActivate: [UserRouteAccessService, PartnerMandatoryGuard]
    }
];
