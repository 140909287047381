import { Moment } from 'moment';
import { Resolution } from '../model/resolution.enum';
import { MeasureUnit } from './measure-unit.enum';

export class TimeInterval {
    start: Moment;
    end: Moment;
    resolution: Resolution;
    measureUnit?: MeasureUnit;

    public static builder(): TimeIntervalBuilder {
        return TimeIntervalBuilder.builder();
    }
}

export class TimeIntervalBuilder {

    private interval: TimeInterval = new TimeInterval();

    private constructor() {}

    public static builder(): TimeIntervalBuilder {
        return new TimeIntervalBuilder();
    }

    public build(): TimeInterval {
        return this.interval;
    }

    public withStart(start: Moment): TimeIntervalBuilder {
        this.interval.start = start;

        return this;
    }

    public withEnd(end: Moment): TimeIntervalBuilder {
        this.interval.end = end;

        return this;
    }

    public withResolution(resolution: Resolution): TimeIntervalBuilder {
        this.interval.resolution = resolution;

        return this;
    }

    public withMeasureUnit(measureUnit: MeasureUnit): TimeIntervalBuilder {
        this.interval.measureUnit = measureUnit;

        return this;
    }

}
