import {Route} from '@angular/router';

import {DocumentCommonComponent} from './document.common.component';
import {DocumentCompanyComponent} from './document.company.component';
import {UserRouteAccessService} from '../shared';

export const DOCUMENT_ROUTE: Array<Route> = [
    {
      path: 'documentsCompany',
      component: DocumentCompanyComponent,
      data: {
        permission: "DOCUMENT_READ",
        pageTitle: 'main.link.docs.company'
      },
       canActivate: [UserRouteAccessService]
    },
    {
      path: 'documentsCommon',
      component: DocumentCommonComponent,
      data: {
        permission: "DOCUMENT_READ",
        pageTitle: 'main.link.docs.common'
      },
      canActivate: [UserRouteAccessService]
    },
    {
      path: 'documentsCommon/:categoryId',
      component: DocumentCommonComponent,
      data: {
        permission: "DOCUMENT_READ",
        pageTitle: 'main.link.docs.common'
      },
      canActivate: [UserRouteAccessService]
    }
];
