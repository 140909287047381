import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";
import {CORPORATE_DATA_ROUTE} from "./corporate-data.route";
import {CorporateDataComponent} from "./corporate-data.component";
import {CezPortalSharedModule} from '../shared';

@NgModule({
    imports: [
        CommonModule,
        CezPortalSharedModule,
        RouterModule.forRoot(CORPORATE_DATA_ROUTE, {useHash: true})
    ],
    declarations: [CorporateDataComponent]
})
export class CorporateDataModule {
}
