import {HttpClient, HttpParams} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {concat} from 'rxjs';
import {User, UserService} from '../../shared';
import {CompanyModel} from '../../shared/dto/company.model';
import {ContactModel} from '../../shared/dto/contact.model';
import {InvoiceModel} from '../../shared/dto/invoice.model';
import {NavisionModel} from '../../shared/dto/navision.model';
import {PagerModel} from '../../shared/dto/page.model';
import {SubscriptionModel} from '../../shared/dto/subscription.model';
import {MultiSelectTranslationService} from '../../shared/services/multi-select-translation.service';
import {PartnerService} from '../../shared/services/partner.service';
import {ContactModalComponent} from './contact.modal.component';
import {SubscriptionModalComponent} from './subscription-modal/subscription-modal.component';
import {DialogService} from "../../shared/dialog/dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {CanLeaveService, IsModified} from '../../shared/can-leave/can-leave.service';
import {SalesforceEntityType} from "../../shared/dto/salesforce.type";
import {LoadingOverlayService} from "../../shared/services/loading-overlay.service";
import {BootstrapAlertService} from "../../shared/services/bootstrap-alert.service";
import {NavitaMultiSelectOption} from "../../shared/dto/common.model";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {DEFAULT_DROPDOWN_SETTINGS} from "../../core/util/constants";

@Component({
    selector: 'jhi-company',
    templateUrl: './company.component.html',
    styles: []
})
export class CompanyComponent implements OnInit {
    users: User[];
    selectedUsers: User[];
    companyModel: CompanyModel;
    companies: CompanyModel[] = [];
    tempIdCounter: number = -1;
    invoices: InvoiceModel[];
    salesforceAccountId: string;

    dropdownSettings: IDropdownSettings = {
        ...DEFAULT_DROPDOWN_SETTINGS,
        ...this.multiSelectTranslationService.getMultiSelectTexts()
    };


    userListOptions: NavitaMultiSelectOption[] = [];
    userListOptionsModel: NavitaMultiSelectOption[] = [];
    editMode: boolean;
    private _modified: IsModified.Type;

    constructor(private userService: UserService,
                private http: HttpClient,
                private partnerService: PartnerService,
                private modalService: NgbModal,
                private multiSelectTranslationService: MultiSelectTranslationService,
                private canLeaveService: CanLeaveService,
                private dialogService: DialogService,
                private translate: TranslateService,
                private loadingOverlay: LoadingOverlayService,
                private bootstrapAlertService: BootstrapAlertService) {
    }

    ngOnInit() {
        this.userService.query().subscribe(r => {
            this.users = r.body;
            this.users.map(user => this.userListOptions.push({id: user, name: user.email}));
            this.refreshList();
        });
        this.canLeaveService.isModified()
            .subscribe((value: IsModified.Type) => {
                this._modified = value;
            });
    }

    submit() {
        this.companyModel.navisionId = this.companyModel.salesforceAccountId;
        if (this.companyModel.navisionIds && this.companyModel.navisionId) {
            if (this.companyModel.navisionIds.length === 0 ||
                (this.companyModel.navisionIds.length > 0 && this.companyModel.navisionId !== this.companyModel.navisionIds[0].navisionId)
            ) {
                let newNavision = new NavisionModel();
                newNavision.navisionId = this.companyModel.navisionId;
                this.companyModel.navisionIds.push(newNavision);
            }
        }


        this.clearTempIds(this.companyModel.contacts);
        this.clearTempIds(this.companyModel.cezContacts);

        if (this.selectedUsers)
            this.companyModel.users = this.selectedUsers.map(r => {
                let mappedUser = new User();
                mappedUser.id = r.id;
                return {user: mappedUser};
            });

        let subscriptions = [];
        if (this.companyModel.id)
            subscriptions.push(this.http.put('/api/partner', this.companyModel));

        else
            subscriptions.push(this.http.post('/api/partner', this.companyModel));

        // should not save an empty string if the user clears the salesforceAccountId
        if (this.companyModel.salesforceAccountId === '') {
            this.companyModel.salesforceAccountId = null;
        }

        this.companyModel.cezContacts.map(r => {
            if (r.image) {
                let data = new FormData();
                data.append('file', r.image[0]);
                subscriptions.push(this.http.post(`/api/partner/${this.companyModel.id}/cez_contact/${r.id}/picture_file`, data));
            }
        });

        let observables = concat(...subscriptions);
        observables.subscribe(
            () => {
                this.toggleNewContainer();
                this.refreshList();
            },
            // () => this.refreshList()
        );
    }

    refreshList() {
        this.http.get<PagerModel<CompanyModel>>('/api/partner').subscribe(r => {
            this.companies = r.content;
        });

        this.canLeaveService.setModified(false);
    }

    refreshInvoiceList() {
        // FIXME: api endpoint does not exist
        // this.partnerService.getInvoicesForAnotherPartner(this.companyModel.id).subscribe(r => this.invoices = r);
    }

    toggleNewContainer() {
        this.editMode = !this.editMode;
    }

    newCompany() {
        this.companyModel = new CompanyModel();
        this.selectedUsers = [];
        if (this.editMode) {
            this.refreshList();
        }
        this.toggleNewContainer();
    }

    edit(company: CompanyModel) {
        this.companyModel = company;
        this.refreshInvoiceList();
        this.selectedUsers = this.users.filter(r => this.companyModel.users.some(companyUsers => companyUsers.user.id === r.id));
        this.toggleNewContainer();
        this.userListOptionsModel = [];
        if (this.selectedUsers)
            this.selectedUsers.map(user => this.userListOptionsModel.push({id: user, name: user.email}));

    }

    sync(salesforceAccountId: string) {
        this.syncCompany(salesforceAccountId);
    }

    delete(id: number) {
        this.dialogService.confirm(this.translate.instant('filter.basic.deleteDialog.title'), this.translate.instant('filter.basic.deleteDialog.message'))
            .subscribe((value: boolean): void => {
                if (value) {
                    this.deleteConfirmed(id);
                }
            });
    }

    deleteConfirmed(id: number): void {
        this.http.delete('/api/partner/' + id).subscribe(() => this.refreshList());
    }

    deleteInvoice(id: number) {
        this.partnerService.deleteInvoice(this.companyModel.id, id).subscribe(() => this.refreshInvoiceList());
    }

    newContact(contact: ContactModel, cez?: boolean) {
        let editContact: boolean = false;
        if (!contact) {
            contact = new ContactModel();
            contact.id = this.tempIdCounter--;
        } else
            editContact = true;

        if (this.companyModel.contacts == null)
            this.companyModel.contacts = [];

        if (this.companyModel.cezContacts == null)
            this.companyModel.cezContacts = [];

        const modalRef = this.modalService.open(ContactModalComponent, {backdrop: 'static'});
        modalRef.componentInstance.contact = contact;
        modalRef.componentInstance.isCez = cez;
        modalRef.componentInstance.modalClose.subscribe((editedContact: ContactModel) => {
            if (cez)
                this.addOrUpdateContact(this.companyModel.cezContacts, editedContact, editContact);
            else
                this.addOrUpdateContact(this.companyModel.contacts, editedContact, editContact);
            modalRef.close();
        });
    }

    deleteContact(contactId: number, cez?: boolean) {
        if (cez)
            this.companyModel.cezContacts = this.companyModel.cezContacts.filter(r => r.id != contactId);
        else
            this.companyModel.contacts = this.companyModel.contacts.filter(r => r.id != contactId);

        this.onChange();
    }

    subscriptionEditor(subscription: SubscriptionModel) {
        let edit: boolean = false;
        if (!subscription) {
            subscription = new SubscriptionModel();
            subscription.id = this.tempIdCounter--;
        } else
            edit = true;

        if (this.companyModel.subscriptions == null)
            this.companyModel.subscriptions = [];

        const modalRef = this.modalService.open(SubscriptionModalComponent, {backdrop: 'static'});
        modalRef.componentInstance.subscription = subscription;
        modalRef.componentInstance.modalClose.subscribe((subscriptionResponse: SubscriptionModel) => {
            this.addOrUpdateSubscription(this.companyModel.subscriptions, subscription, edit);
            modalRef.close();
        });
    }

    deleteSubscription(subscriptionId: number) {
        this.companyModel.subscriptions = this.companyModel.subscriptions.filter(r => r.id != subscriptionId);
        this.onChange();
    }

    onChange(): void {
        this.canLeaveService.setModified({allowedTabsets: ['agreement-details']});

        var ids: number[] = this.userListOptionsModel.map(a => a.id.id);
        this.companyModel.users = [].concat(this.users).filter(r => ids.includes(r.id)).map(r => {
            return {user: r};
        });

        this.selectedUsers = this.userListOptionsModel.map(r => r.id);
    }

    onDeSelectAllChange(): void {
        this.canLeaveService.setModified({allowedTabsets: ['agreement-details']});
        this.companyModel.users = [];

        this.selectedUsers = this.userListOptionsModel.map(r => r.id);
    }

    onSelectAllChange(): void {
        this.canLeaveService.setModified({allowedTabsets: ['agreement-details']});

        this.companyModel.users = [].concat(this.users).map(r => {
            return {user: r};
        });

        this.selectedUsers = this.userListOptionsModel.map(r => r.id);
    }

    onCancel(): void {
        if (this.editMode && this._modified) {
            this.closeEditMode();
        } else {
            this.toggleNewContainer();
        }
    }

    syncNewCompanyFromSalesforce(salesforceAccountId?: string): void {
        this.loadingOverlay.turnOn();

        let params: HttpParams = new HttpParams();
        if (salesforceAccountId) {
            params = params.set('salesforceId', salesforceAccountId);
        }

        this.http.post<boolean>(`api/salesforce/query/${SalesforceEntityType.ACCOUNT}`, {}, {params: params})
            .subscribe(
                (): void => {
                    this.loadingOverlay.turnOff();
                    this.showSyncSalesforceSuccess();
                }, (): void => {
                    this.loadingOverlay.turnOff();
                    this.salesforceAccountId = null;
                },
                (): void => {
                    this.refreshList();
                    this.salesforceAccountId = null;
                });

    }

    private addOrUpdateContact(contactList: ContactModel[], contact: ContactModel, edit: boolean) {
        if (edit)
            contactList.filter(r => r.id == contact.id)[0] = contact;
        else
            contactList.push(contact);

        this.onChange();
    }

    private addOrUpdateSubscription(subscriptionList: SubscriptionModel[], subscription: SubscriptionModel, edit: boolean) {
        if (edit)
            subscriptionList.filter(r => r.id == subscription.id)[0] = subscription;
        else
            subscriptionList.push(subscription);

        this.onChange();
    }

    private clearTempIds(contactList: ContactModel[]) {
        contactList.map(r => {
            if (r.id < 0)
                r.id = null;
        });
    }

    private showSyncSalesforceSuccess(): void {
        this.bootstrapAlertService.showSuccess('company.sync.syncSuccess');
    }

    private closeEditMode(): void {
        this.canLeaveService.canLeave()
            .subscribe((value) => {
                if (value) {
                    this.refreshList();
                    this.toggleNewContainer();
                }
            });
    }

    private syncCompany(salesforceAccountId: string) {
        this.loadingOverlay.turnOn();

        this.http.post<string>(`api/salesforce/sync/${salesforceAccountId}`, {})
            .subscribe((): void => {
                this.loadingOverlay.turnOff();

                this.bootstrapAlertService.showSuccess('agreement.sync.syncSuccess');
            }, (): void => {
                this.loadingOverlay.turnOff();
            });
    }

}
