import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {DeliveryPeriodDto} from '../../../shared/dto/delivery-period-dto';
import {Subject} from 'rxjs';
import {MtmReportCashoutCalculationType, MtmReportDto, MtmTableType} from './coverage-transactions-mtm.model';
import {CoverageTransactionsService} from '../../coverage-transactions.service';
import {ProductType} from '../../../shared/agreement-details/agreement-enums.model';
import {TranslateService} from '@ngx-translate/core';
import {DecimalPipe} from '@angular/common';

@Component({
    selector: 'jhi-coverage-transactions-mtm',
    templateUrl: './coverage-transactions-mtm.component.html',
    styleUrls: ['coverage-transactions-mtm.component.scss']
})
export class CoverageTransactionsMtmComponent implements OnInit, OnDestroy {

    @Input() public selectedDeliveryPeriod: DeliveryPeriodDto;
    @Input() public tableType: MtmTableType;
    @Input()
    public get calculationType(): MtmReportCashoutCalculationType {
        return this._calculationType;
    }
    public set calculationType(calculationType: MtmReportCashoutCalculationType) {
        if (this._calculationType === calculationType) {
            return;
        }

        this._calculationType = calculationType;

        if (!this.isSummaryTable() && !this.isInitialRestRequest) {
            this.getMtmDetails();
        }
        this.setTableColumnTitle();
    }

    public mtmReportDtos: MtmReportDto[] = [];
    public columnTitle: string;
    public isLoading: boolean = false;

    private isInitialRestRequest: boolean = true;
    private _calculationType: MtmReportCashoutCalculationType;
    private unsubscribe: Subject<void> = new Subject<void>();

    constructor(private coverageTransactionsService: CoverageTransactionsService,
                private numberPipe: DecimalPipe,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.setTableColumnTitle();

        if (this.isSummaryTable()) {
            this.isLoading = true;
            this.coverageTransactionsService.getMtmSummary(this.selectedDeliveryPeriod.id)
                .subscribe((result) => {
                    this.mtmReportDtos = result;
                    this.isLoading = false;
                }, () => this.isLoading = false);
        } else {
            this.getMtmDetails();
        }
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    public getFormattedType(mtmDto: MtmReportDto): string {
        const rbcValue: number = this.selectedDeliveryPeriod.invoicePriceElement || 0;

        return this.translateService.instant('offerClaim.mtmReport.table.type.' + mtmDto.type,
            { rbc: this.numberPipe.transform(rbcValue, '1.2-2') });
    }

    public abs(n: number): number {
        return Math.abs(n);
    }

    private isSummaryTable(): boolean {
        return this.tableType === 'SUMMARY';
    }

    private getMtmDetails(): void {
        let calculationType: MtmReportCashoutCalculationType;

        if (this.isCashout()) {
            calculationType = this.calculationType;
        }

        this.isLoading = true;
        this.coverageTransactionsService.getMtmDetails(this.selectedDeliveryPeriod.id, calculationType)
            .subscribe((result) => {
                this.mtmReportDtos = result;
                this.isInitialRestRequest = false;
                this.isLoading = false;
            }, () => this.isLoading = false);
    }

    private isCashout(): boolean {
        return this.selectedDeliveryPeriod.productType === ProductType.CASH_OUT;
    }

    private setTableColumnTitle(): void {
        if (this.isCashout() && !this.isSummaryTable() && this.calculationType !== MtmReportCashoutCalculationType.DEFAULT) {
            this.columnTitle = this.translateService.instant(`offerClaim.mtmReport.table.${ this.calculationType }`);
        } else {
            this.columnTitle = this.translateService.instant('offerClaim.mtmReport.table.price');
        }
    }
}
