import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalizedTexts } from '..';

@Injectable({
    providedIn: 'root'
})
@Pipe({name: 'translateLocalizedText'})
export class TranslateLocalizedText implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(i18Entity: LocalizedTexts): string {
        switch (this.translate.currentLang) {
            case 'hu':
                return i18Entity.textHU;
            case 'en':
                return i18Entity.textEN;
        }
    }
}
