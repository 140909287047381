import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'rts-prompt',
    templateUrl: './prompt.component.html',
    styles: []
})
export class PromptComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private translate: TranslateService) {
    }

    ngOnInit() {
    }

}
