import {CommonModule, DatePipe, DecimalPipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {NgbDateParserFormatter, NgbModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {ChartModule} from 'angular-highcharts';
import {NgxLoadingModule} from 'ngx-loading';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {TableModule} from 'primeng/table';
import {PasswordMatchValidatorDirective} from '../account/password-reset/finish/password-match.directive';
import {
  DayIntervalSelectorComponent
} from '../consumption/filter-basic/dynamic-day-interval-selector/day-interval-selector.component';
import {DaySelectorComponent} from '../consumption/filter-basic/dynamic-day-interval-selector/day-selector.component';

import {
  ConsumptionDayIntervalSelectorComponent
} from '../consumption-new/filter-basic/dynamic-day-interval-selector/consumption-day-interval-selector.component';
import {
  ConsumptionDaySelectorComponent
} from '../consumption-new/filter-basic/dynamic-day-interval-selector/consumption-day-selector.component';

import {
  DynamicDayIntervalSelectorComponent
} from '../consumption/filter-basic/dynamic-day-interval-selector/dynamic-day-interval-selector.component';
import {
  PeriodParameterSelector
} from '../consumption/filter-basic/dynamic-day-interval-selector/period-parameter-selector.component';
import {
  ConsumptionPeriodParameterSelector
} from '../consumption-new/filter-basic/dynamic-day-interval-selector/period-parameter-selector.component';
import {AgreementDetailsComponent} from './agreement-details/agreement-details.component';
import {AgreementListComponent} from './agreement-list/agreement-list.component';

import {AccountService, AuthServerProvider, AuthService, HasAnyAuthorityDirective, Principal} from './auth';
import {CompanyListComponent} from './company-list/company-list.component';
import {CompanySelectorComponent} from './company-selector/company-selector.component';
import {CezContactListComponent} from './contact-list/cez-contact-list/cez-contact-list.component';
import {ContactListComponent} from './contact-list/contact-list.component';
import {ContractListComponent} from './contract-list/contract-list.component';
import {BootstrapToggleComponent} from './controls/bootstrap-toggle/bootstrap-toggle.component';
import {DatePickerPencilComponent} from './controls/date-picker-pencil/date-picker-pencil.component';
import {DatePickerComponent} from './controls/date-picker/date-picker.component';
import {DeliveryPeriodListComponent} from './delivery-period-list/delivery-period-list.component';
import {DeliveryPeriodModalComponent} from './delivery-period-modal/delivery-period-modal.component';
import {AutofocusDirective} from './directives/autofocus.directive';
import {InvalidWhenValidatorDirective} from './directives/invalid-when.directive';
import {JhiTranslateDirective} from './directives/jhi.translate';
import {PermissionOverlayDirective} from './directives/permission-overlay.directive';
import {DocumentListComponent} from './document-list/document-list.component';

import {MatFileUploadModule} from './fileupload/matFileUpload.module';
import {IntervalRestrictorDirective} from './historical-data-handler/interval-restrictor.directive';

import {JhiLanguageHelper} from './language/language.helper';
import {FindLanguageFromKeyPipe} from './language/language.pipe';
import {MaterialModule} from './material.module';
import {MonthlyDateIntervalComponent} from './monthly-date-interval/monthly-date-interval.component';
import {MonthlyDatePickerComponent} from './monthly-date-picker/monthly-date-picker.component';
import {PartnerDetailsComponent} from './partner-details/partner-details.component';
import {
  PermissionOverlayPopupComponent
} from './permission-overlay/permission-overlay-popup/permission-overlay-popup.component';
import {PermissionOverlayComponent} from './permission-overlay/permission-overlay.component';
import {ByteFormatterPipe} from './pipes/ByteFormatterPipe';
import {CezDatePipe} from './pipes/cez-date.pipe';
import {EncodeURIComponentPipe} from './pipes/encode-uricomponent.pipe';
import {FormatOrderPipe} from './pipes/format-order';
import {JhiKeysPipe} from './pipes/keys.pipe';
import {NegatedPermissionPipe} from './pipes/negated-permission.pipe';
import {PermissionPipe} from './pipes/permission.pipe';
import {SafeHtmlPipe} from './pipes/SafeHtmlPipe';
import {TranslateAdditionalDiagramGroup} from './pipes/translate-additional-diagram-group';
import {TranslateAdditionalDiagramType} from './pipes/translate-additional-diagram-type';
import {TranslateLocalizedText} from './pipes/translate-localized-text';
import {PodListComponent} from './pod-list/pod-list.component';
import {PriceListComponent} from './price-list/price-list.component';
import {ProductListComponent} from './product-list/product-list.component';
import {BookedPerformanceService} from './services/booked-performance.service';
import {CategoryService} from './services/category.service';
import {CompanySelectorService} from './services/company-selector.service';
import {DiagramService} from './services/diagram.service';
import {DocumentFilterService} from './services/document.filter.service';
import {InvoicesService} from './services/invoices.service';
import {LocatorService} from './services/locator.service';
import {MenuService} from './services/menu.service';
import {NewsService} from './services/news.service';
import {NgbDateMomentParserFormatter} from './services/ngb-date-moment-parser-formatter.service';
import {NgBDatePickerConvertService} from './services/ngb.datepicker.convert.service';
import {PartnerService} from './services/partner.service';
import {PodValueService} from './services/pod-value.service';
import {PodService} from './services/pod.service';
import {ProductService} from './services/product.service';
import {TermsOfServicesService} from './services/terms-of-services.service';
import {UserSettingsService} from './services/user-settings.service';
import {ViewTemplateService} from './services/view-template.service';
import {FormatSecondsPipe} from './session-timer/format-seconds.pipe';
import {SessionWarningPopupComponent} from './session-timer/popup/session-warning-popup.component';
import {RestartSessionDirective} from './session-timer/restart-session.directive';
import {SessionTimerComponent} from './session-timer/session-timer.component';
import {TimeSeriesActivateComponent} from './time-series/time-series-activate/time-series-activate.component';
import {TimeSeriesChartComponent} from './time-series/time-series-chart/time-series-chart.component';
import {TimeSeriesImportComponent} from './time-series/time-series-import.component';
import {TimeIntervalComponent} from './time-series/time-series-interval/time-interval.component';

import {ToastMessageComponentComponent} from './toast-message-component/toast-message-component.component';

import {ToleranceLimitListComponent} from './tolerance-limit-list/tolerance-limit-list.component';
import {UserService} from './user/user.service';
import {
  ConsumptionDynamicDayIntervalSelectorComponent
} from '../consumption-new/filter-basic/dynamic-day-interval-selector/consumption-dynamic-day-interval-selector.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgSelectFormFieldControlDirective} from './ng-select/ng-select.directive';
import {ProgressbarDirective} from './directives/progressbar.directive';
import {CountdownModule} from 'ngx-countdown';
import {DailyPricesDialogComponent} from './daily-prices/daily-prices-dialog.component';
import {MinNumberInputDirective} from './directives/min-number-input.directive';
import {MaxNumberInputDirective} from './directives/max-number-input.directive';
import {ParameterViewerComponent} from './parameter-viewer/parameter-viewer.component';
import {MinusDayPipe} from './pipe/minus-day.pipe';
import {StickyDeliveryPeriodComponent} from './sticky-delivery-period/sticky-delivery-period.component';
import {TabSaveDirective} from './directives/tab-save/tab-save.directive';
import {TabIdAndTitleSetterDirective} from './directives/tab-save/tab-id-and-title-setter.directive';
import {HighchartsFullscreenComponent} from './highcharts-fullscreen/highcharts-fullscreen.component';
import {AggregationTableDataComponent} from './aggregation-table-data/aggregation-table-data.component';
import {
  AggregationTableContentComponent
} from './aggregation-table-data/aggregation-table-content/aggregation-table-content.component';
import {ProductNamePipe} from './pipes/product-name.pipe';
import {DeadlineSelectorComponent} from './deadline-selector/deadline-selector.component';
import {NgxMaskModule} from 'ngx-mask';
import {SystemInfoService} from './services/system-info.service';
import {WriteProtectedDirective} from './directives/write-protected.directive';
import {DropdownModule} from 'primeng/dropdown';
import {CheckboxModule} from 'primeng/checkbox';
import {SliderModule} from 'primeng/slider';
import {ConfirmationService, SharedModule} from 'primeng/api';
import {MultiSelectModule} from 'primeng/multiselect';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {CezDateTimePipe} from "./pipes/cez-date-time.pipe";
import { PriceTrendChartComponent } from './chart/price-trend-chart/price-trend-chart.component';

@NgModule({
  imports: [
    NgMultiSelectDropDownModule.forRoot(),
    ChartModule,
    // DataTableModule,
    DropdownModule,
    CheckboxModule,
    SliderModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    CommonModule,
    NgbModule,
    NgbNavModule,
    // DataTableModule,
    TableModule,
    SharedModule,
    MultiSelectModule,
    DropdownModule,
    CheckboxModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    TranslateModule,
    NgxMaterialTimepickerModule,
    MatFileUploadModule,
    NgSelectModule,
    NgSelectModule,
    CountdownModule,
    NgxMaskModule,
    NgxLoadingModule
  ],
  declarations: [
    HasAnyAuthorityDirective,
    SafeHtmlPipe,
    ByteFormatterPipe,
    FindLanguageFromKeyPipe,
    MinusDayPipe,
    CompanySelectorComponent,
    ContactListComponent,
    CompanyListComponent,
    BootstrapToggleComponent,
    ToleranceLimitListComponent,
    ContractListComponent,
    PodListComponent,
    AgreementListComponent,
    AgreementDetailsComponent,
    PartnerDetailsComponent,
    ToastMessageComponentComponent,
    CezContactListComponent,
    DocumentListComponent,
    ProductListComponent,
    DeliveryPeriodListComponent,
    DeliveryPeriodModalComponent,
    PriceListComponent,
    CezDatePipe,
    CezDateTimePipe,
    AutofocusDirective,
    ProductNamePipe,
    DatePickerComponent,
    DatePickerPencilComponent,
    JhiTranslateDirective,
    JhiKeysPipe,
    TranslateLocalizedText,
    TranslateAdditionalDiagramType,
    TranslateAdditionalDiagramGroup,
    FormatOrderPipe,
    PermissionPipe,
    NegatedPermissionPipe,
    MonthlyDatePickerComponent,
    MonthlyDateIntervalComponent,
    EncodeURIComponentPipe,
    PasswordMatchValidatorDirective,
    InvalidWhenValidatorDirective,
    ProgressbarDirective,
    WriteProtectedDirective,
    DynamicDayIntervalSelectorComponent,
    ConsumptionDynamicDayIntervalSelectorComponent,
    ConsumptionPeriodParameterSelector,
    PeriodParameterSelector,
    DayIntervalSelectorComponent,
    DaySelectorComponent,
    ConsumptionDayIntervalSelectorComponent,
    ConsumptionDaySelectorComponent,
    PermissionOverlayDirective,
    DeadlineSelectorComponent,
    TimeSeriesImportComponent,
    TimeIntervalComponent,
    TimeSeriesChartComponent,
    TimeSeriesActivateComponent,
    RestartSessionDirective,
    FormatSecondsPipe,
    SessionTimerComponent,
    SessionWarningPopupComponent,
    PermissionOverlayPopupComponent,
    IntervalRestrictorDirective,
    PermissionOverlayComponent,
    NgSelectFormFieldControlDirective,
    DailyPricesDialogComponent,
    ParameterViewerComponent,
    StickyDeliveryPeriodComponent,
    AggregationTableDataComponent,
    AggregationTableContentComponent,
    MinNumberInputDirective,
    MaxNumberInputDirective,
    TabSaveDirective,
    TabIdAndTitleSetterDirective,
    HighchartsFullscreenComponent,
    PriceTrendChartComponent
  ],
  providers: [
    AccountService,
    Principal,
    AuthServerProvider,
    AuthService,
    UserService,
    ProductNamePipe,
    DatePipe,
    DecimalPipe,
    PodService,
    NgBDatePickerConvertService,
    JhiLanguageHelper,
    Title,
    DiagramService,
    PodValueService,
    ProductService,
    CategoryService,
    DocumentFilterService,
    NewsService,
    BookedPerformanceService,
    ConfirmationService,
    PartnerService,
    ViewTemplateService,
    TermsOfServicesService,
    CompanySelectorService,
    UserSettingsService,
    {
      provide: NgbDateParserFormatter,
      useFactory: () => {
        return new NgbDateMomentParserFormatter();
      }
    },
    LocatorService,
    MenuService,
    InvoicesService,
    CezDatePipe,
    CezDateTimePipe,
    SystemInfoService
  ],
  exports: [
    HasAnyAuthorityDirective,
    DatePipe,
    TranslateModule,
    SafeHtmlPipe,
    ByteFormatterPipe,
    FindLanguageFromKeyPipe,
    MinusDayPipe,
    NgMultiSelectDropDownModule,
    CompanySelectorComponent,
    //DataTableModule,
    TableModule,
    MaterialModule,
    SharedModule,
    MultiSelectModule,
    DropdownModule,
    CheckboxModule,
    SliderModule,
    ContactListComponent,
    CompanyListComponent,
    BootstrapToggleComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    NgbModule,
    NgbNavModule,
    ToleranceLimitListComponent,
    ContractListComponent,
    DocumentListComponent,
    ProductListComponent,
    PodListComponent,
    AgreementListComponent,
    AgreementDetailsComponent,
    PartnerDetailsComponent,
    ToastMessageComponentComponent,
    AutoCompleteModule,
    ConfirmDialogModule,
    CezContactListComponent,
    DeliveryPeriodListComponent,
    PriceListComponent,
    CezDatePipe,
    CezDateTimePipe,
    AutofocusDirective,
    ChartModule,
    ProductNamePipe,
    DatePickerComponent,
    DatePickerPencilComponent,
    JhiTranslateDirective,
    TranslateModule,
    JhiKeysPipe,
    TranslateLocalizedText,
    TranslateAdditionalDiagramType,
    TranslateAdditionalDiagramGroup,
    FormatOrderPipe,
    PermissionPipe,
    NegatedPermissionPipe,
    EncodeURIComponentPipe,
    MonthlyDatePickerComponent,
    MonthlyDateIntervalComponent,
    PasswordMatchValidatorDirective,
    InvalidWhenValidatorDirective,
    ProgressbarDirective,
    WriteProtectedDirective,
    DynamicDayIntervalSelectorComponent,
    ConsumptionDynamicDayIntervalSelectorComponent,
    ConsumptionPeriodParameterSelector,
    DayIntervalSelectorComponent,
    DaySelectorComponent,
    PeriodParameterSelector,
    ConsumptionDayIntervalSelectorComponent,
    ConsumptionDaySelectorComponent,
    PermissionOverlayDirective,
    DeadlineSelectorComponent,
    MatFileUploadModule,
    TimeSeriesImportComponent,
    TimeIntervalComponent,
    TimeSeriesChartComponent,
    TimeSeriesActivateComponent,
    AggregationTableDataComponent,
    AggregationTableContentComponent,
    RestartSessionDirective,
    SessionTimerComponent,
    IntervalRestrictorDirective,
    NgSelectModule,
    NgSelectFormFieldControlDirective,
    DailyPricesDialogComponent,
    ParameterViewerComponent,
    StickyDeliveryPeriodComponent,
    MinNumberInputDirective,
    MaxNumberInputDirective,
    TabSaveDirective,
    TabIdAndTitleSetterDirective,
    NgxMaskModule,
    NgxLoadingModule,
    PriceTrendChartComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CezPortalSharedModule {
  constructor(private injector: Injector) {    // Create global Service Injector.
    LocatorService.injector = injector;
  }
}
