import { FileDto } from './FileDto';

export class InvoiceModel {
    id: number;
    arrivedTime: Date;
    invoiceNumber: string;
    currency: string;
    exchangeMethod: string;
    invoiceType: string;
    paymentDeadlineNgB: any;
    startTimeNgB: any;
    endTimeNgB: any;
    paymentDeadlineDays: number;
    unitPrice: number;
    unitPrices: number;
    includesRHD: boolean;
    invoiceFile: FileDto;
}
