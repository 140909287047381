<section class="small-form mt-2">
    <div class="form-group">
        <label for="permission">{{ 'userManagement.groupType' | translate }}</label>
        <mat-select
            id="permission"
            required
            class="form-control"
            [(ngModel)]="selectedGroupType"
            (ngModelChange)="groupTypeChange()">
            <mat-option
                *ngFor="let type of GroupTypes"
                [value]="type">
                {{ type }}
            </mat-option>
        </mat-select>
    </div>
</section>

<mat-card>
    <mat-card-title>
        <header class="docs">
            <h2>{{ 'global.menu.admin.role-editor' | translate }}</h2>
            <aside>
                <button *ngIf="!showNewGroupContainer" [disabled]="!selectedGroupType" class="btn btn-primary"
                        (click)="toggleNewContainer()">{{ 'agreement.pod_groups.new.newGroup' | translate }}
                </button>
            </aside>
        </header>
        <div class="my-3" *ngIf="showNewGroupContainer">
            <form #f="ngForm" (ngSubmit)="saveNewGroup(f)">
                <div class="form-group">
                    <label>{{ 'agreement.pod_groups.new.newGroup' | translate }}</label>
                    <input required name="newGroupName" [ngModel]="newGroupName" class="form-control">
                </div>
                <div class="pull-right">
                    <button type="button" class="btn btn-primary"
                            (click)="cancelGroupCreation()">{{ 'entity.action.cancel' | translate }}
                    </button>
                    <button type="submit" class="btn btn-primary">{{ 'entity.action.save' | translate }}</button>
                </div>
            </form>
        </div>
    </mat-card-title>
    <mat-card-content>
        <table class="table table-striped">
            <thead>
            <tr>
                <th>
                    <span>#</span>
                </th>
                <th>
                    <span>{{ 'agreement.pod_groups.new.groupName' | translate }}</span>
                </th>
                <th>
                    <span>{{ 'agreement.pod_groups.new.permissions' | translate }}</span>
                </th>
                <th></th>
            </tr>
            </thead>
            <tbody *ngIf="roles">
            <tr *ngFor="let role of roles; let i=index;">
                <td>{{ i + 1 }}</td>
                <td>
                    <span *ngIf="!role.editMode">{{ role.name }}</span>
                    <input *ngIf="role.editMode" [(ngModel)]="role.name" class="form-control"/>
                </td>
                <td>
                    <ng-multiselect-dropdown
                        [placeholder]="'multiSelectText.defaultTitle' | translate"
                        [settings]="dropdownSettings"
                        [data]="permissions"
                        [(ngModel)]="role.permissions"
                        (onSelect)="selectionChange($event)"
                        (onDeSelect)="selectionChange($event)"
                        (onSelectAll)="selectionChange($event)"
                        (onDeSelectAll)="selectionChange($event)">
                    </ng-multiselect-dropdown>
                </td>
                <td>
                    <button *ngIf="!role.editMode" class="btn btn-info btn-sm" (click)="editRole(role)">
                        <span class="fa fa-pencil"></span>
                    </button>
                    <button *ngIf="role.editMode" class="btn btn-success btn-sm" (click)="saveRole(role)">
                        <span class="fa fa-floppy-o"></span>
                    </button>
                    <button *ngIf="role.editMode" class="btn btn-info btn-sm" (click)="undo(role)">
                        <span class="fa fa-undo"></span>
                    </button>
                    <button *ngIf="!role.editMode" class="btn btn-danger btn-sm" (click)="deleteRole(role)">
                        <span class="fa fa-remove"></span>
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </mat-card-content>
    <mat-card-footer>
        <div class="row m-0 justify-content-end">
            <button *ngIf="dirty"
                    class="btn btn-primary m-3"
                    (click)="savePermissions()">{{ 'entity.action.save' | translate }}
            </button>
        </div>
    </mat-card-footer>
</mat-card>
