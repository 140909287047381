import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { AdditionalTimeSeriesDto } from '../model/additional-time-series';
import { TimeSeriesDisplayEvent } from '../model/time-series-display-event';
import { TimeSeriesId } from '../model/time-series-id';
import {MatLegacyCheckboxChange as MatCheckboxChange} from "@angular/material/legacy-checkbox";
import {UtilService} from '../../shared/services/util.service';

@Component({
    selector: 'jhi-additional-timeseries',
    templateUrl: './additional-time-series.component.html',
    styleUrls: ['./additional-time-series.component.scss']
})
export class AdditionalTimeSeriesComponent implements OnInit {

    private _additionalTimeSeries: AdditionalTimeSeriesDto[];

    @Input()
    set additionalTimeSeries(additionalTimeSeries) {
        this._additionalTimeSeries = additionalTimeSeries;

        this.booleanOptions = this.utilService.createBooleanOptions();
    }
    get additionalTimeSeries() {
        return this._additionalTimeSeries;
    }

    @Output() timeSeriesDisplay: EventEmitter<TimeSeriesDisplayEvent> = new EventEmitter();

    private booleanOptions: SelectItem[];

    constructor(public utilService: UtilService) {
    }

    ngOnInit() {
    }

    selectionChanged(checked: boolean, additionalTimeSeries: AdditionalTimeSeriesDto) {
        additionalTimeSeries.selected = checked;
        let isSelected = additionalTimeSeries.selected;

        for (const timeSeriesId of additionalTimeSeries.timeSeriesIds) {
            let numSelected = this.countSelected(timeSeriesId);

            if ((isSelected && numSelected == 1) || (!isSelected && numSelected == 0)) {
                let productDisplayEvent = new TimeSeriesDisplayEvent();
                productDisplayEvent.display = isSelected;
                productDisplayEvent.timeSeriesId = timeSeriesId;
                this.timeSeriesDisplay.emit(productDisplayEvent);
            }
        }
    }

    countSelected(selectedId: TimeSeriesId): number {
        let count = 0;
        for (const additionalTimeSery of this.additionalTimeSeries) {
            for (const timeSeriesId of additionalTimeSery.timeSeriesIds) {
                if (TimeSeriesId.equals(timeSeriesId, selectedId) && additionalTimeSery.selected) {
                    count++;
                }
            }
        }
        return count;
    }
}
