import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {CompanyChangeModel} from '../dto/company.change.model';
import {CompanyModel} from '../dto/company.model';
import {PagerModel} from '../dto/page.model';

@Injectable()
export class CompanySelectorService {

    private CompanyChangeEvent: Subject<CompanyChangeModel> = new Subject();

    constructor(private http: HttpClient) {
    }

    getCompanyChangeEvent() {
        return this.CompanyChangeEvent.asObservable();
    }

    triggerCompanyChange(effectivePermissions: string[], companyName: string) {
        this.CompanyChangeEvent.next({permissions: effectivePermissions, companyName: companyName});
    }

    getCompanies(adminMode: boolean, name: string, page: number, pageSize: number): Observable<PagerModel<CompanyModel>> {
        let params: HttpParams = new HttpParams({
            fromObject: name == null ? {page: String(page), size: String(pageSize)} : {
                name: name,
                page: String(page),
                size: String(pageSize)
            }
        });
        let options = {
            params: params
        };
        if (adminMode) {
            return this.http.get<PagerModel<CompanyModel>>('api/partner', options);
        } else {
            return this.http.get<PagerModel<CompanyModel>>('api/account/partner/page', options);
        }
    }

}

