import {Injectable} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NewClaimDialogConfig, NewClaimDialogMode, PartnerWithDeliveryPeriods} from './new-claim-dialog.model';
import {DeliveryPeriodForOffer} from '../../../shared/offer-claim/model/DeliveryPeriodForOffer';
import {OfferType} from '../../../shared/offer-claim/model/offer-type.enum';
import {Market} from '../../../shared/offer-claim/model/market.enum';
import {OfferPeriod} from '../../../shared/offer-claim/model/offer-period.enum';
import {ProductType} from '../../../shared/agreement-details/agreement-enums.model';
import {ReactiveFormUtil} from '../../../shared/form/reactive-form.util';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {DailyPricesService} from '../../../shared/services/daily-prices.service';
import * as _ from 'lodash';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {DailyRateType} from '../../../shared/daily-rates/model/DailyRatesType.enum';
import {DailyPriceStateChange} from '../../../shared/daily-rates/model/DailyPriceStateChange';

/**
 * State service for NewClaimDialog form
 * It is just for getting form states, does not modify any values!
 * */
@Injectable()
export class NewClaimDialogFormStateService {

    public static PREVENT_EVENT_BUBBLING: { onlySelf: boolean, emitEvent: boolean } = {
        onlySelf: true,
        emitEvent: false
    };

    public form: UntypedFormGroup;
    public averagingControl: UntypedFormControl;
    public partnerControl: UntypedFormControl;
    public deliveryPeriodControl: UntypedFormControl;
    public typeControl: UntypedFormControl;
    public marketControl: UntypedFormControl;
    public periodControl: UntypedFormControl;
    public periodNumberControl: UntypedFormControl;
    public startDateControl: UntypedFormControl;
    public endDateControl: UntypedFormControl;
    public purchaseStatusControl: UntypedFormControl;
    public quantityControl: UntypedFormControl;
    public trancheControl: UntypedFormControl;
    public quantityMwhControl: UntypedFormControl;
    public percentControl: UntypedFormControl;
    public averagingStartControl: UntypedFormControl;
    public averagingEndControl: UntypedFormControl;
    public netPriceControl: UntypedFormControl;
    public curveEnabledControl: UntypedFormControl;
    public curveImportControl: UntypedFormControl;

    public partners: PartnerWithDeliveryPeriods[] = [];
    public deliveryPeriods: DeliveryPeriodForOffer[] = [];
    public types: OfferType[] = [];
    public markets: Market[] = [];
    public marketsFiltered: Market[] = [];
    public periods: OfferPeriod[] = [];
    public periodsFiltered: OfferPeriod[] = [];
    public periodNumbers: number[] = [];
    public periodPeriodNumberMap: Map<OfferPeriod, number[]> = new Map<OfferPeriod, number[]>();

    public startMinDate: NgbDateStruct;
    public startMaxDate: NgbDateStruct;
    public endMinDate: NgbDateStruct;
    public endMaxDate: NgbDateStruct;
    public averagingStartMinDate: NgbDateStruct;
    public averagingStartMaxDate: NgbDateStruct;
    public averagingEndMinDate: NgbDateStruct;
    public averagingEndMaxDate: NgbDateStruct;

    private isDailyPriceActive: boolean = false;
    private dialogConfig: NewClaimDialogConfig;
    private destroy: Subject<void> = new Subject();

    constructor(private fb: UntypedFormBuilder,
                private dailyPricesService: DailyPricesService) {}

    public initForm(dialogConfig: NewClaimDialogConfig): void {
        this.initValues();
        this.dialogConfig = dialogConfig;
        this.initFormControls();
        this.initFormGroup();
        this.subscribeDailyPriceStateChanges();
    }

    public destroyForm(): void {
        this.initValues();
        this.destroy.next();
        this.destroy.complete();
    }

    public setAveraging(state: boolean): void {
        if (this.averagingControl.enabled) {
            this.averagingControl.setValue(state);
        }
    }

    public updateFormValidity(): void {
        Object.keys(this.form.controls).forEach((key: string) =>
            this.form.controls[key].updateValueAndValidity(NewClaimDialogFormStateService.PREVENT_EVENT_BUBBLING));
    }

    /** common state check functions */

    public isAveraging(): boolean {
        return this.averagingControl.value;
    }

    public isDeliveryPeriodDetermined(): boolean {
        return !_.isNil(this.deliveryPeriodControl.value);
    }

    public isPartnerMode(): boolean {
        return this.dialogConfig.mode === NewClaimDialogMode.PARTNER_MODE;
    }

    public isAdminMode(): boolean {
        return this.dialogConfig.mode === NewClaimDialogMode.ADMIN_MODE;
    }

    public isCashOut(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return false;
        }

        return (this.deliveryPeriodControl.value as DeliveryPeriodForOffer).productType === ProductType.CASH_OUT;
    }

    public isStepByStep(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return false;
        }

        return (this.deliveryPeriodControl.value as DeliveryPeriodForOffer).productType === ProductType.STEP_BY_STEP;
    }

    public isModificationMode(): boolean {
        return !_.isNil(this.dialogConfig.claim) && !_.isNil(this.dialogConfig.claim.id);
    }

    public isPeriodSet(): boolean {
        if (_.isNil(this.periodControl.value)) {
            return false;
        }
        if (this.isCustomPeriod()) {
            return !_.isNil(this.startDateControl.value) && !_.isNil(this.endDateControl.value);
        }

        return !_.isNil(this.periodNumberControl.value);
    }

    public isQuantitySet(): boolean {
        if (this.isCashOut()) {
            return !_.isNil(this.quantityControl.value);
        }

        return !_.isNil(this.trancheControl.value);
    }

    public isCustomPeriod(): boolean {
        return this.periodControl.value === OfferPeriod.CUSTOM;
    }

    public isDeliveryPeriodSelectorOff(): boolean {
        return this.isPartnerMode() && this.dialogConfig.deliveryPeriod.all.length === 0
            && !_.isNil(this.dialogConfig.deliveryPeriod.selected);
    }

    public isCurveBasedTransaction(): boolean {
        return this.curveEnabledControl.value;
    }

    public isDailyPriceMode(): boolean {
        return !_.isNil(this.dialogConfig.dailyRate);
    }

    public isDailyPriceModeWithPrice(): boolean {
        return !_.isNil(this.dialogConfig.dailyRate) && !this.dialogConfig.dailyRate.withoutPrice;
    }

    /** get hidden states */

    public partnerAndDeliveryPeriodHidden(): boolean {
        if (this.isPartnerMode()) {
            return !!this.dialogConfig.deliveryPeriod &&
                (!this.dialogConfig.deliveryPeriod.all || this.dialogConfig.deliveryPeriod.all.length === 0);
        }

        return false;
    }

    public partnerHidden(): boolean {
        return this.isPartnerMode();
    }

    public deliveryPeriodHidden(): boolean {
        if (this.isPartnerMode()) {
            return false;
        }

        return !this.partnerControl.value;
    }

    public typeHidden(): boolean {
        return !this.isDeliveryPeriodDetermined();
    }

    public marketHidden(): boolean {
        return !this.isDeliveryPeriodDetermined();
    }

    public periodHidden(): boolean {
        return !this.isDeliveryPeriodDetermined();
    }

    public periodNumberHidden(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return true;
        }

        return this.isCustomPeriod();
    }

    public startDateHidden(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return true;
        }

        return this.periodControl.value !== OfferPeriod.CUSTOM;
    }

    public endDateHidden(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return true;
        }

        return this.periodControl.value !== OfferPeriod.CUSTOM;
    }

    public purchaseStatusHidden(): boolean {
        return !this.isDeliveryPeriodDetermined();
    }

    public quantityHidden(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return true;
        }

        return this.isStepByStep();
    }

    public trancheHidden(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return true;
        }

        return this.isCashOut();
    }

    public quantityMwhHidden(): boolean {
        return !this.isDeliveryPeriodDetermined();
    }

    public percentageHidden(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return true;
        }

        return this.isCashOut();
    }

    public averagingStartDateHidden(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return true;
        }

        return !this.averagingControl.value;
    }

    public averagingEndDateHidden(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return true;
        }

        return !this.averagingControl.value;
    }

    public priceHidden(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return true;
        }
        if (this.isDailyPriceModeWithPrice() && this.isDailyPriceActive) {
            return false;
        }

        return this.isPartnerMode() || this.isAveraging();
    }

    public curveEnabledHidden(): boolean {
        if (!this.isDeliveryPeriodDetermined() || this.isPartnerMode()) {
            return true;
        }

        return this.isStepByStep();
    }

    public curveImportHidden(): boolean {
        if (!this.isDeliveryPeriodDetermined()) {
            return true;
        }

        return !this.curveEnabledControl.value;
    }

    /** set disabled states */

    public setDisabledStates(): void {
        this.setAveragingDisabledState();
        this.setPartnerDisabledState();
        this.setDeliveryPeriodDisabledState();
        this.setTypeDisabledState();
        this.setMarketDisabledState();
        this.setPeriodDisabledState();
        this.setStartDateDisabledState();
        this.setEndDateDisabledState();
        this.setPeriodNumberDisabledState();
        this.setPurchaseStatusDisabledState();
        this.setQuantityDisabledState();
        this.setTrancheDisabledState();
        this.setQuantityMwhDisabledState();
        this.setPercentDisabledState();
        this.setAveragingStartEndDateDisabled();
    }

    public setAveragingDisabledState(): void {
        if (this.isModificationMode() || this.dialogConfig.lockedAveraging) {
            this.averagingControl.disable();
        } else {
            this.averagingControl.enable();
        }
    }

    public setPartnerDisabledState(): void {
        if (this.isModificationMode()) {
            this.partnerControl.disable();
        }
    }

    public setDeliveryPeriodDisabledState(): void {
        if (this.isModificationMode() || (this.isDailyPriceMode() && !_.isNil(this.deliveryPeriodControl.value))) {
            this.deliveryPeriodControl.disable();
        }
    }

    public setTypeDisabledState(): void {
        this.typeControl.disable();
    }

    public setMarketDisabledState(): void {
        if ((this.marketsFiltered.length === 1  && this.isPartnerMode()) || this.isDailyPriceMode()) {
            this.marketControl.disable();
        } else {
            this.marketControl.enable();
        }
    }

    public setPeriodDisabledState(): void {
        if (this.isDailyPriceMode()) {
            this.periodControl.disable();
        } else {
            this.periodControl.enable();
        }
    }

    public setStartDateDisabledState(): void {
    }

    public setEndDateDisabledState(): void {
    }

    public setPeriodNumberDisabledState(): void {
        const yearlyProductSelected: boolean = this.periodControl.value === OfferPeriod.Y;
        if (this.isDailyPriceMode() || yearlyProductSelected) {
            this.periodNumberControl.disable();
        } else {
            this.periodNumberControl.enable();
        }
    }

    public setPurchaseStatusDisabledState(): void {
        if (this.isPartnerMode() || this.isCurveBasedTransaction() || this.isDailyPriceMode()) {
            this.purchaseStatusControl.disable();
        } else {
            this.purchaseStatusControl.enable();
        }
    }

    public setQuantityDisabledState(): void {
        if (this.isCurveBasedTransaction()) {
            this.quantityControl.disable();
        } else {
            this.quantityControl.enable();
        }
    }

    public setTrancheDisabledState(): void {
    }

    public setQuantityMwhDisabledState(): void {
        this.quantityMwhControl.disable();
    }

    public setPercentDisabledState(): void {
        this.percentControl.disable();
    }

    public setAveragingStartEndDateDisabled(): void {
        if (_.isNil(this.periodControl.value) || _.isNil(this.periodNumberControl.value)) {
            this.averagingStartControl.disable();
            this.averagingEndControl.disable();
        } else {
            this.averagingStartControl.enable();
            this.averagingEndControl.enable();
        }
    }

    public setPriceDisabledState(): void {
        if (this.isPartnerMode()) {
            this.netPriceControl.disable(NewClaimDialogFormStateService.PREVENT_EVENT_BUBBLING);
        } else {
            this.netPriceControl.enable(NewClaimDialogFormStateService.PREVENT_EVENT_BUBBLING);
        }
    }

    public getTimeSeriesButtonsDisabled(): boolean {
        if (_.isNil(this.periodControl.value)) {
            return true;
        }
        if (this.isCustomPeriod()) {
            return _.isNil(this.startDateControl) || _.isNil(this.endDateControl.value);
        }

        return _.isNil(this.periodNumberControl.value);
    }

    private initValues(): void {
        this.partners = [];
        this.deliveryPeriods = [];
        this.types = [];
        this.markets = [];
        this.marketsFiltered = [];
        this.periods = [];
        this.periodsFiltered = [];
        this.periodNumbers = [];
        this.periodPeriodNumberMap = new Map<OfferPeriod, number[]>();
        this.startMinDate = null;
        this.startMaxDate = null;
        this.endMinDate = null;
        this.endMaxDate = null;
        this.averagingStartMinDate = null;
        this.averagingStartMaxDate = null;
        this.averagingEndMinDate = null;
        this.averagingEndMaxDate = null;
        this.destroy = new Subject();
    }

    private initFormControls(): void {
        this.averagingControl = new UntypedFormControl(false, Validators.required);
        this.partnerControl = new UntypedFormControl(null, [
            ReactiveFormUtil.conditionalRequired(() => this.isAdminMode())
        ]);
        this.deliveryPeriodControl = new UntypedFormControl(null, [
            ReactiveFormUtil.conditionalRequired(() => !this.isDeliveryPeriodSelectorOff())
        ]);
        this.typeControl = new UntypedFormControl(null, Validators.required);
        this.marketControl = new UntypedFormControl(null, Validators.required);
        this.periodControl = new UntypedFormControl(null, Validators.required);
        this.periodNumberControl = new UntypedFormControl(null, [
            ReactiveFormUtil.conditionalRequired(() => !this.isCustomPeriod())
        ]);
        this.startDateControl = new UntypedFormControl(null, [
            ReactiveFormUtil.conditionalRequired(() => this.isCustomPeriod())
        ]);
        this.endDateControl = new UntypedFormControl(null, [
            ReactiveFormUtil.conditionalRequired(() => this.isCustomPeriod())
        ]);
        this.purchaseStatusControl = new UntypedFormControl(null, Validators.required);
        this.quantityControl = new UntypedFormControl(null, [
            ReactiveFormUtil.conditionalRequired(() => this.isCashOut())
        ]);
        this.trancheControl = new UntypedFormControl(null, [
            ReactiveFormUtil.conditionalRequired(() => this.isStepByStep())
        ]);
        this.quantityMwhControl = new UntypedFormControl(null);
        this.percentControl = new UntypedFormControl(null);
        this.averagingStartControl = new UntypedFormControl(null, [
            ReactiveFormUtil.conditionalRequired(() => this.isAveraging())
        ]);
        this.averagingEndControl = new UntypedFormControl(null, [
            ReactiveFormUtil.conditionalRequired(() => this.isAveraging())
        ]);
        this.netPriceControl = new UntypedFormControl(null, [
            ReactiveFormUtil.conditionalRequired(() => this.isAdminMode() && !this.isAveraging())
        ]);
        this.curveEnabledControl = new UntypedFormControl(false);
        this.curveImportControl = new UntypedFormControl(null, [
            ReactiveFormUtil.conditionalRequired(() => this.isCurveBasedTransaction())
        ]);
    }

    private initFormGroup(): void {
        this.form = this.fb.group({
            averagingControl: this.averagingControl,
            partnerControl: this.partnerControl,
            deliveryPeriodControl: this.deliveryPeriodControl,
            typeControl: this.typeControl,
            marketControl: this.marketControl,
            periodControl: this.periodControl,
            periodNumberControl: this.periodNumberControl,
            startDateControl: this.startDateControl,
            endDateControl: this.endDateControl,
            purchaseStatusControl: this.purchaseStatusControl,
            quantityControl: this.quantityControl,
            trancheControl: this.trancheControl,
            quantityMwhControl: this.quantityMwhControl,
            percentControl: this.percentControl,
            averagingStartControl: this.averagingStartControl,
            averagingEndControl: this.averagingEndControl,
            priceControl: this.netPriceControl,
            curveEnabledControl: this.curveEnabledControl,
            curveImportControl: this.curveImportControl
        });
    }

    private subscribeDailyPriceStateChanges(): void {
        const change: DailyPriceStateChange = this.dailyPricesService.dailyPricesStateChange.getValue();
        this.isDailyPriceActive = change.type != null && change.type === DailyRateType.FIRM;
        this.dailyPricesService.dailyPricesStateChange.pipe(takeUntil(this.destroy))
            .subscribe((dlChange: DailyPriceStateChange) => this.isDailyPriceActive = dlChange.type != null && dlChange.type === DailyRateType.FIRM);
    }
}
