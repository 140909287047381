import { Route } from '@angular/router';

import { BillsComponent } from './bills.component';
import {UserRouteAccessService} from '../shared';
import {PartnerMandatoryGuard} from '../shared/partner-mandatory.guard';

export const BILLS_ROUTE: Array<Route> = [{
    path: 'invoices',
    component: BillsComponent,
    data: {
        permission: 'INVOICE_SCREEN',
        pageTitle: 'main.link.bills',
        softPermissions: ['INVOICE_READ']
    },
    canActivate: [UserRouteAccessService, PartnerMandatoryGuard]
}];
