<mat-card class="docBackground px-4 mb-4 side-card">
    <div class="my-2">
        <mat-form-field class="w-100">
            <mat-label jhiTranslate="filter.basic.templates">Mentett sablon/diagram betöltése
            </mat-label>
            <ng-select
                [title]="selectedTemplate ? selectedTemplate.name : ''"
                [(ngModel)]="selectedTemplate"
                [items]="viewTemplates"
                [searchable]="false"
                [clearable]="true"
                (change)="loadTemplate()"
                ngSelectMat
                bindLabel="name"
                class="template-selector"
                name="company">
                <ng-template ng-label-tmp let-item="item">
                    <mat-icon class="selected" [title]="''" matTooltip="Sablon típus" [matTooltipPosition]="'above'" fontIcon="" *ngIf="item.dynamic">
                        notes
                    </mat-icon>
                    <mat-icon class="selected"  [title]="''" matTooltip="Diagram típus" [matTooltipPosition]="'above'" *ngIf="!item.dynamic">
                        bar_chart
                    </mat-icon>
                    {{item.name}}
                </ng-template>
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="option-item-name" [title]="item?.name">{{item.name}}</div>
                    <mat-icon [title]="''" matTooltip="{{'filter.basic.templateType' | translate}}" fontIcon="" *ngIf="item.dynamic">
                        notes
                    </mat-icon>
                    <mat-icon [title]="''" matTooltip="{{'filter.basic.chartType' | translate}}" *ngIf="!item.dynamic">bar_chart
                    </mat-icon>
                </ng-template>
            </ng-select>
        </mat-form-field>
        <div class="form-row">
            <div class="col-xl-6 my-1">
                <button class="btn btn-primary btn-block my-2"
                        *ngIf="selectedTemplate"
                        (click)="deleteViewTemplate()" jhiTranslate="filter.basic.delete">
                    Törlés
                </button>
            </div>
            <div class="col-xl-6 text-xl-right my-1">
                <button class="btn btn-primary btn-block my-2"
                        *ngIf="selectedTemplate"
                        (click)="resetViewTemplate()" jhiTranslate="filter.basic.resetToDefault">
                    Alaphelyzet
                </button>
            </div>
        </div>
    </div>
</mat-card>

<mat-card class="docBackground px-4 side-card">
    <div class="pull-center mt-3">
        <form (ngSubmit)="runReport()" name="filterForm" role="form" #filterForm="ngForm">

            <div class="form-group mb-3">
                <mat-form-field class="w-100">
                    <mat-select required name="selectedDiagram"
                                [(ngModel)]="filterModel.selectedDiagram"
                                (selectionChange)="diagramSelected()">
                        <ng-container *ngFor="let diagram of diagramListOptions">
                            <mat-option [value]="diagram"
                                        [disabled]="diagram.requiredPermission | doesNotHavePermission"
                                        *permissionOverlay="diagram.requiredPermission">
                                {{translateService.instant(diagram.name)}}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
            <div *ngIf="filterModel.selectedDiagram">
                <div class="form-row">
                    <div class="form-group col-xl-6">
                        <label class="label">{{podDefaultTitle}}</label>


                      <ng-multiselect-dropdown
                        name="selectedPods"
                        [placeholder]="'multiSelectText.defaultTitle' | translate"
                        [settings]="dropdownSettings"
                        [data]="podListOptions"
                        [(ngModel)]="filterModel.selectedPods"
                        (ngModelChange)="podSelectionChange()">
                      </ng-multiselect-dropdown>

              <!--    <ss-multiselect-dropdown name="selectedPods" [(ngModel)]="filterModel.selectedPods"
                                                 style="margin-top: 26px;"
                                                 [texts]="podSelectTexts"
                                                 [options]="podListOptions"
                                                 [settings]="mySettings"
                                                 (ngModelChange)="podSelectionChange()"
                                                 ngDefaultControl>
                        </ss-multiselect-dropdown>-->
                    </div>
                    <div class="form-group col-xl-6">
                        <label class="label">{{podGroupDefaultTitle}}</label>

                      <ng-multiselect-dropdown
                        name="selectedPodGroups"
                        [placeholder]="'multiSelectText.defaultTitle' | translate"
                        [settings]="dropdownSettings"
                        [data]="podGroupsOptions"
                        [(ngModel)]="filterModel.selectedPodGroups">
                      </ng-multiselect-dropdown>


                   <!--   <ss-multiselect-dropdown name="selectedPodGroups" [(ngModel)]="filterModel.selectedPodGroups"
                                                 style="margin-top: 26px;"
                                                 [texts]="podGroupSelectTexts"
                                                 [options]="podGroupsOptions"
                                                 [settings]="mySettings"
                                                 ngDefaultControl>
                        </ss-multiselect-dropdown>-->
                    </div>
                </div>

                <div class="form-row">
                    <div class="container">
                        <period-parameter-selector [upperBound]="filterModel.multiplePeriod ? 4 : 1"
                                                   [(periodParameterModel)]="this.filterModel.periodParameters"></period-parameter-selector>
                    </div>
                </div>

                <div class="form-row"
                     *ngIf="filterModel.selectedDiagram.referenceIntervalAllowed && filterModel.resolution === 'MIN_15'">
                    <div class="container">
                        <period-parameter-selector *ngIf="filterModel.referenceWeekChecked" class="mt-3 d-block"
                                                   [(periodParameterModel)]="filterModel.referenceWeek"
                                                   [upperBound]="1"></period-parameter-selector>
                        <mat-slide-toggle class="ml-3 mb-3" color="primary" *ngIf="filterModel.referenceWeekChecked"
                                          [(ngModel)]="filterModel.excludeBusinessHolidays">
                            {{'consumption.diagram.diagram-options.w_off_day' | translate}}
                        </mat-slide-toggle>
                        <div class="row">

                            <div class="col-12 text-xl-right mb-1" *ngIf="filterModel.referenceWeekChecked">
                                <button class="btn btn-block" (click)="filterModel.referenceWeekChecked = false">
                                    <span class="fa fa-calendar-minus-o"></span> {{'Referencia időszak törlése'}}
                                </button>
                            </div>

                            <div class="col-12 text-xl-right my-1" *ngIf="!filterModel.referenceWeekChecked">
                                <button class="btn btn-block" (click)="filterModel.referenceWeekChecked = true">
                                    <span class="fa fa-calendar-plus-o"></span> {{'Referencia időszak hozzáadása'}}
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div class="form-row my-3">
                <!--Kimutatás készítése gomb-->
                <div class="col text-xl-right my-1 min-width-200">
                    <button class="btn btn-primary btn-block"
                            [disabled]="filterForm.form.invalid || !showEnabled() "
                            jhiTranslate="filter.basic.show"></button>
                </div>

                <!--Összehasonlítás készítése gomb-->
                <div *ngIf="comparisonAllowed" class="col my-1 min-width-200">
                    <ng-container *ngIf="podComparisionEnabled();else intervalComparision">
                        <button class="btn btn-primary btn-block"
                                [ngClass]="{'unavailable-button': 'CONSUMPTION_COMPARISION_BY_POD' | doesNotHavePermission}"
                                [disabled]="filterForm.form.invalid"
                                *permissionOverlay="'CONSUMPTION_COMPARISION_BY_POD'"
                                (click)="filterModel.comparePods=true" jhiTranslate="filter.basic.compare">
                            Összehasonlítás készítése
                        </button>
                    </ng-container>
                    <ng-template #intervalComparision>
                        <ng-container *ngIf="intervalComparisionEnabled();else disabledComparision">
                            <button class="btn btn-primary btn-block"
                                    [disabled]="filterForm.form.invalid"
                                    [ngClass]="{'unavailable-button': 'CONSUMPTION_COMPARISION_BY_INTERVAL' | doesNotHavePermission}"
                                    *permissionOverlay="'CONSUMPTION_COMPARISION_BY_INTERVAL'"
                                    (click)="filterModel.comparePods=true" jhiTranslate="filter.basic.compare">
                                Összehasonlítás készítése
                            </button>
                        </ng-container>
                    </ng-template>
                    <ng-template #disabledComparision>
                        <button class="btn btn-primary btn-block"
                                disabled
                                jhiTranslate="filter.basic.compare">
                            Összehasonlítás készítése
                        </button>
                    </ng-template>
                </div>

            </div>

        </form>
    </div>
</mat-card>

<mat-card class="docBackground px-4 my-4 side-card">
    <div class="form-row">
        <div class="col-xl-6 my-1">
            <button class="btn btn-primary btn-block my-2"
                    [disabled]="filterForm.form.invalid || !anyActionButtonEnabled() || selectedTemplate == null"
                    (click)="openTemplateSaveDialog()" jhiTranslate="common.action.save">
                Mentés
            </button>
        </div>
        <div class="col-xl-6 text-xl-right my-1">
            <button class="btn btn-primary btn-block my-2"
                    [disabled]="filterForm.form.invalid || !anyActionButtonEnabled()"
                    (click)="openTemplateSaveDialog(true)" jhiTranslate="common.action.saveAsNew">
                Mentés újként
            </button>
        </div>
    </div>
</mat-card>
