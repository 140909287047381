<mat-card class="over-flow-scrollbar">
    <div>{{ 'marketPrices.additional-timeseries.title' | translate }}</div>
    <mat-card-content class="min-height-300">
        <p-table #dt [value]="additionalTimeSeries" sortMode="multiple"
                 [scrollable]="true" scrollHeight="300px"
                 [multiSortMeta]="[{field: 'name', order: 1}]"
                 class="table table-striped selectable-table">
            <ng-template pTemplate="header">
                <tr style="background-color: white; border-bottom: 2px solid #dee2e6; border-top: 2px solid #dee2e6;">
                    <th class="info-column"></th>
                    <th [pSortableColumn]="'name'">
                        {{'marketPrices.additional-timeseries.header.name' | translate}}
                        <p-sortIcon [field]="'name'"></p-sortIcon>
                    </th>
                    <th class="additional-display-column-width"></th>
                    <th [pSortableColumn]="'selected'" class="additional-display-column-width">
                        <span class="no-linebreak">
                            {{'marketPrices.additional-timeseries.header.selected' | translate}}
                            <p-sortIcon [field]="'selected'"></p-sortIcon>
                        </span>
                    </th>
                </tr>

                <tr style="background-color: white; border-bottom: 2px solid #dee2e6; border-top: 2px solid #dee2e6;">
                    <th class="info-column"></th>
                    <th>
                        <mat-form-field>
                            <input
                                matInput
                                (input)="dt.filter($event.target.value, 'name', 'contains')"
                                placeholder="{{'global.search' | translate}}">
                        </mat-form-field>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <td class="info-column">
                        <mat-icon *ngIf="rowData.description"
                                  [matTooltip]="rowData.description"
                                  matTooltipClass="largeFontMultiline"
                                  [attr.aria-label]="rowData.description"
                                  class="info-icon">info
                        </mat-icon>
                    </td>
                    <td class="additional-name-column-align">{{rowData.name}}</td>
                    <td>
                        <mat-icon *ngIf="rowData.isGroup"
                                  class="group-icon"
                                  matTooltipClass="largeFont"
                                  matTooltip="{{'marketPrices.additional-timeseries.group' | translate:rowData}}">
                            category
                        </mat-icon>
                        <mat-icon *ngIf="!rowData.isGroup"
                                  matTooltipClass="largeFont"
                                  matTooltip="{{'marketPrices.additional-timeseries.single' | translate}}"
                                  class="fa-rotate-270 group-icon">play_arrow
                        </mat-icon>
                    </td>
                    <td>
                        <p-checkbox [ngModel]="rowData.selected" [disabled]="!rowData.hasPermission"
                                      *permissionOverlay="'';disabled:rowData.hasPermission;class:'permission-overlay-checkbox'"
                                      (ngModelChange)="selectionChanged($event, rowData)" binary="true" />
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </mat-card-content>
</mat-card>
