import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NgBDatePickerConvertService } from '../../shared/services/ngb.datepicker.convert.service';
import { FileDto } from '../dto/FileDto';
import { PagerModel } from '../dto/page.model';
import { ProductAndServicesUserModel } from '../dto/product-and-services-user.model';
import { ProductAndServicesModel } from '../dto/product-and-services.model';

@Injectable()
export class ProductService {

    constructor(private http: HttpClient,
                private dateConverter: NgBDatePickerConvertService) {

    }

    getProductsAdmin(): Observable<ProductAndServicesModel[]> {
        return this.http.get<PagerModel<ProductAndServicesModel>>('api/product_and_service/admin').pipe(map(r => r.content));
    }

    getProducts(categoryId: number = null): Observable<ProductAndServicesUserModel[]> {
        if (categoryId) {
            return this.http.get<any>('api/product_and_service/by_category/' + categoryId).pipe(map(r => r.contentList));
        } else {
            return this.http.get<PagerModel<ProductAndServicesUserModel>>('api/product_and_service').pipe(map(r => r.content));
        }
    }

    createOrUpdateProduct(product: ProductAndServicesModel): Observable<ProductAndServicesModel> {
        if (product.id)
            return this.http.put<ProductAndServicesModel>('api/product_and_service/admin', product);
        else
            return this.http.post<ProductAndServicesModel>('api/product_and_service/admin', product);
    }

    deleteProduct(productId: number): Observable<any> {
        return this.http.delete('api/product_and_service/admin/' + productId);
    }

    uploadProductFile(productId: number, files: File): Observable<FileDto> {
        let data = new FormData();
        data.append('file', files);
        return this.http.post<FileDto>('api/product_and_service/admin/' + productId + '/file', data);
    }

    setVisibility(productId: number, visible: boolean): Observable<void> {
        return this.http.patch<void>(`api/product_and_service/admin/${productId}/visible/${visible}`, null);
    }
}
