import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {EmailHistory} from "../model/email-history.model";

@Injectable({
    providedIn: 'root'
})
export class EmailHistoryService {

    constructor(private http: HttpClient) {
    }

    public getEmailHistory(from: string, to: string): Observable<EmailHistory[]> {
        let params = new HttpParams();
        if (from) {
            params = params.append('from', from.toString());
        }
        if (to) {
            params = params.append('to', to.toString());
        }

        return this.http.get<EmailHistory[]>('api/email_history', {
            params: params
        });
    }

}
