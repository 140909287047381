import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContactModel } from '../dto/contact.model';

@Component({
    selector: 'jhi-contact-list',
    templateUrl: './contact-list.component.html',
    styles: []
})
export class ContactListComponent implements OnInit {

    @Input() contacts: ContactModel[] = [];
    @Input() adminView: boolean = false;
    @Input() preventDelete: boolean = false;
    @Output() editContact: EventEmitter<ContactModel> = new EventEmitter();
    @Output() deleteContact: EventEmitter<number> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    edit(contact: ContactModel) {
        this.editContact.emit(contact);
    }

    delete(contact: ContactModel) {
        this.deleteContact.emit(contact.id);
    }

}
