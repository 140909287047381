<mat-accordion>
    <div *ngFor="let category of documentByCategory.subCategories">
        <mat-expansion-panel *ngIf="category.show"
                             [disabled]="category.contentList.length == 0"
                             [expanded]="category.expand">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{category.categoryName}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                <mat-list [disableRipple]="true">
                    <div *ngFor="let item of category.contentList">
                        <div *ngIf="item.show">
                            <mat-list-item>
                                <mat-icon mat-list-icon aria-hidden="true">
                                    {{documentFilterService.getCssForFileExtension(item.documentFile?.mimeType)}}
                                </mat-icon>
                                <h4 mat-line>
                                    <a href="{{item.documentFile?.urlPath}}" target="_blank" restartSession>
                                        {{item.title}}
                                    </a>
                                </h4>
                                <p mat-line>
                                    {{'documents.created' | translate}}:
                                    {{item.releaseTime | cezDate}}
                                </p>
                                <p mat-line *ngIf="adminMode">
                                    {{'documents.langKey' | translate}}:
                                    {{item.language | findLanguageFromKey}}
                                </p>
                                <div class="doc-item-buttons">
                                    <button mat-raised-button
                                            (click)="item.showDetail = !item.showDetail"
                                            color="primary">
                                        {{'health.details.details' | translate}}
                                    </button>
                                    <button *ngIf="adminMode" color="primary" mat-mini-fab
                                            (click)="editClick.emit(item)">
                                        <mat-icon>edit</mat-icon>
                                    </button>
                                    <button *ngIf="adminMode" color="primary" mat-mini-fab
                                            (click)="deleteClick.emit(item)">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </mat-list-item>
                            <div class="document-list-details" *ngIf="item.showDetail">
                                <p><b>{{'documents.description' | translate}}:</b> {{item.description}}</p>
                                <p><b>{{'documents.size' | translate}}:</b> {{item.documentFile?.sizeBytes |
                                    ByteFormatterPipe}}</p>
                            </div>
                        </div>
                    </div>
                </mat-list>
            </div>
        </mat-expansion-panel>
    </div>
</mat-accordion>
