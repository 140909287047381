import { Component, HostListener } from '@angular/core';
import { PermissionOverlayPopupComponent } from './permission-overlay-popup/permission-overlay-popup.component';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
    selector: 'permission-overlay-component',
    templateUrl: './permission-overlay.component.html',
    styleUrls: ['./permission-overlay.component.scss']
})
export class PermissionOverlayComponent {

    constructor(private dialog: MatDialog) {
    }

    @HostListener('click')
    onClick() {
        this.dialog.open(PermissionOverlayPopupComponent, {
            disableClose: true,
            panelClass: 'session-warning-popup'
        }).afterClosed();
    }

}
