import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {AuthServerProvider} from '../shared/auth/auth-session.service';

import {Principal} from '../shared/auth/principal.service';
import {StateStorageService} from '../shared/auth/state-storage.service';
import {Account} from '../shared/user/account.model';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DailyPricesService} from '../shared/services/daily-prices.service';
import {CoverageTransactionsService} from "../coverage-transactions/coverage-transactions.service";
import {ServerSentEventService} from '../sse/server-sent-event.service';

@Injectable()
export class LoginService {
    private loginCallbacks: Function[] = [];
    private logoutEvent: Subject<any> = new Subject();

    logoutCause = null;

    constructor(
        private principal: Principal,
        private authServerProvider: AuthServerProvider,
        private stateStorageService: StateStorageService,
        private matDialogService: MatDialog,
        private dailyPricesService: DailyPricesService,
        private modalService: NgbModal,
        private coverageTransactionsService: CoverageTransactionsService,
        private sseService: ServerSentEventService
    ) { }

    loginSubscribe(callback: Function) {
        this.loginCallbacks.push(callback);
    }

    login(credentials, callback?) {
        let cb = callback || function () { };

        return new Promise((resolve, reject) => {
            this.authServerProvider.login(credentials).subscribe(data => {
                this.principal.identity(true).then((account: Account) => {
                    // After the login the language will be changed to
                    // the language selected by the user during his registration
                    resolve(account);
                    this.loginCallbacks.forEach(r => r());
                    this.dailyPricesService.initDailyPrices();
                    this.coverageTransactionsService.initCartVisibility();
                });
                return cb();
            }, err => {
                this.logout();
                reject(err);
                return cb(err);
            });
        });
    }

    getLogoutEvent() {
        return this.logoutEvent.asObservable();
    }

    logout(cause?: string) {
        this.matDialogService.closeAll();
        this.modalService.dismissAll();
        this.logoutEvent.next();

        this.sseService.unsubscribeFromSse().subscribe(() => {
            this.authServerProvider.logout().subscribe();
        });
        this.dailyPricesService.resetRemainTimeCounter();

        this.principal.authenticate(null);
        this.logoutCause = cause || null;
        this.stateStorageService.clearStorage();
    }
}
