import {PALETTE} from '../../shared/color-palette/color-palette.constant';
import {ProductType} from '../../shared/daily-rates/model/product-type.enum';
import {Color, ColorConstants} from '../../shared/color-palette/color-palette.model';

export class ColorUtil {
    /**
     * Get color from color palette by ProductType and Shade
     *
     * @param productType Type of the product.
     * @param shade number between 1 - 3.
     * @return string Color as hexadecimal string
     * */
    public static getColorFromPaletteByProductType(productType: ProductType, shade: number): string {
        if (productType === ProductType.YEARLY) {
            return ColorUtil.getColorFromPaletteBy(ColorConstants.ORANGE, shade);
        } else if (productType === ProductType.QUARTERLY) {
            return ColorUtil.getColorFromPaletteBy(ColorConstants.YELLOW, shade);
        } else if (productType === ProductType.MONTHLY) {
            return ColorUtil.getColorFromPaletteBy(ColorConstants.BLUE, shade);
        } else {
            return ColorUtil.getColorFromPaletteBy(ColorConstants.RED, shade);
        }
    }

    /**
     * Get color from color palette by Color and Shade
     *
     * @param color Color from palette.
     * @param shade number between 1 - 3.
     * @return string Color as hexadecimal string
     * */
    public static getColorFromPaletteBy(color: Color, shade: number): string {
        if (shade < 1) {
            shade = 1;
        } else if (shade > 3) {
            shade = 3;
        }

        return PALETTE[color][3 - shade];
    }
}
