import { LocalizedTexts } from "../../shared/dto/localized-texts.model";

export class TermsOfService {  
    id: number;
    text: LocalizedTexts;

    constructor() {
        this.text = new LocalizedTexts();
    }
}
