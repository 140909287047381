import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import DataUpdateTrackingModel from "../dto/data-update-tracking.model";
import * as moment from "moment";
import {Moment} from "moment";
import {DialogService} from "../dialog/dialog.service";
import {DeliveryPeriodDto} from '../dto/delivery-period-dto';
import {DataUpdateModel, DeliveryPeriodDataUpdateModel} from '../dto/data-update.model';

@Injectable({
    providedIn: 'root'
})
export class DataUpdateTrackingService {
    private LAST_UPDATE_STALENESS_DAYS_THRESHOLD = 40;

    constructor(private http: HttpClient,
                private dialogService: DialogService) {
    }

    public getAllDataUpdateTracking(): Observable<DataUpdateTrackingModel> {
        return this.http.get<DataUpdateTrackingModel>('api/data-update-tracking/all');
    }

    public getHedgeConsumptionPlanUpdateTrackingData(deliveryPeriodId: number): Observable<DataUpdateModel> {
        return this.http.get<DataUpdateModel>('api/data-update-tracking/hedge-consumption-plan/' + deliveryPeriodId);
    }

    public showPopup() {
        this.getAllDataUpdateTracking().subscribe((value) => {
            this.dialogService.showLastUpdates(value, {minWidth: 650, disableClose: true});
        });
    }

    public isLastUpdateStale(update: DataUpdateModel, selectedDeliveryPeriod?: DeliveryPeriodDto): boolean {
        const lastModifiedDate = update ? moment(update.lastModifiedDate) : null;
        const currentDeliveryPeriod: boolean = selectedDeliveryPeriod && moment(selectedDeliveryPeriod.startTime).year() === moment().year();
        return currentDeliveryPeriod && moment().diff(lastModifiedDate, 'days') > this.LAST_UPDATE_STALENESS_DAYS_THRESHOLD;
    }

    public getFormattedDateAndModifier(update: DataUpdateModel | DeliveryPeriodDataUpdateModel): string {
        return this.getFormattedDate(update.lastModifiedDate);
    }

    private getFormattedDate(date: string | Date | Moment): string {
        return moment(date).format('l');
    }
}
