<table class="table table-striped">
  <thead>
    <tr>
      <th><span jhiTranslate="agreement.agreementData.contacts.name">Name</span></th>
      <th><span jhiTranslate="agreement.agreementData.contacts.phone">Phone number</span></th>
      <th> <span jhiTranslate="agreement.agreementData.contacts.mobile">Mobile number</span></th>
      <th><span jhiTranslate="agreement.agreementData.contacts.e-mail">E-mail</span></th>
      <th *ngIf="adminView"><span></span></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let contact of contacts">
      <td>{{contact.name}}</td>
      <td>{{contact.phone}}</td>
      <td>{{contact.mobile}}</td>
      <td>{{contact.email}}</td>
      <td *ngIf="adminView">
        <button type="button" class="btn btn-info btn-sm" (click)="edit(contact)">
          <span class="fa fa-pencil"></span>
        </button>
        <button type="button" *ngIf="!preventDelete" class="btn btn-danger btn-sm" (click)="delete(contact)">
          <span class="fa fa-remove"></span>
        </button>
      </td>
    </tr>
  </tbody>
</table>
