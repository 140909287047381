export type ToleranceType =
    'CONSUMPTION_PLAN_RATE'
    | 'CONTRACTED_PLAN'
    | 'AVERAGE_CONSUMPTION'
    | 'EXPECTED_PLAN'
    | 'PERIOD_AVERAGE';

export const ToleranceType = {
    CONSUMPTION_PLAN_RATE: 'CONSUMPTION_PLAN_RATE' as ToleranceType,
    CONTRACTED_PLAN: 'CONTRACTED_PLAN' as ToleranceType,
    AVERAGE_CONSUMPTION: 'AVERAGE_CONSUMPTION' as ToleranceType,
    EXPECTED_PLAN: 'EXPECTED_PLAN' as ToleranceType,
    PERIOD_AVERAGE: 'PERIOD_AVERAGE' as ToleranceType
};
