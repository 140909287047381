import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';

import { LinkByCategoryModel } from './index';
import {NewsService} from '../shared/services/news.service';


@Component({
    selector: 'useful-links',
    templateUrl: './link.html'
})
export class LinkComponent implements OnInit {
    public linkList: Array<LinkByCategoryModel>;
    @Input() categoryId: number;

    constructor(private http: HttpClient,
                public newsService: NewsService) {
    }

    ngOnInit() {
        this.refreshLinks();
    }

    refreshLinks() {
        this.http.get<any>('/api/useful_link/by_category/' + this.categoryId).subscribe(r =>
            this.linkList = r.contentList
        );
    }
}
