import {Pipe, PipeTransform} from "@angular/core";
import {DatePipe} from "@angular/common";
import {StateStorageService} from "../auth";
import {LocatorService} from "../services/locator.service";

@Pipe({
    name: 'cezDateTime'
})
export class CezDateTimePipe implements PipeTransform {

    transform(value: any): any {
        let langKey = this.getLangKey();
        let datePipe = new DatePipe('en-US');
        return datePipe.transform(value, this.getMomentFormat(langKey));
    }

    transformWithPattern(value: any, pattern: string): any {
        let datePipe = new DatePipe('en-US');
        return datePipe.transform(value, pattern);
    }

    getMomentFormat(langKey: string): string {
        if (langKey === 'hu')
            return 'yyyy.MM.dd. HH:mm:ss';

        return 'dd/MM/yyyy HH:mm:ss';
    }

    getLangKey() {
        let stateStorageService = <StateStorageService>LocatorService.injector.get(StateStorageService);
        let langKey = stateStorageService.getLanguage();

        return langKey;
    }
}
