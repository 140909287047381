<div class="modal-header">
  <h4 class="modal-title">{{(doc?.id ? 'documents.modal.modifyTitle' : 'documents.modal.addTitle') | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>


<form #documentEditForm="ngForm" (ngSubmit)="onSubmit(documentEditForm)">
  <div class="modal-body">
    <div class="form-group">
      <label jhiTranslate="documents.category"></label>
      <select required name="category" [(ngModel)]="selectedCategory" (change)="refreshSubCategories()"
              class="form-control">
        <option></option>
        <option *ngFor="let item of categories" [ngValue]="item">{{item.name}}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="this.selectedCategory && this.selectedCategory.id == -1">
      <label>Partner</label>
      <input disabled name="company" type="text" value="{{selectedCompany.accountName}}" class="form-control">
    </div>
    <div class="form-group">
      <label jhiTranslate="documents.subCategory"></label>
      <select required name="subCategory" [(ngModel)]="selectedSubCategory" class="form-control">
        <option></option>
        <option *ngFor="let item of subCategories" [ngValue]="item">{{item.name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label jhiTranslate="documents.title"></label>
      <input required name="title" [ngModel]="doc.title" class="form-control">
    </div>
    <div class="form-group" *ngIf="languages && languages.length > 0">
      <label jhiTranslate="documents.langKey"></label>
      <select required class="form-control" id="language" name="language" [(ngModel)]="doc.language">
        <option></option>
        <option *ngFor="let language of languages" [value]="language">{{language | findLanguageFromKey}}</option>
      </select>
    </div>
    <div class="form-group">
      <label jhiTranslate="documents.description"></label>
      <input required name="description" [ngModel]="doc.description" class="form-control">
      <br>
      <label jhiTranslate="documents.file"></label>
      <!--<file-uploader [fileType]="'application/*'" (onSelection)="fileuploaderFileChange($event)" [required]="!doc.id"></file-uploader>-->
      <div class="col-auto">
        <div style="display: flex; align-items: center;">
          <input placeholder="{{'select.default' | translate}}" readonly class="form-control"
                 style="width: 200px; float: left; margin-right: 0px;" matInput *ngIf="!fileInput?.httpUrl"
                 value="{{fileInput?.files[0]?.name}}"/>

          <button mat-mini-fab color="primary" (click)="addFile(fileInput)" type="button">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <input #fileInput [hidden]="true" type="file" accept="application/*"/>
      </div>

      <cez-file-upload class="col"
                       *ngIf="fileInput.value"
                       [file]="fileInput.files[0]"
                       [autoUpload]="true"
                       (onUpload)="onUpload($event)"
                       (removeEvent)="fileInput.value = ''"></cez-file-upload>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" jhiTranslate="entity.action.save"
            [disabled]="documentEditForm.form.invalid || isSaving || (!doc.id && !fileInput.files) ">Submit
    </button>
  </div>
</form>
