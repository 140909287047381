import {Category, FileDto} from '../shared';

export class LinkModel {
    id: number;
    title: string;
    description: string;
    linkUrl: string;
    picture: FileDto;
    language: string;
    releaseTime: Date;
    categories: Category[];
    visible: boolean;
}
