import {NgModule} from '@angular/core';
import {FileUploadComponent} from './matFileUpload/file-upload.component';
import {FileUploadQueueComponent} from './matFileUploadQueue/file-upload-queue.component';
import {FileUploadInputForDirective} from './fileUploadInputFor.directive';

import {MatIconModule} from '@angular/material/icon';
import {HttpClientModule} from '@angular/common/http';
import {BytesPipe} from './bytes.pipe';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {SessionTimeoutDirective} from '../session-timer/directive/session-timeout.directive';
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {MatLegacyCardModule as MatCardModule} from "@angular/material/legacy-card";


@NgModule({
    imports: [
        MatButtonModule,
        MatProgressBarModule,
        MatIconModule,
        MatCardModule,
        HttpClientModule,
        CommonModule,
        TranslateModule
    ],
    declarations: [
        FileUploadComponent,
        FileUploadQueueComponent,
        FileUploadInputForDirective,
        BytesPipe,
        SessionTimeoutDirective
    ],
    exports: [
        FileUploadComponent,
        FileUploadQueueComponent,
        FileUploadInputForDirective,
        BytesPipe,
        SessionTimeoutDirective
    ]
})
export class MatFileUploadModule {
}
