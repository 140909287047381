<h1 mat-dialog-title class="modal-primary">{{modalTitle}}</h1>
<div mat-dialog-content>

    <form [formGroup]="form" class="d-flex flex-column">
        <mat-form-field>
            <input matInput
                   formControlName="groupNameEN"
                   placeholder="{{'additional-diag-group-edit.groupName.en' | translate}}"
                   required>
        </mat-form-field>

        <mat-form-field>
            <input matInput
                   formControlName="groupNameHU"
                   placeholder="{{'additional-diag-group-edit.groupName.hu' | translate}}"
                   required>
        </mat-form-field>

        <mat-form-field>
            <textarea matInput
                      formControlName="groupDescriptionEN"
                      placeholder="{{'additional-diag-group-edit.group-description.en' | translate }}">
            </textarea>
        </mat-form-field>

        <mat-form-field>
            <textarea matInput
                      formControlName="groupDescriptionHU"
                      placeholder="{{'additional-diag-group-edit.group-description.hu' | translate }}">
            </textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="{{'additional-diag-group-edit.diagramType' | translate }}"
                        formControlName="diagramType"
                        required>
                <mat-option *ngFor="let entry of diagramType | keys" [value]="entry.value">
                    {{'DiagramType.' + entry.value | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="{{'additional-diag-group-edit.additionalDiagTypes' | translate }}"
                        formControlName="additionalDiagramTypes"
                        [compareWith]="compareWithFunc"
                        multiple>
                <mat-option *ngFor="let diagType of additionalDiagramTypes" [value]="diagType">
                    {{diagType | translateAdditionalDiagramType}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="{{'additional-diag-group-edit.products' | translate }}"
                        formControlName="products"
                        [compareWith]="compareWithFuncProduct"
                        multiple>
                <mat-option *ngFor="let product of products" [value]="product">
                    {{product.productName}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select placeholder="{{'additional-diag-group-edit.spots' | translate }}"
                        formControlName="spots"
                        [compareWith]="compareWithFuncSpot"
                        multiple>
                <mat-option *ngFor="let spot of spots" [value]="spot">
                    {{spot.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

    </form>

</div>


<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close class="cez-color--dark">
        {{'entity.action.cancel' | translate }}
    </button>
    <button mat-raised-button
            (click)="save()"
            color="primary"
            [disabled]="!form.valid">
        {{'entity.action.save' | translate }}
    </button>
</div>
