import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ToastMessageModel} from '../dto/toast-message.model';

@Component({
    selector: 'jhi-toast-message-component',
    templateUrl: './toast-message-component.component.html',
    styles: []
})
export class ToastMessageComponentComponent implements OnInit {

    @Input() alerts: ToastMessageModel[] = [];
    @Output() removeAlerts: EventEmitter<void> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    closeAlert(alert: any) {
        this.alerts.splice(this.alerts.indexOf(alert), 1);
    }

    getAlertIcon(type: string): string {
        if (type === 'danger') {
            return 'cancel';
        } else if (type === 'warning') {
            return 'error';
        } else if (type === 'success') {
            return 'done';
        }

        return 'info';
    }

    clearAlerts(): void {
        this.removeAlerts.emit();
    }
}
