import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {StateStorageService} from '../auth';
import {DialogService} from '../dialog/dialog.service';
import {TranslateService} from '@ngx-translate/core';

@Directive({
    selector: '[writeProtected]'
})
export class WriteProtectedDirective {

    @Input("writeProtected") permissions: string | string[];
    @Output() clickIfHasPermission: EventEmitter<void> = new EventEmitter();
    @Input() writeProtectEnabled: boolean = true;

    constructor(private stateStorageService: StateStorageService,
                private dialogService: DialogService,
                private translate: TranslateService) {
    }

    @HostListener('click', ['$event'])
    public onClick(event: Event): void {
        if (!this.writeProtectEnabled) {
            return;
        }

        const permissions: string[] = this.stateStorageService.getEffectivePermissions();
        const hasAnyPermission: boolean = Array.isArray(this.permissions) ?
            this.permissions.some((permission: string): boolean => permissions.indexOf(permission) !== -1) :
            permissions.indexOf(this.permissions) !== -1;

        if (hasAnyPermission) {
            this.clickIfHasPermission.emit();
        } else {
            event.preventDefault(); // prevents submit form
            this.dialogService.alert(this.translate.instant('validation.HAVE_NO_PERMISSION_FOR_THIS_OPERATION'), '');
        }
    }

}
