<div [ngClass]="tabContent ? 'container-fluid no-padding' : 'container-fluid'">
    <div class="row">
        <div [ngClass]="{'col-lg-4': compactSelected, 'col-lg-5': !compactSelected}">
            <jhi-market-products [products]="products" (timeSeriesDisplay)="showHideDiagram($event)"
                                 [(compactSelected)]="compactSelected"></jhi-market-products>
            <jhi-additional-timeseries [additionalTimeSeries]="additionalTimeSeries"
                                       (timeSeriesDisplay)="showHideDiagram($event)"></jhi-additional-timeseries>
        </div>
        <div [ngClass]="{'col-lg-8': compactSelected, 'col-lg-7': !compactSelected}">
            <mat-card>
                <mat-card-content>
                    <div class="container-fluid">
                        <div class="d-flex row">
                            <div class="col-md-8">
                                <jhi-display-mode (settingsChanged)="displaySettingsChanged($event)"
                                                  [from]="timeSeriesFrom"
                                                  [to]="timeSeriesTo"
                                                  [resolution]="timeSeriesResolution"></jhi-display-mode>
                            </div>
                            <div class="col-md-4">
                                <jhi-diagram-options
                                    [(minSelected)]="minSelected"
                                    [(maxSelected)]="maxSelected"
                                    [(avgSelected)]="avgSelected"></jhi-diagram-options>
                            </div>
                        </div>
                    </div>
                    <jhi-diagram [timeSeriesDataChanges]="timeSeriesDataChanges"
                                 [minSelected]="minSelected"
                                 [maxSelected]="maxSelected"
                                 [resolution]="timeSeriesResolution"
                                 [compactSelected]="compactSelected"
                                 [averageSelected]="avgSelected"></jhi-diagram>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
