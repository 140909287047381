import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CompanyModel} from '../dto/company.model';

@Component({
    selector: 'jhi-company-list',
    templateUrl: './company-list.component.html',
    styles: []
})
export class CompanyListComponent {

    @Input() companies: CompanyModel[];
    @Input() crudOptions: boolean;
    @Output() editCompany: EventEmitter<CompanyModel> = new EventEmitter();
    @Output() deleteCompany: EventEmitter<number> = new EventEmitter();
    @Output() syncCompany: EventEmitter<string> = new EventEmitter();

    constructor() {
    }

    syncSalesforce(salesforceAccountId: string) {
        this.syncCompany.emit(salesforceAccountId);
    }

    edit(company: CompanyModel) {
        this.editCompany.emit(company);
    }

    delete(company: CompanyModel) {
        this.deleteCompany.emit(company.id);
    }

    public hasDependencies(company: CompanyModel): boolean {
        return (company.agreements && company.agreements.length > 0)
            || (company.subscriptions && company.subscriptions.length > 0);
    }

}
