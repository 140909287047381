import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Moment } from 'moment';
import { DateInterval } from '../../../shared/dto/date-interval.model';
import { PeriodParameterModel } from '../../../shared/dto/period-parameter.model';
import { IntervalUtil } from './interval-util';
import * as moment from 'moment';;

@Component({
    selector: 'period-parameter-selector',
    templateUrl: 'period-parameter-selector.component.html'
})
export class PeriodParameterSelector {

    @Input() upperBound: number;

    @Input() set periodParameterModel(value: PeriodParameterModel[]) {
        const newIntervals = PeriodParameterSelector.convertPeriodParametersToIntervals(value);

        if(IntervalUtil.sameIntervals(newIntervals, this._intervals)) {
            return;
        }

        this._intervals = newIntervals;
    }
    @Output() periodParameterModelChange: EventEmitter<PeriodParameterModel[]>  = new EventEmitter();

    private _intervals: DateInterval[];

    get intervals(): DateInterval[] {
        return this._intervals;
    }

    set intervals(value: DateInterval[]) {
        if(IntervalUtil.sameIntervals(value, this._intervals)) {
            return;
        }

        this._intervals = value;

        const newPeriodParameters = this.convertIntervalsToPeriodParameter(value);
        this.periodParameterModelChange.emit(newPeriodParameters);
    }

    constructor() {
        this._intervals = [PeriodParameterSelector.emptySelector()];
    }

    private convertIntervalsToPeriodParameter(intervals: DateInterval[]): PeriodParameterModel[] {
        const newPeriodParameters = intervals.map((interval, index) => {
            const periodInDays = Boolean(interval.to) && Boolean(interval.from) ? interval.to.diff(interval.from, 'days') : 0;
            const postfix = index+1;
            const isMultiplePeriodAllowed = this.upperBound !== 1;
            const showNextPeriod = index + 1 < intervals.length;
            const periodParameter = new PeriodParameterModel(periodInDays, postfix, isMultiplePeriodAllowed, showNextPeriod);

            periodParameter.dateFrom = PeriodParameterSelector.convertMomentToNgbDate(interval.from);
            periodParameter.dateTo = PeriodParameterSelector.convertMomentToNgbDate(interval.to);

            return periodParameter;
        });
        for (let index = newPeriodParameters.length; index < 4; index++) {
            const isMultiplePeriodAllowed = this.upperBound !== 1;
            const postfix = index+1;
            const periodParameter = new PeriodParameterModel(0, postfix, isMultiplePeriodAllowed, false);
            newPeriodParameters.push(periodParameter);
        }
        return newPeriodParameters;
    }

    private static convertMomentToNgbDate(date: Moment): {year: number, month: number, day: number} {
        if (!Boolean(date)) {
            return null;
        }

        return {
            year: date.year(),
            month: date.month()+1,
            day: date.date(),
        }
    }

    private static convertPeriodParametersToIntervals(periodParameters: PeriodParameterModel[]): DateInterval[] {
        if (!Boolean(periodParameters) || periodParameters.length === 0) {
            return [];
        }

        const dateIntervals = [];

        let index = 0;
        do {
            const periodParameter = periodParameters[index];
            const from = PeriodParameterSelector.convertNgbDateToMoment(periodParameter.dateFrom);
            const to = PeriodParameterSelector.convertNgbDateToMoment(periodParameter.dateTo);
            dateIntervals.push({from, to});
        } while(periodParameters[index++].showNextPeriod);

        return dateIntervals;
    }

    private static convertNgbDateToMoment(date: {year: number, month: number, day: number}): Moment {
        if (!Boolean(date)) {
            return null;
        }

        return moment([
            date.year,
            date.month - 1,
            date.day
        ]);
    }

    private static emptySelector(): DateInterval {
        return {
            from: null,
            to: null
        };
    }

}
