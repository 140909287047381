import {AfterViewChecked, AfterViewInit, Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import {Moment} from 'moment';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {ToleranceChartService} from './tolerance-chart.service';
import {ToleranceCombinedChartService} from './tolerance-combined-chart.service';
import {ToleranceData} from './tolerance-data.model';
import {TolerancePeriod} from './tolerance-period.model';
import {ToleranceThresholdService} from './tolerance-threshold.service';
import {ToleranceType} from './tolerance-type.enum';
import {Observable} from 'rxjs';
import * as moment from 'moment';
import {StateStorageService} from '../shared/auth';
import {DialogService} from '../shared/dialog/dialog.service';
import {LoadingOverlayService} from '../shared/services/loading-overlay.service';
import {HistoricalDataUtil} from '../shared/historical-data-handler/historical-data.util';
import {Resolution} from '../shared/time-series/model/resolution.enum';
@Component({
    selector: 'cez-tolerance-threshold',
    templateUrl: './tolerance-threshold.component.html',
    styleUrls: ['./tolerance-threshold.component.scss'],
    providers: [
        ToleranceChartService,
        ToleranceCombinedChartService
    ]
})
export class ToleranceThresholdComponent implements OnInit, AfterViewInit, AfterViewChecked {

    readonly localPermissions: string[] = ['TOLERANCE_THRESHOLD_READ', 'TOLERANCE_THRESHOLD_ADMIN'];
    readonly warnDifference: number = 5;
    readonly maxMonthNumber: number = 12;

    expectedPlanProps: string[] = ['sumYearly', 'sumMonthly', 'expectedPlan'];
    selectedExpectedPlan: string = this.expectedPlanProps[0];

    form = this.formBuilder.group({
        partnerId: this.formBuilder.control(null),
        tolerancePeriodId: this.formBuilder.control(null),
        lowerBound: this.formBuilder.control(85, Validators.compose([Validators.required, Validators.min(0)])),
        upperBound: this.formBuilder.control(130, Validators.compose([Validators.required, Validators.min(0)])),
        type: this.formBuilder.control(ToleranceType.CONSUMPTION_PLAN_RATE, Validators.required),

        sumYearly: this.formBuilder.control(null, Validators.required),
        sumMonthly: this.formBuilder.control(null, Validators.required),

        start: this.formBuilder.control(null, Validators.required),
        end: this.formBuilder.control(null, Validators.required)
    });

    tolerancePeriods: TolerancePeriod[] = [];
    data: ToleranceData;
    chart: Chart;
    combined_chart: Chart;
    toleranceTypes = ToleranceType;

    formExpectedPlanMonths: UntypedFormGroup;

    numberOfMonthsInTolerancePeriod: number = 0;
    currentPeriod: TolerancePeriod;
    monthInTolerancePeriod: number[] = [];

    readonly permissionMap = new Map<TolerancePeriod, boolean>();

    constructor(private service: ToleranceThresholdService,
                private stateStorageService: StateStorageService,
                private formBuilder: UntypedFormBuilder,
                private dialogService: DialogService,
                private chartService: ToleranceChartService,
                private combinedChartService: ToleranceCombinedChartService,
                private loadingOverlay: LoadingOverlayService,
                translate: TranslateService) {

        translate.getTranslation(translate.currentLang).subscribe(() => {
            this.chart = this.chartService.create();
            this.combined_chart = this.combinedChartService.create();
        });
    }


    ngOnInit(): void {
        this.formControl.partnerIdControl.setValue(this.stateStorageService.getSelectedCompanyId());
        this.formControl.typeControl.disable({emitEvent: false});
        this.formControl.sumYearlyControl.disable({emitEvent: false});
        this.formControl.sumMonthlyControl.disable({emitEvent: false});
        this.formControl.startControl.disable({emitEvent: false});
        this.formControl.endControl.disable({emitEvent: false});
        if (this.stateStorageService.getSelectedCompanyId()) {
            this.service.listTolerancePeriods(this.stateStorageService.getSelectedCompanyId())
                .subscribe(tps => {
                    const now = moment.tz('Europe/Budapest');

                    this.tolerancePeriods = tps;
                    this.tolerancePeriods.forEach(v => {
                        this.permissionMap.set(v, this.permission(v));
                    });

                    const validPeriods = this.tolerancePeriods.filter(v => this.permissionMap.get(v));

                    if (validPeriods.length > 0) {
                        const currentPeriod = validPeriods.reduce((result, currentValue) => {
                            return now.isSameOrAfter(currentValue.start) ? currentValue : result;
                        });
                        this.formControl.tolerancePeriodIdControl.setValue(currentPeriod.id);
                        this.getNumberOfMonthsOfPeriod(currentPeriod);
                    }
                });
        }
    }

    private getNumberOfMonthsOfPeriod(currentPeriod: TolerancePeriod): void {
        this.currentPeriod = currentPeriod;

        this.numberOfMonthsInTolerancePeriod = moment(this.currentPeriod.end).add(1, 'months').diff(moment(this.currentPeriod.start), 'month');

        this.formExpectedPlanMonths = this.formBuilder.group({});
        this.monthInTolerancePeriod = [];

        this.iterateOnMonths((i: number) => {
            const control: AbstractControl = this.formBuilder.control(null, Validators.required);
            control.disable({emitEvent: false});

            this.setToleranceExpectedPlanControlState(control, i, new Date().getMonth(), new Date().getFullYear(), currentPeriod);

            this.valueChanges(control.valueChanges, 1000);
            this.formExpectedPlanMonths.addControl('expectedPlanMonths' + i, control);
            this.monthInTolerancePeriod.push(i);
        });
    }

    private iterateOnMonths(func: (i: number) => void): void {
        const monthOfYear: number = this.getMonthOfYear();
        for (let i = monthOfYear; i < Math.min(monthOfYear + this.numberOfMonthsInTolerancePeriod, this.maxMonthNumber); i++) {
            func.call(this, i);
        }
    }

    private getNumberOfMonths(currentPeriodId: number): void {
        const currentPeriod: TolerancePeriod = this.tolerancePeriods.find(t => t.id === currentPeriodId);
        this.getNumberOfMonthsOfPeriod(currentPeriod);
    }

    ngAfterViewInit(): void {
        this.formControl.upperControl.valueChanges.subscribe(v => this.refreshWarnPlotLines(v, 0));
        this.formControl.lowerControl.valueChanges.subscribe(v => this.refreshWarnPlotLines(v, 1));

        this.formControl.tolerancePeriodIdControl.valueChanges.subscribe((value) => {
            this.formControl.typeControl.enable({emitEvent: false});
            this.getNumberOfMonths(value);
            this.expectedPlanSelectionChange(this.selectedExpectedPlan);
        });

        this.formControl.typeControl.valueChanges.subscribe(v => {
            if (!this.formData.sumYearly && !this.formData.sumMonthly) {
                this.formControl.sumYearlyControl.disable({emitEvent: false});
                this.formControl.sumMonthlyControl.disable({emitEvent: false});
            }
            if (!this.formData.start && !this.formData.end) {
                this.formControl.startControl.disable({emitEvent: false});
                this.formControl.endControl.disable({emitEvent: false});
            }
            switch (v) {
                case ToleranceType.EXPECTED_PLAN:
                    this.expectedPlanSelectionChange(this.selectedExpectedPlan);
                    break;
                case ToleranceType.PERIOD_AVERAGE:
                    this.formControl.startControl.enable({emitEvent: false});
                    this.formControl.endControl.enable({emitEvent: false});
                    break;
            }
        });

        this.valueChanges(this.formControl.partnerIdControl.valueChanges);
        this.valueChanges(this.formControl.tolerancePeriodIdControl.valueChanges);
        this.valueChanges(this.formControl.typeControl.valueChanges);
        this.valueChanges(this.formControl.sumMonthlyControl.valueChanges, 1000);
        this.valueChanges(this.formControl.sumYearlyControl.valueChanges, 1000);
        this.valueChanges(this.formControl.startControl.valueChanges);
        this.valueChanges(this.formControl.endControl.valueChanges);


        this.formControl.startControl.valueChanges
            .pipe(filter(s => s != null && (!this.formData.end || s.isAfter(this.formData.end))))
            .subscribe(s => this.formControl.endControl.setValue(s.clone().endOf('month'), {emitEvent: false}));
        this.formControl.endControl.valueChanges
            .pipe(filter(e => e != null && (!this.formData.start || e.isBefore(this.formData.start))))
            .subscribe(e => this.formControl.startControl.setValue(e.clone().startOf('month'), {emitEvent: false}));
    }


    save(): void {
    }

    updateData(formData, formDataExpectedPlan): void {
        formData.expectedPlanMonths = Array(this.maxMonthNumber);

        this.iterateOnMonths((i: number) => {
            formData.expectedPlanMonths[i] = formDataExpectedPlan['expectedPlanMonths' + i];
        });

        this.loadingOverlay.turnOn();
        this.service.getAllToleranceData(formData)
            .subscribe(
                list => {
                      this.combinedChartService.update(list);
                      this.data = list.find(t => t.type === this.formData.type);

                      this.setExpectedPlanMonthsValues();

                      this.chartService.update(this.data);
                      if (list && list.length > 0) {
                        if (list[0].upperBound - list[0].lowerBound > 10) {
                          this.formControl.upperControl.setValue(list[0].upperBound - this.warnDifference);
                          this.formControl.lowerControl.setValue(list[0].lowerBound + this.warnDifference);
                        } else {
                          this.formControl.upperControl.setValue(list[0].upperBound);
                          this.formControl.lowerControl.setValue(list[0].lowerBound);
                        }
                      }
                      this.refreshWarnPlotLines(this.formData.upper, 0);
                      this.refreshWarnPlotLines(this.formData.lower, 1);
                      this.loadingOverlay.turnOff();
                },
                err => {
                    this.combinedChartService.clear();
                    this.chartService.clear();
                    console.log(err);
                    this.loadingOverlay.turnOff();
                }
            );
    }

    private setExpectedPlanMonthsValues(): void {
        if (this.data.type === ToleranceType.EXPECTED_PLAN && this.selectedExpectedPlan === 'expectedPlan') {
            const monthOfYear: number = this.getMonthOfYear();

            this.iterateOnMonths((i: number) => {
                let startOfActualMonth = moment(new Date()).startOf('month');
                let month = moment(new Date()).startOf('year').add(monthOfYear);
                if (month.isBefore(startOfActualMonth)) {
                    const monthDiff: number = Math.max(moment(new Date()).startOf('year').diff(moment(this.currentPeriod.start), 'months'), 0);
                    let value = this.data.plan[monthDiff + i - monthOfYear];
                    if (!isNaN(value)) {
                        let control = this.formExpectedPlanMonths.get('expectedPlanMonths' + i);
                        control.setValue(value);
                    }
                }
            });
        }
    }

    expectedPlanSelectionChange(e: string): void {
        if (this.expectedPlanProps) {
            this.expectedPlanProps.forEach(p => {
                    if (p === e) {
                        if (p !== 'expectedPlan' && this.form.controls[p]) {
                            this.form.controls[p].enable({emitEvent: false});
                        }
                        this.updateData(this.form.value, this.formExpectedPlanMonths.value);
                    } else if (this.form.controls[p]) {
                        this.form.controls[p].disable({emitEvent: false});
                    }
                }
            );
        }
    }

    private getMonthOfYear(): number {
        if (moment(new Date()).year() <= moment(this.currentPeriod.start).year()) {
            return moment(this.currentPeriod.start).month();
        }
        return 0;
    }

    get formControl(): {
        partnerIdControl: AbstractControl,
        tolerancePeriodIdControl: AbstractControl,
        lowerControl: AbstractControl,
        upperControl: AbstractControl,
        typeControl: AbstractControl,
        sumYearlyControl: AbstractControl,
        sumMonthlyControl: AbstractControl,
        startControl: AbstractControl,
        endControl: AbstractControl
    } {
        return {
            partnerIdControl: this.form.controls.partnerId,
            tolerancePeriodIdControl: this.form.controls.tolerancePeriodId,
            lowerControl: this.form.controls.lowerBound,
            upperControl: this.form.controls.upperBound,
            typeControl: this.form.controls.type,
            sumYearlyControl: this.form.controls.sumYearly,
            sumMonthlyControl: this.form.controls.sumMonthly,
            startControl: this.form.controls.start,
            endControl: this.form.controls.end
        };
    }


    private get formData(): {
        partnerId: number,
        tolerancePeriodId: number,
        lower: number,
        upper: number,
        type: ToleranceType,
        sumYearly: number,
        sumMonthly: number,
        start: any,
        end: Moment,
    } {
        return {
            partnerId: this.formControl.partnerIdControl.value,
            tolerancePeriodId: this.formControl.tolerancePeriodIdControl.value,
            lower: this.formControl.lowerControl.value,
            upper: this.formControl.upperControl.value,
            type: this.formControl.typeControl.value,
            sumYearly: this.formControl.sumYearlyControl.value,
            sumMonthly: this.formControl.sumMonthlyControl.value,
            start: this.formControl.startControl.value,
            end: this.formControl.endControl.value
        };
    }

    private valueChanges(value: Observable<any>, dueTime: number = 0) {
        value.pipe(
            distinctUntilChanged(),
            filter(() =>
                Boolean(this.formData.partnerId)
                && Boolean(this.formData.tolerancePeriodId)
                && Boolean(this.formData.type)
            ),
            debounceTime(dueTime),
            tap(v => this.updateData(this.form.value, this.formExpectedPlanMonths.value))
        )
            .subscribe();
    }

    permission(tp: TolerancePeriod): boolean {
        const permissions = this.stateStorageService.getEffectivePermissions();

        if (!this.localPermissions.find(value => permissions.indexOf(value) > -1)) {
            return false;
        }

        const min: Moment = HistoricalDataUtil.getHistoricalMin(permissions, Resolution.MONTHLY);

        return !min || min.isBefore(tp.end);
    }

    ngAfterViewChecked(): void {
    }

    private refreshWarnPlotLines(value: number, plotLine: number) {
        this.chartService.refreshWarnPlotLines(value, plotLine);
        this.combinedChartService.refreshWarnPlotLines(value, plotLine);
    }

    private setToleranceExpectedPlanControlState(control: AbstractControl | UntypedFormControl,
                                                 monthsInExpectedPlan: number,
                                                 currentMonth: number,
                                                 currentYear: number,
                                                 currentPeriod: TolerancePeriod): void {
        if (moment(currentPeriod.end).year() < currentYear) {
            control.disable();
        } else if (moment(currentPeriod.end).year() == currentYear) {
            for (let j = 0; j < monthsInExpectedPlan - currentMonth; j++) {
                control.enable();
            }
        } else if (moment(currentPeriod.end).year() > currentYear) {
            control.enable();
        }
    }
}
