import { Language } from '../agreement-details/agreement-enums.model';

export interface CustomFile {
    id: number,
    fileName: string,
    type: CustomFileType,
    fileUrl: string,
    size: number,
    uploadedAt: string,
    uploadedBy: string,
    note: string,
    language: Language
}

export type CustomFileType = 'TERMS_OF_USE'|'PRIVACY_POLICY'|'OTHER'|'PUBLIC';

export const CustomFileType = {
    TERMS_OF_USE: 'TERMS_OF_USE' as CustomFileType,
    PRIVACY_POLICY: 'PRIVACY_POLICY' as CustomFileType,
    OTHER: 'OTHER' as CustomFileType,
    PUBLIC: 'PUBLIC' as CustomFileType
};
