import {Moment} from 'moment';
import * as moment from 'moment';

export abstract class CountdownHighlighterBase {

    private idRemainTimeMap: { [key: number]: Moment } = {};

    protected constructor() {}

    public startCountDown(offerClaimId: number, timeRemain: number): void {
        if (!offerClaimId || !timeRemain) {
            return;
        }
        this.idRemainTimeMap[offerClaimId] = moment().add(timeRemain, 'second');
    }

    public getRemainTimeClassList(offerClaimId: number, timeRemain: number): string {
        if (this.highLightRemainTime(offerClaimId, timeRemain)) {
            return this.invisible(offerClaimId) ? 'expire-highlight bg-cezorange-light' : 'expire-highlight';
        }

        return '';
    }

    public highLightRemainTime(offerClaimId: number, timeRemain: number): boolean {
        if (!this.idRemainTimeMap || this.remainTime(offerClaimId) < 0 || !timeRemain) {
            return false;
        }
        return this.remainTime(offerClaimId) <= 60;
    }

    public invisible(offerClaimId: number): boolean {
        if (this.remainTime(offerClaimId) >= 11) {
            return false;
        } else {
            return this.remainTime(offerClaimId) % 2 === 0;
        }
    }

    private remainTime(offerClaimId: number): number {
        if (!offerClaimId || !this.idRemainTimeMap[offerClaimId]) {
            return 0;
        }
        const remainTime: number = +moment
            .duration(this.idRemainTimeMap[offerClaimId].diff(moment()))
            .asSeconds().toFixed(0);
        if (remainTime <= 0) {
            delete this.idRemainTimeMap[offerClaimId];
        }

        return remainTime;
    }

}
