<div>
    <div class="row">
        <div class="col-md-8 offset-md-2">
            <h1 jhiTranslate="register.title">Registration</h1>

            <div class="alert alert-success" *ngIf="success" jhiTranslate="register.messages.success">
                <strong>Registration saved!</strong> Please check your email for confirmation.
            </div>

            <div class="alert alert-danger" *ngIf="error" jhiTranslate="register.messages.error.fail">
                <strong>Registration failed!</strong> Please try again later.
            </div>

            <div class="alert alert-danger" *ngIf="errorUserExists" jhiTranslate="register.messages.error.userexists">
                <strong>Login name already registered!</strong> Please choose another one.
            </div>

            <div class="alert alert-danger" *ngIf="errorEmailExists" jhiTranslate="register.messages.error.emailexists">
                <strong>E-mail is already in use!</strong> Please choose another one.
            </div>

            <div class="alert alert-danger" *ngIf="doNotMatch" jhiTranslate="global.messages.error.dontmatch">
                The password and its confirmation do not match!
            </div>
        </div>
        <div class="col-md-8 offset-md-2">
            <form name="form" role="form" (ngSubmit)="register()" #registerForm="ngForm">
                <div class="form-group">
                    <label class="form-control-label" for="login" jhiTranslate="global.form.username">Username</label>
                    <input type="text" class="form-control" [(ngModel)]="registerAccount.login" id="login" name="login" #login="ngModel" placeholder="{{'global.form.username.placeholder' | translate}}"
                           required minlength="1" maxlength="50" pattern="[_'.@A-Za-z0-9-]*">]
                    <div *ngIf="login.dirty && login.invalid">
                        <small class="form-text text-danger" *ngIf="login.errors.required" jhiTranslate="register.messages.validate.login.required">
                            Your username is required.
                        </small>
                        <small class="form-text text-danger" *ngIf="login.errors.minlength"
                                jhiTranslate="register.messages.validate.login.minlength">
                            Your username is required to be at least 1 character.
                        </small>
                        <small class="form-text text-danger" *ngIf="login.errors.maxlength"
                                jhiTranslate="register.messages.validate.login.maxlength">
                            Your username cannot be longer than 50 characters.
                        </small>
                        <small class="form-text text-danger" *ngIf="login.errors.pattern"
                               jhiTranslate="register.messages.validate.login.pattern">
                            Your username can only contain lower-case letters and digits.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="email" jhiTranslate="global.form.email">E-mail</label>
                    <input type="email" email class="form-control" id="email" name="email" #email="ngModel" placeholder="{{'global.form.email.placeholder' | translate}}"
                            [(ngModel)]="registerAccount.email" minlength=5 maxlength=100 required>
                    <div *ngIf="email.dirty && email.invalid">
                        <small class="form-text text-danger" *ngIf="email.errors.required"
                                jhiTranslate="global.messages.validate.email.required">
                            Your e-mail is required.
                        </small>
                        <small class="form-text text-danger" *ngIf="email.errors.invalid"
                               jhiTranslate="global.messages.validate.email.invalid">
                            Your e-mail is invalid.
                        </small>
                        <small class="form-text text-danger" *ngIf="email.errors.minlength"
                               jhiTranslate="global.messages.validate.email.minlength">
                            Your e-mail is required to be at least 5 characters.
                        </small>
                        <small class="form-text text-danger" *ngIf="email.errors.maxlength"
                               jhiTranslate="global.messages.validate.email.maxlength">
                            Your e-mail cannot be longer than 100 characters.
                        </small>
                    </div>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="password" jhiTranslate="global.form.newpassword">New password</label>
                    <input type="password" class="form-control" id="password" name="password" #password="ngModel" placeholder="{{'global.form.newpassword.placeholder' | translate}}"
                            [(ngModel)]="registerAccount.password" minlength=4 maxlength=50 required>
                    <div *ngIf="password.dirty && password.invalid">
                        <small class="form-text text-danger" *ngIf="password.errors.required"
                                jhiTranslate="global.messages.validate.newpassword.required">
                            Your password is required.
                        </small>
                        <small class="form-text text-danger" *ngIf="password.errors.minlength"
                                jhiTranslate="global.messages.validate.newpassword.minlength">
                            Your password is required to be at least 4 characters.
                        </small>
                        <small class="form-text text-danger" *ngIf="password.errors.maxlength"
                                jhiTranslate="global.messages.validate.newpassword.maxlength">
                            Your password cannot be longer than 50 characters.
                        </small>
                    </div>
                    <jhi-password-strength-bar [passwordToCheck]="registerAccount.password"></jhi-password-strength-bar>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="confirmPassword" jhiTranslate="global.form.confirmpassword">New password confirmation</label>
                    <input type="password" class="form-control" id="confirmPassword" name="confirmPassword" #confirmPasswordInput="ngModel" placeholder="{{'global.form.confirmpassword.placeholder' | translate}}"
                            [(ngModel)]="confirmPassword" minlength=4 maxlength=50 required>
                    <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                        <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.required"
                               jhiTranslate="global.messages.validate.confirmpassword.required">
                            Your confirmation password is required.
                        </small>
                        <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.minlength"
                              jhiTranslate="global.messages.validate.confirmpassword.minlength">
                            Your confirmation password is required to be at least 4 characters.
                        </small>
                        <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.maxlength"
                               jhiTranslate="global.messages.validate.confirmpassword.maxlength">
                            Your confirmation password cannot be longer than 50 characters.
                        </small>
                    </div>
                </div>

                <button type="submit" [disabled]="registerForm.form.invalid" class="btn btn-primary" jhiTranslate="register.form.button">Register</button>
            </form>
            <p></p>
            <div class="alert alert-warning">
                <span jhiTranslate="global.messages.info.authenticated.prefix">If you want to </span>
                <a class="alert-link" (click)="openLogin()" jhiTranslate="global.messages.info.authenticated.link">sign in</a><span jhiTranslate="global.messages.info.authenticated.suffix">, you can try the default accounts:<br/>- Administrator (login="admin" and password="admin") <br/>- User (login="user" and password="user").</span>
            </div>
        </div>
    </div>
</div>
