<div [ngClass]="widget ? 'container-fluid no-padding' : 'container-fluid'">
    <div class="row">
        <div class="col-lg-4" *ngIf="!widget">
            <jhi-filter-basic (reportRunned)="getReportParametersForRun($event)">
            </jhi-filter-basic>
        </div>
        <div [ngClass]="!widget ? 'col-lg-8' : 'col-md-12'">
            <jhi-performance-diagram-area [widget]="widget" [hidden]="!(actualDiagramId === 1)"></jhi-performance-diagram-area>
            <jhi-diagram-column [widget]="widget" [hidden]="!(actualDiagramId === 2)"></jhi-diagram-column>
            <jhi-performance-dispersion-diagram-column [widget]="widget" [hidden]="!(actualDiagramId === 3)"></jhi-performance-dispersion-diagram-column>
            <jhi-performance-diagram-box-plot [widget]="widget" [hidden]="!(actualDiagramId === 4)"></jhi-performance-diagram-box-plot>
            <jhi-min-max-avarage [widget]="widget" [hidden]="!(actualDiagramId === 5)"></jhi-min-max-avarage>
            <jhi-performance-heatmap-diagram [widget]="widget" [hidden]="!(actualDiagramId === 6)"></jhi-performance-heatmap-diagram>
        </div>
    </div>
</div>
