<section class="small-form my-3">
    <div class="form-group">
        <label jhiTranslate="userManagement.company">Company</label>
        <ng-select
            required="false"
            [(ngModel)]="selectedCompany"
            [items]="companies"
            [disabled]="disabled"
            [loading]="loading"
            [loadingText]="loadingText"
            [notFoundText]="notFoundText"
            name="company"
            class="form-control"
            [typeahead]="searchCompanyEvent"
            [virtualScroll]="false"
            (change)="selectCompany()"
            (scrollToEnd)="fetchMore(select.filterValue)"
            #select>
            <ng-template ng-option-tmp let-item="item" let-index="index">
                <span class="m-2">{{item.accountName}}</span>
            </ng-template>
            <ng-template ng-label-tmp let-item="item" let-index="index">
                <span class="m-2">{{item.accountName}}</span>
            </ng-template>
        </ng-select>




    </div>
</section>
