
export class PriceTrackerModel {
    partner: string;
    createdAt: Date;
    lastModifiedAt: Date;
    year: number;
    product: string;
    minPrice: number;
    maxPrice: number;
}
