export class Account {
    roleGroup: number;
    twoFactorAuthRequired: boolean;
    twoFactorKeyActivated: boolean;
    twoFactorKeyCreationTime: Date;
    permissions: string[];
    effectivePermissions: string[];
    groups: number[];
    constructor(
        public activated: boolean,
        public authorities: string[],
        public email: string,
        public firstName: string,
        public langKey: string,
        public lastName: string,
        public login: string,
        public imageUrl: string
    ) { }
}
