<div class="pull-center">
    <div class="col-md-8 offset-md-2">
        <h1 jhiTranslate="reset.finish.title" class="text-center">Reset password</h1>

        <div class="alert alert-danger" jhiTranslate="reset.finish.messages.keymissing" *ngIf="keyMissing">
            <strong>The password reset key is missing.</strong>
        </div>

        <div class="alert alert-warning" *ngIf="!success && !keyMissing">
            <span jhiTranslate="reset.finish.messages.info">Choose a new password</span>
        </div>

        <div class="alert alert-success" *ngIf="success">
            <span jhiTranslate="reset.finish.messages.success"></span>
            <a class="alert-link" (click)="login()" jhiTranslate="global.messages.info.authenticated.link">sign in</a>.
        </div>

        <div *ngIf="!keyMissing">
            <form *ngIf="!success" name="form" role="form" (ngSubmit)="finishReset()" #passwordForm="ngForm" ngModel passwordMatch>
                <div class="form-group">
                    <input type="password" class="form-control" id="password" name="password" #passwordInput="ngModel" placeholder="{{'reset.finish.pwPlaceholder' | translate}}"
                           [(ngModel)]="resetAccount.password" minlength=4 maxlength=100 required>
                    <div *ngIf="passwordInput.dirty && passwordInput.invalid">
                        <small class="form-text text-danger" *ngIf="passwordInput.errors.required" jhiTranslate="global.messages.validate.newpassword.required">
                            Your password is required.
                        </small>
                        <small class="form-text text-danger" *ngIf="passwordInput.errors.minlength" jhiTranslate="global.messages.validate.newpassword.minlength">
                            Your password is required to be at least 4 characters.
                        </small>
                        <small class="form-text text-danger" *ngIf="passwordInput.errors.maxlength" jhiTranslate="global.messages.validate.newpassword.maxlength">
                            Your password cannot be longer than 50 characters.
                        </small>
                    </div>
                    <jhi-password-strength-bar [passwordToCheck]="resetAccount.password"></jhi-password-strength-bar>
                </div>

                <div class="form-group">
                    <input type="password" class="form-control" id="confirmPassword" name="confirmPassword" #confirmPasswordInput="ngModel" placeholder="{{'reset.finish.pwConfirmPlaceholder' | translate}}"
                           [(ngModel)]="confirmPassword" minlength=4 maxlength=100 required
                           [invalidWhen]="confirmationInvalid">
                    <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                        <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.required" jhiTranslate="global.messages.validate.confirmpassword.required">
                            Your password confirmation is required.
                        </small>
                        <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.minlength" jhiTranslate="global.messages.validate.confirmpassword.minlength">
                            Your password confirmation is required to be at least 4 characters.
                        </small>
                        <small class="form-text text-danger" *ngIf="confirmPasswordInput.errors.maxlength" jhiTranslate="global.messages.validate.confirmpassword.maxlength">
                            Your password confirmation cannot be longer than 50 characters.
                        </small>
                    </div>
                    <div *ngIf="passwordForm.form.invalid && confirmPasswordInput.dirty">
                        <small class="form-text text-danger"
                               *ngIf="confirmPasswordInput.invalid && !confirmPasswordInput.errors.required && !confirmPasswordInput.errors.minlength && !confirmPasswordInput.errors.maxlength && passwordForm.hasError('passwordMismatch')"
                               jhiTranslate="global.messages.error.dontmatch">
                            The password and its confirmation do not match!
                        </small>
                    </div>
                </div>
                <button type="submit" [disabled]="passwordForm.form.invalid" class="btn btn-primary" jhiTranslate="reset.finish.form.button">Reset Password</button>
            </form>
        </div>

    </div>
</div>
