import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Chart} from 'angular-highcharts';
import {DiagramColumnComponent} from './diagram-column/diagram-column.component';
import {FilterModel} from './filter-basic/filter.model';
import {MinMaxAvarageComponent} from './min-max-avarage/min-max-avarage.component';
import {PerformanceDiagramAreaComponent} from './performance-diagram-area/performance-diagram-area.component';
import {
    PerformanceDiagramBoxPlotComponent
} from './performance-diagram-box-plot/performance-diagram-box-plot.component';
import {
    PerformanceDispersionDiagramColumnComponent
} from './performance-dispersion-diagram-column/performance-dispersion-diagram-column.component';
import {PerformanceHeatmapDiagramComponent} from './performance-heatmap-diagram/performance-heatmap-diagram.component';
import {FilterBasicComponent} from './filter-basic/filter-basic.component';
import {ConsumptionExportService} from './export/consumption-export.service';
import {NgBDatePickerConvertService} from '../shared/services/ngb.datepicker.convert.service';
import {StateStorageService} from "../shared/auth";
import * as moment from 'moment';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'jhi-consumption',
    templateUrl: './consumption-new.component.html',
    styleUrls: ['./consumption-new.component.scss']
})
export class ConsumptionNewComponent implements OnInit, OnDestroy {

    actualDiagramId: number;
    @Input() widget: boolean;
    @Input() tabContent: boolean;
    @ViewChild(FilterBasicComponent, {static: false}) filterBasicComponent: FilterBasicComponent;
    @ViewChild(PerformanceDiagramAreaComponent, {static: true}) performanceAreaComponent: PerformanceDiagramAreaComponent;
    @ViewChild(DiagramColumnComponent, {static: true}) performanceRunComponent: DiagramColumnComponent;
    @ViewChild(PerformanceDiagramBoxPlotComponent, {static: true}) boxPlotComponent: PerformanceDiagramBoxPlotComponent;
    @ViewChild(PerformanceDispersionDiagramColumnComponent, {static: true}) dispersionComponent: PerformanceDispersionDiagramColumnComponent;
    @ViewChild(PerformanceHeatmapDiagramComponent, {static: true}) heatmapComponent: PerformanceHeatmapDiagramComponent;
    @ViewChild(MinMaxAvarageComponent, {static: true}) minMaxAverage: MinMaxAvarageComponent;

    filterModel: FilterModel = new FilterModel();
    minSelected: boolean = false;
    maxSelected: boolean = false;
    avgSelected: boolean = false;
    woffDaySelected: boolean = false;

    constructor(public translateService: TranslateService,
                private dateConverter: NgBDatePickerConvertService,
                private consumptionExportService: ConsumptionExportService,
                private  stateStorageService: StateStorageService) {
    }

    ngOnInit() {
        if (!this.widget) {
            this.actualDiagramId = 1;
        }
        Highcharts.setOptions({
            lang: {
                resetZoom: this.translateService.instant('filter.basic.resetZoom'),
                resetZoomTitle: this.translateService.instant('filter.basic.resetZoomTitle'),
                printChart: this.translateService.instant('filter.basic.printChart'),
                downloadSVG: this.translateService.instant('filter.basic.downloadSVG'),
                downloadPNG: this.translateService.instant('filter.basic.downloadPNG'),
                downloadPDF: this.translateService.instant('filter.basic.downloadPDF'),
                downloadJPEG: this.translateService.instant('filter.basic.downloadJPEG')
            }
        });
    }

    getReportParametersForRun(filterModel: FilterModel) {
        this.removeXlsBtnFromHighChart();
        this.addXlsBtnToHighChart(filterModel);

        if (filterModel.selectedDiagram && filterModel.selectedDiagram.id) {
            this.actualDiagramId = filterModel.selectedDiagram ? filterModel.selectedDiagram.id : 1;
        }

        const filterModelCopy: FilterModel = JSON.parse(JSON.stringify(filterModel));

        switch (this.actualDiagramId) {
            case 1:
                this.performanceAreaComponent.getReportParametersForRun(filterModelCopy);
                break;
            case 2:
                this.performanceRunComponent.getReportParametersForRun(filterModelCopy);
                break;
            case 3:
                this.dispersionComponent.getReportParametersForRun(filterModelCopy);
                break;
            case 4:
                this.boxPlotComponent.getReportParametersForRun(filterModelCopy);
                break;
            case 5:
                this.minMaxAverage.getReportParametersForRun(filterModelCopy);
                break;
            case 6:
                this.heatmapComponent.getReportParametersForRun(filterModelCopy);
                break;

        }
    }

    public dynamicFilterChanged($event: FilterModel): void {
        if (this.filterBasicComponent && this.filterBasicComponent.filterValid()) {
            this.filterBasicComponent.runReport();
        }
    }

    private addXlsBtnToHighChart(filterModel: FilterModel): void {
        const sufficientPermissionForXls: string[] = ['CONSUMPTION_EXCEL_EXPORT'];
        const permissions = this.stateStorageService.getEffectivePermissions();
        if (sufficientPermissionForXls.find(value => permissions.indexOf(value) > -1)) {
            if ((this.filterBasicComponent.exportValid() && this.filterBasicComponent.formGroup.form.valid)) {
                let item: any = {
                    className: 'highcharts-custom-btn',
                    text: this.translateService.instant('filter.basic.downloadXLSX'),
                    onclick: () => {
                        this.consumptionExportService.download(
                            moment(this.dateConverter.convertToDate(filterModel.periodParameters.length > 0 ? filterModel.periodParameters[0].dateFrom : undefined)),
                            moment(this.dateConverter.convertToDate(filterModel.periodParameters.length > 0 ? filterModel.periodParameters[0].dateTo : undefined)).add(1, 'days'),
                            filterModel.resolution,
                            filterModel.selectedPods,
                            filterModel.selectedPodGroups
                        );
                    },
                    enabled: false
                };
                Highcharts.getOptions().exporting.buttons.contextButton.menuItems.push(item);
            }
        }
    }

    private removeXlsBtnFromHighChart(): void {
        const xlsBtn = this.getXlsBtnFromHighChart();
        if (xlsBtn) {
            Highcharts.getOptions().exporting.buttons.contextButton.menuItems.pop();
        }
    }

    private getXlsBtnFromHighChart(): any {
        const xlsBtn:any = Highcharts.getOptions().exporting.buttons.contextButton.menuItems[Highcharts.getOptions().exporting.buttons.contextButton.menuItems.length - 1];
        if (xlsBtn.className == 'highcharts-custom-btn') {
            return xlsBtn;
        }
        return null;
    }

    ngOnDestroy(): void {
        this.removeXlsBtnFromHighChart();
    }

    public filterModelChanged(filterModel: FilterModel) {
        this.filterModel = filterModel;
    }
}
