import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { forkJoin, Observable } from 'rxjs';
import { filter, flatMap } from 'rxjs/operators';
import { ProductDto } from '../../price/model/productDto';
import { StateStorageService } from '../../shared/auth';
import { DialogService } from '../../shared/dialog/dialog.service';
import { TranslateAdditionalDiagramGroup } from '../../shared/pipes/translate-additional-diagram-group';
import { TranslateAdditionalDiagramType } from '../../shared/pipes/translate-additional-diagram-type';
import { MarketPriceService } from '../../shared/services/market-price.service';
import { AdditionalDiagGroupEditorComponent } from './additional-diag-group-editor/additional-diag-group-editor.component';
import { AdditionalDiagTypeEditorComponent } from './additional-diag-type-editor/additional-diag-type-editor.component';
import { AdditionalDiagramGroupDTO } from './model/additional-diagram-group.model';
import { AdditionalDiagramResolution } from './model/additional-diagram-resolution';
import { AdditionalDiagramTypeDTO } from './model/additional-diagram-type.model';
import { DiagramType } from './model/diagram-type';
import { SeriesType } from './model/series-type';
import { AdditionalDiagramService } from './service/additional-diagram-service';
import { AdditionalDiagUtil } from './additional-diag-util';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

@Component({
    selector: 'jhi-additional-diag-types',
    templateUrl: './additional-diag-types.component.html',
    styleUrls: ['./additional-diag-types.component.scss']
})
export class AdditionalDiagTypesComponent implements OnInit {

    selectedDiagramType: AdditionalDiagramTypeDTO;
    additionalDiagramTypes: AdditionalDiagramTypeDTO[];

    products: ProductDto[];

    selectedDiagramGroup: AdditionalDiagramGroupDTO;
    additionalDiagramGroups: AdditionalDiagramGroupDTO[];

    diagramTypeOptions: SelectItem[];
    resolutionOptions: SelectItem[];
    seriesTypeOptions: SelectItem[];
    booleanOptions: SelectItem[];

    translateAdditionalDiagramType: TranslateAdditionalDiagramType;
    translateAdditionalDiagramGroup: TranslateAdditionalDiagramGroup;

    createSelectItem = (typeName, prefix: String): SelectItem => {
        return {
            label: this.translate.instant(prefix + '.' + typeName),
            value: typeName
        };
    };

    constructor(private stateStorageService: StateStorageService,
                private dialog: MatDialog,
                private additionalDiagramService: AdditionalDiagramService,
                private translate: TranslateService,
                private dialogService: DialogService,
                private marketPriceService: MarketPriceService,
                private additionalDiagUtil: AdditionalDiagUtil) {

        this.translateAdditionalDiagramType = new TranslateAdditionalDiagramType(translate);
        this.translateAdditionalDiagramGroup = new TranslateAdditionalDiagramGroup(translate);

        this.booleanOptions = [
            {
                label: this.translate.instant('additional-diag-type.type.groupOnlyVisibility.true'),
                value: 'true'
            },
            {
                label: this.translate.instant('additional-diag-type.type.groupOnlyVisibility.false'),
                value: 'false'
            }
        ];

        this.diagramTypeOptions = Object.keys(DiagramType)
            .map(diagramType => this.createSelectItem(diagramType, 'DiagramType'));

        this.resolutionOptions = Object.keys(AdditionalDiagramResolution)
            .map(resolution => this.createSelectItem(resolution, 'AdditionalDiagramResolution'));

        this.seriesTypeOptions = Object.keys(SeriesType)
            .map(seriesType => this.createSelectItem(seriesType, 'SeriesType'));
    }

    ngOnInit() {
        this.refresh();
    }

    editDiagramType(additionalDiagramTypeDTO?: AdditionalDiagramTypeDTO) {
        this.dialog
            .open(AdditionalDiagTypeEditorComponent, {
                disableClose: true,
                data: additionalDiagramTypeDTO || null
            })
            .afterClosed()
            .pipe(filter((saved) => saved))
            .subscribe(() => this.refresh());
    }

    deleteDiagramType(additionalDiagramTypeDTO: AdditionalDiagramTypeDTO) {
        this.dialogService.confirm(
            this.translate.instant('additional-diag-type.typeDeleteConfirm.title'),
            this.translate.instant('additional-diag-type.typeDeleteConfirm.message',
                {
                    diagramName: this.translateAdditionalDiagramType.transform(additionalDiagramTypeDTO)
                }
            )
        ).pipe(
            filter((confirmed) => confirmed),
            flatMap(() => this.removeDiagramType(additionalDiagramTypeDTO.diagramTypeId, additionalDiagramTypeDTO.seriesType))
        ).subscribe(() => {
                this.refresh();
            },
            (error) => {
                console.log(error);
            });
    }

    removeDiagramType(id: number, seriesType: SeriesType): Observable<any> {
        let referencedGroups: AdditionalDiagramGroupDTO[] = [];

        for (const additionalDiagramGroup of this.additionalDiagramGroups) {
            for (const additionalDiagramType of additionalDiagramGroup.additionalDiagramTypes) {
                if (additionalDiagramType.diagramTypeId === id) {
                    referencedGroups.push(additionalDiagramGroup);
                }
            }
        }

        if (referencedGroups.length > 0) {
            let referencedGroupNames = referencedGroups
                .map(diagramGroup => this.translateAdditionalDiagramGroup.transform(diagramGroup))
                .join('; ');
            return this.dialogService.alert(this.translate.instant('additional-diag-type.typeReferenced.title'),
                this.translate.instant('additional-diag-type.typeReferenced.message',
                    {
                        referencedGroupNames: referencedGroupNames
                    }
                ));
        }

        if (this.additionalDiagramTypes.filter(adt => adt.diagramTypeId === id).length > 1) {
            return this.dialogService.confirm(this.translate.instant('additional-diag-type.spotDeleteConfirm.title'), this.translate.instant('additional-diag-type.spotDeleteConfirm.message'))
                .pipe(
                    filter((confirmed) => confirmed),
                    flatMap(() => seriesType === SeriesType.ADDITIONAL ? this.additionalDiagramService.removeDiagramType(id) : this.additionalDiagramService.removeProduct(id))
                );
        }

        return seriesType === SeriesType.ADDITIONAL ? this.additionalDiagramService.removeDiagramType(id) : this.additionalDiagramService.removeProduct(id);
    }

    editDiagramGroup(additionalDiagramGroup?: AdditionalDiagramGroupDTO) {

        this.dialog
            .open(AdditionalDiagGroupEditorComponent, {
                disableClose: true,
                data: additionalDiagramGroup || null
            })
            .afterClosed()
            .pipe(filter((saved) => saved))
            .subscribe(() => this.refresh());
    }

    deleteDiagramGroup(additionalDiagramGroupDTO: AdditionalDiagramGroupDTO) {
        this.dialogService.confirm(
            this.translate.instant('additional-diag-type.groupDeleteConfirm.title'),
            this.translate.instant('additional-diag-type.groupDeleteConfirm.message',
                {
                    diagramName: this.translateAdditionalDiagramGroup.transform(additionalDiagramGroupDTO)
                }
            )
        ).pipe(
            filter((confirmed) => confirmed),
            flatMap(() => this.additionalDiagramService.removeDiagramGroup(additionalDiagramGroupDTO.diagramGroupId))
        ).subscribe(() => this.refresh(),
            (error) => {
                console.log(error);
            });
    }

    refresh() {
        this.selectedDiagramType = null;
        forkJoin(
            this.additionalDiagramService.listDiagramTypes(),
            this.additionalDiagramService.listDiagramGroups(),
            this.marketPriceService.listSpotTimeSeries(),
            this.marketPriceService.listProducts()
        ).subscribe(results => {
            const [diagramTypes, diagramGroups, spot, products] = results;
            const convertedProducts: AdditionalDiagramTypeDTO[] = products.map(AdditionalDiagUtil.convertProductDto);

            const convertedSpots: AdditionalDiagramTypeDTO[] = spot.map(AdditionalDiagUtil.convertAdditionalTimeSeriesDto);

            diagramTypes.forEach(d => d.seriesType = SeriesType.ADDITIONAL);

            this.additionalDiagramTypes = diagramTypes;
            this.additionalDiagramTypes.push(...convertedProducts, ...convertedSpots);
            this.selectedDiagramType = null;

            this.additionalDiagramGroups = diagramGroups;
            this.selectedDiagramGroup = null;

        });
    }

}
