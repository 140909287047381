import {Component, Input} from '@angular/core';
import {PodGroupModel} from '../../shared/dto/pod-group.model';
import {PodModel} from '../../shared/dto/pod.model';
import {ConsumptionExportService} from './consumption-export.service';
import * as moment from 'moment';;

@Component({
    selector: 'consumption-export',
    templateUrl: './consumption-export.component.html',
    styles: [],
    providers: [ConsumptionExportService]
})
export class ConsumptionExportComponent {

    @Input() pods: PodModel[];
    @Input() podGroups: PodGroupModel[];
    @Input() startTime: Date;
    @Input() endTime: Date;
    @Input() resolution: string;
    @Input() disabled: boolean = false;

    constructor(private service: ConsumptionExportService) {
    }

    exportData() {
        this.service.download(
            moment(this.startTime),
            moment(this.endTime).add(1, 'days'),
            this.resolution,
            this.pods,
            this.podGroups
        );
    }
}
