<div class="select-wrapper w-auto cursor--pointer">
    <div class="d-flex flex-row align-items-center h-100 position-relative mr-4 ml-3 cursor--pointer">
        <span class="font-weight-bold">{{ 'coverageTransactions.tabs.overview.interval' | translate }}</span>
        <span *ngIf="deliveryPeriod">
            <span class="font-weight-bold">:</span>
            {{ deliveryPeriod.startTime | cezDate }} - {{ deliveryPeriod.endTime | cezDate }}
        </span>
    </div>

    <select class="form-control"
            [(ngModel)]="deliveryPeriod"
            (ngModelChange)="onDeliveryPeriodChanged()">
        <option *ngFor="let dp of deliveryPeriods" [ngValue]="dp">
            {{ dp.startTime | cezDate }} - {{ dp.endTime | cezDate }}
        </option>
    </select>
</div>

<ng-container *ngIf="selectedOverview && selectedOverview.progressBarConfig && selectedOverview.progressBarConfig.currentValue !== null">
    <div>
        <span>{{ 'agreement.agreementData.services.productType' | translate }}: </span>{{ selectedOverview.product }}
    </div>
    <div>
        <span>{{ 'agreement.agreementData.quantityExpected' | translate }}: </span>{{ selectedOverview.quantity | number}}
    </div>
    <div>
        <span>{{ 'agreement.agreementData.mtmUnitPrice' | translate }}: </span>{{ selectedOverview.mtmUnitPrice | number: '1.2-2'}}
    </div>

    <div>
        <span>{{ 'agreement.agreementData.position' | translate }}: </span>
        <div class="progress mw-150">
            <div class="progress-bar" role="progressbar" [progressbar]="selectedOverview.progressBarConfig"></div>
        </div>
    </div>
</ng-container>
