import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Table } from 'primeng/table';
import { IntervalType } from '../model/interval-type';
import { ProductDto } from '../model/productDto';
import { TimeSeriesDisplayEvent } from '../model/time-series-display-event';
import {SelectItem, SortEvent} from 'primeng/api';
import {MatLegacyCheckboxChange as MatCheckboxChange} from "@angular/material/legacy-checkbox";
import {UtilService} from '../../shared/services/util.service';
import {DiagramType} from '../../admin/additional-diag-types/model/diagram-type';

@Component({
    selector: 'jhi-market-products',
    templateUrl: './market-products.component.html',
    styleUrls: ['market-products.component.scss']
})
export class MarketProductsComponent implements OnInit {

    private _products: ProductDto[];

    @Output() compactSelectedChange: EventEmitter<boolean> = new EventEmitter();
    private _compactSelected: boolean;

    get compactSelected(): boolean {
        return this._compactSelected;
    }

    @ViewChild('dt', {static: true})
    private table: Table;

    @Input() set compactSelected(value: boolean) {
        this._compactSelected = value;
        this.compactSelectedChange.emit(this._compactSelected);
        this.table.reset();
    }

    @Input()
    set products(products) {
        this._products = products;

        this.booleanOptions = this.utilService.createBooleanOptions();

        if (!products || products.length === 0) return;
        this.minYear = products.reduce((min, product) => Math.max(Math.min(min, product.year), 2000), products[0].year);
        this.maxYear = products.reduce((max, product) => Math.min(Math.max(max, product.year), 2100), products[0].year);
        this.yearFilter = [this.minYear, this.maxYear];
    }

    get products() {
        return this._products;
    }

    @Output() timeSeriesDisplay: EventEmitter<TimeSeriesDisplayEvent> = new EventEmitter();

    @ViewChild('dt', {static: true}) tableRef: Table;

    intervalTypeOptions: SelectItem[];
    private booleanOptions: SelectItem[];

    yearFilter: number[];
    public minYear: number;
    public maxYear: number;

    constructor(private utilService: UtilService,
                private translate: TranslateService) {
        this.intervalTypeOptions = Object.keys(IntervalType)
            .map(intervalType => this.utilService.createSelectItem(intervalType, 'IntervalType'));
    }

    ngOnInit() {
        let filterConstraints = this.tableRef.filters as any;
        filterConstraints.between = this.utilService.betweenFilter;
        filterConstraints.intervalfilter = this.utilService.intervalFilter;
        filterConstraints.smartTextFilter = this.utilService.smartTextFilter;
    }

    selectionChanged(checked: boolean, product: ProductDto) {
        product.selected = checked;

        let productDisplayEvent = new TimeSeriesDisplayEvent();
        productDisplayEvent.display = checked;
        productDisplayEvent.timeSeriesId = {
            timeSeriesHeaderId: product.timeSeriesHeaderId,
            sid: false,
            diagramType: DiagramType.LINE
        };
        this.timeSeriesDisplay.emit(productDisplayEvent);
    }

    spotInDeliveryChanged(checked: boolean, product: ProductDto) {
        product.spotInDelivery = checked;
        product.spotInDeliveryError = null;

        let productDisplayEvent = new TimeSeriesDisplayEvent();
        productDisplayEvent.display = checked;
        productDisplayEvent.timeSeriesId = {
            timeSeriesHeaderId: product.timeSeriesHeaderId,
            sid: true,
            diagramType: DiagramType.LINE
        };
        this.timeSeriesDisplay.emit(productDisplayEvent);
    }

    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {
            const multiSortMeta = event.multiSortMeta;
            let result = null;

            if (multiSortMeta === undefined) {
                return this.sortField(event.order, event.field, data1, data2);
            } else {
                multiSortMeta.forEach((meta) => {
                    let metaResult = this.sortField(meta.order, meta.field, data1, data2);
                    result = result || metaResult;
                });
            }

            return result;
        });
    }

    private sortField(order:number, field: string, data1, data2) {
        let result = null;
        const shouldTranslate = field === 'intervalType';
        const value1 = shouldTranslate ? this.translate.instant('IntervalType.' + data1[field]) : data1[field];
        const value2 = shouldTranslate ? this.translate.instant('IntervalType.' + data2[field]) : data2[field];

        if (value1 == null && value2 != null) {
            result = -1;
        } else if (value1 != null && value2 == null) {
            result = 1;
        } else if (value1 == null && value2 == null) {
            result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }

        return order * result;
    }

    setErrorForSpotInDelivery(headerId: number, errorCode: string) {
        const x = this.products.find(product => product.timeSeriesHeaderId === headerId);
        if (!x) {
            console.error('No product found with headerId ' + headerId);
        } else {
            x.spotInDeliveryError = errorCode;
        }
    }
}
