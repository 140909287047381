import {Component, OnInit} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RoutesRecognized
} from '@angular/router';


import {JhiLanguageHelper, Principal} from '../../shared';
import {ToastMessageModel} from '../../shared/dto/toast-message.model';
import {BootstrapAlertService} from '../../shared/services/bootstrap-alert.service';
import {LoadingOverlayService} from '../../shared/services/loading-overlay.service';
import {DailyPricesService} from '../../shared/services/daily-prices.service';
import {CoverageTransactionsService} from '../../coverage-transactions/coverage-transactions.service';
import {StateStorageService} from '../../shared/auth';

@Component({
    selector: 'jhi-main',
    templateUrl: './main.component.html'
})
export class JhiMainComponent implements OnInit {

    public readonly CEZ_HEDGE_URL: string = '/hedge-transaction';

    messageList: ToastMessageModel[] = [];

    constructor(
        private jhiLanguageHelper: JhiLanguageHelper,
        public router: Router,
        private principal: Principal,
        private $storageService: StateStorageService,
        private bootstrapAlertService: BootstrapAlertService,
        private loadingOverlayService: LoadingOverlayService,
        private coverageTransactionsService: CoverageTransactionsService,
        private dailyPricesService: DailyPricesService) {
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        if (routeSnapshot.firstChild) {
            return this.getPageTitle(routeSnapshot.firstChild);
        }

        if (routeSnapshot.data && routeSnapshot.data['pageTitle']) {
            return routeSnapshot.data['pageTitle'];
        } else {
            return 'main.link.homepage';
        }
    }

    ngOnInit() {
        this.dailyPricesService.initDailyPrices();
        this.coverageTransactionsService.initCartVisibility();

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.loadingOverlayService.turnOn();
            }

            if (event instanceof NavigationEnd) {
                this.jhiLanguageHelper.updateTitle(this.getPageTitle(this.router.routerState.snapshot.root));
                this.loadingOverlayService.turnOff();

                // ga('set', 'page', event.urlAfterRedirects);
                // ga('send', 'pageview');
            }

            if (event instanceof NavigationCancel) {
                this.loadingOverlayService.turnOff();
            }

            if (event instanceof NavigationError) {
                this.loadingOverlayService.turnOff();
            }

            if (event instanceof RoutesRecognized) {
                let params = {};
                let destinationData = {};
                let destinationName = '';
                let destinationEvent = event.state.root.firstChild.children[0];
                if (destinationEvent !== undefined) {
                    params = destinationEvent.params;
                    destinationData = destinationEvent.data;
                    destinationName = destinationEvent.url[0].path;
                }
                let from = {name: this.router.url.slice(1)};
                let destination = {name: destinationName, data: destinationData};
                this.$storageService.storeDestinationState(destination, params, from);
            }
        });

        this.bootstrapAlertService.getAlertEvent().subscribe(r => {
            this.messageList.push(r);
        });
    }

    get loadingOverlay(): boolean {
        return this.loadingOverlayService.isOn();
    }

    get isCezHedgeScreen(): boolean {
        return this.router.url.startsWith(this.CEZ_HEDGE_URL);
    }

    public dismissMessages = () => {
        this.messageList = [];
    }
}
