import {ChangeType} from './change-type';
import {TimeSeriesData} from '../timeseries/time-series-data';
import {TimeSeriesId} from '../time-series-id';

export class TimeSeriesDataSelectionChange {

    changeType: ChangeType;
    timeSeriesId: TimeSeriesId;
    timeSeriesData: TimeSeriesData;

    public static addTimeSeries(timeSeriesId: TimeSeriesId, timeSeriesData: TimeSeriesData): TimeSeriesDataSelectionChange {
        return new TimeSeriesDataSelectionChange(ChangeType.ADDITION, timeSeriesId, timeSeriesData);
    }

    public static removeTimeSeries(timeSeriesId: TimeSeriesId): TimeSeriesDataSelectionChange {
        return new TimeSeriesDataSelectionChange(ChangeType.REMOVAL, timeSeriesId, null);
    }

    private constructor(changeType: ChangeType, timeSeriesId: TimeSeriesId, timeSeriesData: TimeSeriesData) {
        this.changeType = changeType;
        this.timeSeriesId = timeSeriesId;
        this.timeSeriesData = timeSeriesData;
    }

}
