import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import {MatLegacyChipInputEvent as MatChipInputEvent} from "@angular/material/legacy-chips";
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from "@angular/material/legacy-autocomplete";

@Component({
    selector: 'jhi-chip-list-autocomplete',
    templateUrl: 'chip-list-autocomplete.component.html',
    styleUrls: ['./chip-list-autocomplete.component.scss']
})
export class ChipListAutocompleteComponent implements OnInit, OnChanges {
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = false;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    inputFormControl = new UntypedFormControl();

    @Input() placeholder: string = 'Select items...';
    @Input() items: any[];
    @Input() nameFunction: any;
    filteredItems: Observable<any[]>;
    @Input() selectedItems: any[] = [];

    @Output() selectionChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

    @ViewChild('itemInput', {static: true}) itemInput: ElementRef<HTMLInputElement>;

    constructor(private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.filteredItems = this.inputFormControl.valueChanges.pipe(
            startWith(null),
            map((value: string|null) => value ? this._filter(value) : this.filterSelectedElements(this.items)
            ));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['items']) {
            this.updateFilteredItems();
        }
    }

    add(event: MatChipInputEvent): void {
        const input = event.chipInput.inputElement;
        const value = event.value;

        if ((value || '').trim()) {
            let newInput = this.items.find(item => this.nameFunction(item) === value);
            if (newInput) {
                this.selectedItems.push(newInput);
            }
        }

        if (input) {
            input.value = '';
        }
        this.inputFormControl.setValue(null);
        this.selectionChanged.emit(this.selectedItems);
    }

    addAll(): void {
        this.items.forEach(i => {
            if (i !== undefined && this.selectedItems.indexOf(i) === -1) {
                this.selectedItems.push(i);
            }
        });
        this.itemInput.nativeElement.value = '';
        this.inputFormControl.setValue(null);
        this.selectionChanged.emit(this.selectedItems);
    }

    remove(item: any): void {
        const index = this.selectedItems.indexOf(item);

        if (index >= 0) {
            this.selectedItems.splice(index, 1);
        }

        this.selectionChanged.emit(this.selectedItems);
        this.inputFormControl.setValue(null);
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (event.option.viewValue === this.translateService.instant('chip-list.select-all')) {
            this.addAll();
        } else {
            this.selectedItems.push(this.items.find(item => this.nameFunction(item) === event.option.viewValue));
            this.itemInput.nativeElement.value = '';
            this.inputFormControl.setValue(null);
            this.selectionChanged.emit(this.selectedItems);
        }


    }

    private _filter(value: string): any[] {
        const filterValue = value.toLowerCase();
        const filtered = this.items.filter(item => this.nameFunction(item).toLowerCase().indexOf(filterValue) === 0);
        return this.filterSelectedElements(filtered);
    }

    filterSelectedElements(items: any[]): any[] {
        return items.filter(item => undefined === this.selectedItems.find(si => this.nameFunction(si) === this.nameFunction(item)));
    }

    private updateFilteredItems() {
        this.inputFormControl.setValue(this.inputFormControl.value);
    }
}
