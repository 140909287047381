import {Route} from '@angular/router';
import {CoverageTransactionsComponent} from './coverage-transactions.component';
import {UserRouteAccessService} from '../shared';
import {PartnerMandatoryGuard} from '../shared/partner-mandatory.guard';
import {PartnerDeliveryPeriodGuard} from "../shared/delivery-period-list/partner-delivery-period.guard";

export const COVERAGE_TRANSACTION_ROUTE: Route[] = [
    {
        path: 'hedge-transaction',
        component: CoverageTransactionsComponent,
        data: {
            permission: 'HEDGE_TRANSACTIONS_SCREEN',
            pageTitle: 'main.link.coverageTransactions'
        },
        children: [
            {path: '', redirectTo: 'overview', pathMatch: 'full' as Route['pathMatch'] },
            {
                path: 'overview',
                loadChildren: () => import('./overview/coverage-transactions-overview.module').then(m => m.CoverageTransactionsOverviewModule)
            },
            {
                path: 'transactions',
                loadChildren: () => import('./offer-claim/offer-claim.module').then(m => m.OfferClaimModule)
            },
            {
                path: 'price-monitoring',
                loadChildren: () => import('./price-monitoring/coverage-transactions-price-tracker.module').then(m => m.CoverageTransactionsPriceTrackerModule)
            },
            {
                path: 'transaction-history',
                loadChildren: () => import('./transaction-history/transaction-history.module').then(m => m.TransactionHistoryModule)
            },
            {
                path: 'contract-parameters',
                loadChildren: () => import('./contract-parameters/coverage-transactions-contract-parameters.module').then(m => m.CoverageTransactionsContractParametersModule)
            },
            {
                path: 'hedge-consumption-plan',
                loadChildren: () => import('./hedge-consumption-plan/hedge-consumption-plan.module').then(m => m.HedgeConsumptionPlanModule)
            },
            {
                path: 'cart',
                loadChildren: () => import('./cart/cart.module').then(m => m.CartModule)
            }
        ],
        canActivate: [UserRouteAccessService, PartnerMandatoryGuard, PartnerDeliveryPeriodGuard],
    }
];
