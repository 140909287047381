import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { StateStorageService } from '../auth/state-storage.service';
import { LocatorService } from '../services/locator.service';

@Injectable()
export class NgbDateMomentParserFormatter extends NgbDateParserFormatter {
  constructor() {
      super();
  };

  format(date: NgbDateStruct): string {
    if (date === null) {
      return '';
    }
    let d = moment({
      year: date.year,
      month: date.month - 1,
      date: date.day
    });
    return d.isValid() ? d.format(this.getMomentFormat()) : '';
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }
    let d = moment(value, this.getMomentFormat());
    return d.isValid() ? {
      year: d.year(),
      month: d.month() + 1,
      day: d.date()
    } : null;
  }

    getMomentFormat(): string {
    let stateStorageService = <StateStorageService>LocatorService.injector.get(StateStorageService);
    let langKey = stateStorageService.getLanguage();

    if (langKey === "hu")
      return "YYYY.MM.DD.";

        return 'DD.MM.YYYY';
  }
}
