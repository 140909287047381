import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CoverageTransactionsService } from "../../coverage-transactions/coverage-transactions.service";
import {map} from "rxjs/operators";
import {StateStorageService} from "../auth";
import {DialogService} from "../dialog/dialog.service";
import {TranslateService} from "@ngx-translate/core";

@Injectable({
  providedIn: 'root'
})
export class PartnerDeliveryPeriodGuard  {

    constructor(
        private stateStorageService: StateStorageService,
        private coverageTransactionsService: CoverageTransactionsService,
        private dialogService: DialogService,
        private translate: TranslateService,
        private router: Router
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const companyId = this.stateStorageService.getSelectedCompanyId();

        return this.coverageTransactionsService.getDeliveryPeriods(companyId).pipe(
            map(data => {
                if (!data.length || data.length === 0) {
                    this.router.navigate(['']);
                    this.dialogService.alert(this.translate.instant('validation.PARTNER_DOES_NOT_HAVE_DELIVERY_PERIOD'), '');
                    return false;
                }
                return true;
            })
        );
    }

}
