import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportsComponent} from './reports.component';
import {RouterModule} from '@angular/router';
import {REPORTS_ROUTE} from "./reports.route";
import {InvoiceReportModule} from '../invoice-report/invoice-report.module';
import {MarketPriceModule} from '../price/market-price.module';
import {ToleranceThresholdModule} from '../tolerance-threshold/tolerance-threshold.module';
import {CezPortalSharedModule} from '../shared';
import {ConsumptionNewModule} from '../consumption-new/consumption-new.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(REPORTS_ROUTE, {useHash: true}),
        CezPortalSharedModule,
        ConsumptionNewModule,
        InvoiceReportModule,
        MarketPriceModule,
        ToleranceThresholdModule
    ],
    declarations: [ReportsComponent]
})
export class ReportsModule { }
