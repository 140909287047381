import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import {finalize} from 'rxjs/operators';
import {LoadingOverlayService} from './loading-overlay.service';

export type FileSaveType = 'POST' | 'GET';

@Injectable({
    providedIn: 'root'
})
export class FileSaverService {

    constructor(private http: HttpClient,
                private loadingOverlay: LoadingOverlayService) {
    }

    public save(url: string, type: string = 'application/octet-stream',
                body?: any, params: HttpParams | { [param: string]: string | string[] } = new HttpParams(),
                defaultFileName?: string,
                fileSaveType: FileSaveType = 'POST',
                uriDecode: boolean = false): void {
        switch (fileSaveType) {
            case 'POST':
                this.savePost(url, type, body, params, defaultFileName, uriDecode);
                break;
            case 'GET':
                this.saveGet(url, type, params, defaultFileName, uriDecode);
                break;
        }
    }

    private savePost(url: string, type: string = 'application/octet-stream', body?: any, params: HttpParams | { [param: string]: string | string[] } = new HttpParams(), defaultFileName?: string, uriDecode?: boolean): void {
        this.loadingOverlay.turnOn();
        this.http.post(url, body, {
            params,
            observe: 'response',
            responseType: 'arraybuffer'
        }).pipe(finalize(() => this.loadingOverlay.turnOff())).subscribe(result => this.saveFile(result, type, defaultFileName, uriDecode));
    }

    private saveGet(url: string, type: string = 'application/octet-stream', params: HttpParams | { [param: string]: string | string[] } = new HttpParams(), defaultFileName?: string, uriDecode?: boolean): void {
        this.loadingOverlay.turnOn();
        this.http.get(url, {
            params,
            observe: 'response',
            responseType: 'arraybuffer'
        }).pipe(finalize(() => this.loadingOverlay.turnOff())).subscribe(result => this.saveFile(result, type, defaultFileName, uriDecode));
    }

    private saveFile(result: any, type: string, defaultFileName?: string, uriDecode?: boolean): void {
        const blob = new Blob([new Uint8Array(result.body)], {type});
        const disposition = result.headers.get('Content-Disposition');

        // https://stackoverflow.com/a/40940790
        if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(!!uriDecode ? decodeURIComponent(disposition) : disposition);
            if (matches != null && matches[1]) {
                FileSaver.saveAs(blob, matches[1].replace(/['"]/g, ''));
            }
        } else {
            FileSaver.saveAs(blob, defaultFileName);
        }
    }
}
