import {Pipe, PipeTransform} from "@angular/core";
import {OfferType} from "../offer-claim/model/offer-type.enum";

@Pipe({
    name: 'productName'
})
export class ProductNamePipe implements PipeTransform {
    transform(name: string): string {
        if (name && name.length) {
            return `${name.length < 5 ? 'YR' : name.substring(4)}-${name.substring(0, 4)} HU ${OfferType.BL}`;
        }
        return name;
    }
}
