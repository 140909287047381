import { Route } from '@angular/router';

import { UserRouteAccessService } from '../shared/auth/user-route-access-service';
import { RegistrationRequestComponent } from './registration.request.component';

export const REGISTRATION_ROUTE: Route = {
  path: 'register',
  component: RegistrationRequestComponent,
  data: {   
    permission: "",
    pageTitle: 'register.title'
  }
};
