import { Route } from '@angular/router';

import { NewsComponent } from './news.component';
import {UserRouteAccessService} from '../shared';

export const NEWS_ROUTE: Route[] = [
  {
    path: 'news/category/:category',
    component: NewsComponent,
    data: {
      permission: "NEWS_READ",
      pageTitle: 'home.title'
    },
    canActivate: [UserRouteAccessService]
  }, {
    path: 'news/category/:category/:id',
    component: NewsComponent,
    data: {
      permission: "NEWS_READ",
      pageTitle: 'home.title'
    },
    canActivate: [UserRouteAccessService]
  }, {
    path: 'news/:id',
    component: NewsComponent,
    data: {
      permission: "NEWS_READ",
      pageTitle: 'home.title'
    },
    canActivate: [UserRouteAccessService]
  }];
