import {Component, OnDestroy, OnInit} from '@angular/core';
import {CoverageTransactionsOverview} from './coverage-transactions-overview.model';
import {DeliveryPeriodDto} from '../../shared/dto/delivery-period-dto';
import {CoverageTransactionsAdminService} from '../../coverage-transaction.admin/coverage-transactions.admin.service';
import {forkJoin, Observable, Subject} from 'rxjs';
import {LoadingOverlayService} from '../../shared/services/loading-overlay.service';
import {CoverageTransactionsService} from '../coverage-transactions.service';
import {
    DailyRatesPricesChartBaseComponent
} from '../../coverage-transaction.admin/daily-rates/daily-rates-prices-chart-base.component';
import {FileSaverService} from '../../shared/services/file-saver.service';
import {HttpParams} from '@angular/common/http';
import {CoverageTransactionsPeriodTabService} from '../coverage-transactions-period-tab.service';
import {ProductNamePipe} from '../../shared/pipes/product-name.pipe';
import {AggregationTableType} from '../../shared/aggregation-table-data/aggregation-table-data.model';
import {MtmReportCashoutCalculationType} from './mtm-report/coverage-transactions-mtm.model';
import {ProductType, Scheduling} from '../../shared/agreement-details/agreement-enums.model';
import {mergeMap, take, takeUntil, tap} from 'rxjs/operators';
import {TimeSeriesChartLineType} from '../../shared/time-series/model/chart-type.enum';
import {HighchartsService} from '../../highcharts/highcharts.service';
import {DialogService} from '../../shared/dialog/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {DataUpdateTrackingService} from '../../shared/services/data-update-tracking.service';

@Component({
    selector: 'jhi-coverage-transactions-overview',
    templateUrl: './coverage-transactions-overview.component.html',
    styleUrls: ['coverage-transactions-overview.component.scss']
})
export class CoverageTransactionsOverviewComponent extends DailyRatesPricesChartBaseComponent implements OnInit, OnDestroy {

    public readonly AggregationTableType: typeof AggregationTableType = AggregationTableType;
    public readonly ProductType: typeof ProductType = ProductType;

    public _selectedDeliveryPeriod: DeliveryPeriodDto;

    public get selectedDeliveryPeriod(): DeliveryPeriodDto {
        return this._selectedDeliveryPeriod;
    }

    public set selectedDeliveryPeriod(deliveryPeriod: DeliveryPeriodDto) {
        if (deliveryPeriod && this._selectedDeliveryPeriod !== deliveryPeriod) {
            this._selectedDeliveryPeriod = deliveryPeriod;
            this.coverageTransactionsService.loadMtmUnitPrices(this.coverageTransactionsOverviews, this.selectedDeliveryPeriod.id);
            setTimeout(() => {
                super.init(this._selectedDeliveryPeriod.id);
            });
        }
    }

    public TimeSeriesChartLineType: typeof TimeSeriesChartLineType = TimeSeriesChartLineType;

    public deliveryPeriods: DeliveryPeriodDto[] = [];
    public coverageTransactionsOverviews: CoverageTransactionsOverview[] = [];

    private destroy: Subject<void> = new Subject<void>();
    public isMtmReportSfChecked: boolean = true;
    public isMtmReportRbcChecked: boolean = true;
    public mtmReportCashoutCalculationType: MtmReportCashoutCalculationType = MtmReportCashoutCalculationType.SF_RBC;
    public isPeriodsLoading: boolean = false;

    constructor(private loadingOverlay: LoadingOverlayService,
                private coverageTransactionsAdminService: CoverageTransactionsAdminService,
                private coverageTransactionsService: CoverageTransactionsService,
                private coverageTransactionsPeriodTabService: CoverageTransactionsPeriodTabService,
                private fileSaver: FileSaverService,
                private productNamePipe: ProductNamePipe,
                private highchartsService: HighchartsService,
                private dialogService: DialogService,
                private translate: TranslateService,
                private dataUpdateTrackingService: DataUpdateTrackingService) {
        super(coverageTransactionsAdminService, loadingOverlay, productNamePipe, false);
    }

    ngOnInit(): void {
        this.initDeliveryPeriods();
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    private initDeliveryPeriods(): void {
        this.isPeriodsLoading = true;
        const sources = [
            this.coverageTransactionsPeriodTabService.deliveryPeriods.pipe(take(1), tap((deliveryPeriods) => this.deliveryPeriods = deliveryPeriods)),
            this.coverageTransactionsPeriodTabService.coverageTransactionsOverviews.pipe(take(1), tap((coverageTransactionsOverviews) => {
                this.coverageTransactionsOverviews = coverageTransactionsOverviews;
                this.coverageTransactionsService.loadMtmUnitPrices(this.coverageTransactionsOverviews);
            }))
        ];
        forkJoin(sources)
            .pipe(takeUntil(this.destroy))
            .pipe(mergeMap(() => this.selectedDeliveryPeriodChanged()))
            .subscribe(_ => {
                this.isPeriodsLoading = false;
            });
    }

    private selectedDeliveryPeriodChanged(): Observable<DeliveryPeriodDto> {
        return this.coverageTransactionsPeriodTabService.getSelectedDeliveryPeriod()
            .pipe(tap(resp => this.selectedDeliveryPeriod = resp))
            .pipe(takeUntil(this.destroy));
    }

    public exportMtmReport(deliveryPeriod: DeliveryPeriodDto): void {
        let params: HttpParams = new HttpParams();
        if (this.mtmReportCashoutCalculationType) {
            params = params.set('calculationType', this.mtmReportCashoutCalculationType);
        }

        this.fileSaver.save(
            `/api/mtm-report/xls-export/${deliveryPeriod.id}`,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            {},
            params,
            null,
            'GET',
            true
        );
    }

    onDeliveryPeriodChange(deliveryPeriodId: number): void {
        const deliveryPeriod: DeliveryPeriodDto = this.deliveryPeriods.find((item) => {
            return item.id === deliveryPeriodId;
        });

        // this is needed to update the table row class ...
        // otherwise the `setSelectedDeliveryPeriodTab` would be enough to update the `selectedDeliveryPeriod` in this component
        this.coverageTransactionsPeriodTabService.setSelectedDeliveryPeriod(deliveryPeriod);
    }

    onMtmReportSfChanged(): void {
        if (!this.isMtmReportSfChecked) {
            this.isMtmReportRbcChecked = false;
        }

        this.updateMtmReportCalculationType();
    }

    onMtmReportRbcChanged(): void {
        this.updateMtmReportCalculationType();
    }




    public showMtmInfoPopup(): void {
        if (this.selectedDeliveryPeriod.scheduling == Scheduling.FLOATING_BALANCING) {
            this.dialogService.alert(
                ' ',
                this.translate.instant('offerClaim.mtmReport.tooltip_balancing_fee')
            );
        } else {

            if (this.selectedDeliveryPeriod.scheduling == Scheduling.SCHEDULING_BONUS) {
                this.dialogService.alert(
                    ' ',
                    this.translate.instant('offerClaim.mtmReport.tooltip_scheduling_bonus')
                );
            }else{
                this.dialogService.alert(
                    ' ',
                    this.translate.instant('offerClaim.mtmReport.tooltip')
                );
            }

        }
    }

    public showOtcPricesInfoPopup(): void {
        this.dialogService.alert(
            ' ',
            this.translate.instant('dailyRates.chart.tooltip')
        );
    }

    public showPositionOverviewInfoPopup(): void {
        this.dialogService.alert(
            ' ',
            this.translate.instant('offerClaim.aggregationTable.tooltip')
        );
    }

    public getDailyRatesChartExportName(): string {
        return this.highchartsService.getChartTitle('dailyRates.chart.title', this.selectedDeliveryPeriod);
    }

    public showDataUpdateTrackingInformation(): void {
        this.dataUpdateTrackingService.showPopup();
    }

    private updateMtmReportCalculationType(): void {
        if (this.isMtmReportRbcChecked) {
            this.mtmReportCashoutCalculationType = MtmReportCashoutCalculationType.SF_RBC;
        } else if (this.isMtmReportSfChecked) {
            this.mtmReportCashoutCalculationType = MtmReportCashoutCalculationType.SF;
        } else {
            this.mtmReportCashoutCalculationType = MtmReportCashoutCalculationType.DEFAULT;
        }
    }

}
