import { Component, Input, OnInit } from '@angular/core';
import { ProductType } from '../agreement-details/agreement-enums.model';
import { DeliveryPeriod } from '../dto/delivery.periods';

@Component({
    selector: 'jhi-price-list',
    templateUrl: './price-list.component.html',
    styles: []
})
export class PriceListComponent implements OnInit {

    @Input() model: DeliveryPeriod[];
    @Input() productType: ProductType;
    @Input() goO: boolean;

    constructor() {
    }

    ngOnInit() {
    }

}
