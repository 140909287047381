<table class="table table-striped">
  <thead>
    <tr>
      <th>
        <span>#</span>
      </th>
      <th>
        <span jhiTranslate="agreement.agreementData.tolerancePeriods.startTime"></span>
      </th>
      <th>
        <span jhiTranslate="agreement.agreementData.tolerancePeriods.endTime"></span>
      </th>
      <th>
        <span jhiTranslate="agreement.agreementData.tolerancePeriods.contractedQuantityMWh"></span>
      </th>
      <th>
        <span jhiTranslate="agreement.agreementData.tolerancePeriods.toleranceBandUpPercent"></span>
      </th>
      <th>
        <span jhiTranslate="agreement.agreementData.tolerancePeriods.toleranceBandDownPercent"></span>
      </th>
      <th>
        <span jhiTranslate="agreement.agreementData.tolerancePeriods.priceOfUpwardDeviation"></span>
      </th>
      <th>
        <span jhiTranslate="agreement.agreementData.tolerancePeriods.priceOfDownwardDeviation"></span>
      </th>
      <th *ngIf="adminView"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let toleranceLimit of model; let i = index">
      <td>{{i+1}}</td>
      <td>{{toleranceLimit.startTime | cezDate}}</td>
      <td>{{toleranceLimit.endTime | cezDate}}</td>
      <td>{{toleranceLimit.contractedQuantityMWh}}</td>
      <td>{{toleranceLimit.toleranceBandUpPercent}}</td>
      <td>{{toleranceLimit.toleranceBandDownPercent}}</td>
      <td>{{toleranceLimit.priceOfUpwardDeviation}}</td>
      <td>{{toleranceLimit.priceOfDownwardDeviation}}</td>
      <td *ngIf="adminView">
        <button type="button" class="btn btn-info btn-sm" (click)="edit(toleranceLimit)">
          <span class="fa fa-pencil"></span>
        </button>
        <button type="button" class="btn btn-danger btn-sm" (click)="delete(toleranceLimit)">
          <span class="fa fa-remove"></span>
        </button>
      </td>
    </tr>
  </tbody>
</table>
