import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgreementUserComponent } from './agreement-user.component';
import { RouterModule } from '@angular/router';
import { AGREEMENT_ROUTE } from "./agreement-user.route";
import { PodGroupComponentComponent } from './pod-group-component/pod-group-component.component';
import { PodGroupPageComponent } from './pod-group-page/pod-group-page.component';
import {CezPortalSharedModule} from '../shared';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AGREEMENT_ROUTE, { useHash: true }),
    CezPortalSharedModule
  ],
  declarations: [AgreementUserComponent, PodGroupComponentComponent, PodGroupPageComponent]
})
export class AgreementUserModule { }
