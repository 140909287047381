<ngx-loading class="local-loader"
             [show]="isLoading"
             [config]="{fullScreenBackdrop: false}"></ngx-loading>

<table class="table table-striped">
    <thead>
    <tr>
        <th class="text-left">
            <span jhiTranslate="coverageTransactions.tabs.overview.table.header.product"></span>
        </th>
        <th class="text-right">
            <span *ngIf="!expired" jhiTranslate="coverageTransactions.tabs.overview.table.header.price"></span>
            <span *ngIf="expired"
                  jhiTranslate="coverageTransactions.tabs.overview.table.header.lastPublishedPrice"></span>
        </th>
        <th *ngIf="hasTimeRemain" class="text-right">
            <span jhiTranslate="coverageTransactions.tabs.overview.table.header.remainTime"></span>
        </th>
        <th class="text-center">
            <span jhiTranslate="coverageTransactions.tabs.overview.table.header.priceVarying"></span>
        </th>
        <th class="text-left coverage-transaction-table-button-header"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let product of availableProducts">
        <td class="text-left">{{ getProductName(product.name) }}</td>
        <td class="text-right"
            [ngClass]="expired ? 'font-italic gray' : '' ">{{ !!product.currentPrice ? product.currentPrice : product.previousPrice | number:'1.2-2' }}
        </td>
        <td *ngIf="hasTimeRemain" class="text-right countdown-column">
          <countdown *ngIf="isShowRemainTime(product)"
                     [config]="{
                            leftTime: remainTime,
                        }">
          </countdown>
        </td>
        <td class="text-left">
          <jhi-price-trend-chart [priceData]="product.previous7BusinessDayPrices"></jhi-price-trend-chart>
        </td>
        <td class="text-right">
            <button writeProtected="HEDGE_TRANSACTIONS_WRITE"
                    (clickIfHasPermission)="goToPriceMonitoring(product)"
                    matTooltip="{{'coverageTransactions.tabs.overview.table.tooltip.addPriceMonitoring' | translate}}"
                    mat-mini-fab
                    color="primary"
                    class="btn-sm">
                <mat-icon>notifications_active</mat-icon>
            </button>
            <button *ngIf="isMarketOpen"
                    writeProtected="HEDGE_TRANSACTIONS_WRITE"
                    (clickIfHasPermission)="goToBuying(product)"
                    matTooltip="{{'coverageTransactions.tabs.overview.table.tooltip.addProduct' | translate}}"
                    mat-mini-fab
                    color="primary"
                    class="btn-sm ml-1">
                <mat-icon>shopping_cart</mat-icon>
            </button>
        </td>
    </tr>
    </tbody>
</table>
<h5> {{ (isLastMonthOfDeliveryPeriod() ? 'offerClaim.infoTextLastDp' : 'offerClaim.infoText') | translate }}</h5>
