import {OfferClaimDto} from '../../shared/offer-claim/model/OfferClaimDto';

export interface ScheduledQuotationDto {
    claims: OfferClaimDto[];
    scheduledQuotation: Date;
}

export enum BusinessDayType {
    HOLIDAY = 'HOLIDAY',
    WORKDAY = 'WORKDAY',
}

export interface BusinessCalendar {
    day: string;
    dayType: BusinessDayType;
}
