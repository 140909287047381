<ul ngbNav #nav="ngbNav" class="nav-tabs" tabSave="email" #tabSave="tabSave" style="display: flex; justify-content: center;">

  <li [ngbNavItem]="'history'">
    <a ngbNavLink>{{'email.tabs.history.title' | translate}}</a>
    <ng-template ngbNavContent>
      <jhi-history *ngIf="tabSave.tabLoaded"></jhi-history>
    </ng-template>
  </li>

  <li [ngbNavItem]="'template'">
    <a ngbNavLink>{{'email.tabs.template.title' | translate}}</a>
    <ng-template ngbNavContent>
      <jhi-template *ngIf="tabSave.tabLoaded"></jhi-template>
    </ng-template>
  </li>

</ul>


<div [ngbNavOutlet]="nav"></div>
