import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Moment } from 'moment-timezone';
import { DateInterval } from '../../../shared/dto/date-interval.model';
import { IntervalUtil } from './interval-util';

@Component({
    selector: 'day-interval-selector',
    templateUrl: 'day-interval-selector.component.html',
    styleUrls: ['day-interval-selector.component.scss']
})

export class DayIntervalSelectorComponent {

    private _dateFrom: Moment;
    private _dateTo: Moment;

    @Input() set selectedInterval(interval: DateInterval) {
        if (this.isIntervalValueChanged(interval)) {
            this._dateFrom = interval.from;
            this._dateTo = interval.to;
        }
    }
    @Output() selectedIntervalChange: EventEmitter<DateInterval> = new EventEmitter();
    @Input() order: number;


    get dateFrom(): Moment {
        return this._dateFrom;
    }

    set dateFrom(value: Moment) {
        if (!IntervalUtil.differentMoments(this._dateFrom, value)) {
            return;
        }

        this._dateFrom = value;

        if (Boolean(this._dateFrom) && (!Boolean(this._dateTo) || this._dateFrom.isAfter(this._dateTo))) {
            this._dateTo = this._dateFrom.clone();
        }

        this.intervalChanged();
    }

    get dateTo(): Moment {
        return this._dateTo;
    }

    set dateTo(value: Moment) {
        if (!IntervalUtil.differentMoments(this._dateTo, value)) {
            return;
        }

        this._dateTo = value;

        if (Boolean(this._dateTo) && (!Boolean(this._dateFrom) || this._dateTo.isBefore(this._dateFrom))) {
            this._dateFrom = this._dateTo.clone();
        }

        this.intervalChanged();
    }

    isIntervalValueChanged(newInterval: DateInterval): boolean {
        return newInterval.from !== this._dateFrom || newInterval.to !== this._dateTo;
    }

    intervalChanged() {
        this.selectedIntervalChange.emit({
            from: this._dateFrom,
            to: this._dateTo
        });
    }
}
