import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {DialogService} from '../dialog/dialog.service';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";

export interface CanLeave {
    canLeave(): Observable<boolean> | Promise<boolean>;
}

export declare namespace IsModified {
    interface AllowedTabsets {
        allowedTabsets: string[]; // the string value passed to tabSave directive, like: `<ngb-nav tabSave="agreement-details">`
    }

    type Type = boolean | AllowedTabsets;
}

@Injectable({
  providedIn: 'root'
})
export class CanLeaveService implements OnDestroy {

    private _isModified: IsModified.Type = false;
    private _isModifiedSubject: BehaviorSubject<IsModified.Type> = new BehaviorSubject<IsModified.Type>(this._isModified);
    private _isDestroyed: Subject<void> = new Subject<void>();

    constructor(private dialogService: DialogService,
                private translateService: TranslateService) { }

    public isModified(): Observable<IsModified.Type> {
        return this._isModifiedSubject.asObservable();
    }

    /**
     * Use this while the form value has been changed, saved or cancelled.
     *
     * Pass `IsModified.AllowedTabs` in case some tabs are allowed to be changed, but others not.
     */
    public setModified(value: IsModified.Type): void {
        this._isModified = value;
        this._isModifiedSubject.next(value);
    }

    /**
     * Used by CanLeave interface and tabSave directive
     *
     * @param successCallback Runs if confirmed. Either provide this callback or subscribe to the canLeave method.
     * @param message Custom message key.
     * @param title Custom title key.
     */
    canLeave(successCallback: () => void = undefined,
             message: string = this.translateService.instant('filter.basic.confirmDialog.message'),
             title: string = this.translateService.instant('filter.basic.confirmDialog.title')): Observable<boolean> {
        if (!this._isModified) {
            const canLeaveResponse: BehaviorSubject<boolean> = new BehaviorSubject(true);
            return canLeaveResponse.asObservable();
        }

        const dialog: Observable<boolean> = this.dialogService.confirm(title, message);

        dialog
            .pipe(takeUntil(this._isDestroyed))
            .subscribe((confirmed?: boolean) => {
                if (confirmed) {
                    this.setModified(false);

                    if (successCallback) {
                        successCallback();
                    }
                }
            });

        return dialog;
    }

    ngOnDestroy(): void {
        this._isDestroyed.next();
        this._isDestroyed.complete();
    }
}
