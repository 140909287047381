import { Directive, ElementRef, Input } from '@angular/core';
import { SessionTimerService } from '../session-timer.service';
import { PartialObserver, Subscription } from 'rxjs';

@Directive({
    selector: '[sessionTimeout]'
})
export class SessionTimeoutDirective {

    sessionTimeoutSubscription: Subscription;

    constructor(private el: ElementRef,
                private sessionTimerService: SessionTimerService) {
        const observer: PartialObserver<boolean> = {
            complete: () => {
                this.timeoutFunction();
                this.sessionTimeoutSubscription.unsubscribe();
            }
        };
        this.sessionTimeoutSubscription = this.sessionTimerService.onTimeout(observer);
    }

    @Input('sessionTimeout') timeoutFunction: () => void;
}
