<div class="modal-header">
  <h4
    class="modal-title">{{(link?.id ? 'useful-link.modal.editLink' : 'useful-link.modal.createLink') | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form #linkEditForm="ngForm" (ngSubmit)="onSubmit(linkEditForm)">
  <div class="modal-body">
    <div class="form-group">
      <label jhiTranslate="useful-link.category">Category</label>
      <select required name="category" [(ngModel)]="selectedCategory" class="form-control">
        <option></option>
        <option *ngFor="let item of categories" [ngValue]="item">{{item.name}}</option>
      </select>
    </div>
    <div class="form-group" *ngIf="languages && languages.length > 0">
      <label jhiTranslate="useful-link.langKey"></label>
      <select required class="form-control" id="language" name="language" [(ngModel)]="link.language">
        <option></option>
        <option *ngFor="let language of languages" [value]="language">{{language | findLanguageFromKey}}</option>
      </select>
    </div>
    <div class="form-group">
      <label jhiTranslate="useful-link.title">Title</label>
      <input required name="title" [ngModel]="link.title" class="form-control">
    </div>
    <div class="form-group">
      <label jhiTranslate="useful-link.url">Url</label>
      <input required name="linkUrl" [ngModel]="link.linkUrl" class="form-control"
             pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)">
    </div>
    <div class="form-group">
      <label jhiTranslate="useful-link.description">Description</label>
      <input required name="description" [ngModel]="link.description" class="form-control" maxlength="150">
      <br>
      <label jhiTranslate="useful-link.picture">Picture</label>
      <!--<file-uploader [fileType]="'image/*'" (onSelection)="fileuploaderFileChange($event)"></file-uploader>-->

      <div class="form-group">
        <div style="display: flex; align-items: center;">
          <input placeholder="{{'select.default' | translate}}" readonly class="form-control"
                 style="width: 200px; float: left; margin-right: 0px;" matInput *ngIf="!fileInput?.httpUrl"
                 value="{{fileInput?.files[0]?.name}}"/>
          <button mat-mini-fab color="primary" (click)="addFile(fileInput)" type="button">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <input #fileInput [hidden]="true" type="file" accept="image/*"/>
      </div>

      <cez-file-upload class="col"
                       id="czFileUp"
                       *ngIf="fileInput.value"
                       [file]="fileInput.files[0]"
                       [autoUpload]="true"
                       (onUpload)="onUpload($event)"
                       (removeEvent)="fileInput.value = ''"></cez-file-upload>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary"
            [disabled]="linkEditForm.form.invalid || isSaving">{{'entity.action.save' | translate}}</button>
  </div>
</form>
