<div class="modal-header">
  <h4 class="modal-title">{{(news?.id ? 'news.modal.modifyTitle' : 'news.modal.addTitle') | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="modalClose.emit()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form #newsEditForm="ngForm" (ngSubmit)="onSubmit(newsEditForm)">
  <div class="modal-body">
    <div class="form-group">
      <label>{{'news.category' | translate}}</label>
      <select required name="category" [(ngModel)]="selectedCategory" class="form-control">
        <option></option>
        <option *ngFor="let item of categories" [ngValue]="item">{{item.name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label>{{'news.title' | translate}}</label>
      <input name="title" [ngModel]="news.title" class="form-control" required>
    </div>
    <div class="form-group">
      <label>{{'news.summary' | translate}}</label>
      <input name="summary" [ngModel]="news.summary" class="form-control">
    </div>
    <div class="form-group">
      <label>{{'news.siteLanguage' | translate}}</label>
      <select class="form-control" id="language" name="language" [(ngModel)]="news.language" required>
        <option></option>
        <option *ngFor="let language of languages" [value]="language">{{language | findLanguageFromKey}}</option>
      </select>
    </div>
    <div class="form-group">
      <label>{{'news.contentLanguage' | translate}}</label>
      <select class="form-control" id="contentLanguage" name="contentLanguage" [(ngModel)]="news.contentLanguage"
              required>
        <option></option>
        <option *ngFor="let language of languages" [value]="language">{{language | findLanguageFromKey}}</option>
      </select>
    </div>
    <div class="form-group">
      <label>{{'news.content' | translate}}</label>
      <quill-editor [modules]="editorModules" name="text" [ngModel]="news.text" required></quill-editor>
      <br>
      <label>{{'news.picture' | translate}}</label>
      <!--<file-uploader [fileType]="'image/*'" (onSelection)="fileuploaderFileChange($event)" [required]="!news.id"></file-uploader>-->

      <div style="display: flex; align-items: center;">
        <input placeholder="{{'select.default' | translate}}" readonly class="form-control"
               style="width: 200px; float: left; margin-right: 0px;" matInput *ngIf="!fileInput?.httpUrl"
               value="{{fileInput?.files[0]?.name}}"/>

        <button mat-mini-fab color="primary" (click)="addFile(fileInput)" type="button">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <input #fileInput [hidden]="true" type="file" accept="image/*"/>

      <cez-file-upload class="col"
                       *ngIf="fileInput.value"
                       [file]="fileInput.files[0]"
                       [autoUpload]="true"
                       (onUpload)="onUpload($event)"
                       (removeEvent)="fileInput.value = ''"></cez-file-upload>
    </div>
    <div *ngIf="!newTagMode" class="form-group">
      <label>{{'news.tags' | translate}}</label>
      <div class="input-group">
        <p-autoComplete id="tags" name="tags" optionLabel="name" [multiple]="true" [(ngModel)]="selectedTags"
                        [suggestions]="tagResult" (completeMethod)="searchTags($event)"></p-autoComplete>
        <button mat-mini-fab color="primary" style="padding-left: -100px;" type="button" (click)="newTagMode=true">
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    <div *ngIf="newTagMode" class="row">
      <div class="form-group col-md-6">
        <label>{{'news.newTagHu' | translate}}</label>
        <input name="newTag" [(ngModel)]="newTagHu" class="form-control" required>
      </div>
      <div class="form-group col-md-6">
        <label>{{'news.newTagEn' | translate}}</label>
        <input name="newTag" [(ngModel)]="newTagEn" class="form-control" required>
        <div class="mt-2 pull-right">
          <button type="button" class="btn btn-success btn-sm" (click)="newTagMode=false; saveTag()">
            <span class="fa fa-check"></span>
          </button>
          <button type="button" class="btn btn-danger btn-sm" (click)="newTagMode=false">
            <span class="fa fa-times"></span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary"
            [disabled]="newsEditForm.form.invalid || isSaving || (!news.id && !selectedFile)">{{'entity.action.save' | translate}}</button>
  </div>
</form>
