import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StateStorageService } from './auth';

@Injectable({
    providedIn: 'root'
})
export class PartnerMandatoryGuard  {

    constructor(private stateStorageService: StateStorageService,
                private router: Router) {

    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean>|Promise<boolean>|boolean {

        if (!this.stateStorageService.getSelectedCompanyId()) {
            this.router.navigate(['company/selector']);
            return false;
        }

        return true;
    }
}
