<div class="modal-header">
  <h4 class="modal-title" jhiTranslate="scheduling.booked-performances-modal.title">Booked Performance</h4>
  <button type="button" class="close" aria-label="Close" (click)="this.activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form name="bookedPerformanceForm" role="form" #bookedPerformanceForm="ngForm" (ngSubmit)="save()">
  <div class="modal-body">
    <div class="row text-center">
      <div class="form-group col-md-6">
          <mat-form-field class="w-100">
              <input matInput [matDatepicker]="fromPicker"
                     placeholder="{{'scheduling.booked-performances-modal.startTime' | translate }}"
                     [(ngModel)]="model.startTime"
                     name="validFrom"
                     required>
              <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
              <mat-datepicker #fromPicker></mat-datepicker>
          </mat-form-field>
      </div>
      <div class="form-group col-md-6">
          <mat-form-field class="w-100">
              <input matInput [matDatepicker]="toPicker"
                     placeholder="{{'scheduling.booked-performances-modal.endTime' | translate }}"
                     [(ngModel)]="model.endTime"
                     name="validTo"
                     required>
              <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
              <mat-datepicker #toPicker></mat-datepicker>
          </mat-form-field>
      </div>
    </div>
    <div class="form-group">
        <label for="bookedQuantity" jhiTranslate="scheduling.booked-performances-modal.bookedQuantity">Booked Capacity</label>
      <div class="input-group">
        <input required type="number" id="bookedQuantity" name="bookedQuantity" [(ngModel)]="model.bookedQuantity" class="form-control">
        <span class="input-group-append">
          <span class="input-group-text">kW</span>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
          <label for="limitQuantity" jhiTranslate="scheduling.booked-performances-modal.limitQuantity">Capacity limit warning at (kW)</label>
        <div class="input-group">
          <input type="number" id="limitQuantity" name="limitQuantity" [disabled]="model.limitPercentage" [(ngModel)]="model.limitQuantity"
            class="form-control">
          <span class="input-group-append">
            <span class="input-group-text">kW</span>
          </span>
        </div>
      </div>
      <div class="form-group col-md-6">
          <label for="limitPercentage" jhiTranslate="scheduling.booked-performances-modal.limitPercentage">Capacity limit warning at (%)</label>
        <div class="input-group">
          <input type="number" id="limitPercentage" (change)=checkValidity() name="limitPercentage" min="0" max="100" [disabled]="model.limitQuantity"
            [(ngModel)]="model.limitPercentage" class="form-control">
          <span class="input-group-append">
            <span class="input-group-text">%</span>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
      <button type="submit" class="btn btn-primary"
              [disabled]="bookedPerformanceForm.form.invalid || ('BOOKED_PERFORMANCE_WRITE' | doesNotHavePermission)"
              jhiTranslate="scheduling.booked-performances-modal.save"
              *permissionOverlay="'BOOKED_PERFORMANCE_WRITE'">{{'entity.action.save' | translate}}</button>
  </div>
</form>
