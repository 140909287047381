import {AfterViewInit, Component, Inject, OnDestroy} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {Chart} from 'angular-highcharts';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as Highcharts from 'highcharts';

export interface HighchartsFullscreenData {
    chart: Chart;
    chartData?: Highcharts.SeriesOptions[];
}

@Component({
    selector: 'jhi-highcharts-fullscreen',
    templateUrl: './highcharts-fullscreen.component.html',
    styleUrls: ['./highcharts-fullscreen.component.scss']
})
export class HighchartsFullscreenComponent implements OnDestroy, AfterViewInit {

    private destroy: Subject<any> = new Subject();

    constructor(@Inject(MAT_DIALOG_DATA) public data: HighchartsFullscreenData) {
    }

    ngAfterViewInit(): void {
        this.data.chart.ref$.pipe(takeUntil(this.destroy)).subscribe((chartRef: Highcharts.Chart) => {
            chartRef.reflow(); // resize the chart to match the div height and width

            this.data.chartData.forEach((series: any) => {
                this.data.chart.addSeries(series, true, true);
            });
        });
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }
}
