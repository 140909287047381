import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AdditionalDiagramTypeDTO} from "../../admin/additional-diag-types/model/additional-diagram-type.model";


@Pipe({name: 'translateAdditionalDiagramType'})
export class TranslateAdditionalDiagramType implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(additionalDiagramType: AdditionalDiagramTypeDTO): string {
        switch (this.translate.currentLang) {
            case 'hu':
                return additionalDiagramType.typeNameHU;
            case 'en':
                return additionalDiagramType.typeNameEN;
        }
    }
}
