import {Component, OnInit} from '@angular/core';
import {CoverageTransactionsService} from '../../coverage-transactions/coverage-transactions.service';
import {AveragingTransactionDisplayModel} from '../../shared/offer-claim/model/averaging-transaction-display.model';
import {Moment} from 'moment';
import * as moment from 'moment';
import {CoverageTransactionsUtil} from '../../coverage-transactions/coverage-transactions.util';
import {TranslateService} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {FormControl, UntypedFormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";

type Partner = { id: number, name: string };
type DeliveryPeriod = { id: number, validFrom: Moment, validTo: Moment };

@Component({
    selector: 'jhi-averaging-transactions',
    templateUrl: './averaging-transactions.component.html',
    styleUrls: ['./averaging-transactions.component.scss']
})
export class AveragingTransactionsComponent implements OnInit {

    public averagingTransactions: AveragingTransactionDisplayModel[] = [];
    public averagingTransactionsFiltered: AveragingTransactionDisplayModel[] = [];
    public partners: Partner[] = [];
    public deliveryPeriods: DeliveryPeriod[] = [];
    public dealIds: string[];
    public selectedDealId: string = null;
    public selectedPartner: Partner = null;
    public selectedDeliveryPeriod: DeliveryPeriod = null;

    filteredDealIds: Observable<string[]> = new Observable<string[]>();
    dealIdControl = new FormControl('');

    constructor(private coverageTransactionsService: CoverageTransactionsService,
                private translate: TranslateService) { }

    ngOnInit(): void {
        this.getAveragingTransactions();
    }

    private _filterDealIds(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.dealIds.filter(option => option.toLowerCase().includes(filterValue));
    }

    public filterAveragingTransaction(): void {
        this.averagingTransactionsFiltered = this.averagingTransactions
            .filter(this.partnerFilter)
            .filter(this.deliveryPeriodFilter)
            .filter(this.dealIdFilter)
            .map(offerClaimHistory => {
                offerClaimHistory.productName = this.getProductName(offerClaimHistory);
                return offerClaimHistory;
            })
            .sort(this.sortAveragingTransactions.bind(this));
    }

    public getProductName(averagingTransactionDisplayModel: AveragingTransactionDisplayModel): string {
        const productStringArray: string[] = averagingTransactionDisplayModel.productName.split(' ');

        if (averagingTransactionDisplayModel.productName.includes("Custom")) {
            return `CUSTOM-${new Date(averagingTransactionDisplayModel.averagingStartDate).getFullYear()} HU ${productStringArray[1]}`;
        }

        return `${productStringArray[0]} HU ${productStringArray[1]}`;
    }

    public translatePurchaseStatus(averagingTransactionInput: AveragingTransactionDisplayModel): string {
        if (!averagingTransactionInput) {
            return '';
        }

        if (!averagingTransactionInput.purchaseStatus) {
            return '';
        }

        if (this.isAveragingTransaction(averagingTransactionInput)) {
            return this.translate.instant('offerClaimHistory.averagingPurchaseStatus.' + averagingTransactionInput.purchaseStatus);
        } else {
            return this.translate.instant('offerClaimHistory.purchaseStatus.' + averagingTransactionInput.purchaseStatus);
        }
    }

    private isAveragingTransaction(averagingTransaction: AveragingTransactionDisplayModel): boolean {
        return !!averagingTransaction
            && !!averagingTransaction.averagingStartDate
            && !!averagingTransaction.averagingEndDate;
    }

    private getAveragingTransactions() {
        this.coverageTransactionsService.getAveragingTransactions().subscribe((avgTransactions: AveragingTransactionDisplayModel[]) => {
            this.partners = this.distinctPartners(avgTransactions);
            this.deliveryPeriods = this.distinctDeliveryPeriods(avgTransactions);
            this.dealIds = this.distinctDealIds(avgTransactions);
            this.averagingTransactions = avgTransactions;
            this.filterAveragingTransaction();

            this.filteredDealIds = this.dealIdControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filterDealIds(value || '')),
            );
        });
    }

    private distinctPartners(avgTransactions: AveragingTransactionDisplayModel[]): Partner[] {
        const partners: Partner[] = [];
        avgTransactions
            .map((avgT: AveragingTransactionDisplayModel) => ({
                id: avgT.partnerId,
                name: avgT.partnerAccountName
            } as Partner))
            .forEach((partner: Partner) => {
                if (partners.map((p => p.id)).indexOf(partner.id) === -1) {
                    partners.push(partner);
                }
            });

        return partners;
    }

    private distinctDeliveryPeriods(avgTransactions: AveragingTransactionDisplayModel[]): DeliveryPeriod[] {
        const deliveryPeriods: DeliveryPeriod[] = [];
        avgTransactions
            .map((avgT: AveragingTransactionDisplayModel) => ({
                id: avgT.deliveryPeriodId,
                validFrom: moment(avgT.deliveryPeriodStartTime),
                validTo: moment(avgT.deliveryPeriodEndTime)
            } as DeliveryPeriod))
            .forEach((deliveryPeriod: DeliveryPeriod) => {
                if (deliveryPeriods.filter((dp: DeliveryPeriod) => dp.validFrom.isSame(deliveryPeriod.validFrom) &&
                    dp.validTo.isSame(deliveryPeriod.validTo)).length === 0) {
                    deliveryPeriods.push(deliveryPeriod);
                }
            });

        return deliveryPeriods;
    }

    private distinctDealIds(avgTransactions: AveragingTransactionDisplayModel[]): string[] {
        const dealIds: string[] = [];

        avgTransactions.forEach((avgT: AveragingTransactionDisplayModel) => dealIds.push(avgT.dealId));

        return dealIds;
    }

    private partnerFilter: (aT: AveragingTransactionDisplayModel) => boolean = (aT: AveragingTransactionDisplayModel) => {
        return !this.selectedPartner || aT.partnerId === this.selectedPartner.id;
    }

    private deliveryPeriodFilter: (aT: AveragingTransactionDisplayModel) => boolean = (aT: AveragingTransactionDisplayModel) => {
        return !this.selectedDeliveryPeriod || moment(aT.deliveryPeriodStartTime).isSame(this.selectedDeliveryPeriod.validFrom) &&
            moment(aT.deliveryPeriodEndTime).isSame(this.selectedDeliveryPeriod.validTo);
    }

    private dealIdFilter: (aT: AveragingTransactionDisplayModel) => boolean = (aT: AveragingTransactionDisplayModel) => {
        return !this.dealIdControl.value || aT.dealId === this.dealIdControl.value;
    }

    private sortAveragingTransactions(prev: AveragingTransactionDisplayModel, curr: AveragingTransactionDisplayModel): number {
        const partnerNameComparator: number = prev.partnerAccountName.localeCompare(curr.partnerAccountName);
        const deliveryPeriodStartComparator: number = moment(prev.deliveryPeriodStartTime).diff(moment(curr.deliveryPeriodStartTime));
        const averagingStartComparator: number = moment(prev.averagingStartDate).diff(moment(curr.averagingStartDate));
        const productNameComparator: number = CoverageTransactionsUtil.productNameComparator(prev.productName, curr.productName);

        if (partnerNameComparator !== 0) return partnerNameComparator;
        if (deliveryPeriodStartComparator !== 0) return deliveryPeriodStartComparator;
        if (productNameComparator !== 0) return productNameComparator;

        return averagingStartComparator;
    }
}
