import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Moment } from 'moment';
import {DateInterval} from '../../shared/dto/date-interval.model';


@Component({
    selector: 'jhi-dynamic-date',
    templateUrl: './dynamic-date.component.html',
    styleUrls: ['./dynamic-date.component.scss']
})
export class DynamicDateComponent implements OnInit {

    @Input()
    intervalList: DateInterval[] = [];
    @Output() intervalListEvent: EventEmitter<DateInterval[]> = new EventEmitter();

    @Input()
    upperBound: number = null;

    constructor() {
    }

    ngOnInit() {
    }

    addNextDateInterval(originalmoment: Moment) {
        this.intervalList.push({from: originalmoment.clone(), to: originalmoment.clone().add(1, 'month')});
    }

    deleteInterval(index: number) {
        this.intervalList.splice(index, 1);
        this.intervalListEvent.emit(this.intervalList);
    }

    setInterval(interval: DateInterval, intervalIndex: number) {
        this.intervalList[intervalIndex] = interval;
        this.intervalListEvent.emit(this.intervalList);
    }

    get noMoreDates(): boolean {
        if (this.upperBound) {
            return this.upperBound <= this.intervalList.length;
        }

        return false;
    }
}
