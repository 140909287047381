import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {PodBookedPerformanceModel} from '../../shared/dto/pod.booked.performance.model';

@Component({
    selector: 'jhi-booked-performance-modal',
    templateUrl: './booked-performance-modal.component.html',
    styleUrls: ['booked-performance-modal.component.scss']
})
export class BookedPerformanceModalComponent implements OnInit {

    @Input() model: PodBookedPerformanceModel;
    @Output() modalClose: EventEmitter<PodBookedPerformanceModel> = new EventEmitter();

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
    }

    save() {
        this.modalClose.emit(this.model);
    }

    checkValidity() {
        if (this.model.limitPercentage > 100)
            this.model.limitPercentage = 100;

        if (this.model.limitPercentage < 0)
            this.model.limitPercentage = 0;
    }
}
