<mat-card>

    <mat-vertical-stepper #stepper [linear]="true" class="cez-stepper">
        <mat-step #stepOne [completed]="scopeStepCompleted()">
            <ng-template matStepLabel class="cez-step-title">
                {{'invoiceReport.steps.stepOne.label' | translate}}
                <span class="selection-list"
                      *ngIf="scope.value !== '' ">{{'invoiceReport.scopeType.' + scope.value | translate}}</span>
                <span class="selection-list-second"
                      *ngIf="resultView.value !== '' ">| {{'invoiceReport.resultViewType.' + resultView.value | translate}}</span>
                <span class="selection-list-second">| {{currency.value}}</span>
            </ng-template>

            <mat-form-field class="mt-3">
                <mat-select #scopeSelector placeholder="{{'invoiceReport.steps.stepOne.scopeSelector' | translate}}"
                            [formControl]="scope">
                    <mat-option *ngFor="let s of scopes | keys" [value]="s.value">
                        {{'invoiceReport.scopeType.' + s.value | translate}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="ml-3">
                <mat-select #resultViewTypeSelector
                            placeholder="{{'invoiceReport.steps.stepOne.resultViewTypeSelector' | translate}}"
                            [formControl]="resultView">
                    <ng-container *ngFor="let v of resultViews | keys">
                        <mat-option [value]="v.value" *permissionOverlay="permissionMap.get(v.value)"
                                    [disabled]="permissionMap.get(v.value) | doesNotHavePermission">
                            {{'invoiceReport.resultViewType.' + v.value | translate}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="ml-3">
                <mat-select #currencySelector
                            placeholder="{{'invoiceReport.steps.stepOne.currencySelector' | translate}}"
                            [formControl]="currency">
                    <mat-option *ngFor="let v of currencies | keys" [value]="v.value">
                        {{v.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="button-row">
                <button mat-raised-button color="primary"
                        [disabled]="scope.invalid || resultView.invalid || currency.invalid"
                        (click)="goToFilterStep(stepOne)">
                    {{'invoiceReport.steps.stepOne.nextFilter' |
                    translate}}
                </button>
            </div>
        </mat-step>

        <mat-step #stepTwo [completed]="filterStepCompleted()">
            <ng-template matStepLabel>
                {{'invoiceReport.steps.stepTwo.label' | translate}}
                <span class="selection-list">{{getParameterLabel()}}</span>
                <span class="selection-list-second"
                      *ngIf="filterControl.value.resolution">| {{'global.resolutions.' + filterControl.value.resolution | translate}}</span>
                <span class="selection-list-second"
                      *ngIf="['COLUMNDIAGRAM', 'PIECHART'].indexOf(resultView.value) > -1 && filterControl.value.unitCost != null">| {{'invoiceReport.unitCostSelector.' + filterControl.value.unitCost | translate}}</span>
            </ng-template>

            <jhi-filters *ngIf="hasAnyOfThePermissions" [scope]="scope.value" [resultView]="resultView.value"
                         [formControl]="filterControl"></jhi-filters>

            <div class="button-row">
                <button mat-raised-button color="primary" type="button" [disabled]="showDisabled()"
                        (click)="goToStep3(false);">{{'invoiceReport.steps.stepTwo.show' | translate}}
                </button>
                <button mat-raised-button color="primary" type="button" [hidden]="compareUnavailable()"
                        [disabled]="filterControl.invalid || compareDisabled()"
                        (click)="goToStep3(true);">{{'invoiceReport.steps.stepTwo.compare' | translate}}
                </button>
            </div>
        </mat-step>

        <mat-step #stepThree [completed]="false">
            <ng-template matStepLabel>{{'invoiceReport.steps.stepThree.label' | translate}}</ng-template>

            <jhi-table *ngIf="tableData" [data]="tableData"></jhi-table>

            <jhi-invoice-column-diagram *ngIf="invoiceReportResponseByInterval"
                                        [chartData]="invoiceReportResponseByInterval"
                                        [unitCost]="filterControl.value.unitCost"></jhi-invoice-column-diagram>

            <jhi-invoice-pie-chart *ngIf="pieChartData"
                                   [chartData]="pieChartData"
                                   [unitCost]="filterControl.value.unitCost"></jhi-invoice-pie-chart>

            <jhi-invoice-file *ngIf="tableData || invoiceReportResponseByInterval || pieChartData"
                              [invoices]="invoices"></jhi-invoice-file>
        </mat-step>

    </mat-vertical-stepper>

</mat-card>


<ngx-loading [show]="loadingOverlay"></ngx-loading>
