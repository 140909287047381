import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {ToastMessageModel} from '../../shared/dto/toast-message.model';

@Injectable({providedIn: 'root'})
export class BootstrapAlertService {

    messageId: number = 0;
    private alertEvent: Subject<ToastMessageModel> = new Subject();

    constructor() {
    }

    getAlertEvent() {
        return this.alertEvent.asObservable();
    }

    showError(message: string) {
        let toast: ToastMessageModel = {
            id: this.messageId++,
            type: 'danger',
            message: message,
            messageLocalized: ''
        };

        this.alertEvent.next(toast);
    }

    showSuccess(messageLocalized: string) {
        let toast: ToastMessageModel = {
            id: this.messageId++,
            type: 'success',
            message: '',
            messageLocalized: messageLocalized
        };

        this.alertEvent.next(toast);
    }

    showWarning(messageLocalized: string) {
        let toast: ToastMessageModel = {
            id: this.messageId++,
            type: 'warning',
            message: '',
            messageLocalized: messageLocalized
        };

        this.alertEvent.next(toast);
    }

    showSuccessAdmin(message: string) {
        let toast: ToastMessageModel = {
            id: this.messageId++,
            type: 'success',
            message: message,
            messageLocalized: ''
        };

        this.alertEvent.next(toast);
    }
}
