import {Component, OnInit, ViewChild} from '@angular/core';
import {FilterService, SortEvent, SortMeta} from 'primeng/api';
import {OfferClaimHistoryDto} from '../../shared/offer-claim/model/OfferClaimHistoryDto';
import {DeliveryPeriod} from '../../shared/dto/delivery.periods';
import {PurchaseStatus} from '../../shared/offer-claim/model/purchase-status.enum';
import {DateIntervalFilter, NumberFilter} from '../filter.type';
import {TranslateService} from '@ngx-translate/core';
import {Table} from 'primeng/table';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {ActiveOfferDto} from '../../shared/offer-claim/model/ActiveOfferDto';
import {CoverageTransactionsAdminService} from '../coverage-transactions.admin.service';
import * as _ from 'lodash';
import {SaleStatus} from '../../shared/offer-claim/model/sale-status.enum';
import {Observable} from "rxjs";
import {UntypedFormControl} from "@angular/forms";
import {map, startWith} from "rxjs/operators";

@Component({
    selector: 'jhi-active-offers',
    templateUrl: './active-offers.component.html',
    styleUrls: ['./active-offers.component.scss']
})
export class ActiveOffersComponent implements OnInit {

    public readonly TABLE_SORT_ASC: number = 1;
    public readonly TABLE_SORT_DESC: number = -1;

    private readonly DATE_FILTER_FIELDS: string[] = ['startDate', 'endDate', 'evaluationDeadline'];
    private readonly NUMBER_FILTER_FIELDS: string[] = ['price', 'quantity', 'netSum'];

    public activeOfferClaimDto: ActiveOfferDto[] = [];
    public deliveryPeriods: DeliveryPeriod[] = [];
    public partners: string[] = [];
    public dealIds: string[] = [];
    public products: string[] = [];
    public productTypes: string[] = [];
    public purchaseStatuses: PurchaseStatus[] = [];
    public statusList: SaleStatus[];

    public dateFilterMap: Map<string, DateIntervalFilter> = new Map<string, DateIntervalFilter>();
    public numberFilterMap: Map<string, NumberFilter> = new Map<string, NumberFilter>();

    @ViewChild('activeOffersTable', {static: true})
    private activeOffersTable: Table;

    filteredDealIds: Observable<string[]> = new Observable<string[]>();
    dealIdControl = new UntypedFormControl('');

    constructor(private translate: TranslateService,
                private coverageTransactionsAdminService: CoverageTransactionsAdminService,
                private filterService: FilterService) {
    }

    ngOnInit(): void {
        this.DATE_FILTER_FIELDS.forEach((field: string) =>
            this.dateFilterMap.set(field, {start: undefined, end: undefined}));
        this.NUMBER_FILTER_FIELDS.forEach((field: string) =>
            this.numberFilterMap.set(field, {min: undefined, max: undefined}));

        this.dateFilterMap.forEach((filter: DateIntervalFilter, field: string): void => {
            this.filterService.register('dateBetween-' + field, (value): boolean => {
                if (value === undefined || value === null) {
                    return false;
                }

                let startMatches: boolean = true;
                let endMatches: boolean = true;

                if (!!filter.start && this.toDate(filter.start) > value) {
                    startMatches = false;
                }
                if (!!filter.end && this.toDate(filter.end) < value) {
                    endMatches = false;
                }

                return startMatches && endMatches;
            });
        });

        this.numberFilterMap.forEach((filter: NumberFilter, field: string): void => {
            this.filterService.register('numberBetween-' + field, (value): boolean => {
                if (value === undefined || value === null) {
                    return false;
                }

                let startMatches: boolean = true;
                let endMatches: boolean = true;

                if (!!filter.min && filter.min > value) {
                    startMatches = false;
                }
                if (!!filter.max && filter.max < value) {
                    endMatches = false;
                }

                return startMatches && endMatches;
            });
        });

        this.getActiveOffers();
    }

    private _filterDealIds(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.dealIds.filter(option => option.toLowerCase().includes(filterValue));
    }

    public onKeyDown(event: any): void {
        // not allow negative prices
        if (event.key === '-' || (this.translate.currentLang === 'hu' && event.key === '.')) {
            event.preventDefault();
        }
    }

    public translateProductType(productType: string) {
        return this.translate.instant('agreement.agreementData.services.productTypeValues.' + productType);
    }

    public translateSaleStatus(status: string) {
        return this.translate.instant('activeOffers.saleStatus.' + status);
    }

    public translatePurchaseStatus(status: string) {
        return this.translate.instant('activeOffers.purchaseStatus.' + status);
    }

    public sortOverview(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const multiSortMeta: SortMeta[] = event.multiSortMeta;
            let result: number = null;

            if (multiSortMeta === undefined) {
                return this.sortField(event.order, event.field, data1, data2);
            } else {
                multiSortMeta.forEach((meta: SortMeta): void => {
                    let metaResult: number = this.sortField(meta.order, meta.field, data1, data2);
                    result = result || metaResult;
                });
            }

            return result;
        });
    }

    public getProductName(activeOffer: ActiveOfferDto): string {
        if (activeOffer.product.includes("Custom")) {
            const type = activeOffer.product.replace('Custom', '');
            return `CUSTOM-${new Date(activeOffer.startDate).getFullYear()} HU ${type}`;
        }

        const productStringArray = activeOffer.product.split(' ');
        return `${productStringArray[2]}-${productStringArray[1]} HU ${productStringArray[0]}`;
    }

    private sortField(order: number, field: string, data1: OfferClaimHistoryDto, data2: OfferClaimHistoryDto): number {
        let result: number;

        let value1 = data1[field];
        let value2 = data2[field];
        if (field === 'saleStatus') {
            value1 = this.translateSaleStatus(data1[field]);
            value2 = this.translateSaleStatus(data2[field]);
        }
        if (field === 'productType') {
            value1 = this.translateProductType(data1[field]);
            value2 = this.translateProductType(data2[field]);
        }
        if (field === 'purchaseStatus') {
            value1 = this.translatePurchaseStatus(data1[field]);
            value2 = this.translatePurchaseStatus(data2[field]);
        }

        if (value1 == null && value2 != null) {
            result = -1;
        } else if (value1 != null && value2 == null) {
            result = 1;
        } else if (value1 == null && value2 == null) {
            result = 0;
        } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        } else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }

        return order * result;
    }

    private getActiveOffers(): void {
        this.coverageTransactionsAdminService.getActiveOffers().subscribe((activeOffers: ActiveOfferDto[]): void => {
            this.activeOfferClaimDto = activeOffers.map(offer => {
                offer.product = this.getProductName(offer);
                return offer;
            });
            this.createFilters(this.activeOfferClaimDto);

            this.filteredDealIds = this.dealIdControl.valueChanges.pipe(
                startWith(''),
                map(value => this._filterDealIds(value || '')),
            );
        });
    }

    private createFilters(activeOffers: ActiveOfferDto[]): void {
        activeOffers.forEach((activeOffer: ActiveOfferDto): void => {
            if (!_.includes(this.dealIds, activeOffer.dealId)) {
                this.dealIds.push(activeOffer.dealId);
            }
            if (!_.find(this.deliveryPeriods, ['id', activeOffer.deliveryPeriod.id])) {
                this.deliveryPeriods.push(activeOffer.deliveryPeriod);
            }
            if (!_.includes(this.partners, activeOffer.partner)) {
                this.partners.push(activeOffer.partner);
            }
            if (!_.includes(this.products, activeOffer.product)) {
                this.products.push(activeOffer.product);
            }
            if (!_.includes(this.productTypes, activeOffer.productType)) {
                this.productTypes.push(activeOffer.productType);
            }
        });
        this.partners.sort();
        this.products.sort();

        this.productTypes.sort((a: string, b: string) => this.translateProductType(a).localeCompare(this.translateProductType(b)));

        this.statusList = _.values(SaleStatus);
        this.statusList.sort((a: SaleStatus, b: SaleStatus) => this.translateSaleStatus(a).localeCompare(this.translateSaleStatus(b)));

        this.purchaseStatuses = _.values(PurchaseStatus);
        this.purchaseStatuses.sort((a: PurchaseStatus, b: PurchaseStatus) => this.translatePurchaseStatus(a).localeCompare(this.translatePurchaseStatus(b)));
    }

    private toDate(date: NgbDateStruct): string {
        return new Date(date.year, date.month - 1, date.day).toISOString();
    }
}





