<footer class="main">
    <section *ngIf="!reRender">
        <span class="footer-copyright" jhiTranslate="footer.copyright"></span>
        <span *ngIf="authenticated" class="footer-links">
            <a href="/api/files/privacy-policy" jhiTranslate="footer.privacyPolicy" target="_blank">Adatkezelési tájékoztató</a>
            <a href="/api/files/terms-of-use" jhiTranslate="footer.termsOfUse" target="_blank">Felhasználási feltételek</a>
            <a *ngIf="!isHedgeUrl.getValue()" href="/#/sitemap" jhiTranslate="footer.siteMap">Oldaltérkép</a>
            <a href="/#/contact" jhiTranslate="footer.contact">Kapcsolat</a>
        </span>
    </section>
</footer>
<template></template>
