import { PodModel } from "./pod.model";
import { CompanyModel } from "./company.model";

export class PodBookedPerformanceModel {
    pod: PodModel;
    partner: CompanyModel;
    bookedQuantity: number;
    startTime: Date;
    endTime: Date;
    id: number;
    limitPercentage: number;
    limitQuantity: number;
}
