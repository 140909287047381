import { Route } from '@angular/router';

import { UserRouteAccessService } from '../shared';
import { HomeComponent } from './home.component';
import { PartnerMandatoryGuard } from '../shared/partner-mandatory.guard';

export const HOME_ROUTE: Route[] = [
    {
        path: '',
        component: HomeComponent,
        data: {
            permission: '',
            pageTitle: 'main.link.homepage'
        },
        canActivate: [UserRouteAccessService, PartnerMandatoryGuard],
        outlet: 'home',
        pathMatch: 'full' as Route['pathMatch']
    }];
