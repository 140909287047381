import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[jhiTranslate]'
})
export class JhiTranslateDirective implements OnInit {
  @Input('jhiTranslate') key: string;

  constructor(private el: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService) {

  }

  ngOnInit() {
    if (!this.key)
      return;
      this.translateService.get(this.key).subscribe(r =>
          this.renderer.setProperty(this.el.nativeElement, 'innerHTML', r));
  }
}
