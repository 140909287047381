import {Component} from '@angular/core';
import {CanLeave, CanLeaveService} from '../../shared/can-leave/can-leave.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'admin-tabs',
    templateUrl: './admin.tabs.html'
})
export class AdminTabsComponent implements CanLeave {

    constructor(private canLeaveService: CanLeaveService) {
    }

    canLeave(): Observable<boolean> {
        return this.canLeaveService.canLeave();
    }

}
