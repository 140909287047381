import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { LinkByCategoryModel, LinkModel } from '../links';

import { LinkAdminModalComponent } from './links.admin.modal.component';
import {TranslateService} from "@ngx-translate/core";
import {NewsService} from '../shared/services/news.service';
import {DialogService} from '../shared/dialog/dialog.service';
import {PagerModel} from '../shared/dto/page.model';


@Component({
    selector: 'useful-links-admin',
    templateUrl: './link.html'
})
export class LinkComponent implements OnInit {
    public linkList: Array<LinkByCategoryModel>;

    constructor(private http: HttpClient,
                private modalService: NgbModal,
                public newsService: NewsService,
                private dialogService: DialogService,
                private translate: TranslateService) {
    }

    openModal(link: LinkModel) {
        if (!link)
            link = new LinkModel();

        const modalRef = this.modalService.open(LinkAdminModalComponent, {backdrop: 'static'});
        modalRef.componentInstance.link = link;
        // modalRef.componentInstance.categories = this.categories;
        modalRef.componentInstance.modalClose.subscribe(() => {
            modalRef.close();
            this.refreshLinks();
        });
    }


    ngOnInit() {
        this.refreshLinks();
    }

    refreshLinks() {
        this.http.get<PagerModel<LinkByCategoryModel>>('/api/useful_link/admin').subscribe(r =>
            this.linkList = r.content
        );
    }

    deleteLink(article: LinkModel) {
        this.dialogService.confirm(this.translate.instant('filter.basic.deleteDialog.title'), this.translate.instant('filter.basic.deleteDialog.message'))
            .subscribe((value: boolean): void => {
                if (value) {
                    this.deleteConfirmed(article);
                }
            });
    }

    deleteConfirmed(article: LinkModel): void {
        this.http.delete('/api/useful_link/admin/' + article.id).subscribe(r => this.refreshLinks());
    }

    publishLink(item: LinkModel, visible: boolean) {
        this.http.patch('/api/useful_link/admin/' + item.id + '/visible/' + visible, '').subscribe(() => {
            item.visible = visible;
            if (!item.releaseTime)
                item.releaseTime = new Date();
        });
    }
}
