import { ElementRef } from '@angular/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';

export function init() {
    calculateGraphPath();
    getSeriesExtremes();
    clearForceChartBoostCache();
    angularHighChartsExportFix();
}

function getSeriesExtremes() {
  const oldExtremeFn = (Highcharts.Axis.prototype as any).getSeriesExtremes;

  (Highcharts.Axis.prototype as any).getSeriesExtremes = function () {
    oldExtremeFn.call(this);
    if (this.isXAxis) {
      this.series.forEach(series => {
        if (series.options.step === 'timeseries' && series.options.pointInterval && series.xData.length) {
          const newMax = series.xData[series.xData.length - 1] + series.options.pointInterval;
          if (this.dataMax < newMax) {
            this.dataMax = newMax;
          }
        }
      });
    }
  };
}

function clearForceChartBoostCache() {
  const oldZoomFn = (Highcharts.Chart.prototype as any).zoom;

  (Highcharts.Chart.prototype as any).zoom = function (mouseEvent) {
    this.boostForceChartBoost = undefined;
    oldZoomFn.call(this, mouseEvent);
  };
}

function calculateGraphPath() {
  const lineSeries = (Highcharts as any).seriesTypes.line;
  const oldLineGraphPathFn = lineSeries.prototype.getGraphPath;

  lineSeries.prototype.getGraphPath = function (points, nullsAsZeroes, connectCliffs) {
    if (this.options.step !== 'timeseries') {
      return oldLineGraphPathFn.call(this, points, nullsAsZeroes, connectCliffs);
    }

    let series = this,
      options = series.options,
      reversed,
      graphPath = [],
      xMap = [],
      gap;

    points = points || series.points;

    reversed = points.reversed;
    if (reversed) {
      points.reverse();
    }

    if (options.connectNulls && !nullsAsZeroes && !connectCliffs) {
      points = this.getValidPoints(points);
    }

    points.forEach(function (point, i) {
      let plotX = point.plotX,
        plotY = point.plotY,
        lastPoint = points[i - 1],
        pathToPoint;

      if (
        (point.leftCliff || (lastPoint && lastPoint.rightCliff)) &&
        !connectCliffs
      ) {
        gap = true;
      }

      if (point.isNull && !nullsAsZeroes) {
        gap = true;
        if (lastPoint && !lastPoint.isNull) {
          graphPath.push('L', point.plotX, lastPoint.plotY);
          xMap.push(point.x);
        }
      } else {
        if (i === 0 || gap) {
          pathToPoint = ['M', point.plotX, point.plotY];
        } else {
          pathToPoint = [
            'L',
            plotX,
            lastPoint.plotY
          ];
          pathToPoint.push('L', plotX, plotY);
        }

        xMap.push(point.x, point.x);

        graphPath.push.apply(graphPath, pathToPoint);
        gap = false;
      }
    });

    if (points.length > 0 && series.options.pointInterval) {
      const point = points[points.length - 1];
      if (!point.isNull) {
        const lastX = series.xAxis.translate(point.x + series.options.pointInterval, 0, 0, 0, 1);
        graphPath.push('L', lastX, point.plotY);
        xMap.push(lastX);
      }
    }

    (<any>graphPath).xMap = xMap;
    series.graphPath = graphPath;

    return graphPath;
  };
}

function angularHighChartsExportFix() {
    Chart.prototype.init = function (el: ElementRef) {
        Highcharts.chart(el.nativeElement, this.options, chart => {
            if (!this.ref) {
                this.refSubject.next(chart);
                this.ref = chart;
                this.refSubject.complete();
            }
        });
    };
}
