import {ProductType} from "./product-type.enum";
import {AvailabilityStatus} from './availability-status.enum';

export class DailyRatesDto {
    name: string;
    type: ProductType;
    validFrom: Date;
    validTo: Date;
    availableFrom: Date;
    availableTo: Date;
    currentPrice: number | string;
    previousPrice: number | string;
    previousPriceDMinus7: number | string;
    previousPriceDMinus28: number | string;
    previous7BusinessDayPrices: number[];
    expirationEmail: Date;
    hasPeriodForRate: boolean;
    withoutPrice: boolean;
    availabilityStatus: AvailabilityStatus;
    illiquid: boolean;
}
