import {AgreementModel} from './agreement.model';

export class DeliveryPeriod {
    startTime: Date;
    startTimeNgB: any;
    endTime: Date;
    endTimeNgB: any;
    id: number;
    contractedQuantityMWh: number;
    price: string;
    priceFormula: string;
    priceOfGoo: string;
    salesforceDeliveryPeriodId: string;
    multiplicative: number;
    additiveSbs: number;
    serviceFee: number;
    invoicePriceElement: number;
    agreement: AgreementModel;
    hedgeLimit: number;
}
