import { Injectable } from '@angular/core';
import { DocumentByCategoryModel } from '../dto/documentCategory.model';
import {DocumentModel} from "../dto/document.model";

@Injectable()
export class DocumentFilterService {

    filterList(docList: DocumentByCategoryModel, filterText: string) {
        if (filterText === '') {
            docList.subCategories
                .map(r => {
                    r.contentList.map(s => s.show = true);
                    r.show = true;
                });
        } else {
            docList.subCategories
                .map(r => {
                    r.contentList.map(s => s.show =
                        s.title.toLowerCase().indexOf(filterText.toLowerCase()) > -1
                        || s.description.toLowerCase().indexOf(filterText.toLowerCase()) > -1
                    );
                    r.show = r.contentList.some(s => s.show);
                });
        }
    }

    getCssForFileExtension(extension: string) {
        if (extension) {
            if (extension.indexOf('pdf') > -1)
                return 'picture_as_pdf';
            if (extension.indexOf('word') > -1)
                return 'subject';
            if (extension.indexOf('image') > -1)
                return 'photo-camera';
        }
        return 'attachment';
    }
}
