import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../shared';
import { Principal } from '../shared/auth/principal.service';
import { StateStorageService } from '../shared/auth/state-storage.service';
import { CompanyModel } from '../shared/dto/company.model';
import { CompanySelectorService } from '../shared/services/company-selector.service';
import { Account } from '../shared/user/account.model';

@Component({
    selector: 'company-selector-page',
    templateUrl: './company-selector-page.component.html',
    styles: []
})
export class CompanySelectorPageComponent implements OnInit {

    currentUserAdmin: boolean = false;
    subComponentCanBeInited: boolean;

    constructor(private stateStorageService: StateStorageService,
                private router: Router,
                private http: HttpClient,
                private companySelectorService: CompanySelectorService,
                private principal: Principal) {

        principal.identity(true).then((account: Account) => {
            if (account !== null) {
                this.currentUserAdmin = account.groups.some(r => r === 1);
                this.subComponentCanBeInited = true;
            }
        });
    }

    ngOnInit() {
    }

    companySelected(company: CompanyModel) {

        this.http.post<User>('api/account/partner/' + company.id, null).subscribe(resp => {
            if (resp.login.length > 0) {
                this.stateStorageService.storeSelectedCompany(company);

                let previousState = this.stateStorageService.getPreviousState();
                if (previousState) {
                    this.stateStorageService.resetPreviousState();
                    this.router.navigate([previousState.name], {queryParams: previousState.params});
                }

                this.stateStorageService.storeEffectivePermissions(resp.effectivePermissions);
                this.companySelectorService.triggerCompanyChange(resp.effectivePermissions, company.accountName);
                this.router.navigate(['']);
            } else {
                // TODO hibakezelés
            }

        });

    }

}
