import { Component, OnInit } from '@angular/core';
import {InvoiceModel} from '../shared/dto/invoice.model';
import {PartnerService} from '../shared/services/partner.service';


@Component({
    selector: 'jhi-bills',
    templateUrl: './bills.component.html',
    styles: []
})
export class BillsComponent implements OnInit {

    invoices: InvoiceModel[];

    constructor(private partnerService: PartnerService) {
    }

    ngOnInit() {
    }

}
