<div class="input-group">
  <input class="form-control" [(ngModel)]="dpModel" name="{{id}}" id="{{id}}" ngbDatepicker #d="ngbDatepicker" (ngModelChange)="dpModelChange.emit(dpModel)"
    [disabled]="disabled" [dayTemplate]="cezDateDesign">
  <div class="input-group-append" *ngIf="!userView || !disabled">
    <button class="btn btn-outline-secondary" (click)="d.toggle(); click.emit()" type="button">
      <i class="fa fa-calendar" aria-hidden="true" role="button"></i>
    </button>
  </div>
  <div class="input-group-append" *ngIf="disabled">
    <button class="btn btn-outline-secondary" role="button" (click)="dpPencilClick.emit()" type="button">
      <i class="fa fa-pencil" aria-hidden="true"></i>
    </button>
  </div>
</div>

<ng-template #cezDateDesign let-date="date" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
  let-focused="focused">
  <span class="datepicker-template" [class.bg-primary-cez]="selected" [ngClass]="{'text-muted':date.month !== currentMonth,'opac05':date.month !== currentMonth, 'bg-primary-cez':selected}">
    {{ date.day }}
  </span>
</ng-template>
