import { Injectable, Injector } from '@angular/core';

@Injectable()
export class LocatorService {

    public static injector: Injector;

    constructor() {
    }
}
