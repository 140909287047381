import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'jhi-permission-overlay-popup',
    templateUrl: './permission-overlay-popup.component.html',
    styleUrls: ['./permission-overlay-popup.component.scss']
})
export class PermissionOverlayPopupComponent implements OnInit {

    title = 'global.dialog.alert.title';
    message = 'global.dialog.alert.message';
    ok = 'session-timer.warn-popup.ok';

    constructor() {
    }

    ngOnInit() {
    }

}
