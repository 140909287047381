import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {from, Observable, of, throwError} from 'rxjs';
import {mergeMap, tap} from 'rxjs/operators';
import {LoginService} from '../../login/login.service';
import {Principal} from '../auth';
import {ValidationException} from '../dto/validation.exception.model';
import {SessionTimerService} from '../session-timer/session-timer.service';
import {BootstrapAlertService} from './bootstrap-alert.service';

@Injectable({providedIn: 'root'})
export class CezHttpInterceptorService implements HttpInterceptor {

    static readonly IGNORED_URLS: string[] = ['/api/offer-claim', '/api/offer-claim/validate', '/api/offer-claim/validate-finalizing-purchase', 'api/price-tracker/validate'];

    private sessionTimerService: SessionTimerService;

    constructor(private router: Router,
                private bootstrapAlertService: BootstrapAlertService,
                private loginService: LoginService,
                private translateService: TranslateService,
                private principal: Principal,
                private injector: Injector) {
        this.sessionTimerService = this.injector.get(SessionTimerService);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let headers = req.headers || new HttpHeaders();

        return (req.url === 'api/account' || req.url === 'api/logout' || req.url === 'api/authentication' ? of(this.principal.userIdentity) : from(this.principal.identity())).pipe(
            mergeMap(userIdentity => {
                if (userIdentity) {
                    headers = headers.set('Accept-Language', this.translateService.currentLang);
                }

                return next.handle(req.clone({
                    headers: headers,
                    withCredentials: true
                })).pipe(
                    tap(event => {
                        this.sessionTimerService.restartSession();
                    }, error => {
                        this.errorHandling(error, req);
                    })
                );
            })
        );
    }

    private errorHandling(res: any, req: any) {
        if (CezHttpInterceptorService.IGNORED_URLS.includes(req.url) || !!res?.error?.hidden) {
            return;
        }

        if (res.status === 400) {
            let errorObject = res.error;
            let message = this.translateService.instant(errorObject.violation ? errorObject.violation.errorCode : errorObject.message);
            this.bootstrapAlertService.showError(message);
        } else if (res.status === 403) {
            this.bootstrapAlertService.showError('This user has no sufficient privileges to perform this operation.\nModifications has not been saved!');
        } else if (res.status === 401 || res.message === 'Cannot read property \'_headers\' of undefined') {
            if (this.principal.isAuthenticated()) {
                this.loginService.logout('sessionExpired');
                this.router.navigate(['/login']);
            }
        } else if (res.status >= 500 && res.status < 600) {
            if (res.error && res.error.message) {
                this.bootstrapAlertService.showError(this.translateService.instant(res.error.message, {errorId: res.error.errorId}));
            } else {
                this.bootstrapAlertService.showError(res.message);
            }
        } else if (res.status === 422) {
            const errorObject: ValidationException = res.error;

            if (!res.error.hidden) {
                if (!errorObject.violations || errorObject.violations.length === 0) {
                    this.bootstrapAlertService.showError(this.translateService.instant('validation.unknown'));
                } else {
                    if (errorObject.violations.length === 1) {
                        const message = this.translateService.instant('validation.' + errorObject.violations[0].errorCode, errorObject.violations[0].args);
                        this.bootstrapAlertService.showError(message);
                    } else {
                        const message = errorObject.violations.map(violation => '<li>' + this.translateService.instant(
                            `validation.${violation.errorCode}`,
                            violation.args
                        ) + '</li>').join('');
                        this.bootstrapAlertService.showError('<ul class="m-0">' + message + '</ul>');
                    }
                }
            }
        }
        return throwError(res);
    }
}
