import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Log } from './log.model';

@Injectable()
export class LogsService {
    constructor(private http: HttpClient) {
    }

    changeLevel(log: Log): Observable<void> {
        return this.http.put<void>('management/logs', log);
    }

    findAll(): Observable<Log[]> {
        return this.http.get<Log[]>('management/logs');
    }
}
