export type TimeSeriesType =
    'PLANED_CONSUMPTION'
    |'PRODUCTION_SCHEDULE'
    |'CONSUMPTION_SCHEDULE'
    |'LATEST_SUBMITTED_CONSUMPTION_SCHEDULE'
    |'HEDGE_CONSUMPTION_PLAN'
    |'HOURLY_SHAPED_PRICES'
    |'OFFER_CLAIM_CONSUMPTION'
    |'DAILY_RATE_PRICE';

export const TimeSeriesType = {
    PLANED_CONSUMPTION: 'PLANED_CONSUMPTION' as TimeSeriesType,
    PRODUCTION_SCHEDULE: 'PRODUCTION_SCHEDULE' as TimeSeriesType,
    CONSUMPTION_SCHEDULE: 'CONSUMPTION_SCHEDULE' as TimeSeriesType,
    LATEST_SUBMITTED_CONSUMPTION_SCHEDULE: 'LATEST_SUBMITTED_CONSUMPTION_SCHEDULE' as TimeSeriesType,
    HEDGE_CONSUMPTION_PLAN: 'HEDGE_CONSUMPTION_PLAN' as TimeSeriesType,
    HOURLY_SHAPED_PRICES: 'HOURLY_SHAPED_PRICES' as TimeSeriesType,
    OFFER_CLAIM_CONSUMPTION: 'OFFER_CLAIM_CONSUMPTION' as TimeSeriesType,
    DAILY_RATE_PRICE: 'DAILY_RATE_PRICE' as TimeSeriesType
};
