import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LinkModel } from '../links';
import {Category, JhiLanguageHelper} from '../shared';
import {CategoryService} from '../shared/services/category.service';
import {ExcelImportResult} from "../shared/time-series/model/excel-import-result.model";

@Component({
    selector: 'ngbd-modal-content',
    templateUrl: "links.admin.modal.component.html"
})
export class LinkAdminModalComponent implements OnInit {
    @Input() categories: Category[];
    @Input() link: LinkModel;
    @Output() modalClose: EventEmitter<any> = new EventEmitter();

    selectedFile: File;
    selectedCategory: Category;
    isSaving: boolean = false;
    languages: any[];

    constructor(public activeModal: NgbActiveModal,
                private http: HttpClient,
                private categoryService: CategoryService, private languageHelper: JhiLanguageHelper) {
    }

    ngOnInit() {
        this.categoryService.getLinkCategories().subscribe(r => {
            this.categories = r;
            if (this.link.categories && this.link.categories[0])
                this.selectedCategory = this.categories.filter(cat => cat.id === this.link.categories[0].id)[0];
        });

        this.languageHelper.getAll().then((languages) => {
            this.languages = languages;
        });
    }

  public addFile(fileInput: HTMLInputElement) {
    fileInput.value = '';
    fileInput.click();
  }

  public onUpload(event: { file: File, success: boolean, response?: ExcelImportResult, error?: HttpErrorResponse }) {
    this.selectedFile = event.file;
  }


    onSubmit(form: NgForm) {
        this.isSaving = true;
        let link = <LinkModel>form.value;
        link.id = this.link.id;
        //link.language = "hu";
        let category = new Category();
        category.id = this.selectedCategory.id;
        link.categories = [category];

        if (link.id) {
            this.updateLink(link);
        } else
            this.createLink(link);
    }

    createLink(link: LinkModel) {
        this.http.post<LinkModel>('/api/useful_link/admin', link).subscribe(r => {
            if (this.selectedFile) {
                let linkId = r.id;
                let data = new FormData();
                data.append("file", this.selectedFile);
                this.http.post('/api/useful_link/admin/' + linkId + '/picture', data).subscribe(() =>
                    this.modalClose.emit());
            } else
                this.modalClose.emit();
            this.isSaving = false;
        });
    }

    updateLink(link: LinkModel) {
        this.http.put("/api/useful_link/admin", link).subscribe(r => {
            if (this.selectedFile ) {
                let data = new FormData();
                data.append("file", this.selectedFile);
                this.http.post('/api/useful_link/admin/' + link.id + '/picture', data).subscribe(() => this.modalClose.emit());
            } else
                this.modalClose.emit();
            this.isSaving = false;
        });
    }
}
