import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subject, Subscription} from 'rxjs';

export interface FilterBasicState {

}

export interface FilterEvent {
    type: 'avg' | 'min' | 'max' | 'performance' | 'schedule';
    value: any;
}

@Injectable()
export class ConsumptionDynamicFilterService {

    public diagramSelectionChangeSubject: Subject<void> = new Subject<void>();
    public filterChangedSubject: Subject<FilterEvent> = new Subject<FilterEvent>();
    public filterBasicChangedSubject: Subject<FilterEvent> = new Subject<FilterEvent>();


    constructor(private translateService: TranslateService) {
    }

    public getResolutionList(): any[] {
        return [
            {
                value: 'MIN_15',
                description: this.translateService.instant('filter.basic.min15'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_QUARTER_HOURLY',
                tsValue: ''
            },
            {
                value: 'HOUR',
                description: this.translateService.instant('filter.basic.hour'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_HOURLY'
            },
            {
                value: 'DAY',
                description: this.translateService.instant('filter.basic.day'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_DAILY'
            },
            {
                value: 'WEEK',
                description: this.translateService.instant('filter.basic.week'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_WEEKLY'
            },
            {
                value: 'MONTH',
                description: this.translateService.instant('filter.basic.month'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_MONTHLY'
            },
            {
                value: 'QUARTER_OF_YEAR',
                description: this.translateService.instant('filter.basic.quarterOfYear'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_QUARTER_YEARLY'
            },
            {
                value: 'YEAR',
                description: this.translateService.instant('filter.basic.year'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_YEARLY'
            }
        ];
    }

    public diagramSelectionChanged(selectedDiagram?: any): void {
        this.diagramSelectionChangeSubject.next(selectedDiagram);
    }

    public filterChanged(filterEvent: FilterEvent) {
        this.filterChangedSubject.next(filterEvent);
    }

    public filterBasicChanged(filterEvent: FilterEvent) {
        this.filterBasicChangedSubject.next(filterEvent);
    }
}
