import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DOCUMENT_ADMIN_ROUTE, DocumentAdminComponent } from './index';
import { RouterModule } from '@angular/router';
import { DocumentModalComponent } from "./document.admin.modal.component";
import {CezPortalSharedModule} from '../shared';


@NgModule({
    imports: [CommonModule, FormsModule, CezPortalSharedModule, RouterModule.forRoot(DOCUMENT_ADMIN_ROUTE, { useHash: true })],
    declarations: [DocumentAdminComponent, DocumentModalComponent],
    exports: [
        DocumentAdminComponent
    ]
})
export class DocumentAdminModule { }
