import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Moment } from 'moment';
import { IntervalUtil } from './interval-util';

@Component({
    selector: 'day-selector',
    templateUrl: 'day-selector.component.html'
})
export class DaySelectorComponent {


    @Input() set selectedDate(selectedDate: Moment) {
        if (IntervalUtil.differentMoments(selectedDate, this.dateControl.value)) {
            this.dateControl.setValue(selectedDate);
            this.dateChanged(selectedDate);
        }
    }

    @Output() selectedDateChange: EventEmitter<Moment> = new EventEmitter();

    @Input() placeholder: string;

    dateControl: UntypedFormControl;

    constructor() {
        this.dateControl = new UntypedFormControl();
    }

    dateChanged(value: Moment) {
        this.selectedDateChange.emit(value ? value.clone() : null);
    }
}
