import {AfterViewInit, Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';


import {PasswordResetFinish} from './password-reset-finish.service';
import {AbstractControl} from '@angular/forms';

@Component({
    selector: 'jhi-password-reset-finish',
    templateUrl: './password-reset-finish.component.html'
})
export class PasswordResetFinishComponent implements OnInit, AfterViewInit {
    confirmPassword: string;
    keyMissing: boolean;
    resetAccount: any;
    success: string;
    modalRef: NgbModalRef;
    key: string;

    constructor(
        private passwordResetFinish: PasswordResetFinish,
        private router: Router,
        private route: ActivatedRoute,
        private elementRef: ElementRef, private renderer: Renderer2
    ) {

    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.key = params['key'];
        });
        this.resetAccount = {};
        this.keyMissing = !this.key;
    }

    ngAfterViewInit() {
        if (this.elementRef.nativeElement.querySelector('#password') != null) {
            //this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#password'), 'focus', []);
            this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('#password')).focus();
        }
    }

    finishReset() {
        this.success = null;
        this.passwordResetFinish.save({key: this.key, newPassword: this.resetAccount.password}).subscribe(() => {
            this.success = 'OK';
        });
    }

    confirmationInvalid(control: AbstractControl): boolean {
        const password = control.parent.controls['password'].value;
        const confirmPassword = control.parent.controls['confirmPassword'].value;
        return password !== confirmPassword;
    }

    login() {
        this.router.navigate(['/login']);
    }
}
