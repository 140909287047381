import { NestedTreeControl } from '@angular/cdk/tree';
import { Component } from '@angular/core';
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {MenuModel} from '../layouts/navbar/menu-model';
import {MenuService} from '../shared/services/menu.service';
import {StateStorageService} from '../shared/auth';

@Component({
    selector: 'jhi-site-map',
    templateUrl: './site-map.component.html',
    styleUrls: ['site-map.component.scss']
})
export class SiteMapComponent {

    nestedTreeControl: NestedTreeControl<MenuModel> = new NestedTreeControl<MenuModel>(node => node.ChildLinks);
    nestedDataSource: MatTreeNestedDataSource<MenuModel> = new MatTreeNestedDataSource();

    constructor(private menuService: MenuService, private storage: StateStorageService) {
        this.nestedDataSource.data = this.menuService.getMenuItems(storage.getEffectivePermissions() || []);
    }

    hasNestedChild = (_: number, nodeData: MenuModel) => nodeData.ChildLinks && nodeData.ChildLinks.length > 0;
}
