<ul ngbNav #nav="ngbNav" class="nav-tabs" tabSave="coverate-transactions.admin" #tabSet  #tabSave="tabSave"    style="display: flex; justify-content: center;" >
  <li [ngbNavItem]="'tasks-tab'">
    <a ngbNavLink>{{ 'coverageTransactions.tabs.admin.tasks.title' | translate }}</a>
    <ng-template ngbNavContent>
      <jhi-tasks *ngIf="tabSave.tabLoaded"></jhi-tasks>
    </ng-template>
  </li>
  <li [ngbNavItem]="'active-offers'">
    <a ngbNavLink>{{ 'coverageTransactions.tabs.admin.activeOffers.title' | translate }}</a>
      <ng-template ngbNavContent>
          <jhi-active-offers *ngIf="tabSave.tabLoaded"></jhi-active-offers>
      </ng-template>
  </li>
  <li [ngbNavItem]="'buyingTab'">
    <a ngbNavLink>{{ 'coverageTransactions.tabs.admin.dailyPrices.title' | translate }}</a>
    <ng-template ngbNavContent>
        <jhi-daily-rates *ngIf="tabSave.tabLoaded"></jhi-daily-rates>
    </ng-template>
  </li>
  <li [ngbNavItem]="'coverageTransactions.tabs.admin.summaryTable.title'">
    <a ngbNavLink>{{ 'coverageTransactions.tabs.admin.summaryTable.title' | translate }}</a>
    <ng-template ngbNavContent>
        <jhi-offer-claim-history *ngIf="tabSave.tabLoaded"></jhi-offer-claim-history>
    </ng-template>
  </li>
  <li [ngbNavItem]="'coverageTransactions.tabs.admin.averagingTransactions.title'">
    <a ngbNavLink>{{ 'coverageTransactions.tabs.admin.averagingTransactions.title' | translate }}</a>
    <ng-template ngbNavContent>
        <jhi-averaging-transactions *ngIf="tabSave.tabLoaded"></jhi-averaging-transactions>
    </ng-template>
  </li>
  <li [ngbNavItem]="'coverageTransactions.tabs.admin.activePriceTrackings.title'">
    <a ngbNavLink>{{ 'coverageTransactions.tabs.admin.activePriceTrackings.title' | translate }}</a>
    <ng-template ngbNavContent>
      <jhi-active-price-trackings *ngIf="tabSave.tabLoaded"></jhi-active-price-trackings>
    </ng-template>
  </li>
  <li [ngbNavItem]="'coverageTransactions.tabs.admin.availableProductPeriods.title'">
    <a ngbNavLink>{{ 'coverageTransactions.tabs.admin.availableProductPeriods.title' | translate }}</a>
    <ng-template ngbNavContent>
      <jhi-available-products *ngIf="tabSave.tabLoaded"></jhi-available-products>
    </ng-template>
  </li>
  <li [ngbNavItem]="'coverageTransactions.tabs.admin.businessParameters.title'">
    <a ngbNavLink>{{ 'coverageTransactions.tabs.admin.businessParameters.title' | translate }}</a>
    <ng-template ngbNavContent>
      <jhi-business-parameters *ngIf="tabSave.tabLoaded"></jhi-business-parameters>
    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>
