import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, Validators} from '@angular/forms';

@Directive({
    selector: '[maxNumber]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxNumberInputDirective, multi: true }]
})
export class MaxNumberInputDirective implements Validator {

    @Input() maxNumber: number;

    validate(control: AbstractControl): { [key: string]: any } {
        return Validators.max(this.maxNumber)(control);
    }

}
