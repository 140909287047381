import { Route } from '@angular/router';

import { LinkTabsComponent } from './link-tabs.component';
import {UserRouteAccessService} from '../shared';

export const LINK_ROUTE: Route = {
  path: 'link',
  component: LinkTabsComponent,
  data: {
    permission: "USEFUL_LINK_READ",
    pageTitle: 'main.link.useful'
  },
  canActivate: [UserRouteAccessService]
};
