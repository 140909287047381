<div class="row" *ngFor="let di of intervalList; index as i; last as isLast">
    <div class="col">
        <monthly-date-interval [dateFrom]="intervalList[i].from" [dateTo]="intervalList[i].to"
                               (selectInterval)="setInterval($event, i)" intervalRestrictor>{{di}}
        </monthly-date-interval>

        <button [disabled]="intervalList.length < 2" mat-mini-fab color="primary" (click)="deleteInterval(i)">
            <mat-icon>delete</mat-icon>
        </button>

        <button class="ml-2" mat-mini-fab color="primary" *ngIf="isLast"
                matTooltip="{{'invoiceReport.steps.stepTwo.dateComponentTitle' | translate}}"
                (click)="addNextDateInterval(intervalList[i].to)"
                [disabled]="noMoreDates">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>






