import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DocumentModel } from '../dto/document.model';
import { DocumentByCategoryModel } from '../dto/documentCategory.model';
import { DocumentFilterService } from '../services/document.filter.service';

@Component({
    selector: 'cez-document-list',
    templateUrl: './document-list.component.html',
    styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent implements OnChanges {

    @Input() documentByCategory: DocumentByCategoryModel;
    @Input() adminMode: boolean;
    @Input() filterText: string;
    @Output() editClick: EventEmitter<DocumentModel> = new EventEmitter();
    @Output() deleteClick: EventEmitter<DocumentModel> = new EventEmitter();

    constructor(public documentFilterService: DocumentFilterService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['documentByCategory'] || changes['filterText']) {
            this.doFilter();
        }
    }

    private doFilter() {
        if (this.documentByCategory && this.documentByCategory.subCategories) {
            this.documentFilterService.filterList(this.documentByCategory, this.filterText || '');
        }
    }
}
