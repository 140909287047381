<mat-card>
    <mat-card-title class="d-flex">
        <div class="w-100">
            <div class="w-100">
                {{ 'podMaintenance.title' | translate }}
                <button mat-raised-button
                        [matTooltip]="'podMaintenance.tooltipForImport' | translate"
                        color="primary"
                        (click)="podAgreementAssignmentFileInput.click()"
                        type="button"
                        class="btn float-right">
                    <i class="material-icons">find_in_page</i>
                    {{ 'podMaintenance.button.import' | translate }}
                </button>
                <button mat-raised-button
                        class="btn btn-primary text-white float-right mr-2"
                        type="button"
                        (click)="generatePodAgreementAssignmentTemplate()">
                    <i class="material-icons">file_download</i>{{ 'agreement.exportTemplate' | translate }}
                </button>
            </div>
            <div class="ml-auto w-100">
                <cez-file-upload-queue #podAgreementAssignment [isMultipleFiles]="false">
                    <cez-file-upload [file]="file" [id]="i"
                                     [httpUrl]="'/api/pod-maintenance/import-async'"
                                     (onUpload)="podAgreementAssignmentUploaded($event)"
                                     [autoUpload]="true"
                                     *ngFor="let file of podAgreementAssignment.files; let i = index"></cez-file-upload>
                </cez-file-upload-queue>
                <input #podAgreementAssignmentFileInput [hidden]="true" multiple type="file"
                       [fileUploadInputFor]="podAgreementAssignment"
                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
            </div>
        </div>
    </mat-card-title>

    <mat-card-content>
        <p-table #table
                 [value]="podMaintenanceViews"
                 [lazy]="true"
                 [paginator]="true"
                 [rows]="ROWS_PER_PAGE"
                 [totalRecords]="totalRecords"
                 (onLazyLoad)="lazyLoadPodAgreementAssignments($event)"
                 [filterDelay]="700"
                 sortMode="multiple"
                 [multiSortMeta]="[{field: 'accountName', order: 1}, {field: 'startTime', order: 1}]"
                 class="table table-striped">

            <ng-template pTemplate="header">
                <tr>
                    <th>
                        {{ 'podMaintenance.table.podCode' | translate }}
                    </th>
                    <th>
                        {{ 'podMaintenance.table.networkOperator' | translate }}
                    </th>
                    <th>
                        {{ 'podMaintenance.table.podType' | translate }}
                    </th>
                    <th>
                        {{ 'podMaintenance.table.address' | translate }}
                    </th>
                    <th class="p-highlight">
                        {{ 'podMaintenance.table.accountName' | translate }}
                        <p-sortIcon [field]="'accountName'"></p-sortIcon>
                    </th>
                    <th>
                        {{ 'podMaintenance.table.salesforceQuoteId' | translate }}
                    </th>
                    <th class="p-highlight">
                        {{ 'podMaintenance.table.startTime' | translate }}
                        <p-sortIcon [field]="'startTime'"></p-sortIcon>
                    </th>
                    <th>
                        {{ 'podMaintenance.table.endTime' | translate }}
                    </th>
                </tr>

                <tr>
                    <th>
                        <mat-form-field>
                            <input matInput
                                   placeholder="{{'global.search' | translate}}"
                                   (input)="table.filter($event.target.value, POD_CODE_FILTER_KEY, 'contains')"
                                   [formControl]="podCodeFilterControl">
                        </mat-form-field>
                    </th>
                    <th>
                        <mat-form-field>
                            <mat-select placeholder="{{'global.choose' | translate}}"
                                        (selectionChange)="table.filter($event.value, NETWORK_OPERATOR_FILTER_KEY, 'equals')"
                                        [formControl]="operatorFilterControl">
                                <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                                <mat-option [value]="'global.filter./NO_VALUE_PRESENT/' | translate">
                                    {{ 'global.filter./NO_VALUE_PRESENT/' | translate }}
                                </mat-option>
                                <mat-option *ngFor="let networkOperator of networkOperators"
                                            [value]="networkOperator">{{ networkOperator }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </th>
                    <th>
                        <mat-form-field>
                            <mat-select placeholder="{{'global.choose' | translate}}"
                                        (selectionChange)="table.filter($event.value, POD_TYPE_FILTER_KEY, 'equals')"
                                        [formControl]="podTypeFilterControl">
                                <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                                <mat-option [value]="'global.filter./NO_VALUE_PRESENT/' | translate">
                                    {{ 'global.filter./NO_VALUE_PRESENT/' | translate }}
                                </mat-option>
                                <mat-option *ngFor="let podType of podTypes"
                                            [value]="podType">{{ 'profileType.' + podType | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </th>
                    <th>
                        <mat-form-field>
                            <input matInput
                                   placeholder="{{'global.search' | translate}}"
                                   (input)="table.filter($event.target.value, ADDRESS_FILTER_KEY, 'contains')"
                                   [formControl]="addressFilterControl">
                        </mat-form-field>
                    </th>
                    <th>
                        <mat-form-field>
                            <mat-label>{{ 'global.choose' | translate }}</mat-label>
                            <input matInput
                                   type="text"
                                   [formControl]="accountNameFilterControl"
                                   [matAutocomplete]="auto"/>
                            <mat-autocomplete #auto="matAutocomplete"
                                              (optionSelected)="table.filter($event.option.value, ACCOUNT_NAME_FILTER_KEY, 'equals')">
                                <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                                <mat-option [value]="'global.filter./NO_VALUE_PRESENT/' | translate">
                                    {{ 'global.filter./NO_VALUE_PRESENT/' | translate }}
                                </mat-option>
                                <mat-option *ngFor="let accountName of filteredAccountNames"
                                            [value]="accountName">{{ accountName }}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </th>
                    <th>
                        <mat-form-field>
                            <input matInput
                                   placeholder="{{'global.search' | translate}}"
                                   (input)="table.filter($event.target.value, SF_ID_FILTER_KEY, 'contains')"
                                   [formControl]="agreementFilterControl">
                        </mat-form-field>
                    </th>
                    <th>
                        <jhi-date-picker placeholder="{{ 'global.from' | translate }}"
                                         [dpModel]="defaultStartTime"
                                         (dpModelChange)="table.filter($event, START_TIME_FILTER_KEY, 'biggerThanOrEqual')"/>
                    </th>
                    <th>
                        <jhi-date-picker placeholder="{{ 'global.to' | translate }}"
                                         [dpModel]="defaultEndTime"
                                         (dpModelChange)="table.filter($event, END_TIME_FILTER_KEY, 'lessThanOrEqual')"/>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <td>{{ rowData.podCode }}</td>
                    <td>{{ rowData.networkOperator }}</td>
                    <td>{{ rowData.podType ? ('profileType.' + rowData.podType | translate) : '' }}</td>
                    <td>{{ rowData.address }}</td>
                    <td>{{ rowData.accountName }}</td>
                    <td>{{ rowData.salesforceQuoteId }}</td>
                    <td>{{ rowData.startTime | cezDate }}</td>
                    <td>{{ rowData.endTime | cezDate }}</td>
                </tr>
            </ng-template>
        </p-table>
    </mat-card-content>
</mat-card>
