import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from '../agreement-details/agreement-enums.model';
import { CustomFile, CustomFileType } from '../dto/custom-file.model';

@Injectable({
    providedIn: 'root'
})
export class CustomFileService {

    constructor(private http: HttpClient) {
    }

    listFiles(): Observable<CustomFile[]> {
        return this.http.get<CustomFile[]>('/api/custom-file');
    }

    updateFileMeta(file: CustomFile) {
        return this.http.put('/api/custom-file', file);
    }

    saveFile(type: CustomFileType, file: any, fileName: string, note?: string, language?: Language) {
        const formData: FormData = new FormData();
        formData.append('file', file, fileName);

        return this.http.post('/api/custom-file', formData, {
            params: {
                type,
                note,
                language
            }
        });
    }

    deleteFile(id: number) {
        return this.http.delete(`/api/custom-file/${id}`);
    }
}
