import {Scheduling, SchedulingSettlementType} from '../agreement-details/agreement-enums.model';

export class AggreementServicesModel {
    id: number;
    cezScreenAccess: boolean = false;
    extensionOption: boolean = false;
    extensionDeadline: Date;
    extensionPeriodStart: Date;
    extensionPeriodEnd: Date;
    extensionDeadlineNgB: any;
    extensionPeriodStartNgB: any;
    extensionPeriodEndNgB: any;
    guaranteeOfOrigin: boolean = false;
    typeOfGoo: string;
    flexibilityOfGoo: boolean = false;
    popra: boolean;
    scheduling: Scheduling = null;
    schedulingSettlementType: SchedulingSettlementType;
    schedulingDeadlineDays: number;
    schedulingDeadlineTime: string;
    softSchedulingDeadline = false;
    measureUnit: string = 'MWh';
}
