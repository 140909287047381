<mat-card class="over-flow-scrollbar">

    <mat-card-title class="d-flex">
        <div>{{'invoice-resource.title' | translate}}</div>
        <div class="ml-auto button-row">
            <button color="primary" mat-mini-fab (click)="editInvoiceResource()"
                    *ngIf="'INVOICE_RESOURCE_WRITE' | hasPermission">
                <mat-icon>add</mat-icon>
            </button>
            <button color="primary" mat-mini-fab [disabled]="!selectedInvoiceResource"
                    *ngIf="'INVOICE_RESOURCE_WRITE' | hasPermission"
                    (click)="editInvoiceResource(selectedInvoiceResource)">
                <mat-icon>edit</mat-icon>
            </button>
            <button color="primary" mat-mini-fab [disabled]="!selectedInvoiceResource"
                    *ngIf="'INVOICE_RESOURCE_WRITE' | hasPermission"
                    (click)="deleteInvoiceResource(selectedInvoiceResource.resourceId)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </mat-card-title>

    <mat-card-content>
        <p-table #resourceList [value]="invoiceResources" [paginator]="true" [rows]="10"
                 sortMode="multiple" selectionMode="single" [(selection)]="selectedInvoiceResource"
                 class="table table-striped selectable-table">
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'resourceId'">{{'invoice-resource.table.resourceId' | translate}}
                        <p-sortIcon [field]="'resourceId'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'assignedId'">{{'invoice-resource.table.assignedId' | translate}}
                        <p-sortIcon [field]="'assignedId'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'nameEn'">{{'invoice-resource.table.nameEn' |
                        translate}}
                        <p-sortIcon [field]="'nameEn'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'nameHu'">{{'invoice-resource.table.nameHu' |
                        translate}}
                        <p-sortIcon [field]="'nameHu'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'resourceGroup'">{{'invoice-resource.table.resourceGroup' | translate}}
                        <p-sortIcon [field]="'resourceGroup'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'unit'">{{'invoice-resource.table.unit' | translate}}
                        <p-sortIcon [field]="'unit'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'shouldBeProcessed'">{{'invoice-resource.table.shouldBeProcessed' | translate}}
                        <p-sortIcon [field]="'shouldBeProcessed'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'usedInUnitCost'">{{'invoice-resource.table.usedInUnitCost' | translate}}
                        <p-sortIcon [field]="'usedInUnitCost'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'distributionProperty'">{{'invoice-resource.table.distributionProperty' | translate}}
                        <p-sortIcon [field]="'distributionProperty'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <td>{{rowData.resourceId}}</td>
                    <td>{{rowData.assignedId}}</td>
                    <td>{{rowData.nameEn}}</td>
                    <td>{{rowData.nameHu}}</td>
                    <td>{{'invoice-resource.resource-groups.' + rowData.resourceGroup | translate}}</td>
                    <td>{{rowData.unit}}</td>
                    <td>{{'invoice-resource.table.content.shouldBeProcessed.' + rowData.shouldBeProcessed | translate}}</td>
                    <td>{{'invoice-resource.table.content.usedInUnitCost.' + rowData.usedInUnitCost | translate}}</td>
                    <td>{{'invoice-resource.distribution-property-values.' + rowData.distributionProperty | translate}}</td>
                </tr>
            </ng-template>
        </p-table>
    </mat-card-content>
</mat-card>

