import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {TranslateService} from "@ngx-translate/core";
import {DocumentByCategoryModel} from '../shared/dto/documentCategory.model';
import {CompanyModel} from '../shared/dto/company.model';
import {Category} from '../shared';
import {DocumentFilterService} from '../shared/services/document.filter.service';
import {CategoryService} from '../shared/services/category.service';
import {DialogService} from '../shared/dialog/dialog.service';
import {PagerModel} from '../shared/dto/page.model';
import {DocumentModel} from '../shared/dto/document.model';
import {DocumentModalComponent} from './document.admin.modal.component';

@Component({
    selector: 'document-management-admin',
    templateUrl: './document.management.html'
})
export class DocumentAdminComponent implements OnInit {
    docList: Array<DocumentByCategoryModel>;
    selectedCompany: CompanyModel;
    filterText: string = "";
    categories: Category[];

    constructor(private http: HttpClient,
                private documentFilterService: DocumentFilterService,
                private modalService: NgbModal,
                private categoryService: CategoryService,
                private dialogService: DialogService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.categoryService.getDocumentsCategories().subscribe(r => {
            this.categories = r;
        });
    }

    selectedCompanyChange() {
        this.refreshDocs();
    }

    refreshDocs() {
        this.docList = new Array<DocumentByCategoryModel>();

        for (let category of this.categories) {

            let postObject = {
                partnerId: null,
                partnerSpecific: false
            };
            if (category.partnerSpecific) {
                postObject.partnerId = this.selectedCompany.id;
                postObject.partnerSpecific = true;
            }

            this.http.post("api/document/admin/search", postObject).subscribe(r => {
                let pager = <PagerModel<DocumentModel>>r;
                let subdocList = pager.content;

                let documentByCategory = new DocumentByCategoryModel();
                documentByCategory.categoryName = category.name;
                documentByCategory.show = true;
                documentByCategory.subCategories = [];

                category.subCategories.map(subCat => {
                    let documentBySubCategory = new DocumentByCategoryModel();
                    documentBySubCategory.categoryName = subCat.name;
                    documentBySubCategory.contentList = subdocList.filter(doc => doc.categories[0].id == subCat.id);
                    documentBySubCategory.show = true;

                    documentByCategory.subCategories.push(documentBySubCategory);
                });

                this.docList.push(documentByCategory);
            });
        }
    }

    openDoc(doc: DocumentModel) {
        if (!doc)
            doc = new DocumentModel();

        const modalRef = this.modalService.open(DocumentModalComponent, {backdrop: 'static'});
        modalRef.componentInstance.doc = doc;
        modalRef.componentInstance.categories = this.categories;
        modalRef.componentInstance.selectedCompany = this.selectedCompany;
        modalRef.componentInstance.modalClose.subscribe(() => {
            modalRef.close();
            this.refreshDocs();
        });
    }

    deleteDoc(doc: DocumentModel) {
        this.dialogService.confirm(this.translate.instant('filter.basic.deleteDialog.title'), this.translate.instant('filter.basic.deleteDialog.message'))
            .subscribe((value: boolean): void => {
                if (value) {
                    this.deleteConfirmed(doc);
                }
            });
    }

    deleteConfirmed(doc: DocumentModel): void {
        this.http.delete("/api/document/admin/" + doc.id).subscribe(r => {
            this.refreshDocs();
        });
    }
}
