import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'formatOrder' })
export class FormatOrderPipe implements PipeTransform {

    constructor(private translate: TranslateService) {
    }


    transform(numericValue: number, translateKey: string): string {
        let ordinalString;
        switch (this.translate.currentLang) {
            case 'hu':
                ordinalString = this.getHungarianOrdinal(numericValue);
                break;
            case 'en':
                ordinalString = this.getEnglishOrdinal(numericValue);
                break;
        }

        if (!translateKey) {
            return ordinalString;
        }

        return this.translate.instant(translateKey, {ordinal: ordinalString});
    }

    private getHungarianOrdinal(numericValue: number): string {
        return numericValue + ".";
    }

    private getEnglishOrdinal(numericValue: number): string {
        // 10-20 numbers all end with 'th' so no need to check anything else
        const twoDigit = numericValue % 100;
        if (twoDigit > 9 && twoDigit < 20) {
            return numericValue + 'th';
        }

        switch (numericValue % 10) {
            case 1:
                return numericValue + 'st';
            case 2:
                return numericValue + 'nd';
            case 3:
                return numericValue + 'rd';
            default:
                return numericValue + 'th';
        }
    }
}
