<div class="container-fluid">
    <mat-card *ngIf="'CUSTOM_FILE_WRITE' | hasPermission" class="row mb-3">
        <mat-card-content class="col-12">
            <div class="row">
                <div class="col-auto">
                    <button mat-mini-fab color="primary" (click)="addFile(fileInput)" type="button">
                        <mat-icon>add</mat-icon>
                    </button>
                    <input #fileInput [hidden]="true" type="file"/>
                </div>
                <mat-form-field class="col-lg-2 col-md-3">
                    <mat-select [(ngModel)]="selectedType" required placeholder="{{'customFileUpload.type' | translate}}">
                        <mat-option *ngFor="let type of types | keys" [value]="type.value">
                            {{('customFileUpload.types.' + type.value) | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="col-lg-2 col-md-3">
                    <input matInput placeholder="{{'customFileUpload.note' | translate}}" [(ngModel)]="note">
                </mat-form-field>
                <mat-form-field class="col-lg-2 col-md-3">
                    <mat-select [(ngModel)]="selectedLanguage"
                                placeholder="{{'customFileUpload.language' | translate}}">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let language of languages | keys" [value]="language.value">
                            {{('customFileUpload.languages.' + language.value) | translate}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <cez-file-upload class="col"
                                 *ngIf="fileInput.value"
                                 [file]="fileInput.files[0]"
                                 [httpUrl]="'/api/custom-file'"
                                 [httpRequestParams]="uploadParams()"
                                 (onUpload)="onUpload()"
                                 (removeEvent)="fileInput.value = ''"></cez-file-upload>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="row">
        <mat-card-actions *ngIf="'CUSTOM_FILE_WRITE' | hasPermission" class="d-flex">
            <div class="ml-auto button-row">
                <button color="primary" mat-mini-fab [disabled]="!selectedFile" (click)="editFile()">
                    <mat-icon>edit</mat-icon>
                </button>
                <button color="primary" mat-mini-fab [disabled]="!selectedFile" (click)="deleteFile()">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </mat-card-actions>

        <mat-card-content>
            <p-table #table
                     [value]="files"
                     sortMode="single"
                     sortField="uploadedAt"
                     (sortFunction)="customSort($event)"
                     [customSort]="true"
                     [sortOrder]="-1"
                     [autoLayout]="true"
                     selectionMode="single" [(selection)]="selectedFile"
                     class="table table-striped selectable-table">

                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'fileName'">{{'customFileUpload.fileName' | translate}}
                            <p-sortIcon [field]="'fileName'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'type'">{{'customFileUpload.type' | translate}}
                            <p-sortIcon [field]="'type'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'size'">{{'customFileUpload.size' | translate}}
                            <p-sortIcon [field]="'size'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'uploadedAt'">{{'customFileUpload.uploadedAt' | translate}}
                            <p-sortIcon [field]="'uploadedAt'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'uploadedBy'">{{'customFileUpload.uploadedBy' | translate}}
                            <p-sortIcon [field]="'uploadedBy'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'note'">{{'customFileUpload.note' | translate}}
                            <p-sortIcon [field]="'note'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'language'">{{'customFileUpload.language'| translate}}
                            <p-sortIcon [field]="'language'"></p-sortIcon>
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <mat-form-field>
                                <input matInput placeholder="{{'global.search' | translate}}"
                                       (input)="table.filter($event.target.value, 'fileName', 'contains')">
                            </mat-form-field>
                        </th>
                        <th>
                            <mat-form-field>
                                <mat-select placeholder="{{'global.search' | translate}}" (selectionChange)="table.filter($event.value, 'type', 'equals')">
                                    <mat-option style="opacity: 0.4; text-align: center">-</mat-option>
                                    <mat-option *ngFor="let type of types | keys" [value]="type.value">
                                        {{('customFileUpload.types.' + type.value) | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </th>
                        <th></th>
                        <th></th>
                        <th>
                            <mat-form-field>
                                <input matInput placeholder="{{'global.search' | translate}}"
                                       (input)="table.filter($event.target.value, 'uploadedBy', 'contains')">
                            </mat-form-field>
                        </th>
                        <th>
                            <mat-form-field>
                                <input matInput placeholder="{{'global.search' | translate}}"
                                       (input)="table.filter($event.target.value, 'note', 'contains')">
                            </mat-form-field>
                        </th>
                        <th>
                            <mat-form-field>
                                <mat-select placeholder="{{'global.search' | translate}}"
                                            (selectionChange)="table.filter($event.value, 'language', 'equals')">
                                    <mat-option style="opacity: 0.4; text-align: center">-</mat-option>
                                    <mat-option *ngFor="let language of languages | keys" [value]="language.value">
                                        {{('customFileUpload.languages.' + language.value) | translate}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                    <tr class="table-padding" [pSelectableRow]="rowData">
                        <td><a [href]="rowData.fileUrl" target="_blank">{{rowData.fileName}}</a></td>
                        <td>{{('customFileUpload.types.' + rowData.type) | translate}}</td>
                        <td>{{rowData.size | bytes}}</td>
                        <td>{{rowData.uploadedAt | date:'medium'}}</td>
                        <td>{{rowData.uploadedBy}}</td>
                        <td>{{rowData.note}}</td>
                        <td>{{!rowData.language ? '' : 'customFileUpload.languages.' + rowData.language | translate}}</td>
                    </tr>
                </ng-template>
            </p-table>

        </mat-card-content>
    </mat-card>
</div>
