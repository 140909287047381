<header class="docs">
    <h2>{{'news.admin' | translate}}</h2>
    <aside *ngIf="adminAccess">
        <button class="btn btn-primary" (click)="openModal()">{{'news.addNews' | translate}}</button>
    </aside>
</header>
<h2 *ngIf="unPublishedNews && unPublishedNews.length">{{'news.unpublished' | translate}}</h2>
<section class="newsList mb-3">
    <article *ngFor="let article of unPublishedNews" class="tile" style="overflow: hidden">
        <div class="col-xl-4 text-center">
            <img class="medium" src="{{newsService.getPicture(article.picture)}}" alt="">
        </div>
        <div class="col-xl-8 text-left">
            <div>
                <header>
                    <h4>{{article.title}}</h4>
                    <h6>{{article.releaseTime | cezDate}}</h6>
                </header>
                <p> {{article.summary || newsService.trimNewsText(article.text)}} </p>
            </div>
            <footer>
                <a class="link font-weight-bold" role="button" (click)="openNews(article)">{{'home.readMore' | translate}}</a>
                <div class="pull-right">
                    <button type="submit" class="btn btn-success btn-sm" role="button" (click)="publishNews(article, true)">
                        <span class="fa fa-cloud-upload"></span>
                    </button>
                    <button type="submit" class="btn btn-info btn-sm ml-1" (click)="openModal(article)">
                        <span class="fa fa-pencil"></span>
                    </button>
                    <button type="submit" class="btn btn-danger btn-sm ml-1" (click)="deleteNews(article)">
                        <span class="fa fa-remove"></span>
                    </button>
                </div>
            </footer>
        </div>
    </article>
</section>
<h2 *ngIf="news && news.length">{{'news.published' | translate}}</h2>
<section class="newsList">
    <article class="bb-1 mb-2" *ngFor="let article of news" style="overflow: hidden">

        <div class="pull-left">
            <a href="javascript:void(0)" (click)="openNews(article)">{{article.title}}</a>
            <h6>{{article.releaseTime | cezDate}}</h6>

        </div>
        <div class="pull-right">
            <button type="submit" class="btn btn-warning btn-sm" role="button" (click)="publishNews(article, false)">
                <span class="fa fa-cloud-download"></span>
            </button>
            <button type="submit" class="btn btn-info btn-sm ml-1" (click)="openModal(article)">
                <span class="fa fa-pencil"></span>
            </button>
            <button type="submit" class="btn btn-danger btn-sm ml-1" (click)="deleteNews(article)">
                <span class="fa fa-remove"></span>
            </button>
        </div>
    </article>
</section>

<div *ngIf="news">
    <div class="row justify-content-center">
        <ngb-pagination [collectionSize]="totalItems" [(page)]="page" (pageChange)="loadPage()"></ngb-pagination>
    </div>
</div>
