import {DecimalPipe} from '@angular/common';
import {Component, Input, OnInit} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {ConsumptionDiagramType} from '../../../consumption/consumption-diagram-type';
import {DiagramService} from '../../services/diagram.service';
import {Resolution} from '../model/resolution.enum';
import {TimeSeries} from '../model/timeseries';
import {Unit} from '../model/unit.enum';
import {ChartData} from './chart-data.model';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from '../../dialog/dialog.service';
import {TimeSeriesChartLineType} from '../model/chart-type.enum';
import moment from 'moment';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'jhi-time-series-chart',
    templateUrl: './time-series-chart.component.html',
    styleUrls: ['./time-series-chart.component.scss']
})
export class TimeSeriesChartComponent implements OnInit {

    @Input()
    public forceUnitLabel: string;

    @Input()
    public exportChartTitle: string | undefined;

    @Input()
    public sourceWidth: string | undefined;

    @Input()
    public sourceHeight: string | undefined;

    @Input()
    public fontSize: string | undefined;

    @Input()
    public timeSeriesChartLineType: TimeSeriesChartLineType = TimeSeriesChartLineType.LINE;

    chart: Chart;
    originalSeriesName: string;
    resolution: Resolution = Resolution.HOURLY;
    unit: Unit = Unit.MW;
    exportFileName: string;
    isFullscreenAllowed: boolean = false;
    chartData: Highcharts.SeriesOptions[] = [];

    private hiddenSeriesNames: Set<string> = new Set<string>();

    @Input()
    public set defaultUnit(defaultUnit: Unit) {
        this.unit = defaultUnit;
    }

    @Input()
    public set fullscreenAllowed(fullscreenAllowed: boolean) {
        this.isFullscreenAllowed = fullscreenAllowed;
    }

    constructor(private decimalPipe: DecimalPipe,
                private diagramService: DiagramService,
                private translateService: TranslateService,
                private dialogService: DialogService) {
    }

    ngOnInit() {
        this.chart = new Chart(this.defaultChartOptions);
        //console.log(this.chart);
    }

    deleteSeries(additionalSeriesNames: string[], deleteOriginalSeries: boolean = false) {
        this.chart.ref.zoomOut();
        if (deleteOriginalSeries) {
            this.removeChartSeriesByName(this.originalSeriesName);
        }
        additionalSeriesNames.forEach(name => {
            this.removeChartSeriesByName(name);
        });
    }

    addOriginalSeries(data: ChartData, chartType: string = 'line') {
        if (this.chart.ref.series.filter(s => s.name === this.originalSeriesName).length === 1) {
            this.chart.ref.series.filter(s => s.name === this.originalSeriesName)[0].remove();
            this.chartData = this.chartData
                .filter(s => s.name === this.originalSeriesName)
                .splice(0, 1);
        }
        this.addSeries(data.timeSeries, data.name, '#f24f00', 1, chartType);
        this.originalSeriesName = data.name;

        if (!this.forceUnitLabel && this.unit !== data.unit) {
            this.unit = data.unit;
            this.chart.ref.update({
                yAxis: {
                    min: 0,
                    title: {
                        text: data.unit
                    }
                }
            });
        }

        this.chart.ref.zoomOut();
        this.chart.ref.update({
                exporting: {
                    filename: data.name + ' ' + data.timeSeries.timeInterval.start + ' ' + data.timeSeries.timeInterval.end
                }
            }
        );
    }

    /*addNewSeries(data: ChartData, chartType: string = 'line') {
        this.removeChartSeriesByName(data.name);
        this.resolution = data.timeSeries.timeInterval.resolution;
        this.chart.ref.zoomOut();
        this.addSeries(data.timeSeries, data.name, '#007600', 1, chartType);
    }*/

    addNewSeries(data: ChartData, chartType: string = 'line', color: string = '#007600') {
        this.removeChartSeriesByName(data.name);
        this.resolution = data.timeSeries.timeInterval.resolution;
        this.chart.ref.zoomOut();
        this.addSeries(data.timeSeries, data.name, color, 1, chartType);
    }


    initChart(unit: Unit = Unit.HUF, chartType: string = 'line') {
        if (!this.forceUnitLabel && this.unit !== unit) {
            this.unit = unit;
            this.chart.ref.update({
                yAxis: {
                    min: 0,
                    title: {
                        text: unit
                    }
                }
            });
        }
    }

    addTimeSeries(data: ChartData, chartType: string = 'line', color: string = '#007600', removeExisting: boolean = false) {
        if (removeExisting) {
            this.removeChartSeriesByName(data.name);
        }
        this.resolution = data.timeSeries.timeInterval.resolution;
        this.chart.ref.zoomOut();
        this.addSeries(data.timeSeries, data.name, color, 1, chartType);
    }

    clearTimeSeries() {
        if (this.chart?.ref) {
            this.chart.ref.zoomOut();
            while (this.chart.ref.series.length != 0) {
                this.clear();
            }
        }
    }

    private clear() {
        this.chart.ref.series.forEach(s => {
            s.remove();
        });
        this.chartData = [];
    }

    private addSeries(timeSeries: TimeSeries, name: string, color: string, zIndex: number, chartType: string) {
        const serie: any = {
            type: chartType,
            data: timeSeries.data,
            name: name,
            color: color,
            fillOpacity: 0.2,
            zIndex: zIndex,
            pointStart: moment(timeSeries.timeInterval.start).valueOf(),
            pointIntervalUnit: this.diagramService.getPointIntervalUnit(timeSeries.timeInterval.resolution),
            pointInterval: this.diagramService.getPointInterval(timeSeries.timeInterval.resolution),
            step: 'timeseries',
            events: {
                show: (e) => {
                    this.hiddenSeriesNames.delete(name);
                },
                hide: (e) => {
                    this.hiddenSeriesNames.add(name);
                },
            },
            visible: !this.hiddenSeriesNames.has(name),
        };

        if (this.timeSeriesChartLineType === TimeSeriesChartLineType.DOTTED) {
            serie.lineWidth = 0;
            serie.marker = {
                symbol: 'circle',
                enabled: true,
                radius: 3.5
            };
            serie.states = {
                hover: {
                    lineWidthPlus: 0
                }
            };
        } else {
            serie.lineWidth = 2;
        }

        this.chartData.push(serie);
        this.chart.addSeries(serie, true, true);
    }

    private get defaultChartOptions() {
        const _this = this;

        const chartOptions: any = {
            chart: {
                zoomType: 'xy',
                style: {
                    fontFamily: '\'Nimbus\''
                },
                resetZoomButton: {
                    theme: {
                        opacity: 0.3,
                        states: {
                            hover: {
                                opacity: 1
                            }
                        }
                    }
                }
            },
            title: {
                text: null
            },
            yAxis: {
                min: 0,
                title: {
                    text: _this.forceUnitLabel || _this.unit
                },
                labels: {
                    formatter: function () {
                        return _this.decimalPipe.transform(this.value, '1.0-2');
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                lineWidth: 3,
                dateTimeLabelFormats: _this.diagramService.getDateTimeLabelFormat(),
                plotLines: []
            },
            lang: {
                decimalPoint: ','
            },
            tooltip: {
                // shared: true,
                useHTML: true,
                formatter: function () {
                    return _this.diagramService.getCommonTooltipFormatter(this, _this.forceUnitLabel || _this.unit, true, _this.resolution, ConsumptionDiagramType.SCHEDULING, false, '#000000');
                }
                // headerFormat: '<small>{point.key}</small><table>',
                // pointFormatter: function () {
                //     const format = '1.3-3';
                //     const valueFn = (value: number) => _this.decimalPipe.transform(value, format) + ' ' + 'MWh';
                //
                //     const series = `<tr style="color: ${this.color}"><td style="padding-right: 10px"><b>${this.series.name}</b></td><td style="text-align: right"><b>${valueFn(this.y)}</b> </td></tr>`;
                //     return `${series}`;
                // },
                // footerFormat: '</table>'
            },
            legend: {
                enabled: true
            },
            scrollbar: {
                enabled: false
            },
            time: {
                useUTC: false
            },
            boost: {
                enabled: true,
                useGPUTranslations: false
            },
            series: [],
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: false
                    }
                }
            },
            exporting: {
                menuItemDefinitions: {
                    fullscreen: {
                        onclick: () => {
                            this.dialogService.fullscreenChart(new Chart(chartOptions), this.chartData);
                        },
                        text: this.translateService.instant('entity.action.fullscreen'),
                    }
                },
                buttons: {
                    contextButton: {
                        menuItems: ['fullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                        align: 'left',
                        x: -10,
                        y: -10
                    }
                },
                filename: _this.exportFileName ? _this.exportFileName : 'Idosor',
                sourceWidth: _this.sourceWidth || 1800,
                sourceHeight: _this.sourceHeight || 660,
                scale: 1,
                chartOptions: {
                    title: {
                        style: {
                            fontSize: _this.fontSize || '12px'
                        },
                        text: _this.exportChartTitle || ''
                    },
                    xAxis: {
                        labels: {
                            style: {
                                fontSize: '10px'
                            }
                        },
                        title: {
                            style: {
                                fontSize: '10px'
                            }
                        },
                        plotLines: {
                            width: '2px'
                        }
                    },
                    yAxis: {
                        labels: {
                            style: {
                                fontSize: '10px'
                            }
                        },
                        title: {
                            style: {
                                fontSize: '10px'
                            }
                        }
                    },
                    legend: {
                        itemStyle: {
                            fontSize: '12px'
                        }
                    }
                }
            }
        };

        if (this.resolution) {
            chartOptions.tooltip.xDateFormat = this.diagramService.getXDateFormat(this.resolution);
        }

        return chartOptions;
    }

    private removeChartSeriesByName(name: string) {
        this.chart.ref.series.filter(s => s.name === name).forEach(s => {
            s.remove();
        });
        this.chartData = this.chartData.filter(s => s.name !== name);
    }

}
