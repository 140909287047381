import { Injectable } from '@angular/core';
import { PermissionOverlayDirective } from '../directives/permission-overlay.directive';

@Injectable({
    providedIn: 'root'
})
export class PermissionOverlayRefreshService {

    readonly REFRESH_INTERVAL = 200;

    private overlays = new Array<PermissionOverlayDirective>();

    constructor() {
        this.runRefresh();
    }

    register(component: PermissionOverlayDirective) {
        this.overlays.push(component);
    }

    unregister(component: PermissionOverlayDirective) {
        const index = this.overlays.indexOf(component);
        if (index > -1) {
            this.overlays.splice(index, 1);
        }
    }

    private runRefresh() {
        this.overlays.forEach(overlay => overlay.recalculateOverlayDimensions());
        setTimeout(() => this.runRefresh(), this.REFRESH_INTERVAL);
    }
}
