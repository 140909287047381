import {DeliveryPeriodDto} from '../shared/dto/delivery-period-dto';
import {AgreementModel} from '../shared/dto/agreement.model';
import {DeliveryPeriodForOffer} from '../shared/offer-claim/model/DeliveryPeriodForOffer';
import {PartnerDto} from '../shared/offer-claim/model/PartnerDto';
import {PartnerWithDeliveryPeriods} from '../shared/offer-claim/model/PartnerWithDeliveryPeriods';
import {OfferPeriod} from '../shared/offer-claim/model/offer-period.enum';
import {SaleStatus} from '../shared/offer-claim/model/sale-status.enum';
import {DateUtil} from '../core/util/date.util';
import {OfferClaimDto} from '../shared/offer-claim/model/OfferClaimDto';
import {OfferClaimSessionStore} from '../shared/offer-claim/model/OfferClaimSessionStore';
import {OfferClaimWithCurrentStatusDto} from '../shared/offer-claim/model/OfferClaimWithCurrentStatusDto';
import * as moment from 'moment';
import {Moment} from 'moment';
import {CartItemWithCurrentStatusDto} from '../shared/offer-claim/model/CartItemWithCurrentStatusDto';
import * as _ from 'lodash';
import {ClaimItemBaseModel} from '../shared/offer-claim/model/claim-item-base.model';

export interface EvaluationStatusConfig {
    icon: 'check_circle' | 'cancel';
    cssClass: 'mat-button-toggle--primary' | 'mat-button-toggle--dark';
}

export class CoverageTransactionsUtil {

    private static readonly EVALUATION_STATUS_CONFIG: Map<SaleStatus, EvaluationStatusConfig> = new Map<SaleStatus, EvaluationStatusConfig>([
        [SaleStatus.ACCEPTED, {icon: 'check_circle', cssClass: 'mat-button-toggle--primary'}],
        [SaleStatus.REJECTED, {icon: 'cancel', cssClass: 'mat-button-toggle--dark'}]
    ]);

    public static readonly CART_ITEM_DELIVERY_PERIOD_ID_SESSION_STORE_PREFIX = "HEDGE_TRANSACTIONS_DELIVERY_PERIOD_ID_";

    public static convertPartners(partners: PartnerDto[], removePastDeliveryPeriods: boolean = true): PartnerWithDeliveryPeriods[] {
        let partnersWithDeliveryPeriods: PartnerWithDeliveryPeriods[] = [];

        partners.forEach(partner => {
                let partnerWithDeliveryPeriod: PartnerWithDeliveryPeriods = new PartnerWithDeliveryPeriods();
                partnerWithDeliveryPeriod.companyId = partner.companyId;
                partnerWithDeliveryPeriod.name = partner.name;
                partnerWithDeliveryPeriod.deliveryPeriods = this.convertAgreementsToDeliveryPeriodsForOffer(partner.agreements, removePastDeliveryPeriods);

                partnersWithDeliveryPeriods.push(partnerWithDeliveryPeriod);
            }
        );

        partnersWithDeliveryPeriods.sort((a, b) => {
            return a.name.toUpperCase().localeCompare(b.name.toUpperCase());
        });

        return partnersWithDeliveryPeriods;
    }

    public static convertAgreementsToDeliveryPeriodsForOffer(agreements: AgreementModel[], removePastDeliveryPeriods: boolean = true): DeliveryPeriodForOffer[] {
        let deliveryPeriodsForOffer: DeliveryPeriodForOffer[] = [];
        agreements.forEach(agreement => {
            agreement.deliveryPeriods.forEach(deliveryPeriod => {
                if (removePastDeliveryPeriods ? DateUtil.isNowBeforeDate(DateUtil.add(deliveryPeriod.endTime, 1, 'day')) : true) {
                    let deliveryPeriodForOffer: DeliveryPeriodForOffer = new DeliveryPeriodForOffer();
                    deliveryPeriodForOffer.deliveryPeriod = deliveryPeriod;
                    deliveryPeriodForOffer.productType = agreement.productType;
                    deliveryPeriodForOffer.hudexD = agreement.hudexDAccess;
                    deliveryPeriodForOffer.hudexDOne = agreement.hudexDOneMarketAccess;
                    deliveryPeriodForOffer.yearly = agreement.yearlyProducts;
                    deliveryPeriodForOffer.quarterly = agreement.quarterlyProducts;
                    deliveryPeriodForOffer.monthly = agreement.monthlyProducts;
                    deliveryPeriodForOffer.weekly = agreement.weeklyProducts;
                    deliveryPeriodForOffer.tranche = agreement.clicks;
                    deliveryPeriodForOffer.agreement = agreement;
                    deliveryPeriodsForOffer.push(deliveryPeriodForOffer);
                }
            });
        });
        deliveryPeriodsForOffer = deliveryPeriodsForOffer.sort((a, b) => {
            if (a.deliveryPeriod.startTime == b.deliveryPeriod.startTime) {
                return 0;
            } else if (a.deliveryPeriod.startTime > b.deliveryPeriod.startTime) {
                return 1;
            } else if (a.deliveryPeriod.startTime < b.deliveryPeriod.startTime) {
                return -1;
            }
        });

        return deliveryPeriodsForOffer;
    }

    public static findIndexOfCurrentPeriod(deliveryPeriods: DeliveryPeriodDto[]): number {
        return deliveryPeriods.findIndex((deliveryPeriod: DeliveryPeriodDto) =>
            moment().isSameOrAfter(moment(deliveryPeriod.startTime)) &&
            moment().isSameOrBefore(moment(deliveryPeriod.endTime).add(1, 'day')));
    }

    public static findIndexOfNextPeriod(deliveryPeriods: DeliveryPeriodDto[]): number | null {
        let now = moment();

        const featurePeriods = deliveryPeriods.filter(period => moment(period.startTime).isAfter(now));

        if (featurePeriods.length === 0) {
            return null;
        }

        return deliveryPeriods.indexOf(_.minBy(featurePeriods, 'startTime'));
    }

    public static findIndexOfPreviousPeriod(deliveryPeriods: DeliveryPeriodDto[]): number | null {
        let now = moment();

        const pastPeriods = deliveryPeriods.filter(period => moment(period.startTime).isBefore(now));

        if (pastPeriods.length === 0) {
            return null;
        }

        return deliveryPeriods.indexOf(_.maxBy(pastPeriods, 'startTime'));
    }

    public static determineYear(productName: string): number {
        //example: (2022M12) -> 2022
        return +productName.replace(/\s/g, '').substring(0, 4);
    }

    public static determineOfferPeriod(productName: string): OfferPeriod {
        //example: (2022M12) -> M
        return OfferPeriod[productName.replace(/\s/g, '').slice(4, 5)];
    }

    public static determinePeriodNumber(productName: string): number {
        //example: (2022M12) -> 12
        return +productName.replace(/\s/g, '').slice(5);
    }

    public static productNameComparator(prev: string, curr: string): number {
        const periods: OfferPeriod[] = [OfferPeriod.M, OfferPeriod.Q, OfferPeriod.Y];
        const prevPeriod: OfferPeriod = CoverageTransactionsUtil.determineOfferPeriod(prev);
        const currPeriod: OfferPeriod = CoverageTransactionsUtil.determineOfferPeriod(curr);
        const prevPeriodNumber: number = CoverageTransactionsUtil.determinePeriodNumber(prev);
        const currPeriodNumber: number = CoverageTransactionsUtil.determinePeriodNumber(curr);

        const offerPeriodComparator: number = periods.indexOf(prevPeriod) - periods.indexOf(currPeriod);
        const periodNumberComparator: number = prevPeriodNumber - currPeriodNumber;

        if (offerPeriodComparator !== 0) return offerPeriodComparator;
        if (periodNumberComparator !== 0) return periodNumberComparator;

        return 0;
    }

    public static getEvaluationStatusConfig(): Map<SaleStatus, EvaluationStatusConfig> {
        return this.EVALUATION_STATUS_CONFIG;
    }


    public static removeCartItems(key: number | string): void {
        sessionStorage.removeItem(this.CART_ITEM_DELIVERY_PERIOD_ID_SESSION_STORE_PREFIX + key);
    }

    public static setCartItems(key: number | string, value: string): void {
        sessionStorage.setItem(this.CART_ITEM_DELIVERY_PERIOD_ID_SESSION_STORE_PREFIX + key, value);
    }

    public static getCartItems<T extends OfferClaimDto | OfferClaimSessionStore>(key: number | string): T[] {
        return JSON.parse(sessionStorage.getItem(this.CART_ITEM_DELIVERY_PERIOD_ID_SESSION_STORE_PREFIX + key));
    }

    /**
     * Get array of month numbers from offerClaims where it has values.
     * */
    public static getMonthNumbers(offerClaims: OfferClaimDto[]): number[] {
        const months: number[] = [];

        offerClaims.forEach((oc: OfferClaimDto): void => (
                (): number[] => {
                    switch (oc.period) {
                        case OfferPeriod.Y:
                            return Array.from(Array(12).keys()).map((i: number) => i + 1);
                        case OfferPeriod.Q:
                            const firstMonth: number = oc.periodNumber * 3 - 2;
                            return [firstMonth, firstMonth + 1, firstMonth + 2];
                        case OfferPeriod.M:
                            return [oc.periodNumber];
                        case OfferPeriod.W:
                            return [moment(oc.startDate).month() + 1];
                        default: return [];
                    }
                })().forEach((month: number): void => {
                if (months.indexOf(month) === -1) {
                    months.push(month);
                }
            })
        );

        return months;
    }

    /**
     * Get array of quarter-year numbers from offerClaims where it has values.
     * */
    public static getQuarterNumbers(offerClaims: OfferClaimDto[]): number[] {
        const quarterYears: number[] = [];

        CoverageTransactionsUtil.getMonthNumbers(offerClaims).map((month: number): number => {
            if (month > 0 && month < 4) {
                return 1; // Q1
            } else if (month > 3 && month < 7) {
                return 2; // Q2
            } else if (month > 6 && month < 10) {
                return 3; // Q3
            } else {
                return 4; // Q4
            }
        }).forEach((quarter: number): void => {
            if (quarterYears.indexOf(quarter) === -1) {
                quarterYears.push(quarter);
            }
        });

        return quarterYears;
    }

    public static getSaleStatus(offerClaim: OfferClaimWithCurrentStatusDto): SaleStatus {
        return !!offerClaim.currentStatus ? offerClaim.currentStatus : offerClaim.saleStatus;
    }

    public static getCartSaleStatus(offerClaim: CartItemWithCurrentStatusDto): SaleStatus {
        return !!offerClaim.currentStatus ? offerClaim.currentStatus : offerClaim.saleStatus;
    }

    /**
     * Check is the saleStatus toggle switched to ACCEPTED or REJECTED
     * */
    public static isSaleStatusModified(offerClaim: OfferClaimWithCurrentStatusDto): boolean {
        if (!offerClaim.currentStatus || !offerClaim.saleStatus) {
            return false;
        }

        return offerClaim.currentStatus !== offerClaim.saleStatus &&
            offerClaim.saleStatus === SaleStatus.ACCEPTED ||
            offerClaim.saleStatus === SaleStatus.REJECTED;
    }

    /**
     * This method sorts the delivery periods by its start and end times. If the start times are equal, it checks the end times.
     * */
    public static sortDeliveryPeriods(prev: DeliveryPeriodDto, curr: DeliveryPeriodDto): number {
        return CoverageTransactionsUtil.sortByStartEndTimes(prev.startTime, curr.startTime, prev.endTime, curr.endTime);
    }

    /**
     * This method sorts the models by its start and end times. If the start times are equal, it checks the end times.
     * */
    public static sortByStartEndTimes(prevStartTime: Date | Moment, currStartTime: Date | Moment,
                                      prevEndTime: Date | Moment, currEndTime: Date | Moment): number {
        const startDifference: number = moment(prevStartTime).diff(moment(currStartTime));
        const endDifference: number = moment(prevEndTime).diff(moment(currEndTime));

        if (startDifference !== 0) {
            return startDifference;
        }

        return endDifference;
    }

    public static getSumOfNetPrices(offerClaims: ClaimItemBaseModel[]): number {
      return offerClaims
        .filter((claim: OfferClaimWithCurrentStatusDto): boolean => claim.saleStatus === SaleStatus.ACCEPTED)
        .map((claim: OfferClaimWithCurrentStatusDto): number => claim.netPrice)
        .reduce((prev: number, curr: number) => prev + curr, 0);
    }

}
