import {Component} from '@angular/core';
import {LoginService} from '../../login/login.service';
import {Principal} from '../../shared/auth/principal.service';
import {BehaviorSubject} from "rxjs";
import * as _ from "lodash";
import {NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'jhi-footer',
    templateUrl: './footer.component.html'
})
export class FooterComponent {
    reRender: boolean;
    isHedgeUrl: BehaviorSubject<boolean>;

    constructor(loginService: LoginService,
                private router: Router,
                private principal: Principal) {
        loginService.loginSubscribe(() => {
            this.reRender = true;
            setTimeout(() => {
                this.reRender = false;
            }, 100);
        });
        this.isHedgeUrl = new BehaviorSubject(this.isCurrentRouterUrlHedgeTransaction());
        this.initUrlChangeSubscription();
    }

    get authenticated() {
        return this.principal.isAuthenticated();
    }

    initUrlChangeSubscription(): void {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const isHedge: boolean = this.isCurrentRouterUrlHedgeTransaction();
                if (this.isHedgeUrl.getValue() != isHedge) {
                    this.isHedgeUrl.next(isHedge);
                }
            }
        });
    }

    isCurrentRouterUrlHedgeTransaction(): boolean {
        return _.includes(this.router.url, '/hedge-transaction');
    }
}
