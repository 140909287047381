<header class="docs">
    <h2>{{docList.categoryName}}</h2>
    <aside>
        <input [(ngModel)]="filterText" type="text"
               placeholder="{{'global.search' | translate}}"/>
    </aside>
</header>

<div class="col-xl-6 offset-xl-3">
    <cez-document-list [documentByCategory]="docList" [filterText]="filterText"></cez-document-list>
</div>

