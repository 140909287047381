import {Component, EventEmitter, Input, Output} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {EmailDetails} from "../model/email-content.model";
import {DocumentFilterService} from "../../../../shared/services/document.filter.service";

@Component({
    selector: 'jhi-email-content-modal',
    templateUrl: "./email-content-modal.component.html",
  styleUrls: ['./email-content-modal.component.scss']
})
export class EmailContentModalComponent  {

    @Input() emailDetails: EmailDetails;
    @Output() modalClose: EventEmitter<any> = new EventEmitter();

    constructor(public activeModal: NgbActiveModal,
                public documentFilterService: DocumentFilterService) {
    }

}

