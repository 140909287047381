import {Component, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {OfferType} from '../../../shared/offer-claim/model/offer-type.enum';
import {DailyRatesDto} from '../../../shared/daily-rates/model/DailyRatesDto';
import {DailyPricesService} from '../../../shared/services/daily-prices.service';
import { Chart } from 'angular-highcharts';
import {takeUntil} from 'rxjs/operators';
import {forkJoin, Subject} from 'rxjs';
import {Market} from '../../../shared/offer-claim/model/market.enum';
import {CoverageTransactionsService} from '../../coverage-transactions.service';
import {DeliveryPeriodDto} from '../../../shared/dto/delivery-period-dto';
import moment, {Moment} from 'moment';
import {DailyRateType} from '../../../shared/daily-rates/model/DailyRatesType.enum';
import {DailyPriceStateChange} from '../../../shared/daily-rates/model/DailyPriceStateChange';

@Component({
    selector: 'jhi-available-products-overview',
    templateUrl: './available-products-overview.component.html',
    styleUrls: ['available-products-overview.component.scss']
})
export class AvailableProductsOverviewComponent implements OnInit, OnDestroy {

    @Input()
    public set availableProductsInput(selectedDeliveryPeriod: DeliveryPeriodDto) {
        this.selectedDeliveryPeriod = selectedDeliveryPeriod;
        this.initData(selectedDeliveryPeriod.id);
    }

    public availableProducts: DailyRatesDto[] = [];
    public hasTimeRemain: boolean = false;
    public isLoading: boolean = false;
    public isMarketOpen: boolean = false;
    public selectedDeliveryPeriod: DeliveryPeriodDto;

    private dailyRateType: DailyRateType | null;
    private isActiveDailyPrices: boolean;
    private destroy: Subject<any> = new Subject();

    constructor(private dailyPricesService: DailyPricesService,
                private elementRef: ElementRef,
                private coverageTransactionsService: CoverageTransactionsService) {
    }

    ngOnInit(): void {
        if (this.dailyPricesService.navigateBack === 'overviewTab') {
            this.dailyPricesService.navigateBack = null;
            this.elementRef.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
        this.setDailyPricesState();
    }

    ngOnDestroy(): void {
        this.destroy.next(true);
        this.destroy.complete();
    }

    public getProductName(name: string): string {
        if (name.length < 5) {
            return `YR-${name.substring(0, 4)} HU ${OfferType.BL}`;
        }
        return `${name.substring(4)}-${name.substring(0, 4)} HU ${OfferType.BL}`;
    }

    public isValidPrice(price: number | string): boolean {
        return !(price === null || price === undefined || price === '');
    }

    public isShowRemainTime(product: DailyRatesDto): boolean {
        return this.isActiveDailyPrices && this.isValidPrice(product.currentPrice) && this.remainTime != 0 && !product.withoutPrice;
    }

    public calcHasTimeRemain(): void {
        this.hasTimeRemain = false;
        this.availableProducts.forEach((product: DailyRatesDto) => {
            if (this.isShowRemainTime(product)) {
                this.hasTimeRemain = true;
                return;
            }
        });
    }

    public get remainTime(): number {
        return this.dailyPricesService.remainTime;
    }

    public get expired(): boolean {
        return this.remainTime <= 0 || this.dailyRateType !== DailyRateType.FIRM;
    }

    public getChartForProduct(product: DailyRatesDto): Chart {
        const data: number[] = !!product.previous7BusinessDayPrices ? product.previous7BusinessDayPrices : [];
        const chartOptions: any = this.getChartOptions(data);

        return new Chart({...chartOptions});
    }

    public goToBuying(product: DailyRatesDto): void {
        this.dailyPricesService.navigateTo = 'buyingTab';
        this.dailyPricesService.navigateBack = 'overviewTab';
        this.dailyPricesService.setSelectedRate(product);
    }

    public goToPriceMonitoring(product: DailyRatesDto): void {
        this.dailyPricesService.navigateTo = 'priceMonitoringTab';
        this.dailyPricesService.navigateBack = 'overviewTab';
        this.dailyPricesService.setSelectedRate(product);
    }

    public isLastMonthOfDeliveryPeriod(): boolean {
        const endDate: Moment = moment(this.selectedDeliveryPeriod.endTime);

        return moment().year() === endDate.year() &&
            moment().month() === endDate.month();
    }

    private initData(selectedDeliveryPeriodId: number): void {
        this.getAvailableProducts(selectedDeliveryPeriodId);
    }

    private getAvailableProducts(deliveryPeriodId: number): void {
        this.availableProducts = [];
        this.isLoading = true;
        forkJoin(
            this.coverageTransactionsService.getProductsByDeliveryPeriod(deliveryPeriodId, true),
            this.coverageTransactionsService.isMarketOpen(Market.OTC, null, null)
        ).subscribe((response: [DailyRatesDto[], boolean]): void => {
            this.availableProducts = response[0];
            this.isMarketOpen = response[1];
            this.calcHasTimeRemain();
            this.isLoading = false;
        }, (): boolean => this.isLoading = false);
    }

    private updateAvailableProductsRemainTime(changedProducts: DailyRatesDto[]): void {
        this.availableProducts.forEach((product: DailyRatesDto): void => {
            const changedProduct: DailyRatesDto[] = changedProducts.filter((p: DailyRatesDto) => p.name === product.name);
            if (changedProduct && changedProduct.length > 0) {
                if (changedProduct[0].currentPrice) {
                    product.currentPrice = changedProduct[0].currentPrice;
                }
                product.withoutPrice = changedProduct[0].withoutPrice;
            }
        });
    }

    private setDailyPricesState(): void {
        this.dailyPricesService.dailyPricesStateChange.pipe(takeUntil(this.destroy))
            .subscribe((change: DailyPriceStateChange): void => {
                const type: DailyRateType | null = change.type;
                const changedRates: DailyRatesDto[] = change.updatedDailyRates;

                this.dailyRateType = type;
                this.isActiveDailyPrices = type != null && type === DailyRateType.FIRM;

                if (type === DailyRateType.FIRM) {
                    // firm price published
                    if (changedRates.length === 0) {
                        this.getAvailableProducts(this.selectedDeliveryPeriod.id);
                    } else {
                        this.updateAvailableProductsRemainTime(changedRates);
                        this.calcHasTimeRemain();
                    }
                } else if (type === null) {
                    // daily prices expired
                    this.calcHasTimeRemain();
                }
            });
    }

    private getChartOptions(data: number[]): any {
        const chartOptions: any = {};

        chartOptions.chart = {
            backgroundColor: 'transparent',
            borderWidth: 0,
            margin: [2, 0, 2, 0],
            width: 140,
            height: 20,
            style: {
                overflow: 'visible'
            }
        };
        chartOptions.title = {
            text: ''
        };
        chartOptions.credits = {
            enabled: false
        };
        chartOptions.xAxis = {
            visible: false,
            lineWidth: 0,
            labels: {
                enabled: false,
            },
            title: {
                text: null,
            },
            startOnTick: false,
            endOnTick: false,
            tickPositions: []
        };
        chartOptions.yAxis = {
            visible: false,
            endOnTick: false,
            startOnTick: false,
            labels: {
                enabled: false,
            },
            title: {
                text: null,
            },
            tickPositions: [0]
        };
        chartOptions.legend = {
            enabled: false
        };
        chartOptions.tooltip = {
            enabled: true,
            formatter: function () {
                return this.y;
            },
            hideDelay: 0,
            outside: true,
            shared: true,
        };
        chartOptions.plotOptions = {
            series: {
                animation: false,
                lineWidth: 1,
                shadow: false,
                states: {
                    hover: {
                        lineWidth: 1,
                    }
                },
                marker: {
                    radius: 2,
                    states: {
                        hover: {
                            radius: 2
                        }
                    }
                },
                connectNulls: true
            },
            area: {
                enableMouseTracking: true
            }
        };
        chartOptions.series = [
            {
                name: 'Price',
                type: 'line',
                data: data
            }
        ];
        chartOptions.navigation = {
            buttonOptions: {
                enabled: false
            }
        };
        chartOptions.colors = ['#f24f00'];

        return chartOptions;
    }
}
