<header class="docs">
  <h2>{{'calendar.calendarAdmin' | translate}}</h2>
  <aside>
    <button *ngIf="!editMode && adminAccess" class="btn btn-primary" (click)="editMode=true">{{'calendar.addNew' | translate}}</button>
  </aside>
</header>

<div class="row" *ngIf="editMode && adminAccess">
  <div class="form-group col-md-6">
    <label for="redLetterDay">{{'calendar.new.newDate' | translate}}</label>
    <ng-container *ngIf="editMode">
      <jhi-date-picker [(dpModel)]="newDate" id="redLetterDay"></jhi-date-picker>
      <button class="btn btn-primary mt-2" (click)="saveRedLetterDay()">{{'entity.action.save' | translate}}</button>
      <button class="btn btn-primary mt-2" (click)="editMode = false">{{'entity.action.cancel' | translate}}</button>
    </ng-container>
  </div>

  <div class="form-group col-md-6">
    <label for="exceptionalWorkDay">{{'calendar.new.dayType' | translate}}</label>
    <select required class="form-control" [(ngModel)]="dayType">
      <option></option>
        <option *ngFor="let type of dayTypes" [value]="type">{{('calendar.dayType.' + type) | translate}}</option>
    </select>
  </div>
</div>

<ul class="list-unstyled">
  <li *ngFor="let item of dates" class="py-1">
    <button type="submit" class="btn btn-danger btn-sm" (click)="remove(item)">
      <span class="fa fa-times"></span>
    </button>
      {{item.day | cezDate}} - {{('calendar.dayType.' + item.dayType) | translate}}

  </li>
</ul>
