import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import BlotFormatter from 'quill-blot-formatter';
import * as Quill from 'quill';
import {News} from '../shared/dto/news';
import {BaseDto, Category, JhiLanguageHelper, LocalizedTexts} from '../shared';
import {ExcelImportResult} from "../shared/time-series/model/excel-import-result.model";

Quill.register('modules/blotFormatter', BlotFormatter);

@Component({
    selector: 'ngbd-modal-content',
    templateUrl: "news.admin.modal.component.html",
    styleUrls: ['news.admin.modal.component.scss']
})
export class NewsAdminModalComponent implements OnInit {
    @Input() categories: Category[];
    @Input() news: News;
    @Output() modalClose: EventEmitter<any> = new EventEmitter();

    selectedFile: File;
    selectedCategory: Category;
    isSaving: boolean = false;
    languages: any[];
    selectedTags: BaseDto[];
    tagList: BaseDto[] = [];
    tagResult: BaseDto[];
    newTagHu: string;
    newTagEn: string;
    newTags: LocalizedTexts[] = [];
    tempCounter: number = -1;
    newTagMode: boolean;
    editorModules: any;

    constructor(public activeModal: NgbActiveModal,
                private languageHelper: JhiLanguageHelper,
                private http: HttpClient) {
    }

    ngOnInit() {
        this.http.get<BaseDto[]>('/api/tag').subscribe(r => {
            this.tagList = r;
            if (this.news.tags)
                this.selectedTags = this.tagList.filter(tag => this.news.tags.some(newsTag => newsTag.id === tag.id));
        });

        this.languageHelper.getAll().then((languages) => {
            this.languages = languages;
        });

        if (this.news.id) {
            if (this.news.categories[0])
                this.selectedCategory = this.categories.filter(r => r.id === this.news.categories[0].id)[0];
        }

        this.editorModules = {
            toolbar: [
                [{'font': ['Sans Serif', 'serif', 'monospace']}],
                [{'size': ['small', false, 'large', 'huge']}],
                ['bold', 'italic', 'underline', 'strike'],
                ['color', 'background'],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'header': 1}, {'header': 2}, 'blockquote', 'code-block'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                [{'direction': 'rtl'}, {'align': ['', 'center', 'right', 'justify']}],
                ['link', 'image', 'video'],
                ['clean']
            ],
            blotFormatter: {}
        };
    }


  public addFile(fileInput: HTMLInputElement) {
    fileInput.value = '';
    fileInput.click();
  }

  public onUpload(event: { file: File, success: boolean, response?: ExcelImportResult, error?: HttpErrorResponse }) {
      this.selectedFile = event.file;
  }

    onSubmit(form: NgForm) {
        this.isSaving = true;

        let news = <News>form.value;
        news.id = this.news.id;

        let category = new Category();
        category.id = this.selectedCategory.id;
        news.categories = [category];

        if (this.newTags && this.newTags.length > 0)
            this.publisTags().subscribe(() => this.createOrUpdateNews(news));
        else
            this.createOrUpdateNews(news);

    }

    createOrUpdateNews(news: News) {
        if (news.id) {
            this.updateNews(news)
        } else
            this.createNews(news);
    }

    createNews(news: News) {
        this.http.post<News>('/api/news/admin', news).subscribe(r => {
            let newsId = r.id;
            this.savePictureAndTags(newsId);
        });
    }

    updateNews(news: News) {
        this.http.put("/api/news/admin", news).subscribe(r => {
            this.savePictureAndTags(news.id);
        })
    }

    savePictureAndTags(newsId: number) {
        if (this.selectedTags) {
            this.http.patch('api/news/' + newsId + '/tag', this.selectedTags.map(tag => tag.id))
                .subscribe();
        }
        if (this.selectedFile) {
            let data = new FormData();
            data.append("file", this.selectedFile);
            this.http.post('/api/news/admin/' + newsId + '/picture', data).subscribe(r =>
                this.modalClose.emit());
        } else
            this.modalClose.emit();
        this.isSaving = false;
    }

    searchTags(event) {
        this.tagResult = this.tagList.filter(r => r.name.indexOf(event.query) > -1);
    }

    saveTag() {
        this.tagList.push({id: this.tempCounter, name: this.newTagEn, language: null});
        this.newTags.push({id: this.tempCounter--, textEN: this.newTagEn, textHU: this.newTagHu});
        this.newTagHu = "";
        this.newTagEn = "";
    }

    publisTags(): Observable<void> {
        let serverDto = this.newTags.map(r => {
            let localizedTextDto: LocalizedTexts = {id: null, textEN: r.textEN, textHU: r.textHU};
            return {id: null, name: r};
        });
        return this.http.post<any>('/api/tag', serverDto).pipe(map(r => {
            this.tagList = r.map(tag => {
                return {id: tag.id, name: tag.name.textEN}
            });
            if (this.selectedTags)
                this.selectedTags.map(tag => {
                    if (tag.id < 0) {
                        let tagNameEn = this.newTags.filter(newTag => newTag.id === tag.id)[0].textEN;
                        tag.id = this.tagList.filter(newTag => newTag.name === tagNameEn)[0].id;
                    }
                });
            this.newTags = [];
        }));
    }
}
