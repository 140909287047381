import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { StateStorageService } from '../../shared/auth/state-storage.service';
import { NgBDatePickerConvertService } from '../../shared/services/ngb.datepicker.convert.service';
import {BusinessCalendarService} from '../../shared/services/business-calendar.service';
import {LinkModel} from "../../links";
import {DialogService} from "../../shared/dialog/dialog.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'jhi-calendar-admin',
    templateUrl: './calendar-admin.component.html',
    styles: []
})
export class CalendarAdminComponent implements OnInit {
    dates: any[] = [];
    newDate: any;
    editMode: boolean;
    dayType: string;
    dayTypes: string[] = ['HOLIDAY', 'WORKDAY'];
    adminAccess: boolean = false;
    account: any;

    constructor(private dateConverter: NgBDatePickerConvertService,
                private http: HttpClient,
                private businessCalendarService: BusinessCalendarService,
                private stateStorageService: StateStorageService,
                private dialogService: DialogService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.getPermissions();
        this.refreshList();
    }

    getPermissions() {
        let permissions = this.stateStorageService.getEffectivePermissions();
        this.adminAccess = permissions.indexOf('BUSINESS_CALENDAR_WRITE') > -1;
    }

    refreshList() {
        if (this.adminAccess) {
            this.businessCalendarService.getBusinessCalendar().subscribe(response => {
                this.dates = response.map(r => {
                    return {day: new Date(r.day), dayType: r.dayType};
                });
            });
        }
    }

    saveRedLetterDay() {
        this.saveDate(this.editMode, this.dates, this.newDate, this.dayType);
    }

    saveDate(editmode: boolean, dateCollection: any[], newItem: any, dayType: string) {
        editmode = false;
        let convertedDate = this.dateConverter.convertToDate(newItem);
        if (!dateCollection.some(r => r.day.getTime() === convertedDate.getTime())) {
            let dateAndType = {day: convertedDate, dayType: dayType};
            dateCollection.unshift(dateAndType);
            this.http.post('api/business_calendar', dateAndType).subscribe();
        }
    }

    remove(item: any) {
        this.dialogService.confirm(this.translate.instant('filter.basic.deleteDialog.title'), this.translate.instant('filter.basic.deleteDialog.message'))
            .subscribe((value: boolean): void => {
                if (value) {
                    this.deleteConfirmed(item);
                }
            });
    }

    deleteConfirmed(item: any): void {
        this.dates.splice(this.dates.indexOf(item), 1);
        this.http.delete('api/business_calendar/' + (<Date>item.day).toISOString().split('.')[0] + 'Z').subscribe();
    }

}
