import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {News} from '../shared/dto/news';
import {NewsService} from '../shared/services/news.service';

@Component({
    selector: 'ngbd-modal-content',
    templateUrl: "news.modal.component.html"
})
export class NewsModalComponent {
    @Input() article: News;
    comment: string = "";
    success: string;
    error: string;
    show: boolean = false;
    commentSaveInProgress: boolean;

    constructor(public activeModal: NgbActiveModal,
                public newsService: NewsService,
                private http: HttpClient) {
    }


    saveComment() {
        let commentDTO = { text: this.comment };
        if (this.comment) {
            this.commentSaveInProgress = true;
            this.http.patch("/api/news/" + this.article.id + "/comment", commentDTO).subscribe(() => {
                this.error = null;
                this.success = 'OK';
                this.show = false;
                this.commentSaveInProgress = false;
                setTimeout(() => {
                    this.success = '';
                }, 5000);
            });
        }
    }
}

