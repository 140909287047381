import {DeliveryPeriod} from "../../dto/delivery.periods";
import {ProductType} from "../../agreement-details/agreement-enums.model";
import {AgreementModel} from '../../dto/agreement.model';

export class DeliveryPeriodForOffer {
    deliveryPeriod: DeliveryPeriod;
    productType: ProductType;
    hudexD: Boolean;
    hudexDOne: Boolean;
    yearly: Boolean;
    quarterly: Boolean;
    monthly: Boolean;
    weekly: Boolean;
    tranche: number;
    agreement: AgreementModel;
}
