import { AfterViewInit, Component, ContentChildren, forwardRef, Input, OnDestroy, QueryList } from '@angular/core';
import { combineLatest, merge, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FileUploadComponent } from '../matFileUpload/file-upload.component';


@Component({
    selector: 'cez-file-upload-queue',
    templateUrl: `file-upload-queue.component.html`,
    styleUrls: ['../matFileUpload/file-upload.component.scss']
})
export class FileUploadQueueComponent implements OnDestroy, AfterViewInit {

    @Input() isMultipleFiles: boolean;

    @ContentChildren(forwardRef(() => FileUploadComponent)) fileUploads: QueryList<FileUploadComponent>;
    files: Array<any> = [];
    isUploading: boolean;
    isUploadedAll: boolean;

    /** Subscription to remove changes in files. */
    private fileRemoveSubscription: Subscription|null;


    /** Combined stream of all of the file upload remove change events. */
    get fileUploadRemoveEvents() {
        return merge(...this.fileUploads.map(fileUpload => fileUpload.removeEvent));
    }


    ngAfterViewInit() {
        // When the list changes, re-subscribe
        this.fileUploads.changes
            .pipe(startWith(null))
            .subscribe(() => {
                if (this.fileRemoveSubscription) {
                    this.fileRemoveSubscription.unsubscribe();
                }
                this.listenToFileRemoved();
                this.uploadedAll();
            });
    }

    ngOnDestroy() {
        if (this.files) {
            this.removeAll();
        }
    }

    add(file: any) {
        if (this.isMultipleFiles) {
            this.files.push(file);
        } else {
            this.files = [file];
        }
    }

    uploadAll() {
        this.isUploading = true;

        combineLatest(this.fileUploads.map(item => item.onUpload))
            .pipe(map(value => value as any))
            .subscribe(
                () => {
                    this.uploadedAll();
                    this.isUploading = false;
                }
            );

        this.fileUploads.forEach((fileUpload) => {
            fileUpload.upload();
        });
    }

    removeAll() {
        this.files.splice(0, this.files.length);
    }

    private listenToFileRemoved(): void {
        this.fileRemoveSubscription = this.fileUploadRemoveEvents.subscribe((event: FileUploadComponent) => {
            this.files.splice(event.id, 1);
        });
    }


    uploadedAll(): void {
        this.isUploadedAll = this.fileUploads.map(v => v.isUploaded).reduce((a, b) => a && b, true);
    }
}
