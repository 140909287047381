import {DiagramType} from '../../admin/additional-diag-types/model/diagram-type';

export class TimeSeriesId {

    timeSeriesHeaderId: number;
    sid: boolean;
    profileName?: string;
    diagramType: DiagramType;

    static equals(thiz: TimeSeriesId, other: TimeSeriesId): boolean {
        return thiz.timeSeriesHeaderId === other.timeSeriesHeaderId &&
            thiz.profileName === other.profileName &&
            thiz.sid === other.sid &&
            thiz.diagramType === other.diagramType;
    }


    static findChart(timeSeriesIds: TimeSeriesId[], keyTimeSeriesId: TimeSeriesId): number {
        return timeSeriesIds.findIndex(timeSeriesId => TimeSeriesId.equals(timeSeriesId, keyTimeSeriesId));
    }

    static toIdString(timeSeriesId: TimeSeriesId): string {
        return timeSeriesId.timeSeriesHeaderId + "|" + timeSeriesId.sid + "|" + timeSeriesId.profileName + "|" + timeSeriesId.diagramType;
    }

}
