import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {tap} from 'rxjs/operators';

import {BookedPerformanceModalComponent} from './booked-performance-modal/booked-performance-modal.component';
import {SortEvent} from 'primeng/api';
import {PodModel} from '../shared/dto/pod.model';
import {PodsDefaultSettingsModel} from '../shared/dto/pods-default-settings.model';
import {BookedPerformanceDefaults} from '../shared/dto/booked.performance.defaults.model';
import {PodBookedPerformanceModel} from '../shared/dto/pod.booked.performance.model';
import {StateStorageService} from '../shared/auth';
import {BookedPerformanceService} from '../shared/services/booked-performance.service';
import {PartnerService} from '../shared/services/partner.service';
import {JhiLanguageHelper} from '../shared';
import {PodService} from '../shared/services/pod.service';
import {DialogService} from '../shared/dialog/dialog.service';

@Component({
    selector: 'jhi-booked-performance',
    templateUrl: './booked-performance.component.html',
    styleUrls: ['booked-performance.component.scss']
})
export class BookedPerformanceComponent implements OnInit {

    partnerId = this.stateStorageService.getSelectedCompanyId();
    selectedPodId: number;
    podData: PodModel[];
    podsAndDefault: PodsDefaultSettingsModel = new PodsDefaultSettingsModel();
    viewDetail: boolean;
    bookedPerformanceDefaults: BookedPerformanceDefaults = new BookedPerformanceDefaults();
    originalDefaults: BookedPerformanceDefaults = new BookedPerformanceDefaults();
    selectedPodBookedPerformances: PodBookedPerformanceModel[];
    editDefaults: boolean;
    languages: any[];

    constructor(private bookedPerformanceService: BookedPerformanceService,
                private stateStorageService: StateStorageService,
                private modalService: NgbModal,
                private dialogService: DialogService,
                private partnerService: PartnerService,
                private languageHelper: JhiLanguageHelper,
                public podService: PodService,
                public translateService: TranslateService) {
    }

    ngOnInit() {
        this.bookedPerformanceService.getPartnersPodsAndDefaultSettings(this.partnerId).pipe(tap()).subscribe(r => {
            this.podData = r.podList;
            this.bookedPerformanceDefaults = r.partnerDefaultSettings;
        });

        this.languageHelper.getAll().then((languages) => {
            this.languages = languages;
        });
    }

    view(pod: PodModel) {
        this.selectedPodId = pod.id;
        this.bookedPerformanceService.getBookedPerformance(this.partnerId, pod.id)
            .subscribe(r => this.selectedPodBookedPerformances = r);

        this.viewDetail = true;
    }

    editBookedPerformance(model: PodBookedPerformanceModel) {
        let edit: boolean = false;
        if (!model) {
            model = new PodBookedPerformanceModel();
        } else
            edit = true;

        if (this.selectedPodBookedPerformances == null)
            this.selectedPodBookedPerformances = [];

        const modalRef = this.modalService.open(BookedPerformanceModalComponent, {backdrop: 'static'});
        modalRef.componentInstance.model = model;
        modalRef.componentInstance.modalClose.subscribe((podBookedPerformanceModel: PodBookedPerformanceModel) => {
            this.addOrUpdateBookedPerformance(this.selectedPodBookedPerformances, podBookedPerformanceModel, edit);
            modalRef.close();
        });
    }

    deleteBookedPerformance(id: number) {
        this.dialogService.confirm(this.translateService.instant('scheduling.question'), '').subscribe((sure: boolean) => {
            if (sure) {
                this.bookedPerformanceService.deleteBookedPerformance(id).subscribe();
                this.selectedPodBookedPerformances = this.selectedPodBookedPerformances.filter(r => r.id !== id);
            }
        });
    }

    closeDefaultSettings() {
        this.bookedPerformanceDefaults = JSON.parse(JSON.stringify(this.originalDefaults));
        this.editDefaults = false;
    }

    editDefaultSettings() {
        this.originalDefaults = JSON.parse(JSON.stringify(this.bookedPerformanceDefaults));
        this.editDefaults = true;
    }

    saveDefaultSettings() {
        this.partnerService.updateDefaultSettings(this.partnerId, this.bookedPerformanceDefaults).subscribe();
        this.editDefaults = false;
    }

    private addOrUpdateBookedPerformance(podBookedPerformances: PodBookedPerformanceModel[],
                                         podBookedPerformance: PodBookedPerformanceModel,
                                         edit: boolean) {
        if (edit) {
            this.bookedPerformanceService.updateBookedPerformance(this.partnerId, this.selectedPodId, podBookedPerformance).subscribe();
            podBookedPerformances.filter(r => r.id == podBookedPerformance.id)[0] = podBookedPerformance;
        } else {
            this.bookedPerformanceService.saveBookedPerformance(this.partnerId, this.selectedPodId, podBookedPerformance).subscribe();
            podBookedPerformances.push(podBookedPerformance);
        }
    }

    checkValidity() {
        if (this.bookedPerformanceDefaults.bookedPerformanceDefaultPercentage > 100)
            this.bookedPerformanceDefaults.bookedPerformanceDefaultPercentage = 100;

        if (this.bookedPerformanceDefaults.bookedPerformanceDefaultPercentage < 0)
            this.bookedPerformanceDefaults.bookedPerformanceDefaultPercentage = 0;
    }

    customSort(event: SortEvent) {
        event.data.sort((data1, data2) => {

            let extractFn;
            let transformFn;
            switch (event.field) {
                case 'podCode':
                    extractFn = data => data.podCode;
                    transformFn = value => value;
                    break;
                case 'type':
                    extractFn = data => data.podType;
                    transformFn = value => this.podService.translateProfileType(value);
                    break;
                case 'zipCode':
                    extractFn = data => data.zipCode;
                    transformFn = value => value;
                    break;
                case 'address':
                    extractFn = data => data;
                    transformFn = value => this.podService.getPodAddress(value);
                    break;
                default:
                    console.error('Unknown field: ' + event.field);
            }

            const value1 = extractFn(data1);
            const value2 = extractFn(data2);
            let result = null;

            if (value1 == null && value2 != null)
                result = -1;
            else if (value1 != null && value2 == null)
                result = 1;
            else if (value1 == null && value2 == null)
                result = 0;
            else {
                const transformed1 = transformFn(value1);
                const transformed2 = transformFn(value2);

                if (typeof transformed1 === 'string' && typeof transformed2 === 'string')
                    result = transformed1.localeCompare(transformed2);
                else
                    result = transformed1 - transformed2;
            }

            return (event.order * result);
        });
    }

}
