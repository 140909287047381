import {HttpClient, HttpParams} from '@angular/common/http';
import {AfterViewInit, Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DocumentByCategoryModel} from '../shared/dto/documentCategory.model';
import {FileSaverService} from '../shared/services/file-saver.service';
import {DocumentModel} from '../shared/dto/document.model';

@Component({
    selector: 'document-management-common',
    templateUrl: './document.management.html'
})
export class DocumentCommonComponent implements AfterViewInit {

    private static readonly AUTO_DOWNLOAD_FILES: string[] = ['Multi-source', 'Cash-out'];

    public docList: DocumentByCategoryModel = new DocumentByCategoryModel();
    public filterText: string = '';

    constructor(private http: HttpClient,
                private activatedRoute: ActivatedRoute,
                private fileSaverService: FileSaverService) {
    }

    ngAfterViewInit() {
        this.http.get('/api/document/by_category/-2').subscribe((doc: DocumentByCategoryModel) => {
            this.docList = doc;
            this.docList.subCategories.map(sc => {
                const categoryId: number = +this.activatedRoute.snapshot.paramMap.get('categoryId');
                sc.show = true;
                sc.contentList.map(content => content.show = true);
                if (!!categoryId && sc.categoryId == categoryId) {
                    sc.expand = true;
                    this.downloadPdfDocs(sc);
                }
            });
        });
    }

    private downloadPdfDocs(subCategories: DocumentByCategoryModel): void {
        subCategories.contentList.filter((cl: DocumentModel) => DocumentCommonComponent.AUTO_DOWNLOAD_FILES.indexOf(cl.title) !== -1)
            .forEach((cl: DocumentModel) => this.fileSaverService.save(cl.documentFile.urlPath,
                'application/octet-stream',
                null,
                new HttpParams(),
                cl.documentFile.fileName,
                'GET'));
    }
}
