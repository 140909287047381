import {HttpClient, HttpEventType, HttpHeaders, HttpParams} from '@angular/common/http';
import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {ExcelImportResult} from '../../time-series/model/excel-import-result.model';


@Component({
  selector: 'cez-file-upload',
  templateUrl: `./file-upload.component.html`,
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnDestroy {

  progressSubject: Subject<number>;
  isUploading: boolean;
  isUploaded: boolean;
  loaded: number = 0;
  total: number = 0;

  @Input() httpUrl: string;

  @Input() httpRequestHeaders: HttpHeaders | {
    [header: string]: string | string[];
  } = new HttpHeaders();

  @Input() httpRequestParams: HttpParams | {
    [param: string]: string | string[];
  };

  @Input() fileAlias: string = 'file';

  private _autoUpload = false;
  @Input() set autoUpload(x: boolean) {
    this._autoUpload = x;
    if (this._file && !this.isUploading && !this.isUploaded) {
      setTimeout(() => this.upload());
    }
  }

  @Input() doBeforeUpload: () => void;

  get autoUpload() {
    return this._autoUpload;
  }

  @Input() set file(file: any) {
    this._file = file;
    this.isUploaded = false;
    this.progressSubject = null;
    this.total = this._file.size;
    if (this.autoUpload) {
      this.upload();
    }
  }

  get file(): any {
    return this._file;
  }

  @Input() set id(id: number) {
    this._id = id;
  }

  get id(): number {
    return this._id;
  }

  @Output() removeEvent = new EventEmitter<FileUploadComponent>();
  @Output() onUpload = new EventEmitter();

  private _file: any;
  private _id: number;


  constructor(private _http: HttpClient) {
  }


  ngOnDestroy() {
  }


  upload(): void {
    if (!!this.doBeforeUpload) {
      this.doBeforeUpload();
    }
    if (this.isUploaded) {
      return;
    }

    this.isUploading = true;
    this.progressSubject = new Subject<number>();

    let formData = new FormData();
    formData.append(this.fileAlias, this._file);

    if (this.httpUrl) {
      //szerver oldali azonnali upload
      this._http.post<ExcelImportResult>(this.httpUrl, formData, {
        headers: this.httpRequestHeaders,
        observe: 'events',
        params: this.httpRequestParams || new HttpParams(),
        reportProgress: true,
        responseType: 'json'

      }).subscribe((event: any) => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressSubject.next(Math.round(event.loaded * 100 / event.total));
          this.loaded = event.loaded;
          this.total = event.total;
        } else if (event.type === HttpEventType.Response) {
          this.isUploaded = true;
          this.isUploading = false;
          this.onUpload.emit({file: this._file, success: true, response: event.body});
        }


      }, (error: any) => {
        this.isUploading = false;
        this.isUploaded = false;
        this.onUpload.emit({file: this._file, success: false, error: error});

      }, () => {

      });
    } else {
      //kliens oldali uplodhoz
      this.progressSubject = this._file.size;
      this.loaded = this._file.size;
      this.total = this._file.size;
      this.isUploaded = true;
      this.isUploading = false;
      this.onUpload.emit({file: this._file, success: true, response: ''});
    }
  }


  cancel(): void {
    this.removeEvent.emit(this);
  }

}
