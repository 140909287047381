import {TimeSeriesData} from './time-series-data';
import {TimeSeriesId} from '../time-series-id';

export class IdentifiedTimeSeries {

    timeSeriesId: TimeSeriesId;
    timeSeries: TimeSeriesData;


    constructor(timeSeriesID: TimeSeriesId, timeSeries: TimeSeriesData) {
        this.timeSeriesId = timeSeriesID;
        this.timeSeries = timeSeries;
    }
}
