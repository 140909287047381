export type ExcelType =
    'VECTOR'
    |'MATRIX'
    |'MATRIX_T'

export const ExcelType = {
    VECTOR: 'VECTOR' as ExcelType,
    MATRIX: 'MATRIX' as ExcelType,
    MATRIX_T: 'MATRIX_T' as ExcelType
};
