<div>
    <router-outlet name="navbar"></router-outlet>
</div>
<div class="container-fluid">
    <router-outlet name="home"></router-outlet>
</div>
<!--<side-bar></side-bar>-->
<div class="container-fluid main-container"
     [ngClass]="isCezHedgeScreen ? 'main-container-cez-hedge' : 'main-container'"
     [hidden]="this.router.url === '/'">

    <div class="card jh-card">
        <router-outlet></router-outlet>
        <router-outlet name="popup"></router-outlet>
    </div>
</div>
<jhi-footer></jhi-footer>

<jhi-toast-message-component [sessionTimeout]="dismissMessages"
                             (removeAlerts)="dismissMessages()"
                             [alerts]="messageList"></jhi-toast-message-component>

<ngx-loading [show]="loadingOverlay"></ngx-loading>

