import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceModel } from '../../../shared/dto/invoice.model';
import { NgBDatePickerConvertService } from '../../../shared/services/ngb.datepicker.convert.service';
import {ExcelImportResult} from "../../../shared/time-series/model/excel-import-result.model";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
    selector: 'jhi-invoice-modal',
    templateUrl: './invoice-modal.component.html',
    styles: []
})
export class InvoiceModalComponent implements OnInit {

    @Input() model: InvoiceModel;
    @Output() modalClose: EventEmitter<InvoiceModel> = new EventEmitter();

    constructor(private dateConverter: NgBDatePickerConvertService,
                public activeModal: NgbActiveModal) {
    }

    ngOnInit() {

    }

    save() {

        this.modalClose.emit(this.model);
    }

    fileuploaderFileChange(files: FileList) {


    }

  public addFile(fileInput: HTMLInputElement) {
    fileInput.value = '';
    fileInput.click();
  }

  public onUpload(event: { file: File, success: boolean, response?: ExcelImportResult, error?: HttpErrorResponse }) {

  }

}
