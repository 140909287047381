import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {QuillModule} from 'ngx-quill';

import {
  UserDeleteDialogComponent,
  UserDialogComponent,
  UserMgmtComponent,
  UserMgmtDeleteDialogComponent,
  UserMgmtDetailComponent,
  UserMgmtDialogComponent
} from '../';
import {DocumentAdminModule} from '../../documents.admin/document.admin.module';
import {LinkAdminModule} from '../../links.admin/link.module';
import {NewsAdminModule} from '../../news.admin/news.admin.module';
import {CezPortalSharedModule} from '../../shared';
import {
  AdditionalDiagGroupEditorComponent
} from '../additional-diag-types/additional-diag-group-editor/additional-diag-group-editor.component';
import {
  AdditionalDiagTypeEditorComponent
} from '../additional-diag-types/additional-diag-type-editor/additional-diag-type-editor.component';
import {AdditionalDiagTypesComponent} from '../additional-diag-types/additional-diag-types.component';
import {AdditionalDiagramService} from '../additional-diag-types/service/additional-diagram-service';
import {AdminFileUploadComponent} from '../admin-file-upload/admin-file-upload.component';
import {CalendarAdminComponent} from '../calendar-admin/calendar-admin.component';
import {CompanyComponent} from '../company/company.component';
import {ContactModalComponent} from '../company/contact.modal.component';
import {ContractComponent} from '../contract/contract.component';
import {
  InvoiceResourceEditorComponent
} from '../invoice-resource/invoice-resource-editor/invoice-resource-editor.component';
import {InvoiceResourceComponent} from '../invoice-resource/invoice-resource.component';
import {PodComponent} from '../pod/pod.component';
import {ProductComponent} from '../product/product.component';
import {ProductModalComponent} from '../product/product.modal.component';
import {RoleEditorComponent} from '../role.editor/role.editor.component';
import {ScheduledTaskComponent} from '../scheduled.task/scheduled.task.component';
import {TermsOfServiceComponent} from '../terms.of.service/terms.of.service.component';
import {AdminTabsComponent} from './admin.tabs.component';
import {AdminTabsRoute} from './admin.tabs.route';
import {AdditionalDiagUtil} from '../additional-diag-types/additional-diag-util';
import {InvoicesModule} from '../../invoices/invoices.module';
import {CoverageTransactionsAdminModule} from '../../coverage-transaction.admin/coverage-transactions.admin.module';
import {CezPortalAdminModule} from "../admin.module";
import {TemplateModalComponent} from "../email/template/template.modal.component";
import {EmailContentModalComponent} from "../email/history/modal/email-content-modal.component";
import {EmailComponent} from "../email/email.component";
import {EmailHistoryComponent} from "../email/history/email-history.component";
import {TemplateComponent} from "../email/template/template.component";
import {EmailViewerComponent} from "../email/history/email-viewer/email-viewer.component";
import {PodMaintenanceComponent} from '../pod-maitenance/pod-maintenance.component';

@NgModule({
  imports: [
    CezPortalSharedModule,
    NewsAdminModule,
    DocumentAdminModule,
    LinkAdminModule,
    QuillModule,
    InvoicesModule,
    CoverageTransactionsAdminModule,
    RouterModule.forRoot([AdminTabsRoute], {useHash: true}),
    CezPortalAdminModule
  ],
  declarations: [
    AdminTabsComponent,
    UserMgmtComponent,
    UserDialogComponent,
    UserDeleteDialogComponent,
    UserMgmtDetailComponent,
    UserMgmtDialogComponent,
    UserMgmtDeleteDialogComponent,
    RoleEditorComponent,
    TermsOfServiceComponent,
    TemplateModalComponent,
    EmailContentModalComponent,
    ScheduledTaskComponent,
    ContractComponent,
    PodComponent,
    CompanyComponent,
    ProductComponent,
    EmailComponent,
    EmailHistoryComponent,
    TemplateComponent,
    EmailViewerComponent,
    ProductModalComponent,
    ContactModalComponent,
    CalendarAdminComponent,
    AdditionalDiagTypesComponent,
    AdditionalDiagTypeEditorComponent,
    AdditionalDiagGroupEditorComponent,
    InvoiceResourceComponent,
    InvoiceResourceEditorComponent,
    AdminFileUploadComponent,
    PodMaintenanceComponent
  ],
  providers: [AdditionalDiagramService, AdditionalDiagUtil],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminTabsModule {
}
