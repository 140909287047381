import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TwoFactorAuthService } from '../two-factor-auth.service';
import {LoginService} from '../../login/login.service';
import {BootstrapAlertService} from '../../shared/services/bootstrap-alert.service';

@Component({
    selector: 'jhi-twofactor-code-activate',
    templateUrl: './twofactor-code-activate.component.html',
    styles: []
})
export class TwofactorCodeActivateComponent implements OnInit {

    activationKey: string;
    twoFactorBase32Code: string;
    randomId: string = Math.random().toString(36).substring(7) + new Date().getTime();

    constructor(private router: Router,
                private loginService: LoginService,
                private twoFactorAuthService: TwoFactorAuthService,
                private bootstrapAlertService: BootstrapAlertService) {
    }

    ngOnInit() {
        this.twoFactorAuthService.getActivationCode().subscribe(r => this.twoFactorBase32Code = r);
    }

    save() {
        this.twoFactorAuthService.activate(this.activationKey).subscribe(() => {
            this.bootstrapAlertService.showSuccess('two-factor-auth.activationSuccess');
            this.loginService.logout();
            this.router.navigate(['/login']);
        });
    }
}
