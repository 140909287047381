import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PagerModel } from '../../shared/dto/page.model';
import { TermsOfService } from './terms.of.service.model';

@Component({
    selector: 'terms-of-service-editor',
    templateUrl: './terms.of.service.component.html',
})
export class TermsOfServiceComponent implements OnInit {
    showNewContainer = false;
    termsOfServiceNew: TermsOfService = new TermsOfService();
    termsOfServices: TermsOfService[];
    isSaving: boolean;

    constructor(private http: HttpClient) {

    }

    ngOnInit() {
        this.refreshList();
    }

    refreshList() {
        this.http.get<PagerModel<TermsOfService>>('/api/terms_of_service/admin').subscribe(r => {
            this.termsOfServices = r.content;
        });
    }

    saveNew(form: NgForm) {
        this.isSaving = true;
        this.termsOfServiceNew.text.textEN = form.value.textEN;
        this.termsOfServiceNew.text.textHU = form.value.textHU;
        this.http.post("/api/terms_of_service/admin", this.termsOfServiceNew).subscribe(r => {
            this.termsOfServiceNew.text.textEN = "";
            this.termsOfServiceNew.text.textHU = "";
            this.toggleNewContainer();
            this.refreshList();
            this.isSaving = false;
        });
    }

    toggleNewContainer() {
        this.showNewContainer = !this.showNewContainer;
    }
}
