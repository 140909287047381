import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Category, JhiLanguageHelper} from '../shared';
import {DocumentModel} from '../shared/dto/document.model';
import {CompanyModel} from '../shared/dto/company.model';
import {ExcelImportResult} from "../shared/time-series/model/excel-import-result.model";


@Component({
  selector: 'ngbd-modal-content',
  templateUrl: 'document.admin.modal.component.html'
})
export class DocumentModalComponent implements OnInit {
  @Input() categories: Category[];
  @Input() doc: DocumentModel;
  @Input() selectedCompany: CompanyModel;
  @Output() modalClose: EventEmitter<any> = new EventEmitter();

  selectedCategory: Category;
  subCategories: Category[];
  selectedSubCategory: Category;
  selectedFile: File;
  isSaving: boolean = false;
  languages: any[];

  constructor(public activeModal: NgbActiveModal,
              private http: HttpClient,
              private languageHelper: JhiLanguageHelper) {
  }

  ngOnInit() {
    if (this.doc.id) {
      this.selectedCategory = this.categories.filter(r => r.subCategories.some(sc => sc.id == this.doc.categories[0].id))[0];
      this.refreshSubCategories();

      if (this.doc.categories[0])
        this.selectedSubCategory = this.subCategories.filter(r => r.id === this.doc.categories[0].id)[0];
    }
    this.languageHelper.getAll().then((languages) => {
      this.languages = languages;
    });
  }

  public addFile(fileInput: HTMLInputElement) {
    fileInput.value = '';
    fileInput.click();
  }

  public onUpload(event: { file: File, success: boolean, response?: ExcelImportResult, error?: HttpErrorResponse }) {
    this.selectedFile = event.file;
  }


  refreshSubCategories() {
    this.subCategories = this.categories.filter(r => r === this.selectedCategory)[0].subCategories;
  }

  onSubmit(form: NgForm) {
    this.isSaving = true;
    let doc = <DocumentModel>form.value;
    let category = new Category();
    category.id = this.selectedSubCategory.id;
    doc.categories = [category];
    if (this.selectedCategory.partnerSpecific) {
      doc.partnerId = this.selectedCompany.id;
    }

    if (this.doc.id)
      this.updateDoc(doc);
    else
      this.createDoc(doc);
  }

  createDoc(doc: DocumentModel) {
    this.http.post<DocumentModel>('/api/document/admin', doc).subscribe(r => {
      let docId = r.id;
      let data = new FormData();
      data.append('file', this.selectedFile);
      this.http.post('/api/document/admin/' + docId + '/file', data).subscribe(() => {
        this.http.patch('/api/document/admin/' + docId + '/visible/' + true, '').subscribe(() => {
          this.modalClose.emit();
          this.isSaving = false;
        });
      });
    });
  }

  updateDoc(doc: DocumentModel) {
    doc.id = this.doc.id;
    this.http.put('/api/document/admin', doc).subscribe(r => {
      if (this.selectedFile) {
        let data = new FormData();
        data.append('file', this.selectedFile);
        this.http.post('/api/document/admin/' + doc.id + '/file', data).subscribe(() => this.modalClose.emit());
      } else
        this.modalClose.emit();

      this.isSaving = false;
    });
  }
}
