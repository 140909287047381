import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ContactDataComponent} from './contact-data.component';
import {RouterModule} from "@angular/router";
import {CONTACT_DATA_ROUTE} from "./contact-data.route";
import {CezPortalSharedModule} from '../shared';

@NgModule({
    imports: [
        CommonModule,
        CezPortalSharedModule,
        RouterModule.forRoot(CONTACT_DATA_ROUTE, {useHash: true})
    ],
    declarations: [ContactDataComponent]
})
export class ContactDataModule {
}
