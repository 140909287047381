import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';

import {LinkByCategoryModel} from './index';
import {NewsService} from '../shared/services/news.service';


@Component({
    selector: 'useful-links-tabs',
    templateUrl: './link-tabs.html'
})
export class LinkTabsComponent implements OnInit {

    public linkCategories: LinkByCategoryModel[];

    constructor(private http: HttpClient,
                public newsService: NewsService) {
    }

    ngOnInit(): void {
        this.refreshLinks();
    }

    refreshLinks(): void {
        this.http.get<LinkByCategoryModel[]>('/api/category/links').subscribe(r => {
            this.linkCategories = r;
        });
    }
}
