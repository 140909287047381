<div class="docBackground px-4">
  <div class="pull-center mt-3">
    <form (ngSubmit)="saveTemplate()" name="filterForm" role="form" #filterForm="ngForm">
      <div class="form-group">
        <label for="selectedTemplate" jhiTranslate="filter.basic.templates">Sablonok</label>
        <mat-select class="form-control" [(ngModel)]="selectedTemplate" name="selectedTemplate" id="selectedTemplate">
          <mat-option *ngFor="let template of viewTemplates" [value]="template">
            {{template.name}}
          </mat-option>
        </mat-select>
      </div>
      <div class="form-row">
        <div class="col-xl-6 my-1">
          <button type="button" class="btn btn-primary btn-block" [disabled]="!selectedTemplate"
                  (click)="loadTemplate()"
                  jhiTranslate="filter.basic.load">Betöltés
          </button>
        </div>
        <div class="col-xl-6 text-xl-right my-1">
          <button type="button" class="btn btn-primary btn-block" [disabled]="!selectedTemplate"
                  (click)="deleteViewTemplate()"
                  jhiTranslate="filter.basic.delete">Törlés
          </button>
        </div>
      </div>
    </form>
    <hr>
    <form (ngSubmit)="runReport()" name="filterForm" role="form" #filterForm="ngForm">
      <div class="form-group">
        <label for="selectedDiagram" jhiTranslate="filter.basic.diagram">Diagram</label>
        <mat-select required name="selectedDiagram" id="selectedDiagram" class="form-control"
                    [(ngModel)]="filterModel.selectedDiagram"
                    (selectionChange)="diagramSelected()">
          <ng-container *ngFor="let diagram of diagramListOptions">
            <mat-option [value]="diagram" [disabled]="diagram.requiredPermission | doesNotHavePermission"
                        *permissionOverlay="diagram.requiredPermission">
              {{translateService.instant(diagram.name)}}
            </mat-option>
          </ng-container>
        </mat-select>
      </div>
      <div *ngIf="filterModel.selectedDiagram">
        <div class="mx-auto text-center my-2">
          <div class="btn-toolbar justify-content-center d-flex" role="toolbar">
            <div *ngFor="let resolution of resolutions">
              <div class="btn-group btn-group-toggle" [(ngModel)]="filterModel.resolution" ngbRadioGroup
                   *permissionOverlay="resolution.requiredPermission"
                   name="resolution">
                <label class="btn btn-primary" ngbButtonLabel
                       [ngClass]="{'unavailable-button': resolution.requiredPermission | doesNotHavePermission}">
                  <input ngbButton type="radio" [value]="resolution.value"
                         [disabled]="!resolution.enabled || (resolution.requiredPermission | doesNotHavePermission)">
                  {{resolution.description}}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-xl-6">
            <label for="selectedPodGroups" jhiTranslate="filter.basic.podGroups">POD group</label>

            <ng-multiselect-dropdown
              name="selectedPodGroups" id="selectedPodGroups"
              [placeholder]="'Select selectedPodGroups'"
              [settings]="dropdownSettings"
              [data]="podGroupsOptions"
              [(ngModel)]="filterModel.selectedPodGroups">
            </ng-multiselect-dropdown>


           <!-- <ss-multiselect-dropdown name="selectedPodGroups" id="selectedPodGroups" [(ngModel)]="filterModel.selectedPodGroups"
                                          [texts]="myTexts"
                                          [options]="podGroupsOptions"
                                          [settings]="mySettings"
                                          ngDefaultControl>
                 </ss-multiselect-dropdown>-->
          </div>
          <div class="form-group col-xl-6">
            <label for="selectedPods" jhiTranslate="filter.basic.pods">POD</label>



            <ng-multiselect-dropdown
              name="selectedPods" id="selectedPods"
              [placeholder]="'Select selectedPods'"
              [settings]="dropdownSettings"
              [data]="podListOptions"
              [(ngModel)]="filterModel.selectedPods">
            </ng-multiselect-dropdown>



     <!--      <ss-multiselect-dropdown name="selectedPods" id="selectedPods" [(ngModel)]="filterModel.selectedPods"
                                     [texts]="myTexts" [options]="podListOptions"
                                     [settings]="mySettings"
                                     ngDefaultControl>
            </ss-multiselect-dropdown>-->
          </div>
        </div>

        <div class="form-row">
          <div class="container">
            <period-parameter-selector [upperBound]="filterModel.multiplePeriod ? 4 : 1"
                                       [(periodParameterModel)]="this.filterModel.periodParameters"></period-parameter-selector>
          </div>
        </div>
        <div class="row" *ngIf="showTemperature">
          <div class="col-xl-6">
            <div class="form-group" *permissionOverlay="'';disabled:isTemperatureAllowed">
              <p-checkbox [label]="translateService.instant('filter.basic.temperature')"
                          name="temperatureChecked"
                          [disabled]="!isTemperatureAllowed"
                          [(ngModel)]="filterModel.temperatureChecked"
                          [binary]="true"></p-checkbox>
            </div>
          </div>
        </div>
        <ng-container
          *ngIf="filterModel.selectedDiagram.referenceIntervalAllowed && filterModel.resolution === 'MIN_15'">
          <div class="row">
            <div class="col-xl-6">
              <div class="form-group" *permissionOverlay="'CONSUMPTION_REFERENCE_INTERVAL'">
                <p-checkbox [label]="translateService.instant('filter.basic.referenceInterval')"
                            name="referenceInterval"
                            [disabled]="'CONSUMPTION_REFERENCE_INTERVAL' | doesNotHavePermission"
                            [(ngModel)]="filterModel.referenceWeekChecked"
                            [binary]="true"></p-checkbox>
              </div>
            </div>
            <div class="col-xl-6" *ngIf="filterModel.referenceWeekChecked">
              <div class="form-group">
                <p-checkbox [label]="translateService.instant('filter.basic.excludeBusinessHolidays')"
                            name="excludeBusinessHolidays"
                            [(ngModel)]="filterModel.excludeBusinessHolidays"
                            [binary]="true"></p-checkbox>
              </div>
            </div>
          </div>
          <period-parameter-selector *ngIf="filterModel.referenceWeekChecked"
                                     [(periodParameterModel)]="filterModel.referenceWeek"
                                     [upperBound]="1"></period-parameter-selector>
        </ng-container>
        <p-selectButton styleClass="form-row selectButton" name="templateOptions" [options]="templateOptions"
                        [(ngModel)]="selectedTemplateType">
          <ng-template let-item>
            <span class="btn btn-primary btn-block">{{item.label | translate}}</span>
          </ng-template>
        </p-selectButton>
        <div class="form-row" *ngIf="!selectedTemplateType">
          <div *ngIf="comparisonAllowed" class="col my-1 min-width-200">
            <ng-container *ngIf="podComparisionEnabled();else intervalComparision">
              <button class="btn btn-primary btn-block"
                      [ngClass]="{'unavailable-button': 'CONSUMPTION_COMPARISION_BY_POD' | doesNotHavePermission}"
                      [disabled]="filterForm.form.invalid"
                      *permissionOverlay="'CONSUMPTION_COMPARISION_BY_POD'"
                      (click)="filterModel.comparePods=true" jhiTranslate="filter.basic.compare">
                Összehasonlítás készítése
              </button>
            </ng-container>
            <ng-template #intervalComparision>
              <ng-container *ngIf="intervalComparisionEnabled();else disabledComparision">
                <button class="btn btn-primary btn-block"
                        [disabled]="filterForm.form.invalid"
                        [ngClass]="{'unavailable-button': 'CONSUMPTION_COMPARISION_BY_INTERVAL' | doesNotHavePermission}"
                        *permissionOverlay="'CONSUMPTION_COMPARISION_BY_INTERVAL'"
                        (click)="filterModel.comparePods=true" jhiTranslate="filter.basic.compare">
                  Összehasonlítás készítése
                </button>
              </ng-container>
            </ng-template>
            <ng-template #disabledComparision>
              <button class="btn btn-primary btn-block"
                      disabled
                      jhiTranslate="filter.basic.compare">
                Összehasonlítás készítése
              </button>
            </ng-template>
          </div>
          <div class="col text-xl-right my-1 min-width-200">
            <button class="btn btn-primary btn-block"
                    [disabled]="filterForm.form.invalid || !showEnabled() "
                    jhiTranslate="filter.basic.show"></button>
          </div>
        </div>
        <div *ngIf="selectedTemplateType">
          <div class="form-row">
            <div class="form-group"
                 [ngClass]="{'col-sm-6' : currentUserAdmin,'col-sm-12' : !currentUserAdmin}">
              <label *ngIf="selectedTemplateType.value===2" for="newViewTemplateName"
                     jhiTranslate="filter.basic.templateName"></label>
              <label *ngIf="selectedTemplateType.value===1" for="newViewTemplateName"
                     jhiTranslate="filter.basic.chartName"></label>
              <input required [(ngModel)]="newViewTemplateName" class="form-control"
                     name="newViewTemplateName" id="newViewTemplateName">
            </div>
            <div class="form-group col-sm-6" *ngIf="currentUserAdmin">
              <label for="selectedUser" jhiTranslate="userManagement.detail.title"></label>
              <select class="form-control" [(ngModel)]="selectedUser" name="selectedUser" id="selectedUser" required>
                <option [ngValue]="{id:null}">
                  ----
                </option>
                <option *ngFor="let user of users" [ngValue]="user">
                  {{user.login}}
                </option>
              </select>
            </div>
          </div>
          <div class="row mb-3" *ngIf="selectedTemplateType.value===2">
            <div class="form-group" class="col-sm-4">
              <label for="selectedIntervalGranularity"
                     jhiTranslate="filter.basic.intervalGranularity"></label>
              <select class="form-control" [(ngModel)]="selectedIntervalGranularity"
                      name="selectedIntervalGranularity" id="selectedIntervalGranularity" required>
                <option></option>
                <option [ngValue]="0">
                  <span jhiTranslate="filter.basic.dynamicDataDay"></span>
                </option>
                <option [ngValue]="1">
                  <span jhiTranslate="filter.basic.dynamicDataWeek"></span>
                </option>
                <option [ngValue]="2">
                  <span jhiTranslate="filter.basic.dynamicDataMonth"></span>
                </option>
              </select>
            </div>
            <div class="form-group" class="col-sm-4">
              <label for="intervalSize" jhiTranslate="filter.basic.intervalMeasure"></label>
              <input required [(ngModel)]="intervalSize" class="form-control" name="intervalSize" id="intervalSize"
                     type="number" min="1"
                     (change)="intervalSizeBlur()">
            </div>
            <div class="form-group" class="col-sm-4">
              <label for="shift" jhiTranslate="filter.basic.shift"></label>
              <input required [(ngModel)]="shift" class="form-control" name="shift" id="shift" type="number" max="0"
                     (change)="shiftBlur()">
            </div>
          </div>
          <div class="form-row">
            <div class="col-xl-6 my-1">
              <button type="button" mat-raised-button color="primary" class="btn-block"
                      (click)="selectedTemplateType = null"
                      jhiTranslate="entity.action.cancel"></button>
            </div>
            <div class="col-xl-6 text-xl-right my-1">
              <button type="button" mat-raised-button color="primary" (click)="saveTemplate()"
                      class="btn-block"
                      [disabled]="filterForm.form.invalid || !anyActionButtonEnabled()"
                      jhiTranslate="entity.action.save"></button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="form-row">
      <div class="col my-1">
        <consumption-export
          [podGroups]="filterModel.selectedPodGroups"
          [pods]="filterModel.selectedPods"
          [startTime]="filterModel.periodParameters.length > 0 ? filterModel.periodParameters[0].dateFrom : undefined"
          [endTime]="filterModel.periodParameters.length > 0 ? filterModel.periodParameters[0].dateTo: undefined"
          [resolution]="filterModel.resolution"
          [disabled]="filterForm.form.invalid || !exportValid() || ('CONSUMPTION_EXCEL_EXPORT' | doesNotHavePermission)"></consumption-export>
      </div>
    </div>
  </div>
</div>
