import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TwoFactorAuthService {

    constructor(private http: HttpClient) {
    }

    activate(code: string): Observable<any> {
        return this.http.post('/api/account/two_factor/key/activate/' + code, null);
    }

    getActivationCode(): Observable<string> {
        return this.http.get('/api/account/two_factor/key/base32', {
            responseType: 'text'
        });
    }

    resetTwoFactorKey(userId: number): Observable<any> {
        return this.http.patch(`/api/user/${userId}/reset_two_factor_key`, null);
    }
}
