        <table class="table table-striped">
          <thead>
            <tr>
              <th>
                <span>#</span>
              </th>
              <th>
                <span jhiTranslate="agreement.agreementNumber"></span>
              </th>
              <th>
                <span jhiTranslate="agreement.agreementData.services.productType"></span>
              </th>
              <th>
                <span jhiTranslate="agreement.begin"></span>
              </th>
              <th>
                <span jhiTranslate="agreement.end"></span>
              </th>
              <th>
                <span jhiTranslate="agreement.agreementData.mainData.bidLanguage"></span>
              </th>

              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let agreement of agreements; let i = index">
              <td>{{i+1}}</td>
              <td>{{agreement.quoteNumber}}</td>
                <td>{{('agreement.agreementData.services.productTypeValues.' + agreement.productType) | translate}}</td>
              <td>{{agreement.startTime | cezDate}}</td>
              <td>{{agreement.endTime | cezDate}}</td>
              <td>
                <span *ngIf="agreement.contractLanguage === 'hu'" class="flag-hu"></span>
                <span *ngIf="agreement.contractLanguage === 'en'" class="flag-en"></span>
              </td>
              <td *ngIf="adminMode">
                <button class="btn btn-info btn-sm" (click)="syncSalesforce(agreement)" matTooltip="{{'agreement.sync.buttonTooltip' | translate}}">
                    <span class="fa fa-refresh"></span>
                </button>
                <button class="btn btn-info btn-sm" (click)="edit(agreement)">
                  <span class="fa fa-pencil"></span>
                </button>
                <button class="btn btn-danger btn-sm" [disabled]="hasDependencies(agreement)" (click)="delete(agreement)">
                  <span class="fa fa-remove"></span>
                </button>
              </td>
              <td *ngIf="!adminMode">
                <button class="btn btn-success btn-sm" (click)="view(agreement)">
                  <span class="fa fa-eye"></span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
