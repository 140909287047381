import {AfterViewInit, Directive, Input, OnDestroy, Optional} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {TabSave} from './tab-save.type';
import {CanLeaveService, IsModified} from '../../can-leave/can-leave.service';
import * as _ from "lodash";
import {NgbNav, NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";

@Directive({
    selector: '[tabSave]',
    exportAs: 'tabSave'
})
export class TabSaveDirective implements AfterViewInit, OnDestroy {

    /**
     * Must be unique for each tab!
     */
    @Input()
    public tabSave: Required<string>;

    @Input()
    public get tabSaveTabsLoaded(): boolean {
        return this._tabSaveTabsLoaded;
    }

    public set tabSaveTabsLoaded(value: boolean) {
        this._tabSaveTabsLoaded = value;

        if (this._tabSaveTabsLoaded && !this.tabLoaded) {
            this.setSavedTab();
        }
    }

    public tabLoaded: boolean = false;

    private _tabSaveTabsLoaded: boolean;
    private _isFormModified: IsModified.Type = false;
    private destroyed: Subject<void> = new Subject<void>();

    constructor(@Optional() private tab: NgbNav,
                private canLeaveService: CanLeaveService) {}


    ngAfterViewInit(): void {
        if (!this.tab) {
            console.warn('TabSaveDirective: No `<ngb-nav>` parent found!', this.tabSave);
            return;
        }
        this.setSavedTab();
        this.subscribeToIsModified();
        this.subscribeToTabChange();
    }

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    public getActiveTab(): string {
        return this.tab.activeId;
    }

    private setSavedTab(): void {
        const tabId: string = sessionStorage.getItem(this.getStorageKey());

        if (!tabId || (!_.isNil(tabId) && this.tab.activeId === tabId)) {
            this.tabLoaded = true;
            return;
        }

        setTimeout(() => {
            if (this.tab?.items?.toArray().length > 0) {
                this.tab?.select(tabId);
                this.tabLoaded = true;
            }
        });
    }

    private subscribeToTabChange(): void {
        this.tab.navChange
            .pipe(takeUntil(this.destroyed))
            .subscribe((event: NgbNavChangeEvent) => {
                if (!this.isTabChangeAllowed()) {
                    event.preventDefault();

                    this.canLeaveService.canLeave(this.onTabChangeConfirmed.bind(this, event));
                    ////////////////////////////////////////////////
                    // or this is the same:
                    ////////////////////////////////////////////////
                    // this.canLeaveService.canLeave()
                    //     .pipe(takeUntil(this.destroyed))
                    //     .subscribe((confirmed?: boolean) => {
                    //         if (confirmed) {
                    //             this.onTabChangeConfirmed(event);
                    //         }
                    //     });
                } else {
                    this.saveSelectedTab(event);
                }
            });
    }

    private isTabChangeAllowed(): boolean {
        if (this.isBoolean(this._isFormModified)) {
            return !this._isFormModified;
        } else {
            return !!this._isFormModified.allowedTabsets.find((item) => item === this.tabSave);
        }
    }

    private isBoolean(item: IsModified.Type): item is boolean {
        return typeof item === 'boolean';
    }

    private onTabChangeConfirmed(event: NgbNavChangeEvent): void {
        this.tab?.select(event.nextId);
    }


    private saveSelectedTab(event: NgbNavChangeEvent): void {
        sessionStorage.setItem(this.getStorageKey(), event.nextId);
    }

    private getStorageKey(): string {
        return `${TabSave.STORAGE_KEY_PREFIX}${this.tabSave}`;
    }

    private subscribeToIsModified() {
        this.canLeaveService.isModified()
            .pipe(takeUntil(this.destroyed))
            .subscribe((value: IsModified.Type) => {
                this._isFormModified = value;
            });
    }

    ////////////////////////////////////////////////////////////
    // USE THESE METHODS IN CASE WE NEED TO HAVE DYNAMIC TAB IDS
    ////////////////////////////////////////////////////////////
    // private setSavedTab(): void {
    //     const savedTab: SavedTab = JSON.parse(sessionStorage.getItem(this.getStorageKey()));
    //
    //     if (!savedTab) {
    //         return;
    //     }
    //
    //     let tabId: string = savedTab.id;
    //
    //     if (this.isDynamicTabId(savedTab.id)) {
    //         const tab: NgbTab = this.tab.tabs.find((item) => item.title === savedTab.title);
    //         tabId = tab.id;
    //     }
    //
    //     if (this.tab.activeId !== tabId) {
    //         setTimeout(() => {
    //             this.tab.select(tabId);
    //         });
    //     }
    // }
    //
    // /**
    //  * We have to save even the title of the tab and not just the id (which would be clean and simple).
    //  *
    //  * Because while the tabs have no ids, ngbTabs generates a new one at each page load.
    //  */
    // private saveSelectedTab(event: NgbTabChangeEvent): void {
    //     let activeTabTitle: string;
    //
    //     if (this.isDynamicTabId(event.nextId)) {
    //         activeTabTitle = this.tab.tabs.find((item) => item.id === event.nextId).title;
    //     }
    //
    //     sessionStorage.setItem(this.getStorageKey(), JSON.stringify({id: event.nextId, title: activeTabTitle}));
    // }
    //
    // private isDynamicTabId(tabId: string): boolean {
    //     return tabId.startsWith('ngb-tab-');
    // }

}
