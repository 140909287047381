<table class="table table-striped">
    <thead>
    <tr>
        <th><span>#</span></th>
        <th><span jhiTranslate="agreement.agreementData.partnerMainData.companyNameHu"></span></th>
        <th><span jhiTranslate="agreement.agreementData.partnerMainData.companyAddress"></span></th>
        <th *ngIf="crudOptions"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let company of companies; let i = index;">
        <td>{{ i + 1 }}</td>
        <td>{{ company.accountName }}</td>
        <td>{{ company.registeredSeatAddress }}</td>
        <td *ngIf="crudOptions">
            <button type="button" class="btn btn-info btn-sm"
                    (click)="syncSalesforce(company.salesforceAccountId)">
                <span class="fa fa-refresh"></span>
            </button>
            <button type="button" class="btn btn-info btn-sm" (click)="edit(company)">
                <span class="fa fa-pencil"></span>
            </button>
            <button type="button" class="btn btn-danger btn-sm" [disabled]="hasDependencies(company)"
                    (click)="delete(company)">
                <span class="fa fa-remove"></span>
            </button>
        </td>
    </tr>
    </tbody>
</table>
