import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductAndServicesUserModel } from '../dto/product-and-services-user.model';
import { ProductAndServicesModel } from '../dto/product-and-services.model';
import { DocumentFilterService } from '../services/document.filter.service';

@Component({
    selector: 'cez-product-list',
    templateUrl: './product-list.component.html',
    styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent {

    @Input() products: (ProductAndServicesModel|ProductAndServicesUserModel|any)[];
    @Input() adminMode: boolean;
    @Output() publishClick: EventEmitter<number> = new EventEmitter();
    @Output() deactivateClick: EventEmitter<number> = new EventEmitter();
    @Output() editClick: EventEmitter<ProductAndServicesModel> = new EventEmitter();
    @Output() deleteClick: EventEmitter<number> = new EventEmitter();

    constructor(public documentFilterService: DocumentFilterService) {
    }

    private isUserModel(item: ProductAndServicesModel|ProductAndServicesUserModel): item is ProductAndServicesUserModel {
        return !this.adminMode;
    }

    getItemTitle(item: ProductAndServicesModel|ProductAndServicesUserModel): string {
        if (this.isUserModel(item)) {
            return `${item.title}`;
        } else {
            return `${item.title.textHU} (${item.title.textEN})`;
        }
    }

    getItemDescription(item: ProductAndServicesModel|ProductAndServicesUserModel): string {
        if (this.isUserModel(item)) {
            return `${item.description}`;
        } else {
            return `${item.description.textHU} (${item.description.textEN})`;
        }
    }


}
