import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '../';


@Injectable()
export class CategoryService {

    constructor(private http: HttpClient) {

    }

    getProductCategories(): Observable<Category[]> {
        return this.http.get<Category[]>('api/category/products_and_services');
    }

    getNewsCategories(): Observable<Category[]> {
        return this.http.get<Category[]>('api/category/news');
    }

    getDocumentsCategories(): Observable<Category[]> {
        return this.http.get<Category[]>('api/category/documents');
    }

    getLinkCategories(): Observable<Category[]> {
        return this.http.get<Category[]>('api/category/links');
    }
}
