<div class="row">
    <div class="col">
        <mat-slide-toggle color="primary" [(ngModel)]="minSelected">{{'marketPrices.diagram-options.min' | translate}}
        </mat-slide-toggle>
    </div>
    <div class="col">
        <mat-slide-toggle color="primary" [(ngModel)]="maxSelected">{{'marketPrices.diagram-options.max' | translate}}
        </mat-slide-toggle>
    </div>
    <div class="col">
        <mat-slide-toggle color="primary" [(ngModel)]="avgSelected">{{'marketPrices.diagram-options.avg' | translate}}
        </mat-slide-toggle>
    </div>
</div>
