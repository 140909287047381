export type ConsumptionNewDiagramType =
    'PERFORMANCE'
    |'DIAGCOLUMN'
    |'DISPERSION'
    |'BOXPLOT'
    |'MINMAXAVG'
    |'HEATMAP'
    |'SCHEDULING';

export const ConsumptionDiagramType = {
    PERFORMANCE: 'PERFORMANCE' as ConsumptionNewDiagramType,
    DIAGCOLUMN: 'DIAGCOLUMN' as ConsumptionNewDiagramType,
    DISPERSION: 'DISPERSION' as ConsumptionNewDiagramType,
    BOXPLOT: 'BOXPLOT' as ConsumptionNewDiagramType,
    MINMAXAVG: 'MINMAXAVG' as ConsumptionNewDiagramType,
    HEATMAP: 'HEATMAP' as ConsumptionNewDiagramType,
    SCHEDULING: 'SCHEDULING' as ConsumptionNewDiagramType
};
