import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { QuillModule } from 'ngx-quill';
import { NewsAdminComponent } from './news.admin.component';
import { NewsAdminModalComponent } from './news.admin.modal.component';
import { NEWS_ADMIN_ROUTE } from './news.admin.route';
import {CezPortalSharedModule} from '../shared';


@NgModule({
    imports: [
        CezPortalSharedModule,
        RouterModule.forRoot([NEWS_ADMIN_ROUTE], { useHash: true }),
        QuillModule
    ],
    declarations: [
        NewsAdminComponent, NewsAdminModalComponent
    ],
    exports: [
        NewsAdminComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class NewsAdminModule { }
