<div class="mt-3 mb-3">
    <p-treeTable [value]="tableData" [scrollable]="true" class="table tree-table">
        <ng-template pTemplate="header">
            <tr>
                <th class="invoice-report-table-column-name">{{'invoiceReport.table.header.name' | translate}}</th>

                <th class="invoice-report-table-column-quantity">{{'invoiceReport.table.header.quantity' | translate}}
                </th>
                <th class="invoice-report-table-column-unit">{{'invoiceReport.table.header.unit' | translate}}</th>
                <th class="invoice-report-table-column-price">{{'invoiceReport.table.header.price' | translate}}
                </th>
                <th class="invoice-report-table-column-value">{{'invoiceReport.table.header.value' | translate}}
                </th>
                <th class="invoice-report-table-column-vat">{{'invoiceReport.table.header.vat' | translate}}</th>
                <th class="invoice-report-table-column-gross">{{'invoiceReport.table.header.gross' | translate}}
                </th>
                <th class="invoice-report-table-column-currency">{{'invoiceReport.table.header.currency' |
                    translate}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr [ngClass]="[rowNode.node.children.length > 0 ? 'header-row' : 'leaf-row', rowNode.node.data.groupName ? 'group-header-row' : '']">

                <td class="invoice-report-table-column-name" *ngIf="rowData.groupName">
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{'invoice-resource.resource-groups.' + rowData.groupName | translate}}
                </td>
                <td *ngIf="rowData.resourceName" class="invoice-report-table-column-name">
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{rowData.resourceName | translateLocalizedText}}
                </td>
                <td *ngIf="rowData.intervalStart" class="invoice-report-table-column-name">
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{rowData.intervalStart | date: 'yyyy. MMMM'}}
                    {{(rowData.intervalStart | date: 'yyyy. MMMM') === (rowData.intervalEnd | date: 'yyyy. MMMM') ?
                    '': ' -- '}}
                    {{(rowData.intervalStart | date: 'yyyy. MMMM') === (rowData.intervalEnd | date: 'yyyy. MMMM') ?
                    '': rowData.intervalEnd | date: 'yyyy. MMMM'}}
                </td>
                <td *ngIf="rowData.podName" class="invoice-report-table-column-name">
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{rowData.podName}}
                </td>

                <td class="invoice-report-table-column-quantity number">{{rowData.value?.quantity |number:'1.2-2'}}</td>
                <td class="invoice-report-table-column-unit">{{rowData.value?.unit}}</td>
                <td class="invoice-report-table-column-price number">{{rowData.value?.price | number:'1.2-2'}}</td>
                <td class="invoice-report-table-column-value number">{{rowData.value?.netValue | number:'1.2-2'}}</td>
                <td class="invoice-report-table-column-vat number">{{rowData.value?.vatValue | number:'1.2-2'}}</td>
                <td class="invoice-report-table-column-gross number">{{rowData.value?.grossValue | number:'1.2-2'}}</td>
                <td class="invoice-report-table-column-currency">{{rowData.value?.currency}}</td>
            </tr>
        </ng-template>
    </p-treeTable>
</div>

