<div class="modal-header">
  <h4 class="modal-title">Invoice</h4>
  <button type="button" class="close" aria-label="Close" (click)="this.activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form name="invoiceForm" role="form" #invoiceForm="ngForm" (ngSubmit)="save()">
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label for="currency">Currency</label>
          <select required id="currency" name="currency" class="form-control" [(ngModel)]="model.currency">
            <option></option>
            <option value="HUF">
              HUF
            </option>
            <option value="EUR">
              EUR
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label for="exchangeMethod">Exchange Method</label>
          <select required id="exchangeMethod" name="exchangeMethod" class="form-control"
                  [(ngModel)]="model.exchangeMethod">
            <option></option>
            <option value="MNB_5_DAYS">
              MNB 5 DAYS
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label for="invoiceType">Invoice Type</label>
          <select required id="invoiceType" name="invoiceType" class="form-control" [(ngModel)]="model.invoiceType">
            <option></option>
            <option value="HUF">
              HUF
            </option>
            <option value="EUR">
              EUR
            </option>
            <option value="MIXED">
              MIXED
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label for="paymentDeadline">Payment Deadline</label>
          <jhi-date-picker [(dpModel)]="model.paymentDeadlineNgB" id="paymentDeadline"></jhi-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label for="startTime">Start Time</label>
          <jhi-date-picker [(dpModel)]="model.startTimeNgB" id="startTime"></jhi-date-picker>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label for="endTime">End Time</label>
          <jhi-date-picker [(dpModel)]="model.endTimeNgB" id="endTime"></jhi-date-picker>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="paymentDeadlineDays">PaymentDeadline Days</label>
      <input required id="paymentDeadlineDays" name="paymentDeadlineDays" [(ngModel)]="model.paymentDeadlineDays"
             class="form-control"
             type="number">
    </div>
    <div class="form-group">
      <label for="unitPrice">Unit Price</label>
      <input required id="unitPrice" name="unitPrice" [(ngModel)]="model.unitPrice" class="form-control" type="number">
    </div>
    <div class="form-group">
      <label for="unitPrices">Unit Prices</label>
      <input required id="unitPrices" name="unitPrices" [(ngModel)]="model.unitPrices" class="form-control"
             type="number">
    </div>
    <div class="form-group">
      <p-checkbox label="Includes RHD" name="includesRHD" [(ngModel)]="model.includesRHD" binary="true"></p-checkbox>
    </div>
    <div class="form-group" *ngIf="model.id > -1">
      <label for="comment">File</label>
      <!--<file-uploader [fileType]="'application/*'" (onSelection)="fileuploaderFileChange($event)" [required]="false"></file-uploader>-->
      <div class="col-auto">
        <div style="display: flex; align-items: center;">
          <input placeholder="{{'select.default' | translate}}" readonly class="form-control"
                 style="width: 200px; float: left; margin-right: 0px;" matInput *ngIf="!fileInput?.httpUrl"
                 value="{{fileInput?.files[0]?.name}}"/>

          <button mat-mini-fab color="primary" (click)="addFile(fileInput)" type="button">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <input #fileInput [hidden]="true" type="file"/>
      </div>

      <cez-file-upload class="col"
                       *ngIf="fileInput.value"
                       [file]="fileInput.files[0]"
                       [autoUpload]="true"
                       (onUpload)="onUpload($event)"
                       (removeEvent)="fileInput.value = ''"></cez-file-upload>
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary"
            [disabled]="invoiceForm.form.invalid">{{'entity.action.save' | translate}}</button>
  </div>
</form>
