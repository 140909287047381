import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoicesComponent } from './invoices.component';
import { InvoiceChartPopup } from './chart/invoice-chart-popup.component';
import { InvoiceReportModule } from '../invoice-report/invoice-report.module';
import {CezPortalSharedModule} from '../shared';

@NgModule({
    imports: [
        CommonModule,
        CezPortalSharedModule,
        InvoiceReportModule
    ],
    declarations: [
        InvoicesComponent,
        InvoiceChartPopup
    ],
    exports: [
        InvoicesComponent
    ]
})
export class InvoicesModule {
}
