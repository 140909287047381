import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TermsOfService } from '../../terms.of.service/terms.of.service.model';

@Injectable()
export class TermsOfServicesService {

    constructor(private http: HttpClient) {
    }

    termsOfServiceNeedsToBeAccepted(): Observable<boolean> {
        return this.http.get<TermsOfService[]>('/api/account/terms_of_service').pipe(map(Boolean));
    }
}
