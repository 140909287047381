<div class="input-group">
    <input class="form-control"
           [(ngModel)]="dpModel"
           name="{{id}}"
           id="{{id}}"
           ngbDatepicker #d="ngbDatepicker"
           (blur)="changeEvent()"
           [markDisabled]="isDayDisabled"
           (dateSelect)="changeEvent()"
           [disabled]="disabled"
           [dayTemplate]="cezDateDesign"
           [minDate]="minDate"
           [maxDate]="maxDate"
           [required]="required"
           [placeholder]="placeholder"
           [readOnly]="readonly">
  <div class="input-group-append">
      <button class="btn btn-outline-secondary" (click)="d.toggle(); click.emit()" type="button" [disabled]="disabled">
      <i class="fa fa-calendar" aria-hidden="true" role="button"></i>
    </button>
  </div>
</div>

<ng-template #cezDateDesign let-date="date" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled"
  let-focused="focused">
  <span class="datepicker-template" [class.bg-primary-cez]="selected" [ngClass]="{'text-muted':date.month !== currentMonth,'opac05':date.month !== currentMonth, 'bg-primary-cez':selected}">
    {{ date.day }}
  </span>
</ng-template>
