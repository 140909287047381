import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminService} from '../admin.service';
import {
    PodMaintenanceSearchPreloadModel,
    PodMaintenanceSearchResultModel,
    PodMaintenanceViewModel
} from './pod-maintenance.model';
import {PodMaintenanceImportResultModel} from '../../pod-maintenance/model/pod-maintenance-import-result.model';
import {HttpErrorResponse} from '@angular/common/http';
import {Violation} from '../../shared/dto/validation.exception.model';
import {TranslateService} from '@ngx-translate/core';
import {DialogService} from '../../shared/dialog/dialog.service';
import {Table, TableLazyLoadEvent} from 'primeng/table';
import moment from 'moment';
import {NgBDatePickerConvertService} from '../../shared/services/ngb.datepicker.convert.service';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {FormControl} from '@angular/forms';
import {SortUtil} from '../../core/util/sort.util';
import {UtilService} from '../../shared/services/util.service';

@Component({
    selector: 'app-pod-maintenance',
    templateUrl: './pod-maintenance.component.html',
    styleUrl: './pod-maintenance.component.scss'
})
export class PodMaintenanceComponent implements OnInit {

    public readonly ROWS_PER_PAGE: number = 10;
    public readonly POD_CODE_FILTER_KEY: string = "podCode";
    public readonly NETWORK_OPERATOR_FILTER_KEY: string = "networkOperator";
    public readonly POD_TYPE_FILTER_KEY: string = "podType";
    public readonly ADDRESS_FILTER_KEY: string = "address";
    public readonly ACCOUNT_NAME_FILTER_KEY: string = "accountName";
    public readonly SF_ID_FILTER_KEY: string = "salesforceQuoteId";
    public readonly START_TIME_FILTER_KEY: string = "startTime";
    public readonly END_TIME_FILTER_KEY: string = "endTime";

    @ViewChild('table') table: Table;

    public networkOperators: string[] = [];
    public accountNames: string[] = [];
    public filteredAccountNames: string[] = [];
    public podTypes: string[] = [];
    public podMaintenanceViews: PodMaintenanceViewModel[] = [];
    public totalRecords: number = 0;

    public podCodeFilterControl: FormControl<string> = new FormControl<string>('');
    public podTypeFilterControl: FormControl<string> = new FormControl<string>('');
    public operatorFilterControl: FormControl<string> = new FormControl<string>('');
    public addressFilterControl: FormControl<string> = new FormControl<string>('');
    public accountNameFilterControl: FormControl = new FormControl('');
    public agreementFilterControl: FormControl<string> = new FormControl<string>('');

    public defaultStartTime: NgbDateStruct = this.datePickerConvertService.convertFromMoment(moment().startOf('year'));
    public defaultEndTime: NgbDateStruct = this.datePickerConvertService.convertFromMoment(moment().endOf('year'));

    constructor(private adminService: AdminService,
                private translateService: TranslateService,
                private dialogService: DialogService,
                private datePickerConvertService: NgBDatePickerConvertService,
                private utilService: UtilService) {
    }

    ngOnInit(): void {
        this.initAccountNameFormControl();
        this.tablePreload();
        this.loadTableData(1,
            this.datePickerConvertService.convertToDate(this.defaultStartTime),
            this.datePickerConvertService.convertToDate(this.defaultEndTime)
        );
    }

    public podAgreementAssignmentUploaded(event: {
        file: File,
        success: boolean,
        response: PodMaintenanceImportResultModel,
        error?: HttpErrorResponse
    }): void {
        const translatedErrors: string[] = event.response.violations.map((violation: Violation): string =>
            this.translateService.instant(`validation.${violation.errorCode}`, {
                excelRowNum: violation.args['excelRowNum'],
                duplicateRowNum: violation.args['duplicateRowNum'],
                podCode: violation.args['podCode'],
                agreementInterval: violation.args['agreementInterval'],
                agreementSfId: violation.args['agreementSfId'],
            })
        );

        this.dialogService.podImportAlert(
            this.translateService.instant("podMaintenance.successImport", [event.response.numberOfImportedRows]),
            translatedErrors
        ).subscribe(() => {
            this.tablePreload();
            this.loadTableData(1,
                this.datePickerConvertService.convertToDate(this.defaultStartTime),
                this.datePickerConvertService.convertToDate(this.defaultEndTime),
                true
            );
        });
    }

    public lazyLoadPodAgreementAssignments(event: TableLazyLoadEvent): void {
        const currentPage: number = event.first / this.ROWS_PER_PAGE + 1;
        const podCodeFilter: string | undefined = (event.filters[this.POD_CODE_FILTER_KEY] as FilterMetadata | undefined)?.value as string;
        const networkOperatorFilter: string | undefined = (event.filters[this.NETWORK_OPERATOR_FILTER_KEY] as FilterMetadata | undefined)?.value as string;
        const podTypeFilter: string | undefined = (event.filters[this.POD_TYPE_FILTER_KEY] as FilterMetadata | undefined)?.value as string;
        const addressFilter: string | undefined = (event.filters[this.ADDRESS_FILTER_KEY] as FilterMetadata | undefined)?.value as string;
        const accountNameFilter: string | undefined = (event.filters[this.ACCOUNT_NAME_FILTER_KEY] as FilterMetadata | undefined)?.value as string;
        const salesforceQuoteIdFilter: string | undefined = (event.filters[this.SF_ID_FILTER_KEY] as FilterMetadata | undefined)?.value as string;
        const startTimeFilter: NgbDateStruct | undefined = (event.filters[this.START_TIME_FILTER_KEY] as FilterMetadata | undefined)?.value as NgbDateStruct;
        const endTimeFilter: NgbDateStruct | undefined = (event.filters[this.END_TIME_FILTER_KEY] as FilterMetadata | undefined)?.value as NgbDateStruct;

        this.loadTableData(
            currentPage,
            this.datePickerConvertService.convertToDate(startTimeFilter || this.defaultStartTime),
            this.datePickerConvertService.convertToDate(endTimeFilter || this.defaultEndTime),
            false,
            podCodeFilter,
            this.utilService.noAssignmentOrDefault(networkOperatorFilter),
            this.utilService.noAssignmentOrDefault(podTypeFilter),
            addressFilter,
            this.utilService.noAssignmentOrDefault(accountNameFilter),
            salesforceQuoteIdFilter
        );
    }

    public generatePodAgreementAssignmentTemplate(): void {
        this.adminService.generatePodAgreementAssignmentTemplate();
    }

    private tablePreload(): void {
        this.adminService.loadPodAgreementAssignmentFilters().subscribe((preload: PodMaintenanceSearchPreloadModel): void => {
            this.networkOperators = preload.filters[this.NETWORK_OPERATOR_FILTER_KEY].sort(SortUtil.compareHungarianFunc) || [];
            this.accountNames = preload.filters[this.ACCOUNT_NAME_FILTER_KEY].sort(SortUtil.compareHungarianFunc) || [];
            this.filteredAccountNames = this.accountNames;
            this.podTypes = preload.filters[this.POD_TYPE_FILTER_KEY].sort(SortUtil.compareHungarianFunc) || [];
        });
    }

    private loadTableData(page: number, startTimeFilter: Date, endTimeFilter: Date, resetPage: boolean = false,
                          podCodeFilter?: string, networkOperatorFilter?: string, podTypeFilter?: string,
                          addressFilter?: string, accountNameFilter?: string, salesforceQuoteIdFilter?: string): void {
        this.adminService.searchPodAgreementAssignments({
            pagination: true,
            currentPage: page,
            pageSize: this.ROWS_PER_PAGE,
            podCode: podCodeFilter,
            networkOperator: networkOperatorFilter,
            podType: podTypeFilter,
            address: addressFilter,
            accountName: accountNameFilter,
            salesforceQuoteId: salesforceQuoteIdFilter,
            startTime: startTimeFilter,
            endTime: endTimeFilter
        }).subscribe((searchResult: PodMaintenanceSearchResultModel): void => {
            this.podMaintenanceViews = searchResult.podMaintenanceViews;
            this.totalRecords = searchResult.totalRecords;
            if (resetPage) {
                this.podCodeFilterControl.reset();
                this.podTypeFilterControl.reset();
                this.operatorFilterControl.reset();
                this.addressFilterControl.reset();
                this.accountNameFilterControl.reset();
                this.agreementFilterControl.reset();
                this.defaultStartTime = this.datePickerConvertService.convertFromMoment(moment().startOf('year'));
                this.defaultEndTime = this.datePickerConvertService.convertFromMoment(moment().endOf('year'));
                this.table.first = 0;
                this.table.clearFilterValues();
            }
        });
    }

    private initAccountNameFormControl(): void {
        this.accountNameFilterControl.valueChanges.subscribe((filterValue: string): void => {
            if (!filterValue || filterValue.length == 0) {
                this.filteredAccountNames = this.accountNames;
            } else {
                this.filteredAccountNames = this.accountNames
                    .filter((accountName: string) => accountName.toLowerCase().includes(filterValue.toLowerCase()))
                    .sort();
            }
        });
    }
}
