<div class="row" [formGroup]="form">
    <div class="col">
        <mat-form-field>
            <mat-select
                placeholder="{{'consumption.display-mode.resolution' | translate }}"
                formControlName="resolution"
                required>
                <mat-option *ngFor="let entry of timeSeriesResolutions | keys" [value]="entry.value.value">
                    {{entry.value.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col" *ngIf="refIntervalVisible()">
        <mat-form-field>
            <input matInput [matDatepicker]="fromPicker"
                   placeholder="{{'consumption.display-mode.referenceinterval.from' | translate }}"
                   formControlName="from"
                   intervalRestrictor
                   [min]="min"
                   [max]="max"
                   required>
            <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
            <mat-datepicker #fromPicker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col" *ngIf="refIntervalVisible()">
        <mat-form-field>
            <input matInput [matDatepicker]="toPicker"
                   placeholder="{{'consumption.display-mode.referenceinterval.to' | translate }}"
                   formControlName="to"
                   intervalRestrictor
                   [min]="min"
                   [max]="max"
                   required>
            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
            <mat-datepicker #toPicker></mat-datepicker>
        </mat-form-field>
    </div>

</div>



