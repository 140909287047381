<div class="header-margin-bottom">
  <button (click)="addNewOfferClaim()"
          [disabled]="newClaimDialogOpened"
          class="btn btn-primary">{{ 'offerClaim.button.addClaim' | translate }}
  </button>
</div>

<div *ngFor="let groupedOfferClaimDto of groupedOfferClaimDtos">
  <mat-card class="bg-white">
    <mat-card-title>
      {{ groupedOfferClaimDto.partner.accountName }}
      <ng-container *ngIf="!!groupedOfferClaimDto.partner.scheduledQuotation">
        <span class="mr-1"> - {{ groupedOfferClaimDto.partner.scheduledQuotation | date:'yyyy.MM.dd HH:mm' }} </span>
        <countdown
          *ngIf="groupedOfferClaimDto.partner.scheduledQuotationTimer > 0 && !groupedOfferClaimDto.partner.isScheduledQuotationTimerMoreThan100Days"
          [config]="{
              leftTime: groupedOfferClaimDto.partner.scheduledQuotationTimer,
              format: scheduledQuotationCountdownFormat,
              formatDate: formatDate
          }"
          (event)="$event.action === 'done' ? groupedOfferClaimDto.partner.scheduledQuotationTimer = -1 : null"
          class="text--cez-red cursor--default">
        </countdown>
        <span *ngIf="groupedOfferClaimDto.partner.isScheduledQuotationTimerMoreThan100Days" class="text--cez-red">
                    {{ 'offerClaim.scheduledQuotation.timer.moreThan100Days' | translate }}
                </span>
        <span *ngIf="groupedOfferClaimDto.partner.scheduledQuotationTimer <= 0" class="text--cez-red">
                    {{ 'offerClaim.scheduledQuotation.timer.finished' | translate }}
                </span>
      </ng-container>
    </mat-card-title>
    <mat-card-content>
      <div class="container-fluid">
        <form #registrationRequestForm="ngForm"
              (ngSubmit)="onSubmit(registrationRequestForm, groupedOfferClaimDto)">
          <div class="d-flex row">
            <p-table #dt [value]="groupedOfferClaimDto.offerClaims"
                     class="table tasks-table">
              <ng-template pTemplate="header">
                <tr>
                  <th class="text-center head-th">
                    <span jhiTranslate="offerClaim.tableField.dealId"></span>
                  </th>
                  <th class="text-center head-th">
                    <span jhiTranslate="offerClaim.tableField.createdByPartner"></span>
                  </th>
                  <th class="text-center head-th offer-claim-quotation-requester-column-width">
                    <span jhiTranslate="offerClaim.tableField.contractingAuthor"></span>
                  </th>
                  <th class="text-center head-th offer-claim-period-column-width">
                    <span jhiTranslate="offerClaim.tableField.market"></span>
                  </th>
                  <th class="text-center head-th offer-claim-quotation-requester-column-width">
                    <span jhiTranslate="agreement.agreementData.services.productType"></span>
                  </th>
                  <th class="text-center head-th offer-claim-quotation-requester-column-width">
                    <span jhiTranslate="offerClaim.tableField.deliveryPeriod"></span>
                  </th>
                  <th class="text-center head-th offer-claim-period-column-width">
                    <span jhiTranslate="offerClaim.tableField.productName"></span>
                  </th>
                  <th class="text-center head-th">
                    <span jhiTranslate="offerClaim.tableField.startDate"></span>
                  </th>
                  <th class="text-center head-th">
                    <span jhiTranslate="offerClaim.tableField.endDate"></span>
                  </th>
                  <th class="text-center head-th">
                    <span jhiTranslate="offerClaimHistory.tableField.purchaseType"></span>
                  </th>
                  <th class="text-center head-th offer-claim-quantity-column-width">
                    <span jhiTranslate="offerClaim.tableField.quantityMw"></span>
                  </th>
                  <th class="text-center head-th">
                    <span jhiTranslate="offerClaim.tableField.price"></span>
                  </th>
                  <th class="text-right">
                    <span jhiTranslate="offerClaim.tableField.netPrice"></span>
                  </th>
                  <th class="text-center"></th>
                </tr>
                <tr>
                  <th></th>
                  <th>
                    <mat-select
                      class="pt-2 pb-2 head-select"
                      (selectionChange)="dt.filter($event.value, 'createdByPartner', 'equals')"
                      placeholder="{{'global.choose' | translate}}">
                      <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                      <mat-option
                        [value]="true">{{ 'offerClaim.createdByPartner.true' | translate }}
                      </mat-option>
                      <mat-option
                        [value]="false">{{ 'offerClaim.createdByPartner.false' | translate }}
                      </mat-option>
                    </mat-select>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>
                    <mat-select
                      class="pt-2 pb-2 head-select"
                      (selectionChange)="dt.filter($event.value, 'deliveryPeriod.id', 'equals')"
                      placeholder="{{'global.choose' | translate}}">
                      <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                      <mat-option
                        *ngFor="let deliveryPeriod of getDeliveryPeriods(groupedOfferClaimDto.partner.id)"
                        [value]="deliveryPeriod.id">
                        {{ deliveryPeriod.startTime | cezDate }}
                        - {{ deliveryPeriod.endTime | cezDate }}
                      </mat-option>
                    </mat-select>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                  <td>{{ rowData.dealId }}</td>
                  <td>{{ 'offerClaim.createdByPartner.' + rowData.createdByPartner | translate }}</td>
                  <td>{{ getQuotationRequester(rowData.quotationRequester) }}</td>
                  <td>{{ rowData.market }}</td>
                  <td>{{ 'agreement.agreementData.services.productTypeValues.' + rowData.productType | translate }}</td>
                  <td class="text-center ">{{ rowData.deliveryPeriod.startTime | cezDate }}
                    - {{ rowData.deliveryPeriod.endTime | cezDate }}
                  </td>
                  <td>{{ getProductName(rowData) }}</td>
                  <td>{{ rowData.startDate | cezDate }}</td>
                  <td>{{ rowData.endDate | cezDate }}</td>
                  <td class="text-center">
                    {{ translatePurchaseStatus(rowData) }}
                  </td>
                  <td
                    class="text-right">{{ (rowData.quantity !== null ? rowData.quantity : rowData.tranche) | number: '1.3-3' }}
                  </td>
                  <td class="text-right">
                    <input type="text"
                           name="price-{{rowData.id}}"
                           mask="separator.2"
                           autocomplete="off"
                           class="text-right form-control"
                           maxlength="7"
                           (keydown)="onKeyDown($event)"
                           [(ngModel)]="rowData.netPrice"
                           thousandSeparator="{{'number.thousandSeparator' | translate}}"
                           [decimalMarker]="'number.decimalMarker' | translate"
                           [dropSpecialCharacters]="true"
                           [validation]="true"
                           (focusout)="priceFocusOutEvent($event, groupedOfferClaimDto, rowData)">
                  </td>
                  <td class="text-right">{{ calcNetPrice(rowData) | number:'1.0-0' }}</td>
                  <td>
                    <button class="btn btn-danger btn-sm"
                            type="button"
                            matTooltip="{{'offerClaim.tooltip.delete' | translate}}"
                            (click)="delete(rowData.id)">
                      <span class="fa fa-remove"></span>
                    </button>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr class="net-price-sum-row">
                  <td colspan="12" class="text-left">{{ 'offerClaim.tableField.netPriceSum' | translate }}</td>
                  <td class="text-right">{{ calcSumNetPrice(groupedOfferClaimDto) | number:'1.0-0' }}</td>
                  <td colspan="1"></td>
                </tr>
              </ng-template>
            </p-table>
          </div>
          <div class="row">
            <div class="col"
                 *ngIf="!!openPositionStatePartnerMap.get(groupedOfferClaimDto.partner.id)">
              <div class="row align-items-center justify-content-end pt-2 pb-2"
                   *ngFor="let agreementState of openPositionStatePartnerMap.get(groupedOfferClaimDto.partner.id).agreementOpenPositionStates">
                <div class="col-6">
                  <div class="row align-items-center"
                       *ngFor="let periodState of agreementState.deliveryPeriodOpenPositionStates">
                    <div class="col period-custom-col-auto" *ngIf="!!periodState.deliveryPeriodOpenPositionPercent">
                          <span class="font-weight-bold">
                            {{ getPeriodProgressBarLabel(periodState) }}
                          </span>
                    </div>
                    <div class="col-4 progress-col">
                      <div class="progress">
                        <div class="progress-bar"
                             role="progressbar"
                             [progressbar]="getProgressBarConfig(periodState.deliveryPeriodOpenPositionPercent)">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6" *ngIf="!!agreementState.agreementOpenPositionPercent">
                  <div class="row justify-content-start align-items-center">
                    <div class="col agreement-custom-col-auto">
                          <span class="progress-bar-label">
                            {{ 'offerClaim.progressBar.agreementBarLabel' | translate }}
                          </span>
                    </div>
                    <div class="col-4 progress-col">
                      <div class="progress">
                        <div class="progress-bar"
                             role="progressbar"
                             [progressbar]="getProgressBarConfig(agreementState.agreementOpenPositionPercent)">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto selection-cell">
              <button [disabled]="isDisabledSendQuotationButton(groupedOfferClaimDto, registrationRequestForm)"
                      class="btn btn-primary pull-right ml-2">{{ 'offerClaim.button.sendQuotation' | translate }}
              </button>
              <div>
                <deadline-selector [valueChange]="selectDeadLine(groupedOfferClaimDto)"></deadline-selector>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <small class="form-text text-danger pull-right"
                         *ngIf="registrationRequestForm.controls['deadline'] != null && registrationRequestForm.controls['deadline'].errors">
                    {{ 'offerClaim.errors.deadlineRequirements' | translate }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</div>


