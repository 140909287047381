<header>
  <h2 jhiTranslate="footer.contact"></h2>
</header>

<p>CEZ Magyarország Kft.
  <br> 1118 Budapest, Rétköz utca 5.</p>
<p>Tel.: +36 1 266 9324
  <br> Fax: +36 1 266 9331</p>

<form #contactPageForm="ngForm">
  <div class="card px-3 py-3 mt-3 bg-grey">
    <div class="form-group row">
        <label class="col-sm-4" for="message" jhiTranslate="contact.message"></label>
      <div class="col-sm-8">
        <div class="input-group">
          <textarea required rows="10" type="text" class="form-control" id="message" name="message" [(ngModel)]="message"></textarea>
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-primary btn-wide-250 pull-right mt-3" [disabled]="contactPageForm.form.invalid" (click)="submit()"
          jhiTranslate="contact.sendButton">Mentés
  </button>
</form>
