import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AdditionalDiagramGroupDTO} from "../../admin/additional-diag-types/model/additional-diagram-group.model";

@Pipe({name: 'translateAdditionalDiagramGroup'})
export class TranslateAdditionalDiagramGroup implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(additionalDiagramGroup: AdditionalDiagramGroupDTO): string {
        switch (this.translate.currentLang) {
            case 'hu':
                return additionalDiagramGroup.groupNameHU;
            case 'en':
                return additionalDiagramGroup.groupNameEN;
        }
    }
}
