import {Component, Input} from '@angular/core';
import {ParameterViewerModel, ParameterViewerType} from './parameter-viewer.model';
import {TranslateService} from "@ngx-translate/core";
import {DialogService} from "../dialog/dialog.service";

@Component({
    selector: 'jhi-parameter-viewer',
    templateUrl: './parameter-viewer.component.html',
    styleUrls: ['./parameter-viewer.component.scss']
})
export class ParameterViewerComponent {

    @Input() parameters: ParameterViewerModel[] = [];

    public ParameterViewerType: typeof ParameterViewerType = ParameterViewerType;

    constructor(private translate: TranslateService,
                private dialogService: DialogService) {
    }

    public getParamName(param: ParameterViewerModel): string {
        return !!param.nameI18nPrefix ? param.nameI18nPrefix + param.name : param.name;
    }

    public getParamValue(param: ParameterViewerModel): string {
        return !!param.valueI18nPrefix ? param.valueI18nPrefix + param.value : param.value;
    }

    public getInfo(param: ParameterViewerModel) {
        this.dialogService.alert(' ', this.translate.instant(param.info));
    }

}
