import { PodModel } from '../../shared/dto/pod.model';
import { PodGroupModel } from '../../shared/dto/pod-group.model';
import { PeriodParameterModel } from '../../shared/dto/period-parameter.model';

export class FilterModel {
    selectedPods: PodModel[] = [];
    selectedPodGroups: PodGroupModel[] = [];
    resolution: string;
    temperatureChecked: boolean;
    referenceWeekChecked: boolean;
    excludeBusinessHolidays: boolean = false;
    avarageWeekDay: boolean;
    avarageHoliday: boolean;
    selectedDiagram: any;
    periodParameters: PeriodParameterModel[];
    referenceWeek: PeriodParameterModel[];
    multiplePeriod: boolean;
    comparePods: boolean = false;
    shift: number;
    intervalSize: number;
    selectedIntervalGranularity: number;

    minSelected: boolean;
    maxSelected: boolean;
    avgSelected: boolean;
    perfSelected: boolean;
    scheduleSelected: boolean;
}
