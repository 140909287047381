import {Route} from '@angular/router';


import {CorporateDataComponent} from "./corporate-data.component";
import {UserRouteAccessService} from '../shared';
import {PartnerMandatoryGuard} from '../shared/partner-mandatory.guard';

export const CORPORATE_DATA_ROUTE: Route[] = [
    {
        path: 'corporate-data',
        component: CorporateDataComponent,
        data: {
            pageTitle: 'main.link.corporateData'
        },
        canActivate: [UserRouteAccessService, PartnerMandatoryGuard]
    }
];
