import {DatePipe, DecimalPipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Chart} from 'angular-highcharts';
import {forkJoin, Observable, Subject} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {DiagramType} from '../../admin/additional-diag-types/model/diagram-type';
import {ConsumptionDiagramType} from '../../consumption/consumption-diagram-type';
import {FilterModel} from '../../consumption/filter-basic/filter.model';
import {PodValueRequest} from '../../shared/dto/pod.value.request';
import {NgBDatePickerConvertService} from '../../shared/services/ngb.datepicker.convert.service';
import {PodValueService} from '../../shared/services/pod-value.service';
import {PeriodParameterModel} from '../dto/period-parameter.model';
import {ValueStats} from '../dto/value.stats.model';
import {Resolution} from '../time-series/model/resolution.enum';
import moment, {Moment} from 'moment';

export interface DiagramChangeEvent {
  chart: any,
  type: 'legendItemClick',
  value: any
}

@Injectable()
export class DiagramService {

  seriesColors = ['#f24f00', '#b8b3ad', '#f77f45', '#737373'];
  plotBands = [];
  public diagramChanged: Subject<DiagramChangeEvent> = new Subject<DiagramChangeEvent>();

  constructor(private dateConverter: NgBDatePickerConvertService,
              private podValueService: PodValueService,
              private translateService: TranslateService,
              private decimalPipe: DecimalPipe,
              private datePipe: DatePipe) {
  }

  getAllDiagram() {
    return [{
      id: 1,
      name: 'consumption.performance_consumptionText',
      minMaxAllowed: true,
      avarageAllowed: true,
      referenceIntervalAllowed: true,
      comaparisonAllowed: true,
      temperatureAllowed: true,
      resolutionAllowed: [true, true, true, true, true, true, true], //Negyedórás, Órás, Napi, Heti, Havi, Negyedéves, Éves
      requiredPermission: 'CONSUMPTION_CHART_CONSUMPTION',
      icon: 'fa fa-line-chart fa-2'
    }, {
      id: 2,
      name: 'consumption.performance_consumptionText_runDown',
      minMaxAllowed: false,
      avarageAllowed: true,
      referenceIntervalAllowed: false,
      comaparisonAllowed: true,
      temperatureAllowed: true,
      resolutionAllowed: [true, true, true, true, true, true, true], //Negyedórás, Órás, Napi, Heti, Havi, Negyedéves, Éves
      requiredPermission: 'CONSUMPTION_CHART_CONSUMPTION_RANKING',
      icon: 'fa fa-bar-chart fa-2'
    }, {
      id: 3,
      name: 'consumption.dispersion',
      minMaxAllowed: false,
      avarageAllowed: false,
      referenceIntervalAllowed: false,
      comaparisonAllowed: false,
      temperatureAllowed: false,
      resolutionAllowed: [true, true, true, true, true, true, true], //Negyedórás, Órás, Napi, Heti, Havi, Negyedéves, Éves
      requiredPermission: 'CONSUMPTION_CHART_DISTRIBUTION',
      icon: 'fa fa-tasks fa-2'
    }, {
      id: 4,
      name: 'consumption.boxPlot',
      minMaxAllowed: false,
      avarageAllowed: false,
      referenceIntervalAllowed: false,
      comaparisonAllowed: false,
      temperatureAllowed: true,
      resolutionAllowed: [false, false, true, true, true, true, false], //Negyedórás, Órás, Napi, Heti, Havi, Negyedéves, Éves
      requiredPermission: 'CONSUMPTION_CHART_BOX_PLOT',
      icon: 'fa fa-life-ring fa-2'
    }, {
      id: 5,
      name: 'consumption.minMaxAverage',
      minMaxAllowed: true,
      avarageAllowed: true,
      referenceIntervalAllowed: false,
      comaparisonAllowed: false,
      temperatureAllowed: true,
      resolutionAllowed: [false, false, true, true, true, true, false], //Negyedórás, Órás, Napi, Heti, Havi, Negyedéves, Éves
      requiredPermission: 'CONSUMPTION_CHART_MIN_MAX_AVG',
      icon: 'fa fa-area-chart fa-2'
    }, {
      id: 6,
      name: 'consumption.heatmap',
      minMaxAllowed: false,
      avarageAllowed: false,
      referenceIntervalAllowed: false,
      comaparisonAllowed: false,
      temperatureAllowed: false,
      resolutionAllowed: [true, false, false, false, false, false, false], //Negyedórás, Órás, Napi, Heti, Havi, Negyedéves, Éves
      requiredPermission: 'CONSUMPTION_CHART_HEATMAP',
      icon: 'fa fa-sun-o fa-2'
    }];
  }

  calculateMultiplePeriodChartData(filterModel: FilterModel): Observable<any> {
    return this.podValueService.getMultiPeriodValues(filterModel).pipe(map(series => {
      let seriesWithDate = [];

      series.map((podvalueResponse, idx) => {
        seriesWithDate[idx] = {
          valueStats: podvalueResponse.valueStats,
          pointStart: moment(filterModel.periodParameters[0].dateFrom).valueOf(),
          pointInterval: this.getPointInterval(filterModel.resolution),
          pointIntervalUnit: this.getPointIntervalUnit(filterModel.resolution),
          data: podvalueResponse.valuesKW
        };
      });

      let indexedData = this.calculateMultiplePeriodData(seriesWithDate);
      let chartData: any[] = [];
      let i = 0;
      for (let legend of seriesWithDate) {
        let dateFrom = this.dateConverter.convertToDate(filterModel.periodParameters[0].dateFrom);
        let legendFromString = moment(this.dateConverter.convertToDate(filterModel.periodParameters[i].dateFrom)).format('l');
        let dateToString = moment(this.dateConverter.convertToDate(filterModel.periodParameters[i].dateTo)).format('l');
        let isAggregatedValues = (filterModel.selectedPods.length + filterModel.selectedPodGroups.length) > 1;
        let legendName = '';
        if (isAggregatedValues)
          this.translateService.get('consumption.aggregatedValues').subscribe(aggregatedValuesText => {
            legendName = aggregatedValuesText;
          });
        else
          legendName = filterModel.selectedPods.length > 0 ? filterModel.selectedPods[0].podCode : filterModel.selectedPodGroups[0].name;

        chartData.push({
          name: `${legendName}: ${legendFromString} - ${dateToString}`,
          color: this.seriesColors[i],
          pointStart: moment(dateFrom).valueOf(),
          pointInterval: this.getPointInterval(filterModel.resolution),
          pointIntervalUnit: this.getPointIntervalUnit(filterModel.resolution),
          data: legend.data,
          lineWidth: 2,
          dateFrom: dateFrom.toISOString(),
          valueStats: seriesWithDate[i].valueStats
        });
        i++;
      }

      return {dataWithDate: seriesWithDate, chartData: chartData};
    }));
  }

  calculateMultiplePeriodData(multiPodValues: any[]) {
    if (multiPodValues.length === 0) {
      return [];
    }

    const min = multiPodValues.reduce((length, series) =>
      length > series.data.length ? series.data.length : length, multiPodValues[0].data.length);

    return multiPodValues.map(podValues => {
      return podValues.data.slice(0, min).map((r, i) => {
        if (multiPodValues[0].data[i] && r)
          return [multiPodValues[0].data[i][0], r[1]];
      });
    });
  }

  calculateTemperatureSeries(filterModel: FilterModel, chartData: any) {
    if (chartData.length === 0)
      return;

    let subscriptions = [];
    let periodsCount = filterModel.periodParameters.filter(r => r.dateTo).length;
    let dateFrom = this.dateConverter.convertToDate(filterModel.periodParameters[0].dateFrom);
    for (let multiPeriodIndex = 0; multiPeriodIndex < periodsCount; multiPeriodIndex++) {
      let apiParams = this.createApiParamsFromFilter(filterModel, multiPeriodIndex);
      subscriptions.push(this.podValueService.getTemperatureValues(apiParams).pipe(map(temperature => {
        chartData.push({
          name: periodsCount === 1 ?
            this.translateService.instant('filter.basic.temperature') :
            `${this.translateService.instant('filter.basic.temperature')} ${chartData[multiPeriodIndex].name}`,
          color: chartData[multiPeriodIndex].color,
          type: filterModel.selectedDiagram.id === 2 ? 'area' : 'line',
          dashStyle: 'LongDashDot',
          marker: {
            symbol: 'diamond'
          },
          temperature: true,
          valueSuffix: ' °C',
          yAxis: 1,
          pointStart: moment(dateFrom).valueOf(),
          pointInterval: this.getPointInterval(temperature.resolution),
          pointIntervalUnit: this.getPointIntervalUnit(temperature.resolution),
          data: temperature.valuesCelsius
        });
      })));
    }
    return forkJoin(subscriptions);
  }

  calculateMTemperatureSeriesForRanking(filterModel: FilterModel, sortedDataWithOriginalIndexes: any[], chartData: any[]) {
    if (sortedDataWithOriginalIndexes.length === 0)
      return;

    let subscriptions = [];
    for (let seriesIndex = 0; seriesIndex < sortedDataWithOriginalIndexes.length; seriesIndex++) {
      const apiParams = this.createApiParamsFromFilter(filterModel, 0);
      const sortedDataWithOriginalIndexesCopy = sortedDataWithOriginalIndexes[seriesIndex].concat();
      const datesInOrder = sortedDataWithOriginalIndexesCopy.sort(function (a, b) {
        return a[0] > b[0] ? 1 : -1;
      });
      subscriptions.push(this.podValueService.getTemperatureValues(apiParams).pipe(map(temperature => {
        const temperatureWithDates = temperature.valuesCelsius.map((r, i) =>
          [datesInOrder[i][0], r]);

        chartData.push({
          name: `${this.translateService.instant('filter.basic.temperature')} ${chartData[seriesIndex].name}`,
          color: chartData[seriesIndex].color,
          type: filterModel.selectedDiagram.id === 2 ? 'area' : 'line',
          zIndex: -1,
          fillOpacity: 0.1,
          marker: {
            symbol: 'diamond'
          },
          yAxis: 1,
          temperature: true,
          data: sortedDataWithOriginalIndexes[seriesIndex].map((r, i) => {
            const relatedDateTemperatureArray = temperatureWithDates.find(y => y[0] == r[0]);
            const yAxisValue = relatedDateTemperatureArray ? relatedDateTemperatureArray[1] : null;
            return [i + 1, yAxisValue];
          }),
          tooltip: {formatter: null, valueSuffix: ' °C'}
        });

      })));
    }
    return forkJoin(subscriptions);
  }

  calculateComparedChartData(apiParams: PodValueRequest, legendNames: string[], sortDesc: boolean = false): Observable<any[]> {

    return this.podValueService.getComparedValues(apiParams).pipe(map(r => {
      if (sortDesc)
        r.map(response => response.valuesKW = response.valuesKW.sort((n1, n2) => n2 - n1));

      let chartData: any[] = [];
      let i = 0;
      for (let podresponse of r) {
        chartData.push({
          name: legendNames[i],
          color: this.seriesColors[i],
          lineWidth: 2,
          pointStart: moment(podresponse.startTime).valueOf(),
          pointInterval: this.getPointInterval(podresponse.resolution),
          pointIntervalUnit: this.getPointIntervalUnit(podresponse.resolution),
          data: podresponse.valuesKW,
          valueStats: podresponse.valueStats
        });
        i++;
      }

      return chartData;
    }));
  }

  addDateByResolution(startDate: string, previousDate: Date, index: number, resolution: string): Date {
    if (!previousDate)
      return new Date(startDate);

    // +15 min / +1 day
    switch (resolution) {
      case 'MIN_15':
        previousDate.setTime(previousDate.getTime() + 900000);
        break;
      case 'HOUR': {

        let dateByIndex = new Date(startDate).setHours(new Date(startDate).getHours() + index);
        let valueByPrevDate = new Date(Date.UTC(previousDate.getFullYear(), previousDate.getMonth(), previousDate.getDate(), previousDate.getHours())).setHours(previousDate.getHours() + 1);
        //time saving days (03.29, 10.25)
        if (dateByIndex <= valueByPrevDate) {
          previousDate.setHours(previousDate.getHours() + 1);
        }

        break;
      }
      case 'DAY':
        previousDate.setDate(previousDate.getDate() + 1);
        break;
      case 'WEEK':
        previousDate.setDate(previousDate.getDate() + 7);
        break;
      case 'MONTH':
        previousDate.setMonth(previousDate.getMonth() + 1);
        break;
      case 'QUARTER_OF_YEAR':
        previousDate.setMonth(previousDate.getMonth() + 3);
        break;
      case 'YEAR':
        previousDate.setFullYear(previousDate.getFullYear() + 1);
        break;
    }

    return previousDate;
  }

  addDateByResolutionForPerformanceAnalysis(startDate: string, index: number, resolution: string): Date {
    let newDate = new Date(startDate);
    // +15 min / +1 day
    switch (resolution) {
      case 'MIN_15':
        newDate.setTime(newDate.getTime() + (index * 900000));
        break;
      case 'DAY':
        newDate.setDate(newDate.getDate() + index);
        break;
      case 'WEEK':
        newDate.setDate(newDate.getDate() + index * 7);
        break;
      case 'MONTH':
        newDate.setMonth(newDate.getMonth() + index);
        break;
      case 'QUARTER_OF_YEAR':
        newDate.setMonth(newDate.getMonth() + index * 3);
        break;

    }

    return newDate;
  }

  GenerateDateForDiagramData(valuesKw: number[], startTime: string, resolution: string) {
    let data: any[][] = [];
    let previousDate;
    for (let i = 0; i < valuesKw.length; i++) {
      let newDate: Date = this.addDateByResolution(startTime, previousDate, i, resolution);
      data[i] = [newDate.getTime(), valuesKw[i]];
      previousDate = newDate;
    }
    return data;
  }

  createAvaragePlotLines(chartData: any): any[] {
    let plotLines = [];
    // Plot line options for adding
    let chartDataLength = chartData.filter(r => r.valueStats).length;

    for (let i = 0; i < 4; i++) {
      let averagePlotLine;

      if (chartData[i] && chartData[i].valueStats) {
        averagePlotLine = this.addRealAveragePlotLine(i, chartData);

      } else {
        //needs for highcharts (it's a bug), if we don't user dummy lines, then the plotlines from the previous report will remain there
        averagePlotLine = this.createInvisibleDummyAveragePlotline(i, plotLines);

      }

      plotLines.push(averagePlotLine);

    }
    return plotLines;
  }

  addRealAveragePlotLine(i: number, chartData: any) {
    const diagramChanged = this.diagramChanged;
    let averageText = this.translateService.instant('metrics.jvm.http.table.average');
    let plotLineId = `${averageText} ${chartData[i].name}`;
    let plotLineOptions = {
      color: chartData[i].color,
      id: plotLineId,
      width: 1,
      value: chartData[i].valueStats.average,
      dashStyle: 'Solid'
    };

    chartData.push({
      // Series that mimics the plot line
      type: 'line',
      color: chartData[i].color,
      name: plotLineId,
      groupId: 'avg',
      dashStyle: 'Solid',
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function (e) {
          diagramChanged.next({
            chart: this.chart,
            type: 'legendItemClick',
            value: {
              groupId: 'avg',
              name: plotLineId,
              item: this
            }
          });
          if (this.visible) {
            this.chart.yAxis[0].removePlotLine(plotLineId);
          } else {
            this.chart.yAxis[0].addPlotLine(plotLineOptions);
          }
        }
      }
    });

    return plotLineOptions;
  }

  createInvisibleDummyAveragePlotline(i: number, plotLines: any[]) {

    let dummyPlotLine = {
      color: 'maroon',
      id: i + ' max',
      width: 0,
      value: 0,
      dashStyle: 'LongDash'
    };

    return dummyPlotLine;
  }

  markPartialValues(chartData: any): boolean {
    let containsPartialData: boolean = false;

    let chartDataLength = chartData.filter(r => r.valueStats).length;
    for (let i = 0; i < chartDataLength; i++) {
      let lastIndex = chartData[i].data.length - 1;

      if (chartData[i].valueStats && chartData[i].valueStats.partialFirstInterval && Array.isArray(chartData[i].data[0])) {
        chartData[i].data[0] = {x: chartData[i].data[0][0], y: chartData[i].data[0][1], color: 'grey'};
        containsPartialData = true;
      }

      if (chartData[i].valueStats && chartData[i].valueStats.partialLastInterval && Array.isArray(chartData[i].data[lastIndex])) {
        chartData[i].data[lastIndex] = {
          x: chartData[i].data[lastIndex][0],
          y: chartData[i].data[lastIndex][1],
          color: 'grey'
        };
        containsPartialData = true;
      }
    }

    return containsPartialData;
  }

  markPartialValuesInSortedData(chartData: any, partialIndexes: number[]) {
    for (let index of partialIndexes) {
      chartData[index] = {x: chartData[index][0], y: chartData[index][1], color: 'grey'};
    }
  }

  addMinMaxPlotLine(chartData: any): any[] {
    let plotLines = [];
    for (let i = 0; i < 4; i++) {
      let minPlotLine;
      let maxPlotLine;

      if (chartData[i] && chartData[i].valueStats) {
        let currentSeriesPlotLine = this.addRealMinMaxPlotlines(i, chartData);
        minPlotLine = currentSeriesPlotLine.min;
        maxPlotLine = currentSeriesPlotLine.max;
      } else {
        //needs for highcharts (it's a bug), if we don't user dummy lines, then the plotlines from the previous report will remain there
        let currentSeriesPlotLine = this.createInvisibleDummyMinMaxPlotlines(i, plotLines);
        minPlotLine = currentSeriesPlotLine.min;
        maxPlotLine = currentSeriesPlotLine.max;
      }

      plotLines.push(minPlotLine);
      plotLines.push(maxPlotLine);
    }
    return plotLines;
  }

  addRealMinMaxPlotlines(i: number, chartData: any): any {
    const diagramChanged = this.diagramChanged;
    let minValue = chartData[i].valueStats.min;
    let maxValue = chartData[i].valueStats.max;
    let min = (<Array<number>>chartData[i].data).indexOf(minValue);
    let max = (<Array<number>>chartData[i].data).indexOf(maxValue);
    let minKey = min > 0 ? this.calcDateTimeFromIndex(chartData[i].pointStart, min, chartData[i].pointInterval, chartData[i].pointIntervalUnit) : null;
    let maxKey = max > 0 ? this.calcDateTimeFromIndex(chartData[i].pointStart, max, chartData[i].pointInterval, chartData[i].pointIntervalUnit) : null;

    let minPlotLine = {
      color: chartData[i].color,
      id: 'Min ' + chartData[i].name,
      width: 1,
      value: minKey,
      dashStyle: 'Dot'
    };

    let maxPlotLine = {
      color: chartData[i].color,
      id: 'Max ' + chartData[i].name,
      width: 1,
      value: maxKey,
      dashStyle: 'LongDash'
    };

    chartData.push({
      // Series that mimics the plot line
      color: minPlotLine.color,
      name: minPlotLine.id,
      groupId: 'min',
      dashStyle: 'Dot',
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function (e) {
          diagramChanged.next({
            chart: this.chart,
            type: 'legendItemClick',
            value: {
              groupId: 'min',
              name: minPlotLine.id,
              item: this
            }
          });
          if (this.visible) {
            this.chart.xAxis[0].removePlotLine(minPlotLine.id);
          } else {
            this.chart.xAxis[0].addPlotLine(minPlotLine);
          }
        }
      }
    });
    chartData.push({
      // Series that mimics the plot line
      color: maxPlotLine.color,
      name: maxPlotLine.id,
      groupId: 'max',
      dashStyle: 'ShortDash',
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function (e) {
          diagramChanged.next({
            chart: this.chart,
            type: 'legendItemClick',
            value: {
              groupId: 'max',
              name: maxPlotLine.id,
              item: this
            }
          });
          if (this.visible) {
            this.chart.xAxis[0].removePlotLine(maxPlotLine.id);
          } else {
            this.chart.xAxis[0].addPlotLine(maxPlotLine);
          }
        }
      }
    });

    return {min: minPlotLine, max: maxPlotLine};
  }

  addSchedulePlotLine(chartData: any, timeSeries: any, startTime: any, podValuesData: any, resolution: any): void {
    const diagramChanged = this.diagramChanged;
    const name: string = this.translateService.instant('timeSeriesImport.timeSeriesTypes.' + 'CONSUMPTION_SCHEDULE');
    const diffName: string = this.translateService.instant('consumption.scheduleDiff');
    const diffPercentName: string = this.translateService.instant('consumption.scheduleDiffPercent');

    this.addConsumptionSchedulePlotLine(chartData, timeSeries, startTime, name, diagramChanged, resolution);
    this.addConsumptionScheduleDiffPlotLine(chartData, timeSeries, startTime, diffName, diagramChanged, resolution, podValuesData);
    this.addConsumptionScheduleDiffPercentPlotLine(chartData, timeSeries, startTime, diffPercentName, diagramChanged, resolution, podValuesData);
  }

  addConsumptionSchedulePlotLine(chartData: any, timeSeries: any, startTime: any, name: string, diagramChanged: any, resolution: any): void {
    let schedulePlotLine = {
      color: '#F35230',
      id: name,
      width: 1,
      dashStyle: 'LongDash',
      type: 'line'
    };

    chartData.push({
      name: name,
      color: '#F35230',
      pointStart: moment(startTime).valueOf(),
      pointIntervalUnit: this.getPointIntervalUnit(resolution),
      pointInterval: this.getPointInterval(resolution),
      step: 'timeseries',
      groupId: 'schedule',
      data: timeSeries !== null ? timeSeries.data : [],
      events: {
        legendItemClick: function (e) {
          diagramChanged.next({
            chart: this.chart,
            type: 'legendItemClick',
            value: {
              groupId: 'schedule',
              name: name,
              item: this
            }
          });
          if (this.visible) {
            this.chart.yAxis[0].removePlotLine(schedulePlotLine.id);
          } else {
            this.chart.yAxis[0].addPlotLine(schedulePlotLine);
          }
        }
      }
    });
  }

  addConsumptionScheduleDiffPlotLine(chartData: any, timeSeries: any, startTime: any, name: string, diagramChanged: any, resolution: any, podValuesData: any): void {
    let consumptionScheduleDiffData: any[] = [];
    if (podValuesData && podValuesData.length > 0) {
      if (timeSeries === null) {
        podValuesData.forEach(() => {
          consumptionScheduleDiffData.push(null);
        });
      } else {
        podValuesData.forEach((data, index) => {
          consumptionScheduleDiffData.push(Math.abs(timeSeries.data[index] - data));
        });
      }
    }

    let schedulePlotLine = {
      color: '#E3330D',
      id: name,
      width: 1,
      dashStyle: 'LongDash',
      type: 'line'
    };

    chartData.push({
      name: name,
      color: '#E3330D',
      pointStart: moment(startTime).valueOf(),
      pointIntervalUnit: this.getPointIntervalUnit(resolution),
      pointInterval: this.getPointInterval(resolution),
      step: 'timeseries',
      groupId: 'schedule',
      data: consumptionScheduleDiffData,
      events: {
        legendItemClick: function (e) {
          diagramChanged.next({
            chart: this.chart,
            type: 'legendItemClick',
            value: {
              groupId: 'schedule',
              name: name,
              item: this
            }
          });
          if (this.visible) {
            this.chart.yAxis[0].removePlotLine(schedulePlotLine.id);
          } else {
            this.chart.yAxis[0].addPlotLine(schedulePlotLine);
          }
        }
      }
    });
  }

  addConsumptionScheduleDiffPercentPlotLine(chartData: any, timeSeries: any, startTime: any, name: string, diagramChanged: any, resolution: any, podValuesData: any): void {
    let consumptionScheduleDiffData: any[] = [];
    if (podValuesData && podValuesData.length > 0) {
      if (timeSeries === null) {
        podValuesData.forEach(() => {
          consumptionScheduleDiffData.push(null);
        });
      } else {
        podValuesData.forEach((data, index) => {
          consumptionScheduleDiffData.push(Math.abs((timeSeries.data[index] - data) / timeSeries.data[index] * 100));
        });
      }
    }

    let schedulePlotLine = {
      color: '#9CE30D',
      id: name,
      width: 1,
      dashStyle: 'LongDash',
      type: 'line'
    };

    chartData.push({
      name: name,
      color: '#9CE30D',
      pointStart: moment(startTime).valueOf(),
      pointIntervalUnit: this.getPointIntervalUnit(resolution),
      pointInterval: this.getPointInterval(resolution),
      step: 'timeseries',
      groupId: 'schedule',
      data: consumptionScheduleDiffData,
      events: {
        legendItemClick: function (e) {
          diagramChanged.next({
            chart: this.chart,
            type: 'legendItemClick',
            value: {
              groupId: 'schedule',
              name: name,
              item: this
            }
          });
          if (this.visible) {
            this.chart.yAxis[0].removePlotLine(schedulePlotLine.id);
          } else {
            this.chart.yAxis[0].addPlotLine(schedulePlotLine);
          }
        }
      }
    });
  }

  createInvisibleDummyMinMaxPlotlines(i: number, plotLines: any[]): any {
    let minPlotLine = {
      color: 'blue',
      id: i + ' min',
      width: 0,
      value: 0,
      dashStyle: 'LongDash'
    };

    let maxPlotLine = {
      color: 'maroon',
      id: i + ' max',
      width: 0,
      value: 0,
      dashStyle: 'LongDash'
    };

    return {min: minPlotLine, max: maxPlotLine};
  }

  createMinMaxAverageDiagramPlotLines(valueStats: ValueStats): any[] {
    let plotLines = [];

    plotLines.push(this.createPlotLine(valueStats.average, this.translateService.instant('metrics.jvm.http.table.average'), '#f24f00', 'avg'));
    plotLines.push(this.createPlotLine(valueStats.min, 'Min', 'blue', 'min'),);
    plotLines.push(this.createPlotLine(valueStats.max, 'Max', 'maroon', 'max'));

    return plotLines;
  }

  createPlotLine(value: number, name: string, color: string, groupId?: string) {
    const diagramChanged = this.diagramChanged;
    let plotLineId = name;
    let plotLineOptions = {
      color: color,
      id: plotLineId,
      width: 1,
      value: value,
      dashStyle: 'solid'
    };

    let chartData = {
      // Series that mimics the plot line
      color: color,
      name: plotLineId,
      groupId: groupId,
      dashStyle: 'solid',
      marker: {
        enabled: false
      },
      events: {
        legendItemClick: function (e) {
          diagramChanged.next({
            chart: this.chart,
            type: 'legendItemClick',
            value: {
              groupId: groupId,
              name: plotLineId,
              item: this
            }
          });
          if (this.visible) {
            this.chart.yAxis[0].removePlotLine(plotLineId);
          } else {
            this.chart.yAxis[0].addPlotLine(plotLineOptions);
          }
        }
      }
    };

    return {plotLine: plotLineOptions, chartData: chartData};
  }

  sortDataMarkPartial(series, chartData, sortedDataWithOriginalIndexes) {

    let dataWithDateAndIndex = this.GenerateDateForDiagramData(series.valuesKW, series.startTime, series.resolution);

    let sortedDataWithOriginalIndexItem = dataWithDateAndIndex.concat().sort((n1, n2) => n2[1] - n1[1]);
    sortedDataWithOriginalIndexes.push(sortedDataWithOriginalIndexItem);

    let partialIndexes = [];
    let originalPartialIndexes = [dataWithDateAndIndex[0][0], dataWithDateAndIndex[dataWithDateAndIndex.length - 1][0]];
    let diagramData = sortedDataWithOriginalIndexItem.map((r, i) => {
      if (series.valueStats.partialFirstInterval && r[0] === originalPartialIndexes[0])
        partialIndexes.push(i);

      if (series.valueStats.partialLastInterval && r[0] === originalPartialIndexes[1])
        partialIndexes.push(i);

      return [i + 1, r[1]];
    });

    this.markPartialValuesInSortedData(diagramData, partialIndexes);

    chartData.push({
      name: series.name,
      color: series.color || '#f24f00',
      lineWidth: 2,
      data: diagramData,
      valueStats: series.valueStats
    });

    if (partialIndexes.length > 0 && !chartData.some(r => r.color === 'grey'))
      this.translateService.get('consumption.partialValue').subscribe(partialValueText => {
        chartData.push(
          {
            name: partialValueText,
            type: 'line',
            color: 'grey',
            dashStyle: 'Dot',
            marker: {symbol: 'circle'},
            events: {
              legendItemClick: function (e) {
                return false;
              }
            }
          });
      });
  }

  createLegendText(filterModel: FilterModel): Observable<string> {
    let fromDate = this.dateConverter.convertToDate(filterModel.periodParameters[0].dateFrom);
    let toDate = this.dateConverter.convertToDate(filterModel.periodParameters[0].dateTo);

    return this.translateService.get('consumption.aggregatedValues').pipe(map(aggregatedValuesText => {
      let legendItemText = '';
      if (filterModel.selectedPods.length + filterModel.selectedPodGroups.length === 1) {
        if (filterModel.selectedPods.length === 1) {
          legendItemText = filterModel.selectedPods[0].podCode;
        } else {
          legendItemText = filterModel.selectedPodGroups[0].name;
        }
      } else {
        legendItemText = aggregatedValuesText;
      }

      legendItemText += ` ${moment(fromDate).format('l')} - ${moment(toDate).format('l')}`;
      return legendItemText;
    }));
  }

  createTitle(filterModel: FilterModel): Observable<string> {
    let fromDate: Date = this.dateConverter.convertToDate(filterModel.periodParameters[0].dateFrom);
    let toDate: Date = this.dateConverter.convertToDate(filterModel.periodParameters[0].dateTo);

    return this.translateService.get(['consumption.sum', 'consumption.comparison', 'consumption.selectedPeriods']).pipe(map(translations => {
      let title = '';
      let multipodText = filterModel.comparePods
        ? translations['consumption.comparison']
        : translations['consumption.sum'];

      if (filterModel.periodParameters.filter(r => r.dateTo).length > 1) {
        title = `${translations['consumption.sum']}, ${translations['consumption.comparison'].toLowerCase()} ${translations['consumption.selectedPeriods']}`;
      } else {
        if (filterModel.selectedPods.length + filterModel.selectedPodGroups.length === 1) {

          if (filterModel.selectedPods.length === 1) {
            title = filterModel.selectedPods[0].podCode;
          } else {
            title = filterModel.selectedPodGroups[0].name;
          }
        } else {
          title = multipodText;
        }

        title += ` / ${moment(fromDate).format('l')} - ${moment(toDate).format('l')}`;
      }

      title = `${this.translateService.instant(filterModel.selectedDiagram.name)} - ${title}`;

      return title;
    }));

  }

  createApiParamsFromFilter(filterModel: FilterModel, dateIndex: number) {
    const minLength: number = filterModel.periodParameters.filter(pp => pp.dateFrom).reduce((min, p) => min > p.periodInDays ? p.periodInDays : min, filterModel.periodParameters[0].periodInDays);
    const dateFrom: Date = this.dateConverter.convertToDate(filterModel.periodParameters[dateIndex].dateFrom);
    const dateTo: Date = this.dateConverter.convertToDate(filterModel.periodParameters[dateIndex].dateTo);

    let apiParams = {
      startTime: dateFrom.toISOString(),
      endTime: filterModel.periodParameters[dateIndex].periodInDays > minLength ? dateTo.toISOString() :
        new Date(dateFrom.setDate(dateFrom.getDate() + minLength)).toISOString(),
      resolution: filterModel.resolution,
      podIds: filterModel.selectedPods.map(r => {
        return r.id;
      }),
      podGroupIds: filterModel.selectedPodGroups.map(r => r.id)
    };

    return apiParams;
  }

  createPlotBands(filterModel: FilterModel) {
    let longestPeriodParameter: PeriodParameterModel = filterModel.periodParameters.reduce((acc, curr) => curr.periodInDays > acc.periodInDays ? curr : acc);

    let periodLength = longestPeriodParameter.periodInDays;
    let fromDate = this.dateConverter.convertToDate(filterModel.periodParameters[0].dateFrom);
    let toDate: Date = moment(fromDate).add(longestPeriodParameter.periodInDays, 'days').toDate();
    //highcharts bug, highcharts stores the references oof plotbands, so we must 'hide' the previous lines.
    //with local variable it does the same
    this.plotBands.map(r => r.color = 'rgba(255, 255, 255, 0)');

    //hour
    if (periodLength < 1) {
      for (let i = 0; i < 13; i++) {
        if (i > 0)
          fromDate.setHours(fromDate.getHours() + 2);
        toDate = new Date(fromDate.getTime());
        toDate.setHours(toDate.getHours() + 1);
        this.plotBands[i] = { // highlight last minute
          color: 'rgba(242, 79, 0, .1)',
          from: new Date(fromDate.getTime()),
          to: toDate
        };
      }
    } else if (periodLength < 7) {
      for (let i = 0; i < periodLength / 2 + 1; i++) {
        if (i > 0)
          fromDate.setDate(fromDate.getDate() + 2);
        toDate = new Date(fromDate.getTime());
        toDate.setDate(toDate.getDate() + 1);
        this.plotBands[i] = { // highlight last minute
          color: 'rgba(242, 79, 0, .1)',
          from: new Date(fromDate.getTime()),
          to: toDate
        };
      }
    } else if (periodLength < 35) {
      fromDate = this.getNextMonday(fromDate);
      for (let i = 0; i < periodLength / 7 + 1; i++) {
        if (i > 0)
          fromDate.setDate(fromDate.getDate() + 2 * 7);
        toDate = new Date(fromDate.getTime());
        toDate.setDate(toDate.getDate() + 7);
        this.plotBands[i] = { // highlight last minute
          color: 'rgba(242, 79, 0, .1)',
          from: new Date(fromDate.getTime()),
          to: toDate
        };
      }
    } else if (periodLength < 120) {
      this.markMonthPlotBands(fromDate, toDate, false);
    } else if (periodLength < 450) {
      this.markMonthPlotBands(fromDate, toDate, true);
    } else {
      fromDate = new Date(Date.UTC(fromDate.getFullYear() + 1, 0, 1, fromDate.getHours(), 0, 0));
      let yearNumber = toDate.getFullYear() - fromDate.getFullYear();
      for (let i = 0; i < yearNumber / 2 + 1; i++) {
        if (i > 0)
          fromDate.setFullYear(fromDate.getFullYear() + 2);
        toDate = new Date(fromDate.getTime());
        toDate.setFullYear(toDate.getFullYear() + 1);
        this.plotBands[i] = { // highlight last minute
          color: 'rgba(242, 79, 0, .1)',
          from: new Date(fromDate.getTime()),
          to: toDate
        };
      }
    }
    return this.plotBands;
  }

  markMonthPlotBands(fromDate: Date, toDate: Date, quarterOfYear: boolean) {
    fromDate = new Date(Date.UTC(fromDate.getFullYear(), quarterOfYear ? 0 : fromDate.getMonth() + 1, 1, 0, 0, 0));
    let monthNumbers = (toDate.getFullYear() - fromDate.getFullYear()) * 12;
    monthNumbers -= fromDate.getMonth() + 1;
    monthNumbers += toDate.getMonth();
    monthNumbers = quarterOfYear ? monthNumbers / 6 : monthNumbers / 2;

    for (let i = 0; i < monthNumbers + 1; i++) {
      if (i > 0)
        fromDate = new Date(Date.UTC(fromDate.getFullYear(), quarterOfYear ? fromDate.getMonth() + 6 : fromDate.getMonth() + 2, 1, 0, 0, 0));
      toDate = new Date(Date.UTC(fromDate.getFullYear(), quarterOfYear ? fromDate.getMonth() + 3 : fromDate.getMonth() + 1, 1, 0, 0, 0));
      this.plotBands[i] = { // highlight last minute
        color: 'rgba(242, 79, 0, .1)',
        from: new Date(fromDate.getTime()),
        to: toDate
      };
    }
  }

  getCommonTooltipFormatter(chart: any, suffix: string, xIsDate: boolean, resolution: string, diagType: ConsumptionDiagramType, isMultiplePeriod: boolean, color?: string) {
    let isTemperature: boolean = chart.series.userOptions ? chart.series.userOptions.temperature : false;
    const celsius: string = '°C';
    const xAxisSuffix: string = '%';
    let valueFn;
    let dateFormat: string;
    const isReference: boolean = chart.series.userOptions ? chart.series.userOptions.isReference : false;


    if (this.translateService.currentLang === 'hu') {
      if (isReference === true) {
        dateFormat = 'EEEE HH:mm';
      } else {
        switch (resolution) {
          case 'MIN_15':
          case 'QUARTER_HOURLY':
            dateFormat = 'yyyy.M.d HH:mm';
            break;
          case 'HOUR':
          case 'HOURLY':
            dateFormat = 'yyyy.M.d HH:mm';
            break;
          case 'MONTH':
          case 'MONTHLY':
          case 'CUSTOM':
          case 'QUARTER_OF_YEAR':
          case 'QUARTER_YEARLY':
            dateFormat = 'yyyy.M';
            break;
          case 'YEAR':
          case 'YEARLY':
            dateFormat = 'yyyy';
            break;
          default:
            dateFormat = 'yyyy.M.d';

        }
      }
    } else {
      if (isReference === true) {
        dateFormat = 'EEEE HH:mm';
      } else {
        switch (resolution) {
          case 'MIN_15':
          case 'QUARTER_HOURLY':
            dateFormat = 'd/M/yyyy HH:mm';
            break;
          case 'HOUR':
          case 'HOURLY':
            dateFormat = 'd/M/yyyy HH:mm';
            break;
          case 'MONTH':
          case 'MONTHLY':
          case 'CUSTOM':
          case 'QUARTER_OF_YEAR':
          case 'QUARTER_YEARLY':
            dateFormat = 'M/yyyy';
            break;
          case 'YEAR':
          case 'YEARLY':
            dateFormat = 'yyyy';
            break;
          default:
            dateFormat = 'd/M/yyyy';

        }
      }
    }


    let header: string;

    if (diagType === ConsumptionDiagramType.DIAGCOLUMN) {
      header = `<div style="padding: 0 0 5px 10px"><b>${(chart.key / chart.series.xAxis.dataMax * 100).toFixed(0)} ${xAxisSuffix}</b></div><table>`;
    } else
      header = isMultiplePeriod ? '<table>' : `<div style="padding: 0 0 5px 10px"><b>${xIsDate ? this.datePipe.transform(chart.x, dateFormat) : chart.key}</b></div><table>`;

    let footer: string = '</table>';

    switch (suffix.toLocaleLowerCase()) {
      case  this.translateService.instant('consumption.pcs') :
        valueFn = (value: number) => this.decimalPipe.transform(value, '1.0-0');
        break;
      case 'kw':
      case 'kwh':
        valueFn = (value: number) => this.decimalPipe.transform(value, '1.2-2');
        break;
      case 'mw':
      case 'mwh':
        valueFn = (value: number) => this.decimalPipe.transform(value, '1.2-3');
        break;
      default:
        valueFn = (value: number) => this.decimalPipe.transform(value, '1.2-2');
    }
    const tooltipColor: string = color || chart.series.color;
    let value: string = `<tr style="color: ${tooltipColor}"><td style="padding-right: 10px"><b>${chart.series.name}</b></td><td style="text-align: right"><b>${valueFn(chart.y)} ${isTemperature ? celsius : suffix}</b> </td></tr>`;

    let min: string = chart.series.options.valueStats && diagType !== ConsumptionDiagramType.BOXPLOT ? `<tr style="color: ${tooltipColor}"><td style="padding: 0 10px; font-style: italic">${this.translateService.instant('consumption.minimum')}</td><td style="text-align: right"><b>${valueFn(chart.series.options.valueStats.min)} ${isTemperature ? celsius : suffix}</b></td>` : '';
    let max: string = chart.series.options.valueStats && diagType !== ConsumptionDiagramType.BOXPLOT ? `<tr style="color: ${tooltipColor}"><td style="padding: 0 10px; font-style: italic">${this.translateService.instant('consumption.maximum')}</td><td style="text-align: right"><b>${valueFn(chart.series.options.valueStats.max)} ${isTemperature ? celsius : suffix}</b></td>` : '';
    let average: string = chart.series.options.valueStats ? `<tr style="color: ${tooltipColor}"><td style="padding: 0 10px; font-style: italic">${this.translateService.instant('consumption.average')}</td><td style="text-align: right"><b>${valueFn(chart.series.options.valueStats.average)} ${isTemperature ? celsius : suffix}</b></td>` : '';

    let high: string = chart.point.high ? `<tr style="color: ${tooltipColor}"><td style="padding: 0 10px; font-style: italic">${this.translateService.instant('consumption.maximum')}</td><td style="text-align: right"><b>${valueFn(chart.point.high)} ${isTemperature ? celsius : suffix}</b></td>` : '';
    let q3: string = chart.point.q3 ? `<tr style="color: ${tooltipColor}"><td style="padding: 0 10px; font-style: italic">${this.translateService.instant('consumption.q3')}</td><td style="text-align: right"><b>${valueFn(chart.point.q3)} ${isTemperature ? celsius : suffix}</b></td>` : '';
    let median: string = chart.point.median ? `<tr style="color: ${tooltipColor}"><td style="padding: 0 10px; font-style: italic">${this.translateService.instant('consumption.median')}</td><td style="text-align: right"><b>${valueFn(chart.point.median)} ${isTemperature ? celsius : suffix}</b></td>` : '';
    let q1: string = chart.point.q1 ? `<tr style="color: ${tooltipColor}"><td style="padding: 0 10px; font-style: italic">${this.translateService.instant('consumption.q1')}</td><td style="text-align: right"><b>${valueFn(chart.point.q1)} ${isTemperature ? celsius : suffix}</b></td>` : '';
    let low: string = chart.point.low ? `<tr style="color: ${tooltipColor}"><td style="padding: 0 10px; font-style: italic">${this.translateService.instant('consumption.minimum')}</td><td style="text-align: right"><b>${valueFn(chart.point.low)} ${isTemperature ? celsius : suffix}</b></td>` : '';

    return header + value + min + max + average + high + q3 + median + q1 + low + footer;
  }

  getMinMaxToolTip(chart: any): string {

    let range = chart.points.filter(r => typeof r.point.low !== 'undefined')[0].point;
    let temperature = chart.points.filter(r => r.point.series.userOptions.temperature)[0];
    const valueFn = (value: number) => this.decimalPipe.transform(value, '1.2-2');
    const suffixKw = 'kW';
    const suffixTemp = '°C';

    let header: string = `<div style="padding: 0 0 5px 10px"><b>${moment(new Date(chart.x)).format('l')} </b></div><table>`;
    let footer: string = '</table>';
    let average: string = chart.y ? `<tr style="color: ${chart.points[0].series.color}"><td style="padding: 0 10px; font-style: italic">${this.translateService.instant('metrics.jvm.http.table.average')}</td><td style="text-align: right"><b>${valueFn(chart.y)} ${suffixKw}</b></td>` : '';
    let temperatureString: string = temperature ? `<tr style="color: ${temperature.color}"><td style="padding: 0 10px; font-style: italic">${this.translateService.instant('filter.basic.temperature')}</td><td style="text-align: right"><b>${valueFn(temperature.y)} ${suffixTemp}</b></td>` : '';
    let rangeString: string = range.low && range.high ? `<tr style="color: ${range.color}"><td style="padding: 0 10px; font-style: italic">${this.translateService.instant('consumption.range')}</td><td style="text-align: right"><b>${valueFn(range.low)} - ${valueFn(range.high)} ${suffixKw}</b></td>` : '';

    return header + average + rangeString + temperatureString + footer;
  }

  getHeatMapTooltip(chart: any) {
    const suffixTemp = '°C';
    const suffix = 'kW';
    let time = chart.y;
    let hours1 = ('0' + Math.floor(time)).slice(-2);
    let mins1 = ('0' + Math.round(time % 1 * 100)).slice(-2);
    const valueFn = (value: number) => this.decimalPipe.transform(value, '1.2-2');

    let header: string = `<div style="padding: 0 0 5px 10px"><b>${moment(new Date(chart.point.x)).format('l')} ${hours1}:${mins1} ${valueFn(chart.series.data[chart.point.index].value)} ${suffix} </b></div><table>`;
    let footer: string = '</table>';

    return header + footer;
  }

  renderFunction(logoWidth, logoHeight) {
    return function (event: any) {
      const chart = event.target;
      if (this.watermark) {
        this.watermark.image.hide();
      }
      this.watermark = {
        image: this.renderer.image(
          '/api/files/static/cez-squarelogo-transparent.png',
          chart.chartWidth / 2 - logoWidth / 2,
          (chart.xAxis[0].height / 2 - logoHeight / 2 + chart.title.alignAttr.y),
          logoWidth,
          logoHeight
        ).add()
      };
    };
  }

  getWatermark(widget: boolean) {
    const logoWidth = widget ? 90 : 180;
    const logoHeight = widget ? 90 : 180;
    return {
      redraw: this.renderFunction(logoWidth, logoHeight),
      load: this.renderFunction(logoWidth, logoHeight)
    };
  }

  getPointInterval(resolution: string): number {
    switch (resolution) {
      case 'YEAR':
      case 'MONTH':
      case 'DAY':
      case Resolution.YEARLY:
      case Resolution.MONTHLY:
      case Resolution.DAILY:
        return 1;
      case 'QUARTER_OF_YEAR':
      case Resolution.QUARTER_YEARLY:
        return 3;
      case 'HOUR':
      case Resolution.HOURLY:
        return 3600 * 1000;
      case 'MIN_15':
      case Resolution.QUARTER_HOURLY:
        return 3600 / 4 * 1000;
      case 'WEEK':
      case Resolution.WEEKLY:
        return 24 * 3600 * 1000 * 7;
      default:
        throw new Error('Unknown resolution: ' + resolution);
    }
  }

  getPointIntervalUnit(resolution: string): string {
    switch (resolution) {
      case 'YEAR':
      case Resolution.YEARLY:
        return 'year';
      case 'QUARTER_OF_YEAR':
      case Resolution.QUARTER_YEARLY:
        return 'month';
      case 'MONTH':
      case Resolution.MONTHLY:
        return 'month';
      case 'WEEK':
      case Resolution.WEEKLY:
        return undefined;
      case 'DAY':
      case Resolution.DAILY:
        return 'day';
      case 'HOUR':
      case Resolution.HOURLY:
        return undefined;
      case 'MIN_15':
      case Resolution.QUARTER_HOURLY:
        return undefined;
      default:
        throw new Error('Unknown resolution: ' + resolution);
    }
  }

  calcDateTimeFromIndex(startTime: Moment | Date | number, index: number, pointInterval: number, pointIntervalUnit?: 'day' | 'month' | 'year'): number {
    const startMoment: Moment = moment(startTime);
    startMoment.add(index * pointInterval, pointIntervalUnit || 'milliseconds');
    return startMoment.valueOf();
  }


  diagramColumnPlotBands(chart) {
    return Array(5).fill(undefined).map((_, i) => ({
      color: 'rgba(242, 79, 0, .1)',
      from: chart.sortedDataWithOriginalIndexes[0].length * (i * 0.2),
      to: chart.sortedDataWithOriginalIndexes[0].length * (i * 0.2 + 0.1)
    }));
  }

  getDateTimeLabelFormat() {
    if (this.translateService.currentLang === 'hu') {
      return {
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%Y.%m.%e',
        month: '%Y.%m.',
        week: '%Y.%m.%e',
        year: '%Y'
      };
    } else {
      return {
        minute: '%H:%M',
        hour: '%H:%M',
        day: '%e/%m/%Y',
        month: '%m/%Y',
        week: '%e/%m/%Y',
        year: '%Y'
      };
    }
  }

  getXDateFormat(resolution: Resolution): string {
    if (this.translateService.currentLang === 'hu') {
      switch (resolution) {
        case Resolution.DAILY:
          return '%Y.%m.%e';
        case Resolution.HOURLY:
          return '%Y.%m.%e %Hh';
        default:
          throw new Error('Unsupported resolution: ' + resolution);
      }
    } else {
      switch (resolution) {
        case Resolution.DAILY:
          return '%m/%e/%Y';
        case Resolution.HOURLY:
          return '%m/%e/%Y %Hh';
        default:
          throw new Error('Unsupported resolution: ' + resolution);
      }
    }
  }

  getSeriesType(diagramType: DiagramType): string {
    switch (diagramType) {
      case DiagramType.COLUMN:
        return 'column';
      case DiagramType.LINE:
        return 'line';
    }
  }

  createDummyChart(): Chart {
    return new Chart({
      title: {
        text: ''
      },
      chart: {
        height: '757px'
      },
      credits: {
        enabled: false
      },
      exporting: {
        buttons: {
          contextButton: {
            align: 'left',
            x: -10,
            y: -10
          }
        },
      }
    });
  }

  private getNextMonday(date: Date): Date {
    let currentDayOfWeek = date.getDay();
    date.setDate(date.getDate() + 7 - currentDayOfWeek + 1);
    return date;
  }

  public setVisiblePlot(chart: any, groupId: string, visible: boolean): void {
    chart.refSubject.pipe(
      take(1)
    ).subscribe((ref: any) => {
      ref.legend.allItems.forEach(value => {
        if (value.userOptions.groupId && value.userOptions.groupId.indexOf(groupId) > -1) {
          if (value.hcEvents['legendItemClick']) {
            value.setVisible(!visible);
            const method = value.hcEvents['legendItemClick'][0];
            method.fn.apply(value);
            value.setVisible(visible);
          }
        }
      });
    });
  }

}
