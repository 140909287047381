import { Route } from '@angular/router';
import { SiteMapComponent } from './site-map.component';
import {UserRouteAccessService} from '../shared';

export const SITE_MAP_ROUTE: Route = {
    path: 'sitemap',
    component: SiteMapComponent,
    data: {
        pageTitle: 'footer.siteMap'
    },
    canActivate: [UserRouteAccessService]
};
