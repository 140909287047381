<div class="modal-header">
    <h4 class="modal-title">{{article.title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click');">
        <span aria-hidden="true">&times;</span>
      </button>
</div>
<div class="modal-body">
    <div>
        <span *ngFor="let tag of article.tags" class="badge badge-secondary mr-1 mb-2">{{tag.name}}</span>
    </div>
    <h6 class="text-muted">{{article.releaseTime | cezDate}}</h6>
    <div class="image-container text-center">
        <img src="{{newsService.getPicture(article.picture)}}" alt="">
    </div>
    <div class="news-container ql-editor" [innerHTML]="article.text | safeHtml"></div>
</div>
<div class="modal-footer">
    <i class="fa fa-envelope-square fa-3x" (click)="show = !show" style="color:#f24f00;" aria-hidden="true"></i>
    <button type="button" class="btn btn-primary" (click)="activeModal.dismiss('Cross click');" jhiTranslate="audits.filter.button.close"></button>

</div>
<div class="modal-text-cez">
    <div class=" alert alert-success" *ngIf="success" jhiTranslate="news.modal.message.success">
        <strong>Saved!</strong>
    </div>
</div>
<div class="modal-text-cez" *ngIf="show">
    <div class="form-group">
        <label class="form-control-label" for="comment" jhiTranslate="news.modal.messageForAdmin">Üzenet adminnak</label>
        <textarea class="form-control" [(ngModel)]="comment" id="comment" required></textarea>
    </div>
    <button *ngIf="!commentSaveInProgress" [disabled]="!comment" (click)="saveComment()" class="btn btn-primary" jhiTranslate="news.modal.sendButton">Save</button>
    <button disabled *ngIf="commentSaveInProgress" class="btn btn-primary"><i class="fa fa-spinner fa-spin"></i></button>
</div>
