import { Language } from '../agreement-details/agreement-enums.model';
import { AgreementModel } from './agreement.model';
import { FileDto } from './FileDto';

export class ContractModel {
    agreement: AgreementModel;
    comment: string;
    contractLanguage: Language;
    contractNumber: string;
    endTime: Date;
    endTimeNgB: any;
    files: FileDto[];
    id: number;
    signedTime: Date;
    startTime: Date;
    signedTimeNgB: any;
    startTimeNgB: any;
    uploadedFiles: FileList;
}

