export type Unit = 'kW' | 'kWh' | 'MW' | 'MWh' | 'percent' | 'HUF' | 'EUR';

export const Unit = {
    kW: 'kW' as Unit,
    kWh: 'kWh' as Unit,
    MW: 'MW' as Unit,
    MWh: 'MWh' as Unit,
    percent: 'percent' as Unit,
    HUF: 'HUF' as Unit,
    EUR: 'EUR' as Unit
};
