export enum SalesforceProcessAction {
    PROCESS = 'PROCESS',
    ABORT = 'ABORT'
}

export enum SalesforceEntityType {
    QUOTE = 'QUOTE',
    DELIVERY_PERIOD = 'DELIVERY_PERIOD',
    ACCOUNT = 'ACCOUNT',
    CONTACT = 'CONTACT'
}
