<div class="modal-header">
    <h4 class="modal-title">{{'agreement.agreementData.deliveryPeriods.cardTitle' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="this.activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<form name="deliveryPeriodForm" role="form" #deliveryPeriodForm="ngForm" (ngSubmit)="save()">
    <div class="modal-body">

        <div class="form-group">
            <label for="contractedQuantityMWh"
                   jhiTranslate="agreement.agreementData.deliveryPeriods.contractedQuantityMWh">Contracted quantity
                MWh</label>
            <input required id="contractedQuantityMWh" name="contractedQuantityMWh"
                   [(ngModel)]="model.contractedQuantityMWh" class="form-control"
                   type="number">
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="startTime" jhiTranslate="agreement.agreementData.deliveryPeriods.startTime">Start
                        time</label>
                    <jhi-date-picker [(dpModel)]="model.startTimeNgB" id="startTime"></jhi-date-picker>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="endTime" jhiTranslate="agreement.agreementData.deliveryPeriods.endTime">End time</label>
                    <jhi-date-picker [(dpModel)]="model.endTimeNgB" id="endTime"></jhi-date-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group" *ngIf="productType === 'FIXED_PRICE' || productType === 'MULTI_SOURCE'">
                    <label for="price" jhiTranslate="agreement.agreementData.deliveryPeriods.price">Price</label>
                    <input required id="price" name="price" [(ngModel)]="model.price" class="form-control" type="text">
                </div>
                <div *ngIf="productType === 'STEP_BY_STEP'" class="form-group">
                    <label for="multiplicative"
                           jhiTranslate="agreement.agreementData.deliveryPeriods.multiplicative">Multiplicative</label>
                    <input required id="multiplicative" name="multiplicative"
                           [(ngModel)]="model.multiplicative" class="form-control"
                           type="number">
                </div>
                <div *ngIf="productType === 'STEP_BY_STEP'" class="form-group">
                    <label for="additiveSbs"
                           jhiTranslate="agreement.agreementData.deliveryPeriods.additiveSbs">Additive SbS [EUR/MWh]</label>
                    <input required id="additiveSbs" name="additiveSbs"
                           [(ngModel)]="model.additiveSbs" class="form-control"
                           type="number">
                </div>
                <div *ngIf="productType === 'CASH_OUT'" class="form-group">
                    <label for="serviceFee"
                           jhiTranslate="agreement.agreementData.deliveryPeriods.serviceFee"></label>
                    <input required id="serviceFee" name="serviceFee"
                           [(ngModel)]="model.serviceFee" class="form-control"
                           type="number">
                </div>
                <div *ngIf="productType === 'CASH_OUT'" class="form-group">
                    <label for="invoicePriceElement"
                           jhiTranslate="agreement.agreementData.deliveryPeriods.invoicePriceElement"></label>
                    <input required id="invoicePriceElement" name="invoicePriceElement"
                           [(ngModel)]="model.invoicePriceElement" class="form-control"
                           type="number">
                </div>
                <div class="form-group">
                    <label for="hedgeLimit"
                           jhiTranslate="agreement.agreementData.deliveryPeriods.hedgeLimit"></label>
                    <input id="hedgeLimit" name="hedgeLimit"
                           [required]="false"
                           (keydown)="blockDecimalMarkers($event)"
                           [(ngModel)]="model.hedgeLimit" class="form-control"
                           type="number">
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group" *ngIf="goO">
                    <label for="priceOfGoo" jhiTranslate="agreement.agreementData.deliveryPeriods.priceOfGoo">Price of
                        Goo</label>
                    <input required id="priceOfGoo" name="priceOfGoo" [(ngModel)]="model.priceOfGoo"
                           class="form-control" type="text">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary"
                [disabled]="deliveryPeriodForm.form.invalid">{{'entity.action.save' | translate}}</button>
    </div>
</form>
