import {ColorPalette} from './color-palette.model';

export const PALETTE: ColorPalette = {
    RED: [
        '#a41414',
        '#d74747',
        '#eba3a3',
    ],
    ORANGE: [
        '#c23f00',
        '#f57233',
        '#fab999',
    ],
    YELLOW: [
        '#cc9200',
        '#ffc533',
        '#ffe299',
    ],
    BLUE: [
        '#0072b2',
        '#33a5e5',
        '#99d2f2',
    ],
};
