import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AccountService, JhiLanguageHelper, Principal } from '../../shared';
import * as moment from 'moment';

@Component({
  selector: 'jhi-settings',
  templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
  error: string;
  success: string;
  settingsAccount: any;
  languages: any[];

  constructor(
    private account: AccountService,
    private principal: Principal,
    private languageHelper: JhiLanguageHelper,
    private http: HttpClient,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.principal.identity().then((account) => {
      this.settingsAccount = this.createAccount(account);
    });
    this.languageHelper.getAll().then((languages) => {
      this.languages = languages;
    });
  }

  save() {
    this.account.save(this.settingsAccount).subscribe(() => {
      this.error = null;
      this.success = 'OK';

      this.copyAccount(this.principal.userIdentity, this.settingsAccount);
      if (this.settingsAccount.langKey !== this.translateService.currentLang) {
        this.translateService.use(this.settingsAccount.langKey);
        moment.locale(this.settingsAccount.langKey);
      }

    }, () => {
      this.success = null;
      this.error = 'ERROR';
    });
  }

  copyAccount(userIdentity, account) {
    userIdentity.activated = account.activated;
    userIdentity.email = account.email;
    userIdentity.firstName = account.firstName;
    userIdentity.langKey = account.langKey;
    userIdentity.lastName = account.lastName;
    userIdentity.login = account.login;
    userIdentity.imageUrl = account.imageUrl;
  }

  createAccount(account) {
    return {
      activated: account.activated,
      email: account.email,
      firstName: account.firstName,
      langKey: account.langKey,
      lastName: account.lastName,
      login: account.login,
      imageUrl: account.imageUrl
    };
  }
}
