import {Injectable, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {DeliveryPeriodDto} from '../shared/dto/delivery-period-dto';
import {CompanyModel} from '../shared/dto/company.model';
import {TranslateService} from '@ngx-translate/core';
import {DeliveryPeriod} from '../shared/dto/delivery.periods';
import {CezDatePipe} from '../shared/pipes/cez-date.pipe';
import {StateStorageService} from '../shared/auth';
import * as moment from 'moment';;

@Injectable({providedIn: 'root'})
export class HighchartsService implements OnInit, OnDestroy {

    private readonly PERIOD_PATTERN_EN: string = 'dd.MM.yyyy.';
    private readonly PERIOD_PATTERN_HU: string = 'yyyy.MM.dd.';
    private readonly NOW_PATTERN_EN: string = 'dd.MM.yyyy. hh:mm a';
    private readonly NOW_PATTERN_HU: string = 'yyyy.MM.dd. HH:mm';

    private destroy: Subject<void> = new Subject<void>();

    constructor(private stateStorageService: StateStorageService,
                private translate: TranslateService,
                private cezDatePipe: CezDatePipe) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    /**
     * Retrieves the title for the chart based on the given parameters.
     *
     * @param {string} chartNameKey - The translation key for the chart name.
     * @param {DeliveryPeriodDto | DeliveryPeriod} deliveryPeriod - The delivery period object.
     * @returns {string} - The formatted chart title. Pattern: <ChartName> / <Company> / DeliveryPeriodStart-DeliveryPeriodEnd (line break) <(ExportTime)>
     */
    public getChartTitle(chartNameKey: string, deliveryPeriod: DeliveryPeriodDto | DeliveryPeriod): string {
        const periodPattern: string = this.translate.currentLang === 'en' ?
            this.PERIOD_PATTERN_EN : this.PERIOD_PATTERN_HU;
        const nowPattern: string = this.translate.currentLang === 'en' ?
            this.NOW_PATTERN_EN : this.NOW_PATTERN_HU;

        const chartName: string = this.translate.instant(chartNameKey);
        const companyName: string = this.getCompany().accountName;
        const deliveryPeriodStart: string = this.cezDatePipe.transformWithPattern(deliveryPeriod.startTime, periodPattern);
        const deliveryPeriodEnd: string = this.cezDatePipe.transformWithPattern(deliveryPeriod.endTime, periodPattern);
        const now: string = this.cezDatePipe.transformWithPattern(moment(), nowPattern);

        return `${chartName} / ${companyName} / ${deliveryPeriodStart}-${deliveryPeriodEnd} <br> (${now})`;
    }

    /**
     * Fills the gap in a line chart series with the specified values for the minimum and maximum x-axis values.
     *
     * @param {number[]} series - The array representing the data points of the line chart series.
     * @param {number} [minX] - The minimum x-axis value. If not provided, defaults to -1.
     * @param {number} [maxX] - The maximum x-axis value.
     * @returns {Object[]} An array of objects representing the filled gap in the line chart series.
     */
    public fillLineChartGap(series: number[], minX?: number, maxX?: number): any[] {
        const firstValue: number = series[0];
        const lastValue: number = series[series.length - 1];
        const minXValue: number = minX === undefined ? -1 : minX - 1;

        return !maxX ?
            [{x: minXValue, y: firstValue}, ...series] :
            [{x: minXValue, y: firstValue}, ...series, {x: maxX + 1, y: lastValue}];
    }

    private getCompany(): CompanyModel {
        return this.stateStorageService.getSelectedCompany();
    }

}
