export type ConsumptionDiagramType =
    'PERFORMANCE'
    |'DIAGCOLUMN'
    |'DISPERSION'
    |'BOXPLOT'
    |'MINMAXAVG'
    |'HEATMAP'
    |'SCHEDULING';

export const ConsumptionDiagramType = {
    PERFORMANCE: 'PERFORMANCE' as ConsumptionDiagramType,
    DIAGCOLUMN: 'DIAGCOLUMN' as ConsumptionDiagramType,
    DISPERSION: 'DISPERSION' as ConsumptionDiagramType,
    BOXPLOT: 'BOXPLOT' as ConsumptionDiagramType,
    MINMAXAVG: 'MINMAXAVG' as ConsumptionDiagramType,
    HEATMAP: 'HEATMAP' as ConsumptionDiagramType,
    SCHEDULING: 'SCHEDULING' as ConsumptionDiagramType
};
