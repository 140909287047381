import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import {DocumentByCategoryModel} from '../shared/dto/documentCategory.model';


@Component({
    selector: 'document-management-company',
    templateUrl: './document.management.html'
})
export class DocumentCompanyComponent implements AfterViewInit {

    public docList: DocumentByCategoryModel = new DocumentByCategoryModel();
    public filterText: string = '';

    constructor(private http: HttpClient) {
    }

    ngAfterViewInit() {
        this.http.get('/api/document/by_category/-1').subscribe((doc: DocumentByCategoryModel) => {
            this.docList = doc;
            this.docList.subCategories.map(sc => {
                sc.show = true;
                sc.contentList.map(r => r.show = true)
            })
        });
    }
}

