import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewsModalComponent } from '../news';
import {TranslateService} from "@ngx-translate/core";
import {News} from '../shared/dto/news';
import {Category} from '../shared';
import {NewsService} from '../shared/services/news.service';
import {CategoryService} from '../shared/services/category.service';
import {StateStorageService} from '../shared/auth';
import {DialogService} from '../shared/dialog/dialog.service';
import {NewsAdminModalComponent} from './news.admin.modal.component';
import {PagerModel} from '../shared/dto/page.model';

@Component({
    selector: 'news-admin',
    templateUrl: './news.admin.component.html',

})
export class NewsAdminComponent implements OnInit {
    selectedFiles: FileList;
    news: Array<News>;
    unPublishedNews: Array<News>;
    editorContent: string;
    categories: Category[];
    totalItems: any;
    itemsPerPage: any;
    page: any;
    adminAccess: boolean = false;
    account: any;

    constructor(
        private http: HttpClient,
        public newsService: NewsService,
        private modalService: NgbModal,
        private categoryService: CategoryService,
        private stateStorageService: StateStorageService,
        private dialogService: DialogService,
        private translate: TranslateService
    ) {
        this.page = 1;
        this.itemsPerPage = 10;
    }

    ngOnInit() {
        this.getPermissions();
    }

    getPermissions() {
        let permissions = this.stateStorageService.getEffectivePermissions();
        this.adminAccess = (permissions.indexOf("NEWS_WRITE") > -1) && (permissions.indexOf("CATEGORY_READ") > -1)
            && (permissions.indexOf("TAG_WRITE") > -1) && (permissions.indexOf("TAG_READ") > -1);

        this.refreshAllNews();
    }

    fileuploaderFileChange(files: FileList) {
        this.selectedFiles = files;
    }

    publishNews(item: News, visible: boolean) {
        this.http.patch('/api/news/admin/' + item.id + '/visible/' + visible, '').subscribe(() => this.refreshAllNews());
    }

    refreshNews(published: boolean) {
        if (published)
            this.http.post<PagerModel<News>>(`/api/news/admin/search?page=${this.page - 1}&size=${this.itemsPerPage}`, {visible: published})
                .subscribe(r => {
                    let response = r;
                    this.news = response.content;
                    this.totalItems = response.totalElements;
                });
        else
            this.http.post<PagerModel<News>>('/api/news/admin/search?page=0&size=1000', {visible: published})
                .subscribe(r => {
                    this.unPublishedNews = r.content;
                });
    }

    refreshAllNews() {
        if (this.adminAccess) {
            this.categoryService.getNewsCategories().subscribe(r => {
                this.categories = r;
            });
            this.refreshNews(true);
            this.refreshNews(false);
        }
    }

    openModal(news?: News) {
        if (!news)
            news = new News();

        const modalRef = this.modalService.open(NewsAdminModalComponent, {backdrop: 'static'});
        modalRef.componentInstance.news = news;
        modalRef.componentInstance.categories = this.categories;
        modalRef.componentInstance.modalClose.subscribe(() => {
            modalRef.close();
            this.refreshAllNews()
        });

    }

    openNews(article: News) {
        const modalRef = this.modalService.open(NewsModalComponent);
        modalRef.componentInstance.article = article;
    }

    deleteNews(article: News) {
        this.dialogService.confirm(this.translate.instant('filter.basic.deleteDialog.title'), this.translate.instant('filter.basic.deleteDialog.message'))
            .subscribe((value: boolean): void => {
                if (value) {
                    this.deleteConfirmed(article);
                }
            });
    }

    deleteConfirmed(article: News): void {
        this.http.delete("/api/news/admin/" + article.id).subscribe(r => this.refreshAllNews());
    }

    loadPage() {
        this.refreshNews(true);
    }
}


