import {Component, ContentChild, EventEmitter, Input, OnInit, Output, ViewChild, ViewChildren} from '@angular/core';
import {Principal} from '../../shared/auth/principal.service';
import {CompanyModel} from '../dto/company.model';
import {CompanySelectorService} from '../services/company-selector.service';
import {NgSelectComponent} from '@ng-select/ng-select';
import {debounceTime, switchMap, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'company-selector',
    templateUrl: './company-selector.component.html',
    styles: []
})
export class CompanySelectorComponent implements OnInit {

    companies: CompanyModel[] = [];

    @Input() selectedCompany: CompanyModel;
    @Input() disabled: boolean;
    @Input() adminMode: boolean;
    @Output() selectedCompanyChange: EventEmitter<CompanyModel> = new EventEmitter<CompanyModel>();
    @ViewChild('select', {static: true}) select: NgSelectComponent;

    userLangKey: string;
    private hasMore: boolean = true;
    private currentPage: number = 0;
    private readonly pageSize = 15;
    public loading: boolean = false;
    public loadingText: string = this.translateService.instant('filter.basic.load');
    public notFoundText: string = this.translateService.instant('multiSelectText.searchEmptyResult');
    public searchCompanyEvent = new EventEmitter<string>();

    constructor(private principal: Principal,
                private companySelectorService: CompanySelectorService,
                private translateService: TranslateService) {
        this.principal.identity().then((account) => {
            if (account)
                this.userLangKey = account.langKey;
        });
    }

    ngOnInit() {
        this.getCompanies(null, this.currentPage, this.pageSize);
        this.searchCompanyEvent
            .pipe(
                debounceTime(200),
                tap(() => {
                    this.companies = [];
                    this.currentPage = 0;
                    this.loading = true;
                }),
                switchMap(term => this.companySelectorService.getCompanies(this.adminMode, term, this.currentPage, this.pageSize))
            )
            .subscribe(pageResponse => {
                this.loading = false;
                this.responseHandler(pageResponse);
            }, (err) => {
                this.loading = false;
            });
    }

    selectCompany() {
        if (this.selectedCompany) {
            this.selectedCompanyChange.emit(this.selectedCompany);
        }
    }

    public fetchMore(filterValue: string) {
        if (this.hasMore) {
            this.currentPage++;
            this.getCompanies(filterValue, this.currentPage, this.pageSize);
        }
    };

    private getCompanies(name: string, page: number, pageSize: number): void {
        this.loading = true;
        this.companySelectorService.getCompanies(this.adminMode, name, page, pageSize).subscribe(pageResponse => {
            this.loading = false;
            this.responseHandler(pageResponse);
        });
    }

    private responseHandler(pageResponse: any): void {
        this.hasMore = this.currentPage + 1 < pageResponse.totalPages;
        this.companies = this.companies.concat(pageResponse.content);
        if (this.selectedCompany && this.companies.every(company => company.id !== this.selectedCompany.id)) {
            this.companies = [this.selectedCompany, ...this.companies];
        }
    }
}
