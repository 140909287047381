import {Component, Inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

export interface ModifyDialogData {
    value: any;
    title: string;
    paramName: string;
    type: 'text' | 'number';
    saveFunction: (data: any) => Observable<any>;
}

@Component({
    selector: 'rts-modify',
    templateUrl: './modify.component.html',
    styles: []
})
export class ModifyComponent implements OnInit {

    public value: any;
    public defaultValue: any;

    constructor(private dialogRef: MatDialogRef<ModifyComponent>,
                @Inject(MAT_DIALOG_DATA) public data: ModifyDialogData) {}

    ngOnInit(): void {
        this.value = this.data.value;
        this.defaultValue = this.data.value;
    }

    public save(): void {
        if (this.value && this.data.saveFunction) {
            this.data.saveFunction(this.value).subscribe(
                (): void => this.dialogRef.close()
            );
        }
    }

}
