import { AgreementModel } from './agreement.model'
import { PodModel } from './pod.model'

export class PeriodicalPodModel {
    agreement: AgreementModel;
    id: number;
    pod: PodModel;
    startTime: Date;
    endTime: Date;
    startTimeNgB: any;
    endTimeNgB: any;
}