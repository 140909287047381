import {Component, Input, OnInit} from '@angular/core';
import {Chart} from "angular-highcharts";

@Component({
    selector: 'jhi-price-trend-chart',
    templateUrl: './price-trend-chart.component.html',
    styleUrls: ['./price-trend-chart.component.scss']
})
export class PriceTrendChartComponent implements OnInit {

    @Input() priceData: number[];

    constructor() {
    }

    ngOnInit() {
    }

    public getChartForData(priceData: number[]): Chart {
        const data: number[] = !!priceData ? priceData : [];
        const chartOptions: any = this.getChartOptions(data);

        return new Chart({...chartOptions});
    }

    private getChartOptions(data: number[]): any {
        const chartOptions: any = {};

        chartOptions.chart = {
            backgroundColor: 'transparent',
            borderWidth: 0,
            margin: [2, 0, 2, 0],
            width: 140,
            height: 20,
            style: {
                overflow: 'visible'
            }
        };
        chartOptions.title = {
            text: ''
        };
        chartOptions.credits = {
            enabled: false
        };
        chartOptions.xAxis = {
            visible: false,
            lineWidth: 0,
            labels: {
                enabled: false,
            },
            title: {
                text: null,
            },
            startOnTick: false,
            endOnTick: false,
            tickPositions: []
        };
        chartOptions.yAxis = {
            visible: false,
            endOnTick: false,
            startOnTick: false,
            labels: {
                enabled: false,
            },
            title: {
                text: null,
            },
            tickPositions: [0]
        };
        chartOptions.legend = {
            enabled: false
        };
        chartOptions.tooltip = {
            enabled: true,
            formatter: function () {
                return this.y;
            },
            hideDelay: 0,
            outside: true,
            shared: true,
        };
        chartOptions.plotOptions = {
            series: {
                animation: false,
                lineWidth: 1,
                shadow: false,
                states: {
                    hover: {
                        lineWidth: 1,
                    }
                },
                marker: {
                    radius: 2,
                    states: {
                        hover: {
                            radius: 2
                        }
                    }
                },
                connectNulls: true
            },
            area: {
                enableMouseTracking: true
            }
        };
        chartOptions.series = [
            {
                name: 'Price',
                type: 'line',
                data: data
            }
        ];
        chartOptions.navigation = {
            buttonOptions: {
                enabled: false
            }
        };
        chartOptions.colors = ['#f24f00'];

        return chartOptions;
    }

}
