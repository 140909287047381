import { Route } from '@angular/router';

import { CompanySelectorPageComponent } from './company-selector-page.component';
import { UserRouteAccessService } from '../shared';

export const COMPANY_SELECTOR_ROUTE: Route = {
        path: 'company/selector',
        component: CompanySelectorPageComponent,
        data: {
            pageTitle: 'company-selector.title'
        },
        canActivate: [UserRouteAccessService]
    };
