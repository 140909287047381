import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";
import {TranslateService} from "@ngx-translate/core";
import {CoverageTransactionsAdminService} from '../coverage-transactions.admin.service';
import {DailyPricesService} from '../../shared/services/daily-prices.service';
import {LoadingOverlayService} from '../../shared/services/loading-overlay.service';
import {Observable, Subject} from 'rxjs';
import {AvailableProductPeriodsDto} from "../../shared/available-products/model/AvailableProductPeriodsDto";
import {NgForm} from "@angular/forms";
import * as _ from "lodash";
import {CanLeave, CanLeaveService} from "../../shared/can-leave/can-leave.service";


@Component({
    selector: 'jhi-available-products',
    templateUrl: './available-product-periods.component.html',
    styleUrls: ['./available-product-periods.component.scss']
})
export class AvailableProductPeriodsComponent implements OnInit, OnDestroy, CanLeave {

    public availablePeriods: AvailableProductPeriodsDto[] = [];
    public availablePeriodsDefault: AvailableProductPeriodsDto[] = [];

    private destroy: Subject<void> = new Subject();

    constructor(private http: HttpClient,
                private snackBar: MatSnackBar,
                private coverageTransactionsAdminService: CoverageTransactionsAdminService,
                private dailyPricesService: DailyPricesService,
                private translate: TranslateService,
                private loadingOverlay: LoadingOverlayService,
                private canLeaveService: CanLeaveService) {

    }

    ngOnInit(): void {
        this.loadAvailableProductPeriods();
    }

    ngOnDestroy(): void {
        this.destroy.next();
        this.destroy.complete();
    }

    canLeave(): Observable<boolean> {
        return this.canLeaveService.canLeave();
    }

    public loadAvailableProductPeriods() {
        setTimeout(() => this.loadingOverlay.turnOn());
        this.coverageTransactionsAdminService.getAvailableProductPeriods().subscribe((data: AvailableProductPeriodsDto[]) => {
            this.availablePeriods = _.cloneDeep(data);
            this.availablePeriodsDefault = _.cloneDeep(data);

            this.loadingOverlay.turnOff();
        }, () => this.loadingOverlay.turnOff());
    }

    public successSnackBar(message: string, action?: string) {
        this.snackBar.open(message, action, {duration: 3000});
    }

    public onSubmit(rows: AvailableProductPeriodsDto[], form: NgForm) {
        this.http.post("api/available-product-periods/update", rows).subscribe((data) => {
            this.successSnackBar(this.translate.instant('common.result.saveSuccess'));
            this.loadAvailableProductPeriods();
            this.canLeaveService.setModified(false);
        });
    }

    public onKeyDown(event: any) {
        // Do not allow negative values.
        if (event.key === '-') {
            event.preventDefault();
        }
        this.canLeaveService.setModified(true);
    }

    public isSaveDisabled(): boolean {
        return JSON.stringify(this.availablePeriods) === JSON.stringify(this.availablePeriodsDefault) ||
            this.availablePeriods.filter((period: AvailableProductPeriodsDto) => period.availablePeriodCount !== 0 && !period.availablePeriodCount).length !== 0;
    }

    public reset(): void {
        this.availablePeriods = _.cloneDeep(this.availablePeriodsDefault);
        this.canLeaveService.setModified(false);
    }
}
