<div class="modal-header">
    <h4 class="modal-title">{{(tracker?.id ? 'priceTracker.modal.title.modify' : 'priceTracker.modal.title.new') | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modalClose.emit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form #priceTrackerForm="ngForm" name="priceTrackerForm" (ngSubmit)="onSubmit(priceTrackerForm)">
    <div class="modal-body">
        <div class="row">
            <div class="form-group col-6">
                <label>{{'priceTracker.modal.label.years' | translate}}</label>
                <select class="form-control"
                        [(ngModel)]="selectedTracker.year"
                        (change)="onYearChange()"
                        name="year"
                        required>
                    <option *ngFor="let year of years"
                            [ngValue]="year">
                        {{year}}
                    </option>
                </select>
            </div>
            <div class="form-group col-6"></div>
        </div>
        <div class="row">
            <div class="form-group col-12">
                <label>{{'priceTracker.modal.label.period' | translate}}</label>
                <select [(ngModel)]="selectedTracker.period"
                        [disabled]="periodDisabled()"
                        (change)="periodChange()"
                        class="form-control"
                        name="period"
                        required>
                    <option *ngFor="let item of periods"
                            [ngValue]="item">{{item}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12">
                <label>{{'priceTracker.modal.label.periodNumber' | translate}}</label>
                <select [(ngModel)]="selectedTracker.periodNumber"
                        [disabled]="periodNumbersDisabled()"
                        (change)="periodNumberChange()"
                        class="form-control"
                        name="periodNumber"
                        required>
                    <option *ngFor="let item of periodNumbers"
                            [ngValue]="item">{{item}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md-6">
                <label>{{'priceTracker.modal.label.minPrice' | translate}}</label>
                <input type="text" #minPriceInput="ngModel"
                       name="minPrice"
                       mask="separator.2"
                       autocomplete="off"
                       class="text-right form-control"
                       maxlength="8"
                       (keydown)="onKeyDown($event)"
                       [(ngModel)]="selectedTracker.minPrice"
                       [thousandSeparator]="'number.thousandSeparator' | translate"
                       [decimalMarker]="'number.decimalMarker' | translate"
                       [dropSpecialCharacters]="true"
                       [validation]="true"
                       [maxNumber]="9999.99">
                <div *ngIf="minPriceInput.dirty && minPriceInput.invalid">
                    <small class="form-text text-danger" *ngIf="minPriceInput.errors.required">
                        {{ 'entity.validation.required' | translate }}
                    </small>
                    <small class="form-text text-danger" *ngIf="minPriceInput.errors.min">
                        {{ 'entity.validation.min' | translate: {min: 0 | number} }}
                    </small>
                    <small class="form-text text-danger" *ngIf="minPriceInput.errors.max">
                        {{ 'entity.validation.max' | translate: {max: 9999.99 | number} }}
                    </small>
                </div>
                <div *ngIf="minGreaterOrEqualMax()">
                    <small class="form-text text-danger">
                        {{ 'priceTracker.modal.validation.minMax' | translate }}
                    </small>
                </div>
            </div>
            <div class="form-group col-md-6">
                <label>{{'priceTracker.modal.label.maxPrice' | translate}}</label>
                <input type="text" #maxPriceInput="ngModel"
                       name="maxPrice"
                       mask="separator.2"
                       autocomplete="off"
                       class="text-right form-control"
                       maxlength="8"
                       (keydown)="onKeyDown($event)"
                       [(ngModel)]="selectedTracker.maxPrice"
                       thousandSeparator="{{'number.thousandSeparator' | translate}}"
                       [decimalMarker]="'number.decimalMarker' | translate"
                       [dropSpecialCharacters]="true"
                       [validation]="true"
                       [maxNumber]="9999.99">
                <div *ngIf="maxPriceInput.dirty && maxPriceInput.invalid">
                    <small class="form-text text-danger" *ngIf="maxPriceInput.errors.required">
                        {{ 'entity.validation.required' | translate }}
                    </small>
                    <small class="form-text text-danger" *ngIf="maxPriceInput.errors.min">
                        {{ 'entity.validation.min' | translate: {min: 0 | number} }}
                    </small>
                    <small class="form-text text-danger" *ngIf="maxPriceInput.errors.max">
                        {{ 'entity.validation.max' | translate: {max: 9999.99 | number} }}
                    </small>
                </div>
            </div>
        </div>
        <div *ngIf="hasError()" class="row mx-0 mt-3">
            <div *ngIf="hasError()"
                 class="alert alert-danger col-12 m-0" role="alert">
                <ul class="m-0">
                    <li *ngFor="let error of errorMessages">{{error}}</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button [disabled]="!canSave(priceTrackerForm)" class="btn btn-primary"
                type="submit">{{'common.action.save' | translate}}</button>
    </div>
</form>
