<div class="card px-3 py-3 mt-3 bg-grey">
    <header class="cez-table-header">
        <h4 class="card-title">{{'company.new.cezContacts' | translate}}</h4>
    </header>
    <jhi-cez-contact-list [contacts]="partner.cezContacts"></jhi-cez-contact-list>
</div>
<div class="card px-3 py-3 mt-3 bg-grey">
    <header class="cez-table-header">
        <h4 class="card-title" jhiTranslate="agreement.agreementData.contactsTitle">Contacts</h4>
    </header>
    <jhi-contact-list [contacts]="partner.contacts" [adminView]="false" [preventDelete]="true"></jhi-contact-list>
</div>
