import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {DynamicDateComponent} from './dynamic-date/dynamic-date.component';
import {FiltersComponent} from './filters/filters.component';
import {InvoiceColumnDiagramComponent} from './invoice-column-diagram/invoice-column-diagram.component';
import {InvoiceFileComponent} from './invoice-file/invoice-file.component';
import {InvoiceReportComponent} from './invoice-report.component';
import {TableComponent} from './table/table.component';
import {InvoicePieChartComponent} from './invoice-pie-chart/invoice-pie-chart.component';
import {TreeTableModule} from 'primeng/treetable';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {CezPortalSharedModule} from '../shared';
import {ChipListAutocompleteComponent} from '../shared/controls/autocomplete-chip-list/chip-list-autocomplete.component';

@NgModule({
    imports: [
        CommonModule,
        CezPortalSharedModule,
        TreeTableModule,
        ScrollPanelModule
    ],
    declarations: [
        InvoiceReportComponent,
        FiltersComponent,
        DynamicDateComponent,
        TableComponent,
        ChipListAutocompleteComponent,
        InvoiceColumnDiagramComponent,
        InvoicePieChartComponent,
        InvoiceFileComponent
    ],
    exports: [TableComponent, InvoiceColumnDiagramComponent, InvoicePieChartComponent, InvoiceFileComponent, InvoiceReportComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InvoiceReportModule {
}
