import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';

import {FilterModel} from '../../consumption/filter-basic/filter.model';
import {BoxPlotResponse} from '../dto/boxplot.response.model';
import {MinMaxResponse} from '../dto/min.max.response.model';
import {PodSpectrumResponse} from '../dto/pod.spectrum.response';
import {PodValueRequest} from '../dto/pod.value.request';
import {PodValueResponse} from '../dto/pod.value.response';
import {ReferenceWeekRequest} from '../dto/reference.week.request';
import {TemperatureResponse} from '../dto/temperature.response';

import {NgBDatePickerConvertService} from './ngb.datepicker.convert.service';
import {PodValueConsumptionScheduleRequest} from '../dto/pod.value.consumption.schedule.request';
import {PodValueConsumptionScheduleResponse} from '../dto/pod.value.consumption.schedule.response';
import {Moment} from "moment";
import {Resolution} from "../time-series/model/resolution.enum";

@Injectable()
export class PodValueService {

    response: PodValueResponse;

    constructor(private http: HttpClient,
                private dateConverter: NgBDatePickerConvertService) {

    }

    createApiParams(filterModel: FilterModel): PodValueRequest {
        let apiParams = {
            startTime: this.dateConverter.convertToDate(filterModel.periodParameters[0].dateFrom).toISOString(),
            endTime: this.dateConverter.convertToDate(filterModel.periodParameters[0].dateTo).toISOString(),
            resolution: filterModel.resolution,
            podIds: filterModel.selectedPods.map(r => r.id),
            podGroupIds: filterModel.selectedPodGroups.map(r => r.id)
        };

        return apiParams;
    }

    getValues(podValueDto: PodValueRequest): Observable<PodValueResponse> {
        return this.http.post<PodValueResponse>('api/pod_value/pod_values', podValueDto);
    }

    getTemperatureValues(podValueDto: PodValueRequest): Observable<TemperatureResponse> {
        return this.http.post<TemperatureResponse>('/api/temperature/get_interval', podValueDto);
    }

    getBoxPlotData(podValueDto: PodValueRequest): Observable<BoxPlotResponse> {
        return this.http.post<BoxPlotResponse>('api/pod_value/quartiles', podValueDto);
    }

    getMinMaxData(podValueDto: PodValueRequest): Observable<MinMaxResponse> {
        return this.http.post<MinMaxResponse>('api/pod_value/min_max_average', podValueDto);
    }

    getSpectrumData(podValueDto: PodValueRequest): Observable<PodSpectrumResponse> {
        return this.http.post<PodSpectrumResponse>('api/pod_value/spectrum', podValueDto);
    }

    getReferenceWeek(podValueDto: ReferenceWeekRequest): Observable<PodValueResponse> {
        return this.http.post<PodValueResponse>('api/pod_value/reference_week', podValueDto);
    }

    getConsumptionSchedule(podValueDto: PodValueConsumptionScheduleRequest): Observable<PodValueConsumptionScheduleResponse> {
        return this.http.post<PodValueConsumptionScheduleResponse>('api/pod_value/consumption_schedule', podValueDto);
    }

    getConsumptionFact(startTime: Moment, endTime: Moment, resolution: Resolution, deliveryPeriodId: number): Observable<PodValueResponse> {
        return this.http.get<PodValueResponse>('api/pod_value/consumption_fact', {
                params: new HttpParams({
                    fromObject: {
                        startTime: startTime.toISOString(),
                        endTime: endTime.toISOString(),
                        resolution,
                        deliveryPeriodId: String(deliveryPeriodId)
                    }
                })
            }
        );
    }

    getComparedValues(podValueDto: PodValueRequest): Observable<PodValueResponse[]> {
      let observables: Observable<PodValueResponse>[] = [];


        let podIds = podValueDto.podIds.slice();
        for (let podId of podIds) {
          let podParameter = { ...podValueDto, podGroupIds: [], podIds: [podId] };
            observables.push(this.http.post<PodValueResponse>('api/pod_value/pod_values', podParameter));

        }

        let podGroupIds = podValueDto.podGroupIds.slice();
        for (let podGroupId of podGroupIds) {
          let podParameter = { ...podValueDto, podIds: [], podGroupIds: [podGroupId] };
          observables.push(this.http.post<PodValueResponse>('api/pod_value/pod_values', podParameter));

        }

        return forkJoin(observables);
    }

    getMultiPeriodValues(filterModel: FilterModel): Observable<PodValueResponse[]> {

      let observables: Observable<PodValueResponse>[] = [];
        for (let periodParam of filterModel.periodParameters) {
            if (!periodParam.dateFrom)
                break;

            let apiParams = {
                startTime: this.dateConverter.convertToDate(periodParam.dateFrom).toISOString(),
                endTime: this.dateConverter.convertToDate(periodParam.dateTo).toISOString(),
                resolution: filterModel.resolution,
                podIds: filterModel.selectedPods.map(r => r.id),
                podGroupIds: filterModel.selectedPodGroups.map(r => r.id)
            };
          observables.push(this.getValues(apiParams));
        }
        return forkJoin(observables);
    }

}
