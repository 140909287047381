import { Route } from '@angular/router';

import { NavbarComponent } from './layouts/navbar/navbar.component';
import {CanDeactivateGuard} from "./shared/can-leave/can-deactivate.guard";

export const navbarRoute: Route = {
  path: '',
  component: NavbarComponent,
  outlet: 'navbar',
  canDeactivate: [CanDeactivateGuard]
};



// AppRoutingModule
