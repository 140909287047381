import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TolerancePeriod } from "../../../shared/dto/tolerance.limits";
import { NgBDatePickerConvertService } from "../../../shared/services/ngb.datepicker.convert.service";




@Component({
  selector: 'jhi-tolerance-limit-modal',
  templateUrl: './tolerance-limit-modal.component.html',
  styles: []
})
export class ToleranceLimitModalComponent implements OnInit {

  @Input() model: TolerancePeriod
  @Output() modalClose: EventEmitter<TolerancePeriod> = new EventEmitter();

  constructor(private dateConverter: NgBDatePickerConvertService, public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (this.model.startTime) { 
      this.model.startTimeNgB = this.dateConverter.convertFromDate(this.model.startTime);
      this.model.endTimeNgB = this.dateConverter.convertFromDate(this.model.endTime);
    }
  }

  save() { 
    this.model.startTime = this.dateConverter.convertToDate(this.model.startTimeNgB);
    this.model.endTime = this.dateConverter.convertToDate(this.model.endTimeNgB);
    this.modalClose.emit(this.model);
  }
}
