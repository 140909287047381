<header class="docs">
    <aside>
        <button class="btn btn-primary" (click)="openDoc(null)" jhiTranslate="documents.newDocument"
                [disabled]="!selectedCompany">Új dokumentum
        </button>
    </aside>
</header>

<company-selector [adminMode]="true" [(selectedCompany)]="selectedCompany"
                  (selectedCompanyChange)="selectedCompanyChange()"></company-selector>

<div class="col-xl-6 offset-xl-3">
    <section *ngFor="let doc of docList">
        <header class="mt-3">
            <h2>{{doc.categoryName}}</h2>
        </header>
        <cez-document-list [documentByCategory]="doc"
                           [adminMode]="true"
                           (editClick)="openDoc($event)"
                           (deleteClick)="deleteDoc($event)">
        </cez-document-list>
    </section>
</div>
