<div>
    <div class="row">
        <div class="col-md-8 offset-md-2">
            <h2 jhiTranslate="password.title" *ngIf="account">Password for [<b>{{account.login}}</b>]</h2>

            <div class="alert alert-success" *ngIf="success" jhiTranslate="password.messages.success">
                <strong>Password changed!</strong>
            </div>

            <div class="alert alert-danger" *ngIf="doNotMatch" jhiTranslate="global.messages.error.dontmatch">
                The password and its confirmation do not match!
            </div>

            <form name="form" role="form" (ngSubmit)="changePassword()" #passwordForm="ngForm">

                <div class="form-group">
                    <label class="form-control-label" for="password" jhiTranslate="global.form.newpassword">New password</label>
                    <input type="password" class="form-control" id="password" name="password" #passwordInput="ngModel"
                    placeholder="{{'global.form.newpassword.placeholder' | translate}}"
                           [(ngModel)]="password" minlength=4 maxlength=100 required>
                    <div *ngIf="passwordInput.dirty && passwordInput.invalid">
                        <small class="form-text text-danger"
                           *ngIf="passwordInput.errors.required" jhiTranslate="global.messages.validate.newpassword.required">
                            Your password is required.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="passwordInput.errors.minlength" jhiTranslate="global.messages.validate.newpassword.minlength">
                            Your password is required to be at least 4 characters.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="passwordInput.errors.maxlength" jhiTranslate="global.messages.validate.newpassword.maxlength">
                            Your password cannot be longer than 50 characters.
                        </small>
                    </div>
                    <jhi-password-strength-bar [passwordToCheck]="password"></jhi-password-strength-bar>
                </div>
                <div class="form-group">
                    <label class="form-control-label" for="confirmPassword" jhiTranslate="global.form.confirmpassword">New password confirmation</label>
                    <input type="password" class="form-control" id="confirmPassword" name="confirmPassword" #confirmPasswordInput="ngModel"
                    placeholder="{{'global.form.confirmpassword.placeholder' | translate}}"
                           [(ngModel)]="confirmPassword" minlength=4 maxlength=100 required>
                    <div *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                        <small class="form-text text-danger"
                           *ngIf="confirmPasswordInput.errors.required" jhiTranslate="global.messages.validate.confirmpassword.required">
                            Your confirmation password is required.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="confirmPasswordInput.errors.minlength" jhiTranslate="global.messages.validate.confirmpassword.minlength">
                            Your confirmation password is required to be at least 4 characters.
                        </small>
                        <small class="form-text text-danger"
                           *ngIf="confirmPasswordInput.errors.maxlength" jhiTranslate="global.messages.validate.confirmpassword.maxlength">
                            Your confirmation password cannot be longer than 50 characters.
                        </small>
                    </div>
                </div>

                <button type="submit" [disabled]="passwordForm.form.invalid" class="btn btn-primary" jhiTranslate="password.form.button">Save</button>
            </form>
        </div>
    </div>
</div>
