import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillsComponent } from './bills.component';
import { RouterModule } from '@angular/router';
import { BILLS_ROUTE } from "./bills.route";
import { InvoicesModule } from '../invoices/invoices.module';
import {CezPortalSharedModule} from '../shared';

@NgModule({
    imports: [
        CommonModule,
        CezPortalSharedModule,
        RouterModule.forRoot(BILLS_ROUTE, {useHash: true}),
        InvoicesModule
    ],
    declarations: [BillsComponent]
})
export class BillsModule {
}
