<form name="editForm" role="form" novalidate (ngSubmit)="save()" #editForm="ngForm">

  <div class="modal-header">
    <h4 class="modal-title" id="myUserLabel" jhiTranslate="userManagement.home.createOrEditLabel">
      Create or edit a User</h4>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="clear()">&times;</button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="form-group col-md-6">
        <label jhiTranslate="global.field.id">ID</label>
        <input type="text" class="form-control" name="id" [(ngModel)]="user.id" readonly>
      </div>
      <div class="form-group col-md-6">
        <label class="form-control-label" jhiTranslate="userManagement.login">Login</label>
        <input type="text" class="form-control" name="login" #loginInput="ngModel" [(ngModel)]="user.login" required
               minlength=1
               maxlength=50 pattern="[_'.@A-Za-z0-9-]*">

        <div *ngIf="loginInput.dirty && loginInput.invalid">
          <small class="form-text text-danger" *ngIf="loginInput.errors.required"
                 jhiTranslate="entity.validation.required">
            This field is required.
          </small>

          <small class="form-text text-danger" *ngIf="loginInput.errors.maxlength"
                 jhiTranslate="entity.validation.maxlength" translate-value-max="50">
            This field cannot be longer than 50 characters.
          </small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label class="form-control-label" jhiTranslate="userManagement.firstName">First Name</label>
        <input type="text" class="form-control" name="firstName" #firstNameInput="ngModel" [(ngModel)]="user.firstName"
               maxlength="50">

        <div *ngIf="firstNameInput.dirty && firstNameInput.invalid">
          <small class="form-text text-danger" *ngIf="firstNameInput.errors.maxlength"
                 jhiTranslate="entity.validation.maxlength" translate-value-max="50">
            This field cannot be longer than 50 characters.
          </small>
        </div>
      </div>
      <div class="form-group col-md-6">
        <label jhiTranslate="userManagement.lastName">Last Name</label>
        <input type="text" class="form-control" name="lastName" #lastNameInput="ngModel" [(ngModel)]="user.lastName"
               maxlength="50">

        <div *ngIf="lastNameInput.dirty && lastNameInput.invalid">
          <small class="form-text text-danger" *ngIf="lastNameInput.errors.maxlength"
                 jhiTranslate="entity.validation.maxlength" translate-value-max="50">
            This field cannot be longer than 50 characters.
          </small>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label class="form-control-label" jhiTranslate="userManagement.email">Email</label>
        <input type="email" class="form-control" name="email" #emailInput="ngModel" [(ngModel)]="user.email" required
               maxlength="100" email>

        <div *ngIf="emailInput.dirty && emailInput.invalid">
          <small class="form-text text-danger" *ngIf="emailInput.errors.required"
                 jhiTranslate="entity.validation.required">
            This field is required.
          </small>

          <small class="form-text text-danger" *ngIf="emailInput.errors.maxlength"
                 jhiTranslate="entity.validation.maxlength" translate-value-max="100">
            This field cannot be longer than 100 characters.
          </small>
          <small class="form-text text-danger" *ngIf="emailInput.errors.email"
                 jhiTranslate="global.messages.validate.email.invalid">
            Your e-mail is invalid.
          </small>
        </div>
      </div>
      <div class="form-group col-md-6" *ngIf="languages && languages.length > 0">
        <label jhiTranslate="userManagement.langKey">Lang Key</label>
        <select class="form-control" id="langKey" name="langKey" [(ngModel)]="user.langKey" required>
          <option></option>
          <option *ngFor="let language of languages" [value]="language">{{language | findLanguageFromKey}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <p-checkbox [disabled]="user.id === null" label="{{'userManagement.activated' | translate}}" name="activated"
                    [ngModel]="user.id === null ? true : user.activated"
                    (ngModelChange)="user.id === null ? true : user.activated = $event"
                    binary="true"></p-checkbox>
      </div>
      <div class="form-group col-md-6">

        <p-checkbox label="{{'userManagement.twoFactorAuthRequired' | translate}}" name="twoFactorAuthRequired"
                    [(ngModel)]="user.twoFactorAuthRequired" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <p-checkbox disabled="true" label="{{'userManagement.twoFactorKeyActivated' | translate}}"
                    name="twoFactorKeyActivated" [ngModel]="user.twoFactorKeyActivated"
                    binary="true"></p-checkbox>
      </div>
      <div class="form-group col-md-6">
        <p-checkbox label="{{'userManagement.twoFactorReset' | translate}}" name="twoFactorReset"
                    [(ngModel)]="twoFactorReset" binary="true"></p-checkbox>
      </div>
    </div>
    <div class="form-group" *ngIf="user.id">
      <label jhiTranslate="userManagement.profilePicture">Profile picture</label>
      <!--<file-uploader [maxSizeInKb]="5120" [fileType]="'image/*'" (onSelection)="fileuploaderFileChange($event)"></file-uploader>-->
      <div class="col-auto">
        <div style="display: flex; align-items: center;">
          <input placeholder="{{'select.default' | translate}}" readonly class="form-control"
                 style="width: 200px; float: left; margin-right: 0px;" matInput *ngIf="!fileInput?.httpUrl"
                 value="{{fileInput?.files[0]?.name}}"/>
          <button mat-mini-fab color="primary" (click)="addFile(fileInput)" type="button">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <input #fileInput [hidden]="true" type="file" accept="image/*"/>
      </div>

      <cez-file-upload class="col"
                       *ngIf="fileInput.value"
                       [file]="fileInput.files[0]"
                       [autoUpload]="true"
                       (onUpload)="onUpload($event)"
                       (removeEvent)="fileInput.value = ''"></cez-file-upload>
    </div>
    <div class="form-group">
      <label jhiTranslate="userManagement.profiles"></label>
      <select class="form-control" multiple name="authority" [(ngModel)]="user.groups">
        <option></option>
        <option *ngFor="let authority of authorities" [value]="authority.id">{{authority.name}}</option>
      </select>
    </div>
    <div class="form-group">
      <label jhiTranslate="userManagement.roleGroup"></label>
      <select class="form-control" name="roleGroup" [(ngModel)]="user.roleGroup">
        <option [value]="null"></option>
        <option *ngFor="let roleGroup of roleGroups" [value]="roleGroup.id">{{roleGroup.name}}</option>
      </select>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary no-radius" data-dismiss="modal" (click)="clear()">
      <span class="fa fa-ban"></span>&nbsp;
      <span jhiTranslate="entity.action.cancel">Cancel</span>
    </button>
    <button type="submit" [disabled]="editForm.form.invalid || isSaving" class="btn btn-primary">
      <span class="fa fa-floppy-o"></span>&nbsp;
      <span jhiTranslate="entity.action.save">Save</span>
    </button>
  </div>
</form>
