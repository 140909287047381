import {Component, Input} from '@angular/core';
import {TreeNode} from 'primeng/api';
import {
    InvoiceReportResponse,
    InvoiceReportResponseGroup,
    InvoiceReportResponseResource
} from '../model/response.model';
import moment from "moment-timezone";

@Component({
    selector: 'jhi-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent {

    tableData: TreeNode[];

    @Input() set data(sourceData: InvoiceReportResponse) {
        this.createTableData(sourceData);
    }

    createTableData(sourceData: InvoiceReportResponse) {
        this.tableData = sourceData.groups.map(value => this.createResourceGroupLevelNode(value));
    }

    createResourceGroupLevelNode(group: InvoiceReportResponseGroup): TreeNode {
        return {
            data: {
                groupName: group.group,
                value: group.value
            },
            children: group.resources.map(resource => this.createResourceLevelNode(resource))
        };
    }

    createResourceLevelNode(resource: InvoiceReportResponseResource): TreeNode {
        return {
            data: {
                resourceName: resource.resourceName,
                value: resource.value
            },
            children: resource.intervals.map(interval => this.createIntervalLevelNode(interval, resource.intervals.length <= 1))
        };
    }

    createIntervalLevelNode(interval, shouldCreateChildren: boolean): TreeNode {
        return {
            data: {
                intervalStart: interval.interval.start,
                intervalEnd: moment(interval.interval.end).add(-1, 'month'),
                value: interval.value
            },
            children: shouldCreateChildren ? this.createPodsAndPodGroupsLevelNodes(interval.pods, interval.podGroups) : []
        };
    }

    createPodsAndPodGroupsLevelNodes(pods, podGroups): TreeNode[] {
        const podNodes: TreeNode[] = pods.map(pod => ({
            data: {
                podName: pod.name,
                value: pod.value
            },
            children: []
        }));
        const podGroupNodes: TreeNode[] = podGroups.map(group => ({
            data: {
                podName: group.name,
                value: group.value
            },
            children: []
        }));
        return podNodes.concat(...podGroupNodes);
    }

}
