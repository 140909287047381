<!--<div class="links" *ngFor="let category of linkList">
  <header>
    <h1>{{category.name}}</h1>
  </header>-->
<section class="newsList">
    <article *ngFor="let article of linkList" class="tile">
        <img class="rectangle80 ml-3" src="{{newsService.getPicture(article.picture)}}" alt="">
        <div>
            <header>
                <a target="_blank" href="{{article.linkUrl}}">
                    <strong>{{article.title}}</strong>
                </a>
            </header>
            <p>
                {{article.description}}
            </p>
        </div>
    </article>
</section>
<!--</div>-->