export class ReportTabsUtil {

    public static consumptionPermission: string = 'CONSUMPTION_SCREEN';

    public static consumptionSoftPermission: string[] = [
        'CONSUMPTION_CHART_CONSUMPTION',
        'CONSUMPTION_CHART_CONSUMPTION_RANKING',
        'CONSUMPTION_CHART_DISTRIBUTION',
        'CONSUMPTION_CHART_BOX_PLOT',
        'CONSUMPTION_CHART_MIN_MAX_AVG',
        'CONSUMPTION_CHART_HEATMAP'
    ];

    public static invoicePermission: string = 'INVOICE_REPORT_SCREEN';

    public static invoiceSoftPermission: string[] = [
        'INVOICE_REPORT_TABLE',
        'INVOICE_REPORT_BAR_CHART',
        'INVOICE_REPORT_PIE_CHART'
    ];

    public static pricePermission: string = 'PRICE_REPORT_SCREEN';

    public static priceSoftPermission: string[] = [
        'PRICE_REPORT_SPOT_HU',
        'PRICE_REPORT_SPOT_FOREIGN',
        'PRICE_REPORT_FORWARD_HU',
        'PRICE_REPORT_FORWARD_FOREIGN',
        'PRICE_REPORT_ADDITIONAL'
    ];

    public static tolerancePermission: string = 'TOLERANCE_THRESHOLD_SCREEN';

    public static toleranceSoftPermission: string[] = [
        'TOLERANCE_THRESHOLD_READ',
        'TOLERANCE_THRESHOLD_ADMIN'
    ];

    public static hasPermission(permissions: string[], permission: string): boolean {
        return permissions.filter(p => p === permission).length > 0;
    }

    public static hasSoftPermissionPermission(permissions: string[], softPermissions: string[]): boolean {
        return permissions.filter(sp => softPermissions.indexOf(sp) > -1).length != 0;
    }
}
