import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ConsumptionDisplayModeChangeEventModel} from './consumption-display-mode-change-event.model';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Resolution} from "../../shared/time-series/model/resolution.enum";
import {TranslateService} from "@ngx-translate/core";
import {FilterModel} from "../filter-basic/filter.model";
import {Moment} from "moment";
import {ResolutionNew} from "../../shared/time-series/model/resolution_new.enum";

@Component({
    selector: 'jhi-consumption-display-mode',
    templateUrl: './consumption-display-mode.component.html',
    styleUrls: []
})
export class ConsumptionDisplayModeComponent implements OnInit {

    timeSeriesResolutions: any[];

    form: UntypedFormGroup;

    @Input()
    from: Moment;
    @Input()
    to: Moment;
    @Input()
    filterModel: FilterModel;

    @Input() resolution: ResolutionNew;

    @Output() settingsChanged: EventEmitter<ConsumptionDisplayModeChangeEventModel> = new EventEmitter<ConsumptionDisplayModeChangeEventModel>();

    constructor(private fb: UntypedFormBuilder,
                public translateService: TranslateService) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            'from': [],
            'to': [],
            'resolution': [this.resolution || ResolutionNew.HOUR, Validators.required]
        });

        this.timeSeriesResolutions = this.getResolutionList();

        this.form.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(1000))
            .subscribe(value => {
                // if (this.form.valid) {
                    this.settingsChanged.emit({
                        resolution: value.resolution
                    });
                //}
            });

        this.form.controls['from'].valueChanges.subscribe((startDate: Moment) => {
            const endDate: Moment = this.form.controls['to'].value;
            if (startDate && endDate && startDate.isAfter(endDate)) {
                this.form.controls['to'].setValue(startDate.clone(), {emitEvent: false});
            }
        });

        this.form.controls['to'].valueChanges.subscribe((endDate: Moment) => {
            const startDate: Moment = this.form.controls['from'].value;
            if (startDate && endDate && startDate.isAfter(endDate)) {
                this.form.controls['from'].setValue(endDate.clone(), {emitEvent: false});
            }
        });

    }

    getResolutionList() {
        return [
            {
                value: ResolutionNew.MIN_15,
                description: this.translateService.instant('filter.basic.min15'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_QUARTER_HOURLY'
            },
            {
                value: ResolutionNew.HOUR,
                description: this.translateService.instant('filter.basic.hour'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_HOURLY'
            },
            {
                value: ResolutionNew.DAY,
                description: this.translateService.instant('filter.basic.day'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_DAILY'
            },
            {
                value: ResolutionNew.WEEK,
                description: this.translateService.instant('filter.basic.week'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_WEEKLY'
            },
            {
                value: ResolutionNew.MONTH,
                description: this.translateService.instant('filter.basic.month'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_MONTHLY'
            },
            {
                value: ResolutionNew.QUARTER_OF_YEAR,
                description: this.translateService.instant('filter.basic.quarterOfYear'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_QUARTER_YEARLY'
            },
            {
                value: ResolutionNew.YEAR,
                description: this.translateService.instant('filter.basic.year'),
                enabled: true,
                requiredPermission: 'CONSUMPTION_RESOLUTION_YEARLY'
            }
        ];
    }

    public refIntervalVisible(): boolean {
        return this.filterModel.selectedDiagram.id === 1;
    }
}
