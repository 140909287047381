import {Directive, Input, OnInit} from '@angular/core';
import {NgbNavItem} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';

@Directive({
  selector: 'li[ngbNavItem][idAndTitleTranslateKey]:not([id])'
})
export class TabIdAndTitleSetterDirective implements OnInit {

    @Input()
    public idAndTitleTranslateKey: Required<string>;

    constructor(private tab: NgbNavItem) {
    }

    /**
     * Needed by `TabSaveDirective`, we can't have dynamic tab ids
     * @see TabSaveDirective
     */
    ngOnInit(): void {
        this.tab._id = this.idAndTitleTranslateKey;
    }

}
