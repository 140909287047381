import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatSeconds'
})
export class FormatSecondsPipe implements PipeTransform {

    transform(value: number): string {
        if (value === null) {
            return '';
        }

        const minutes = Math.floor(value / 60);
        const seconds = Math.floor(value - minutes * 60);
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

}
