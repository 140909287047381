<header class="docs">
    <h2 jhiTranslate="main.link.scheduling"></h2>
    <div *ngIf="!editDefaults && !viewDetail" class="right">
        <button class="btn btn-primary" (click)="editDefaultSettings()"
                jhiTranslate="scheduling.booked-performance-settings.button"
                [disabled]="('BOOKED_PERFORMANCE_READ' | doesNotHavePermission)"
                *permissionOverlay="'BOOKED_PERFORMANCE_READ'">Edit defaults
        </button>
    </div>
</header>

<form *ngIf="editDefaults" name="bookedPerformanceDefaultForm" role="form" #bookedPerformanceDefaultForm="ngForm"
      (ngSubmit)="saveDefaultSettings()">
    <div class="card px-3 py-3 mt-3" *ngIf="!viewDetail">
        <header class="cez-table-header">
            <h4 class="card-title" jhiTranslate="scheduling.booked-performance-settings.title">General contracted
                capacity warnings setting</h4>
        </header>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="bookedPerformanceMailTo" jhiTranslate="scheduling.booked-performance-settings.mailto">E-mail
                        to</label>
                    <input
                        [disabled]="!bookedPerformanceDefaults.bookedPerformanceEnableEmail || ('BOOKED_PERFORMANCE_WRITE' | doesNotHavePermission)"
                        type="email" email
                        id="bookedPerformanceMailTo" name="bookedPerformanceMailTo"
                        #emailInput="ngModel" [(ngModel)]="bookedPerformanceDefaults.bookedPerformanceMailTo"
                        class="form-control" maxlength="100">
                    <div *ngIf="emailInput.dirty && emailInput.invalid">
                        <small class="form-text text-danger" *ngIf="emailInput.errors.maxlength"
                               jhiTranslate="entity.validation.maxlength" translate-value-max="100">
                            This field cannot be longer than 100 characters.
                        </small>
                        <small class="form-text text-danger" *ngIf="emailInput.errors.email"
                               jhiTranslate="global.messages.validate.email.invalid">
                            Your e-mail is invalid.
                        </small>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group" *ngIf="languages && languages.length > 0">
                    <label for="bookedPerformanceEmailLanguage"
                           jhiTranslate="scheduling.booked-performance-settings.language">Lang key</label>
                    <select
                        [disabled]="!bookedPerformanceDefaults.bookedPerformanceEnableEmail || ('BOOKED_PERFORMANCE_WRITE' | doesNotHavePermission)"
                        class="form-control"
                        id="bookedPerformanceEmailLanguage"
                        name="bookedPerformanceEmailLanguage"
                        [(ngModel)]="bookedPerformanceDefaults.bookedPerformanceEmailLanguage">
                        <option></option>
                        <option *ngFor="let language of languages"
                                [value]="language">{{ language | findLanguageFromKey }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="bookedPerformanceDefaultPercentage"
                           jhiTranslate="scheduling.booked-performance-settings.label">Capacity
                        limit warning at (%)</label>
                    <div class="input-group">
                        <input type="number" id="bookedPerformanceDefaultPercentage"
                               name="bookedPerformanceDefaultPercentage" min="0" max="100"
                               [(ngModel)]="bookedPerformanceDefaults.bookedPerformanceDefaultPercentage"
                               (change)="checkValidity()" class="form-control"
                               [disabled]="('BOOKED_PERFORMANCE_WRITE' | doesNotHavePermission)">
                        <span class="input-group-append">
              <span class="input-group-text">%</span>
            </span>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group pt-08">
                    <label></label>
                    <p-checkbox label="{{translateService.instant('scheduling.booked-performance-settings.checkbox')}}"
                                name="bookedPerformanceEnableEmail"
                                [disabled]="('BOOKED_PERFORMANCE_WRITE' | doesNotHavePermission)"
                                [(ngModel)]="bookedPerformanceDefaults.bookedPerformanceEnableEmail"
                                binary="true"></p-checkbox>
                </div>
            </div>
        </div>
        <div>
            <button class="btn btn-primary mt-2" (click)="closeDefaultSettings()"
                    jhiTranslate="scheduling.booked-performance-settings.cancel">Cancel
            </button>
            <button class="btn btn-primary mt-2 pull-right"
                    [disabled]="bookedPerformanceDefaultForm.form.invalid || ('BOOKED_PERFORMANCE_WRITE' | doesNotHavePermission)"
                    jhiTranslate="scheduling.booked-performance-settings.save"
                    *permissionOverlay="'BOOKED_PERFORMANCE_WRITE'">Save
            </button>
        </div>
    </div>
</form>

<mat-card *ngIf="!viewDetail && !editDefaults">
    <mat-card-title>
        <header class="cez-table-header">
            <h4 class="card-title" jhiTranslate="scheduling.pods-table.title">PODs</h4>
        </header>
    </mat-card-title>
    <mat-card-content>
        <p-table [value]="podData"
                 sortMode="single"
                 sortField="podCode"
                 [autoLayout]="true"
                 [customSort]="true"
                 (sortFunction)="customSort($event)"
                 class="table table-striped">

            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'podCode'">{{ 'scheduling.pods-table.podCode' | translate }}
                        <p-sortIcon [field]="'podCode'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'type'">{{ 'scheduling.pods-table.type' | translate }}
                        <p-sortIcon [field]="'type'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'zipCode'">{{ 'scheduling.pods-table.zipCode' | translate }}
                        <p-sortIcon [field]="'zipCode'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'address'">{{ 'scheduling.pods-table.address' | translate }}
                        <p-sortIcon [field]="'address'"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                <tr class="table-padding">
                    <td class="text-left">{{ rowData.podCode }}</td>
                    <td>{{ podService.translateProfileType(rowData.podType) }}</td>
                    <td>{{ rowData.zipCode }}</td>
                    <td>{{ podService.getPodAddress(rowData) }}</td>
                    <td class="text-right">
                        <button class="btn btn-success btn-sm" (click)="view(rowData)"
                                [disabled]="('BOOKED_PERFORMANCE_READ' | doesNotHavePermission)"
                                *permissionOverlay="'BOOKED_PERFORMANCE_READ'">
                            <span class="fa fa-eye"></span>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </mat-card-content>
    <mat-card-footer>

    </mat-card-footer>
</mat-card>

<mat-card *ngIf="viewDetail">
    <mat-card-title>
        <header class="cez-table-header">
            <h4 class="card-title" jhiTranslate="scheduling.booked-performances-table.title">Booked
                Performances</h4>
            <button class="btn btn-primary btn-wide-150" (click)="editBookedPerformance(null)"
                    jhiTranslate="scheduling.booked-performances-table.new"
                    [disabled]="('BOOKED_PERFORMANCE_WRITE' | doesNotHavePermission)"
                    *permissionOverlay="'BOOKED_PERFORMANCE_WRITE'">
                New
            </button>
        </header>
    </mat-card-title>
    <mat-card-content>
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                <tr>
                    <th>
                        <span jhiTranslate="scheduling.booked-performances-table.startTime">Start time</span>
                    </th>
                    <th>
                        <span jhiTranslate="scheduling.booked-performances-table.endTime">End time</span>
                    </th>
                    <th>
                        <span
                            jhiTranslate="scheduling.booked-performances-table.bookedQuantity">Booked Capacity</span>
                    </th>
                    <th>
                        <span jhiTranslate="scheduling.booked-performances-table.limitPercentage">Capacity limit warning at (%)</span>
                    </th>
                    <th>
                        <span jhiTranslate="scheduling.booked-performances-table.limitQuantity">Capacity limit warning at (kW)</span>
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let bookedPerformance of selectedPodBookedPerformances">
                    <td>{{ bookedPerformance.startTime | cezDate }}</td>
                    <td>{{ bookedPerformance.endTime | cezDate }}</td>
                    <td>{{ bookedPerformance.bookedQuantity }}</td>
                    <td>{{ bookedPerformance.limitPercentage }}</td>
                    <td>{{ bookedPerformance.limitQuantity }}</td>
                    <td>
                        <button class="btn btn-info btn-sm" (click)="editBookedPerformance(bookedPerformance)"
                                [disabled]="('BOOKED_PERFORMANCE_WRITE' | doesNotHavePermission)"
                                *permissionOverlay="'BOOKED_PERFORMANCE_WRITE'">
                            <span class="fa fa-pencil"></span>
                        </button>
                        <button class="btn btn-danger btn-sm"
                                (click)="deleteBookedPerformance(bookedPerformance.id)"
                                [disabled]="('BOOKED_PERFORMANCE_WRITE' | doesNotHavePermission)"
                                *permissionOverlay="'BOOKED_PERFORMANCE_WRITE'">
                            <span class="fa fa-times"></span>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
    <mat-card-footer class="text-right">
        <button class="btn btn-primary m-3" (click)="viewDetail=false" *ngIf="viewDetail">
            <span jhiTranslate="scheduling.booked-performances-table.ok">OK</span>
        </button>
    </mat-card-footer>
</mat-card>

<p-confirmDialog header="{{'scheduling.title' | translate}}" icon="fa fa-question-circle" width="425"></p-confirmDialog>
