<div>
    <h2 jhiTranslate="sessions.title" *ngIf="account">Active sessions for [<b>{{account.login}}</b>]</h2>

    <div class="alert alert-success" *ngIf="success" jhiTranslate="sessions.messages.success">
        <strong>Session invalidated!</strong>
    </div>
    <div class="alert alert-danger" *ngIf="error" jhiTranslate="sessions.messages.error">
        <strong>An error has occured!</strong> The session could not be invalidated.
    </div>

    <div class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th jhiTranslate="sessions.table.ipaddress">IP Address</th>
                    <th jhiTranslate="sessions.table.useragent">User agent</th>
                    <th jhiTranslate="sessions.table.date">Date</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let session of sessions">
                    <td>{{session.ipAddress}}</td>
                    <td>{{session.userAgent}}</td>
                    <td>{{session.tokenDate | date:'longDate'}}</td>
                    <td>
                        <button type="submit"
                                class="btn btn-primary"
                                (click)="invalidate(session.series)" jhiTranslate="sessions.table.button">
                                 Invalidate
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
