import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Category } from '../../shared';
import { ProductAndServicesModel } from '../../shared/dto/product-and-services.model';
import { ProductService } from '../../shared/services/product.service';
import {ExcelImportResult} from "../../shared/time-series/model/excel-import-result.model";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
    selector: 'ngbd-modal-content',
    templateUrl: "product.modal.component.html"
})
export class ProductModalComponent implements OnInit {
    @Input() product: ProductAndServicesModel;
    @Input() categories: Category[];
    @Output() modalClose: EventEmitter<any> = new EventEmitter();

    selectedCategory: Category;
    selectedFiles: File;
    isSaving: boolean = false;

    constructor(public activeModal: NgbActiveModal,
        private productService: ProductService) {
    }

    ngOnInit() {
        if (this.product.categories)
            this.selectedCategory = this.categories.filter(r => r.id === this.product.categories[0].id)[0];
    }



  public addFile(fileInput: HTMLInputElement) {
    fileInput.value = '';
    fileInput.click();
  }

  public onUpload(event: { file: File, success: boolean, response?: ExcelImportResult, error?: HttpErrorResponse }) {
      this.selectedFiles = event.file;
  }

    onSubmit() {
        this.isSaving = true;
        let category = new Category();
        category.id = this.selectedCategory.id;
        this.product.categories = [category];
        this.productService.createOrUpdateProduct(this.product).subscribe(r => {
            if (this.selectedFiles) {
                this.productService.uploadProductFile(r.id, this.selectedFiles).subscribe(() => this.modalClose.emit());
                this.selectedFiles = null;
            } else this.modalClose.emit();
        });
    }
}
