<table class="table table-striped">
  <thead>
    <tr>
      <th>
        <span>#</span>
      </th>
      <th>
        <span jhiTranslate="agreement.agreementData.deliveryPeriods.startTime"></span>
      </th>
      <th>
        <span jhiTranslate="agreement.agreementData.deliveryPeriods.endTime"></span>
      </th>
      <th>
        <span jhiTranslate="agreement.agreementData.deliveryPeriods.contractedQuantityMWh"></span>
      </th>
      <th *ngIf="adminView"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let deliveryPeriod of model; let i = index">
      <td>{{i+1}}</td>
      <td>{{deliveryPeriod.startTime | cezDate}}</td>
      <td>{{deliveryPeriod.endTime | cezDate}}</td>
      <td>{{deliveryPeriod.contractedQuantityMWh}}</td>
      <td *ngIf="adminView">
        <button type="button" class="btn btn-info btn-sm" (click)="edit(deliveryPeriod)">
          <span class="fa fa-pencil"></span>
        </button>
        <button type="button" class="btn btn-danger btn-sm" (click)="delete(deliveryPeriod)">
          <span class="fa fa-remove"></span>
        </button>
      </td>
    </tr>
  </tbody>
</table>
