import {Component, OnInit} from '@angular/core';
import {CompanyModel} from '../shared/dto/company.model';
import {StateStorageService} from '../shared/auth';


@Component({
    selector: 'jhi-corporate-data',
    templateUrl: './corporate-data.component.html',
    styleUrls: ['./corporate-data.component.scss']
})
export class CorporateDataComponent implements OnInit {

    partner: CompanyModel;

    constructor(private stateStorageService: StateStorageService) {
    }

    ngOnInit() {
        this.partner = this.stateStorageService.getSelectedCompany();
    }

}
