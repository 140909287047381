import {HttpClient} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {MultiSelectTranslationService} from '../../shared/services/multi-select-translation.service';
import {Role} from './role.model';
import {DialogService} from "../../shared/dialog/dialog.service";
import {TranslateService} from "@ngx-translate/core";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {DEFAULT_DROPDOWN_SETTINGS} from "../../core/util/constants";
import {GroupType} from './group-type.enum';

@Component({
  selector: 'role-editor',
  templateUrl: './role.editor.component.html'
})
export class RoleEditorComponent implements OnInit {

  showNewGroupContainer: boolean;
  dirty: boolean;
  newGroupName: string;
  roles: Role[];
  selectedGroupType: GroupType = GroupType.PERMISSION;


  dropdownSettings: IDropdownSettings = {
    ...DEFAULT_DROPDOWN_SETTINGS,
    ...this.multiSelectTranslationService.getMultiSelectTexts()
  };

  permissions: string[] = [];


  constructor(private http: HttpClient,
              private multiSelectTranslationService: MultiSelectTranslationService,
              private dialogService: DialogService,
              private translate: TranslateService) {

  }

  ngOnInit() {
    this.http.get<string[]>('api/group/permissions').subscribe(r => {
      r.map(item => {
        this.permissions.push(item);
      });
    });
    this.refreshRoleList();
  }

  refreshRoleList() {
    this.http.get<Role[]>('api/groups/' + this.selectedGroupType).subscribe(r => {
      this.roles = r;
    });
  }

  saveNewGroup(form: NgForm) {
    this.http.post('api/groups/' + this.selectedGroupType, {name: form.value.newGroupName}).subscribe(r => {
      this.newGroupName = '';
      this.toggleNewContainer();
      this.refreshRoleList();
    });
  }

  savePermissions() {
    for (let role of this.roles)
      this.http.put('api/groups/' + this.selectedGroupType, role).subscribe(r => this.dirty = false);
  }

  toggleNewContainer() {
    this.showNewGroupContainer = !this.showNewGroupContainer;
  }

  selectionChange(event: any) {
    this.dirty = true;
  }

  editRole(role: Role) {
    role.editMode = true;
    role.originalName = role.name;
  }

  saveRole(role: Role) {
    this.http.put('api/groups/' + this.selectedGroupType, role).subscribe(r => role.editMode = false);
  }

  undo(role: Role) {
    role.name = role.originalName;
    role.editMode = false;
  }

  deleteRole(role: Role) {
    this.dialogService.confirm(this.translate.instant('filter.basic.deleteDialog.title'), this.translate.instant('filter.basic.deleteDialog.message'))
      .subscribe((value: boolean): void => {
        if (value) {
          this.deleteConfirmed(role);
        }
      });
  }

  deleteConfirmed(role: Role): void {
    this.http.delete(`api/groups/${this.selectedGroupType}/${role.id}`).subscribe(r => {
      this.refreshRoleList();
    });
  }

  cancelGroupCreation() {
    this.showNewGroupContainer = false;
  }

  groupTypeChange() {
    this.refreshRoleList();
  }

    protected readonly GroupTypes = Object.values(GroupType);
}
