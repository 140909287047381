<div *ngFor="let contact of contacts" class="card mb-3 py-3 w-75 pull-center">
    <div class="card-block">
        <div class="row">
            <div class="col-sm-4  text-center">
                <i *ngIf="!contact.picture" class="fa fa-user fa-5x mt-3" aria-hidden="true"></i>
                <img *ngIf="contact.picture" class="pull-center mt-2" src="{{contact.picture.urlPath}}" height="100">
            </div>
            <div class="col-sm-8">
                <div class="row">
                    <div class="col-sm-12">
                        <span jhiTranslate="agreement.agreementData.contacts.name"></span>:
                        <strong> {{contact.name}}</strong>
                    </div>
                    <div class="col-sm-12">
                        <span jhiTranslate="agreement.agreementData.contacts.responsibility"></span>:
                        <strong>{{contact.responsibility}}</strong>
                    </div>
                    <div class="col-sm-12">
                        <span jhiTranslate="agreement.agreementData.contacts.phone"></span>:
                        <strong>{{contact.phone}}</strong>
                    </div>
                    <div class="col-sm-12">
                        <span jhiTranslate="agreement.agreementData.contacts.mobile"></span>:
                        <strong>{{contact.mobile}}</strong>
                    </div>
                    <div class="col-sm-12">
                        <span jhiTranslate="agreement.agreementData.contacts.e-mail"></span>:
                        <strong>{{contact.email}}</strong>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
