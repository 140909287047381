import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TWO_FACTOR_CODE_ACTIVATE_ROUTE } from './twofactor-code-activate/twofactor-code-activate.route';
import { TwofactorCodeActivateComponent } from './twofactor-code-activate/twofactor-code-activate.component';
import { RouterModule } from '@angular/router';
import { TwoFactorAuthService } from './two-factor-auth.service';
import {CezPortalSharedModule} from '../shared';

@NgModule({
  imports: [
    CommonModule,
    CezPortalSharedModule,
    RouterModule.forRoot(TWO_FACTOR_CODE_ACTIVATE_ROUTE, { useHash: true })
  ],
  declarations: [TwofactorCodeActivateComponent],
  providers: [TwoFactorAuthService]
})
export class TwofactorAuthModule { }
