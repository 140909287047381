export type QueryInterval = 'ONE_WEEK' | 'ONE_MONTH' | 'TWO_MONTH' | 'ONE_QUARTER' | 'TWO_QUARTER' |
    'THREE_QUARTER' | 'ONE_YEAR' | 'TWO_YEAR' | 'YTD' | 'MAX';

export const QueryInterval = {
    ONE_WEEK: 'ONE_WEEK' as QueryInterval,
    ONE_MONTH: 'ONE_MONTH' as QueryInterval,
    TWO_MONTH: 'TWO_MONTH' as QueryInterval,
    ONE_QUARTER: 'ONE_QUARTER' as QueryInterval,
    TWO_QUARTER: 'TWO_QUARTER' as QueryInterval,
    THREE_QUARTER: 'THREE_QUARTER' as QueryInterval,
    ONE_YEAR: 'ONE_YEAR' as QueryInterval,
    TWO_YEAR: 'TWO_YEAR' as QueryInterval,
    YTD: 'YTD' as QueryInterval,
    MAX: 'MAX' as QueryInterval,
};
