import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {filter, flatMap, tap} from 'rxjs/operators';
import {DialogService} from '../../dialog/dialog.service';
import {LoadingOverlayService} from '../../services/loading-overlay.service';
import {TimeSeriesService} from '../time-series.service';
import {HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MatExpansionPanel} from "@angular/material/expansion";

@Component({
    selector: 'jhi-time-series-activate',
    templateUrl: './time-series-activate.component.html',
    styleUrls: ['./time-series-activate.component.scss']
})
export class TimeSeriesActivateComponent implements OnInit {

    @Input() agreementId: number;
    @Input() hedgeImportParams: HttpParams;
    @Input() activateButtonTooltip: string;
    @Input() deleteFile: Observable<boolean>;
    @Input() activatePermissionCheck: boolean = false;
    @Input() activatePermission: string = '';

    @Output() activation: EventEmitter<any> = new EventEmitter();
    @Output() deletion: EventEmitter<any> = new EventEmitter();

    @ViewChild('activatePanel', {static: true}) activatePanel: MatExpansionPanel;

    files: File[] = [];
    warning: string;

    get active() {
        return this.files.length > 0;
    }

    constructor(private timeSeriesService: TimeSeriesService,
                private loadingOverlay: LoadingOverlayService,
                private dialogService: DialogService,
                private translate: TranslateService) {
    }

    ngOnInit() {
        this.deleteFile.subscribe(() => {
            this.deleteSeries();
        });
    }

    addNewFile(f) {
        this.files = [...this.files, f];
    }

    activate() {
        this.files.forEach(f =>
            this.dialogService.confirm(this.translate.instant('timeSeriesImport.confirmationDialog.title'), this.translate.instant('timeSeriesImport.confirmationDialog.message'))
                .pipe(
                    filter(confirmed => confirmed),
                    tap(() => this.loadingOverlay.turnOn()),
                    flatMap(() => this.timeSeriesService.activate(f, this.agreementId, this.hedgeImportParams)),
                    tap(() => {
                        this.loadingOverlay.turnOff();
                        this.dialogService.saveSuccess();
                    })
                )
                .subscribe(r => {
                        this.warning = this.timeSeriesService.warningHandle(r.warnings);
                        this.activation.emit();
                        this.files = [];
                        this.warning = '';
                    },
                    e => console.error(e))
        );
    }

    cancelClicked() {
        this.deleteSeries();
        this.activatePanel.close();
        this.deletion.emit();
    }

    deleteSeries() {
        this.files = [];
    }
}
