import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FilterModel} from "../filter-basic/filter.model";

@Component({
    selector: 'jhi-consumption-diagram-options',
    templateUrl: './consumption-diagram-options.component.html',
    styles: []
})
export class ConsumptionDiagramOptionsComponent implements OnInit {

    @Output() minSelectedChange: EventEmitter<boolean> = new EventEmitter();
    private _minSelected: boolean;

    @Output() maxSelectedChange: EventEmitter<boolean> = new EventEmitter();
    private _maxSelected: boolean;

    @Output() avgSelectedChange: EventEmitter<boolean> = new EventEmitter();
    private _avgSelected: boolean;

    @Output() tempSelectedChange: EventEmitter<boolean> = new EventEmitter();
    private _tempSelected: boolean;

    @Output() perfSelectedChange: EventEmitter<boolean> = new EventEmitter();
    private _perfSelected: boolean;

    @Output() woffDaySelectedChange: EventEmitter<boolean> = new EventEmitter();
    private _woffDaySelected: boolean;

    @Input()
    filterModel: FilterModel;


    constructor() {
    }

    ngOnInit() {
    }

    get minSelected(): boolean {
        return this._minSelected;
    }

    @Input() set minSelected(value: boolean) {
        this._minSelected = value;
        this.minSelectedChange.emit(this._minSelected);
    }

    get maxSelected(): boolean {
        return this._maxSelected;
    }

    @Input() set maxSelected(value: boolean) {
        this._maxSelected = value;
        this.maxSelectedChange.emit(this._maxSelected);
    }

    get avgSelected(): boolean {
        return this._avgSelected;
    }

    @Input() set avgSelected(value: boolean) {
        this._avgSelected = value;
        this.avgSelectedChange.emit(this._avgSelected);
    }

    get tempSelected(): boolean {
        return this._tempSelected;
    }

    @Input() set tempSelected(value: boolean) {
        this._tempSelected = value;
        this.filterModel.temperatureChecked = this._tempSelected;
    }

    get perfSelected(): boolean {
        return this._perfSelected;
    }

    @Input() set perfSelected(value: boolean) {
        this._perfSelected = value;
        this.perfSelectedChange.emit(this._perfSelected);
    }

    get woffDaySelected(): boolean {
        return this._woffDaySelected;
    }

    @Input() set woffDaySelected(value: boolean) {
        this._woffDaySelected = value;
        this.woffDaySelectedChange.emit(this._woffDaySelected);
    }

    public minIsVisible(): boolean {
        return true
    }

    public maxIsVisible(): boolean {
        return true;
    }

    public avgIsVisible(): boolean {
        return true;
    }

    public tempIsVisible(): boolean {
        return this.filterModel.selectedDiagram.id ===1 ||
            this.filterModel.selectedDiagram.id ===2 ||
            this.filterModel.selectedDiagram.id ===4 ||
            this.filterModel.selectedDiagram.id ===5;
    }

    public performanceIsVisible(): boolean {
        return true;
    }

    public  wDayOffIsVisible(): boolean {
        return true;
    }
}
