import {Component, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LocalizedTexts} from "../../../shared";
import {Template} from "./template.model";
import {TemplateModalComponent} from "./template.modal.component";
import {TranslateService} from "@ngx-translate/core";
import {SortUtil} from "../../../core/util/sort.util";

@Component({
    selector: 'jhi-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {

    emailTemplates: Template[];

    constructor(
        private http: HttpClient,
        private modalService: NgbModal,
        private translate: TranslateService) {
    }

    ngOnInit() {
        this.refreshList();
    }

    refreshList() {
        this.http.get<Template[]>('/api/email_template_keys').subscribe(r => {
            this.emailTemplates = r;
            this.emailTemplates.map(t => t.message = new LocalizedTexts());
            this.emailTemplates.map(t => t.templateName = this.translate.instant('email.tabs.template.table.content.' + t.key + '.name'));

            this.emailTemplates = this.emailTemplates.sort(this.sortByName);
        });
    }

    private sortByName = (a, b) => SortUtil.compareHungarian(a.templateName.toLowerCase(), b.templateName.toLowerCase());

    openModal(template: Template) {
        template.editMode = true;
        this.http.get<Template>('/api/email_template/' + template.key).subscribe(r => {
            let editableTo = template.mailToEditable;
            template = r;
            template.key = template.templateName;
            template.mailToEditable = editableTo;

            const modalRef = this.modalService.open(TemplateModalComponent, { backdrop: "static" });
            modalRef.componentInstance.template = template;
            modalRef.componentInstance.modalClose.subscribe(() => {
                this.emailTemplates.map(t => t.editMode = false);
                modalRef.close();
            });
        });
    }

}
