import { InvoiceReportRowData } from './table-row-data.model';
import {LocalizedTexts} from '../../shared';
import {DateInterval} from '../../shared/dto/date-interval.model';

export class InvoiceReportResponse {
    groups: InvoiceReportResponseGroup[];
    invoices: InvoiceReportResponseInvoice[];
}

export class InvoiceReportResponseGroup {
    group: string;
    resources: InvoiceReportResponseResource[];
    value: InvoiceReportRowData;
}

export class InvoiceReportResponseInvoice {
    invoiceFileResourceId: number;
    invoiceNumber: string;

}

export class InvoiceReportResponseResource {
    intervals: InvoiceReportResponseInterval[];
    resourceId: number;
    resourceName: LocalizedTexts;
    value: InvoiceReportRowData;
}

export class InvoiceReportResponseInterval {
    containPartialInvoice: boolean;
    interval: DateInterval;
    value: InvoiceReportRowData;
}
