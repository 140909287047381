import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[invalidWhen]',
    providers: [{provide: NG_VALIDATORS, useExisting: InvalidWhenValidatorDirective, multi: true}]
})
export class InvalidWhenValidatorDirective implements Validator {

    @Input('invalidWhen') predicate: (control: AbstractControl) => boolean;

    validate(control: AbstractControl): {[key: string]: any} | null {
        if (this.predicate(control)) {
            return {'invalidWhen': true};
        }

        return null;
    }
}
