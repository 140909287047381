<h1 mat-dialog-title class="modal-primary">{{'customFileUpload.editDialog.title' | translate}}</h1>

<div mat-dialog-content [formGroup]="form" class="d-flex flex-column">
    <mat-form-field>
        <mat-select formControlName="type" required placeholder="{{'customFileUpload.type' | translate}}">
            <mat-option *ngFor="let type of types | keys" [value]="type.value">
                {{('customFileUpload.types.' + type.value) | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-select formControlName="language" placeholder="{{'customFileUpload.language' | translate}}">
            <mat-option></mat-option>
            <mat-option *ngFor="let lang of languages | keys" [value]="lang.value">
                {{('customFileUpload.languages.' + lang.value) | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <input matInput placeholder="{{'customFileUpload.note' | translate}}" formControlName="note">
    </mat-form-field>
</div>

<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close class="cez-color--dark">
        {{'entity.action.cancel' | translate }}
    </button>
    <button mat-raised-button
            (click)="save()"
            color="primary"
            [disabled]="!form.valid">
        {{'entity.action.save' | translate }}
    </button>
</div>
