import * as moment from 'moment';
import {Moment} from 'moment';
import {StateStorageService} from "../../shared/auth";
import {LocatorService} from "../../shared/services/locator.service";

export class DateUtil {

  public static readonly MIN_DATE: Date = new Date('0001-01-01');
  public static readonly MAX_DATE: Date = new Date('9999-12-31');

  public static readonly ISO_8601_PATTERN = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$/;

  /**
   * startDate is inclusive, endDate is exclusive
   * @param startDate Date
   * @param endDate Date
   * @returns Number of total days (without decimals) between two dates
   */
  public static calculateDaysBetween(startDate: Date | Moment, endDate: Date | Moment): number {
    return moment(endDate).diff(moment(startDate), 'days');
  }

  public static tomorrow(): Date {
    return moment().startOf('day').add(1, 'days').toDate();
  }

  public static add(date: Date, amount: moment.DurationInputArg1, unitOfTime: moment.DurationInputArg2): Date {
    return moment(date).add(amount, unitOfTime).toDate();
  }

  public static subtract(date: Date, amount: moment.DurationInputArg1, unitOfTime: moment.DurationInputArg2): Date {
    return moment(date).subtract(amount, unitOfTime).toDate();
  }

  public static parse(date: string, format: moment.MomentFormatSpecification = 'YYYY-MM-DD'): Date {
    return moment(date, format).toDate();
  }

  public static isNowBeforeDate(date: Date): boolean {
    return moment().isBefore(moment(date));
  }

  public static transformDateToLocalFormat(date: Date) {
    return moment(date).format(this.getDateFormat());
  }

  static getDateFormat(): string {
    let langKey = this.getLangKey();

    if (langKey === 'hu')
      return 'YYYY.MM.DD.';

    return 'DD/MM/YYYY';
  }

  static getLangKey() {
    let stateStorageService = <StateStorageService>LocatorService.injector.get(StateStorageService);
    let langKey = stateStorageService.getLanguage();

    return langKey;
  }
}
