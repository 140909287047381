import {Injectable} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import {Moment} from 'moment';

@Injectable()
export class NgBDatePickerConvertService {

    public convertToDate(ngbDate: NgbDateStruct): Date {
        if (!ngbDate) {
            return null;
        }

        return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
    }

    public convertFromDate(dateString: Date | string): NgbDateStruct {
        if (!dateString) {
            return null;
        }
        const date: Date = new Date(dateString);

        return {year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate()};
    }

    public convertFromMoment(moment: Moment): NgbDateStruct {
        return this.convertFromDate(moment.toDate());
    }
}
