import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConsumptionComponent } from '../consumption/consumption.component';
import { Account, Principal } from '../shared';
import { News } from '../shared/dto/news';
import { NewsService } from '../shared/services/news.service';
import { PodService } from '../shared/services/pod.service';
import { UserSettingsService } from '../shared/services/user-settings.service';
import { ViewTemplateService } from '../shared/services/view-template.service';

@Component({
  selector: 'jhi-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  account: Account;
  modalRef: NgbModalRef;

  contentVisible: boolean = false;
  latestNews: News[] = [];
  layoutId: number;
  chart1Title: string;
  chart2Title: string;
  chart3Title: string;
  chart4Title: string;
  @ViewChild('chart1', {static: false}) chart1: ConsumptionComponent;
  @ViewChild('chart2', {static: false}) chart2: ConsumptionComponent;
  @ViewChild('chart3', {static: false}) chart3: ConsumptionComponent;
  @ViewChild('chart4', {static: false}) chart4: ConsumptionComponent;


  constructor(

    private principal: Principal,
    private router: Router,
    public newsService: NewsService,
    private userSettingsService: UserSettingsService,
    private viewTemplateService: ViewTemplateService,
    private podService: PodService
  ) {
  }

  ngOnInit() {
    this.registerAuthenticationSuccess();
    this.contentVisible = false;
    this.accountPermissionCheck();

    this.newsService.getLast3News().subscribe(news => {
      this.latestNews = news;
      this.userSettingsService.getUserLayoutId().subscribe(layout => this.layoutId = layout);
      this.userSettingsService.getUserSettings().subscribe(userSettings => {
        this.viewTemplateService.getChartViewTemplates().subscribe(templates => {
          let selectedGraphTemplate1 = templates.filter(template => template.id === userSettings.selectedGraph1)[0];
          if (selectedGraphTemplate1) {
            this.chart1Title = selectedGraphTemplate1.name.substr(0, 200);
            let jsonData = JSON.parse(selectedGraphTemplate1.jsonData);
            if (jsonData) {
              let filter = this.viewTemplateService.filterModelFromViewTemplate(jsonData, selectedGraphTemplate1.dynamic);
              this.chart1.getReportParametersForRun(filter);
            }
          }
          let selectedGraphTemplate2 = templates.filter(template => template.id === userSettings.selectedGraph2)[0];
          if (selectedGraphTemplate2) {
            this.chart2Title = selectedGraphTemplate2.name.substr(0, 200);
            let jsonData = JSON.parse(selectedGraphTemplate2.jsonData);
            if (jsonData) {
              let filter = this.viewTemplateService.filterModelFromViewTemplate(jsonData, selectedGraphTemplate2.dynamic);
              this.chart2.getReportParametersForRun(filter);
            }
          }
          let selectedGraphTemplate3 = templates.filter(template => template.id === userSettings.selectedGraph3)[0];
          if (selectedGraphTemplate3) {
            this.chart3Title = selectedGraphTemplate3.name.substr(0, 200);
            let jsonData = JSON.parse(selectedGraphTemplate3.jsonData);
            if (jsonData) {
              let filter = this.viewTemplateService.filterModelFromViewTemplate(jsonData, selectedGraphTemplate3.dynamic);
              this.chart3.getReportParametersForRun(filter);
            }
          }
          let selectedGraphTemplate4 = templates.filter(template => template.id === userSettings.selectedGraph4)[0];
          if (selectedGraphTemplate4) {
            this.chart4Title = selectedGraphTemplate4.name.substr(0, 200);
            let jsonData = JSON.parse(selectedGraphTemplate4.jsonData);
            if (jsonData) {
              let filter = this.viewTemplateService.filterModelFromViewTemplate(jsonData, selectedGraphTemplate4.dynamic);
              this.chart4.getReportParametersForRun(filter);
            }
          }
        });
      });
    });
  }


  ngOnDestroy() {
  }

  accountPermissionCheck() {
    this.principal.identity().then((account) => {
      this.account = account;
      if (!this.publicAccess() && !account) {
        this.router.navigate(['/login']);
      } else
        this.contentVisible = true;

    });
  }

  publicAccess(): boolean {
    return this.router.url == '/login' ||
      this.router.url == '/register' ||
      this.router.url == '/reset/request' ||
      this.router.url.indexOf('/reset/finish') == 0;
  }

  registerAuthenticationSuccess() {
    this.principal.identity().then((account) => {
      this.account = account;
    });
  }

  isAuthenticated() {
    return this.principal.isAuthenticated();
  }

  scrollTo(yPoint: number, duration: number) {
    setTimeout(() => {
      window.scrollTo(0, yPoint)
    }, duration);
    return;
  }
  smoothScroll(eID) {
    let startY = currentYPosition();
    let stopY = elmYPosition(eID) - 100;
    let distance = stopY > startY ? stopY - startY : startY - stopY;
    if (distance < 100) {
      window.scrollTo(0, stopY); return;
    }
    let speed = Math.round(distance / 100);
    if (speed >= 20) speed = 20;
    let step = Math.round(distance / 100);
    let leapY = stopY > startY ? startY + step : startY - step;
    let timer = 0;
    if (stopY > startY) {
      for (let i = startY; i < stopY; i += step) {
        this.scrollTo(leapY, timer * speed);
        leapY += step; if (leapY > stopY) leapY = stopY; timer++;
      } return;
    }
    for (let i = startY; i > stopY; i -= step) {
      this.scrollTo(leapY, timer * speed);
      leapY -= step; if (leapY < stopY) leapY = stopY; timer++;
    }
  }

  goToElement(element: Element) {
    element.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
  }

}

function currentYPosition() {
  // Firefox, Chrome, Opera, Safari
  if (self.pageYOffset) return self.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
}

function elmYPosition(eID) {
  let elm = document.getElementById(eID);
  let y = elm.offsetTop;
  let node = elm;
  while (node.offsetParent && node.offsetParent != document.body) {
    node = <HTMLElement>node.offsetParent;
    y += node.offsetTop;
  } return y;
}


