import { Route } from '@angular/router';

import { UserRouteAccessService } from '../../shared';
import {HomeComponent} from '../../home';


export const settingsRoute: Route = {
  path: 'settings',
  component: HomeComponent,
  data: {
    permission: 'MAIN_PAGE_WIDGET_WRITE',
    pageTitle: 'global.menu.account.settings'
  },
  canActivate: [UserRouteAccessService]
};
