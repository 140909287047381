<div class="modal-header">
    <h4 class="modal-title">{{ emailDetails.subject }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="this.activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <jhi-email-viewer [emailDetails]="emailDetails"></jhi-email-viewer>
</div>
<div *ngIf="!!emailDetails.attachments && emailDetails.attachments.length > 0">
    <mat-list [disableRipple]="true">
        <div *ngFor="let attachment of emailDetails.attachments">
          <div class="attachment-row">
            <mat-icon mat-list-icon aria-hidden="true">
                {{documentFilterService.getCssForFileExtension(attachment?.mimeType)}}
            </mat-icon>
            <div mat-line class="pl-1 align-items-center">
                <a href="{{attachment?.urlPath}}" target="_blank" restartSession>
                    {{attachment.fileName}}
                </a>
            </div>
            </div>
        </div>
    </mat-list>
</div>
