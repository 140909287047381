import { Route } from '@angular/router';
import { AdminTabsComponent } from './admin.tabs.component';
import { UserRouteAccessService } from '../../shared';
import {CanDeactivateGuard} from '../../shared/can-leave/can-deactivate.guard';

export const AdminTabsRoute: Route = {
  path: 'admin',
  component: AdminTabsComponent,
  data: {
    permission: 'USER_ADMIN_WRITE',
    pageTitle: 'global.menu.admin.main'
  },
  canActivate: [UserRouteAccessService],
  canDeactivate: [CanDeactivateGuard]
};
