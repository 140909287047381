import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

export interface DetailsComponentData {
    title: string;
    details: { key: string, value: string }[];
}

@Component({
    selector: 'rts-details',
    templateUrl: './details.component.html',
    styles: []
})
export class DetailsComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: DetailsComponentData) {
    }

    ngOnInit(): void {
    }

}
