import { Routes } from '@angular/router';
import { auditsRoute } from './audits/audits.route';
import { configurationRoute } from './configuration/configuration.route';
import { logsRoute } from './logs/logs.route';
import {userDialogRoute, userMgmtRoute} from './user-management/user-management.route';
import { UserRouteAccessService } from '../shared/auth/user-route-access-service';

let ADMIN_ROUTES = [
    auditsRoute,
    configurationRoute,
    logsRoute
];


export const adminState: Routes = [{
    path: '',
    data: {
        permission: '',
    },
    canActivate: [UserRouteAccessService],
    children: ADMIN_ROUTES
},
...userDialogRoute,
...userMgmtRoute
];
