<mat-card class="position-relative">
  <ngx-loading class="local-loader"
               [show]="isPeriodsLoading"
               [config]="{fullScreenBackdrop: false}"></ngx-loading>
  <mat-card-title>
    {{ 'coverageTransactions.tabs.overview.interval' | translate }}
  </mat-card-title>
  <mat-card-content>
    <table class="table">
      <thead>
      <tr class="row">
        <th class="text-left col-3 coverage-transaction-table-radio-header">
          <span jhiTranslate="agreement.agreementData.deliveryPeriods.cardTitle"></span>
        </th>
        <th class="text-left col-2">
          <span jhiTranslate="agreement.agreementData.services.productType"></span>
        </th>
        <th class="text-right col-2">
          <span jhiTranslate="agreement.agreementData.quantityExpected"></span>
        </th>
        <th class="text-right col-2">
          <span jhiTranslate="offerClaim.mtmReport.table.mtmUnitPrice"></span>
        </th>
        <th class="text-left col-3">
          <span jhiTranslate="agreement.agreementData.position"></span>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let ctOverview of coverageTransactionsOverviews;"
          class="row cursor--pointer m-0" (click)="onDeliveryPeriodChange(ctOverview.deliveryPeriodDto.id)">
        <td class="text-left col-3">
          <mat-radio-button [checked]="selectedDeliveryPeriod.id === ctOverview.deliveryPeriodDto.id"
                            color="primary">
          </mat-radio-button>
          <span class="d-inline-block w-75 h-100 pl-4">
                {{ ctOverview.deliveryPeriod }}
          </span>
        </td>

        <td class="text-left col-2">
          {{ ctOverview.product }}
        </td>

        <td class="text-right col-2">
          {{ ctOverview.quantity | number }}
        </td>
        <td class="text-right col-2">
          <mat-spinner
            *ngIf="ctOverview.mtmUnitPrice === null || ctOverview.mtmUnitPrice === undefined; else unitPrice"
            class="float-right" diameter="20">Loading...
          </mat-spinner>
          <ng-template #unitPrice>{{ ctOverview.mtmUnitPrice | number: '1.2-2' }}</ng-template>
        </td>
        <td class="col-3 px-5 progress-bar-td">
          <div class="w-100 h-100 d-flex justify-content-center align-items-center">
            <mat-spinner
              *ngIf="ctOverview.mtmUnitPrice === null || ctOverview.mtmUnitPrice === undefined; else progressBar"
              class="float-right" diameter="20">Loading...
            </mat-spinner>
            <ng-template #progressBar>
              <div class="progress w-100">
                <div class="progress-bar" role="progressbar"
                     [progressbar]="ctOverview.progressBarConfig"></div>
              </div>
            </ng-template>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </mat-card-content>
</mat-card>
<ng-container *ngFor="let deliveryPeriod of deliveryPeriods">
  <div *ngIf="deliveryPeriod.id === selectedDeliveryPeriod?.id" class="row">
    <div class="col-lg-6 col-sm-12 d-flex">
      <mat-card class="w-100 position-relative">
        <div class="card-header-row">
          <mat-card-title class="card-header-row__text-container">
            {{ 'offerClaim.chart.title' | translate }}
            <i class="fa fa-refresh align-middle info-icon hand"
               (click)="showDataUpdateTrackingInformation()"></i>
          </mat-card-title>
        </div>
        <mat-card-content class="min-card-height">
          <div class="container-fluid">
            <ul ngbNav #positionReportNav="ngbNav" tabSave="coverageTransactions.overview.chart" #positionOverviewTabSave="tabSave" class="nav-tabs">
              <li [ngbNavItem]="'consumption-tab-co'" *ngIf="deliveryPeriod.productType === 'CASH_OUT'">
                <a ngbNavLink>{{'offerClaim.chart.tab.co-consumption' | translate}}</a>
                <ng-template ngbNavContent>
                  <jhi-position-report *ngIf="positionOverviewTabSave.tabLoaded"
                                       class="w-100"
                                       [deliveryPeriod]="deliveryPeriod"
                                       [chartType]="'CONSUMPTION'"></jhi-position-report>
                </ng-template>
              </li>
              <li [ngbNavItem]="'capacity-tab-co'" *ngIf="deliveryPeriod.productType === 'CASH_OUT'">
                <a ngbNavLink>{{'offerClaim.chart.tab.co-capacity' | translate}}</a>
                <ng-template ngbNavContent>
                  <jhi-position-report *ngIf="positionOverviewTabSave.tabLoaded"
                                       class="w-100"
                                       [deliveryPeriod]="deliveryPeriod"
                                       [chartType]="'CAPACITY'"></jhi-position-report>
                </ng-template>
              </li>
              <li [ngbNavItem]="'tranches-tab-sbs'" *ngIf="deliveryPeriod.productType === 'STEP_BY_STEP'">
                <a ngbNavLink>{{'offerClaim.chart.tab.sbs-tranches' | translate}}</a>
                <ng-template ngbNavContent>
                  <jhi-position-report *ngIf="positionOverviewTabSave.tabLoaded"
                                       class="w-100"
                                       [deliveryPeriod]="deliveryPeriod"></jhi-position-report>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="positionReportNav"></div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-6 col-sm-12 d-flex">
      <mat-card class="w-100">
        <div class="card-header-row">
          <mat-card-title class="card-header-row__text-container">
            {{ 'offerClaim.mtmReport.title' | translate }}
            <mat-icon class="info-icon align-middle display-6 hand"
                      (click)="showMtmInfoPopup()">info
            </mat-icon>
            <i class="fa fa-refresh align-middle info-icon hand ml-1"
               (click)="showDataUpdateTrackingInformation()"></i>
          </mat-card-title>
          <aside *ngIf="deliveryPeriod.productType === ProductType.CASH_OUT"
                 class="card-header-row__button-container mt-2">
            <ng-container *ngIf="mtmTabSave.getActiveTab() === 'mtm-report-details'">
              <p-checkbox [(ngModel)]="isMtmReportSfChecked"
                          (ngModelChange)="onMtmReportSfChanged()"
                          [label]="'offerClaim.mtmReport.button.SF' | translate"
                          binary="true"
                          class="mr-2"
              />
              <p-checkbox [disabled]="!isMtmReportSfChecked"
                          [(ngModel)]="isMtmReportRbcChecked"
                          (ngModelChange)="onMtmReportRbcChanged()"
                          [label]="'offerClaim.mtmReport.button.RBC' | translate"
                          binary="true"
                          class="mr-2"
              />
            </ng-container>
          </aside>
          <aside class="card-header-row__button-container">
            <button mat-icon-button
                    matTooltip="{{'offerClaim.mtmReport.button.export' | translate}}"
                    type="button"
                    (click)="exportMtmReport(deliveryPeriod)"
                    class="mr-2">
              <mat-icon>cloud_download</mat-icon>
            </button>
          </aside>
        </div>
        <mat-card-content class="min-card-height">
          <div class="container-fluid">
              <ul ngbNav #mtmNav="ngbNav" tabSave="coverageTransactions.overview.mtmReport" #mtmTabSave="tabSave" class="nav-tabs">
                <li [ngbNavItem]="'mtm-report-summary'">
                  <a ngbNavLink>{{'offerClaim.mtmReport.table.summary' | translate}}</a>
                  <ng-template ngbNavContent>
                    <jhi-coverage-transactions-mtm *ngIf="mtmTabSave.tabLoaded"
                                                   class="w-100"
                                                   [selectedDeliveryPeriod]="deliveryPeriod"
                                                   [tableType]="'SUMMARY'"></jhi-coverage-transactions-mtm>
                  </ng-template>
                </li>
                <li [ngbNavItem]="'mtm-report-details'">
                  <a ngbNavLink>{{'offerClaim.mtmReport.table.details' | translate}}</a>
                  <ng-template ngbNavContent>
                    <jhi-coverage-transactions-mtm *ngIf="mtmTabSave.tabLoaded"
                                                   class="w-100"
                                                   [selectedDeliveryPeriod]="deliveryPeriod"
                                                   [calculationType]="mtmReportCashoutCalculationType"
                                                   [tableType]="'DETAILS'"></jhi-coverage-transactions-mtm>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="mtmNav"></div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-6 col-sm-12 d-flex">
      <mat-card class="w-100 position-relative">
        <h4>{{ 'coverageTransactions.tabs.availableProducts.title' | translate }}</h4>
        <mat-card-content class="min-card-height">
          <div class="container-fluid">
            <div class="d-flex row">
              <jhi-available-products-overview class="w-100"
                                               [availableProductsInput]="selectedDeliveryPeriod"></jhi-available-products-overview>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-lg-6 col-sm-12 d-flex">
      <mat-card class="w-100 position-relative">
        <ngx-loading class="local-loader"
                     [show]="isDailyRatePricesLoading"
                     [config]="{fullScreenBackdrop: false}"></ngx-loading>
        <div class="row">
          <div class="col-3">
            <div class="row ml-1">
              <h4 class="mb-0 pt-1">
                {{ 'dailyRates.chart.title' | translate }}
                <mat-icon class="info-icon align-middle display-6 hand"
                          (click)="showOtcPricesInfoPopup()">info
                </mat-icon>
              </h4>
            </div>
          </div>
          <div class="col-9 btn-toolbar d-flex justify-content-end">
            <div class="btn-group mb-2">
              <button color="primary"
                      type="button"
                      (click)="loadData(QueryInterval.ONE_WEEK, D_7)"
                      [class.cez-btn-group__button--active]="activeButton === D_7"
                      class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">7D
              </button>
              <button color="primary"
                      type="button"
                      (click)="loadData(QueryInterval.ONE_MONTH, M_1)"
                      [class.cez-btn-group__button--active]="activeButton === M_1"
                      class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">1M
              </button>
              <button color="primary"
                      type="button"
                      (click)="loadData(QueryInterval.TWO_MONTH, M_2)"
                      [class.cez-btn-group__button--active]="activeButton === M_2"
                      class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">2M
              </button>
              <button color="primary"
                      type="button"
                      (click)="loadData(QueryInterval.ONE_QUARTER, Q_1)"
                      [class.cez-btn-group__button--active]="activeButton === Q_1"
                      class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">1Q
              </button>
              <button color="primary"
                      type="button"
                      (click)="loadData(QueryInterval.TWO_QUARTER, Q_2)"
                      [class.cez-btn-group__button--active]="activeButton === Q_2"
                      class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">2Q
              </button>
              <button color="primary"
                      type="button"
                      (click)="loadData(QueryInterval.THREE_QUARTER, Q_3)"
                      [class.cez-btn-group__button--active]="activeButton === Q_3"
                      class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">3Q
              </button>
              <button color="primary"
                      type="button"
                      (click)="loadData(QueryInterval.ONE_YEAR, Y_1)"
                      [class.cez-btn-group__button--active]="activeButton === Y_1"
                      class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">1Y
              </button>
              <button color="primary"
                      type="button"
                      (click)="loadData(QueryInterval.TWO_YEAR, Y_2)"
                      [class.cez-btn-group__button--active]="activeButton === Y_2"
                      class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">2Y
              </button>
              <button color="primary"
                      type="button"
                      (click)="loadData(QueryInterval.YTD, YTD)"
                      [class.cez-btn-group__button--active]="activeButton === YTD"
                      class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">YTD
              </button>
              <button color="primary"
                      type="button"
                      (click)="loadData(QueryInterval.MAX, MAX)"
                      [class.cez-btn-group__button--active]="activeButton === MAX"
                      class="btn font-size-14 mt-1 mr-2 cez-btn-group__button">MAX
              </button>
            </div>
          </div>
        </div>
        <hr class="mt-2 mb-3"/>
        <mat-card-content class="min-card-height">
          <div class="container-fluid">
            <jhi-time-series-chart #chart
                                   forceUnitLabel="EUR/MWh"
                                   defaultUnit="EUR"
                                   [exportChartTitle]="getDailyRatesChartExportName()"
                                   sourceWidth="850"
                                   sourceHeight="400"
                                   fontSize="16px"
                                   [fullscreenAllowed]="true"
                                   [timeSeriesChartLineType]="TimeSeriesChartLineType.DOTTED"></jhi-time-series-chart>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="col-12">
      <mat-card class="position-relative">
        <mat-card-title class="card-header-row__text-container">
          {{ 'offerClaim.chart.series.openBuySellPosition' | translate }}
          <mat-icon (click)="showPositionOverviewInfoPopup()"
                    class="info-icon align-middle display-6 hand">info
          </mat-icon>
        </mat-card-title>
        <mat-card-content>
          <aggregation-table-data [tableType]="AggregationTableType.BEFORE_TRADE"
                                  [deliveryPeriodId]="deliveryPeriod.id"
                                  class="w-100"></aggregation-table-data>
        </mat-card-content>
      </mat-card>
    </div>

  </div>
</ng-container>
