import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {EmailDetails} from "../model/email-content.model";

@Component({
    selector: 'jhi-email-viewer',
    templateUrl: './email-viewer.component.html',
    encapsulation: ViewEncapsulation.ShadowDom
})
export class EmailViewerComponent {

    @Input() emailDetails: EmailDetails;
    @Output() modalClose: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

}
