import { Route } from '@angular/router';

import { DocumentAdminComponent } from './document.admin.component';
import {UserRouteAccessService} from '../shared';

export const DOCUMENT_ADMIN_ROUTE: Array<Route> = [{
  path: 'documents/admin',
  component: DocumentAdminComponent,
  data: {
    authorities: [],
    pageTitle: 'main.link.docs.company'
  }
  /*canActivate: [UserRouteAccessService]*/
}

];
