import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContractModel } from '../dto/contract.model';

@Component({
    selector: 'jhi-contract-list',
    templateUrl: './contract-list.component.html',
    styles: []
})
export class ContractListComponent implements OnInit {

    @Input() model: ContractModel[];
    @Input() adminView: boolean = false;
    @Output() editEmitter: EventEmitter<ContractModel> = new EventEmitter();
    @Output() deleteEmitter: EventEmitter<number> = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    edit(contract: ContractModel) {
        this.editEmitter.emit(contract);
    }

    delete(contract: ContractModel) {
        this.deleteEmitter.emit(contract.id);
    }

}
