import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../login/login.service';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
    selector: 'rts-confirm',
    templateUrl: './session-warning-popup.component.html',
    styles: []
})
export class SessionWarningPopupComponent {

    title = 'session-timer.warn-popup.title';
    message = 'session-timer.warn-popup.message';
    ok = 'session-timer.warn-popup.ok';

    constructor(private loginService: LoginService,
                private translate: TranslateService,
                private dialogRef: MatDialogRef<SessionWarningPopupComponent>) {

        this.loginService.getLogoutEvent().subscribe(() => this.dialogRef.close(false));

    }

}
