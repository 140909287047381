import { FileDto } from './FileDto';
import {Language} from '../agreement-details/agreement-enums.model';

export class ContactModel {
    address: string;
    comment: string;
    scheduler = false;
    email: string;
    fax: string;
    id: number;
    mobile: string;
    name: string;
    partner: {};
    phone: string;
    responsibility: string;
    image: FileList;
    picture: FileDto;
    fixingPerson: boolean;
    billingContact: boolean;
    consumptionRelated: boolean;
    contactOwner: string;
    contractRelevantContact: boolean;
    department: string;
    ekr: boolean;
    optedOutOfEmail: boolean;
    getAllInvoices: boolean;
    indicativePrices: boolean;
    paymentContact: boolean;
    meetTheAnalyst: boolean;
    reportContact: boolean;
    otcPrices: boolean;
    paymentReminderNotToBeSent: boolean;
    language: Language;
    representativeOfTheCompany: string;
    status: string;
    technicalMatters: boolean;
}
