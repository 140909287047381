<mat-card [hidden]="editMode">
  <mat-card-title>
    <h2>{{'main.link.contract' | translate}}</h2>
  </mat-card-title>
  <mat-card-content>
    <jhi-agreement-list (viewEvent)="view($event)"></jhi-agreement-list>
  </mat-card-content>
</mat-card>

<header *ngIf="editMode"
        class="docs">
  <aside>
    <button class="btn btn-primary"
            (click)="cancel()"
            jhiTranslate="entity.action.back">Megállapodások</button>
  </aside>
</header>

<form #userAgreementForm="ngForm" *ngIf="agreement.id > -1 && editMode">

  <jhi-agreement-details [(agreement)]="agreement" [userView]="true" (tabChange)="setSaveButtonVisible($event)" [(controlsState)]="controlsState"
    #agreementDetailsComponent="AgreementDetails"></jhi-agreement-details>
  <button *ngIf="saveButtonVisible" class="btn btn-primary btn-wide-250 pull-right mt-3" [disabled]="!hasDirtyData() || isAgreementInValid()"
    (click)="submit()" jhiTranslate="agreement.agreementData.sendFeedback">Mentés</button>
</form>
