<company-selector [adminMode]="true"
                  [(selectedCompany)]="selectedCompany"
                  (selectedCompanyChange)="selectedCompanyChange()"
                  [disabled]="editMode">
</company-selector>

<div class="row align-items-end justify-content-end">
  <button *ngIf="editMode"
          class="btn btn-primary mb-2"
          (click)="close()">{{'agreement.close' | translate}}</button>
</div>

<mat-card [hidden]="editMode">
  <mat-card-title>
    <div class="row pl-0">
      <h2 class="col-4">{{'agreement.title' | translate}}</h2>
      <div class="col-8">
        <div class="pull-right btn-group">
          <input type="text" class="form-control mb-2 pull-left" [(ngModel)]="salesforceQuoteId"/>
          <button class="btn btn-primary mb-2" (click)="syncNewAgreementFromSalesforce(salesforceQuoteId)">{{'agreement.sync.new' | translate}}</button>
        </div>
        <button class="btn btn-primary mb-2 mr-2 pull-right" *ngIf="!editMode" (click)="newAgreement()" [disabled]="!selectedCompany">{{'agreement.new' | translate}}</button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-content>
    <jhi-agreement-list [adminMode]="true"
                        [selectedCompany]="selectedCompany"
                        (editEvent)="edit($event)"
                        (syncAgreementFromSalesforce)="syncExistingAgreementFromSalesforce($event)"
                        (deleteEvent)="delete($event)">
    </jhi-agreement-list>
  </mat-card-content>
</mat-card>

<div *ngIf="editMode">
  <form class="form-horizontal" name="agreementForm" role="form" (ngSubmit)="submit()" #agreementForm="ngForm" >
    <jhi-agreement-details [(agreement)]="agreement"  #agreementDetailsComponent="AgreementDetails"></jhi-agreement-details>
    <button type="submit" class="btn btn-primary pull-right mt-3" [disabled]="isAgreementInValid()">{{'entity.action.save' | translate}}</button>
  </form>
</div>
