export type ResolutionNew =
    'MIN_15'
    | 'HOUR'
    | 'DAY'
    | 'WEEK'
    | 'MONTH'
    | 'QUARTER_OF_YEAR'
    | 'YEAR';

export const ResolutionNew = {
    MIN_15: 'MIN_15' as ResolutionNew,
    HOUR: 'HOUR' as ResolutionNew,
    DAY: 'DAY' as ResolutionNew,
    WEEK: 'WEEK' as ResolutionNew,
    MONTH: 'MONTH' as ResolutionNew,
    QUARTER_OF_YEAR: 'QUARTER_OF_YEAR' as ResolutionNew,
    YEAR: 'YEAR' as ResolutionNew
};
