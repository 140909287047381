export class MenuModel {
    url: string;
    text: string;
    localizedTextFromDb: boolean;
    ChildLinks: Array<MenuModel>;
    isVisible: boolean;
    childrenVisible: boolean;
    isActive: boolean;

    constructor(url: string, text: string, ChildLinks: Array<MenuModel>, isVisible: boolean, localizedTextFromDb: boolean = false) {
        this.url = url;
        this.text = text;
        this.ChildLinks = ChildLinks;
        this.isVisible = isVisible;
        this.childrenVisible = false;
        this.localizedTextFromDb = localizedTextFromDb;
    }
}