<mat-card>
    <mat-card-content>
        <form [formGroup]="dateRangeForm" (ngSubmit)="onSubmit()">
            <mat-form-field class="mr-2 mt-2">
                <input matInput [matDatepicker]="fromDatepicker"
                       placeholder="{{ 'email.tabs.history.intervalFrom' | translate }}" formControlName="fromDate"
                       readonly="true"
                       (dateChange)="onFromDateSelected($event)">
                <mat-datepicker-toggle matSuffix [for]="fromDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #fromDatepicker></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="mr-2 mt-2">
                <input matInput [matDatepicker]="toDatepicker"
                       placeholder="{{ 'email.tabs.history.intervalTo' | translate }}" formControlName="toDate"
                       readonly="true"
                       (dateChange)="onToDateSelected($event)">
                <mat-datepicker-toggle matSuffix [for]="toDatepicker"></mat-datepicker-toggle>
                <mat-datepicker #toDatepicker></mat-datepicker>
            </mat-form-field>
            <button mat-mini-fab color="primary" type="submit" [disabled]="dateRangeForm.invalid">
                <i class="material-icons">search</i>
            </button>
        </form>
        <p-table
            #emailHistoryTable
            [value]="tableData"
            [paginator]="true"
            [rows]="10"
            sortMode="multiple"
            (sortFunction)="sortOverview($event)"
            [customSort]="true"
            class="table table-striped">
            <ng-template pTemplate="header">
                <tr>
                    <th id="header-sort-partner" [pSortableColumn]="'partner'" class="text-left head-th column-width">
                        <span jhiTranslate="email.tabs.history.table.header.partner"></span>
                        <p-sortIcon [field]="'partner'"></p-sortIcon>
                    </th>
                    <th id="header-sort-subject" [pSortableColumn]="'subject'"
                        class="text-left head-th subject-column-width">
                        <span jhiTranslate="email.tabs.history.table.header.subject"></span>
                        <p-sortIcon [field]="'subject'"></p-sortIcon>
                    </th>
                    <th id="header-sort-mailTo" [pSortableColumn]="'mailTo'" class="text-left head-th column-width">
                        <span jhiTranslate="email.tabs.history.table.header.mailTo"></span>
                        <p-sortIcon [field]="'mailTo'"></p-sortIcon>
                    </th>
                    <th id="header-sort-mailCc" [pSortableColumn]="'mailCc'" class="text-left head-th column-width">
                        <span jhiTranslate="email.tabs.history.table.header.mailCc"></span>
                        <p-sortIcon [field]="'mailCc'"></p-sortIcon>
                    </th>
                    <th id="header-sort-mailBcc" [pSortableColumn]="'mailBcc'" class="text-left head-th column-width">
                        <span jhiTranslate="email.tabs.history.table.header.mailBcc"></span>
                        <p-sortIcon [field]="'mailBcc'"></p-sortIcon>
                    </th>
                    <th id="header-sort-sendingStatus" [pSortableColumn]="'sendingStatus'"
                        class="text-left head-th column-width">
                        <span jhiTranslate="email.tabs.history.table.header.sendingStatus"></span>
                        <p-sortIcon [field]="'sendingStatus'"></p-sortIcon>
                    </th>
                    <th id="header-sort-sentAt" [pSortableColumn]="'sentAt'" class="text-left head-th column-width">
                        <span jhiTranslate="email.tabs.history.table.header.sentAt"></span>
                        <p-sortIcon [field]="'sentAt'"></p-sortIcon>
                    </th>
                    <th id="header-sort-empty" class="button-column-width"></th>
                </tr>
                <tr>
                    <th id="header-filter-partner">
                        <mat-select
                            (selectionChange)="filter($event, 'partner', 'equals')"
                            placeholder="{{'global.choose' | translate}}"
                            class="pt-2 pb-2 head-select">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let partner of partners"
                                [value]="partner">
                                {{ partner }}
                            </mat-option>
                        </mat-select>
                    </th>
                    <th id="header-filter-subject">
                        <mat-select
                            (selectionChange)="filter($event, 'subject', 'equals')"
                            placeholder="{{'global.choose' | translate}}"
                            class="pt-2 pb-2 head-select">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let subject of subjects"
                                [value]="subject">
                                {{ subject }}
                            </mat-option>
                        </mat-select>
                    </th>
                    <th id="header-filter-mailTo">
                        <mat-select
                            (selectionChange)="filter($event, 'mailTo', 'equals')"
                            placeholder="{{'global.choose' | translate}}"
                            class="pt-2 pb-2 head-select">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let mailTo of mailTos"
                                [value]="mailTo">
                                {{ mailTo }}
                            </mat-option>
                        </mat-select>
                    </th>
                    <th id="header-filter-mailCc">
                        <mat-select
                            (selectionChange)="filter($event, 'mailCc', 'equals')"
                            placeholder="{{'global.choose' | translate}}"
                            class="pt-2 pb-2 head-select">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let mailCc of mailCcs"
                                [value]="mailCc">
                                {{ mailCc }}
                            </mat-option>
                        </mat-select>
                    </th>
                    <th id="header-filter-mailBcc">
                        <mat-select
                            (selectionChange)="filter($event, 'mailBcc', 'equals')"
                            placeholder="{{'global.choose' | translate}}"
                            class="pt-2 pb-2 head-select">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let mailBcc of mailBccs"
                                [value]="mailBcc">
                                {{ mailBcc }}
                            </mat-option>
                        </mat-select>
                    </th>
                    <th id="header-filter-sendingStatus">
                        <mat-select
                            (selectionChange)="filter($event, 'sendingStatus', 'equals')"
                            placeholder="{{'global.choose' | translate}}"
                            class="pt-2 pb-2 head-select">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let sendingStatus of sendingStatuses"
                                [value]="sendingStatus">
                                {{ translateSendingStatus(sendingStatus) }}
                            </mat-option>
                        </mat-select>
                    </th>
                    <th id="header-filter-sentAt">
                        <mat-select
                            (selectionChange)="filter($event, 'sentAt', 'equals')"
                            placeholder="{{'global.choose' | translate}}"
                            class="pt-2 pb-2 head-select">
                            <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
                            <mat-option
                                *ngFor="let sentAt of sentAts"
                                [value]="sentAt">
                                {{ sentAt | cezDateTime }}
                            </mat-option>
                        </mat-select>
                    </th>
                    <th id="header-filter-empty"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
                <tr [pSelectableRow]="rowData">
                    <td>{{ rowData.partner }}</td>
                    <td>{{ rowData.subject }}</td>
                    <td>{{ rowData.mailTo }}</td>
                    <td>{{ rowData.mailCc }}</td>
                    <td>{{ rowData.mailBcc }}</td>
                    <td>{{ translateSendingStatus(rowData.sendingStatus) }}</td>
                    <td>{{ rowData.sentAt | cezDateTime }}</td>
                    <td class="text-right">
                        <button type="submit" class="btn btn-success btn-sm"
                                (click)="openModal(rowData.contentId, rowData.attachmentIds, rowData.subject)">
                            <span class="fa fa-eye"></span>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </mat-card-content>
</mat-card>
