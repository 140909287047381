import {HttpHeaders, HttpResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';

import {Principal, User, UserService} from '../../shared';
import {TableLazyLoadEvent} from 'primeng/table';

@Component({
    selector: 'jhi-user-mgmt',
    templateUrl: './user-management.component.html'
})
export class UserMgmtComponent implements OnInit, OnDestroy {

    public currentAccount: any;
    public users: User[];
    public itemsPerPage: number = 10;
    private page: number = 1;
    private sort: string[] = [
        'lastName,asc',
        'firstName,asc',
        'id'
    ];
    public totalItems: number = 0;
    private userChangeSubscription: Subscription;


    constructor(private userService: UserService,
                private principal: Principal) {
    }

    ngOnInit(): void {
        this.principal.identity().then((account): void => {
            this.currentAccount = account;
            this.loadAll();
        });

        this.userChangeSubscription = this.userService.getUserChangeEvent().asObservable().subscribe(() => {
            this.loadAll();
        });
    }

    ngOnDestroy(): void {
        this.userChangeSubscription.unsubscribe();
    }

    public lazyLoadUsers(event: TableLazyLoadEvent): void {
        this.page = event.first / this.itemsPerPage + 1;
        this.loadAll();
    }

    public setActive(user: User, isActivated: boolean): void {
        user.activated = isActivated;
        this.userService.update(user).subscribe(() => this.loadAll());
    }

    private loadAll(): void {
        this.userService.query({
            page: this.page - 1,
            size: this.itemsPerPage,
            sort: this.sort
        }).subscribe((res: HttpResponse<User[]>) => this.onSuccess(res.body, res.headers));
    }

    private onSuccess(data: User[], headers: HttpHeaders) {
        this.totalItems = parseInt(headers.get('X-Total-Count'));
        this.users = data;
    }

}
