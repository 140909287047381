import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Chart} from 'angular-highcharts';
import {forkJoin, Subscription} from 'rxjs';
import {finalize, map} from 'rxjs/operators';
import {StateStorageService} from '../../shared/auth/state-storage.service';
import {PodBookedPerformanceModel} from '../../shared/dto/pod.booked.performance.model';
import {BookedPerformanceService} from '../../shared/services/booked-performance.service';
import {DiagramService} from '../../shared/services/diagram.service';
import {NgBDatePickerConvertService} from '../../shared/services/ngb.datepicker.convert.service';
import {PodValueService} from '../../shared/services/pod-value.service';
import {FilterModel} from '../filter-basic/filter.model';
import moment from "moment-timezone";
import {ConsumptionDiagramType} from "../consumption-new-diagram-type";
import {
  ConsumptionDynamicFilterService,
  FilterEvent
} from "../consumption-dynamic-filter/consumption-dynamic-filter.service";


@Component({
  selector: 'jhi-performance-diagram-area',
  templateUrl: './performance-diagram-area.component.html',
  styles: []
})
export class PerformanceDiagramAreaComponent implements OnInit, OnDestroy {

  @Input() widget: boolean;
  chartData: any[];
  chart1: Chart;
  filterModel: FilterModel;
  loading: boolean;
  sortedDataWithOriginalIndexes = [];
  showExport: boolean = false;
  bookingPerfData: any[];
  maxPerformance: number;
  readonly defaultTickAmount = 8;
  private dynamicFilterChangeSub: Subscription;
  private podValuesData: any;

  constructor(private dateConverter: NgBDatePickerConvertService,
              private podValueService: PodValueService,
              private diagramService: DiagramService,
              private bookedPerformanceService: BookedPerformanceService,
              private stateStorageService: StateStorageService,
              public translateService: TranslateService,
              public consumptionDynamicFilterService: ConsumptionDynamicFilterService) {
  }

  ngOnInit() {
    if (!this.widget) {
      this.chart1 = this.diagramService.createDummyChart();
    }
    this.listenDynamicFilterChange();
  }

  getReportParametersForRun(filterModel: FilterModel) {
    this.filterModel = filterModel;
    this.showExport = filterModel.selectedPods.length == 1 && !this.widget;
    this.loading = true;
    this.chartData = [];
    this.bookingPerfData = [];
    this.sortedDataWithOriginalIndexes = [];
    let apiParams = {
      startTime: this.dateConverter.convertToDate(filterModel.periodParameters[0].dateFrom).toISOString(),
      endTime: this.dateConverter.convertToDate(filterModel.periodParameters[0].dateTo).toISOString(),
      resolution: filterModel.resolution,
      podIds: filterModel.selectedPods.map(r => {
        return r.id;
      }),
      podGroupIds: filterModel.selectedPodGroups.map(r => r.id)
    };

    if (this.filterModel.periodParameters.filter(r => r.dateTo).length > 1) {
      this.diagramService.calculateMultiplePeriodChartData(filterModel)
        .pipe(finalize(() => this.loading = false))
        .subscribe(r => {
          this.sortedDataWithOriginalIndexes = r.dataWithDate;
          this.chartData = r.chartData;

          this.maxPerformance = r.chartData.reduce((max, curr) => max > curr.valueStats.max ? max : curr.valueStats.max, 0);
          this.calculateChart(filterModel.resolution, apiParams, filterModel);
        });
    } else {
      if (filterModel.comparePods) {
        let legendNames = filterModel.selectedPods.map(r => r.podCode).concat(filterModel.selectedPodGroups.map(r => r.name));

        this.diagramService.calculateComparedChartData(apiParams, legendNames)
          .pipe(finalize(() => this.loading = false))
          .subscribe(r => {
            this.chartData = r;
            this.maxPerformance = r.reduce((max, curr) => max > curr.valueStats.max ? max : curr.valueStats.max, 0);
            this.calculateChart(filterModel.resolution, apiParams, filterModel);
          });
      } else
        this.podValueService.getValues(apiParams)
          .pipe(finalize(() => this.loading = false))
          .subscribe(r => {
            this.podValuesData = r.valuesKW;
            this.calculateData(r.valuesKW, r.resolution, r.startTime, r.valueStats);

            if (this.filterModel.selectedPods.length == 1 && r.resolution == 'MIN_15') {
              let partnerId = this.stateStorageService.getSelectedCompanyId();
              this.bookedPerformanceService.getBookedPerformanceListForInterval(
                partnerId,
                apiParams.podIds[0],
                apiParams.startTime,
                apiParams.endTime
              ).subscribe(res => {
                const diagramChanged = this.diagramService.diagramChanged;
                if (res.length > 0) {
                  res.forEach(podBookedPerformance => {

                    this.bookingPerfData.push({
                      name: this.translateService.instant('scheduling.booked-performances-modal.title'),
                      color: 'red',
                      groupId: 'performance',
                      lineWidth: 1,
                      visible: false,
                      marker: {
                        enabled: false
                      },
                      data: this.generateBookedQuantityForDiagramData(podBookedPerformance, apiParams.startTime, apiParams.endTime),
                      events: {
                        legendItemClick: function (e) {
                          diagramChanged.next({
                            chart: this.chart,
                            type: 'legendItemClick',
                            value: {
                              groupId: 'performance',
                              name: 'performance',
                              item: this
                            }
                          });
                        }
                      }
                    });
                    this.maxPerformance = podBookedPerformance.bookedQuantity > this.maxPerformance ? podBookedPerformance.bookedQuantity : this.maxPerformance;

                  });
                }
                this.calculateChart(r.resolution, apiParams, filterModel);
              });
            } else
              this.calculateChart(r.resolution, apiParams, filterModel);
          });
    }
  }

  calculateData(valuesKw: number[], resolution: string, startTime: string, valueStats) {
    this.diagramService.createLegendText(this.filterModel)
      .subscribe(legendText => {
        this.chartData = [{
          name: legendText,
          color: '#f24f00',
          data: valuesKw,
          lineWidth: 2,
          pointStart: moment(startTime).valueOf(),
          pointInterval: this.diagramService.getPointInterval(resolution),
          pointIntervalUnit: this.diagramService.getPointIntervalUnit(resolution),
          valueStats: valueStats
        }];
      });
    this.maxPerformance = valueStats.max;
  }

  calculateChart(resolution: string, apiParams: any, filterModel: FilterModel) {
    let subscriptions = [];
    if (this.filterModel.temperatureChecked)
      subscriptions.push(this.diagramService.calculateTemperatureSeries(this.filterModel, this.chartData));

    if (this.filterModel.referenceWeekChecked && this.filterModel.resolution === 'MIN_15') {
      let referenceWeekParams = {
        startTime: this.dateConverter.convertToDate(this.filterModel.periodParameters[0].dateFrom).toISOString(),
        endTime: this.dateConverter.convertToDate(this.filterModel.periodParameters[0].dateTo).toISOString(),
        referenceIntervalStartTime: this.dateConverter.convertToDate(this.filterModel.referenceWeek[0].dateFrom).toISOString(),
        referenceIntervalEndTime: this.dateConverter.convertToDate(this.filterModel.referenceWeek[0].dateTo).toISOString(),
        resolution: 'MIN_15',
        podIds: apiParams.podIds,
        podGroupIds: apiParams.podGroupIds,
        excludeBusinessHolidays: this.filterModel.excludeBusinessHolidays
      };

      subscriptions.push(this.podValueService.getReferenceWeek(referenceWeekParams).pipe(map(r => {
        this.chartData.push({
          name: this.translateService.instant('filter.basic.referenceInterval'),
          color: '#98c601',
          marker: {symbol: 'circle'},
          lineWidth: 1,
          valueStats: r.valueStats,
          pointStart: moment(referenceWeekParams.startTime).valueOf(),
          pointInterval: this.diagramService.getPointInterval(referenceWeekParams.resolution),
          pointIntervalUnit: this.diagramService.getPointIntervalUnit(referenceWeekParams.resolution),
          data: r.valuesKW,
          isReference: true
        });
        const newMax = r.valuesKW.reduce((max, curr) => max > curr ? max : curr, 0);
        this.maxPerformance = newMax > this.maxPerformance ? newMax : this.maxPerformance;
        this.diagramService.addRealAveragePlotLine(this.chartData.length - 1, this.chartData);
      })));
    }

    if (resolution != 'MIN_15') {
      let consumptionScheduleApiParams = {
        startTime: this.dateConverter.convertToDate(filterModel.periodParameters[0].dateFrom).toISOString(),
        endTime: this.dateConverter.convertToDate(filterModel.periodParameters[0].dateTo).toISOString(),
        partnerId: this.stateStorageService.getSelectedCompanyId(),
        resolution: filterModel.resolution
      };
      subscriptions.push(this.podValueService.getConsumptionSchedule(consumptionScheduleApiParams).pipe(map(r => {
        this.diagramService.addSchedulePlotLine(this.chartData, r.timeSeries, r.startTime, this.podValuesData, resolution);
      })));
    }

    if (subscriptions.length > 0)
      forkJoin(subscriptions.filter(r => r)).subscribe(r =>
        this.displayChart(resolution, apiParams)
      );
    else
      this.displayChart(resolution, apiParams);
  }

  displayChart(resolution: string, apiParams: any) {
    let chartType = (resolution !== 'MIN_15') ? 'column' : 'line';
    let yAxisText = (resolution === 'MIN_15') ? 'kW' : 'kWh';
    let minmaxLines;
    let isMultiplePeriod: boolean = this.filterModel.periodParameters.filter(r => r.dateTo).length > 1;

    if (resolution == 'MIN_15') {
      if (this.bookingPerfData.length > 0) {
        this.chartData = this.chartData.concat(this.bookingPerfData);
      }
      minmaxLines = this.diagramService.addMinMaxPlotLine(this.chartData);
    } else {
      let containsPartialData = this.diagramService.markPartialValues(this.chartData);

      minmaxLines = this.createDummyPlotlines();
      if (containsPartialData)
        this.chartData.push({
          name: this.translateService.instant('consumption.partialValue'),
          type: 'line',
          color: 'grey',
          dashStyle: 'Dot',
          marker: {symbol: 'circle'},
          events: {
            legendItemClick: function (e) {
              return false;
            }
          }
        });
    }

    let plotLines = this.diagramService.createAvaragePlotLines(this.chartData);
    let diagramService = this.diagramService;
    let consumptionScheduleDiffPercentName: string = this.translateService.instant('consumption.scheduleDiffPercent');

    this.diagramService.createTitle(this.filterModel).subscribe(title => {
      this.chartData = this.chartData.sort(function (a, b) {
        if (a.color === '#98c601' || a.color === 'grey' || a.color === '#000000') return 1;
        if (b.color === '#98c601' || b.color === 'grey' || b.color === '#000000') return -1;
        if (a.color < b.color) return 1;
        if (a.color > b.color) return -1;
        if (a.name && b.name && a.name.length > b.name.length) return 1;
        if (a.name && b.name && a.name.length < b.name.length) return -1;
        return 0;
      });

      this.chart1 = new Chart({
        title: {
          text: this.widget ? '' : title,
          style: {
            fontSize: this.widget ? '12px' : '18px'
          }
        },
        chart: {
          type: chartType,
          spacingBottom: 15,
          spacingTop: 10,
          spacingLeft: 10,
          spacingRight: 10,
          height: this.widget ? null : '757px',
          style: {
            fontFamily: '\'Nimbus\''
          },
          events: this.diagramService.getWatermark(this.widget),
          resetZoomButton: {
            theme: {
              style: 'opacity: 0.3',
              states: {
                hover: {
                  style: 'opacity: 1'
                }
              }
            }
          }
        },
        xAxis: {
          type: 'datetime',
          lineWidth: 3,
          dateTimeLabelFormats: this.diagramService.getDateTimeLabelFormat(),
          labels: {
            align: 'right',
            rotation: -45,
            formatter: function () {
              return moment(new Date(this.value)).format('l');
            }
          },
          title: {
            text: this.translateService.instant('audits.table.header.date')
          },
          plotLines: minmaxLines,
          plotBands: this.diagramService.createPlotBands(this.filterModel)
        },
        yAxis: [{ // Primary yAxis
          max: Math.ceil(this.maxPerformance),
          endOnTick: false,
          tickAmount: this.defaultTickAmount,
          title: {
            text: yAxisText
          },
          plotLines: plotLines
        }, { // Secondary yAxis
          tickAmount: this.defaultTickAmount,
          gridLineWidth: 0,
          labels: {
            format: '{value}°C',
            style: {
              color: '#000000'
            }
          },
          title: {
            text: this.filterModel.temperatureChecked ? this.translateService.instant('filter.basic.temperature') : '',
            style: {
              color: '#000000'
            }
          },
          opposite: true

        }],
        legend: {
          enabled: !this.widget
        },
        exporting: {
          filename: title.replace(/\//ig, '_'),
          buttons: {
            contextButton: {
              align: 'left',
              x: -10,
              y: -10
            }
          },
          sourceWidth: 1180,
          sourceHeight: 757,
          chartOptions: {
            title: {
              style: {
                fontSize: '12px'
              }
            },
            legend: {
              enabled: true,
              itemWidth: 400,
              itemStyle: {
                fontSize: '10px'
              }
            },
            xAxis: {
              title: {
                style: {
                  fontSize: '10px'
                }
              },
              labels: {
                style: {
                  fontSize: '10px'
                },
                align: 'right',
                rotation: -45,
                formatter: function () {
                  return moment(new Date(this.value)).format('l');
                }
              }
            },
            yAxis: [{ // Primary yAxis
              max: Math.ceil(this.maxPerformance),
              endOnTick: false,
              tickAmount: this.defaultTickAmount,
              title: {
                text: yAxisText,
                style: {
                  fontSize: '10px'
                }
              },
              plotLines: plotLines,
              labels: {
                style: {
                  fontSize: '8px'
                }
              }
            }, { // Secondary yAxis
              tickAmount: this.defaultTickAmount,
              gridLineWidth: 0,
              labels: {
                format: '{value}°C',
                style: {
                  color: '#000000',
                  fontSize: '8px'
                }
              },
              title: {
                text: this.filterModel.temperatureChecked ? this.translateService.instant('filter.basic.temperature') : '',
                style: {
                  color: '#000000',
                  fontSize: '10px'
                }
              },
              opposite: true

            }]
          }
        },
        series: this.chartData,
        credits: {
          enabled: false
        },
        lang: {
          decimalPoint: ','
        },
        tooltip: {
          useHTML: true,
          formatter: function () {
            if (this.series.name === consumptionScheduleDiffPercentName) {
              return diagramService.getCommonTooltipFormatter(this, '%', true, resolution, ConsumptionDiagramType.PERFORMANCE, isMultiplePeriod);
            } else {
              return diagramService.getCommonTooltipFormatter(this, yAxisText, true, resolution, ConsumptionDiagramType.PERFORMANCE, isMultiplePeriod);
            }
          }
        }
        //To render image on exported content Highcharts export server needs an access to the picture (relative url is not enough)!
        // ,
        // exporting: {
        //   chartOptions: {
        //     chart: {
        //       events: this.diagramService.getWatermark(this.widget)
        //     }
        //   }
        // }
      });

      setTimeout(() => {
        this.diagramService.setVisiblePlot(this.chart1, 'min', this.filterModel.minSelected);
        this.diagramService.setVisiblePlot(this.chart1, 'max', this.filterModel.maxSelected);
        this.diagramService.setVisiblePlot(this.chart1, 'avg', this.filterModel.avgSelected);
        this.diagramService.setVisiblePlot(this.chart1, 'schedule', this.filterModel.scheduleSelected);
        if (this.maxPerformance) {
          this.diagramService.setVisiblePlot(this.chart1, 'performance', this.filterModel.perfSelected);
        }
      }, 0);

    });
  }

  createDummyPlotlines() {
    //for highcharts to remove lines from previously runned report. It's a bug workaround
    let dummyMinMaxValues = [];
    for (let i = 0; i < 4; i++) {
      let dummyMinMaxValueObject = this.diagramService.createInvisibleDummyMinMaxPlotlines(i, dummyMinMaxValues);
      dummyMinMaxValues.push(dummyMinMaxValueObject.min);
      dummyMinMaxValues.push(dummyMinMaxValueObject.max);
    }

    return dummyMinMaxValues;
  }

  generateBookedQuantityForDiagramData(podBookedPerformance: PodBookedPerformanceModel, startTime: string, endTime: string) {

    let quantity = podBookedPerformance.bookedQuantity;
    let intervalStart = new Date(podBookedPerformance.startTime);
    let intervalEnd = new Date(podBookedPerformance.endTime);
    let diagramStart = new Date(startTime);
    let diagramEnd = new Date(endTime);
    if (intervalStart.getTime() < diagramStart.getTime()) {

      intervalStart.setTime(diagramStart.getTime());
    }
    if (diagramEnd.getTime() < intervalEnd.getTime()) {
      intervalEnd.setTime(diagramEnd.getTime());
    }

    let data: any[][] = [];

    intervalEnd.setDate(intervalEnd.getDate() + 1);

    for (let nextDate = intervalStart; nextDate.getTime() <= intervalEnd.getTime(); nextDate.setTime(nextDate.getTime() + 900000)) {
      let localTime = nextDate.getTime();

      data.push([localTime, quantity]);
    }

    return data;
  }

  private listenDynamicFilterChange(): void {
    this.dynamicFilterChangeSub = this.consumptionDynamicFilterService.filterChangedSubject.subscribe((event: FilterEvent) => {
      if (event.type == 'min' || event.type == 'max' || event.type == 'avg' || event.type == 'performance' || event.type == 'schedule') {
        this.diagramService.setVisiblePlot(this.chart1, event.type, event.value);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.dynamicFilterChangeSub) {
      this.dynamicFilterChangeSub.unsubscribe();
    }
  }
}
