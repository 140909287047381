<h1 mat-dialog-title>{{data.title | translate}}</h1>
<div mat-dialog-content>
    <div *ngFor="let detail of data.details"
        class="row">
        <div class="col-6 m-0 text-left"> {{detail.key | translate}}</div>
        <div class="col-6 p-0 m-0 text-left" [innerHTML]="detail.value"></div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [mat-dialog-close] cdkFocusInitial>{{'common.action.ok' | translate}}
    </button>
</div>
