import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { LangChangeEvent, TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

import { LANGUAGES } from './language.constants';

@Injectable()
export class JhiLanguageHelper {

    cezTitle: string = ' - CEZHU|b';

    constructor(public translateService: TranslateService, private titleService: Title, private router: Router) {
        this.init();
    }

    getAll(): Promise<any> {
        return Promise.resolve(LANGUAGES);
    }

    /**
     * Update the window title using params in the following
     * order:
     * 1. titleKey parameter
     * 2. $state.$current.data.pageTitle (current state page title)
     * 3. 'global.title'
     */
    updateTitle(titleKey?: string) {
        if (!titleKey) {
            titleKey = this.getPageTitle(this.router.routerState.snapshot.root);
        }

        this.translateService.get(titleKey).subscribe(title => {
            this.titleService.setTitle(title + this.cezTitle);
        });
    }

    private init() {
        this.translateService.onTranslationChange.subscribe((event: TranslationChangeEvent) => {
            this.updateTitle();
        });

        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.updateTitle();
        });
    }

    private getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
        if (routeSnapshot.firstChild) {
            return this.getPageTitle(routeSnapshot.firstChild);
        }

        if (routeSnapshot.data && routeSnapshot.data['pageTitle']) {
            return routeSnapshot.data['pageTitle'];
        } else {
            return 'main.link.homepage';
        }
    }
}
