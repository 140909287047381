export class SubscriptionModel {
    id: number
    startTime: Date
    endTime: Date
    startTimeNgB: any
    endTimeNgB: any
    name: string
    subscriptionGroup: {
        id: number,
        name: string,
        permissions: [
            {
                permission: string
            }
        ],
        type: string
    }
}