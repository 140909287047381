import { Component, OnInit } from '@angular/core';
import { Layout } from './layout.model';
import { UserSettings } from './userSettings.model';
import {UserSettingsService} from '../shared/services/user-settings.service';
import {ViewTemplate} from '../shared/dto/view.template.model';
import {ViewTemplateService} from '../shared/services/view-template.service';

@Component({
    selector: 'jhi-home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
    layoutOptions: Layout[];
    usersettings: UserSettings = new UserSettings();
    dynamicTemplates: ViewTemplate[];

    constructor(private userSettingsService: UserSettingsService, private viewTemplateService: ViewTemplateService) {

        this.layoutOptions =
            [
                { id: 0, cssClass: "option1", active: false },
                { id: 1, cssClass: "option2", active: false },
                { id: 2, cssClass: "option3", active: false },
                { id: 3, cssClass: "option4", active: false }
            ];
    }

    ngOnInit() {
        this.userSettingsService.getUserSettings().subscribe(r => {
            if (!r)
                return;
            this.usersettings = r;
            this.layoutOptions.map(item => item.active = item.id == this.usersettings.selectedlayout);
        });

        this.viewTemplateService.getChartViewTemplates()
            .subscribe(r => this.dynamicTemplates = r.filter(template => template.dynamic));
    }

    setActiveLayout(option: Layout) {
        this.layoutOptions.map(r => r.active = false);
        option.active = true;
        this.usersettings.selectedlayout = option.id;
        this.userSettingsService.saveUserSettings(this.usersettings).subscribe();
    }

    setGraphicon() {
        this.userSettingsService.saveUserSettings(this.usersettings).subscribe();
    }
}
