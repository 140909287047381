import {Component, OnInit, ViewChild} from '@angular/core';
import {PriceTrackerModel} from "../../shared/dto/price-tracker.model";
import {FilterService, SortEvent, SortMeta} from "primeng/api";
import {CoverageTransactionsAdminService} from "../coverage-transactions.admin.service";
import {PriceTrackerDto} from "../../coverage-transactions/price-monitoring/coverage-transactions-price-tracker.model";
import {OfferPeriod} from "../../shared/offer-claim/model/offer-period.enum";
import {OfferType} from "../../shared/offer-claim/model/offer-type.enum";
import * as _ from "lodash";
import {DateIntervalFilter, NumberFilter} from "../filter.type";
import {TranslateService} from "@ngx-translate/core";
import {SortUtil} from "../../core/util/sort.util";
import {NgBDatePickerConvertService} from "../../shared/services/ngb.datepicker.convert.service";
import {Table} from "primeng/table";

@Component({
    selector: 'jhi-active-price-trackings',
    templateUrl: './active-price-trackings.component.html',
    styleUrls: ['./active-price-trackings.component.scss']
})
export class ActivePriceTrackingsComponent implements OnInit {

    private readonly DATE_FILTER_FIELDS: string[] = ['createdAt', 'lastModifiedAt'];
    private readonly NUMBER_FILTER_FIELDS: string[] = ['minPrice', 'maxPrice'];

    public priceTrackerModels: PriceTrackerModel[] = [];

    public partners: string[] = [];
    public createdAts: Date[] = [];
    public lastModifiedAts: Date[] = [];
    public years: number[] = [];
    public products: string[] = [];

    @ViewChild('trackerTable', {static: true}) trackerTable: Table;

    public dateFilterMap: Map<string, DateIntervalFilter> = new Map<string, DateIntervalFilter>();
    public numberFilterMap: Map<string, NumberFilter> = new Map<string, NumberFilter>();

    constructor(private translate: TranslateService,
                private coverageTransactionsAdminService: CoverageTransactionsAdminService,
                private ngBDatePickerConvertService: NgBDatePickerConvertService,
                private filterService: FilterService) {
    }

    ngOnInit() {
        this.DATE_FILTER_FIELDS.forEach(field =>
            this.dateFilterMap.set(field, {start: undefined, end: undefined}));
        this.NUMBER_FILTER_FIELDS.forEach(field =>
            this.numberFilterMap.set(field, {min: undefined, max: undefined}));

        this.dateFilterMap.forEach((filter: DateIntervalFilter, field: string) => {
            this.filterService.register('dateBetween-' + field, (value): boolean => this.dateBetweenFilter(filter, value));
        });

        this.numberFilterMap.forEach((filter: NumberFilter, field: string) => {
            this.filterService.register('numberBetween-' + field, (value): boolean => this.numberBetweenFilter(filter, value));
        });

        this.getPriceTrackers();
    }

    sortOverview(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const multiSortMeta: SortMeta[] = event.multiSortMeta;
            let result: number = null;

            if (multiSortMeta === undefined) {
                return SortUtil.sortField(event.order, event.field, data1, data2);
            } else {
                multiSortMeta.forEach((meta) => {
                    let metaResult = SortUtil.sortField(meta.order, meta.field, data1, data2);
                    result = result || metaResult;
                });
            }

            return result;
        });
    }

    private getPriceTrackers() {
        this.coverageTransactionsAdminService.getPriceTrackers(true)
            .subscribe((priceTrackerDtos: PriceTrackerDto[]) => {
                this.priceTrackerModels = priceTrackerDtos.map(priceTrackerDto => this.mapDtoToModel(priceTrackerDto));
                this.createFilters(this.priceTrackerModels);
            });
    }

    private mapDtoToModel(priceTrackerDto: PriceTrackerDto) {
        let priceTrackerModel = new PriceTrackerModel();

        priceTrackerModel.partner = priceTrackerDto.partner;
        priceTrackerModel.createdAt = priceTrackerDto.createdAt;
        priceTrackerModel.lastModifiedAt = priceTrackerDto.lastModifiedAt;
        priceTrackerModel.year = priceTrackerDto.year;
        priceTrackerModel.product = this.getProductName(priceTrackerDto);
        priceTrackerModel.minPrice = priceTrackerDto.minPrice;
        priceTrackerModel.maxPrice = priceTrackerDto.maxPrice;

        return priceTrackerModel;
    }

    private createFilters(priceTrackerModels: PriceTrackerModel[]) {
        priceTrackerModels.forEach((priceTrackerModel: PriceTrackerModel) => {
            if (!_.includes(this.partners, priceTrackerModel.partner) && priceTrackerModel.partner != null) {
                this.partners.push(priceTrackerModel.partner);
            }
            if (!_.includes(this.createdAts, priceTrackerModel.createdAt) && priceTrackerModel.createdAt != null) {
                this.createdAts.push(priceTrackerModel.createdAt);
            }
            if (!_.includes(this.lastModifiedAts, priceTrackerModel.lastModifiedAt) && priceTrackerModel.lastModifiedAt != null) {
                this.lastModifiedAts.push(priceTrackerModel.lastModifiedAt);
            }
            if (!_.includes(this.years, priceTrackerModel.year) && priceTrackerModel.year != null) {
                this.years.push(priceTrackerModel.year);
            }
            if (!_.includes(this.products, priceTrackerModel.product) && priceTrackerModel.product != null) {
                this.products.push(priceTrackerModel.product);
            }
        });

        this.partners.sort();
        this.createdAts.sort();
        this.lastModifiedAts.sort();
        this.years.sort();
        this.products.sort();
    }

    private getProductName(priceTracker: PriceTrackerDto): string {
        const period: string = priceTracker.period === OfferPeriod.Y ? 'YR' : `${priceTracker.period}${priceTracker.periodNumber}`;
        return `${period}-${priceTracker.year} HU ${OfferType.BL}`;
    }

    public onKeyDown(event: any) {
        // not allow negative prices
        if (event.key === '-' || (this.translate.currentLang === 'hu' && event.key === '.')) {
            event.preventDefault();
        }
    }

    private dateBetweenFilter(filter: DateIntervalFilter, value: string): boolean {
        if (value === undefined || value === null) {
            return false;
        }

        if (!!filter.start) {
            let filterStart = this.ngBDatePickerConvertService.convertToDate(filter.start);
            if (filterStart > new Date(value)) {
                return false;
            }
        }
        if (!!filter.end) {
            let filterEnd = this.ngBDatePickerConvertService.convertToDate(filter.end);
            filterEnd.setHours(23, 59, 59, 999);
            if (filterEnd < new Date(value)) {
                return false;
            }
        }

        return true;
    }

    private numberBetweenFilter(filter: NumberFilter, value: number) {
        if (value === undefined || value === null) {
            return false;
        }

        if (!!filter.min && filter.min > value) {
            return false;
        }
        if (!!filter.max && filter.max < value) {
            return false;
        }

        return true;
    }
}
