<table class="table table-striped">
  <thead>
    <tr>
      <th class="text-left">
        <span>#</span>
      </th>
      <th class="text-left">
        <span jhiTranslate="agreement.agreementData.contracts.contractNumber">Contract Number</span>
      </th>
      <th class="text-left">
        <span jhiTranslate="agreement.agreementData.contracts.contractLanguage">Contract Language</span>
      </th>
      <th class="text-left">
        <span jhiTranslate="agreement.agreementData.contracts.signedTime">Signed Time</span>
      </th>
      <th class="text-left">
        <span jhiTranslate="agreement.agreementData.contracts.startTime">Start Time</span>
      </th>
      <th class="text-left">
        <span jhiTranslate="agreement.agreementData.contracts.endTime">End Time</span>
      </th>
      <th class="text-left">
        <span jhiTranslate="agreement.agreementData.contracts.comment">Comment</span>
      </th>
      <th class="text-left">
        <span jhiTranslate="agreement.agreementData.contracts.file">File</span>
      </th>
      <th *ngIf="adminView"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let contract of model; let i = index">
      <td>{{i+1}}</td>
      <td>{{contract.contractNumber}}</td>
      <td>
        <span *ngIf="contract.contractLanguage === 'hu'" class="flag-hu"></span>
        <span *ngIf="contract.contractLanguage === 'en'" class="flag-en"></span>
      </td>
      <td>{{contract.signedTime | cezDate}}</td>
      <td>{{contract.startTime | cezDate}}</td>
      <td>{{contract.endTime | cezDate}}</td>
      <td>{{contract.comment}}</td>
      <td>
        <a *ngIf="contract.files && contract.files.length > 0" href="/api/files/download/{{contract.files[0].id}}" target="_blank">
          <i class="fa fa-file-o" aria-hidden="true"></i>
        </a>
      </td>
      <td *ngIf="adminView">
        <button type="button" class="btn btn-info btn-sm" (click)="edit(contract)">
          <span class="fa fa-pencil"></span>
        </button>
        <button type="button" class="btn btn-danger btn-sm" (click)="delete(contract)">
          <span class="fa fa-remove"></span>
        </button>
      </td>
    </tr>
  </tbody>
</table>
