import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Chart} from 'angular-highcharts';
import { DiagramColumnComponent } from './diagram-column/diagram-column.component';
import { FilterModel } from './filter-basic/filter.model';
import { MinMaxAvarageComponent } from './min-max-avarage/min-max-avarage.component';
import { PerformanceDiagramAreaComponent } from './performance-diagram-area/performance-diagram-area.component';
import { PerformanceDiagramBoxPlotComponent } from './performance-diagram-box-plot/performance-diagram-box-plot.component';
import { PerformanceDispersionDiagramColumnComponent } from './performance-dispersion-diagram-column/performance-dispersion-diagram-column.component';
import { PerformanceHeatmapDiagramComponent } from './performance-heatmap-diagram/performance-heatmap-diagram.component';
import * as Highcharts from 'highcharts';

@Component({
    selector: 'jhi-consumption',
    templateUrl: './consumption.component.html',
    styleUrls: ['./consumption.component.scss']
})
export class ConsumptionComponent implements OnInit {

    actualDiagramId: number;
    @Input() widget: boolean;
    @ViewChild(PerformanceDiagramAreaComponent, {static: true}) performanceAreaComponent: PerformanceDiagramAreaComponent;
    @ViewChild(DiagramColumnComponent, {static: true}) performanceRunComponent: DiagramColumnComponent;
    @ViewChild(PerformanceDiagramBoxPlotComponent, {static: true}) boxPlotComponent: PerformanceDiagramBoxPlotComponent;
    @ViewChild(PerformanceDispersionDiagramColumnComponent, {static: true}) dispersionComponent: PerformanceDispersionDiagramColumnComponent;
    @ViewChild(PerformanceHeatmapDiagramComponent, {static: true}) heatmapComponent: PerformanceHeatmapDiagramComponent;
    @ViewChild(MinMaxAvarageComponent, {static: true}) minMaxAverage: MinMaxAvarageComponent;

    constructor(public translateService: TranslateService) {
    }

    ngOnInit() {
        if (!this.widget) {
            this.actualDiagramId = 1;
        }
    }

    getReportParametersForRun(filterModel: FilterModel) {
        Highcharts.setOptions({
            lang: {
                resetZoom: this.translateService.instant('filter.basic.resetZoom'),
                resetZoomTitle: this.translateService.instant('filter.basic.resetZoomTitle'),
                printChart: this.translateService.instant('filter.basic.printChart'),
                downloadSVG: this.translateService.instant('filter.basic.downloadSVG'),
                downloadPNG: this.translateService.instant('filter.basic.downloadPNG'),
                downloadPDF: this.translateService.instant('filter.basic.downloadPDF'),
                downloadJPEG: this.translateService.instant('filter.basic.downloadJPEG')
            }
        });


        if (filterModel.selectedDiagram && filterModel.selectedDiagram.id) {
            this.actualDiagramId = filterModel.selectedDiagram ? filterModel.selectedDiagram.id : 1;
        }

        const filterModelCopy: FilterModel = JSON.parse(JSON.stringify(filterModel));

        switch (this.actualDiagramId) {
            case 1:
                this.performanceAreaComponent.getReportParametersForRun(filterModelCopy);
                break;
            case 2:
                this.performanceRunComponent.getReportParametersForRun(filterModelCopy);
                break;
            case 3:
                this.dispersionComponent.getReportParametersForRun(filterModelCopy);
                break;
            case 4:
                this.boxPlotComponent.getReportParametersForRun(filterModelCopy);
                break;
            case 5:
                this.minMaxAverage.getReportParametersForRun(filterModelCopy);
                break;
            case 6:
                this.heatmapComponent.getReportParametersForRun(filterModelCopy);
                break;

        }
    }

}
