<div class="card px-3 py-3 bg-grey" ngForm="partnerForm" #partnerForm="ngForm">
  <h4 class="card-title" jhiTranslate="agreement.agreementData.partnerMainDataTitle"></h4>
  <fieldset [disabled]="isFormDisabled">
    <div class="form-group row">
      <label class="col-sm-4" for="textHU"
             jhiTranslate="agreement.agreementData.partnerMainData.companyNameHu"></label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.accountName') ? '' : null"
                 type="text"
                 class="form-control"
                 id="textHU"
                 name="textHU"
                 [(ngModel)]="companyModel.accountName"
                 required
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.accountName') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.accountName')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="taxNumber" jhiTranslate="agreement.agreementData.partnerMainData.taxNumber">Tax
        Number</label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.vatRegistrationNumber') ? '' : null"
                 type="text"
                 class="form-control"
                 id="taxNumber"
                 name="taxNumber"
                 [(ngModel)]="companyModel.vatRegistrationNumber"
                 required
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.vatRegistrationNumber') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.vatRegistrationNumber')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="groupTaxNumber"
             jhiTranslate="agreement.agreementData.partnerMainData.groupTaxNumber">Group Tax
        Number</label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.vatGroupRegistrationNumber') ? '' : null"
                 type="text"
                 class="form-control"
                 id="groupTaxNumber"
                 name="groupTaxNumber"
                 [(ngModel)]="companyModel.vatGroupRegistrationNumber"
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.vatGroupRegistrationNumber') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.vatGroupRegistrationNumber')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="companyRegistrationNumber"
             jhiTranslate="agreement.agreementData.partnerMainData.tradeRegisterNumber">Trade Register Number</label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.companyRegistrationNumber') ? '' : null"
                 type="text"
                 class="form-control"
                 id="companyRegistrationNumber"
                 name="companyRegistrationNumber"
                 [(ngModel)]="companyModel.companyRegistrationNumber" required
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.companyRegistrationNumber') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.companyRegistrationNumber')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="companyAddress"
             jhiTranslate="agreement.agreementData.partnerMainData.companyAddress">Company Address</label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.registeredSeatAddress') ? '' : null"
                 type="text"
                 class="form-control"
                 id="companyAddress"
                 name="companyAddress"
                 [(ngModel)]="companyModel.registeredSeatAddress"
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.registeredSeatAddress') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.registeredSeatAddress')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="postalAddress"
             jhiTranslate="agreement.agreementData.partnerMainData.postalAddress">Postal Address</label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.mailingAddress') ? '' : null"
                 type="text"
                 class="form-control"
                 id="postalAddress"
                 name="postalAddress"
                 [(ngModel)]="companyModel.mailingAddress"
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.mailingAddress') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.mailingAddress')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row mt-3">
      <label class="col-sm-4" for="bankNameHUF"
             jhiTranslate="agreement.agreementData.partnerMainData.bankNameHuf"></label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.bankNameHUF') ? '' : null"
                 type="text"
                 class="form-control"
                 id="bankNameHUF"
                 name="bankNameHUF"
                 [(ngModel)]="companyModel.bankNameHUF"
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.bankNameHUF') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.bankNameHUF')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="bankAccountHUF"
             jhiTranslate="agreement.agreementData.partnerMainData.bankAccountHuf">Bank Account (HUF)</label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.bankAccountHUF') ? '' : null"
                 type="text"
                 class="form-control"
                 id="bankAccountHUF"
                 name="bankAccountHUF"
                 [(ngModel)]="companyModel.bankAccountHUF"
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.bankAccountHUF') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.bankAccountHUF')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="bankNameSwiftCodeHUF"
             jhiTranslate="agreement.agreementData.partnerMainData.swiftCodeHuf"></label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.swiftCodeHUF') ? '' : null"
                 type="text"
                 class="form-control"
                 id="bankNameSwiftCodeHUF"
                 name="bankNameSwiftCodeHUF"
                 [(ngModel)]="companyModel.swiftCodeHUF"
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.swiftCodeHUF') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.swiftCodeHUF')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="bankNameEUR"
             jhiTranslate="agreement.agreementData.partnerMainData.bankNameEur"></label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.bankNameEUR') ? '' : null"
                 type="text"
                 class="form-control"
                 id="bankNameEUR"
                 name="bankNameEUR"
                 [(ngModel)]="companyModel.bankNameEUR"
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.bankNameEUR') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.bankNameEUR')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="bankAccountEUR"
             jhiTranslate="agreement.agreementData.partnerMainData.bankAccountEUR">Bank Account (EUR)</label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.bankAccountEUR') ? '' : null"
                 type="text"
                 class="form-control"
                 id="bankAccountEUR"
                 name="bankAccountEUR"
                 [(ngModel)]="companyModel.bankAccountEUR"
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.bankAccountEUR') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.bankAccountEUR')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="bankNameSwiftCodeEUR"
             jhiTranslate="agreement.agreementData.partnerMainData.swiftCodeEur"></label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.swiftCodeEUR') ? '' : null"
                 type="text"
                 class="form-control"
                 id="bankNameSwiftCodeEUR"
                 name="bankNameSwiftCodeEUR"
                 [(ngModel)]="companyModel.swiftCodeEUR"
                 (ngModelChange)="onChange()">
          <div *ngIf="isDisabled('partner.swiftCodeEUR') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.swiftCodeEUR')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="lastPaymentIndex"
             jhiTranslate="agreement.agreementData.partnerMainData.lastPaymentIndex"></label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.lastPaymentIndex') ? '' : null"
                 type="text"
                 class="form-control"
                 id="lastPaymentIndex"
                 name="lastPaymentIndex"
                 [(ngModel)]="companyModel.lastPaymentIndex"
                 mask="separator.2"
                 autocomplete="off"
                 maxlength="10"
                 [maxNumber]="99999999.99"
                 thousandSeparator="{{'number.thousandSeparator' | translate}}"
                 [decimalMarker]="'number.decimalMarker' | translate"
                 [dropSpecialCharacters]="true"
                 (ngModelChange)="onLastPaymentIndexChange()">
          <div *ngIf="isDisabled('partner.lastPaymentIndex') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.lastPaymentIndex')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4" for="lastDBFailureScore"
             jhiTranslate="agreement.agreementData.partnerMainData.lastDBFailureScore"></label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('partner.lastDBFailureScore') ? '' : null" type="text" class="form-control"
                 id="lastDBFailureScore"
                 name="lastDBFailureScore"
                 [(ngModel)]="companyModel.lastDBFailureScore"
                 mask="separator.2"
                 autocomplete="off"
                 maxlength="10"
                 [maxNumber]="99999999.99"
                 thousandSeparator="{{'number.thousandSeparator' | translate}}"
                 [decimalMarker]="'number.decimalMarker' | translate"
                 [dropSpecialCharacters]="true"
                 (ngModelChange)="onLastDBFailureChange()">
          <div *ngIf="isDisabled('partner.lastDBFailureScore') ? '' : null" class="input-group-append" role="button"
               (click)="enable('partner.lastDBFailureScore')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4"
             for="salesforceAccountId">{{'agreement.agreementData.partnerMainData.salesforceAccountId' | translate}}</label>
      <div class="col-sm-8">
        <div class="input-group">
          <input [attr.disabled]="isDisabled('companyModel.salesforceAccountId') ? '' : null"
                 class="form-control"
                 id="salesforceAccountId"
                 name="salesforceAccountId"
                 [(ngModel)]="companyModel.salesforceAccountId"
                 type="text"
                 (ngModelChange)="onChange()"/>
          <div *ngIf="isDisabled('companyModel.salesforceAccountId') ? '' : null" class="input-group-append" role="button"
               (click)="enable('companyModel.salesforceAccountId')">
            <button type="button" class="btn btn-outline-secondary" aria-hidden="true">
              <i aria-hidden="true" class="fa fa-pencil"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-4"
             for="hedgeEmailSending">{{'agreement.agreementData.partnerMainData.hedgeEmailSending' | translate}}</label>
      <div class="col-sm-8">
        <p-checkbox id="hedgeEmailSending" name="hedgeEmailSending" [binary]="true"
                    [disabled]="isDisabled('companyModel.hedgeEmailSending')"
                    [(ngModel)]="companyModel.hedgeEmailSending"
                    (ngModelChange)="onChange()"></p-checkbox>
      </div>
    </div>
  </fieldset>
</div>

<mat-card class="px-3 py-3 mt-3">
    <mat-card-title>
        <h4 class="card-title">{{'agreement.agreementData.subSidiaries' | translate}}</h4>
    </mat-card-title>
    <mat-card-content>
        <ng-multiselect-dropdown
            name="selectedUsers"
            [placeholder]="'Select partner'"
            [settings]="dropdownSettings"
            [data]="possibleSubsidiaryOptions"
            [(ngModel)]="subsidiaryOptionsModel"
            (onSelect)="onSelectSubsidiariesChange()"
            (onDeSelect)="onSelectSubsidiariesChange()"
            (onSelectAll)="onSelectAllSubsidiariesChange()"
            (onDeSelectAll)="onDeselectAllSubsidiariesChange()">
        </ng-multiselect-dropdown>

        <jhi-company-list [crudOptions]="false" [companies]="companyModel.subsidiaries"></jhi-company-list>
    </mat-card-content>
</mat-card>
