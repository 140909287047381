<div mat-dialog-title class="dialog-title mt-0">
    <button mat-dialog-close aria-label="Close" class="close" type="button">
        <span aria-hidden="true">&times;</span>
    </button>
    <h2>{{data.title}}</h2>
</div>

<div mat-dialog-content>
    <p [innerHTML]="data.message"></p>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button color="primary" [mat-dialog-close] cdkFocusInitial>{{'common.action.ok' | translate}}
    </button>
</div>
