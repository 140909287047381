/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {CezPortalAppModule} from './app/app.module';
import {ProdConfig} from './app/blocks/config/prod.config';


ProdConfig();

if (module['hot']) {
  module['hot'].accept();
}

platformBrowserDynamic().bootstrapModule(CezPortalAppModule);
