<mat-card>
    <mat-card-title>
        <header class="docs">
            <h2>{{'AdminTabs.tos' | translate}}</h2>
            <aside>
                <button class="btn btn-primary" (click)="toggleNewContainer()">{{(showNewContainer ? 'entity.action.cancel' : 'termsOfServices.create') | translate}}</button>
            </aside>
        </header>
    </mat-card-title>
    <mat-card-content>
        <div class="my-3" *ngIf="showNewContainer">
            <form #termOfServiceEditForm="ngForm" (ngSubmit)="saveNew(termOfServiceEditForm)">
                <div class="form-group">
                    <label><strong>{{'termsOfServices.textHu' | translate}}</strong></label>
                    <quill-editor name="textHU" [ngModel]="termsOfServiceNew.text.textHU" required>
                    </quill-editor>

                    <label class="mt-4"><strong>{{'termsOfServices.textEn' | translate}}</strong></label>
                    <quill-editor name="textEN" [ngModel]="termsOfServiceNew.text.textEN" required>
                    </quill-editor>
                </div>
                <button type="submit" class="btn btn-primary" [disabled]="termOfServiceEditForm.form.invalid || isSaving">{{'entity.action.save' | translate}}</button>
            </form>
        </div>

        <div class="table-responsive" *ngIf="termsOfServices && termsOfServices.length">
            <table class="table">
                <thead>
                <tr>
                    <th><span>ID</span></th>
                    <th><span>{{'termsOfServices.textEn' | translate}}</span></th>
                    <th><span>{{'termsOfServices.textHu' | translate}}</span></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let term of termsOfServices">
                    <td>{{term.id}}</td>
                    <td>
                        <p [innerHTML]="term.text.textEN | safeHtml"></p>
                    </td>
                    <td>
                        <p [innerHTML]="term.text.textHU | safeHtml"></p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </mat-card-content>
</mat-card>

