import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PriceTrackerDto} from '../coverage-transactions-price-tracker.model';
import {NgForm} from '@angular/forms';
import {CoverageTransactionsService} from '../../coverage-transactions.service';
import {DialogService} from '../../../shared/dialog/dialog.service';
import {TranslateService} from '@ngx-translate/core';
import {CoverageTransactionsPeriodTabService} from '../../coverage-transactions-period-tab.service';
import {DeliveryPeriodForOffer} from '../../../shared/offer-claim/model/DeliveryPeriodForOffer';
import * as moment from 'moment';
import {ValidationException, Violation} from "../../../shared/dto/validation.exception.model";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
    selector: 'jhi-coverage-transactions-price-tracker-modal',
    templateUrl: './coverage-transactions-price-tracker-modal.component.html',
    styleUrls: ['coverage-transactions-price-tracker-modal.component.scss']
})
export class CoverageTransactionsPriceTrackerModalComponent implements OnInit {

    private readonly YR: string = 'Y';
    private onTheFlyValidationError: boolean;
    public errorMessages: string[] = [];

    @Input()
    public set tracker(tracker: PriceTrackerDto) {
        this.selectedTracker = tracker;
        this.getDailyRateNames();
    }

    selectedTracker: PriceTrackerDto;

    @Output() modalClose: EventEmitter<any> = new EventEmitter();

    years: number[] = [];
    dailyRateNames: string[] = [];

    periods: string[] = [];
    periodNumbers: string[] = [];
    periodNumbersByPeriod: Map<string, string[]> = new Map();

    constructor(private coverageTransactionsService: CoverageTransactionsService,
                private dialogService: DialogService,
                private translate: TranslateService,
                private coverageTransactionsPeriodTabService: CoverageTransactionsPeriodTabService) {
    }

    ngOnInit(): void {
        this.years = this.getAvailableDeliveryPeriodYears();
        this.getDailyRateNames();
    }

    onYearChange(): void {
        this.selectedTracker.dailyRateName = null;
        this.selectedTracker.period = null;
        this.selectedTracker.periodNumber = null;
        this.clearErrors();
        this.getDailyRateNames();
    }

    setAndValidateSelectedTracker(tracker: PriceTrackerDto) {
        this.selectedTracker = tracker;
        if (!!this.selectedTracker) {
            this.validatePeriod();
        }
    }

    getDailyRateNames(): void {
        if (!!this.selectedTracker.year) {
            this.coverageTransactionsService.getDailyRateNames(this.selectedTracker.year)
                .subscribe((result) => {
                    this.dailyRateNames = result;
                    this.processDailyRateNames();
                });
        }
    }

    onKeyDown(event: any) {
        // not allow negative prices
        if (event.key === '-' || (this.translate.currentLang === 'hu' && event.key === '.')) {
            event.preventDefault();
        }
    }

    canSave(form: NgForm): boolean {
        let min = this.selectedTracker.minPrice;
        let max = this.selectedTracker.maxPrice;

        return !!this.selectedTracker.year
            && !!this.selectedTracker.period
            && !!this.selectedTracker.periodNumber
            && (!!min || !!max)
            && this.isMinSmallerOrNotExist(min, max)
            && this.isDecimalCorrect(min)
            && this.isDecimalCorrect(max)
            && !form.invalid
            && !this.onTheFlyValidationError;
    }

    private isMinSmallerOrNotExist(min: number, max: number): boolean {
        if (!!min && !!max) {
            return Number.parseFloat(min.toString()) < Number.parseFloat(max.toString());
        }

        return true;
    }

    private isDecimalCorrect(price: number): boolean {
        if (!!price) {
            return this.numberOfDecimals(price) <= 2;
        }

        return true;
    }

    minGreaterOrEqualMax(): boolean {
        return !!this.selectedTracker.minPrice && !!this.selectedTracker.maxPrice &&
            Number.parseFloat(this.selectedTracker.minPrice.toString()) >= Number.parseFloat(this.selectedTracker.maxPrice.toString());
    }

    onSubmit(form: NgForm) {
        if (!!this.selectedTracker.id) {
            this.update(this.selectedTracker);
        } else {
            this.create(this.selectedTracker);
        }
    }

    periodChange(): void {
        this.periodNumbers = this.periodNumbersByPeriod.get(this.selectedTracker.period);
        if (this.selectedTracker.period === this.YR) {
            this.selectedTracker.periodNumber = this.periodNumbers[0];
            this.clearErrors();
            this.periodNumberChange();
        } else {
            this.selectedTracker.periodNumber = null;
            this.clearErrors();
        }
    }

    periodDisabled(): boolean {
        return !this.selectedTracker.year;
    }

    periodNumbersDisabled(): boolean {
        return !this.selectedTracker.period || this.selectedTracker.period === this.YR;
    }

    private create(tracker: PriceTrackerDto): void {
        this.coverageTransactionsService.createPriceTracker(tracker).subscribe(() => {
            this.dialogService.saveSuccess();
            this.modalClose.emit();
        });
    }

    private update(tracker: PriceTrackerDto): void {
        this.coverageTransactionsService.updatePriceTracker(tracker).subscribe(() => {
            this.dialogService.saveSuccess();
            this.modalClose.emit();
        });
    }

    private processDailyRateNames(): void {
        this.resetPeriods();
        const tempPeriods: string[] = [];

        this.dailyRateNames.forEach((dailyRateName: string) => {
            if (dailyRateName.length === 4 && tempPeriods.indexOf(this.YR) === -1) {
                tempPeriods.push(this.YR);
                this.addPeriodNumberByPeriodName(this.YR, '1');
            } else {
                const periodName: string = dailyRateName.charAt(4);
                if (tempPeriods.indexOf(periodName) === -1) {
                    tempPeriods.push(periodName);
                }
                const periodNumber: string = dailyRateName.substring(5);
                this.addPeriodNumberByPeriodName(periodName, periodNumber);
            }
        });

        setTimeout(() => {
            this.periods = tempPeriods;
            this.periodNumbers = this.periodNumbersByPeriod.get(this.selectedTracker.period);
        }, 0);
    }

    private addPeriodNumberByPeriodName(periodName: string, periodNumber: string): void {
        if (this.periodNumbersByPeriod.has(periodName)) {
            const periodNumbersArray: string[] = this.periodNumbersByPeriod.get(periodName);
            if (periodNumbersArray.indexOf(periodNumber) === -1) {
                periodNumbersArray.push(periodNumber);
            }
        } else {
            this.periodNumbersByPeriod.set(periodName, [periodNumber]);
        }
    }

    private resetPeriods(): void {
        this.periods = [];
        this.periodNumbers = [];
        this.periodNumbersByPeriod = new Map();
    }

    private numberOfDecimals(price: number): number {
        let splitted = price.toString().split('.');
        if (splitted.length < 2) {
            return 0;
        }
        return splitted[1].length;
    }

    /**
     * Retrieves the available delivery period years.
     *
     * @returns {number[]} - An array of years representing the available delivery periods.
     */
    private getAvailableDeliveryPeriodYears(): number[] {
        return this.coverageTransactionsPeriodTabService.deliveryPeriodForOffers
            .map((dp: DeliveryPeriodForOffer) => dp.deliveryPeriod.startTime)
            .map((startTime: Date) => moment(startTime).year())
            .filter((year: number): boolean => year >= moment().year())
            .slice(0, 4);
    }

    periodNumberChange() {
        this.validatePeriod();
    }

    private validatePeriod() {
        this.onTheFlyValidationError = true;
        this.errorMessages = [];
        let priceTracker: PriceTrackerDto;

        if (!!this.selectedTracker.period) {
            priceTracker = {
                createdAt: undefined,
                dailyRateName: "",
                id: this.selectedTracker.id || 0,
                lastModifiedAt: undefined,
                maxPrice: 0,
                minPrice: 0,
                partner: "",
                year: this.selectedTracker.year,
                period: this.selectedTracker.period,
                periodNumber: this.selectedTracker.periodNumber
            };

            this.coverageTransactionsService.validatePriceTracker(priceTracker)
                .subscribe((): void => this.clearErrors(), (error: HttpErrorResponse): void => this.processError(error));
        }
    }

    private processError(error: HttpErrorResponse): void {
        if (error.status !== 422) {
            return;
        }

        const errorObject: ValidationException = error.error;
        if (!errorObject.hidden) {
            if (!errorObject.violations || errorObject.violations.length === 0) {
                this.errorMessages.push(this.translate.instant('validation.unknown'));
            } else {
                errorObject.violations.forEach((violation: Violation) => {
                    this.errorMessages.push(this.translate.instant(`validation.${violation.errorCode}`, violation.args));
                });
            }
        }

        if (this.errorMessages.length === 0) {
            this.onTheFlyValidationError = false;
        }
    }

    public hasError(): boolean {
        return this.errorMessages.length > 0;
    }

    private clearErrors() {
        this.onTheFlyValidationError = false;
        this.errorMessages = [];
    }

}
