import {ContactModel} from './contact.model';
import {AgreementModel} from './agreement.model';
import {AgreementUser} from './agreement-user';
import {SubscriptionModel} from './subscription.model';
import {BookedPerformanceDefaults} from './booked.performance.defaults.model';
import {NavisionModel} from "./navision.model";


export class CompanyModel {
    authorizedToSign: string;
    bankAccountEUR: string;
    bankAccountHUF: string;
    bankNameEUR: string;
    bankNameHUF: string;
    swiftCodeEUR: string;
    swiftCodeHUF: string;
    cezContacts: ContactModel[] = [];
    registeredSeatAddress: string;
    accountName:  string;
    contacts: ContactModel[] = [];
    salesforceAccountId: string;
    id: number;
    mailingAddress: string;
    vatGroupRegistrationNumber: string;
    vatRegistrationNumber: string;
    companyRegistrationNumber: string;
    agreements: AgreementModel[];
    users: AgreementUser[];
    subscriptions: SubscriptionModel[];
    defaultSettings: BookedPerformanceDefaults;
    subsidiaries: CompanyModel[];
    lastPaymentIndex: number;
    lastDBFailureScore: number;
    navisionIds: NavisionModel[];
    navisionId: string;
    hedgeEmailSending: boolean;
}
