export type Color = 'RED' | 'ORANGE' | 'YELLOW' | 'BLUE';

export const ColorConstants = {
    RED: 'RED' as Color,
    ORANGE: 'ORANGE' as Color,
    YELLOW: 'YELLOW' as Color,
    BLUE: 'BLUE' as Color,
};

export type ColorPalette = { [key in Color]: string[] };
