<header class="docs">
    <div class="row pt-3 justify-content-center">
        <div [ngClass]="'col-lg-5 col-sm-12'">
            <mat-card class="bg-white">
                <mat-card-content class="min-card-height">
                    <div class="container-fluid">
                        <form #myForm="ngForm"
                              (ngSubmit)="onSubmit(availablePeriods, myForm)">
                            <div class="d-flex row">
                                <p-table #dt [value]="availablePeriods"
                                         class="table tasks-table table-striped">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th class="text-center head-th">
                                                <span jhiTranslate="availableProductPeriods.tableField.periodType"></span>
                                            </th>
                                            <th class="text-center head-th ">
                                                <span jhiTranslate="availableProductPeriods.tableField.availablePeriodCount"></span>
                                            </th>
                                        </tr>
                                    </ng-template>

                                    <ng-template pTemplate="body" let-rowData>
                                        <tr [pSelectableRow]="rowData">
                                            <td>
                                                {{('availableProductPeriods.tableValue.' + rowData.period) | translate}}
                                            </td>

                                            <td class="text-center">
                                                <input type="text"
                                                       maxlength="3"
                                                       mask="separator.0"
                                                       [(ngModel)]="rowData.availablePeriodCount"
                                                       (keydown)="onKeyDown($event)"
                                                       [dropSpecialCharacters]="true"
                                                       class="text-right form-control"
                                                       name="availablePeriodCount-{{rowData.period}}">
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                            <div class="row">
                                <div class="col-lg-12 d-flex justify-content-end">
                                    <button class="btn btn-primary"
                                            type="submit"
                                            [disabled]="this.isSaveDisabled()">
                                        {{'entity.action.save' | translate}}
                                    </button>
                                    <button class="btn btn-primary ml-2"
                                            type="button"
                                            [disabled]="this.isSaveDisabled()"
                                            (click)="reset()">
                                        {{'entity.action.cancel' | translate}}
                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</header>
