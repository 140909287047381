<div class="modal-header">
  <h4 class="modal-title">{{'agreement.agreementData.contracts.title' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="this.activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form name="contractForm" role="form" #contractForm="ngForm" (ngSubmit)="save()">
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label for="contractNumber">{{'agreement.agreementData.contracts.contractNumber' | translate}}</label>
          <input required id="contractNumber" name="contractNumber" [(ngModel)]="model.contractNumber"
                 class="form-control" type="text">
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label for="signedTime">{{'agreement.agreementData.contracts.signedTime' | translate}}</label>
          <jhi-date-picker [(dpModel)]="model.signedTimeNgB" id="signedTime"></jhi-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label for="startTime">{{'agreement.agreementData.contracts.startTime' | translate}}</label>
          <jhi-date-picker [(dpModel)]="model.startTimeNgB" id="startTime"></jhi-date-picker>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label for="endTime">{{'agreement.agreementData.contracts.endTime' | translate}}</label>
          <jhi-date-picker [(dpModel)]="model.endTimeNgB" id="enídTime"></jhi-date-picker>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="contractLanguage">{{'agreement.agreementData.contracts.contractLanguage' | translate}}</label>
      <select required id="contractLanguage" name="contractLanguage" [(ngModel)]="model.contractLanguage"
              class="form-control">
        <option *ngFor="let item of languages | keys"
                [value]="item.value">{{('agreement.agreementData.mainData.bidLanguageValues.' + item.value) | translate}}</option>
      </select>
    </div>
    <div class="form-group">
      <label for="comment">{{'agreement.agreementData.contracts.comment' | translate}}</label>
      <input required id="comment" name="comment" [(ngModel)]="model.comment" class="form-control" type="text">
    </div>
    <div class="form-group" *ngIf="model.id > -1">
      <label for="comment">{{'agreement.agreementData.contracts.file' | translate}}</label>
      <!-- <file-uploader [fileType]="'application/*'" (onSelection)="fileuploaderFileChange($event)" [required]="false"></file-uploader>-->
      <div class="col-auto">
        <div style="display: flex; align-items: center;">
          <input placeholder="{{'select.default' | translate}}" readonly class="form-control"
                 style="width: 200px; float: left; margin-right: 0px;" matInput *ngIf="!fileInput?.httpUrl"
                 value="{{fileInput?.files[0]?.name}}"/>
          <button mat-mini-fab color="primary" (click)="addFile(fileInput)" type="button">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        <input #fileInput [hidden]="true" type="file"/>
      </div>

      <cez-file-upload class="col"
                       *ngIf="fileInput.value"
                       [file]="fileInput.files[0]"
                       [autoUpload]="true"
                       (onUpload)="onUpload($event)"
                       (removeEvent)="fileInput.value = ''"></cez-file-upload>


    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" [disabled]="contractForm.form.invalid">{{'entity.action.save' | translate}}</button>
  </div>
</form>
