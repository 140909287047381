<header class="docs">
    <h2 jhiTranslate="products.title"></h2>
    <aside>
        <button class="btn btn-primary" (click)="openProduct(null)">{{'prod.newProduct' | translate}}</button>
    </aside>
</header>

<div class="col-xl-6 offset-xl-3">
    <cez-product-list [products]="products"
                      [adminMode]="true"
                      (publishClick)="publish($event)"
                      (deactivateClick)="deactivate($event)"
                      (editClick)="openProduct($event)"
                      (deleteClick)="delete($event)">
    </cez-product-list>
</div>
