import { Route } from '@angular/router';

import { ContactPageComponent } from './contact-page.component';

export const CONTACT_PAGE_ROUTE: Route[] = [
  {
    path: 'contact',
    component: ContactPageComponent,
    data: {   
      pageTitle: 'footer.contact'
    }
  }];
