import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {StateStorageService} from '../auth/state-storage.service';
import {BookedPerformanceDefaults} from '../dto/booked.performance.defaults.model';
import {CompanyModel} from '../dto/company.model';
import {InvoiceModel} from '../dto/invoice.model';
import {PartnerParameterSaveDto} from '../dto/partner-parameter-save.dto';

@Injectable()
export class PartnerService {

    constructor(private stateStorageService: StateStorageService, private http: HttpClient) {
    }


    updateDefaultSettings(partnerId: number, settings: BookedPerformanceDefaults): Observable<any> {
        return this.http.put(`api/partner/${partnerId}/settings`, settings);
    }

    getDefaultSettings(partnerId: number): Observable<BookedPerformanceDefaults> {
        return this.http.get<BookedPerformanceDefaults>(`api/partner/${partnerId}/default_settings`);
    }

    getSubsidiaryCandidates(partnerId: number): Observable<CompanyModel[]> {
        return this.http.get<CompanyModel[]>(`/api/partner/${partnerId}/subsidiary_candidates`);
    }

    get(partnerId: number): Observable<CompanyModel> {
        return this.http.get<CompanyModel>(`/api/partner/${partnerId}`);
    }

    getInvoices(): Observable<InvoiceModel[]> {
        let partnerId = this.stateStorageService.getSelectedCompanyId();
        return this.getInvoicesForAnotherPartner(partnerId);
    }

    getInvoicesForAnotherPartner(partnerId: number): Observable<InvoiceModel[]> {
        return this.http.get<InvoiceModel[]>(`/api/partner/${partnerId}/invoice`);
    }

    deleteInvoice(partnerId: number, invoiceId: number): Observable<void> {
        return this.http.delete<void>(`/api/partner/${partnerId}/invoice/${invoiceId}`);
    }

    public getProductExpiryParameter(partnerId: number): Observable<number> {
        return this.http.get<number>(`/api/partner/${partnerId}/product_expiry`);
    }

    public saveProductExpiryParameter(partnerId: number, parameterValue: PartnerParameterSaveDto): Observable<void> {
        return this.http.post<void>(`/api/partner/${partnerId}/product_expiry`, parameterValue);
    }
}
