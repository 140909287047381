import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserSettings } from '../../home.admin/userSettings.model';

@Injectable()
export class UserSettingsService {

    constructor(private http: HttpClient) {
    }

    getUserLayoutId(): Observable<number> {
        return this.http.get<UserSettings>('/api/account/user_settings').pipe(map(userSettings => {
            if (!userSettings)
                return null;
            return userSettings.selectedlayout;
        }));
    }

    getUserSettings(): Observable<UserSettings> {
        return this.http.get<UserSettings>('/api/account/user_settings');
    }

    saveUserSettings(userSettings: UserSettings): Observable<void> {
        return this.http.post<void>('/api/account/user_settings', userSettings);
    }

}
