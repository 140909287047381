import {OfferClaimDto} from "./OfferClaimDto";
import {TimeSeries} from "../../time-series/model/timeseries";

export class OfferClaimWithTimeSeries {
    offerClaim: OfferClaimDto;
    quantityTimeSeries: TimeSeries;
}

export class OfferClaimWithTimeSeriesBuilder {

    private offerClaimWithTimeSeries: OfferClaimWithTimeSeries = new OfferClaimWithTimeSeries();

    public static builder(): OfferClaimWithTimeSeriesBuilder {
        return new OfferClaimWithTimeSeriesBuilder();
    }

    public build(): OfferClaimWithTimeSeries {
        return this.offerClaimWithTimeSeries;
    }

    public withOfferClaim(offerClaim: OfferClaimDto): OfferClaimWithTimeSeriesBuilder {
        this.offerClaimWithTimeSeries.offerClaim = offerClaim;

        return this;
    }

    public withQuantityTimeSeries(quantityTimeSeries: TimeSeries): OfferClaimWithTimeSeriesBuilder {
        this.offerClaimWithTimeSeries.quantityTimeSeries = quantityTimeSeries;

        return this;
    }
}
