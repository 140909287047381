import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Language} from '../../../shared/agreement-details/agreement-enums.model';
import {ContractModel} from '../../../shared/dto/contract.model';
import {NgBDatePickerConvertService} from '../../../shared/services/ngb.datepicker.convert.service';
import {ExcelImportResult} from "../../../shared/time-series/model/excel-import-result.model";
import {HttpErrorResponse} from "@angular/common/http";


@Component({
  selector: 'jhi-contract-modal',
  templateUrl: './contract-modal.component.html',
  styles: []
})
export class ContractModalComponent implements OnInit {

  @Input() model: ContractModel;
  @Output() modalClose: EventEmitter<ContractModel> = new EventEmitter();

  languages = Language;

  constructor(private dateConverter: NgBDatePickerConvertService, public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (this.model.signedTime) {
      this.model.signedTimeNgB = this.dateConverter.convertFromDate(this.model.signedTime);
      this.model.startTimeNgB = this.dateConverter.convertFromDate(this.model.startTime);
      this.model.endTimeNgB = this.dateConverter.convertFromDate(this.model.endTime);
    }
  }

  save() {
    this.model.signedTime = this.dateConverter.convertToDate(this.model.signedTimeNgB);
    this.model.startTime = this.dateConverter.convertToDate(this.model.startTimeNgB);
    this.model.endTime = this.dateConverter.convertToDate(this.model.endTimeNgB);
    this.modalClose.emit(this.model);
  }

  fileuploaderFileChange(files: FileList) {
    this.model.uploadedFiles = files;
  }


  public addFile(fileInput: HTMLInputElement) {
    fileInput.value = '';
    fileInput.click();
  }

  public onUpload(event: { file: File, success: boolean, response?: ExcelImportResult, error?: HttpErrorResponse }) {
    this.model.uploadedFiles.item[0] = event.file;
  }
}
