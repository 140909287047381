<form [formGroup]="form">
    <div class="row">
        <mat-form-field *ngIf="!hideResolution" [ngClass]="!measureUnitInputHidden() ? 'col-xl-3 col-sm-12' : 'col-xl-4 col-sm-12'">
            <mat-select placeholder="{{'timeInterval.resolution' | translate}}"
                        [formControlName]="'resolution'">
                <mat-option *ngFor="let r of resolutions | keys" [value]="r.value">
                    {{'timeInterval.resolutions.' + r.value | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="!measureUnitInputHidden()" class="col-xl-3 col-sm-12">
            <mat-select placeholder="{{'timeInterval.measureUnit' | translate}}"
                        formControlName="measureUnit">
                <mat-option *ngFor="let measureUnit of measureUnits | keys" [value]="measureUnit.value">
                    {{measureUnit.value}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field [ngClass]="hideResolution ? 'col-6' : !measureUnitInputHidden() ? 'col-xl-3 col-sm-6' : 'col-xl-4 col-sm-6'">
            <input matInput [matDatepicker]="start"
                   placeholder="{{'timeInterval.start' | translate}}"
                   formControlName="start"
                   [min]="min"
                   [max]="max"
                   (dateChange)="startDateChanged($event.value)">
            <mat-datepicker-toggle matSuffix [for]="start"></mat-datepicker-toggle>
            <mat-datepicker #start>
            </mat-datepicker>
        </mat-form-field>
        <mat-form-field [ngClass]="hideResolution ? 'col-6' : !measureUnitInputHidden() ? 'col-xl-3 col-sm-6' : 'col-xl-4 col-sm-6'">
            <input matInput [matDatepicker]="end"
                   placeholder="{{'timeInterval.end' | translate}}"
                   formControlName="end"
                   [min]="min"
                   [max]="max"
                   (dateChange)="endDateChanged($event.value)">
            <mat-datepicker-toggle matSuffix [for]="end"></mat-datepicker-toggle>
            <mat-datepicker #end>
            </mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-xl-4 col-sm-6" *ngIf="isExpectedPlan">
            <mat-select [(value)]="selectedExpectedPlan"
                        placeholder="{{'toleranceThreshold.expectedPlan' | translate}}"
                        (selectionChange)="expectedPlanSelectionChange($event.value)">
                <mat-option *ngFor="let p of expectedPlanProps" [value]="p">
                    {{'toleranceThreshold.' + p | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="col-xl-4 col-sm-6" *ngIf="isExpectedPlan">
            <div *ngFor="let p of expectedPlanProps">
                <mat-form-field *ngIf="p === selectedExpectedPlan && p !== 'expectedPlan'">
                    <input type="number" matInput
                           formControlName="{{p}}"
                           placeholder="{{'toleranceThreshold.' + p | translate}} (MWh)">
                </mat-form-field>
            </div>
        </div>
    </div>
</form>
