import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BookedPerformanceComponent } from './booked-performance.component';
import { RouterModule } from '@angular/router';
import { BOOKED_PERFORMANCE_ROUTE } from "./booked-performace.route";
import { BookedPerformanceModalComponent } from './booked-performance-modal/booked-performance-modal.component';
import {CezPortalSharedModule} from '../shared';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(BOOKED_PERFORMANCE_ROUTE, { useHash: true }),
        CezPortalSharedModule
    ],
    declarations: [BookedPerformanceComponent, BookedPerformanceModalComponent]
})
export class BookedPerformanceModule {
}
