import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    PodMaintenanceSearchPreloadModel,
    PodMaintenanceSearchRequestModel,
    PodMaintenanceSearchResultModel
} from './pod-maitenance/pod-maintenance.model';
import {FileSaverService} from '../shared/services/file-saver.service';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(private http: HttpClient,
                private fileSaverService: FileSaverService) {}

    public searchPodAgreementAssignments(filter: PodMaintenanceSearchRequestModel): Observable<PodMaintenanceSearchResultModel> {
        return this.http.post<PodMaintenanceSearchResultModel>('/api/pod-maintenance/search', filter);
    }

    public loadPodAgreementAssignmentFilters(): Observable<PodMaintenanceSearchPreloadModel> {
        return this.http.get<PodMaintenanceSearchPreloadModel>('/api/pod-maintenance/search/load-filters');
    }

    public generatePodAgreementAssignmentTemplate(): void {
        return this.fileSaverService.save(
            '/api/pod-maintenance/import/template',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            {},
            new HttpParams(),
            'pod_agreement_template.xlsx',
            'GET',
            false
        );
    }

}
