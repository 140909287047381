import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {GetBusinessParameterDtoModel} from '../../shared/dto/get-business-parameter-dto.model';
import {BusinessParameterService, BusinessParameterType} from '../../shared/services/business-parameter.service';
import {FormBuilder, FormControl, FormGroup, NgForm, ValidatorFn, Validators} from '@angular/forms';
import {DiagramType} from '../../admin/additional-diag-types/model/diagram-type';
import {AdditionalDiagramResolution} from '../../admin/additional-diag-types/model/additional-diagram-resolution';
import {TranslateService} from '@ngx-translate/core';
import {DateUtil} from '../../core/util/date.util';
import {DialogService} from '../../shared/dialog/dialog.service';

@Component({
    selector: 'business-parameters-modal',
    templateUrl: 'business-parameters.modal.component.html'
})
export class BusinessParametersModalComponent implements OnInit {
    @Input() businessParameter: GetBusinessParameterDtoModel;
    @Output() modalClose: EventEmitter<void> = new EventEmitter();
    isSaving: boolean = false;

    form: FormGroup;

    protected readonly BusinessParameterType = BusinessParameterType;

    constructor(public activeModal: NgbActiveModal,
                private fb: FormBuilder,
                private translateService: TranslateService,
                private businessParameterService: BusinessParameterService,
                private dialogService: DialogService) {
    }

    ngOnInit() {
        this.form = this.fb.group({
            key: new FormControl(
                {
                    value: this.translateService.instant('businessParameters.key.' + this.businessParameter.key),
                    disabled: true
                },
                Validators.required),
            type: new FormControl(
                {
                    value: this.translateService.instant('businessParameters.type.' + this.businessParameter.type),
                    disabled: true
                },
                Validators.required),
            value: new FormControl(
                this.businessParameter.value,
                [Validators.required, ...this.getValidatorsForType(this.businessParameter.type)]
            )
        });
    }

    getValidatorsForType(type: BusinessParameterType): ValidatorFn[] {
        switch (type) {
            case BusinessParameterType.TIME:
                return [Validators.pattern(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)];
            case BusinessParameterType.NUMBER:
                return [Validators.min(0), Validators.pattern(/^\d+$/)]
            case BusinessParameterType.DATE:
                return [Validators.pattern(DateUtil.ISO_8601_PATTERN)]
            default:
                return [];
        }
    }

    save() {
        this.isSaving = true;
        this.businessParameterService.update(this.businessParameter.key, {
            value: this.form['value'].value
        }).subscribe(() => {
            this.isSaving = false;
            this.modalClose.emit();
            this.dialogService.saveSuccess();
        }, () => {
            this.isSaving = false;
        });
    }

    getValueInputType() {
        switch(this.businessParameter.type) {
            case BusinessParameterType.NUMBER:
                return "number";
            case BusinessParameterType.DATE:
            case BusinessParameterType.TIME:
            default:
                return "text";
        }
    }
}
