<mat-form-field>
    <input matInput [matDatepicker]="datepicker"
           [formControl]="dateControl"
           [placeholder]="placeholder"
           intervalRestrictor
           (dateChange)="dateChanged($event.value)"
           required>
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker></mat-datepicker>
    <mat-error *ngIf="dateControl.errors && dateControl.errors.required">{{'day-selector.day-selector.required' | translate}}</mat-error>
</mat-form-field>
