<div class="modal-header">
  <h4 class="modal-title">{{'agreement.agreementData.tolerancePeriods.cardTitle' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="this.activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form name="toleranceLimitForm" role="form" #toleranceLimitForm="ngForm" (ngSubmit)="save()">
  <div class="modal-body">

    <div class="form-group">
        <label for="contractedQuantityMWh">{{'agreement.agreementData.deliveryPeriods.contractedQuantityMWh' | translate}}</label>
      <input required id="contractedQuantityMWh" name="contractedQuantityMWh" [(ngModel)]="model.contractedQuantityMWh" class="form-control"
        type="number">
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label for="startTime">{{'agreement.agreementData.tolerancePeriods.startTime' | translate}}</label>
          <jhi-date-picker [(dpModel)]="model.startTimeNgB" id="startTime"></jhi-date-picker>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label for="endTime">{{'agreement.agreementData.tolerancePeriods.endTime' | translate}}</label>
          <jhi-date-picker [(dpModel)]="model.endTimeNgB" id="endTime"></jhi-date-picker>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-sm-6">
        <label for="priceOfUpwardDeviation">{{'agreement.agreementData.tolerancePeriods.priceOfUpwardDeviation' | translate}}</label>
        <input required id="priceOfUpwardDeviation" name="priceOfUpwardDeviation" [(ngModel)]="model.priceOfUpwardDeviation"
          class="form-control" type="text" maxlength=50>
      </div>
      <div class="form-group col-sm-6">
        <label for="priceOfDownwardDeviation">{{'agreement.agreementData.tolerancePeriods.priceOfDownwardDeviation' | translate}}</label>
        <input required id="priceOfDownwardDeviation" name="priceOfDownwardDeviation" [(ngModel)]="model.priceOfDownwardDeviation"
          class="form-control" type="text" maxlength=50>
      </div>
    </div>
    <div class="form-group">
      <label for="toleranceBandUpPercent">{{'agreement.agreementData.tolerancePeriods.toleranceBandUpPercent' | translate}}</label>
      <input required id="toleranceBandUpPercent" name="toleranceBandUpPercent" [(ngModel)]="model.toleranceBandUpPercent" class="form-control" type="number">
    </div>
    <div class="form-group">
      <label for="toleranceBandDownPercent">{{'agreement.agreementData.tolerancePeriods.toleranceBandDownPercent' | translate}}</label>
      <input required id="toleranceBandDownPercent" name="toleranceBandDownPercent" [(ngModel)]="model.toleranceBandDownPercent" class="form-control" type="number">
    </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary" [disabled]="toleranceLimitForm.form.invalid">{{'entity.action.save' | translate}}</button>
  </div>
</form>
