import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoiceResourceDTO } from '../../admin/invoice-resource/model/invoice-reosurce.model';
import { InvoiceReportResultByIntervalDto } from '../../invoice-report/model/byinterval/invoice-report-result-by-interval-dto';
import { PieChartData } from '../../invoice-report/model/pie-chart-data';
import { InvoiceReportResponse } from '../../invoice-report/model/response.model';
import { Currency } from '../dto/currency.enum';
import { DateInterval } from '../dto/date-interval.model';
import { Resolution } from '../time-series/model/resolution.enum';

@Injectable({
    providedIn: 'root'
})
export class InvoiceReportService {

    constructor(private http: HttpClient) {
    }

    public getInvoicesForPods(partnerId: number,
                              intervals: DateInterval[],
                              resourceIds: number[],
                              podIds: number[],
                              podGroupIds: number[],
                              currency: Currency,
                              invoiceIds: number[]): Observable<InvoiceReportResponse> {
        const intervalsParam = InvoiceReportService.getIntervalsParam(intervals);

        return this.http.post<InvoiceReportResponse>('api/invoice-report', {
            partnerId,
            intervals: intervalsParam,
            resourceIds,
            podIds,
            podGroupIds,
            currency,
            invoiceIds,
            shouldCompare: false
        });
    }

    public getInvoicesForColumnDiagram(partnerId: number,
                                       intervals: DateInterval[],
                                       resolution: Resolution,
                                       resourceIds: number[],
                                       podIds: number[],
                                       podGroupIds: number[],
                                       shouldCalculateUnitCost: boolean,
                                       currency: Currency,
                                       invoiceIds: number[],
                                       shouldCompare: boolean): Observable<InvoiceReportResultByIntervalDto> {
        const intervalsParam = InvoiceReportService.getIntervalsParam(intervals);

        return this.http.post<InvoiceReportResultByIntervalDto>('api/invoice-report/by-interval', {
            partnerId,
            intervals: intervalsParam,
            resolution,
            resourceIds,
            podIds,
            podGroupIds,
            shouldCalculateUnitCost,
            currency,
            invoiceIds,
            shouldCompare
        });
    }

    public getInvoicesForPieChart(partnerId: number,
                                  intervals: DateInterval[],
                                  resourceIds: number[],
                                  podIds: number[],
                                  podGroupIds: number[],
                                  shouldCalculateUnitCost: boolean,
                                  currency: Currency,
                                  invoiceIds: number[]): Observable<PieChartData> {
        const intervalsParam = InvoiceReportService.getIntervalsParam(intervals);

        return this.http.post<PieChartData>('api/invoice-report/by-pie-chart', {
            partnerId,
            intervals: intervalsParam,
            resourceIds,
            podIds,
            podGroupIds,
            shouldCalculateUnitCost,
            currency,
            invoiceIds
        });
    }

    static getIntervalsParam(intervals: DateInterval[]): any {
        if (!intervals) {
            return null;
        }

        return intervals.map(value => ({
            start: value.from.format('YYYY-MM-DD'),
            end: value.to.format('YYYY-MM-DD')
        }));
    }

    public getInvoiceResourceParents(): Observable<InvoiceResourceDTO[]> {
        return this.http.get<InvoiceResourceDTO[]>('/api/invoice-report/resource-parents');
    }
}
