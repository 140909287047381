import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GetBusinessParameterDtoModel} from '../dto/get-business-parameter-dto.model';
import {UpdateBusinessParameterDtoModel} from '../dto/update-business-parameter-dto.model';

export enum BusinessParameterKey {
  MARKETABILITY_WEEKLY_PARAMETER = "MARKETABILITY_WEEKLY_PARAMETER",
  MARKETABILITY_MONTHLY_PARAMETER = "MARKETABILITY_MONTHLY_PARAMETER",
  MARKETABILITY_QUARTERLY_PARAMETER = "MARKETABILITY_QUARTERLY_PARAMETER",
  MARKETABILITY_YEARLY_PARAMETER = "MARKETABILITY_YEARLY_PARAMETER",
  DAILY_PRICE_VALIDITY_PARAMETER = "DAILY_PRICE_VALIDITY_PARAMETER",
  DAILY_PRICE_GENERATE_FROM = "DAILY_PRICE_GENERATE_FROM",
  NOTIFICATION_OF_PRODUCT_EXPIRY = "NOTIFICATION_OF_PRODUCT_EXPIRY",
  CONSUMPTION_PLANNING_MIGRATION = "CONSUMPTION_PLANNING_MIGRATION",
  OTC_TRADING_WINDOW_FROM = "OTC_TRADING_WINDOW_FROM",
  OTC_TRADING_WINDOW_FROM_SCHEDULING_OFFSET = "OTC_TRADING_WINDOW_FROM_SCHEDULING_OFFSET",
  OTC_TRADING_WINDOW_UNTIL = "OTC_TRADING_WINDOW_UNTIL",
  OTC_TRADING_WINDOW_UNTIL_SCHEDULING_OFFSET = "OTC_TRADING_WINDOW_UNTIL_SCHEDULING_OFFSET",
  HUDEX_D_ACCESS_FROM = "HUDEX_D_ACCESS_FROM",
  HUDEX_D_ACCESS_UNTIL = "HUDEX_D_ACCESS_UNTIL",
  HUDEX_D_1_ACCESS_FROM = "HUDEX_D_1_ACCESS_FROM",
  HUDEX_D_1_ACCESS_UNTIL = "HUDEX_D_1_ACCESS_UNTIL",
  SCHEDULED_SUMMARY = "SCHEDULED_SUMMARY",
  HUDEX_TENOR_AVAILABILITY_Y = "HUDEX_TENOR_AVAILABILITY_Y",
  HUDEX_TENOR_AVAILABILITY_Q = "HUDEX_TENOR_AVAILABILITY_Q",
  HUDEX_TENOR_AVAILABILITY_M = "HUDEX_TENOR_AVAILABILITY_M"
}

export enum BusinessParameterType {
  TIME = "TIME",
  NUMBER = "NUMBER",
  DATE = "DATE"
}

@Injectable({
  providedIn: 'root'
})
export class BusinessParameterService {

  constructor(private http: HttpClient) {
  }

  public getParameters(parameters: BusinessParameterKey[]): Observable<Map<BusinessParameterKey, string>> {
    return this.http.get<Map<BusinessParameterKey, string>>(`/api/business-parameters/${parameters}`);
  }

  public getScheduledSummaryDeadline(): Observable<string> {
    return this.http.get<string>(`/api/business-parameters/scheduled-summary-deadline`);
  }

  public findAllVisible(): Observable<GetBusinessParameterDtoModel[]> {
    return this.http.get<GetBusinessParameterDtoModel[]>(`/api/business-parameters`);
  }

  public update(key: BusinessParameterKey, update: UpdateBusinessParameterDtoModel): Observable<GetBusinessParameterDtoModel> {
    return this.http.put<GetBusinessParameterDtoModel>(`/api/business-parameters/${key}`, update);
  }

}
