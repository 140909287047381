<mat-card class="mat-card-min-height">
  <mat-card-content>
    <p-table #trackerTable
             id="tracker-table"
             [value]="priceTrackerModels"
             scrollHeight="600px"
             [paginator]="true"
             [rows]="10"
             sortMode="multiple"
             [multiSortMeta]="[
            {field: 'partner', order: 1},
            {field: 'year', order: -1},
            {field: 'product', order: 1}
         ]"
             (sortFunction)="sortOverview($event)"
             [customSort]="true"
             class="table table-striped">
      <ng-template pTemplate="header">
        <tr>
          <th id="sort-partner-name" [pSortableColumn]="'partner'" class="text-left head-th filter-column-width">
            <span class="no-linebreak" jhiTranslate="priceTracker.table.partner"></span>
            <p-sortIcon [field]="'partner'"></p-sortIcon>
          </th>
          <th id="sort-creation" [pSortableColumn]="'createdAt'" class="text-left head-th filter-column-width">
            <span class="no-linebreak" jhiTranslate="priceTracker.table.createdAt"></span>
            <p-sortIcon [field]="'createdAt'"></p-sortIcon>
          </th>
          <th id="sort-last-modification" [pSortableColumn]="'lastModifiedAt'"
              class="text-left head-th filter-column-width">
            <span class="no-linebreak" jhiTranslate="priceTracker.table.lastModifiedAt"></span>
            <p-sortIcon [field]="'lastModifiedAt'"></p-sortIcon>
          </th>
          <th id="sort-year" [pSortableColumn]="'year'" class="text-left head-th filter-column-width">
            <span class="no-linebreak" jhiTranslate="priceTracker.table.year"></span>
            <p-sortIcon [field]="'year'"></p-sortIcon>
          </th>
          <th id="sort-product" [pSortableColumn]="'product'" class="text-left head-th filter-column-width">
            <span class="no-linebreak" jhiTranslate="priceTracker.table.product"></span>
            <p-sortIcon [field]="'product'"></p-sortIcon>
          </th>
          <th id="sort-min-price" [pSortableColumn]="'minPrice'" class="text-left head-th filter-column-width">
            <span class="no-linebreak" jhiTranslate="priceTracker.table.minPrice"></span>
            <p-sortIcon [field]="'minPrice'"></p-sortIcon>
          </th>
          <th id="sort-max-price" [pSortableColumn]="'maxPrice'" class="text-left head-th filter-column-width">
            <span class="no-linebreak" jhiTranslate="priceTracker.table.maxPrice"></span>
            <p-sortIcon [field]="'maxPrice'"></p-sortIcon>
          </th>
        </tr>
        <tr>
          <th id="filter-partner" class="filter-th">
            <mat-select
              class="pt-2 pb-2 head-select text-left"
              (selectionChange)="trackerTable.filter($event.value, 'partner', 'equals')"
              placeholder="{{'global.choose' | translate}}">
              <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
              <mat-option
                *ngFor="let partner of partners"
                [value]="partner">
                {{ partner }}
              </mat-option>
            </mat-select>
          </th>
          <th id="filter-creation">
            <div class="date-filter-padding">
              <jhi-date-picker class="default-column-width"
                               [(dpModel)]="dateFilterMap.get('createdAt').start"
                               (dpModelChange)="this.trackerTable.filter($event, 'createdAt', 'dateBetween-createdAt')"
                               placeholder="{{ 'global.from' | translate }}">
              </jhi-date-picker>
            </div>
            <div class="date-filter-padding">
              <jhi-date-picker class="default-column-width"
                               [(dpModel)]="dateFilterMap.get('createdAt').end"
                               (dpModelChange)="this.trackerTable.filter($event, 'createdAt', 'dateBetween-createdAt')"
                               placeholder="{{ 'global.to' | translate }}">
              </jhi-date-picker>
            </div>
          </th>
          <th id="filter-last-modified">
            <div class="date-filter-padding">
              <jhi-date-picker class="default-column-width"
                               [(dpModel)]="dateFilterMap.get('lastModifiedAt').start"
                               (dpModelChange)="this.trackerTable.filter($event, 'lastModifiedAt', 'dateBetween-lastModifiedAt')"
                               placeholder="{{ 'global.from' | translate }}">
              </jhi-date-picker>
            </div>
            <div class="date-filter-padding">
              <jhi-date-picker class="default-column-width"
                               [(dpModel)]="dateFilterMap.get('lastModifiedAt').end"
                               (dpModelChange)="this.trackerTable.filter($event, 'lastModifiedAt', 'dateBetween-lastModifiedAt')"
                               placeholder="{{ 'global.to' | translate }}">
              </jhi-date-picker>
            </div>
          </th>
          <th id="filter-year" class="filter-th">
            <mat-select
              class="pt-2 pb-2 head-select text-left"
              (selectionChange)="trackerTable.filter($event.value, 'year', 'equals')"
              placeholder="{{'global.choose' | translate}}">
              <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
              <mat-option
                *ngFor="let year of years"
                [value]="year">
                {{ year }}
              </mat-option>
            </mat-select>
          </th>
          <th id="filter-product" class="filter-th">
            <mat-select
              class="pt-2 pb-2 head-select text-left"
              (selectionChange)="trackerTable.filter($event.value, 'product', 'equals')"
              placeholder="{{'global.choose' | translate}}">
              <mat-option>{{ 'multiSelectText.checkAll' | translate }}</mat-option>
              <mat-option
                *ngFor="let product of products"
                [value]="product">
                {{ product }}
              </mat-option>
            </mat-select>
          </th>
          <th id="filter-min-price">
            <mat-form-field class="price-column-width">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     maxNumber="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('minPrice').min"
                     (input)="trackerTable.filter($event, 'minPrice', 'numberBetween-minPrice')"
                     placeholder="{{'global.from' | translate}}">
            </mat-form-field>
            <mat-form-field class="price-column-width">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     maxNumber="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('minPrice').max"
                     (input)="trackerTable.filter($event, 'minPrice', 'numberBetween-minPrice')"
                     placeholder="{{'global.to' | translate}}">
            </mat-form-field>
          </th>
          <th id="filter-max-price">
            <mat-form-field class="price-column-width">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     maxNumber="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('maxPrice').min"
                     (input)="trackerTable.filter($event, 'maxPrice', 'numberBetween-maxPrice')"
                     placeholder="{{'global.from' | translate}}">
            </mat-form-field>
            <mat-form-field class="price-column-width">
              <input type="text"
                     matInput
                     mask="separator.2"
                     autocomplete="off"
                     class="text-right"
                     maxlength="10"
                     maxNumber="99999999.99"
                     thousandSeparator="{{'number.thousandSeparator' | translate}}"
                     (keydown)="onKeyDown($event)"
                     [decimalMarker]="'number.decimalMarker' | translate"
                     [dropSpecialCharacters]="true"
                     [validation]="true"
                     [(ngModel)]="numberFilterMap.get('maxPrice').max"
                     (input)="trackerTable.filter($event, 'maxPrice', 'numberBetween-maxPrice')"
                     placeholder="{{'global.to' | translate}}">
            </mat-form-field>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
        <tr [pSelectableRow]="rowData">
          <td class="text-left">{{ rowData.partner }}</td>
          <td class="text-left">{{ rowData.createdAt | cezDate }}</td>
          <td class="text-left">{{ rowData.lastModifiedAt | cezDate }}</td>
          <td class="text-left">{{ rowData.year }}</td>
          <td class="text-left">{{ rowData.product }}</td>
          <td class="text-right">{{ rowData.minPrice | number:'1.2-2'}}</td>
          <td class="text-right">{{ rowData.maxPrice | number:'1.2-2'}}</td>
        </tr>
      </ng-template>
    </p-table>
  </mat-card-content>
</mat-card>

