import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment-timezone';
import { filter, mergeMap } from 'rxjs/operators';
import { Invoice } from './invoice.model';
import { InvoiceChartPopup } from './chart/invoice-chart-popup.component';
import { InvoicePopupModel } from './model/invoice-popup.model';
import { ResultViewType } from '../invoice-report/model/result-view-type.enum';
import { PopupResultModel } from './chart/popup-result.model';
import { Router } from '@angular/router';
import * as moment from 'moment';
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";
import {InvoicesService} from '../shared/services/invoices.service';
import {StateStorageService} from '../shared/auth';
import {DialogService} from '../shared/dialog/dialog.service';
import {DateInterval} from '../shared/dto/date-interval.model';

@Component({
    selector: 'jhi-invoices',
    templateUrl: './invoices.component.html',
    styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

    @Input() partner: number;
    @Input() erasable: boolean;

    invoices: Invoice[];
    selectedInvoice: Invoice;
    fromDate: Moment;
    toDate: Moment;

    resultViewType = ResultViewType;

    constructor(private invoicesService: InvoicesService,
                private translate: TranslateService,
                private stateStorageService: StateStorageService,
                private dialogService: DialogService,
                private dialog: MatDialog,
                private router: Router) {
    }

    ngOnInit() {
        this.fromDate = moment().startOf('month');
        this.toDate = moment().startOf('month').add(1, 'month');
        this.refresh();
    }

    refresh() {
        let partnerId;
        if (this.partner) {
            partnerId = this.partner;
        } else {
            partnerId = this.stateStorageService.getSelectedCompanyId();
        }

        if (this.stateStorageService.getEffectivePermissions().indexOf('INVOICE_READ') > -1) {
            this.invoicesService.getInvoices(String(partnerId), this.fromDate, this.toDate).subscribe(value => {
                this.invoices = value;
            });
        }
    }


    setInterval(interval: DateInterval) {
        this.fromDate = interval.from;
        this.toDate = interval.to;
    }

    findInvoices() {
        this.refresh();
    }

    deleteInvoice(invoiceId: number) {
        this.dialogService.confirm(
            this.translate.instant('invoices.dialog.alert.title'),
            this.translate.instant('invoices.dialog.alert.message')
        ).pipe(
            filter(Boolean),
            mergeMap(() => this.invoicesService.deleteInvoice(invoiceId))
        ).subscribe(() => {
            this.refresh();
            this.dialogService.deleteSuccess();
        }, (error) => {
            console.error(error);
        });

    }

    createChart(diagramType: ResultViewType) {
        let data: InvoicePopupModel = {
            invoices: [this.selectedInvoice],
            diagramType: diagramType
        };

        this.dialog
            .open(InvoiceChartPopup, {
                disableClose: true,
                data: data,
                panelClass: 'invoice-popup'
            }).afterClosed().subscribe(result => {
                if (result === PopupResultModel.NAVIGATE) {
                    this.router.navigateByUrl('/invoiceReport');
                }
        });
    }
}
