<ngx-loading class="local-loader"
             [show]="isLoading"
             [config]="{fullScreenBackdrop: false}"></ngx-loading>

<div class="mtm-tab-table">
    <table class="table table-striped">
        <thead>
        <tr class="sticky-top bg-white">
            <th class="text-left">
                <span></span>
            </th>
            <th class="text-right">
                <span jhiTranslate="offerClaim.mtmReport.table.quantity"></span>
            </th>
            <th class="text-right">
                <span jhiTranslate="offerClaim.mtmReport.table.unitPrice"></span>
            </th>
            <th class="text-right">{{ columnTitle }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let mtmDto of mtmReportDtos" [class.font-weight-bold]="mtmDto.summaryRow">
            <td class="text-left">{{ getFormattedType(mtmDto) }}</td>
            <td class="text-right">{{ mtmDto.quantity | number:'1.2-2' }}</td>
            <td class="text-right">{{ mtmDto.unitPrice | number:'1.2-2' }}</td>
            <td class="text-right">{{ mtmDto.price | number:'1.0-0' }}</td>
        </tr>
        </tbody>
    </table>
</div>
