import { Route } from '@angular/router';
import { BookedPerformanceComponent } from './booked-performance.component';
import {UserRouteAccessService} from '../shared';
import {PartnerMandatoryGuard} from '../shared/partner-mandatory.guard';

export const BOOKED_PERFORMANCE_ROUTE: Array<Route> = [{
    path: 'scheduling',
    component: BookedPerformanceComponent,
    data: {
        permission: 'BOOKED_PERFORMANCE_SCREEN',
        softPermissions: ['BOOKED_PERFORMANCE_WRITE', 'BOOKED_PERFORMANCE_READ'],
        pageTitle: 'main.link.scheduling'
    },
    canActivate: [UserRouteAccessService, PartnerMandatoryGuard]
}];
