import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyModel } from '../dto/company.model';
import { PodBookedPerformanceModel } from '../dto/pod.booked.performance.model';
import { PodModel } from '../dto/pod.model';
import { PodsDefaultSettingsModel } from '../dto/pods-default-settings.model';

@Injectable()
export class BookedPerformanceService {

    constructor(private http: HttpClient) {
    }

    getPods(partnerId: number): Observable<PodModel[]> {
        return this.http.get<PodModel[]>('api/booked-performance/partner/' + partnerId);
    }

    getPartnersPodsAndDefaultSettings(partnerId: number): Observable<PodsDefaultSettingsModel> {
        return this.http.get<PodsDefaultSettingsModel>(`api/booked-performance/partner/${partnerId}/pods-default-settings`);

    }

    getBookedPerformance(partnerId: number, podId: number): Observable<PodBookedPerformanceModel[]> {
        return this.http.get<PodBookedPerformanceModel[]>(`api/booked-performance/partner/${partnerId}/pod/${podId}`);
    }

    getBookedPerformanceListForInterval(partnerId: number, podId: number, startTime: string, endTime: string) {

        return this.http.get<PodBookedPerformanceModel[]>(`api/booked-performance/partner/${partnerId}/pod/${podId}/interval?startTime=${startTime}&endTime=${endTime}`);

    }

    saveBookedPerformance(partnerId: number, podId: number, model: PodBookedPerformanceModel): Observable<PodBookedPerformanceModel[]> {
        this.createModel(partnerId, podId, model);
        return this.http.post<PodBookedPerformanceModel[]>(`api/booked-performance`, model);
    }

    updateBookedPerformance(partnerId: number, podId: number, model: PodBookedPerformanceModel): Observable<PodBookedPerformanceModel[]> {
        this.createModel(partnerId, podId, model);
        return this.http.put<PodBookedPerformanceModel[]>(`api/booked-performance`, model);
    }

    deleteBookedPerformance(id: number): Observable<void> {
        return this.http.delete<void>(`api/booked-performance/${id}`);
    }

    private createModel(partnerId: number, podId: number, model: PodBookedPerformanceModel) {
        model.pod = new PodModel;
        model.partner = new CompanyModel();
        model.pod.id = podId;
        model.partner.id = partnerId;
    }

}
