import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {GroupedOfferClaimDto} from '../shared/offer-claim/model/GroupedOfferClaimDto';
import {OfferClaimDto} from '../shared/offer-claim/model/OfferClaimDto';
import {DailyRatesDto} from '../shared/daily-rates/model/DailyRatesDto';
import {OfferClaimHistoryDto} from '../shared/offer-claim/model/OfferClaimHistoryDto';
import {FileSaverService} from '../shared/services/file-saver.service';
import {DailyRateTimeSeries} from '../shared/daily-rates/model/DailyRateTimeSeries';
import {QueryInterval} from '../shared/daily-rates/model/query-interva.enum';
import {DailyRatesExportDto} from '../shared/daily-rates/model/DailyRatesExportDto';
import {AvailableProductPeriodsDto} from "../shared/available-products/model/AvailableProductPeriodsDto";
import {DailyRateType} from '../shared/daily-rates/model/DailyRatesType.enum';
import {ActiveOfferDto} from '../shared/offer-claim/model/ActiveOfferDto';
import {Moment} from 'moment';
import {DailyRatePreload} from '../shared/daily-rates/model/DailyRatePreload';
import {PartnerWithDeliveryPeriods} from '../shared/offer-claim/model/PartnerWithDeliveryPeriods';
import {PriceTrackerDto} from "../coverage-transactions/price-monitoring/coverage-transactions-price-tracker.model";
import {
  OfferClaimFieldsForOpenPositionDTO
} from '../coverage-transactions/offer-claim/offer-claim-list/offer-claim-list.component';
import {
  OpenPositionStateDTO
} from '../coverage-transactions/offer-claim/offer-claim-list/offer-claim-list.open-position-status.model';

@Injectable({
  providedIn: 'root'
})
export class CoverageTransactionsAdminService {

  constructor(private http: HttpClient,
              private fileSaver: FileSaverService) {
  }

  public getOfferClaims(): Observable<GroupedOfferClaimDto[]> {
    return this.http.get<GroupedOfferClaimDto[]>(`api/offer-claim/admin/list`);
  }

  public getOfferClaimHistory(): Observable<OfferClaimHistoryDto[]> {
    return this.http.get<OfferClaimHistoryDto[]>(`api/offer-claim-history/admin/list`);
  }

  public getOfferClaimHistoryApprovedByPartnerAndDeliveryPeriod(partner: number, deliveryPeriod: number): Observable<OfferClaimHistoryDto[]> {
    return this.http.get<OfferClaimHistoryDto[]>(`api/offer-claim-history/${partner}/${deliveryPeriod}/list`);
  }

  public exportClaimHistory(offerClaimHistoryDtos: OfferClaimHistoryDto[], lang: string): void {
    this.fileSaver.save(
      'api/offer-claim-history/admin/export',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      {
        lang: lang,
        offerClaims: offerClaimHistoryDtos
      }
    );
  }

  public sendQuotation(offerClaims: OfferClaimDto[]): Observable<void> {
    return this.http.post<void>(`api/offer-claim/admin/send-quotation`, offerClaims);
  }

  public getDailyPricesPublicationType(): Observable<DailyRateType> {
    return this.http.get<DailyRateType>("api/daily-prices-state/publication-type");
  }

  public getDailyRates(): Observable<DailyRatesDto[]> {
    return this.http.get<DailyRatesDto[]>("api/daily-rates/current");
  }

  public getPartnersWithDeliveryPeriods(): Observable<PartnerWithDeliveryPeriods[]> {
    return this.http.get<PartnerWithDeliveryPeriods[]>(`api/offer-claim/admin/partners-with-delivery-periods`);
  }

  public saveDailyRates(rates: DailyRatesDto[], type: DailyRateType, deadline: Moment): Observable<DailyRatesDto[]> {
    let params: HttpParams = new HttpParams().set('deadline', deadline.toISOString());
    return this.http.post<DailyRatesDto[]>(`api/daily-rates/current/${type}`, rates, {params: params});
  }

  public getDailyRatesPreloadConfig(): Observable<DailyRatePreload> {
    return this.http.get<DailyRatePreload>("api/daily-rates/preload");
  }

  public withdrawalDailyPrices(): Observable<void> {
    return this.http.post<void>('api/daily-rates/withdrawal', {});
  }

  public getCurrentRates(queryInterval: QueryInterval, deliveryPeriodId?: number): Observable<DailyRateTimeSeries[]> {
    if (deliveryPeriodId == null) {
      return this.http.get<DailyRateTimeSeries[]>(`api/daily-rates/query?tsInterval=${queryInterval}`);
    } else {
      return this.http.get<DailyRateTimeSeries[]>(`api/daily-rates/query?tsInterval=${queryInterval}&deliveryPeriodId=${deliveryPeriodId}`);
    }
  }

  public getAllDailyRateHeadForExport(): Observable<DailyRatesExportDto[]> {
    return this.http.get<DailyRatesExportDto[]>('/api/daily-rates/all-for-export');
  }

  public getAvailableProductPeriods(): Observable<AvailableProductPeriodsDto[]> {
    return this.http.get<AvailableProductPeriodsDto[]>("api/available-product-periods/");
  }

  public getActiveOffers(): Observable<ActiveOfferDto[]> {
    return this.http.get<ActiveOfferDto[]>(`api/offer-claim/admin/active-offers`);
  }

  public getPriceTrackers(forAdmin: boolean): Observable<PriceTrackerDto[]> {
    return this.http.get<PriceTrackerDto[]>(`api/price-tracker?forAdmin=${forAdmin}`);
  }

  public delete(ochId: number): Observable<any> {
    return this.http.delete(`api/offer-claim-history/admin/${ochId}`);
  }

  public getIncomingHedgeLimitDetails(offerClaims: OfferClaimFieldsForOpenPositionDTO[]): Observable<OpenPositionStateDTO> {
    return this.http.post<OpenPositionStateDTO>(`/api/offer-claim-used-fund/incoming-hege-limit-details`, offerClaims);
  }

  exportTransaction(partnerId: number, lang: string) {
    this.fileSaver.save(
      'api/offer-claim-history/admin/export-transactions',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      {
        partnerId: partnerId,
        lang: lang
      }
    );
  }
}
