import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Moment } from 'moment';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DisplayModeChangeEventModel } from './display-mode/display-mode-change-event.model';
import { MarketProductsComponent } from './marketproducts/market-products.component';
import { AdditionalTimeSeriesDto } from './model/additional-time-series';
import { TimeSeriesDataSelectionChange } from './model/change/time-series-data-selection-change';
import { ProductDto } from './model/productDto';
import { TimeSeriesDisplayEvent } from './model/time-series-display-event';
import { TimeSeriesId } from './model/time-series-id';
import { TimeSeriesResolution } from './model/timeseries-resolution';
import { IdentifiedTimeSeries } from './model/timeseries/identified-time-series';
import * as moment from 'moment';
import {MarketPriceService} from '../shared/services/market-price.service';
import {StateStorageService} from '../shared/auth';
import {HistoricalDataUtil} from '../shared/historical-data-handler/historical-data.util';
import {Resolution} from '../shared/time-series/model/resolution.enum';

@Component({
    selector: 'jhi-price',
    templateUrl: './market-price.component.html',
    styleUrls: []
})
export class MarketPriceComponent implements OnInit {

    products: Array<ProductDto>;
    additionalTimeSeries: Array<AdditionalTimeSeriesDto> = [];
    selectedIds: Array<TimeSeriesId> = [];
    timeSeriesDatas: Array<IdentifiedTimeSeries> = [];
    timeSeriesDataChanges: BehaviorSubject<TimeSeriesDataSelectionChange[]> = new BehaviorSubject([]);
    reloadNumber: number = 0;
    loadingOverlay: boolean;

    timeSeriesFrom: Moment;
    timeSeriesTo: Moment;
    timeSeriesResolution: TimeSeriesResolution;

    minSelected: boolean;
    maxSelected: boolean;
    avgSelected: boolean;
    compactSelected: boolean;
    @Input() tabContent: boolean;

    @ViewChild(MarketProductsComponent, {static: true}) marketProductsComponent: MarketProductsComponent;

    constructor(
        public translateService: TranslateService,
        private marketPriceService: MarketPriceService,
        private activatedRoute: ActivatedRoute,
        private stateStorageService: StateStorageService) {
    }

    ngOnInit() {
        this.compactSelected = true;
        this.loadingOverlay = true;
        this.activatedRoute.data.subscribe(result => {
            const [products, additional, spot] = result.marketPrices;
            this.products = products;
            additional.push(...spot);
            this.additionalTimeSeries = additional;
        });

        const min: Moment = HistoricalDataUtil.getHistoricalMin(this.stateStorageService.getEffectivePermissions(), Resolution.DAILY);
        const startOfYear = moment().startOf('year');

        this.timeSeriesFrom = min && min.isAfter(startOfYear) ? min : startOfYear;
        this.timeSeriesTo = moment().startOf('day').add(1, 'day');

        this.timeSeriesResolution = TimeSeriesResolution.HOURLY;
        this.reloadCharts();
    }

    displaySettingsChanged(settings: DisplayModeChangeEventModel) {
        this.timeSeriesFrom = settings.from;
        this.timeSeriesTo = settings.to;
        this.timeSeriesResolution = settings.resolution;
        this.reloadCharts();
    }

    reloadCharts() {
        this.removeTimeSeries(this.selectedIds);

        const reloadNumber = ++this.reloadNumber;
        forkJoin(this.selectedIds.map(selectedId => this.loadChart(selectedId).pipe(catchError(() => of(null)))))
            .subscribe(identifiedTimeSeriesItems => {
                if (reloadNumber === this.reloadNumber) {
                    this.timeSeriesDatas = identifiedTimeSeriesItems.filter(Boolean);
                    this.addTimeSeries(this.timeSeriesDatas);
                }
            });
    }

    showHideDiagram(event: TimeSeriesDisplayEvent) {
        let chartIndex = this.selectedIds.findIndex(timeSeriesId => TimeSeriesId.equals(timeSeriesId, event.timeSeriesId));

        const newChartToDisplay = event.display && chartIndex === -1;
        const existingChartToRemove = !event.display && chartIndex !== -1;

        if (newChartToDisplay) {
            this.selectedIds.push(event.timeSeriesId);
            this.loadChart(event.timeSeriesId)
                .subscribe(identifiedTimeSeries => {
                    let requiredButNotAdded = this.selectedIds.findIndex(timeSeriesId => TimeSeriesId.equals(timeSeriesId, event.timeSeriesId)) !== -1 &&
                        this.timeSeriesDatas.findIndex(timeSeriesData => TimeSeriesId.equals(timeSeriesData.timeSeriesId, event.timeSeriesId)) === -1;
                    if (requiredButNotAdded) {
                        this.timeSeriesDatas.push(identifiedTimeSeries);
                        this.addTimeSeries([identifiedTimeSeries]);
                    }
                }, error => {
                    if (event.timeSeriesId.sid && error.error && error.error.hidden) {
                        this.marketProductsComponent.setErrorForSpotInDelivery(event.timeSeriesId.timeSeriesHeaderId, error.error.violations[0].errorCode);
                    }
                });
        } else if (existingChartToRemove) {
            this.selectedIds.splice(chartIndex, 1);
            let dataIndex = this.timeSeriesDatas.findIndex(timeSeriesData => TimeSeriesId.equals(timeSeriesData.timeSeriesId, event.timeSeriesId));
            if (dataIndex !== -1) {
                this.timeSeriesDatas.splice(dataIndex, 1);
                this.removeTimeSeries([event.timeSeriesId]);
            }
        }

    }

    addTimeSeries(identifiedTimeSeriesItems: IdentifiedTimeSeries[]) {
        let timeSeriesDataSelectionChanges = identifiedTimeSeriesItems.map((identifiedTimeSeries) =>
            TimeSeriesDataSelectionChange.addTimeSeries(identifiedTimeSeries.timeSeriesId, identifiedTimeSeries.timeSeries));
        this.timeSeriesDataChanges.next(timeSeriesDataSelectionChanges);
    }

    removeTimeSeries(timeSeriesIds: TimeSeriesId[]) {
        let timeSeriesDataSelectionChanges = timeSeriesIds.map((timeSeriesId) => TimeSeriesDataSelectionChange.removeTimeSeries(timeSeriesId));
        this.timeSeriesDataChanges.next(timeSeriesDataSelectionChanges);
    }

    loadChart(timeSeriesId: TimeSeriesId): Observable<IdentifiedTimeSeries> {
        if (!this.timeSeriesFrom || !this.timeSeriesTo || !this.timeSeriesResolution) {
            return EMPTY;
        }

        if (timeSeriesId.sid) {
            return this.marketPriceService.getSpotInDelivery(
                timeSeriesId.timeSeriesHeaderId,
                this.timeSeriesFrom,
                this.timeSeriesTo,
                this.timeSeriesResolution
            ).pipe(
                tap(tsd => tsd.diagramType = timeSeriesId.diagramType),
                map(tsd => new IdentifiedTimeSeries(timeSeriesId, tsd))
            );
        } else {
            return this.marketPriceService.getTimeSeries(
                timeSeriesId.timeSeriesHeaderId,
                timeSeriesId.profileName,
                this.timeSeriesFrom,
                this.timeSeriesTo,
                this.timeSeriesResolution
            ).pipe(
                tap(tsd => tsd.diagramType = timeSeriesId.diagramType),
                map(tsd => new IdentifiedTimeSeries(timeSeriesId, tsd))
            );
        }
    }
}
