import { BaseDto, FileDto, Category, LocalizedTexts } from "../";
import { ProductAndServicesModelBase } from "./product-and-services.model.base";

export class ProductAndServicesModel extends ProductAndServicesModelBase {
    title: LocalizedTexts;
    description: LocalizedTexts;

    constructor() {
        super();
        this.title = new LocalizedTexts();
        this.description = new LocalizedTexts();
    }
}