<mat-accordion>
    <mat-expansion-panel matBadge="{{files.length}}" [matBadgeHidden]="!active" matBadgeOverlap="true"
                         matBadgePosition="above before" matBadgeColor="warn" [disabled]="!active" #activatePanel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{!active ? ('timeSeriesImport.header.activatePanel' | translate) : ('timeSeriesImport.waitForActivate' | translate) }}
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div *ngIf="warning" class="alert alert-warning alert-dismissible">
            {{warning}}
        </div>
        <mat-list *ngIf="files.length !== 0">
            <mat-list-item *ngFor="let f of files">
                <mat-icon mat-list-icon>note</mat-icon>
                <p [matTooltip]="f?.name" mat-line>{{f?.name}}</p>
            </mat-list-item>
        </mat-list>

    </mat-expansion-panel>
    <div class="btn-toolbar justify-content-center d-flex">
        <div class="btn-group btn-group-toggle flex-fill">
            <button mat-raised-button color="primary"
                    matTooltip="{{'timeSeriesImport.cancelButton.toolTip' | translate}}"
                    type="button"
                    class="btn btn-block font-size-14 mt-1 mr-1"
                    [disabled]="!active"
                    (click)="cancelClicked()">
                <i class="material-icons">cancel</i>{{'timeSeriesImport.cancelButton.name' | translate}}
            </button>

            <button *ngIf="activatePermissionCheck"
                    mat-raised-button color="primary"
                    [writeProtected]="activatePermission"
                    [matTooltip]="activateButtonTooltip"
                    type="button"
                    [disabled]="!active"
                    class="btn btn-block font-size-14 mt-1"
                    (clickIfHasPermission)="activate()">
                <i class="material-icons">done</i>{{'timeSeriesImport.activateButton.name' | translate}}
            </button>
            <button *ngIf="!activatePermissionCheck"
                    mat-raised-button color="primary"
                    [matTooltip]="activateButtonTooltip"
                    type="button"
                    [disabled]="!active"
                    class="btn btn-block font-size-14 mt-1"
                    (click)="activate()">
                <i class="material-icons">done</i>{{'timeSeriesImport.activateButton.name' | translate}}
            </button>
        </div>
    </div>
</mat-accordion>
