import { AfterViewInit, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';


import { PasswordResetInit } from './password-reset-init.service';

@Component({
    selector: 'jhi-password-reset-init',
    templateUrl: './password-reset-init.component.html'
})
export class PasswordResetInitComponent implements OnInit, AfterViewInit {
    resetAccount: any;
    success: boolean = false;

    constructor(

        private passwordResetInit: PasswordResetInit,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {

    }

    ngOnInit() {
        this.resetAccount = {};
    }

    ngAfterViewInit() {
        //this.renderer.invokeElementMethod(this.elementRef.nativeElement.querySelector('#email'), 'focus', []);
        this.renderer.selectRootElement(this.elementRef.nativeElement.querySelector('#email')).focus();
    }

    requestReset () {

        this.success = false;
        this.passwordResetInit.save(this.resetAccount.email).subscribe(() => {
            this.success = true;
        });
    }
}
