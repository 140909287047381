<div class="modal-header">
  <h4 class="modal-title">{{'agreement.pod_groups.table.cardTitle' | translate}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="this.activeModal.close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form name="podForm" role="form" #podForm="ngForm" (ngSubmit)="save()">
  <div class="modal-body">
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label for="startTime">{{'agreement.agreementData.pods.startTime' | translate}}</label>
          <jhi-date-picker [(dpModel)]="model.startTimeNgB" id="startTime"></jhi-date-picker>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label for="endTime">{{'agreement.agreementData.pods.endTime' | translate}}</label>
          <jhi-date-picker [(dpModel)]="model.endTimeNgB" id="endTime"></jhi-date-picker>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label for="pod">{{'agreement.agreementData.pods.title' | translate}}</label>
      <br>
      <p-autoComplete required id="pod" name="pod" optionLabel="podCode" inputStyleClass="form-control" [dropdown]="true" [(ngModel)]="model.pod"
        [suggestions]="podResults" (completeMethod)="searchPod($event)" (onDropdownClick)="handleDropdown()"> </p-autoComplete>

    </div>
    <ng-container *ngIf="model.pod">
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="podCode">{{'agreement.agreementData.pods.podCode' | translate}}</label>
            <input required id="podCode" name="podCode" [ngModel]="model.pod.podCode" class="form-control" type="text" disabled>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label for="podType">{{'agreement.agreementData.pods.type' | translate}}</label>
              <input required id="podType" name="podType"
                     [value]="podService.translateProfileType(model.pod.podType) || ''" class="form-control" type="text"
                     disabled>
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="zipCode">{{'agreement.agreementData.pods.zipCode' | translate}}</label>
        <input required id="zipCode" name="zipCode" [ngModel]="model.pod.zipCode" class="form-control" type="text" disabled>
      </div>

      <div class="form-group">
        <label for="address">{{'agreement.agreementData.pods.address' | translate}}</label>
        <input required id="address" name="address" [ngModel]="podService.getPodAddress(model.pod)" class="form-control" type="text"
               disabled>
      </div>

      <div class="form-group">
        <label for="profileType">{{'agreement.agreementData.pods.profileType' | translate}}</label>
        <input required id="profileType" name="profileType" [ngModel]="model.pod.profileType" class="form-control" type="text" disabled>
      </div>
    </ng-container>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" [disabled]="podForm.form.invalid || podNotSelected()">{{'entity.action.save' | translate}}</button>
    </div>
  </div>
</form>
