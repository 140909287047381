<h1 mat-dialog-title>{{data.title | translate}}</h1>
<div mat-dialog-content>
    <div class="row">
        <label class="col-6" for="modifyParameter">
            {{data.paramName | translate}}
        </label>
        <div class="col-6">
            <input [type]="data.type"
                   class="form-control"
                   id="modifyParameter"
                   [(ngModel)]="value">
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button
            cez-color="dark"
            [mat-dialog-close]="null">
        {{'common.action.cancel' | translate}}
    </button>
    <button mat-raised-button
            color="primary"
            [disabled]="value === defaultValue"
            (click)="save()">
        {{'common.action.save' | translate}}
    </button>
</div>
